export const totalPricePerDoor = (door, prices) => {

    if (prices === undefined) { alert("Prices are missing") }

    let totalPrice = 0


    if (door.Ironmongery !== undefined) {
        door.Ironmongery.forEach(id => {

            let index = prices.availableIronmongery.findIndex(irm => irm[0] === id)

            totalPrice += Number(prices.availableIronmongery[index][1].price)
        })
    }

    if (door.Glass !== undefined) {

        let sqm = sqmArea(door)

        let index = prices.availableGlass.findIndex(glassType => glassType[0] === door.Glass)

        totalPrice += roundToTwo(Number(prices.availableGlass[index][1].price) * sqm)

    }

    if (door.Finish !== undefined) {


        let index = prices.availableFinishes.findIndex(glassType => glassType[0] === door.Finish)

        totalPrice += roundToTwo(Number(prices.availableFinishes[index][1].price))
    }

    if (door.extraOver !== undefined){
        door.extraOver.forEach( item =>{
            totalPrice += Number(item.price)
        })
    }

    if (door.DoorType !== undefined) {
        let index = prices.availableDoorTypes.findIndex(doorType => doorType[0] === door.DoorType)
        if (index !== -1) {
            let item = prices.availableDoorTypes[index][1]
            let sqm = sqmArea(door)

            // min door area
            if(sqm < item.minArea){sqm = item.minArea}
   

            if (item.fixedPrice !== undefined) {
                totalPrice += roundToTwo((Number(item.price) * sqm) + Number(item.fixedPrice))
            } else {
                totalPrice += roundToTwo(Number(item.price) * sqm)
            }
        }

    }

    return (totalPrice)

}

export const returnTotalVat = (quote) => {

    let total = 0;
    let index;
    let globalDiscount = false

    if (quote.additionalPriceItems !== undefined) {
        index = quote.additionalPriceItems.findIndex(x => x.globalDiscount === true)

        if (index !== -1) {
            globalDiscount = true
        }

    }

    if (globalDiscount) {

        for (let additionalItem of quote.additionalPriceItems) {
            if (additionalItem.globalDiscount) {

                for (let door of quote.doors) {
                    let qty = Number(door.qty);
                    let totPrice = totalPricePerDoor(door, quote.prices) - (totalPricePerDoor(door, quote.prices) * (1 - (100 - door.discount) / 100));
                    totPrice = totPrice - (totPrice * (1 - (100 - additionalItem.discount) / 100));
                    total += (qty * totPrice) * ('0.' + Number(door.vat));
                }

                for (let item of quote.additionalPriceItems) {
                    if (!item.globalDiscount) {
                        let qty = Number(item.qty);
                        let totPrice = item.price - (item.price * (1 - (100 - item.discount) / 100));
                        totPrice = totPrice - (totPrice * (1 - (100 - additionalItem.discount) / 100));
                        total += (qty * totPrice) * ('0.' + Number(item.vat));
                    }
                }
            }
        }

    } else {

        for (let door of quote.doors) {
            let qty = Number(door.qty);
            let totPrice = totalPricePerDoor(door, quote.prices) - (totalPricePerDoor(door, quote.prices) * (1 - (100 - door.discount) / 100));
            total += (qty * totPrice) * ('0.' + Number(door.vat));
        }

        if (quote.additionalPriceItems !== undefined) {
            for (let item of quote.additionalPriceItems) {
                if (!item.globalDiscount) {
                    let qty = Number(item.qty);
                    let totPrice = item.price - (item.price * (1 - (100 - item.discount) / 100));
                    total += (qty * totPrice) * ('0.' + Number(item.vat));
                }
            }
        }

    }
    return total.toFixed(2);
}


export const sqmArea = (door) => {
    return (((Number(door.mainDrawing.doorFrameWidth) + Number(door.mainDrawing.lpWidth) + Number(door.mainDrawing.rpWidth)) * (Number(door.mainDrawing.doorFrameHeight) + Number(door.mainDrawing.transomHeight))) / 1000000)
}

export const roundToTwo = (num) => {
    return +(Math.round(num + "e+2") + "e-2");
}



///// ------------------------------ Total discount 

export const returnTotalDiscount = (quote) => {

    let total = 0;

    for (let door of quote.doors) {
        let qty = Number(door.qty);
        let totPrice = totalPricePerDoor(door, quote.prices) * (1 - (100 - door.discount) / 100);
        total += qty * totPrice;
    }

    for (let item of quote.additionalPriceItems) {

        if (!item.globalDiscount) {
            let qty = Number(item.qty);
            let totPrice = item.price * (1 - (100 - item.discount) / 100);
            total += qty * totPrice;

        }
    }

    if (quote.additionalPriceItems.findIndex(x => x.globalDiscount === true) !== -1) {

        let sumOfAllDoors = sumUpAllNetValue(quote)

        for (let item of quote.additionalPriceItems) {
            if (item.globalDiscount) {
                total = total + ((sumOfAllDoors - total) * (1 - (100 - item.discount) / 100));
            }
        }
    }

    return total.toFixed(2);
}


///// ------------------------------ Total net Sum  

export const sumUpAllNetValue = (quote) => {
    let total = 0;

    for (let door of quote.doors) {
        total += Number(totalPricePerDoor(door, quote.prices)) * Number(door.qty)
    }

    if (quote.additionalPriceItems !== undefined) {
        for (let item of quote.additionalPriceItems) {
            // jesli jest znizka globalna to nie zlicza
            if (!item.globalDiscount) {
                let qty = Number(item.qty);
                let totPrice = item.price;
                total += qty * totPrice;
            }
        }
    }
    return total.toFixed(2);
}



///// ------------------------------ showDiscount  

export const showDiscount = (quote) => {
    let discount = false;

    if (
        quote.doors.findIndex(x => Number(x.discount)) !== -1 ||
        quote.additionalPriceItems.findIndex(x => Number(x.discount) !== 0) !== -1
    ) {
        discount = true
    } else {
        discount = false
    }

    return (discount)
}