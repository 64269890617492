export  const optionReset = () =>{
    // ----------- Resets all choices

    let glassForm = document.querySelectorAll('.glass');
    let ironmongeryForm = document.querySelectorAll('.ironmongery');
    let finishForm = document.querySelectorAll('.finish');
    let doorTypesForm = document.querySelectorAll('.doorTypes');


    finishForm.forEach(element => {  element.children[1].children[0].selectedIndex = 0  });
    ironmongeryForm.forEach(element => {  element.children[1].children[0].selectedIndex = 0  });
    glassForm.forEach(element => {  element.children[1].children[0].selectedIndex = 0  });
    doorTypesForm.forEach(element => {  element.children[1].children[0].selectedIndex = 0  });



    // ------------ Resets all choices End
}