import React, { Component } from 'react';
class DigitalLock extends Component{
    state= {}
    render() {
        return (
            <div style={{ height: `${125/this.props.printRatio}px`,  width: `${100/this.props.printRatio}px`, border: '1px solid #000', position: 'absolute',margin: '0 auto',[this.props.handleSide]: `${25/this.props.printRatio}px`,bottom: `${this.props.bottom/this.props.printRatio}px`,background: 'white', borderRadius: '7px', padding: `${20/this.props.printRatio}px`, boxSizing: 'border-box'}}>   
                <div style={{ height: `${60/this.props.printRatio}px`,  width: `${50/this.props.printRatio}px`, border: '1px solid #000',position: 'absolute', left: '50%', transform: 'translate(-50%)' }}></div>
            </div>
        )
    }
}
export default DigitalLock;