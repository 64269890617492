import React, { Component } from 'react';
// import * as firebase from 'firebase'
import firebase from "firebase/compat/app";
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import moment from 'moment';

import IncomeTable from '../orders/IncomeTable';
import Paper from '@material-ui/core/Paper';


  
  const styles = theme => ({
    root: {
    //   backgroundColor: theme.palette.background.paper,
      width: '100%',
      margin: '50 auto',
    },
    btn:{
        backgroundColor: '#d60a2e',
        color: '#ffffff',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),

    },
    textField: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: 200,
         ['@media (max-width:600px)']: { 
            display: 'inline-flex',
            width: '90%',
            margin: '10px 5%'
          }
    },
    textFieldShort: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: 70,
         ['@media (max-width:600px)']: { 
            display: 'inline-flex',
            width: '90%',
            margin: '10px 5%'
          }
    },paper:{
        margin: '20px',
        overflowX: 'scroll',
        padding: '20px'
    },
  });
  const vat = [
    {
        value: '23',
        label: '23%',
      },{
        value: '21',
        label: '21%',
      },{
          value: '135',
          label: '13.5%',
      },{
          value: '0',
          label: '0%',
      }
      
  ];
  const type = [
    {
        value: 'invoice',
        label: 'Invoice',
    },
    {
      value: 'proforma',
      label: 'Pro forma',
    },
    {
        value: 'creditnote',
        label: 'Credit Note',
      },
      
  ];


class Income extends Component{
    state = {
        invoiceNo: '',
        amount: '',
        date: '',
        docType: 'Invoice',
        vat: '23'

    }

    fieldUpdate = name => event =>{
        this.setState({
            [name]: event.target.value
        })
    }

    getInvoiceNumber = () =>{

        const invoiceNumber = firebase.database().ref().child('InvoiceNumber');
        let inviceNo = '' ;

         invoiceNumber.once('value').then((snap) =>{
                    inviceNo  = snap.val()+1;
                    return inviceNo;
                }).then( ()=>{})
    }

    // getInvoiceNumber = () =>{

    //     const invoiceNumber = firebase.database().ref().child('InvoiceNumber')

    // }

    // newIncome = ()=>{
    //     const invoiceNumber = firebase.database().ref().child('InvoiceNumber')
    //     let inviceNo = '' ;

    //     return invoiceNumber.once('value').then((snap) =>{

    //         inviceNo  = snap.val()+1;
            

    //         firebase.database().ref().update({'InvoiceNumber':inviceNo}).then((snap) => {});

    //     }).then( ()=>{

    //         const ordersRef = firebase.database().ref().child(`Orders/${this.props.orderId}/Income`);
        
            
    //         if(this.state.amount === ''){
    //             alert('Add Amount')
    //         }else if(this.state.date === ''){
    //             alert('Add Date')
    //         }else{
    //             ordersRef.push({
                
    //                 'Amount':this.state.amount,
                    
    //                 'cnValue' : 0,
    //                 'invoiceNo' : inviceNo+'-'+this.props.OrderNumber,
    //                 'vat' : this.state.vat,
    //                 'date' : this.state.date,
    //                 'paid': false
    //             });
    //         }
    //     })
    
    // }
    


    newIncome = ()=>{
        const ordersRef = firebase.database().ref().child(`Orders/${this.props.orderId}/Income`);
        if(this.state.invoiceNo === ''){
            alert('Add Invoice number');
        }else if(this.state.amount === ''){
            alert('Add Amount')
        }else if(this.state.date === ''){
            alert('Add Date')
        }else{
            ordersRef.push({
                'Amount':this.state.amount,
                'invoiceNo' : this.state.invoiceNo,
                'date' : this.state.date,
                'docType': this.state.docType,
                vat: this.state.vat
            });
        }
    
    }


    render() {

        const { classes } = this.props;
        
            return (
                
                <div className={classes.root}>

                
                <Paper className={this.props.classes.paper} elevation={0} variant="outlined" >



                    <TextField
                        id="type"
                        select
                        label="Doc type"
                        className={classes.textField}
                        value={this.state.vat}
                        onChange={this.fieldUpdate('docType')}
                        SelectProps={{
                            native: true,
                            MenuProps: {
                            className: classes.menu,
                            },
                        }}
                        helperText=""
                        
                        >
                        
                        {type.map(option => (
                            <option key={option.value} value={option.value}>
                            {option.label}
                            </option>
                        ))}
                        </TextField> 

                           <TextField
                                id="invoiceNo"
                                value={this.getInvoiceNumber()}
                                onChange={this.fieldUpdate('invoiceNo')}
                                label="Invoice No"
                                className={classes.textField}
                            />
                    

                        

                        <TextField
                            id="amount"
                            label="Amount netto"
                            value={this.state.amount}
                            onChange={this.fieldUpdate('amount')}
                            type="number"
                            className={classes.textFieldShort}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />

                        <TextField
                            id="vat"
                            select
                            label="VAT rate"
                            className={classes.textFieldShort}
                            value={this.state.vat}
                            onChange={this.fieldUpdate('vat')}
                            SelectProps={{
                                native: true,
                                MenuProps: {
                                className: classes.menu,
                                },
                            }}
                            helperText=""
                            
                            >
                            {vat.map(option => (
                                <option key={option.value} value={option.value}>
                                {option.label}
                                </option>
                        ))}
                        </TextField>


                        <TextField
                            id="date"
                            label="Date"
                            type="date"
                            defaultValue={ moment().format('L')}
                        
                            onChange={this.fieldUpdate('date',this.value)}
                            className={classes.textField}
                            InputLabelProps={{
                            shrink: true,
                            }}
                        />

                    

                    <Button  variant="outlined" color="primary" onClick={this.newIncome}>Add New</Button> 


                    </Paper>

                    <IncomeTable orderId={this.props.orderId}></IncomeTable>  

                </div>

            );
        
    }
  }

export default withStyles(styles)(Income);