export default {

    productLine: [

        {
            label: 'RC4',
            value: 'RC4',
        },
        {
            label: 'RC5 & Bulletproof',
            value: 'RC5 & Bulletproof',
        },
        {
            label: 'Secure storage units',
            value: 'Secure storage units',
        },
        {
            label: 'RC2',
            value: 'RC2',
        },
        {
            label: 'RC3',
            value: 'RC3',
        },

    ],

    RC3: [
        // { 
        //     label: 'Gate RC3',
        //     Fire: false,                

        //     Standard:{
        //         value: 372.68,
        //         visionPanelRestrictions:{
        //             left:200,
        //             right:200,
        //             top:200,
        //             bottom:200,
        //         },
        //         maxSizes:{
        //             Single: {
        //                 width: 1500,
        //                 height: 3000,
        //             },
        //             Double: {
        //                 width: 3000,
        //                 height: 3000,
        //             },
        //         },
        //         frame:{
        //             frameDepth: '95',
        //             Single:{
        //                 'type-1': {
        //                     'openingW': 60,
        //                     'openingH': 30,
        //                 },
        //                 'type-2': {
        //                     'openingW': 150,
        //                     'openingH': 70,
        //                 }
        //             },
        //             Double:{
        //                 'type-1': {
        //                     'openingW': 80,
        //                     'openingH': 30,
        //                 },
        //                 'type-2': {
        //                     'openingW': 170,
        //                     'openingH': 70,
        //                 }
        //             },
        //         }, 

        //     },

        //     description: `Description ?`,
        //     additional: ['ROM 2 + Defender','Cylinder - Double Key Class 6','Handle/Handle AHW500'],
        // },



        {
            label: 'DL 1.3 DRAGON',

            Fire:{
                value: 742.00,
                frameValue: 75.85,
                frameValueIntegration: 1.5,
                visionPanelRestrictions:{
                    left:200,
                    right:200,
                    top:200,
                    bottom:200,
                },
                maxSizes:{
                    Single: {
                        width: 2000,
                        height: 4000,

                    },
                    Double: {

                        width: 4000,
                        height: 4000,

                    },
                },
                frame:{
                    frameDepth: '95',
                    Single:{
                        'type-1': {
                            'openingW': 100,
                            'openingH': 30,
                        },
                        'type-2': {
                            'openingW': 170,
                            'openingH': 70,
                        }
                    },
                    Double:{
                        'type-1': {
                            'openingW': 140,
                            'openingH': 30,
                        },
                        'type-2': {
                            'openingW': 210,
                            'openingH': 70,
                        }
                    },
                },
            },

            Standard: {
                value: 566.61,
                frameValue: 75.85,
                frameValueIntegration: 1.5,
                visionPanelRestrictions: {
                    left: 200,
                    right: 200,
                    top: 200,
                    bottom: 200,
                },
                maxSizes: {
                    Single: {
                        width: 2000,
                        height: 4000,
                        //height: 2415,
                    },
                    Double: {
                        // width: 2020,
                        // height: 2415,
                        width: 4000,
                        height: 4500,
                    },
                },
                frame: {
                    frameDepth: '95',
                    Single: {
                        'type-1': {
                            'openingW': 80,
                            'openingH': 30,
                        },
                        'type-2': {
                            'openingW': 150,
                            'openingH': 70,
                        }
                    },
                    Double: {
                        'type-1': {
                            'openingW': 90,
                            'openingH': 30,
                        },
                        'type-2': {
                            'openingW': 170,
                            'openingH': 70,
                        }
                    },
                },

            },

            description: `Description ?`,

            additional: ['Main Lock - KALE 352R Class.6 + Defender', 'Cylinder - Double Key Class 6', 'Handle/Handle AHW500'],
        }
    ],

    RC2: [
        {
            label: 'DL 1.1',
            Fire: false,

            Standard: {
                value: 510.45,
                frameValue: 75.85,
                frameValueIntegration: 1.5,
                visionPanelRestrictions: {
                    left: 200,
                    right: 200,
                    top: 200,
                    bottom: 200,
                },
                maxSizes: {
                    Single: {
                        width: 2000,
                        height: 4000,
                    },
                    Double: {
                        width: 4000,
                        height: 4500,
                    },
                },
                frame: {
                    frameDepth: '95',
                    Single: {
                        'type-1': {
                            'openingW': 70,
                            'openingH': 30,
                        },
                        'type-2': {
                            'openingW': 150,
                            'openingH': 70,
                        }
                    },
                    Double: {
                        'type-1': {
                            'openingW': 80,
                            'openingH': 30,
                        },
                        'type-2': {
                            'openingW': 170,
                            'openingH': 70,
                        }
                    },
                },

            },

            description: `Description ?`,
            additional: ['ROM 2 + Defender', 'Cylinder - Double Key Class 6', 'Handle/Handle AHW500'],
        }
    ],

    RC4: [
        {
            label: 'DC 3.1',
            Fire: {
                value: 824.51,
                frameValue: 88.49,
                frameValueIntegration: 1.54,
                visionPanelRestrictions: {
                    left: 200,
                    right: 200,
                    top: 200,
                    bottom: 200,
                },
                maxSizes: {
                    Single: {
                        width: 2000,
                        height: 4000,
                        //height: 2620,
                        //height: 2300,
                    },
                    Double: {
                                // Tomasz 3 October
                        width: 4000,
                        height: 4500,
                        //height: 2620,
                        //height: 2540,
                    },
                },
                frame: {
                    frameDepth: '95',
                    Single: {
                        'type-1': {
                            'openingW': 80,
                            'openingH': 30,
                        },
                        'type-2': {
                            'openingW': 150,
                            'openingH': 70,
                        }
                    },
                    Double: {
                        'type-1': {
                            'openingW': 140,
                            'openingH': 30,
                        },
                        'type-2': {
                            'openingW': 210,
                            'openingH': 70,
                        }
                    },
                },
            },
            Standard: {
                value: 673.71,
                frameValue: 88.49,
                frameValueIntegration: 1.54,
                visionPanelRestrictions: {
                    left: 200,
                    right: 200,
                    top: 200,
                    bottom: 200,
                },
                maxSizes: {
                    Single: {
                        width: 2000,
                        height: 4000,
                        //height: 2415,
                    },
                    Double: {
                        // width: 2020,
                        // height: 2415,
                        width: 4000,
                        height: 4500,
                    },
                },
                frame: {
                    frameDepth: '95',
                    Single: {
                        'type-1': {
                            'openingW': 80,
                            'openingH': 30,
                        },
                        'type-2': {
                            'openingW': 150,
                            'openingH': 70,
                        }
                    },
                    Double: {
                        'type-1': {
                            'openingW': 90,
                            'openingH': 30,
                        },
                        'type-2': {
                            'openingW': 170,
                            'openingH': 70,
                        }
                    },
                },

            },

            description: `Description ?`,
            additional: [
                'Main Lock - Class.7 + Defender',

                // 'Main Lock - ISEO Multiblindo eMotion',
                'Cylinder - Double Key Class 6', 
                'Handle/Handle AHW500'
            ],
        },

        // Potwierdzic zaleznosci wymiarowe
        {
            label: 'DC 3.1 BP4',
            Fire: {
                value: 992.55,
                frameValue: 88.49,
                frameValueIntegration: 1.54,
                visionPanelRestrictions: {
                    left: 200,
                    right: 200,
                    top: 200,
                    bottom: 200,
                },
                maxSizes: {
                    Single: {
                        width: 2000,
                        height: 4000,
                        //height: 2620,
                        //height: 2300,
                    },
                    Double: {

                        width: 4000,
                        height: 4500,
                        //height: 2620,
                        //height: 2540,
                    },
                },
                frame: {
                    frameDepth: '95',
                    Single: {
                        'type-1': {
                            'openingW': 80,
                            'openingH': 30,
                        },
                        'type-2': {
                            'openingW': 160,
                            'openingH': 80,
                        }
                    },
                    Double: {
                        'type-1': {
                            'openingW': 140,
                            'openingH': 30,
                        },
                        'type-2': {
                            'openingW': 210,
                            'openingH': 80,
                        }
                    },
                },
            },
            Standard: {
                value: 841.75,
                frameValue: 88.49,
                frameValueIntegration: 1.54,
                visionPanelRestrictions: {
                    left: 200,
                    right: 200,
                    top: 200,
                    bottom: 200,
                },
                maxSizes: {
                    Single: {
                        width: 2000,
                        height: 4000,
                        //height: 2415,
                    },
                    Double: {
                        // width: 2020,
                        // height: 2415,
                        width: 4000,
                        height: 4500,
                    },
                },
                frame: {
                    frameDepth: '95',
                    Single: {
                        'type-1': {
                            'openingW': 80,
                            'openingH': 30,
                        },
                        'type-2': {
                            'openingW': 160,
                            'openingH': 80,
                        }
                    },
                    Double: {
                        'type-1': {
                            'openingW': 90,
                            'openingH': 30,
                        },
                        'type-2': {
                            'openingW': 180,
                            'openingH': 80,
                        }
                    },
                },

            },

            description: `Description ?`,
            additional: ['Main Lock - Class.7 + Defender', 'Cylinder - Double Key Class 6', 'Handle/Handle AHW500'],
        },
        {
            label: 'DC 3.1 - SS',
            Fire: {
                value: 445,
                frameValue: 88.49,
                frameValueIntegration: 1.54,
                visionPanelRestrictions: {
                    left: 200,
                    right: 200,
                    top: 200,
                    bottom: 200,
                },
                maxSizes: {
                    Single: {
                        width: 2000,
                        height: 4000,
                    },
                    Double: {

                        width: 4000,
                        height: 4500,
                    },
                },
                frame: {
                    frameDepth: '95',
                    Single: {
                        'type-1': {
                            'openingW': 80,
                            'openingH': 30,
                        },
                        'type-2': {
                            'openingW': 150,
                            'openingH': 70,
                        }
                    },
                    Double: {
                        'type-1': {
                            'openingW': 140,
                            'openingH': 30,
                        },
                        'type-2': {
                            'openingW': 210,
                            'openingH': 70,
                        }
                    },
                },
            },
            Standard: {
                value: 350.00,
                frameValue: 88.49,
                frameValueIntegration: 1.54,
                visionPanelRestrictions: {
                    left: 200,
                    right: 200,
                    top: 200,
                    bottom: 200,
                },
                maxSizes: {
                    Single: {
                        width: 2000,
                        height: 4000,
                    },
                    Double: {
                        // width: 2020,
                        // height: 2415,
                        width: 4000,
                        height: 4500,
                    },
                },
                frame: {
                    frameDepth: '95',
                    Single: {
                        'type-1': {
                            'openingW': 80,
                            'openingH': 30,
                        },
                        'type-2': {
                            'openingW': 150,
                            'openingH': 70,
                        }
                    },
                    Double: {
                        'type-1': {
                            'openingW': 90,
                            'openingH': 30,
                        },
                        'type-2': {
                            'openingW': 170,
                            'openingH': 70,
                        }
                    },
                },

            },

            description: `Description ?`,
            additional: ['Main Lock - Class.7 + Defender', 'Cylinder - Double Key Class 6', 'Handle/Handle AHW500'],
        },
        {
            label: 'DC 3.1 ZK',
            Fire: {
                value: 884.42,
                frameValue: 88.49,
                frameValueIntegration: 1.54,
                visionPanelRestrictions: {
                    left: 200,
                    right: 200,
                    top: 200,
                    bottom: 200,
                },
                maxSizes: {
                    Single: {
                        width: 2000,
                        height: 4000,
                    },
                    Double: {
                        width: 2000,
                        height: 4500,
                    },
                },
                frame: {
                    frameDepth: '95',
                    Single: {
                        'type-1': {
                            'openingW': 80,
                            'openingH': 30,
                        },
                        'type-2': {
                            'openingW': 150,
                            'openingH': 70,
                        }
                    },
                    Double: {
                        'type-1': {
                            'openingW': 140,
                            'openingH': 30,
                        },
                        'type-2': {
                            'openingW': 210,
                            'openingH': 70,
                        }
                    },
                },
            },
            Standard: {
                value: 734.22,
                frameValue: 88.49,
                frameValueIntegration: 1.54,
                visionPanelRestrictions: {
                    left: 200,
                    right: 200,
                    top: 200,
                    bottom: 200,
                },
                maxSizes: {
                    Single: {
                        width: 2000,
                        height: 4000,
                    },
                    Double: {
                        width: 4000,
                        height: 4500,
                    },
                },
                frame: {
                    frameDepth: '95',
                    Single: {
                        'type-1': {
                            'openingW': 80,
                            'openingH': 30,
                        },
                        'type-2': {
                            'openingW': 150,
                            'openingH': 70,
                        }
                    },
                    Double: {
                        'type-1': {
                            'openingW': 90,
                            'openingH': 30,
                        },
                        'type-2': {
                            'openingW': 170,
                            'openingH': 70,
                        }
                    },
                },
            },
            description: `Description ?`,
            additional: ['Main Lock - Class.7 + Defender', 'Cylinder - Double Key Class 6', 'Anti ligature handle (both sides)', 'Prison chain', 'Prison spyhole BR2"S"', 'Prison drop down hatch - 250x200mm + lock Assa Abloy', '2 Flushed slide bar latches']
        },
        {
            label: 'DC 3.1 KT-M',
            Fire: {
                value: 936.24,
                frameValue: 88.49,
                frameValueIntegration: 1.54,
                maxSizes: {
                    Single: {
                        width: 2000,
                        height: 4000,
                    },
                    Double: {
                        width: 4000,
                        height: 4500,
                    },
                },
                visionPanelRestrictions: {
                    left: 200,
                    right: 200,
                    top: 200,
                    bottom: 200,
                },
                frame: {
                    frameDepth: '95',
                    Single: {
                        'type-1': {
                            'openingW': 80,
                            'openingH': 30,
                        },
                        'type-2': {
                            'openingW': 150,
                            'openingH': 70,
                        }
                    },
                    Double: {
                        'type-1': {
                            'openingW': 140,
                            'openingH': 30,
                        },
                        'type-2': {
                            'openingW': 210,
                            'openingH': 70,
                        }
                    },
                },
            },
            Standard: {
                value: 786.24,
                frameValue: 88.49,
                frameValueIntegration: 1.54,
                visionPanelRestrictions: {
                    left: 200,
                    right: 200,
                    top: 200,
                    bottom: 200,
                },
                maxSizes: {
                    Single: {
                        width: 2000,
                        height: 4000,
                    },
                    Double: {
                        width: 4000,
                        height: 4500,
                    },
                },
                frame: {
                    frameDepth: '95',
                    Single: {
                        'type-1': {
                            'openingW': 80,
                            'openingH': 30,
                        },
                        'type-2': {
                            'openingW': 150,
                            'openingH': 70,
                        }
                    },
                    Double: {
                        'type-1': {
                            'openingW': 90,
                            'openingH': 30,
                        },
                        'type-2': {
                            'openingW': 170,
                            'openingH': 70,
                        }
                    },
                },
            },
            description: `Description ?`,
            additional: ['Main Lock - Class.7 + Defender', 'Cylinder - Double Key Class 6', 'Security seal', 'Secondary Lock - Combination lock S&G 8560 mech', 'Knob/Handle TL0403 / AHW500 Assa Abloy']
        },
        {
            label: 'DC 3.1 MB',
            Fire: {
                value: 868.54,
                frameValue: 88.49,
                frameValueIntegration: 1.54,
                maxSizes: {
                    Single: {
                        width: 2000,
                        height: 4000,
                    },
                    Double: {
                        width: 4000,
                        height: 4500,
                    },
                },
                visionPanelRestrictions: {
                    left: 200,
                    right: 200,
                    top: 200,
                    bottom: 200,
                },
                frame: {
                    frameDepth: '95',
                    Single: {
                        'type-1': {
                            'openingW': 80,
                            'openingH': 30,
                        },
                        'type-2': {
                            'openingW': 150,
                            'openingH': 70,
                        }
                    },
                    Double: {
                        'type-1': {
                            'openingW': 140,
                            'openingH': 30,
                        },
                        'type-2': {
                            'openingW': 210,
                            'openingH': 70,
                        }
                    },
                },
            },
            Standard: {
                value: 718.54,
                frameValue: 88.49,
                frameValueIntegration: 1.54,
                visionPanelRestrictions: {
                    left: 200,
                    right: 200,
                    top: 200,
                    bottom: 200,
                },
                maxSizes: {
                    Single: {
                        width: 2000,
                        height: 4000,
                    },
                    Double: {
                        width: 4000,
                        height: 4500,
                    },
                },
                frame: {
                    frameDepth: '95',
                    Single: {
                        'type-1': {
                            'openingW': 80,
                            'openingH': 30,
                        },
                        'type-2': {
                            'openingW': 150,
                            'openingH': 70,
                        }
                    },
                    Double: {
                        'type-1': {
                            'openingW': 90,
                            'openingH': 30,
                        },
                        'type-2': {
                            'openingW': 170,
                            'openingH': 70,
                        }
                    },
                },
            },
            description: `Description ?`,
            additional: ['Main Lock - Class.7 + Defender', 'Cylinder - Double Key Class 6', 'Security seal', 'Sliding padlock - outdoor', 'Slide bar latch', 'Handle/Handle AHW500']
        },
        {
            label: 'DC 3.1 BP7',

            Fire: {
                value: 1991.24,
                frameValue:  109.56,
                frameValueIntegration: 1.48,
                maxSizes: {
                    Single: {
                        width: 2000,
                        height: 4000,
                    },
                    Double: {
                        width: 4000,
                        height: 4500,
                    },
                },
                visionPanelRestrictions: {
                    left: 200,
                    right: 200,
                    top: 200,
                    bottom: 200,
                },
                frame: {
                    frameDepth: '95',
                    Single: {
                        'type-1': {
                            'openingW': 80,
                            'openingH': 30,
                        },
                        'type-2': {
                            'openingW': 160,
                            'openingH': 80,
                        }
                    },
                    Double: {
                        'type-1': {
                            'openingW': 140,
                            'openingH': 30,
                        },
                        'type-2': {
                            'openingW': 210,
                            'openingH': 80,
                        }
                    },
                },
            },
            Standard: {
                value: 1841.24,
                frameValueIntegration: 1.48,
                frameValue:  109.56,
                maxSizes: {
                    Single: {
                        width: 2000,
                        height: 4000,
                    },
                    Double: {
                        width: 4000,
                        height: 4500,
                    },
                },
                visionPanelRestrictions: {
                    left: 200,
                    right: 200,
                    top: 200,
                    bottom: 200,
                },
                frame: {
                    frameDepth: '95',
                    Single: {
                        'type-1': {
                            'openingW': 80,
                            'openingH': 30,
                        },
                        'type-2': {
                            'openingW': 160,
                            'openingH': 80,
                        }
                    },
                    Double: {
                        'type-1': {
                            'openingW': 90,
                            'openingH': 30,
                        },
                        'type-2': {
                            'openingW': 180,
                            'openingH': 80,
                        }
                    },
                },
            },
            description: ``,
            additional: ['Main Lock - Class.7 + Defender', 'Cylinder - Double Key Class 6', 'Handle/Handle AHW500']
        },

    ],

    'RC5 & Bulletproof': [
        {
            label: 'DC 3.1 DS3B',
            Fire: {
                value: 1155.32,
                frameValue:  92.70,
                frameValueIntegration: 1.52,
                maxSizes: {
                    //Zmiana ograniczenia na z polecenia Tomka 24.August.2023
                    Single: {
                        width: 2000,
                        height: 4000,
                    },
                    Double: {
                        width: 4000,
                        height: 4500,
                    },
                },
                visionPanelRestrictions: {
                    left: 250,
                    right: 250,                    top: 200, bottom: 200,
                },
                frame: {
                    frameDepth: '95',
                    Single: {
                        'type-1': {
                            'openingW': 80,
                            'openingH': 30,
                        },
                        'type-2': {
                            'openingW': 160,
                            'openingH': 80,
                        }
                    },
                    Double: {
                        'type-1': {
                            'openingW': 140,
                            'openingH': 30,
                        },
                        'type-2': {
                            'openingW': 210,
                            'openingH': 80,
                        }
                    },
                },
            },

            Standard: {
                value: 1005.32,
                frameValue:  92.70,
                frameValueIntegration: 1.52,
                maxSizes: {
                    //Zmiana ograniczenia na z polecenia Tomka 24.August.2023
                    Single: {
                        width: 2000,
                        height: 4000,
                    },
                    Double: {
                        width: 4000,
                        height: 4500,
                    },
                },
                visionPanelRestrictions: {
                    left: 200,
                    right: 200,
                    top: 200,
                    bottom: 200,
                },
                frame: {
                    frameDepth: '95',
                    Single: {
                        'type-1': {
                            'openingW': 80,
                            'openingH': 30,
                        },
                        'type-2': {
                            'openingW': 160,
                            'openingH': 80,
                        }
                    },
                    Double: {
                        'type-1': {
                            'openingW': 90,
                            'openingH': 30,
                        },
                        'type-2': {
                            'openingW': 180,
                            'openingH': 80,
                        }
                    },
                },
            },
            description: ``,
            additional: ['Main Lock - Class.7 + Defender', 'Cylinder - Double Key Class 6', 'Handle/Handle AHW500']
        },
        {
            label: 'DC 3.1 BP6',
            Fire: {
                value: 1795.77,
                frameValue:  98.32,
                frameValueIntegration: 1.52,
                maxSizes: {
                    Single: {
                        width: 2000,
                        height: 4000,
                    },
                    Double: {
                        width: 4000,
                        height: 4500,
                    },
                },
                visionPanelRestrictions: {
                    left: 200,
                    right: 200,
                    top: 200,
                    bottom: 200,
                },
                frame: {
                    frameDepth: '95',
                    Single: {
                        'type-1': {
                            'openingW': 80,
                            'openingH': 30,
                        },
                        'type-2': {
                            'openingW': 160,
                            'openingH': 80,
                        }
                    },
                    Double: {
                        'type-1': {
                            'openingW': 140,
                            'openingH': 30,
                        },
                        'type-2': {
                            'openingW': 210,
                            'openingH': 80,
                        }
                    },
                },
            },


            Standard: {
                value: 1645.77,
                frameValue:  98.32,
                frameValueIntegration: 1.52,
                maxSizes: {
                    Single: {
                        width: 2000,
                        height: 4000,
                    },
                    Double: {
                        width: 4000,
                        height: 4500,
                    },
                },
                visionPanelRestrictions: {
                    left: 200,
                    right: 200,
                    top: 200,
                    bottom: 200,
                },
                frame: {
                    frameDepth: '95',
                    Single: {
                        'type-1': {
                            'openingW': 80,
                            'openingH': 30,
                        },
                        'type-2': {
                            'openingW': 160,
                            'openingH': 80,
                        }
                    },
                    Double: {
                        'type-1': {
                            'openingW': 90,
                            'openingH': 30,
                        },
                        'type-2': {
                            'openingW': 180,
                            'openingH': 80,
                        }
                    },
                },
            },
            description: ``,
            additional: ['Main Lock - Class.7 + Defender', 'Cylinder - Double Key Class 6', 'Handle/Handle AHW500']
        },
      

    ],
    'Secure storage units': [
        {
            label: 'DC 3.1 DS3',
            Fire: {
                value: 1155.63,
                frameValue:  89.90,
                frameValueIntegration: 1.55,
                maxSizes: {
                    Single: {
                        width: 2000,
                        height: 4000,
                    },
                    Double: {
                        width: 4000,
                        height: 4500,
                    },
                },
                visionPanelRestrictions: {
                    left: 200,
                    right: 200,
                    top: 200,
                    bottom: 200,
                },
                frame: {
                    frameDepth: '95',
                    Single: {
                        'type-1': {
                            'openingW': 80,
                            'openingH': 30,
                        },
                        'type-2': {
                            'openingW': 160,
                            'openingH': 80,
                        }
                    },
                    Double: {
                        'type-1': {
                            'openingW': 140,
                            'openingH': 30,
                        },
                        'type-2': {
                            'openingW': 210,
                            'openingH': 80,
                        }
                    },
                },
            },
            Standard: {
                value: 1005.63,
                frameValue:  89.90,
                frameValueIntegration: 1.55,
                maxSizes: {
                    Single: {
                        width: 2000,
                        height: 4000,
                    },
                    Double: {
                        width: 4000,
                        height: 4500,
                    },
                },
                visionPanelRestrictions: {
                    left: 200,
                    right: 200,
                    top: 200,
                    bottom: 200,
                },
                frame: {
                    frameDepth: '95',
                    Single: {
                        'type-1': {
                            'openingW': 80,
                            'openingH': 30,
                        },
                        'type-2': {
                            'openingW': 160,
                            'openingH': 80,
                        }
                    },
                    Double: {
                        'type-1': {
                            'openingW': 90,
                            'openingH': 30,
                        },
                        'type-2': {
                            'openingW': 180,
                            'openingH': 80,
                        }
                    },
                },
            },
            description: 'We dont have that door in the offer on the website',
            additional: ['Main Lock - Class.7 + Defender', 'Cylinder - Double Key Class 6', 'Handle/Handle AHW500']
        },
    ],

    finishing: [
        {
            label: 'RAL',
            value: 0
        },
        {
            label: '2 color RAL',
            value: 72.36
        },
        {
            label: 'Mesh 10x10',
            value: 80.26
        },
        {
            label: 'Mesh 20x20',
            value: 80.26
        },
        {
            label: 'Veneer',
            value: 82.70
        }
    ],
    additional: [
        {
            label: 'Main Lock - Class.7 + Defender',
            value: 183.51,
            integration: 1.62,
            position: true,
            bottom: 790,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        },
        {
            label: 'Main Lock - ISEO Multiblindo eMotion',
            value: 1018.57,
            integration: 1.11,
            position: true,
            bottom: 790,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        },
        {
            label: 'Main Lock - ISEO Multiblindo eMotion EXIT',
            value: 1147.53,
            integration: 1.11,
            position: true,
            bottom: 790,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        },

        {
            label: 'Main Lock - KALE 352R Class.6 + Defender',
            value: 95.63,
            integration: 2.12,
            depth: 130,
            position: true,
            bottom: 790,
            allowed: [
                'Single', 'Double', 'Standard', 'Fire', 'DL 1.3 DRAGON'
            ]
        },
        {
            label: 'Main Lock - Classroom Lock',
            value: 503.98,
            integration: 1.21,
            position: true,
            bottom: 790,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        },
        {
            label: 'Main Lock - Panic bar Mottura Class.7 - Bar/Handle',
            value: 1059.66,
            integration: 1.1,
            depth: 130,
            position: true,
            bottom: 900,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        },
        {
            label: 'Main Lock - Panic bar Mottura Class.7 - Bar/(no handle outside)',
            value: 1059.66,
            integration: 1.1,
            depth: 130,
            position: true,
            bottom: 900,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        },
        {
            label: 'Main Lock - Panic bar NEMEF on both leaves',
            value: 401.38,
            integration: 1.32,
            depth: 130,
            position: true,
            bottom: 900,
            allowed: [
                'Double', 'Standard',
                'DL 1.1',
            ]
        }, {
            label: 'Main Lock - KALE 252R Class.6',
            value: 54.27,
            integration: 2.92,
            depth: 130,
            position: true,
            bottom: 900,
            allowed: [
                'Double', 'Standard',
                'DL 1.1',
            ]
        },
        {
            label: 'ROM 2 + Defender',
            value: 108.55,
            integration: 1.4,
            position: true,
            bottom: 800,
            allowed: [
                'Single', 'Double', 'DL 1.1', 'Standard', 'Gate RC3',
            ]
        },
        {
            label: 'Main Lock - Panic bar NEMEF',
            value: 203.41,
            integration: 1.63,
            position: true,
            bottom: 900,
            depth: 130,
            allowed: [
                'Single', 'Standard', 'DL 1.1'
            ]
        },
        {
            label: 'Main Lock - Handle/Handle NEMEF',
            value: 55.57,
            integration: 2.93,
            position: true,
            bottom: 900,
            depth: 70,
            allowed: [
                'Single', 'Standard', 'DL 1.1'
            ]
        },
        {
            label: 'Main Lock - FIAM X1R kl.7',
            value: 2080.04,
            integration: 1.17,
            position: true,
            bottom: 790,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        },
        {
            label: 'Main Lock - ABLOY EL160',
            value: 419.73,
            integration: 1.25,
            position: true,
            bottom: 800,
            depth: 70,
            allowed: [
                'Single', 'Double', 'DL 1.1', 'Standard',
            ]
        },
        {
            label: 'Main Lock - ABLOY EL166',
            value: 989.11,
            integration: 1.11,
            position: true,
            bottom: 800,
            depth: 70,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        }, {
            label: 'Main Lock - ABLOY EL560/561',
            value: 962.49,
            integration: 1.11,
            position: true,
            bottom: 800,
            depth: 70,
            allowed: [
                'Single', 'Double', 'DL 1.1', 'Standard',
            ]
        },
        {
            label: 'Main Lock - ABLOY EL566/567',
            value: 1766.54,
            integration: 1.11,
            position: true,
            bottom: 800,
            depth: 70,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        },
        {
            label: 'Main Lock - ABLOY EL520',
            value: 2411.12,
            integration: 1.04,
            position: true,
            bottom: 800,
            depth: 70,
            allowed: [
                'Single', 'Double', 'DL 1.1', 'Standard',
            ]
        },
        {
            label: 'Main Lock - ABLOY EL520 MP',
            value: 3147.97,
            integration: 1.03,
            position: true,
            bottom: 800,
            depth: 70,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        },




        /// -------------------- Brak ceny - nie jest to podstawowe wyposaenie , nie mamy ceny od roku w razie czego będzie na zapytanie 

        // { 

        //     label: 'Digital pad FIAM X1R',
        //     value:  135.00,
        //     integration: 1.7,
        //     position: true,
        //     bottom: 1200,
        //     allowed: [
        //         'Single','Double','Fire','Standard',
        //         'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK','DC 3.1 KT-M','DC 3.1 MB','DC 3.1 DS3B','DC 3.1 BP6','DC 3.1 BP7','DC 3.1 DS3','DC 3.1 - SS','DL 1.3 DRAGON'
        //     ]
        // }

        {
            label: 'Assa Abloy Handle AHW500/Panic Bar PBE011',
            value: 1129.71,
            integration: 1.09,
            position: true,
            bottom: 800,
            depth: 70,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DL 1.3 DRAGON', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS'
            ]
        }, {
            label: 'Assa Abloy Fixed Knob/Panic Bar PBE011',
            value: 1139.00,
            integration: 1.09,
            position: true,
            bottom: 800,
            depth: 70,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1',  'DL 1.3 DRAGON', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS'
            ]
        }, {
            label: 'Anti ligature handle (both sides)',
            value: 134.00,
            integration: 2.613,
            depth: 70,
            position: true,
            bottom: 950,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DC 3.1 ZK', 'DC 3.1', 'DC 3.1 BP4',
            ]
        },

        {
            label: 'Anti ligature handle (safe side)',
            value: 67.00,
            integration: 2.613,
            depth: 70,
            position: true,
            bottom: 950,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DC 3.1 ZK', 'DC 3.1', 'DC 3.1 BP4',
            ]
        },
        {
            label: 'Handle/Handle AHW500',
            value: 30.81,
            integration: 1.52,
            depth: 70,
            position: true,
            bottom: 950,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'Gate RC3', 'DC 3.1 - SS', 'DC 3.1 ZK', 'DL 1.3 DRAGON'
            ]
        }, {
            label: 'Handle/Handle split spindle AHW500',
            value: 44.97,
            integration: 1.76,
            depth: 70,
            position: true,
            bottom: 950,


            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'Gate RC3', 'DC 3.1 - SS', 'DC 3.1 ZK', 'DL 1.3 DRAGON'
            ]
        }, {
            label: 'Handle AHW500',
            value: 21.45,
            integration: 1.72,
            depth: 70,
            position: true,
            bottom: 950,
            inside: true,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'Gate RC3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        },
        {
            label: 'Knob/Handle TL0403 / AHW500 Assa Abloy',
            value: 63.15,
            integration: 1.5,
            depth: 80,
            position: true,
            bottom: 950,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 KT-M', 'DC 3.1 ZK', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'Gate RC3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        },
        {
            label: 'Knob/Handle split spindle TL0403 / AHW500 Assa Abloy',
            value: 75.21,
            integration: 1.45,
            depth: 80,
            position: true,
            bottom: 950,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 KT-M', 'DC 3.1 ZK', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'Gate RC3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        },
        {
            label: 'Knob/Knob TL0403 / TL0403',
            value: 72.39,
            integration: 1.5,
            depth: 80,
            position: true,
            bottom: 950,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 ZK', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'Gate RC3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        },

        {
            label: 'Knob TL0403',
            value: 32.35,
            integration: 1.53,
            depth: 80,
            position: true,
            bottom: 950,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 ZK', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'Gate RC3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        },

        {
            label: 'Push/Pull handle - active leaf',
            value: 49.37,
            integration: 1.45,
            depth: 90,
            sideDist: 150,

            position: true,
            bottom: 600,

            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        }, {
            label: 'Push/Pull handle - inactive leaf',
            value: 49.37,
            integration: 1.45,
            depth: 90,
            position: true,
            sideDist: 150,
            bottom: 700,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        },
        {
            label: 'Push/Pull handle type 2 - active leaf',
            value: 49.37,
            integration: 1.45,
            depth: 90,
            sideDist: 150,

            position: true,
            bottom: 700,

            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        }, {
            label: 'Push/Pull handle type 2 - inactive leaf',
            value: 49.37,
            integration: 1.45,
            depth: 90,
            position: true,
            sideDist: 150,
            bottom: 600,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        },

        {
            label: 'Flush pull (Donimet)',
            value: 32.00,
            integration: 1.7,
            depth: 0,
            position: true,
            bottom: 950,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        }

        , {
            label: 'Secondary Lock ROM 1',
            value: 53.91,
            integration: 2.98,
            position: true,
            bottom: 1200,

            allowed: ['Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 KT-M', 'DC 3.1 ZK', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS'
            ]
        }, {
            label: 'Secondary Lock KALE 257',
            value: 44.36,
            integration: 3.41,
            position: true,
            bottom: 1200,

            allowed: ['Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 KT-M', 'DC 3.1 ZK', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS'
            ]
        }, {
            label: 'Secondary Lock - Combination lock S&G 8560 el',
            value: 881.33,
            integration: 1.16,
            position: true,
            bottom: 1300,
            sideDist: 100,

            allowed: ['Single', 'Double', 'Fire', 'Standard',
                'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1', 'DC 3.1 - SS'

            ]
        }, {
            label: 'Secondary Lock - Combination lock S&G 8560 mech',
            value: 881.33,
            integration: 1.16,
            position: true,
            bottom: 1300,

            sideDist: 100,

            allowed: ['Single', 'Double', 'Fire', 'Standard',
                'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1', 'DC 3.1 - SS']
        },
        {
            label: 'MAUER kl.B - Secondary lock for Bank Doors',
            value: 418.58,
            integration: 1.31,
            allowed: ['Single', 'Double', 'Fire', 'Standard', 'DC 3.1 DS3']
        },
        {
            label: 'Cylinder - Double Key Class 6',
            value: 58.53,
            integration: 1,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DL 1.3 DRAGON', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'Gate RC3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        },
        {
            label: 'Cylinder - Half Key Class 6',
            value: 58.41,
            integration: 1,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'Gate RC3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        },
        {
            label: 'Cylinder - Thumbturn/Key Class 6',
            value: 67.76,
            integration: 1,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'Gate RC3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        },
        {
            label: 'Cylinder - Half Thumbturn Class 6',
            value: 67.76,
            integration: 1,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'Gate RC3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        },
        //-------------- Brak ceny
        // { 
        //     label: 'Cylinder - temporary key/key',
        //     value:  13.05,
        //     integration: 1.5,
        //     allowed: [
        //         'Single','Double','Fire','Standard',
        //         'DL 1.1','DC 3.1','DC 3.1 ZK','DC 3.1 KT-M','DC 3.1 MB','DC 3.1 DS3B','DC 3.1 BP6','DC 3.1 BP7','DC 3.1 DS3','Gate RC3','DC 3.1 - SS'
        //     ]
        // },

        {
            label: 'Maglock - 540kg',
            value: 253.28,
            integration: 1.51,
            inside: true,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        },

        /// -------- Closers

        {
            label: 'Closer - Assa Abloy DC 140 with link arm',
            value: 113.72,
            integration: 1.94,
            inside: true,
            leafChoice: true,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        },


        {
            label: 'Closer - Assa Abloy DC 300 with link arm L190',
            value: 157.91,
            integration: 1.68,
            leafChoice: true,
            inside: true,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        },
        {
            label: 'Closer - Assa Abloy DC 300 with link arm & lock L191',
            value: 179.11,
            integration: 1.60,
            leafChoice: true,
            inside: true,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        }, {
            label: 'Closer - Assa Abloy DC 300 with link arm & lock L197',
            value: 191.78,
            integration: 1.56,
            leafChoice: true,
            inside: true,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        }, {
            label: 'Closer - Abloy DC 340 with guide rail',
            value: 174.20,
            integration: 1.65,
            leafChoice: true,
            inside: true,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        }, {
            label: 'Closer - Abloy DC 340 with guide rail & lock',
            value: 183.76,
            integration: 1.58,
            leafChoice: true,
            inside: true,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        }, {
            label: 'Closer - Abloy DC 700 with guide rail',
            value: 426.96,
            integration: 1.25,
            leafChoice: true,
            inside: true,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        },
        {
            label: 'Closer - Abloy DC340 with integrated mechanical coordinator',
            value: 681.80,
            integration: 1.33,
            inside: true,
            allowed: [
                'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        }, 
        {
            label: 'Closer - Abloy DC500 with integrated mechanical coordinator',
            value: 727.00,
            integration: 1.33,
            inside: true,
            allowed: [
                'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        }, 
        {
            label: 'Closer - Abloy Abloy DC 860 concealed + with guide rail DC892',
            value: 395.95,
            integration: 1.32,
            inside: true,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        }, {
            label: 'Closer - Abloy Abloy DC 840 concealed + with guide rail DC892',
            value: 357.44,
            integration: 1.37,
            inside: true,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        }, {
            label: 'Closer - Abloy Abloy DC 500 cam action',
            value: 256.80,
            integration: 1.35,
            leafChoice: true,
            inside: true,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        }, {
            label: 'Closer - reinforcement',
            value: 55.31,
            integration: 1.08,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        }, {
            label: 'Closers - reinforcement',
            value: 110.62,
            integration: 1.08,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        },
        //-------- Brak ceny
        // { 
        //     label: 'Door opening restrictor',
        //     value:  50.00,
        //     allowed: [
        //         'Single','Double','Fire','Standard',
        //         'DL 1.1','DC 3.1','DC 3.1 ZK','DC 3.1 KT-M','DC 3.1 MB','DC 3.1 DS3B','DC 3.1 BP6','DC 3.1 BP7','DC 3.1 DS3','DC 3.1 - SS'
        //     ]
        // }
        {
            label: 'Flush bolts',
            value: 16.29,
            integration: 3.98,
            allowed: [
                'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        }, {
            label: 'Semi automatic flush bolts',
            value: 61.51,
            integration: 2.00,
            allowed: [
                'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        }, {
            label: 'Automatic flush bolts',
            value: 249.41,
            integration: 1.44,
            allowed: [
                'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        },

        {
            label: 'Security seal',
            value: 12.93,
            integration: 5,
            position: true,
            bottom: 1150,

            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS'
            ]
        },
        // { 
        //     label: 'Sliding padlock - outdoor',
        //     value:  80.00,
        //     integration: 1.2,
        //     position: true,
        //     bottom: 1200,

        //      allowed: [
        //         'Single','Double','Fire', 'Standard',
        //         'DL 1.1','DC 3.1','DC 3.1 ZK','DC 3.1 KT-M','DC 3.1 MB','DC 3.1 DS3B','DC 3.1 BP6','DC 3.1 BP7','DC 3.1 DS3','DC 3.1 - SS'
        //     ]
        // },
        // { 
        //     label: 'Sliding padlock - indoor',
        //     value:  35.00,
        //     integration: 1.2,
        //     position: true,
        //     bottom: 1200,

        //     allowed: [
        //         'Single','Double','Fire','Standard',
        //         'DL 1.1','DC 3.1','DC 3.1 ZK','DC 3.1 KT-M','DC 3.1 MB','DC 3.1 DS3B','DC 3.1 BP6','DC 3.1 BP7','DC 3.1 DS3','DC 3.1 - SS'
        //     ]
        // },

        {
            label: 'Prison chain',
            value: 74.95,
            integration: 3.12,
            position: true,
            sideDist: -8,

            bottom: 1200,

            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DC 3.1 ZK', 'DC 3.1', 'DC 3.1 - SS'
            ]
        },
        {
            label: '2 Flushed slide bar latches concealed',
            value: 74.95,
            integration: 4.57,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DC 3.1 ZK',
            ]
        },




        // { 
        //     label: 'Pull escutcheon',
        //     value:  15.00,
        //     integration: 1.4,
        //     allowed: [
        //         'Single','Double','Standard',
        //         'DL 1.1','DC 3.1','DC 3.1 ZK','DC 3.1 KT-M','DC 3.1 MB','DC 3.1 DS3B','DC 3.1 BP6','DC 3.1 BP7','DC 3.1 DS3'
        //     ]
        // },





        {
            label: 'Prison spyhole BR2"S" glass',
            value: 67.20,
            integration: 3.7,

            position: true,
            bottom: 1500,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DC 3.1 ZK', 'DC 3.1', 'DC 3.1 - SS'
            ]
        },
        {
            label: 'Spyhole Ø 20',
            value: 16.80,
            integration: 2.23,
            position: true,
            bottom: 1500,
            allowed: [
                'Single', 'Double', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        },
        {
            label: 'Spyhole Ø 30',
            value: 24.30,
            integration: 1.98,
            position: true,
            bottom: 1500,
            allowed: [
                'Single', 'Double', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        },
        {
            label: 'Spyhole - fire rated',
            value: 21.97,
            position: true,
            bottom: 1500,
            integration: 2.83,
            allowed: ['Single', 'Double', 'Fire', 'Standard',
                'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'

            ]
        },

        {
            label: 'Prison drop down hatch - 350x350mm + lock Assa Abloy',
            value: 333.40,
            position: true,
            bottom: 962,
            integration: 2.4,
            
            allowed: ['Single', 'Double', 'Fire', 'Standard', 'DC 3.1 ZK', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 - SS']
        }, 
        {
            label: 'Prison drop down hatch - 250x200mm + lock Assa Abloy',
            value: 333.40,
            position: true,
            bottom: 962,
            integration: 2.4,
            
            allowed: ['Single', 'Double', 'Fire', 'Standard', 'DC 3.1 ZK', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 - SS']
        },

        {
            label: 'Prison drop down hatch & lock Assa Abloy',
            value: 333.40,
            position: true,
            bottom: 962,
            integration: 2.4,
            allowed: ['Single', 'Double', 'Fire', 'Standard', 'DC 3.1 ZK', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 - SS']
        }, 
         
        {
            label: 'Side swing hatch - 350x350mm + lock Assa Abloy',
            value: 333.40,
            integration: 1.7,
            position: true,
            bottom: 1100,

            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS'
            ]
        },

        // { 
        //     label: 'Acoustic Drop Down Seal - Planet HS - FH+RD / 48 dB',
        //     value:  89.55,
        //     integration: 1.7,
        //     allowed: [
        //         'Single','Double','Fire','Standard',
        //         'DL 1.1','DC 3.1','DC 3.1 ZK','DC 3.1 KT-M','DC 3.1 MB','DC 3.1 DS3B','DC 3.1 BP6','DC 3.1 BP7','DC 3.1 DS3','DC 3.1 - SS'
        //     ]
        // },
        {
            label: '0-100 Drop Down Seal',
            value: 147.57,
            integration: 1.36,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        },
        {
            label: '100-150 Drop Down Seal',
            value: 176.53,
            integration: 1.3,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        },


        {
            label: 'Electric strike NC - Fire EFF 118F',
            value: 473.74,
            integration: 1.27,
            position: true,
            bottom: 700,
            allowed: ['Single', 'Double', 'Fire', 'DC 3.1', 'DC 3.1 - SS', 'DL 1.3 DRAGON']
        },
        {
            label: 'Electric strike NO - 12V',
            value: 102.35,
            integration: 1.5,
            position: true,
            bottom: 700,
            allowed: [
                'Single', 'Double', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        },
        {
            label: 'Electric strike NC - 12V',
            value: 65.13,
            integration: 1.5,
            position: true,
            bottom: 700,
            allowed: [
                'Single', 'Double', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        },
        // { 
        //     label: 'Electric strike NO (on additional hidden lock - both side door acces controll)',
        //     value:  88.80,
        //     allowed: [
        //         'Single','Double','Fire','Standard',
        //         'DL 1.1','DC 3.1','DC 3.1 ZK','DC 3.1 KT-M','DC 3.1 MB','DC 3.1 DS3B','DC 3.1 BP6','DC 3.1 BP7','DC 3.1 DS3'
        //     ]
        // },



        // {  
        //     label: 'Concealed reed switch',
        //     value:  20.89,
        //     integration: 2.55,
        //     allowed: [
        //         'Single','Double', 'Fire','Standard',
        //         'DL 1.1','DC 3.1','DC 3.1 ZK','DC 3.1 KT-M','DC 3.1 MB','DC 3.1 DS3B','DC 3.1 BP6','DC 3.1 BP7','DC 3.1 DS3','DC 3.1 - SS'
        //     ]
        // },
        {
            label: 'Concealed reed switch ALARMTECH MC-270 grade 3',
            value: 54.27,
            integration: 3.55,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        },

        {
            label: 'Armoured cable door loop ABLOY',
            value: 63.58,
            integration: 2.24,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        },
        {
            label: 'Cable',
            value: 75.72,
            integration: 1.38,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        }
        ,
        {
            label: 'Hasp and staple',
            value: 36.96,
            integration: 1.7,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS'
            ]
        },
        {
            label: 'Slide bar latch',
            value: 55.31,
            integration: 1.7,
            inside: true,
            position: true,
            bottom: 700,
            topUnit: 1,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS'
            ]
        },


        // { 
        //     label: 'Kick plate sqm',
        //     value:  85.25,
        //     integration: 1.4,
        //     position: true,
        //     sideDist: 0,
        //     bottom: 0,
        //     per:  'sqm',
        //     leafChoice: true,
        //     inside: true,
        //     allowed: [
        //         'Single','Double','Fire','Standard',
        //         'DL 1.1','DC 3.1','DC 3.1 ZK','DC 3.1 KT-M','DC 3.1 MB','DC 3.1 DS3B','DC 3.1 BP6','DC 3.1 BP7','DC 3.1 DS3','DC 3.1 - SS'
        //     ]
        // },


        // { 
        //     label: 'Push plate sqm',
        //     value:  85.25,
        //     integration: 1.4,
        //     position: true,
        //     sideDist: 0,
        //     bottom: 0,
        //     per:  'sqm',
        //     leafChoice: true,
        //     inside: true,
        //     allowed: [
        //         'Single','Double','Fire','Standard',
        //         'DL 1.1','DC 3.1','DC 3.1 ZK','DC 3.1 KT-M','DC 3.1 MB','DC 3.1 DS3B','DC 3.1 BP6','DC 3.1 BP7','DC 3.1 DS3','DC 3.1 - SS'
        //     ]
        // },

        {
            label: 'Push plate 150x300',
            value: 80.12,
            integration: 1,
            position: true,
            sideDist: 150,
            leafChoice: true,
            bottom: 1200,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        },
        {
            label: 'Kick plate 900x200',
            value: 87.88,
            integration: 1.07,
            position: true,
            sideDist: 150,
            leafChoice: true,
            bottom: 1200,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        },
        {
            label: 'Kick plate 900x300',
            value: 95.63,
            integration: 1.07,
            position: true,
            sideDist: 150,
            leafChoice: true,
            bottom: 1200,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        },
        {
            label: 'Kick plate 900x900',
            value: 178.34,
            integration: 1.07,
            position: true,
            sideDist: 150,
            leafChoice: true,
            bottom: 1200,
            allowed: [
                'Single', 'Double', 'Fire', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        },

        // { 
        //     label: 'C5 - painting',
        //     value:  90,
        //     integration: 1.35,
        //     position: true,
        //     sideDist: 0,
        //     bottom: 0,
        //     per:  'sqm',
        //     leafChoice: true,
        //     inside: true,
        //     allowed: [
        //         'Single','Double','Fire','Standard',
        //         'DL 1.1','DC 3.1','DC 3.1 ZK','DC 3.1 KT-M','DC 3.1 MB','DC 3.1 DS3B','DC 3.1 BP6','DC 3.1 BP7','DC 3.1 DS3','DC 3.1 - SS'
        //     ]
        // }





    ],
    glassExtras: [
        // { 
        //     label: 'Mirror Effect',
        //     value:   90.00,
        //     integration: 1.2,
        //     allowed: [
        //         'Single','Double','Standard',
        //         'DL 1.1','DC 3.1','DC 3.1 ZK','DC 3.1 KT-M','DC 3.1 MB','DC 3.1 DS3B','DC 3.1 BP6','DC 3.1 BP7','DC 3.1 DS3'
        //     ]
        // },
        {
            label: 'Thermal Isolation',
            value: 83.00,
            integration: 1.2,
            allowed: [
                'Single', 'Double', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 BP4', 'DC 3.1 ZK', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 DS3B', 'DC 3.1 BP6', 'DC 3.1 BP7', 'DC 3.1 DS3'
            ]
        },

    ],


    glass: [

        {
            label: "P4",
            value: 309.70,
            integration: 1.07,
            minGlassArea: 0.75,
            allowed: [
                'Single', 'Double', 'Standard', 'DL 1.1'
            ]
        },
        {
            label: 'P5',
            value: 484.60,
            integration: 1.07,
            minGlassArea: 0.5,
            allowed: [
                'Single', 'Double', 'Standard', 'DL 1.3 DRAGON'
            ]
        },
        {
            label: 'P6',
            value: 727.53,
            integration: 1.07,
            minGlassArea: 0.5,
            allowed: [
                'Single', 'Double', 'Standard',
                'DC 3.1', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 ZK', 'DC 3.1 - SS'
            ]
        },
        {
            label: 'BR4S',
            value: 1475.77,
            integration: 1.07,
            minGlassArea: 0.5,
            allowed: [
                'Single', 'Double', 'Standard',
                'DC 3.1 BP4',
            ]
        },
        {
            label: 'BR5S',
            value: 1496.90,
            integration: 1.07,
            minGlassArea: 0.5,
            allowed: [
                'Single', 'Double', 'Standard',
                'DC 3.1 DS3B',
            ]
        },
        {
            label: 'BR6S',
            value: 3362.36,
            integration: 1,
            minGlassArea: 0.5,
            allowed: [
                'Single', 'Double', 'Standard',
                'DC 3.1 BP6', 'DC 3.1 DS3B'
            ]
        },
        {
            label: 'BR6S + EI60',
            value: 3675.96,
            integration: 1.07,
            minGlassArea: 0.5,
            allowed: [
                'Single', 'Double', 'Fire',
                'DC 3.1 BP6', 'DC 3.1 DS3B'
            ]
        },
        // { 
        //     label: 'BR7',
        //     value:  870.00,
        //     integration: 1.2,
        //     allowed: [
        //         'Single','Double','Standard',
        //         'DC 3.1 BP6','DC 3.1 BP7','DC 3.1 DS3B'
        //     ]
        // },
        {
            label: 'P5+Frame+EI60',
            value:  1536.00,
            integration: 1.0,
            minGlassArea: 0.5,
            allowed: [
                'Single', 'Double', 'Fire',
                'DL 1.3 DRAGON'    
            ]
        },
        {
            label: 'P5+Frame+EI30',
            value:   938.00,
            integration: 1.0,
            minGlassArea: 0.5,
            allowed: [
                'Single', 'Double', 'Fire',
                'DL 1.3 DRAGON'    
            ]
        },
  
        {
            label: 'P6+Frame+EI60',
            value: 1733.05,
            integration: 1.07,
            minGlassArea: 0.5,
            allowed: [
                'Single', 'Double', 'Fire',
                'DC 3.1', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 ZK', 'DC 3.1 - SS'
            ]
        },
        {
            label: 'P6+Frame+EI30',
            value: 1153.46,
            integration: 1.07,
            minGlassArea: 0.5,
            allowed: [
                'Single', 'Double', 'Fire',
                'DC 3.1', 'DC 3.1 KT-M', 'DC 3.1 MB', 'DC 3.1 ZK', 'DC 3.1 - SS'
            ]
        }
    ],
    ventilation: [
        {
            label: 'Fire rated air transfer grill 400mm x 300mm',
            value: 511.74,
            integration: 1.1,
            per: 'unit',
            allowed: ['Single', 'Double', 'Fire',
                'DC 3.1', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        },
        {
            label: 'Fire rated air transfer grill 500mm x 200mm',
            value: 511.74,
            integration: 1.1,
            per: 'unit',
            allowed: ['Single', 'Double', 'Fire',
                'DC 3.1', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        },
        {
            label: 'Air vent grill',
            value: 243.72,
            integration: 1.3,
            per: 'sqm',
            allowed: ['Single', 'Double', 'Standard',
                'DL 1.1', 'DC 3.1', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        },
        {
            label: 'Round vent sleeve',
            value: 36.96,
            integration: 1.36,
            per: 'unit',
            allowed: ['Single', 'Double', 'Standard',
                'DC 3.1', 'DL 1.1', 'DC 3.1 - SS', 'DL 1.3 DRAGON'
            ]
        }

        //  ---brak ceny
        // { 
        //     label: 'Louver vent',
        //     value:  44.00,
        //     integration: 1.7,
        //     per:  'sqm',
        //     allowed: ['Single','Double','Standard',
        //     'DL 1.1','DC 3.1','DC 3.1 - SS'
        //     ]
        // }

    ]

} 