import React from 'react';
import Button from '@material-ui/core//Button';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import Print from '@material-ui/icons/Print';
import Right from '@material-ui/icons/ChevronRight';
import Left from '@material-ui/icons/ChevronLeft';
import Save from '@material-ui/icons/Save';

const styles = {
  stepperNav: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',


    '@media print': {
      display: 'none',
    }
  },
  buttonNext: {
    letterSpacing: '1px',
    color: 'white',
    backgroundColor: '#EFBE2A',
    margin: '10px 5px',
    '&:hover': {
      backgroundColor: '#EFBE2A',
    },
  },
  buttonPrev: {
    letterSpacing: '1px',
    color: 'white',
    backgroundColor: '#EFBE2A',
    margin: '10px 5px',
    '&:hover': {
      backgroundColor: '#EFBE2A',
    },
  },
  button: {
    backgroundColor: '#d60a2e',
    '&:hover': {
      backgroundColor: '#c60727',
    },

  }
}



const StepperNav = props => (

  <div className={props.classes.stepperNav}>
    { props.activeStep === props.steps.length - 1 ? (
      <div style={{gap: "1em", display: 'flex'}}>
        <Button
          disabled={props.activeStep === 0}
          onClick={props.handleBack}
          className={props.classes.backButton}
          variant="outlined"
        >
          <Left />
        </Button>

        { props.createOrderNumbeAndPushToSave !== undefined &&
          <Button color="primary" onClick={props.createOrderNumbeAndPushToSave} variant="outlined">
            <Save /> Save
          </Button>
        }

      </div>
    ) : (
      <div>
        {/* <Typography className={props.classes.instructions}>{props.steps}</Typography> */}
        <div style={{gap: "1em", display: 'flex'}}>
          <Button
            disabled={props.activeStep === 0}
            onClick={props.handleBack}
            className={props.classes.backButton}
            variant="outlined"
          >
            <Left />
          </Button>
          {props.activeStep !== props.steps.length &&
            <Button
               onClick={props.handleNext} variant="outlined">
              <Right />
            </Button>
          }


        </div>
      </div>
    )}
  </div>

)
export default withStyles(styles)(StepperNav);