import React, { Component } from 'react';

class CombinationLock extends Component{

    render() {
        
        return (
            <div id="lock">

                <div style={{ height: `${100/this.props.printRatio}px`,  width: `${100/this.props.printRatio}px`, border: '1px solid #c5c3c3', position: 'absolute',margin: '0 auto',[this.props.handleSide]: `${this.props.sideDist/this.props.printRatio}px`,bottom: `${this.props.bottom/this.props.printRatio}px`,backgroundColor:'white', borderRadius: '50%'}}>
                    <div style={{ position: 'absolute', border: '1px solid #000', width:'90%',height: '90%', borderRadius: '50%', left:'50%', top: '50%', transform: 'translate(-50%,-50%)', boxSizing: 'border-box' }}>
                        <div style={{width:'75%', height: '75%', position:'absolute', border: '1px solid #000', borderRadius: '50%', left:'50%', top: '50%', transform: 'translate(-50%,-50%)', boxSizing: 'border-box' }}></div>
                    </div>
                    {/* <div style={{ height: `60%`,  width: `${15/this.props.printRatio}px`, border: '1px solid #c5c3c3', position: 'absolute',margin: '0 auto',[this.props.handleSide]: `${-25/this.props.printRatio}px`,bottom: `20%`,}}></div> */}
                </div>

            </div>  
       )
    }
}

export default CombinationLock;