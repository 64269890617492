import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
// import { it } from 'date-fns/locale';
// import { transform } from 'popmotion';



const styles = theme => ({

  arrowTop:{
      width: 0,
      height: 0, 
      position: 'absolute',
      borderRight: '3px solid transparent',
      borderLeft: '3px solid transparent',
      borderBottom: '10px solid #d2d2d2',
      top: '0px',
      left: '-3px',
  }, 
  arrowBottom:{
      width: 0,
      height: 0, 
      position: 'absolute',
      borderLeft: '3px solid transparent',
      borderRight: '3px solid transparent',
      borderTop: '10px solid #d2d2d2',
      bottom: '0px',
      left: '-3px',
  },
});



class SideMeasurement extends Component{

    offset = () => {
      if(this.props.mtype === "frame"){
        return '-30px';
      }
      if(this.props.mtype === "wallOp"){
        return '-60px';
      }

      if(this.props.mtype === "transom"){
        return '-30px';
      }
    }

    width = () => {
      if(this.props.mtype === "frame"){
        return '30px';
      }
      if(this.props.mtype === "wallOp"){
        return '60px';
      }
    }

    top = () => {
      if(this.props.mtype === "frame"){
        return '-1px';
      }
      if(this.props.mtype === "wallOp"){
        return '-3px';
      }
    }

    height = () =>{
      if(this.props.mtype === "frame"){
        return `${(Number(this.props.height)+Number(this.props.clearOpeningHeight))/this.props.printRatio }px`;
      }

 
        if(this.props.mtype === "transom"){

          let height = Number(this.props.transomHeight);
          let frame = Number(this.props.clearOpeningHeight) * 2

          if (height < frame){
            return `${frame/this.props.printRatio }px`;
          }else{
            return `${ height/this.props.printRatio }px`;
          }  
        }

   
      

      if(this.props.mtype === "wallOp"){
        if(this.props.transom){
          return `${(Number(this.props.height)+Number(this.props.clearOpeningHeight)+ Number(this.props.transomHeight))/this.props.printRatio +2}px`;
        }else{
          return `${(Number(this.props.height)+Number(this.props.clearOpeningHeight))/this.props.printRatio +2}px`;
        }
        
      }
    }

    descPos = () =>{
      if(this.props.mtype === "frame"){
        return {background: 'white', width: '110px',left: '-55px', top: '50%', transform: 'rotate(270deg)',   position: 'absolute'  };
      }
      if(this.props.mtype === "wallOp"){
        return {'left':'-85px', background: 'white', width: '170px',top: '50%', transform: 'rotate(270deg)',   position: 'absolute' };
      }
    }

    descryption = () => {
      if(this.props.mtype === "frame"){
        return <span>Frame - {Number(this.props.height)+  Number(this.props.clearOpeningHeight) + Number(this.props.threshold)}mm</span>;
      }
      if(this.props.mtype === "wallOp"){
        if(this.props.transom){
          return <span>Structural opening - {Number(this.props.height) + Number(this.props.clearOpeningHeight)+ Number(this.props.threshold) + Number( this.props.transomHeight) + 10}mm</span>;
        }else{
          return <span>Structural opening - {Number(this.props.height) + Number(this.props.clearOpeningHeight)+ Number(this.props.threshold) + 10}mm</span>;
        }
      }
    }

    

    

    state= {}

    render() {
      const { classes } = this.props;
        return (
          
          <div 
          className='sideMesurement'
          style={{
            position: 'absolute',
            height: [this.height()], 
           
            width: [this.width()],
            borderTop: '1px solid #eee',
            borderBottom: '1px solid #eee',
            left: [this.offset()],
            top: [this.top()]
            }}>

            <div style={{
                borderLeft: '1px solid #eee',
                height: '100%',
                widyh: '1px',
                position: 'relative',
                left: 0,
            }}>

                <div className={classes.arrowTop}></div>
                <div className={classes.arrowBottom}></div>
                <small style={this.descPos()}> {this.descryption()}</small>

            </div>
      </div>
      
      
       )
    }
}

export default withStyles(styles)(SideMeasurement);