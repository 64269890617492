import React, { Component } from 'react';

// import * as firebase from 'firebase'
import firebase from "firebase/compat/app";
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import SendToProduction from '@material-ui/icons/SaveAlt';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import {userContext} from '../Context/userContext';

import SelectCustomer from '../Customers/selectCustomer'

import {QuoteTotalValue} from '../logic/GetData' 

import {date} from '../logic/date';

const styles = theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    root: {
        width: '100%',
        marginTop: theme.spacing(1) * 3,
        overflowX: 'auto',
      },
      table: {
        minWidth: 250,
    },
    highlight:{
        '&:hover':{
            background: '#f5f5f5'
        }
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
      },
  });


class Quotes extends Component{
    
    state = {
        open: false,  
        confirmLeadtime: false,
        sendToProduction: '',
        leadTime: 8,
        Customer: ''
    }

    confirm = id => event =>{

        this.setState({
            open: true,
            toBeDeleted: id,
           
        })
    }

    autocompleteUpdate = (event, values) =>{
        if(values != null){
                this.setState({ Customer: values.id})
        }else{
            this.setState({name: ''})
        }
    }

    handlechange = (name) => event =>{

        this.setState({
            [name]: event.target.value
        })

    }

    handleRemoveQuote = () => event  =>{

        const quotesRef = firebase.database().ref('Quotes');
        const deletedOrdersRef = firebase.database().ref('DeletedOrders');

        let quote = {};

        quotesRef.on('value',snap =>{
                let quotes = snap.val();
                quote = quotes[this.state.toBeDeleted];
        })

        quote.deletedBy = this.props.userEmail;
        
        // Save quote in deleted 
        deletedOrdersRef.push(quote);

        //Delete
        quotesRef.child(this.state.toBeDeleted).remove();

        this.setState({
            open:false,
            toBeDeleted: ''
        })
    }


    handleClose = () => {
        this.setState({ open: false, confirmLeadtime: false,  sendToProduction: ''});
    };

    confirmLeadTime = id =>event =>{
        this.setState({ confirmLeadtime: true, sendToProduction: id });
    }

    getAllItems = order  =>{

        let allItems = [];

        console.log(order)

        if(order.product === 'Steel Door'){





            // all additional doors
            if(order.allDoors !==  undefined){

                for(let door of order.allDoors){

                    allItems.push({
                        qty : door.qty,
                        price: door.totalPrice,
                        discount: door.discount,
                        vat: door.vat,
                        name:  door.doorName
                    })
                }
            }    

            // Additional proice items 


            if(order.additionalPriceItems !==  undefined){

                for(let item of order.additionalPriceItems){

                    allItems.push({
                        qty : item.qty,
                        price: item.price,
                        discount: item.discount,
                        vat: item.vat,
                        name:  item.text
                    })
                }
            }  


            
            // Doorrs in current state 

            allItems.push({ 
                qty : order.qty,
                price: order.totalPrice,
                discount: order.discount,
                vat: order.vat,
                name:  order.doorName
            })
        } 
        
        else if(order.product === 'Flexi Quote'){ 
            if(order.priceItems  !==  undefined){
                for(let item of order.priceItems){

                    if('break' in item){} else{

                        allItems.push({
                            qty : item.qty,
                            price: item.price,
                            discount: item.discount,
                            vat: item.vat,
                            name:  item.text
                        })
                    }
                }

            }
        }

        console.log(allItems)
        

         return(allItems)

    }


    sendToProduction = id =>event =>{

            if(this.state.Customer !== ''){


            // const ksd = firebase.app('ksd').database().ref('Orders');
            // const tdsFinanceRef = firebase.app('tdsFinance').database().ref('Orders');
            // const tdsFinanceRefOrderNo = firebase.app('tdsFinance').database().ref().child('OrderNumber');
            const tdsOrderNo = firebase.database().ref().child('OrderNumber');
            const quotesRef = firebase.database().ref('Quotes').child(id);
            const ordersRef = firebase.database().ref('Orders');

            let order;
            let orderNo = '' ;

            tdsOrderNo.once('value').then((snap) =>{

                orderNo  = Number(snap.val())+1;

                firebase.database().ref().update({'OrderNumber':orderNo}).then((snap) => {});
            }).then( ()=>{

                quotesRef.once('value',snap =>{
                    order = snap.val();   
                    if(snap){
                            order.allItems = this.getAllItems(order);                   
                    }

                }).then( ()=>{
                        order.quoteDate = moment().format('L');
                        order.leadTime = moment().add(this.state.leadTime, 'weeks').format('L');

                        let OrderNo = orderNo +'-'+  moment().year() ;

                        ordersRef.push({
                            LeadTime: this.state.leadTime,
                            DateApproved: order.quoteDate,
                            Date: order.leadTime,
                            AllItems: order.allItems,

                            OrderClosed:  false,
                            Customer: this.state.Customer,
                            Reference: order.refernceNumber,
                            OrderNumber: OrderNo,
                            Quote: order.quoteNo,  
                        })
                        // ____  Clean up ___
        
                        this.setState({
                            sendToProduction: '',
                            Customer: '',
                            leadTime: 8,
                        },()=>{this.handleClose()})
                    }
                )  
            })
        }
    }
 
    componentDidMount(){}

    render() {
        return (
            <userContext.Consumer>
                {(user) => ( <>
           
                <Table className={this.props.classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell >Quote NO</TableCell>
                           { user.access>= 5 && <TableCell >Send to production</TableCell> }
                            <TableCell >Quote type</TableCell>
                            <TableCell >Customer Name</TableCell>
                            <TableCell >Refference</TableCell>
                            <TableCell >Date</TableCell>
                            <TableCell >Value</TableCell>
                            <TableCell >Delete</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { this.props.quotes.map(quote=>(

                            <TableRow key={quote[0]} className={this.props.classes.highlight}>
                                <TableCell >
                                    <Link to={`/quote/${ quote[0] }`}>
                                        {'quoteNo' in quote[1]  ? quote[1].quoteNo : quote[0]}
                                    </Link>
                                </TableCell> 
                                {  user.access>= 5 &&
                                <TableCell >
                                    { (quote[1].product === "Flexi Quote" || quote[1].product === "Steel Door") &&
                                 
                                         <IconButton  aria-label="SendToProduction" onClick={this.confirmLeadTime(quote[0])}>
                                            <SendToProduction />
                                        </IconButton>
                                    }
                                </TableCell>
                                }
                                <TableCell >{quote[1].product}</TableCell>
                                <TableCell >{quote[1].companyName}</TableCell>
                                <TableCell >{quote[1].refernceNumber}</TableCell>
                                <TableCell >{date(quote[1].quoteDate)}</TableCell> 
                                <TableCell >{QuoteTotalValue(quote[0], this.props.quotes )}</TableCell> 
                                <TableCell >
                                    <IconButton  aria-label="Delete" 
                                    
                                        onClick={this.confirm(quote[0])}
                                        >
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>




                {/* Confirmation */}

                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    >
                    <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
                
                    <DialogActions>
                        <Button onClick={this.handleClose} variant="outlined" color="primary">
                        Cancel
                        </Button>
                        <Button onClick={this.handleRemoveQuote()} variant="outlined" color="secondary" autoFocus>
                        Delete Quote  <DeleteIcon className={this.props.classes.rightIcon} />
                        </Button>
                    </DialogActions>
                    </Dialog>

                            <Dialog
                            open={this.state.confirmLeadtime}
                            onClose={this.handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            >
                            <DialogTitle id="alert-dialog-title">{"Select customer & Confirm lead time (weeks)"}</DialogTitle>
                        
                            <DialogActions>

                                <SelectCustomer autocompleteUpdate={this.autocompleteUpdate} ></SelectCustomer>

                                <Input 
                                    
                                    value={this.state.leadTime}
                                    onChange={this.handlechange("leadTime")}
                                    type="number"
                                ></Input> 
                                <Button onClick={this.handleClose} variant="outlined" color="primary">
                                    Cancel
                                </Button>

                                <Button onClick={this.sendToProduction(this.state.sendToProduction)} variant="outlined" color="secondary" autoFocus>
                                    CONFIRM
                                </Button>
                              
                            </DialogActions>
                    </Dialog>

                    
            </>
            )}
         </userContext.Consumer>
        );
        
    }
  }
export default withStyles(styles)(Quotes);
