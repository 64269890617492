import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
// import * as firebase from 'firebase';
import firebase from "firebase/compat/app";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
    root: {
        minWidth: '350px',
    },
    textField: {
        width: '300px',
    },
})

class CustomerDetails extends Component {
    state = {
        dialog: false,
        customerDetailsRequired: false,
        companyName: ""
    }
    handleChange = (name) => event => {
        this.setState({
            [name]: event.target.value
        })
    }
    saveCustomer = () => {
        if(this.state.companyName != ""){
        const ordersRef = firebase.database().ref('Customers');
        ordersRef.push({ ...this.state })
        this.setState({ dialog: false })
        }else{
            let privatePerson = this.state.companyName

            if( this.state.contactName && this.state.contactSurname ){
                privatePerson = this.state.contactName + " " + this.state.contactSurname 
            }else if ( this.state.contactName ){
                privatePerson = this.state.contactName
            }else if( this.state.contactSurname ){
                privatePerson = this.state.contactSurname
            }


            this.setState({ 
                customerDetailsRequired: true,
                companyName: privatePerson
            })
        }
    }
    render() {
        //const { classes } = this.props;
        return (
            <>
                <Button variant="outlined" color="primary" onClick={() => { this.setState({ dialog: true }) }}>
                    Add customer
                    </Button>
                <Dialog className={this.props.classes.root} open={this.state.dialog} onClose={() => { this.setState({ dialog: false }) }} aria-labelledby="">
                    <DialogTitle id="customerDetails"></DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {/* ----------------------------------------------   Door Width */}
                            {this.state.customerDetailsRequired && <span>Company name is required, is this a private person? <br/> If it is and you have provided Name and Surname sustem pre populate that field - check if it is correct and save.<br/> </span>}
                            <TextField
                                id="companyName"
                                label="Company Name"
                                type="text"
                                className={this.props.classes.textField}
                                value={this.state.companyName}
                                onChange={this.handleChange('companyName')}
                                margin="normal"
                            />
                            {/* ----------------------------------------------   Contact Name */}
                            <TextField
                                id="contactName"
                                label="Contact Name"
                                type="text"
                                className={this.props.classes.textField}
                                value={this.state.contactName}
                                onChange={this.handleChange('contactName')}
                                margin="normal"
                            />
                            {/* ----------------------------------------------   Contact Surname */}
                            <TextField
                                id="contactSurname"
                                label="Contact Surname"
                                type="text"
                                className={this.props.classes.textField}
                                value={this.state.contactSurname}
                                onChange={this.handleChange('contactSurname')}
                                margin="normal"
                            />
                            {/* ----------------------------------------------   Contact Email */}
                            <TextField
                                id="contactEmail"
                                label="Contact Email"
                                type="email"
                                className={this.props.classes.textField}
                                value={this.state.contactEmail}
                                onChange={this.handleChange('contactEmail')}
                                margin="normal"
                            />
                            {/* ----------------------------------------------   Phone Number */}
                            <TextField
                                id="phoneNumber"
                                label="Phone Number"
                                type="text"
                                className={this.props.classes.textField}
                                value={this.state.phoneNumber}
                                onChange={this.handleChange('phoneNumber')}
                                margin="normal"
                            />
                            {/* ----------------------------------------------   First Address Line */}
                            <TextField
                                id="firstAddressLine"
                                label="First Address Line"
                                type="text"
                                className={this.props.classes.textField}
                                value={this.state.firstAddressLine}
                                onChange={this.handleChange('firstAddressLine')}
                                margin="normal"
                            />
                            {/* ----------------------------------------------   Second Address Line */}
                            <TextField
                                id="secondAddressLine"
                                label="Second Address Line"
                                type="text"
                                className={this.props.classes.textField}
                                value={this.state.secondAddressLine}
                                onChange={this.handleChange('secondAddressLine')}
                                margin="normal"
                            />
                            {/* ----------------------------------------------   Second Address Line */}
                            <TextField
                                id="city"
                                label="City"
                                type="text"
                                className={this.props.classes.textField}
                                value={this.state.city}
                                onChange={this.handleChange('city')}
                                margin="normal"
                            />
                            {/* ----------------------------------------------   County */}
                            <TextField
                                id="county"
                                label="county"
                                type="text"
                                className={this.props.classes.textField}
                                value={this.state.county}
                                onChange={this.handleChange('county')}
                                margin="normal"
                            />
                            {/* ----------------------------------------------   County */}
                            <TextField
                                id="country"
                                label="country"
                                type="text"
                                className={this.props.classes.textField}
                                value={this.state.country}
                                onChange={this.handleChange('country')}
                                margin="normal"
                            />
                            {/* ----------------------------------------------   Eircode */}
                            <TextField
                                id="eircode"
                                label="eircode"
                                type="text"
                                className={this.props.classes.textField}
                                value={this.state.eircode}
                                onChange={this.handleChange('eircode')}
                                margin="normal"
                            />
                            {/* ----------------------------------------------   VAT */}
                            <TextField
                                id="VAT"
                                label="VAT"
                                type="text"
                                className={this.props.classes.textField}
                                value={this.state.refernceNumber}
                                onChange={this.handleChange('vat')}
                                margin="normal"
                            />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={this.saveCustomer} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }
};
export default withStyles(styles)(CustomerDetails);