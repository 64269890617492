import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
// import * as firebase from 'firebase'
import firebase from "firebase/compat/app";
import moment from 'moment';
import { Redirect } from 'react-router';

import { Grid, Container } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PrintRatio from '../partials/PrintRatio';

import Quote from '../SteelDoor/Quote';
import CalcStepper from '../partials/Stepper';
import StepperNav from '../partials/StepperNav';
import Calculator from '../SteelDoor/Calculator';
import CustomerDetails from '../Customers/CustomerDetails';

import { roundToTwo } from '../logic/GetData';

import Prices from '../SteelDoor/Prices';
let uniqid = require('uniqid');

const styles = theme => ({
    root: {
        // width: '96%',
        marginTop: '50px',
        '@media print': {
            margin: '0',
            width: '100%',
        }
    },
    paper: {
        padding: '20px',
        '@media print': {
            'box-shadow': 'none !important',
            'margin': "0px",
            'padding': "0px",
        }
    },
    checked: {
        color: '#d60a2e !important',
    },
    btn: {
        backgroundColor: '#d60a2e',
        color: '#ffffff',
    },
    textField: {
        width: '100%',
    },
    delete: {
        position: 'absolute',
        top: '26px',
        right: '-34px',
    },

    additionalItem: {
        position: 'relative',
    },

    printFullWidth: {
        '@media print': {
            flexBasis: '100%',
            margin: '0px',
            padding: '4px 16px !important',
        }
    }

});


class MainComponent extends Component {



    state = { ...this.props, }



    //-----------------------------------------------------------------
    // Category Update
    //-----------------------------------------------------------------


    categoryUpdate = name => event => {

        let singleDouble = this.state.singleDouble;



        let doorType = Prices[event.target.value][0].label;
        let sqmPrice = Prices[event.target.value][0][this.state.fire].value;
        let nAdiidtional = [];
        let fire = this.state.fire;

        let frameValue = Prices[event.target.value][0][this.state.fire].frameValue;
        let frameValueIntegration = Prices[event.target.value][0][this.state.fire].frameValueIntegration;



        // console.log(Prices[event.target.value][0].Fire)

        if (doorType === "DL 1.1") { fire = "Standard" }

        Prices[event.target.value][0].additional.map(item => {

            for (let opt of Prices.additional) {

                if (opt.label === item) {
                    opt.key = uniqid();
                    nAdiidtional.push(opt)
                }
            }
        })

        this.setState({
            fire: fire,
            additional: nAdiidtional,
            sqmPrice: sqmPrice,
            doorType: doorType,
            productLine: event.target.value,
            frameValue: frameValue,
            frameValueIntegration: frameValueIntegration
        }, () => (
            this.clearOpening(),
            this.doorSizeRestrictions(),
            this.visionPanelMaxSize(),
            this.availableGlass(),
            this.availableVentilation(),
            this.setGrade()

        ))
    }

    //-----------------------------------------------------------------
    // Set Grade
    //-----------------------------------------------------------------

    setGrade = () => {
        let grade = this.state.grade;
        let downgrade = this.state.additional.findIndex(x => x.label === 'Main Lock - Panic bar NEMEF on both leaves');
        let downgrade2 = this.state.additional.findIndex(x => x.label === 'Main Lock - Panic bar NEMEF');
        let downgrade3 = this.state.additional.findIndex(x => x.label === 'Main Lock - Handle/Handle NEMEF');

        if (this.state.productLine === 'RC5 & Bulletproof') {
            grade = "RC5";
        } else if (this.state.productLine === 'Secure storage units') {
            grade = "Class ||";
        } else if (this.state.productLine === 'RC4') {
            grade = "RC4";
        } else if (this.state.productLine === 'RC2') {
            grade = "RC2";
        } else if (this.state.productLine === 'RC3') {
            grade = "RC3";
        }

        if (downgrade !== -1 || downgrade2 !== -1 || downgrade3 !== -1) {
            grade = "";
        }

        this.setState({
            grade: grade,
        })

        return grade;
    }

    //-----------------------------------------------------------------
    // Set First Product
    //-----------------------------------------------------------------

    // add aironmapngery when page loads to item that is curently set a defoult 

    setFirstProduct = (line, product) => {

        let index = Prices[line].findIndex(x => x.label === product);
        let doorType = Prices[line][index].label;
        let additionalN = [];
        let sqmPrice = Prices[line][index][this.state.fire].value
        let frameValue = Prices[line][index][this.state.fire].frameValue;
        let frameValueIntegration = Prices[line][index][this.state.fire].frameValueIntegration;

        Prices[line][index].additional.map(item => {

            let prices = JSON.parse(JSON.stringify(Prices.additional));

            for (let opt of prices) {

                if (opt.label === item) {

                    opt.key = uniqid();

                    additionalN.push(opt);
                }
            }
        })



        this.setState({
            effectiveOpening: 0,
            grade: line,
            doorType: product,
            additional: additionalN,
            sqmPrice: sqmPrice,
            frameValue: frameValue,
            frameValueIntegration: frameValueIntegration
        }, () => (
            this.calculatePrice('setFirstProduct'),
            this.doorSizeRestrictions(),
            this.setGrade(),
            this.ironmongeryForDouble()

        ))

    }



    //-----------------------------------------------------------------
    // Handle Door Type
    //-----------------------------------------------------------------

    handleDorrType = (name, value, pList,) => event => {

        let nAdiidtional = [];

        Prices[pList][event.target.selectedIndex].additional.map(item => {

            for (let opt of Prices.additional) {
                if (opt.label === item) {

                    opt.key = uniqid();
                    nAdiidtional.push(opt)
                }
            }
        })

        this.setState({
            [name]: Prices[pList][event.target.selectedIndex].label,
            [value]: event.target.value,
            description: Prices[pList][event.target.selectedIndex].description,
            frame: Prices[pList][event.target.selectedIndex][this.state.fire].frame,
            visionPanelRestrictions: Prices[pList][event.target.selectedIndex][this.state.fire].visionPanelRestrictions,
            additional: nAdiidtional
        }, () => (
            this.calculatePrice('handleDorrType'),
            this.clearOpening(),
            this.doorSizeRestrictions(),
            this.ironmongeryForDouble(),
            this.availableGlass(),
            this.availableVentilation()
        ))
    }

    //check If Door Has Fire Option



    handleFire = () => event => {

        let fire = this.state.fire;
        let leftPanel = this.state.leftPanel;
        let leftPanelWidth = this.state.leftPanelWidth;
        let rightPanel = this.state.rightPanel;
        let rightPanelWidth = this.state.rightPanelWidth;

        if (fire === 'Standard') { fire = 'Fire'; } else { fire = 'Standard'; }


        if (fire === 'Fire') {

            leftPanel = false;
            leftPanelWidth = 0;
            rightPanel = false;
            rightPanelWidth = 0;

        } else {

        }

        this.setState({
            fire: fire,
            leftPanel: leftPanel,
            leftPanelWidth: leftPanelWidth,
            rightPanel: rightPanel,
            rightPanelWidth: rightPanelWidth
        }, () => (this.setFireValues(), this.availableGlass(), this.availableVentilation()))
    }

    setFireValues = () => {

        let index = Prices[this.state.productLine].findIndex(x => x.label === this.state.doorType);

        let sqmPrice = Prices[this.state.productLine][index][this.state.fire].value;
        let clearOpeningWidth = Prices[this.state.productLine][index][this.state.fire].frame[this.state.singleDouble][this.state.frameType].openingW;

        let maxWidth = Prices[this.state.productLine][index][this.state.fire].maxSizes[this.state.singleDouble].width;
        let maxHeight = Prices[this.state.productLine][index][this.state.fire].maxSizes[this.state.singleDouble].height;

        let ventilationList = this.state.ventilationList;
        if (this.state.fire === 'fire') {
            ventilationList = [];
        }
        this.setState({
            ventilationList: ventilationList,
            sqmPrice: sqmPrice,
            maxWidth: maxWidth,
            maxHeight: maxHeight,
            clearOpeningWidth: clearOpeningWidth
        }, () => { this.doorSizeRestrictions() })
    }

    //Door Size
    handleDoorSize = name => event => {

        this.setState({
            [name]: event.target.value,
        }, () => {
            this.doorSizeRestrictions()


        })
    }

    handlePanelSize = name => event => {


        // Check restrictions here

        let newVal = Number(event.target.value)
        // console.log(newVal, typeof (newVal))

        // Zmiana z 800 na 2000 na prosbe Tomka 04.23
        if (name === 'transomHeight') {
            if (newVal > 3000) {
                newVal = 3000
            }
        }

        if (name === 'leftPanelWidth' || name === 'rightPanelWidth') {
            if (newVal > 3000) {
                newVal = 3000
            }
        }

        this.setState({
            [name]: newVal,
        }, () => {
            this.calculatePrice("handlePanelSize")
        })
    }

    handleSingleDouble = name => event => {

        this.setState({
            [name]: event.target.value,
        }, () => (
            this.doorSizeRestrictions(),
            this.clearOpening(),
            this.visionPanelMaxSize(),
            this.ironmongeryForDouble()
        ))
    }

    // Update value of regular fields and checkboxes

    handleChange = name => event => {

   


        if (name === 'transom') {
            let transomHeight = this.state.transomHeight

            if (transomHeight > 0) {
                transomHeight = 0
            } else {
                transomHeight = 500
            }



            this.setState({
                [name]: event.target.value,
                transomHeight: transomHeight
            }, () => (this.calculatePrice("handleChange", "transom")))
        }else  if (name === 'leftPanel') {
            let leftPanelWidth = this.state.leftPanelWidth

            if (leftPanelWidth > 0) {
                leftPanelWidth = 0
            } else {
                leftPanelWidth = 500
            }

            this.setState({
                [name]: event.target.value,
                leftPanelWidth: leftPanelWidth
            }, () => (this.calculatePrice("handleChange", "leftPanel")))
        }else if (name === 'rightPanel') {
            let rightPanelWidth = this.state.rightPanelWidth

            if (rightPanelWidth > 0) {
                rightPanelWidth = 0
            } else {
                rightPanelWidth = 500
            }

            this.setState({
                [name]: event.target.value,
                rightPanelWidth: rightPanelWidth
            }, () => (this.calculatePrice("handleChange", "rightPanel")))
        }else if (name === 'threshold') {
            if (event.target.value === '0') {
                this.setState({
                    snackBar: true,
                    snackBarText: `Add drop down Seal`
                })
            }
        }
        
        if (event.target.type === "checkbox") {

     

            this.setState({
                [name]: !this.state[name]
            }, () => (this.calculatePrice("handleChange", "checkbox")))

        } else {

         

            this.setState({
                [name]: event.target.value
            }, () => (this.calculatePrice()))
        }


    }

    handleFrameType = name => event => {

        this.setState({
            [name]: event.target.value
        }, () => (this.clearOpening()))

    }

    //------Extar over



    handleExtraOver = (id, field) => event => {
        let extraOver = this.state.extraOver;
        let index = extraOver.findIndex(x => x.key === id);

        extraOver[index][field] = event.target.value;

        this.setState({
            extraOver: extraOver
        }, () => (this.calculatePrice('handleExtraOver')))
    }

    //--add//

    handleAddExtraOver = () => {

        let uid = uniqid();
        this.setState({
            extraOver: this.state.extraOver.concat({ key: uid, label: '', value: '' })
        })

    }

    handlePushItemDown = (list, id) => event => {

        let doors = this.state[list];
        let index = doors.findIndex(x => x.key === id);
        let item = doors[index];

        doors.splice(index, 1);
        doors.splice(index + 1, 0, item);

        this.setState({
            [list]: doors
        })
    }

    changeDoorRatio = (list, id) => event => {
        let doors = this.state[list];
        let index = doors.findIndex(x => x.key === id);
        doors[index].printRatio = event.target.value;
        this.setState({
            allDoors: doors
        })

    }

    handleRemoveItemFromList = (list, id) => event => {

        let remAdd = this.state[list];
        // console.log(list, id, remAdd)



        let index = remAdd.findIndex(x => x.key === id);

        remAdd.splice(index, 1);

        if (list === 'visionPanels') {
            this.setState({
                list: remAdd
            }, () => (
                this.calculatePrice()
            ))
        } else {
            this.setState({
                list: remAdd
            }, () => (this.calculatePrice('handleRemoveItemFromList'), this.handleWidth('deleted')))
        }



    }

    handleWidth = (optionIndex) => {




        let handleWidth = this.state.handleWidth;

        // console.log(handleWidth, optionIndex)

        // "deleted" value is passed by functin if item was removed from the list

        //console.log(optionIndex)

        //if itelem was changed than check if it has depth value




        if (optionIndex !== "deleted") {



            if (typeof (Prices.additional[optionIndex].depth) !== 'undefined') {

                handleWidth = Prices.additional[optionIndex].depth;
            }
        }
        else {
            //if itelem deleted check all list if item with depth exist if not it sets depth to 0
            this.state.additional.map(opt => {

                if (typeof (opt.depth) !== 'undefined') {
                    handleWidth = opt.depth;
                } else {
                    handleWidth = 0;
                }

            })
        }

        this.setState({
            handleWidth,
        })

    }

    handleAdditionalValue = id => event => {





        // let handleWidth = this.state.handleWidth;
        let additional = this.state.additional;

        let i = 0;

        additional.map(opt => {



            if (opt.key === id) {



                //let optionIndex = Prices.additional.findIndex(x=> x.label === event.target.selectedOptions[0].label);
                let index = Prices.additional.findIndex(x => x.label === event.target.selectedOptions[0].label);

                if (index !== -1) {
                    this.handleWidth(index);
                }



                let addons = (addonName) => {
                    let doorloop = Prices.additional.findIndex(x => x.label === addonName);
                    let addon = Prices.additional[doorloop];
                    addon.key = uniqid() + new Date();
                    additional.push(addon);
                }


                // zmiana na rkz
                if (event.target.selectedOptions[0].label.includes('integrated mechanical coordinator')) {


                    let indexOld = additional.findIndex(x => x.label === 'Flush bolts');
                    console.log(indexOld)
                    if (indexOld !== -1) {

                        let index3 = Prices.additional.findIndex(x => x.label === 'Semi automatic flush bolts');

                        console.log(index3)

                        additional[indexOld] = {
                            key: uniqid(),
                            value: Prices.additional[index3].value,
                            label: Prices.additional[index3].label,
                            integration: Prices.additional[index3].integration
                        }

                    } else {
                        addons('Semi automatic flush bolts');

                    }

                    let closersReinfExist = additional.findIndex(x => x.label === 'Closers - reinforcement');
                    if (closersReinfExist === -1) {
                        addons('Closers - reinforcement');
                    }

                    // let semiAutoExist = additional.findIndex(x=> x.label === 'Semi automatic flush bolts');
                    // if(semiAutoExist === -1){
                    //     addons('Semi automatic flush bolts');
                    // }

                }

                // zmiana z rkz na normalny

                if (event.target.selectedOptions[0].label.includes('Closer') && !event.target.selectedOptions[0].label.includes('integrated mechanical coordinator')) {

                    let closerReinfExist = additional.findIndex(x => x.label === 'Closer - reinforcement');

                    if (closerReinfExist === -1) {
                        addons('Closer - reinforcement',);
                    }
                }

                //replace flushbolts with semi automatic flushbolts


                if (event.target.selectedOptions[0].label.includes('Main Lock - FIAM')) {

                    addons('Armoured cable door loop ABLOY');
                    //addons('Digital pad FIAM X1R');
                    addons('Knob/Knob TL0403 / TL0403');
                    addons('Cable EA 218');
                }

                if (
                    event.target.selectedOptions[0].label.includes('EL560') ||
                    event.target.selectedOptions[0].label.includes('EL561') ||
                    event.target.selectedOptions[0].label.includes('EL566') ||
                    event.target.selectedOptions[0].label.includes('EL567') ||
                    event.target.selectedOptions[0].label.includes('eMotion') ||
                    event.target.selectedOptions[0].label.includes('EL166') ||
                    event.target.selectedOptions[0].label.includes('EL52 0MP')

                ) {
                    let cable = additional.findIndex(x => x.label === 'Cable');
                    if (cable === -1) {
                        addons('Cable');
                    }
                }

                if (event.target.selectedOptions[0].label.includes('Main Lock - FIAM')) {
                    this.setState({
                        snackBar: true,
                        snackBarText: `Knob/Handle or Knob/Handle check with the customer`
                    })
                }

                //console.log( Prices.additional[optionIndex].integration)

                additional[i] = {
                    key: id,
                    value: Prices.additional[index].value,
                    label: Prices.additional[index].label,
                    integration: Prices.additional[index].integration
                }
                // Add unique values like position etc.

                if (Prices.additional[index].depth) {
                    additional[i].depth = Prices.additional[index].depth;

                }



                if (Prices.additional[index].position) {

                    additional[i].position = Prices.additional[index].position;
                    additional[i].bottom = Prices.additional[index].bottom;
                    if (Prices.additional[index].sideDist) {
                        additional[i].sideDist = Prices.additional[index].sideDist;
                    }

                }

                if (Prices.additional[index].per) {
                    additional[i].per = Prices.additional[index].per;
                    additional[i].width = 0;
                    additional[i].height = 0;
                    additional[i].price = 0;
                }

                if (Prices.additional[index].inside) {
                    additional[i].inside = Prices.additional[index].inside;
                }

                if (Prices.additional[index].leafChoice) {
                    additional[i].leafChoice = Prices.additional[index].leafChoice;
                }

            }
            i++;
        })

        //console.log(additional)

        this.setState({
            additional,
        }, () => (this.calculatePrice('handleAdditionalValue'), this.setGrade()))

    }

    handleInsideOutside = (id) => event => {
        const additional = this.state.additional;

        let index = additional.findIndex(x => x.key === id);

        additional[index].inside = !additional[index].inside;

        this.setState({
            additional: additional
        })
    }

    handleActiveInactive = (id) => event => {
        const additional = this.state.additional;

        let index = additional.findIndex(x => x.key === id);

        additional[index].leafChoice = !additional[index].leafChoice;

        this.setState({
            additional: additional
        })
    }

    handleAdditionalSqmSize = (id, field) => event => {

        const additional = this.state.additional;
        let index = additional.findIndex(x => x.key === id);

        additional[index][field] = event.target.value;

        this.setState({
            additional: additional
        }, () => (this.handleAdditionalSqmPrice(id)))
    }

    handleAdditionalSqmPrice = (id) => {

        const additional = this.state.additional;
        let index = additional.findIndex(x => x.key === id);

        let width = 0;
        let height = 0;

        if ('width' in additional[index]) {
            width = additional[index].width;
        } else {
            width = 0;
        }

        if ('height' in additional[index]) {
            height = additional[index].height;
        } else {
            height = 0;
        }

        additional[index].price = ((width * height) * additional[index].value / 1000000);

        this.setState({
            additional: additional
        }, () => { this.calculatePrice('handleAdditionalSqmPrice') })

    }

    handleAdditionalPosition = (id, direction) => event => {

        const additional = this.state.additional;
        let index = additional.findIndex(x => x.key === id);

        additional[index][direction] = event.target.value;

        this.setState({
            additional: additional
        })

    }

    // Adds item to additional equipment
    //--add
    handleAdditional = () => {
        let uid = uniqid();

        this.setState({
            additional: this.state.additional.concat({ key: uid, label: Prices.additional[0].label, value: Prices.additional[0].value, integration: Prices.additional[0].integration })
        }, () => { this.calculatePrice("handleAdditional") })

    }
    // --------------------------------------------
    // -----------------------  Additional Price Items
    // --------------------------------------------



    handelAddPriceItem = () => {

        let uid = uniqid();
        this.setState({
            additionalPriceItems: this.state.additionalPriceItems.concat({ key: uid, text: "Supply and Install", price: '0.00', vat: '23', qty: 1, discount: 0, subitems: [], globalDiscount: false, days: 15 })
        })
        console.log(this.state.additionalPriceItems)
    }

    handleQuillTextNotes = (name) => event =>{

        let newText= event

        this.setState({
            [name]: newText
        })


    }


    handleQuillText = (ob) => event => {

        let list = this.state[ob.list];
        let optionIndex = list.findIndex(x => x.key === ob.key);

        list[ob.index].text = event;

        this.setState({
            [list]: list
        })

    }

    handelAdditionalPriceItem = (id, name, list) => event => {

        let li = this.state[list];
        let optionIndex = li.findIndex(x => x.key === id);
        li[optionIndex][name] = event.target.value;

        this.setState({
            [list]: li
        }, this.calculatePrice('handelAdditionalPriceItem'))
    }

    handleRemovePriceItem = id => event => {
        let remAdd = this.state.additionalPriceItems;
        let i = 0;
        remAdd.map(rm => {
            if (rm.key === id) {
                remAdd.splice(i, 1);
            }
            i++;
        })

        this.setState({
            additionalPriceItems: remAdd
        })
    }

    handleglobalDiscount = (list, id) => event => {

        let additionalPriceItems = this.state[list];

        console.log(additionalPriceItems)
        let index = additionalPriceItems.findIndex(x => x.key === id);
        let item = additionalPriceItems[index];

        console.log(list, id);

        additionalPriceItems[index].globalDiscount = !additionalPriceItems[index].globalDiscount;

        this.setState({
            additionalPriceItems: additionalPriceItems
        })

    }

    // --------------------------------------------
    // -----------------------  Call back functions
    // --------------------------------------------

    availableVentilation = () => {

        let availableVentilation = []

        {
            Prices.ventilation.map(option => {


                if (option.allowed.indexOf(this.state.doorType) !== -1) {

                    if (this.state.fire === 'Fire' && option.allowed.indexOf(this.state.fire) !== -1) {

                        availableVentilation.push({ value: option.value, label: option.label });

                    } else if ((this.state.fire === 'Standard' && option.allowed.indexOf(this.state.fire) !== -1)) {

                        availableVentilation.push({ value: option.value, label: option.label });

                    }
                }
            })
        }

        let ventilationType = '';

        if (availableVentilation.length > 0) {

            ventilationType = availableVentilation[0].label;
        }
        this.setState({
            ventilationType: ventilationType,
            availableVentilation: availableVentilation
        }, () => {
            this.calculatePrice('availableVentilation')
        })
    }

    handleVentilationType = () => event => {

        if (this.state.availableVentilation.length > 0) {

            let label = event.target.selectedOptions[0].label;

            this.setState({
                ventilationType: label
            })
        }

    }


    handleAddVentilation = (fixingPlace) => event => {

        let index = Prices.ventilation.findIndex(a => a.label === this.state.ventilationType);

        let uid = uniqid();

        this.setState({
            ventilationList: this.state.ventilationList.concat({
                key: uid,
                fixing: fixingPlace,
                per: Prices.ventilation[index].per,
                label: Prices.ventilation[index].label,
                height: 0,
                width: 0,
                left: 0,
                bottom: 0,
                mainDoor: true,
                value: Prices.ventilation[index].value,
                integration: Prices.ventilation[index].integration,
            })
        }, () => { this.calculatePrice('handleAddVentilation') })
    }





    // Check maximum size of the door and adjust calculatoe accordingly
    doorSizeRestrictions = () => {

        let index = Prices[this.state.productLine].findIndex(x => x.label === this.state.doorType);

        let width = this.state.width;
        let height = this.state.height;
        let doubleDoorMainLeafwidth = this.state.doubleDoorMainLeafwidth;

        let maxWidth = Prices[this.state.productLine][index][this.state.fire].maxSizes[this.state.singleDouble].width;
        let maxHeight = Prices[this.state.productLine][index][this.state.fire].maxSizes[this.state.singleDouble].height;

        if (maxWidth < width) {
            width = maxWidth;
        }


        if (doubleDoorMainLeafwidth > maxWidth) {
            doubleDoorMainLeafwidth = width - 200;
        }
        if (doubleDoorMainLeafwidth === 0) {
            doubleDoorMainLeafwidth = width / 2;
        }

        // if(doubleDoorMainLeafwidth > width ){
        //     doubleDoorMainLeafwidth = width ;

        // }

        if (maxHeight < height) {
            height = maxHeight;
        }

        this.setState({
            height: height,
            width: width,
            maxHeight: maxHeight,
            maxWidth: maxWidth,
            doubleDoorMainLeafwidth: doubleDoorMainLeafwidth,
        }, () => (
            this.visionPanelMaxSize(),
            this.calculatePrice('doorSizeRestrictions')
        ))

    }
    // Additional hardware for double doors

    ironmongeryForDouble = () => {

        let additional = this.state.additional;


        if (this.state.singleDouble === 'Double') {

            for (let opt of Prices.additional) {
                if (opt.label === 'Flush bolts') {

                    opt.key = uniqid();
                    additional.push(opt)
                }
            }

        } else {
            let index = additional.findIndex(x => x.label === 'Flush bolts');
            if (index !== -1) {
                additional.splice(index, 1);
            }
            let index2 = additional.findIndex(x => x.label === 'Semi automatic flush bolts');

            if (index2 !== -1) {
                additional.splice(index2, 1);
            }

            let index3 = additional.findIndex(x => x.label === 'Closer - Abloy RKZ z samozamykaczem DC 340 z szyną');

            if (index3 !== -1) {
                additional.splice(index3, 1);
            }
        }

        this.setState({
            additional: additional,
        }, () => (
            this.doorSizeRestrictions(),
            this.visionPanelMaxSize(),
            this.calculatePrice('ironmongeryForDouble')))
    }

    clearOpening = () => {
        let index = Prices[this.state.productLine].findIndex(x => x.label === this.state.doorType);
        let clearOpeningWidth = Prices[this.state.productLine][index][this.state.fire].frame[this.state.singleDouble][this.state.frameType].openingW;
        let clearOpeningHeight = Prices[this.state.productLine][index][this.state.fire].frame[this.state.singleDouble][this.state.frameType].openingH;

        this.setState({

            clearOpeningWidth: clearOpeningWidth,
            clearOpeningHeight: clearOpeningHeight,
            frameDepth: this.state.frame.frameDepth,
        }, () => (this.calculatePrice('clearOpening')))
    }




    handleVents = (name, id = 'test') => event => {

        let ventilationList = this.state.ventilationList;

        let index = this.state.ventilationList.findIndex(a => a.key === id);

        if (name === "mainDoor") {
            ventilationList[index].mainDoor = !ventilationList[index].mainDoor;

            if (ventilationList[index].glassWidth > this.state.maxVPWidth) {

                this.openSnackbar('Vision panel is to wide', 'left');

                ventilationList[index].glassWidth = this.state.maxVPWidth;

            }
            if (ventilationList[index].glassWidth > this.state.maxVPWidth2) {

                this.openSnackbar('Vision panel is to wide', 'left');

                ventilationList[index].glassWidth = this.state.maxVPWidth2;

            }

        }

        if (name === "left") {
            ventilationList[index].left = event.target.value;
        }

        if (name === "bottom") {
            ventilationList[index].bottom = event.target.value;
        }


        if (name === "ventHeight") {
            if (event.target.value > this.state.maxVPHeight) {
                //this.openSnackbar('Vision panel is to heigh', 'left');
                ventilationList[index].height = this.state.maxVPHeight;
            } else {
                ventilationList[index].height = event.target.value;
            }
        }

        this.setState({

            ventilationList: ventilationList
        }, () => (this.calculatePrice()))
    }






    // ------- --- --- --- ---
    // ---- -- --- Chek if ---
    // ------- --- --- --- ---





    //--------------------------------------------------------------------------------------------------------------------------------
    //----------------------------------------------------------------------------------------------------- *** Vision Panels  ***
    //--------------------------------------------------------------------------------------------------------------------------------

    availableGlass = () => {

        let availableGlass = []

        {
            Prices.glass.map(option => {

                //console.log(option)

                if (option.allowed.indexOf(this.state.doorType) !== -1) {

                    if (this.state.fire === 'Fire' && option.allowed.indexOf(this.state.fire) !== -1) {

                        availableGlass.push({ value: option.value, label: option.label, integration: option.integration, minGlassArea: option.minGlassArea });

                    } else if ((this.state.fire === 'Standard' && option.allowed.indexOf(this.state.fire) !== -1)) {

                        availableGlass.push({ value: option.value, label: option.label, integration: option.integration, minGlassArea: option.minGlassArea });

                    }

                }
            })
        }


        let glass = '';
        let glassValue = '';
        let glassIntegration = '';
        let minGlassArea = 0.5;
        let visionPanel = this.state.visionPanel;

        if (availableGlass.length > 0) {

            glass = availableGlass[0].label;
            glassValue = availableGlass[0].value;
            glassIntegration = availableGlass[0].integration;
            minGlassArea = availableGlass[0].minGlassArea;

        } else {
            visionPanel = false;
        }

        this.setState({
            visionPanel: visionPanel,
            availableGlass: availableGlass,
            glass: glass,
            glassValue: glassValue,
            glassIntegration: glassIntegration,
            minGlassArea: minGlassArea
        }, () => { this.calculatePrice() })

    }





    addVisonPanelToPanelOrDoor = (fixingPlace,) => () => {
        let uid = uniqid();

        let visionPanels = this.state.visionPanels

        visionPanels.push({
            key: uid,
            glassHeight: 0,
            glassWidth: 0,
            left: 0,
            bottom: 0,
            mainDoor: true,
            fixing: fixingPlace,
        })

        this.setState({
            visionPanels
        },
            //this.handleVisionPanelsPrice(),
            this.calculatePrice()
        )
    }






    // ------   Set limits fot vision panels an vents


    visionPanelMaxSize = () => {
        let mxW = '';
        let mxH = '';
        let mxW2 = '';


        if (this.state.height > (this.state.visionPanelRestrictions.bottom + this.state.visionPanelRestrictions.top)) {
            mxH = this.state.height - (this.state.visionPanelRestrictions.bottom + this.state.visionPanelRestrictions.top);
        }

        if (this.state.singleDouble === "Single") {
            if (this.state.width > (this.state.visionPanelRestrictions.right + this.state.visionPanelRestrictions.left)) {
                mxW = this.state.width - (this.state.visionPanelRestrictions.right + this.state.visionPanelRestrictions.left);
            }
        } else if (this.state.singleDouble === "Double") {
            if (this.state.doubleDoorMainLeafwidth > (this.state.visionPanelRestrictions.right + this.state.visionPanelRestrictions.left)) {
                mxW = this.state.doubleDoorMainLeafwidth - (this.state.visionPanelRestrictions.right + this.state.visionPanelRestrictions.left);
                mxW2 = parseInt(this.state.width) - this.state.doubleDoorMainLeafwidth - this.state.visionPanelRestrictions.left - this.state.visionPanelRestrictions.right;
            }
        }

        this.setState({
            maxVPHeight: mxH,
            maxVPWidth: mxW,
            maxVPWidth2: mxW2,
        },
            // ------------------------------------------------------ Usunąć jeśli nie powoduje zadnych komplikacji 
            // () => { this.visionPanelMaxSizeCheck() }
        )

    }
    // ------------------------------------------------------------------------------------------------------------------------------------------------
    // ------------------------------------------------------------------------------------------------------------------------------------------------ 
    // nie jestem pewny czy ta funkcja jest nadal potrzebna moze tylko gdyby zaleznosci wymiarowe sie zmieniły i stara wycena była by odswizana 
    // ------------------------------------------------------------------------------------------------------------------------------------------------
    // ------------------------------------------------------------------------------------------------------------------------------------------------


    // visionPanelMaxSizeCheck = () => {


    //     let visionPanels = this.state.visionPanels

    //     visionPanels.map(panel => {

    //         if (panel.glassWidth > this.state.maxVPWidth) {
    //             panel.glassWidth = this.state.maxVPWidth;
    //         }

    //         if (panel.glassHeight > this.state.maxVPHeight) {
    //             panel.glassHeight = this.state.maxVPHeight;
    //         }

    //     })



    //     this.setState({

    //         visionPanels: visionPanels
    //     }, () => { this.handleVisionPanelsPrice() })


    // }



    // ------  Choosing glass type


    handleGlassType = name => event => {

        if (name === "glass") {


            this.setState({
                glass: this.state.availableGlass[event.target.selectedIndex].label,
                glassValue: event.target.value,
                glassIntegration: this.state.availableGlass[event.target.selectedIndex].integration,
                //minGlassArea: this.state.availableGlass[event.target.selectedIndex].minGlassArea,

            }, () => (this.calculatePrice()))

        } else if (name === "thermalIsolation") {

            this.setState({
                [name]: !this.state[name],
            }, () => { this.calculatePrice() })
        }

        // else if (name === "glassWidth") {
        //     if (event.target.value > this.state.maxVPWidth) {

        //         this.openSnackbar('Vision panel is to wide', 'left');
        //         this.setState({
        //             [name]: this.state.maxVPWidth,
        //         }, () => { this.handleVisionPanelsPrice() })
        //     } else {
        //         this.setState({

        //             [name]: event.target.value,
        //         }, () => { this.handleVisionPanelsPrice() })
        //     }

        // } else if (name === "glassHeight") {
        //     if (event.target.value > this.state.maxVPHeight) {
        //         this.openSnackbar('Vision panel is to heigh', 'left');
        //         this.setState({

        //             [name]: this.state.maxVPHeight,
        //         }, () => { this.handleVisionPanelsPrice() })
        //     } else {
        //         this.setState({
        //             [name]: event.target.value,
        //         }, () => { this.handleVisionPanelsPrice() })
        //     }
        // } 


    }

    // ------  Setting size and location of vision panels 


    visionPanelsLocationAndSize = (name, id = 'test') => event => {


        let visionPanels = this.state.visionPanels;
        let index = this.state.visionPanels.findIndex(a => a.key === id);
        let newValue = event.target.value
        let maxVPHeight = this.state.maxVPHeight
        let maxVPWidth = 0
        if (visionPanels[index].mainDoor) { maxVPWidth = this.state.maxVPWidth } else { maxVPWidth = this.state.maxVPWidth2 }

        console.log(name, id)

        if (name === "mainDoor") {
            visionPanels[index].mainDoor = !visionPanels[index].mainDoor;

            if (visionPanels[index].glassWidth > this.state.maxVPWidth) {

                this.openSnackbar('Vision panel is to wide', 'left');
                visionPanels[index].glassWidth = this.state.maxVPWidth;
            }

            if (visionPanels[index].glassWidth > this.state.maxVPWidth2) {

                this.openSnackbar('Vision panel is to wide', 'left');
                visionPanels[index].glassWidth = this.state.maxVPWidth2;
            }
        }

        if (name === "glassWidth") {
            if (newValue > maxVPWidth) {
                this.openSnackbar('Vision panel is to wide', 'left');
                newValue = maxVPWidth;
            }
        }

        if (name === "glassHeight") {
            if (newValue > maxVPHeight) {
                this.openSnackbar('Vision panel is to heigh', 'left');
                newValue = maxVPHeight;
            }
        }

        if (name === "glassHeight" || name === "glassWidth" || name === "left" || name === "bottom") { visionPanels[index][name] = newValue; }

        this.setState({
            visionPanels: visionPanels
        }, () => { this.calculatePrice() })
    }











    //--------------------------------------------------------------------------------------------------------------------------------
    //----------------------------------------------------------------------------------------------------- *** Side Panels sizes ***
    //--------------------------------------------------------------------------------------------------------------------------------



    sidePanelHeight = () => {
        let height;
        if (this.state.transomType === "type-2") {
            height = Number(this.state.height) + Number(this.state.clearOpeningHeight) + Number(this.state.threshold)
        } else {
            height = Number(this.state.height) + Number(this.state.clearOpeningHeight) + Number(this.state.threshold) + Number(this.state.transomHeight)
        }
        return height
    }

    leftPanelSqm = () => {
        let leftPanelSqm = 0
        let sidePanelHeight = this.sidePanelHeight()

        leftPanelSqm = ((Number(this.state.leftPanelWidth) * sidePanelHeight) / 1000000)

        return leftPanelSqm
    }

    rightPanelSqm = () => {
        let rightPanelSqm = 0
        let sidePanelHeight = this.sidePanelHeight()

        rightPanelSqm = ((Number(this.state.rightPanelWidth) * sidePanelHeight) / 1000000)

        return rightPanelSqm
    }

    leftPanelPerimeter = () => {
        let leftPanelPerimeter = 0
        let sidePanelHeight = this.sidePanelHeight()

        leftPanelPerimeter = (2 * Number(this.state.leftPanelWidth)) + (2 * sidePanelHeight)

        return leftPanelPerimeter
    }


    rightPanelPerimeter = () => {
        let rightPanelPerimeter = 0
        let sidePanelHeight = this.sidePanelHeight()

        rightPanelPerimeter = (2 * Number(this.state.rightPanelWidth)) + (2 * sidePanelHeight)

        return rightPanelPerimeter
    }



    transomSqm = () => {
        let transomSqm = 0
        if (this.state.transomType === "type-2") {
            transomSqm = ((Number(this.state.leftPanelWidth) + Number(this.state.rightPanelWidth) + Number(this.state.width) + Number(this.state.clearOpeningWidth)) * (Number(this.state.transomHeight))) / 1000000
        } else {
            transomSqm = (((parseInt((Number(this.state.width) + Number(this.state.clearOpeningWidth)))) * (Number(this.state.transomHeight))) / 1000000)
        }
        return transomSqm
    }


    transomPerimeter = () => {
        let transomPerimeter = 0
        if (this.state.transomType === "type-2") {
            transomPerimeter = (2 * (Number(this.state.leftPanelWidth) + Number(this.state.rightPanelWidth) + Number(this.state.width) + Number(this.state.clearOpeningWidth))) + (2 * Number(this.state.transomHeight))
        } else {
            transomPerimeter = (2 * (Number(this.state.width) + Number(this.state.clearOpeningWidth))) + (2 * Number(this.state.transomHeight))
        }
        return transomPerimeter
    }





    //--------------------------------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------------  *** Price ***
    //--------------------------------------------------------------------------------------------------------------------------------


    // handleVisionPanelsPrice = () => {

    //     // Only one type of glass can be applied to one door so  vision Panel Price  price integration prace willbe a global


    //     let glassTotal = 0;
    //     let thermalIsolationValue = 0;
    //     let visionPanelCost = 0;
    //     let thermalIsolationCost = 0;

    //     this.state.visionPanels.map(panel => {
    //         let glassSize = 0
    //         if (panel.fixing === "door") {
    //             glassSize = ((panel.glassWidth * panel.glassHeight) / 1000000);
    //         } else if (panel.fixing === "tr") {
    //             glassSize = this.transomSqm()


    //         }

    //         console.log(glassSize)


    //         if (Number(glassSize) < Number(this.state.minGlassArea)) { glassSize = Number(this.state.minGlassArea) }

    //         if (this.state.thermalIsolation === true) {
    //             thermalIsolationValue += (Prices.glassExtras[0].value * Prices.glassExtras[0].integration) * glassSize;
    //             thermalIsolationCost += (Prices.glassExtras[0].value * 0.8) * glassSize;
    //         }


    //         glassTotal += (this.state.glassValue * this.state.glassIntegration) * glassSize;
    //         visionPanelCost += (this.state.glassValue * 0.8) * glassSize;


    //     })

    //     this.setState({
    //         thermalIsolationValue: thermalIsolationValue,
    //         thermalIsolationCost: thermalIsolationCost,
    //         visionPanelPrice: glassTotal,
    //         visionPanelCost: visionPanelCost,
    //     }, () => { this.calculatePrice('handleVisionPanelsPrice') })

    // }




    calculatePrice = (calledBy) => {

        // console.log(this.state.sqmPrice)

        // console.log('start')

        // console.log(calledBy)



        // ----------------------------------------------------------------
        //  Priceing starting point
        // ----------------------------------------------------------------


        let total = 0;
        let totalCost = 0;
        let transomCost = 0;
        let leftPanelCost = 0;
        let rightPanelCost = 0;
        let doorPrice = 0;


        // ----------------------------------------------------------------
        //  Square meter area 
        // ----------------------------------------------------------------

        let sqm = 0
        let leftPanelSqm = 0
        let rightPanelSqm = 0
        let transomSqm = 0

        let leftPanelPerimeter = 0
        let rightPanelPerimeter = 0
        let transomPerimeter = 0




        /// ---- Set sizes


        if (this.state.frameType === 'type-2') {
            sqm = (((Number(this.state.width) + Number(this.state.clearOpeningWidth)) * (Number(this.state.height) + Number(this.state.clearOpeningHeight) + Number(this.state.threshold))) / 1000000)

            // let sidePanelHeight = this.sidePanelHeight()

            leftPanelSqm = this.leftPanelSqm()
            rightPanelSqm = this.rightPanelSqm()

            leftPanelPerimeter = this.leftPanelPerimeter()
            rightPanelPerimeter = this.rightPanelPerimeter()

            transomSqm = this.transomSqm()
            transomPerimeter = this.transomPerimeter()

        } else {
            sqm = (((parseInt(this.state.width) + parseInt(this.state.clearOpeningWidth) + 80) * (parseInt(this.state.height) + parseInt(this.state.clearOpeningHeight) + parseInt(this.state.threshold) + 40)) / 1000000)
        }
        if (sqm < 1) { sqm = 1; }




        // ---- ---- ---- ---- ----
        // ---- calculations start 
        // ---- ---- ---- ---- ----


        if (this.state.singleDouble === "Single") {

            totalCost = roundToTwo((this.state.sqmPrice * 0.8) * sqm);

            // console.log(this.state.transom + "Transom panel cost: " + transomCost)

            if (this.state.doorType === "DC 3.1 ZK") {
                doorPrice = (this.state.sqmPrice * 3.44);
            } else if (this.state.doorType === "DL 1.1") {
                doorPrice = (this.state.sqmPrice * 1.21);
            } else if (this.state.doorType === "DL 1.3 DRAGON") {
                doorPrice = (this.state.sqmPrice * 1.21);
            } else {
                doorPrice = (this.state.sqmPrice * 1.37);
            }


        } else if (this.state.singleDouble === "Double") {
            // -- cost
            totalCost = roundToTwo(((this.state.sqmPrice * 1.1) * 0.8) * sqm);

            // -- price
            if (this.state.doorType === "DC 3.1 ZK") {
                doorPrice = ((this.state.sqmPrice * 1.1) * 3.44);
            } else if (this.state.doorType === "DL 1.1") {
                doorPrice = ((this.state.sqmPrice * 1.1) * 1.21);
            } else if (this.state.doorType === "DL 1.3 DRAGON") {
                doorPrice = ((this.state.sqmPrice * 1.1) * 1.21);
            } else {
                doorPrice = ((this.state.sqmPrice * 1.1) * 1.37);
            }

        }

        total += roundToTwo(doorPrice * sqm);


        // ----------------------------------------------------------------
        // Transom and side panels 
        // ----------------------------------------------------------------


        // ---- Left panel cost

        if (this.state.leftPanel) {
            // Check if vision panel for left panel exits
            let vpExist = this.state.visionPanels.findIndex(vp => vp.fixing === "lp")

            // if not than calculate vision panel price based on door rice
            if (vpExist === -1) {

                leftPanelCost = roundToTwo((this.state.sqmPrice * 0.8) * leftPanelSqm);
                totalCost += roundToTwo((this.state.sqmPrice * 0.8) * leftPanelSqm);

                total += doorPrice * leftPanelSqm;

            } else {
                // If side panel is covered by Glass, add price of the frame
                // Glass is calculated in diffrent place

                leftPanelCost += roundToTwo((this.state.frameValue * 0.8) * (leftPanelPerimeter / 1000));
                totalCost += roundToTwo((this.state.frameValue * 0.8) * (leftPanelPerimeter / 1000));
                total += roundToTwo((this.state.frameValue * this.state.frameValueIntegration) * (leftPanelPerimeter / 1000));

            }
        }


        // ---- Right panel cost

        if (this.state.rightPanel) {
            // Check if vision panel for right panel exits
            let vpExist = this.state.visionPanels.findIndex(vp => vp.fixing === "rp")
            // if not than calculate vision panel price based on door rice
            if (vpExist === -1) {

                rightPanelCost = (this.state.sqmPrice * 0.8) * rightPanelSqm;
                totalCost += (this.state.sqmPrice * 0.8) * rightPanelSqm;

                total += doorPrice * rightPanelSqm;

            } else {
                // If side panel is covered by Glass, add price of the frame
                // Glass is calculated in diffrent place

                rightPanelCost += roundToTwo((this.state.frameValue * 0.8) * (rightPanelPerimeter / 1000));
                totalCost += roundToTwo((this.state.frameValue * 0.8) * (rightPanelPerimeter / 1000));
                total += roundToTwo((this.state.frameValue * this.state.frameValueIntegration) * (rightPanelPerimeter / 1000));

            }

        }

        // ---- Transom panel cost

        if (this.state.transom) {
            // Check if vision panel for right panel exits
            let vpExist = this.state.visionPanels.findIndex(vp => vp.fixing === "tr")
            // if not than calculate vision panel price based on door rice
            if (vpExist === -1) {
                transomCost = (this.state.sqmPrice * 0.8) * transomSqm;

                totalCost += (this.state.sqmPrice * 0.8) * transomSqm;

                total += doorPrice * transomSqm;
            } else {
                // If side panel is covered by Glass, add price of the frame
                // Glass is calculated in diffrent place

                transomCost += roundToTwo((this.state.frameValue * 0.8) * (transomPerimeter / 1000));
                totalCost += roundToTwo((this.state.frameValue * 0.8) * (transomPerimeter / 1000));


                total += roundToTwo((this.state.frameValue * this.state.frameValueIntegration) * (transomPerimeter / 1000));

            }

        }


        // console.log(this.state.sqmPrice );
        // console.log('sqm: '+sqm);
        // console.log('Door Price: ' + total);

        // ----------------------------------------------------------------
        // Finishing 
        // ----------------------------------------------------------------

        let finishingPrice = 0

        if (this.state.colorInside !== this.state.colorOutside) {
            finishingPrice = 115.40;
            total += 115.40;
            totalCost += 115.40 * 0.8;
        }

        //console.log(sqm)


        //console.log('Finishing Price: ' + finishingPrice);


        //-----------------------------------------------------------------
        // Additional
        //-----------------------------------------------------------------
        let additionalSum = 0;
        let additionalCostSum = 0;

        this.state.additional.map(add => {
            //console.log(add)

            if (add.per === 'sqm') {

                additionalSum += roundToTwo(Number(add.price) * Number(add.integration));
                additionalCostSum += roundToTwo(Number(add.price) * 0.8);
                //console.log('Item: '+ add.label + ' - ' + add.price) ;
                //console.log(parseInt(Number(add.price) * Number(add.integration), 10))

            } else {

                // console.log(add.value, add.integration , parseInt(Number(add.value) * Number(add.integration), 10), roundToTwo(Number(add.value) * Number(add.integration)))

                additionalSum += roundToTwo(Number(add.value) * Number(add.integration));
                additionalCostSum += roundToTwo(Number(add.value) * 0.8);

            }
        })



        total += additionalSum;
        totalCost += additionalCostSum;

        //console.log('additional: '+total)

        //-----------------------------------------------------------------
        // Extra Over
        //-----------------------------------------------------------------
        let extraOver = 0;
        this.state.extraOver.map(add => {
            extraOver += Number(add.value)
            //console.log('Item: '+ add.label + ' - ' + add.value) ;
        })

        total += extraOver;
        totalCost += extraOver;

        //console.log('extra over :'+total)

        //-----------------------------------------------------------------
        // Door & Panels Ventilation
        //-----------------------------------------------------------------

        let ventilationTotalPrice = 0;
        let ventilationTotalCost = 0;

        if (this.state.ventilation === true) {

            this.state.ventilationList.map(vent => {

                if (vent.fixing === "door") {

                    if (vent.per === 'sqm') {

                        let ventSqm = 0

                        if (vent.mainDoor === true) {
                            ventSqm = (this.state.maxVPWidth * vent.height) / 1000000;
                        } else {
                            ventSqm = (this.state.maxVPWidth2 * vent.height) / 1000000;
                        }

                        if (ventSqm <= 0.5) { ventSqm = 0.5 }

                        total += (vent.value * vent.integration) * ventSqm;
                        totalCost += roundToTwo((vent.value * ventSqm) * 0.8);

                    } else {

                        total += roundToTwo(Number(vent.value) * Number(vent.integration));
                        totalCost += roundToTwo(Number(vent.value) * 0.8);
                    }

                } else if (vent.fixing === "tr") {
                    transomCost += (vent.value * 0.8) * transomSqm;
                    totalCost += (vent.value * 0.8) * transomSqm;

                    total += (vent.value * vent.integration) * transomSqm;

                } else if (vent.fixing === "rp") {

                    rightPanelCost += (vent.value * 0.8) * rightPanelSqm;
                    totalCost += (vent.value * 0.8) * rightPanelSqm;

                    total += (vent.value * vent.integration) * rightPanelSqm;

                } else if (vent.fixing === "lp") {
                    leftPanelCost += roundToTwo((vent.value * 0.8) * leftPanelSqm);
                    totalCost += roundToTwo((vent.value * 0.8) * leftPanelSqm);

                    total += roundToTwo((vent.value * vent.integration) * leftPanelSqm);
                }




            })
        }

        //console.log('Ventilation: '+total)

        //-----------------------------------------------------------------
        // Vision Panel
        //-----------------------------------------------------------------


        let glassTotal = 0;
        let thermalIsolationValue = 0;
        let visionPanelCost = 0;
        let thermalIsolationCost = 0;



        if (this.state.visionPanel) {


            this.state.visionPanels.map(panel => {
                let glassSize = 0

                if (panel.fixing === "door") {
                    glassSize = ((panel.glassWidth * panel.glassHeight) / 1000000);
                } else if (panel.fixing === "tr") {
                    glassSize = this.transomSqm()
                }else if (panel.fixing === "lp") {
                    glassSize = this.leftPanelSqm()
                }else if (panel.fixing === "rp") {
                    glassSize = this.rightPanelSqm()
                }

                if (Number(glassSize) < Number(this.state.minGlassArea)) { glassSize = Number(this.state.minGlassArea) }
             
                if (this.state.thermalIsolation === true) {
                    thermalIsolationValue += (Prices.glassExtras[0].value * Prices.glassExtras[0].integration) * glassSize;
                    thermalIsolationCost += (Prices.glassExtras[0].value * 0.8) * glassSize;
                }

                else if (panel.fixing === "rp") {
                    rightPanelCost +=  (this.state.glassValue * 0.8) * glassSize;
                }else if (panel.fixing === "lp") {
                    leftPanelCost +=  (this.state.glassValue * 0.8) * glassSize;
                }else if (panel.fixing === "tr") {
                    transomCost +=  (this.state.glassValue * 0.8) * glassSize;
                }

                glassTotal += (this.state.glassValue * this.state.glassIntegration) * glassSize;
                
                visionPanelCost += (this.state.glassValue * 0.8) * glassSize;

            })

            total += glassTotal
            total += roundToTwo(thermalIsolationValue);

            totalCost += roundToTwo(visionPanelCost)
            totalCost += roundToTwo(thermalIsolationCost);

        }

        let discount = this.state.discount;

        this.setState({

            totalPrice: total.toFixed(2),
            finishingPrice: finishingPrice,
            totalCost: totalCost.toFixed(2),
            leftPanelCost: leftPanelCost.toFixed(2),
            rightPanelCost: rightPanelCost.toFixed(2),
            transomCost: transomCost.toFixed(2),

            //Vision panels related
            thermalIsolationValue: thermalIsolationValue,
            thermalIsolationCost: thermalIsolationCost,
            visionPanelPrice: glassTotal,
            visionPanelCost: visionPanelCost,
        })


        // console.log('Total: ' + total);
        // console.log('----------------------------------------------------------------------------');
    }

    // Open Snackbar

    openSnackbar = (text, horizontalPosition) => {
        this.setState({
            snackBar: true,
            snackBarText: text,
            snackBarHorizontal: horizontalPosition
        })

    }

    handleCloseSnackBar = () => {
        this.setState({
            snackBar: false,
            snackBarText: '',
            snackBarHorizontal: '',
        })
    }

    // Stepper navigation

    handleNext = () => {
        window.scrollTo(0, 0);
        const { activeStep } = this.state;
        this.setState({
            activeStep: activeStep + 1,
        });
    };

    handleBack = () => {
        window.scrollTo(0, 0);
        const { activeStep } = this.state;
        this.setState({
            activeStep: activeStep - 1,
        });
    };

    handleReset = () => {
        this.setState({

        });
        this.clearOpening();
    };

    handleNextForSameCompany = () => {
        this.setState({
            activeStep: 0,
            quoteID: '',
        })
        this.clearOpening();
    }
    // Create list of all doors



    currentDoorSetup = () => {



        let uid = uniqid();
        let additional = Array.from(this.state.additional)

        return {
            'key': uid,
            'qty': this.state.qty,
            'side': this.state.side,
            'doorType': this.state.doorType,
            'width': this.state.width,
            'height': this.state.height,
            'description': this.state.description,
            'grade': this.state.grade,
            'vat': this.state.vat,
            'finishingPrice': this.state.finishingPrice,
            'colorOutside': this.state.colorOutside,
            'colorInside': this.state.colorInside,
            'additional': additional,
            'visionPanel': this.state.visionPanel,
            effectiveOpening: this.state.effectiveOpening,

            'glass': this.state.glass,
            'visionPanels': this.state.visionPanels,
            'visionPanelPrice': this.state.visionPanelPrice,
            'totalPrice': this.state.totalPrice,
            'handleWidth': this.state.handleWidth,
            'clearOpeningWidth': this.state.clearOpeningWidth,
            'clearOpeningHeight': this.state.clearOpeningHeight,
            'threshold': this.state.threshold,
            'singleDouble': this.state.singleDouble,
            'doubleDoorMainLeafwidth': this.state.doubleDoorMainLeafwidth,
            'frameType': this.state.frameType,
            'frameDepth': this.state.frameDepth,
            frameValue: this.state.frameValue,
            waFrame: this.state.waFrame,
            'swingDirection': this.state.swingDirection,
            'printRatio': this.state.printRatio,
            // 'mirrorEffect':this.state.mirrorEffect,
            'thermalIsolation': this.state.thermalIsolation,
            // 'mirrorEffectValue':this.state.mirrorEffectValue,
            'thermalIsolationValue': this.state.thermalIsolationValue,
            'fire': this.state.fire,
            'productLine': this.state.productLine,
            'extraOver': this.state.extraOver,
            'visionPanel': this.state.visionPanel,
            'visionPanelRestrictions': this.state.visionPanelRestrictions,
            'maxVPWidth2': this.state.maxVPWidth2,
            'maxVPWidth': this.state.maxVPWidth,
            'maxVPHeight': this.state.maxVPHeight,
            'ventilationList': this.state.ventilationList,
            'ventilation': this.state.ventilation,
            'notes': this.state.notes,
            'doorName': this.state.doorName,
            'discount': this.state.discount,
            'totalCost': this.state.totalCost,
            leftPanelCost: this.state.leftPanelCost,
            rightPanelCost: this.state.rightPanelCost,
            transomCost: this.state.transomCost,
            'transom': this.state.transom,
            transomType: this.state.transomType,
            'leftPanel': this.state.leftPanel,
            'rightPanel': this.state.rightPanel,
            'leftPanelWidth': this.state.leftPanelWidth,
            'transomHeight': this.state.transomHeight,
            'rightPanelWidth': this.state.rightPanelWidth,

        }

    }
    editDoor = (id) => () => {

        let allDoors = this.state.allDoors;

        let index = allDoors.findIndex(x => x.key === id)

        allDoors.push(
            this.currentDoorSetup()
        )
        let doorToBeeEdited = allDoors[index]




        // Eksperymenty z szybami trzeba bedzie do tego wrocic

        // for(let i = 0 ; i < doorToBeeEdited.visionPanels.length ; i++){
        //     console.log(doorToBeeEdited.visionPanels[i].key)
        //     doorToBeeEdited.visionPanels[i].key = doorToBeeEdited.visionPanels[i].key+i
        //     console.log(doorToBeeEdited.visionPanels[i].key)
        // }





        if (!doorToBeeEdited.extraOver) {
            doorToBeeEdited.extraOver = []
        }

        if (!doorToBeeEdited.effectiveOpening) {
            doorToBeeEdited.effectiveOpening = 0
        }

        let indexGlassPrice = Prices.glass.findIndex(x => x.label === doorToBeeEdited.glass);



        if (indexGlassPrice !== -1) {
            console.log(indexGlassPrice)
            doorToBeeEdited.glassValue = Prices.glass[indexGlassPrice].value
        }



        this.setState({
            allDoors: allDoors,

            ...doorToBeeEdited
        }, () => { this.setFireValues() })

    }

    addMoreDoors = () => {

        let allDoors = this.state.allDoors;

        allDoors.push(
            this.currentDoorSetup()
        )

        this.setState({
            allDoors: allDoors,
            doorName: `D00${allDoors.length + 1}`,
            visionPanel: false,
            visionPanels: [],
            ventilation: false,
            ventilationList: [],
            extraOver: [],
            additional: [],




        }, () => { this.setFirstProduct(this.state.productLine, this.state.doorType) })

    }

    // Save Quote

    createOrderNumbeAndPushToSave = () => {

        const ID = firebase.database().ref().child('QuoteNo')
        let quoteNo;

        return ID.once('value').then((snap) => {

            quoteNo = snap.val() + 1;

            firebase.database().ref().update({ 'QuoteNo': quoteNo }).then((snap) => {

                quoteNo = this.props.userEmail.slice(0, 2) + '-' + moment().year() + '-' + quoteNo;
                this.setState({
                    quoteNo: quoteNo,
                    redirect: true
                }, () => { this.handleSaveOrder() })
            });
        })
    }


    handleSaveOrder = () => {


        const quoteRef = firebase.database().ref().child('Quotes');


        quoteRef.push({

            'qty': this.state.qty,
            'side': this.state.side,
            'doorType': this.state.doorType,
            'width': this.state.width,
            'height': this.state.height,
            'description': this.state.description,
            'grade': this.state.grade,
            'vat': this.state.vat,
            'finishingPrice': this.state.finishingPrice,
            'colorOutside': this.state.colorOutside,
            'colorInside': this.state.colorInside,
            'additional': this.state.additional,
            'visionPanel': this.state.visionPanel,

            'glass': this.state.glass,
            'visionPanels': this.state.visionPanels,
            'visionPanelPrice': this.state.visionPanelPrice,
            'totalPrice': this.state.totalPrice,
            'handleWidth': this.state.handleWidth,
            'clearOpeningWidth': this.state.clearOpeningWidth,
            'clearOpeningHeight': this.state.clearOpeningHeight,
            'threshold': this.state.threshold,
            'singleDouble': this.state.singleDouble,
            'doubleDoorMainLeafwidth': this.state.doubleDoorMainLeafwidth,
            'frameType': this.state.frameType,
            'frameDepth': this.state.frameDepth,
            frameValue: this.state.frameValue,
            waFrame: this.state.waFrame,
            'swingDirection': this.state.swingDirection,
            'printRatio': this.state.printRatio,
            // 'mirrorEffect':this.state.mirrorEffect,
            'thermalIsolation': this.state.thermalIsolation,
            // 'mirrorEffectValue':this.state.mirrorEffectValue,
            'thermalIsolationValue': this.state.thermalIsolationValue,
            'fire': this.state.fire,
            'productLine': this.state.productLine,
            'extraOver': this.state.extraOver,
            // 'visionPanel':this.state.visionPanel,
            'visionPanelRestrictions': this.state.visionPanelRestrictions,
            'maxVPWidth2': this.state.maxVPWidth2,
            'maxVPWidth': this.state.maxVPWidth,
            'maxVPHeight': this.state.maxVPHeight,
            'ventilationList': this.state.ventilationList,
            'ventilation': this.state.ventilation,
            'notes': this.state.notes,
            'discount': this.state.discount,
            //all Doors but first
            'allDoors': this.state.allDoors,
            'doorName': this.state.doorName,
            'totalCost': this.state.totalCost,
            leftPanelCost: this.state.leftPanelCost,
            rightPanelCost: this.state.rightPanelCost,
            additionalPriceItems: this.state.additionalPriceItems,
            'transom': this.state.transom,
            transomType: this.state.transomType,
            'leftPanel': this.state.leftPanel,
            'rightPanel': this.state.rightPanel,
            'transomHeight': this.state.transomHeight,
            'leftPanelWidth': this.state.leftPanelWidth,
            'rightPanelWidth': this.state.rightPanelWidth,
            effectiveOpening: this.state.effectiveOpening,


            //Customer Data
            'companyName': this.state.companyName,
            'contactName': this.state.contactName,
            'contactEmail': this.state.contactEmail,
            'phoneNumber': this.state.phoneNumber,
            'firstAddressLine': this.state.firstAddressLine,
            'secondAddressLine': this.state.secondAddressLine,
            'city': this.state.city,
            'county': this.state.county,
            'country': this.state.country,
            'refernceNumber': this.state.refernceNumber,
            'quoteId': this.state.quoteID,
            'quoteDate': this.state.quoteDate,
            'eircode': this.state.eircode,
            'product': 'Steel Door',
            'quoteNo': this.state.quoteNo,



        }).then((snap) => {

            this.setState({
                quoteID: snap.key,

            })
        });


        this.handleNext();

    }

    // start= ()=>{ console.log(this.props);}

    // -----------------------------------------------------------------------------------------------
    // ------------------------------------------------------------------------------  Live cycle
    // ----------------------------------------------------------------------------------------------


    componentDidMount() {


        this.clearOpening();

        this.visionPanelMaxSize();

        if (this.props.editPage === false) {
            this.setFirstProduct(this.state.grade, this.state.doorType);
        } else {
            this.setFireValues()
        }

        this.availableGlass();

        this.availableVentilation();

    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props !== prevProps) {

            //console.log(this.state.fire, this.state.doorType, this.state.totalPrice)

        }


    }


    render() {
        const { classes } = this.props;

        if (this.state.redirect === true) {
            return <Redirect to={'/quotes'} />
        }


        return (

            <Container maxWidth="xl" className={this.props.classes.root} >


                <Grid container spacing={6} style={{ justifyContent: 'center' }}>


                    <Grid item xs={12} >
                        <PrintRatio
                            handleChange={this.handleChange}
                            printRatio={this.state.printRatio}
                            vertical={'top'}
                            verticalOffset={75}
                            horizontal={'right'}
                            horizontalOffset={18}
                        ></PrintRatio>

                        <CalcStepper
                            //properties
                            activeStep={this.state.activeStep}
                            steps={this.state.steps}
                        > </CalcStepper>
                    </Grid >

                    {this.state.activeStep === 0 &&
                        <Grid item md={3} className="noPrint">


                            <Calculator

                                //handlers
                                editDoor={this.editDoor}
                                handelAddPriceItem={this.handelAddPriceItem}
                                handleQuillText={this.handleQuillText}
                                handleQuillTextNotes={this.handleQuillTextNotes}
                                handelAdditionalPriceItem={this.handelAdditionalPriceItem}
                                handleRemovePriceItem={this.handleRemovePriceItem}
                                handleglobalDiscount={this.handleglobalDiscount}                                    // loadDefoultIronmongery={this.loadDefoultIronmongery}
                                handleDorrType={this.handleDorrType}
                                addMoreDoors={this.addMoreDoors}
                                changeDoorRatio={this.changeDoorRatio}
                                handlePanelSize={this.handlePanelSize}

                                handleDoorSize={this.handleDoorSize}
                                handleDropdown={this.handleDropdown}
                                handleAdditional={this.handleAdditional}
                                handleAdditionalValue={this.handleAdditionalValue}
                                // handleRemoveAdditional={this.handleRemoveAdditional}
                                handleRemoveGlass={this.handleRemoveGlass}
                                // handleRemoveVent={this.handleRemoveVent}
                                printRatio={this.state.printRatio}
                                ventilationType={this.state.ventilationType}
                                effectiveOpening={this.state.effectiveOpening}


                                handleRemoveItemFromList={this.handleRemoveItemFromList}
                                handlePushItemDown={this.handlePushItemDown}

                                handleChange={this.handleChange}
                                handleGlassType={this.handleGlassType}
                                doubleDoorMainLeafwidth={this.state.doubleDoorMainLeafwidth}
                                frameType={this.state.frameType}

                                

                                categoryUpdate={this.categoryUpdate}
                                handleSingleDouble={this.handleSingleDouble}
                                handleFire={this.handleFire}
                                handleFrameType={this.handleFrameType}
                                addVisonPanelToPanelOrDoor={this.addVisonPanelToPanelOrDoor}
                                visionPanelsLocationAndSize={this.visionPanelsLocationAndSize}
                                handleAddVentilation={this.handleAddVentilation}
                                handleVentilationType={this.handleVentilationType}
                                handleVents={this.handleVents}
                                handleAdditionalPosition={this.handleAdditionalPosition}
                                handleInsideOutside={this.handleInsideOutside}
                                handleActiveInactive={this.handleActiveInactive}
                                handleAddExtraOver={this.handleAddExtraOver}
                                // handleRemoveExtraOver={this.handleRemoveExtraOver}
                                extraOver={this.state.extraOver}
                                handleExtraOver={this.handleExtraOver}
                                discount={this.state.discount}
                                handleAdditionalSqmSize={this.handleAdditionalSqmSize}
                                allDoors={this.state.allDoors}
                                doorName={this.state.doorName}
                                //properties
                                height={this.state.height}
                                width={this.state.width}
                                additional={this.state.additional}
                                visionPanel={this.state.visionPanel}
                                transom={this.state.transom}
                                transomType={this.state.transomType}
                                leftPanel={this.state.leftPanel}
                                rightPanel={this.state.rightPanel}
                                transomHeight={this.state.transomHeight}
                                leftPanelWidth={this.state.leftPanelWidth}
                                rightPanelWidth={this.state.rightPanelWidth}
                                glassValue={this.state.glassValue}
                                maxVPWidth={this.state.maxVPWidth}
                                maxVPWidth2={this.state.maxVPWidth2}
                                maxVPHeight={this.state.maxVPHeight}
                                singleDouble={this.state.singleDouble}
                                side={this.state.side}
                                availableGlass={this.state.availableGlass}
                                vat={this.state.vat}
                                notes={this.state.notes}

                                ventilation={this.state.ventilation}
                                availableVentilation={this.state.availableVentilation}
                                ventilationList={this.state.ventilationList}
                                totalCost={this.state.totalCost}
                                leftPanelCost={this.state.leftPanelCost}
                                rightPanelCost={this.state.rightPanelCost}
                                transomCost={this.state.transomCost}

                                productLine={this.state.productLine}
                                swingDirection={this.state.swingDirection}
                                doorType={this.state.doorType}
                                maxWidth={this.state.maxWidth}
                                maxHeight={this.state.maxHeight}
                                fire={this.state.fire}
                                // mirrorEffect={this.state.mirrorEffect}
                                thermalIsolation={this.state.thermalIsolation}
                                visionPanels={this.state.visionPanels}
                                qty={this.state.qty}
                                additionalPriceItems={this.state.additionalPriceItems}

                                userEmail={this.props.userEmail}

                            ></Calculator>

                        </Grid>
                    }
                    {this.state.activeStep === 1 &&
                        <Grid item md={3} className="noPrint" >
                            <Paper className={this.props.classes.paper}>
                                <CustomerDetails
                                    companyName={this.state.companyName}
                                    contactName={this.state.contactName}
                                    contactEmail={this.state.contactEmail}
                                    phoneNumber={this.state.phoneNumber}
                                    firstAddressLine={this.state.firstAddressLine}
                                    secondAddressLine={this.state.secondAddressLinesecondAddressLine}
                                    city={this.state.city}
                                    county={this.state.county}
                                    country={this.state.country}
                                    refernceNumber={this.state.refernceNumber}
                                    quoteId={this.state.quoteID}
                                    quoteDate={this.state.quoteDate}
                                    //actions
                                    handleChange={this.handleChange}
                                ></CustomerDetails>
                            </Paper>
                        </Grid>

                    }

                    <Grid item md={9} className={this.props.classes.printFullWidth} >
                        <Paper className={this.props.classes.paper}>
                            <Quote
                                // handleVentPosition={this.handleVentPosition}
                                handleChange={this.handleChange}


                                //properties
                                transom={this.state.transom}
                                transomType={this.state.transomType}
                                transomHeight={this.state.transomHeight}
                                leftPanel={this.state.leftPanel}
                                rightPanel={this.state.rightPanel}
                                leftPanelWidth={this.state.leftPanelWidth}
                                rightPanelWidth={this.state.rightPanelWidth}
                                side={this.state.side}
                                doorType={this.state.doorType}
                                width={this.state.width}
                                height={this.state.height}
                                description={this.state.description}
                                handle={this.state.handle}
                                handlePrice={this.state.handlePrice}
                                grade={this.state.grade}
                                vat={this.state.vat}
                                qty={this.state.qty}
                                discount={this.state.discount}
                                finishingPrice={this.state.finishingPrice}
                                colorOutside={this.state.colorOutside}
                                colorInside={this.state.colorInside}
                                additional={this.state.additional}
                                visionPanel={this.state.visionPanel}
                                // visionPanel={this.state.visionPanel}
                                glass={this.state.glass}
                                visionPanels={this.state.visionPanels}
                                visionPanelPrice={this.state.visionPanelPrice}
                                totalPrice={this.state.totalPrice}
                                handleWidth={this.state.handleWidth}
                                clearOpeningWidth={this.state.clearOpeningWidth}
                                clearOpeningHeight={this.state.clearOpeningHeight}
                                threshold={this.state.threshold}
                                singleDouble={this.state.singleDouble}
                                doubleDoorMainLeafwidth={this.state.doubleDoorMainLeafwidth}
                                frameType={this.state.frameType}
                                frameDepth={this.state.frameDepth}
                                swingDirection={this.state.swingDirection}
                                printRatio={this.state.printRatio}
                                // mirrorEffect={this.state.mirrorEffect}
                                thermalIsolation={this.state.thermalIsolation}
                                // mirrorEffectValue={this.state.mirrorEffectValue}
                                thermalIsolationValue={this.state.thermalIsolationValue}
                                fire={this.state.fire}
                                productLine={this.state.productLine}
                                extraOver={this.state.extraOver}
                                effectiveOpening={this.state.effectiveOpening}

                                visionPanelRestrictions={this.state.visionPanelRestrictions}
                                maxVPWidth2={this.state.maxVPWidth2}
                                maxVPWidth={this.state.maxVPWidth}
                                maxVPHeight={this.state.maxVPHeight}
                                ventilationList={this.state.ventilationList}
                                ventilation={this.state.ventilation}
                                notes={this.state.notes}
                                allDoors={this.state.allDoors}
                                doorName={this.state.doorName}
                                additionalPriceItems={this.state.additionalPriceItems}

                                waFrame={this.state.waFrame}

                                //Customer Data
                                companyName={this.state.companyName}
                                contactName={this.state.contactName}
                                contactEmail={this.state.contactEmail}
                                phoneNumber={this.state.phoneNumber}
                                firstAddressLine={this.state.firstAddressLine}
                                secondAddressLine={this.state.secondAddressLine}
                                city={this.state.city}
                                county={this.state.county}
                                country={this.state.country}
                                refernceNumber={this.state.refernceNumber}
                                quoteId={this.state.quoteID}
                                quoteDate={this.state.quoteDate}
                                eircode={this.state.eircode}
                            ></Quote>
                        </Paper>
                    </Grid>
                    <Grid item md={12} >
                        <StepperNav
                            //actions
                            handleNext={this.handleNext}
                            handleBack={this.handleBack}
                            saveRecord={this.saveRecord}
                            handleReset={this.handleReset}
                            createOrderNumbeAndPushToSave={this.createOrderNumbeAndPushToSave}
                            handleNextForSameCompany={this.handleNextForSameCompany}

                            //properties
                            activeStep={this.state.activeStep}
                            steps={this.state.steps}
                        ></StepperNav>
                    </Grid>
                </Grid>


                <Snackbar
                    severity="error"
                    style={{ background: "red", padding: "100px", borderRadius: "20px", top: "50%" }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.snackBar}
                    //autoHideDuration={5000}
                    onClose={this.handleCloseSnackBar}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{this.state.snackBarText}</span>}
                    action={[
                        ,
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className={classes.close}
                            onClick={this.handleCloseSnackBar}
                        >
                            <CloseIcon />
                        </IconButton>,
                    ]}
                />
            </Container>
        )

    }
}
export default withStyles(styles)(MainComponent);