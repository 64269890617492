import React, { Component } from 'react';

class Bars extends Component{

    state= {

       
    }

    componentDidUpdate(prevProps){

    }

    render() {
        
        return (

            
            <div>
              
                
                <div style={{height: `${this.props.height/this.props.printRatio}px`,  width: `100%`, height: `${this.props.height/this.props.printRatio}px`, border: '1px solid black', position:"absolute", display: "flex", justifyContent: 'center'  }}>
                        
                        { Number(this.props.width) < 440 ? (
                            <div style={{width: '100%', display:'flex',justifyContent: 'space-evenly'}}>
                                <div style={{width:"4px", height: '100%', border: '1px solid black'}}></div> 
                                <div style={{width:"4px", height: '100%', border: '1px solid black'}}></div>
                            </div>    
                        ) : ( Number(this.props.width) >= 440  && Number(this.props.width) < 600)? (
                            <div style={{width: '100%', display:'flex',justifyContent: 'space-evenly'}}>
                                <div style={{width:"4px", height: '100%', border: '1px solid black'}}></div> 
                                <div style={{width:"4px", height: '100%', border: '1px solid black'}}></div>
                                <div style={{width:"4px", height: '100%', border: '1px solid black'}}></div>
                            </div>    
                        ) :  (Number(this.props.width) >= 600 && Number(this.props.width) <740 ) ?(
                            <div style={{width: '100%', display:'flex',justifyContent: 'space-evenly'}}>
                                <div style={{width:"4px", height: '100%', border: '1px solid black'}}></div> 
                                <div style={{width:"4px", height: '100%', border: '1px solid black'}}></div>
                                <div style={{width:"4px", height: '100%', border: '1px solid black'}}></div>
                                <div style={{width:"4px", height: '100%', border: '1px solid black'}}></div>   
                            </div>  
                        ) :  (Number(this.props.width) >= 740 && Number(this.props.width) < 900 ) ?(
                            <div style={{width: '100%', display:'flex',justifyContent: 'space-evenly'}}>
                                <div style={{width:"4px", height: '100%', border: '1px solid black'}}></div> 
                                <div style={{width:"4px", height: '100%', border: '1px solid black'}}></div>
                                <div style={{width:"4px", height: '100%', border: '1px solid black'}}></div>
                                <div style={{width:"4px", height: '100%', border: '1px solid black'}}></div>   
                                <div style={{width:"4px", height: '100%', border: '1px solid black'}}></div> 
                            </div>  
                        ) :  (Number(this.props.width) >= 900 && Number(this.props.width) < 1040 ) ?(
                            <div style={{width: '100%', display:'flex',justifyContent: 'space-evenly'}}>
                                <div style={{width:"4px", height: '100%', border: '1px solid black'}}></div> 
                                <div style={{width:"4px", height: '100%', border: '1px solid black'}}></div>
                                <div style={{width:"4px", height: '100%', border: '1px solid black'}}></div>
                                <div style={{width:"4px", height: '100%', border: '1px solid black'}}></div>   
                                <div style={{width:"4px", height: '100%', border: '1px solid black'}}></div> 
                                <div style={{width:"4px", height: '100%', border: '1px solid black'}}></div> 
                            </div>    
                        )  :  (Number(this.props.width) >= 1040 && Number(this.props.width) < 1200 ) ?(
                            <div style={{width: '100%', display:'flex',justifyContent: 'space-evenly'}}>
                                <div style={{width:"4px", height: '100%', border: '1px solid black'}}></div> 
                                <div style={{width:"4px", height: '100%', border: '1px solid black'}}></div>
                                <div style={{width:"4px", height: '100%', border: '1px solid black'}}></div>
                                <div style={{width:"4px", height: '100%', border: '1px solid black'}}></div> 
                                <div style={{width:"4px", height: '100%', border: '1px solid black'}}></div> 
                                <div style={{width:"4px", height: '100%', border: '1px solid black'}}></div> 
                                <div style={{width:"4px", height: '100%', border: '1px solid black'}}></div>   
                            </div>  
                        )  :  (Number(this.props.width) >= 1200 && Number(this.props.width) < 1350 ) ?(
                            <div style={{width: '100%', display:'flex',justifyContent: 'space-evenly'}}>
                                <div style={{width:"4px", height: '100%', border: '1px solid black'}}></div> 
                                <div style={{width:"4px", height: '100%', border: '1px solid black'}}></div>
                                <div style={{width:"4px", height: '100%', border: '1px solid black'}}></div>
                                <div style={{width:"4px", height: '100%', border: '1px solid black'}}></div> 
                                <div style={{width:"4px", height: '100%', border: '1px solid black'}}></div> 
                                <div style={{width:"4px", height: '100%', border: '1px solid black'}}></div> 
                                <div style={{width:"4px", height: '100%', border: '1px solid black'}}></div> 
                                <div style={{width:"4px", height: '100%', border: '1px solid black'}}></div>   
                            </div>   
                        )  :  (Number(this.props.width) >= 1350 && Number(this.props.width) < 1500 ) ?(
                            <div style={{width: '100%', display:'flex',justifyContent: 'space-evenly'}}>
                                <div style={{width:"4px", height: '100%', border: '1px solid black'}}></div> 
                                <div style={{width:"4px", height: '100%', border: '1px solid black'}}></div>
                                <div style={{width:"4px", height: '100%', border: '1px solid black'}}></div>
                                <div style={{width:"4px", height: '100%', border: '1px solid black'}}></div> 
                                <div style={{width:"4px", height: '100%', border: '1px solid black'}}></div> 
                                <div style={{width:"4px", height: '100%', border: '1px solid black'}}></div> 
                                <div style={{width:"4px", height: '100%', border: '1px solid black'}}></div> 
                                <div style={{width:"4px", height: '100%', border: '1px solid black'}}></div>   
                                <div style={{width:"4px", height: '100%', border: '1px solid black'}}></div> 
                            </div>  
                        ):( <div></div>)}

                         { Number(this.props.height) < 800 ? (
                            <div style={{width: '100%', display:'flex', justifyContent: 'space-evenly', position:'absolute', flexDirection: 'column', height: `100%`}}>
                                <div style={{height:"2px", width: '100%', border: '1px solid black', backgroundColor: 'white'}}></div> 
                            </div>   
                        ) : ( Number(this.props.height) >= 800  && Number(this.props.height) < 1200)? (
                            <div style={{width: '100%', display:'flex', justifyContent: 'space-evenly', position:'absolute', flexDirection: 'column', height: `100%`}}>
                                <div style={{height:"2px", width: '100%', border: '1px solid black', backgroundColor: 'white'}}></div> 
                                <div style={{height:"2px", width: '100%', border: '1px solid black', backgroundColor: 'white'}}></div>
                            
                            </div>
                        )  :  (Number(this.props.height) >= 1200 && Number(this.props.height) < 1600 ) ?(
                            <div style={{width: '100%', display:'flex', justifyContent: 'space-evenly', position:'absolute', flexDirection: 'column', height: `100%`}}>
                                <div style={{height:"2px", width: '100%', border: '1px solid black', backgroundColor: 'white'}}></div> 
                                <div style={{height:"2px", width: '100%', border: '1px solid black', backgroundColor: 'white'}}></div>
                                <div style={{height:"2px", width: '100%', border: '1px solid black', backgroundColor: 'white'}}></div>
                     
                            </div>
                        )  :  (Number(this.props.height) >= 1600 && Number(this.props.height) < 2000 ) ?(
                            <div style={{width: '100%', display:'flex', justifyContent: 'space-evenly', position:'absolute', flexDirection: 'column', height: `100%`}}>
                                <div style={{height:"2px", width: '100%', border: '1px solid black', backgroundColor: 'white'}}></div> 
                                <div style={{height:"2px", width: '100%', border: '1px solid black', backgroundColor: 'white'}}></div>
                                <div style={{height:"2px", width: '100%', border: '1px solid black', backgroundColor: 'white'}}></div>
                                <div style={{height:"2px", width: '100%', border: '1px solid black', backgroundColor: 'white'}}></div> 
                     
                            </div>
                        )  :  (Number(this.props.height) >= 2000 && Number(this.props.height) < 2400 ) ?(
                            <div style={{width: '100%', display:'flex', justifyContent: 'space-evenly', position:'absolute', flexDirection: 'column', height: `100%`}}>
                                <div style={{height:"2px", width: '100%', border: '1px solid black', backgroundColor: 'white'}}></div> 
                                <div style={{height:"2px", width: '100%', border: '1px solid black', backgroundColor: 'white'}}></div>
                                <div style={{height:"2px", width: '100%', border: '1px solid black', backgroundColor: 'white'}}></div>
                                <div style={{height:"2px", width: '100%', border: '1px solid black', backgroundColor: 'white'}}></div> 
                                <div style={{height:"2px", width: '100%', border: '1px solid black', backgroundColor: 'white'}}></div>
                                   
                            </div>
                            )  :  (Number(this.props.height) >= 2400 && Number(this.props.height) < 3000 ) ?(
                                <div style={{width: '100%', display:'flex', justifyContent: 'space-evenly', position:'absolute', flexDirection: 'column', height: `100%`}}>
                                    <div style={{height:"2px", width: '100%', border: '1px solid black', backgroundColor: 'white'}}></div> 
                                    <div style={{height:"2px", width: '100%', border: '1px solid black', backgroundColor: 'white'}}></div>
                                    <div style={{height:"2px", width: '100%', border: '1px solid black', backgroundColor: 'white'}}></div>
                                    <div style={{height:"2px", width: '100%', border: '1px solid black', backgroundColor: 'white'}}></div> 
                                    <div style={{height:"2px", width: '100%', border: '1px solid black', backgroundColor: 'white'}}></div>
                                    <div style={{height:"2px", width: '100%', border: '1px solid black', backgroundColor: 'white'}}></div>   
                                </div>
                        ):( <div></div>)}
                   
                  
                </div>  
                
            </div>  
       )
    }
}

export default Bars;