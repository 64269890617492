import createBox from './createBox'
import { CSG } from 'three-csg-ts';



const createHandleTypeE = (lc, lockCaseLocation, side ,doorWidth, color, id, door, profileWidth, metalness, roughness, lockCaseLocationHorizontal, doorHeight, singleDouble) =>{

    const box = createBox(
        lc.x, 
        lc.y, 
        lc.z, 
        color,
        id,
        metalness,
        roughness
    )

    const handle =  createBox(
        30, 
        100, 
        35, 
        color,
        id,
        metalness,
        roughness
    )
    const handle2 =  createBox(
        30, 
        100, 
        35, 
        color,
        id,
        metalness,
        roughness
    )

    handle.position.z = 20
    handle2.position.z = -20

    box.updateMatrix();
    handle.updateMatrix();
    handle2.updateMatrix();

    const lockCase2 = CSG.subtract(box, handle);
    const lockCase = CSG.subtract(lockCase2, handle2);





    
    if(singleDouble === "Single"){
        if(side === "right"){
            lockCase.position.x = -doorWidth/2 + lc.x/2  - Number(lockCaseLocationHorizontal);
        }else{
            lockCase.position.x = doorWidth/2 - lc.x/2  + Number(lockCaseLocationHorizontal);
        }

    }else{
        if(door === "mainDoor"){
            if(side === "left"){
                lockCase.position.x = -doorWidth/2 + lc.x/2  - Number(lockCaseLocationHorizontal);
            }else{
                lockCase.position.x = doorWidth/2 - lc.x/2  + Number(lockCaseLocationHorizontal);
            }
        }else{
            if(side === "left"){
                lockCase.position.x = doorWidth/2 - lc.x/2 -  Number(profileWidth/2) + Number(lockCaseLocationHorizontal);
            }else{
                lockCase.position.x = -doorWidth/2 + lc.x/2 +  Number(profileWidth/2) - Number(lockCaseLocationHorizontal);
            }
    
        }

    }
   

    lockCase.position.y = (-doorHeight * 0.5) + Number(lockCaseLocation);

    return(lockCase)

}



export default createHandleTypeE