import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';


// import * as firebase from 'firebase'
import firebase from "firebase/compat/app";

import {TextField, Paper, Button } from '@material-ui/core';



const styles = theme =>  ({


  textField:{
      margin: '1em',
  },
  textFieldFullWidth:{
      width: '90%'
  },
  button:{
      margin: '0.2em .5em',
  },


});



class AddComment extends Component {
  state = {
    comment: ''
  };

  addComment = () => event =>{
    firebase.database().ref(this.props.pushPath).push({
            date: Date(),
            note: this.state.comment,
            user: this.props.userEmail,
            type: 'comment'
        })

        this.setState({ comment: ''});
}

  handleChange = (name) => event =>{
       
      this.setState({
          [name]: event.target.value
      })
  }


  render() {
    const { classes } = this.props;
    

    return (
      
      <div className={classes.paper} >

      <h2>Add comment New version</h2>
              <hr/>

        <TextField
            id="comment"
            label="Comment"
            type="text"
            className={`${classes.textField} ${classes.textFieldFullWidth }`}
            value={this.state.comment}
            onChange={this.handleChange('comment')}
            margin="normal"
        />

        <Button onClick={this.addComment()} variant="outlined" > Add comment </Button>

      </div>
    )}
}

export default withStyles(styles)(AddComment);