import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import AirVentGrill from '../../SteelDoor/Drawings/ironmongery/airVentGrill';

const styles = theme => ({
    glass: {
        background: 'rgba(226,226,226,0.4)',
        background: '-moz-linear-gradient(-45deg, rgba(226,226,226,0.4) 0%, rgba(219,219,219,0.5) 40%, rgba(209,209,209,0.5) 46%, rgba(254,254,254,0.4) 100%)',
        background: '-webkit-gradient(left top, right bottom, color-stop(0%, rgba(226,226,226,0.4)), color-stop(40%, rgba(219,219,219,0.5)), color-stop(46%, rgba(209,209,209,0.5)), color-stop(100%, rgba(254,254,254,0.4)))',
        background: '-webkit-linear-gradient(-45deg, rgba(226,226,226,0.4) 0%, rgba(219,219,219,0.5) 40%, rgba(209,209,209,0.5) 46%, rgba(254,254,254,0.4) 100%)',
        background: '-o-linear-gradient(-45deg, rgba(226,226,226,0.4) 0%, rgba(219,219,219,0.5) 40%, rgba(209,209,209,0.5) 46%, rgba(254,254,254,0.4) 100%)',
        background: '-ms-linear-gradient(-45deg, rgba(226,226,226,0.4) 0%, rgba(219,219,219,0.5) 40%, rgba(209,209,209,0.5) 46%, rgba(254,254,254,0.4) 100%)',
        background: 'linear-gradient(135deg, rgba(226,226,226,0.4) 0%, rgba(219,219,219,0.5) 40%, rgba(209,209,209,0.5) 46%, rgba(254,254,254,0.4) 100%)',
        filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2e2e2', endColorstr='#fefefe', GradientType=1 )",
    },
    visionPanel: {
        marginLeft: `auto`,
        border: '1px dashed #bdbdbd',
        color: '#eee',
        marginRight: `auto`,
        '@media print': {
            border: 'none',
        },
    }
})

class Panel extends Component{

    ventHeight = () => {
      return((this.props.height.slice(0,-2) - (this.props.paddingHeight.slice(0,-2)*2)) * this.props.printRatio)
    }

    ventWidth = () => {
      return((this.props.width.slice(0,-2) - (this.props.paddingWidth.slice(0,-2)*2))* this.props.printRatio)
    }

    state= {}

    render() {
      const { classes } = this.props;
        return (
          
          <div className="panel"
                  style={{
                      boxSizing: 'border-box',
                      width:  this.props.width, 
                      height:  this.props.height , 
                      background: "white",  
                      position: 'relative',  
                      border: '0.001rem solid black', 
                      margin: '0px auto',
                      padding:  `${this.props.paddingHeight} ${this.props.paddingWidth}`
                  }}>

                    <div style={{ 
                        border: '0.001rem solid black',
                        width: "100%",
                        height: '100%',
                        position: 'relative'
                      }}>

                      

                    
                        {this.props.ventilationList !== undefined &&
                          this.props.ventilationList.map( (vent)=>{
                              if(vent.fixing === this.props.panelPlacement){
                                return (<AirVentGrill key={vent.key} left={0} paddingHeight={this.props.paddingHeight} paddingWidth={this.props.paddingWidth} height={this.ventHeight()} width={this.ventWidth()} bottom={0} printRatio={this.props.printRatio} id={1}></AirVentGrill>)
                              }
                            }
                          )
                        }

                        {this.props.visionPanels !== undefined &&
                          this.props.visionPanels.map( (glass, index)=>{
                              if(glass.fixing === this.props.panelPlacement){
                                return (<div key={index}
                                     style={{height: "100%"}} className={this.props.classes.glass}></div>)
                              }
                            }
                          )
                        }
                   
                    </div>
            </div>
      
      
       )
    }
}

export default withStyles(styles)(Panel);