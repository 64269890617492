import React, { Component } from 'react';
// import * as firebase from 'firebase'
import firebase from "firebase/compat/app";
import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import OrderDetailsTable from '../orders/OrderDetailsTable';
import Paper from '@material-ui/core/Paper';


import {  suppliers } from '../logic/financialReports';


const styles = theme => ({
    root: {
        //   backgroundColor: theme.palette.background.paper,
        width: '100%',
        margin: '50 auto',
    },
    btn: {
        backgroundColor: '#d60a2e',
        color: '#ffffff',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },

    textField: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: 300,
        ['@media (max-width:600px)']: {
            display: 'inline-flex',
            width: '90%',
            margin: '10px 5%'
        }

    },
    textFieldShort: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: 70,
        ['@media (max-width:600px)']: {
            display: 'inline-flex',
            width: '90%',
            margin: '10px 5%'
        }
    },
    paper: {
        margin: '20px',
        overflowX: 'scroll',
        padding: '20px'
    },

});

const vat = [
    {
        value: '23',
        label: '23%',
    },
    {
        value: '21',
        label: '21%',
    },
    {
        value: '135',
        label: '13.5%',
    },
    {
        value: '0',
        label: '0%',
    },

];

// const suppliers = [
//     {
//         value: 'Donimet',
//         label: 'Donimet',
//     },
//     {
//         value: 'OC Slim',
//         label: 'OC Slim',
//     },
//     {
//         value: 'Interproduct',
//         label: 'Interproduct',
//     },
//     {
//         value: 'Pagen',
//         label: 'Pagen',
//     },
//     {
//         value: 'Entra',
//         label: 'Entra',
//     },
//     {
//         value: 'Anthony',
//         label: 'Anthony',
//     },

// ]

class OrderDetails extends Component {
    state = {
        name: 'New item',
        supplier: 'Donimet',
        vat: 23,
        discount: 0,
        qty: 1,
        price: 0.00,
    }

    fieldUpdate = name => event => {
        this.setState({
            [name]: event.target.value
        })
    }

    newCost = () => {
        const ordersRef = firebase.database().ref().child(`Orders/${this.props.orderId}/AllItems`);
        if (this.state.name === '') {
            alert('Add Cost Name');
        } else if (this.state.qty === '') {
            alert('Add Invoice Number');
        } else if (this.state.discount === '') {
            alert('Add Cost');
        } else if (this.state.date === '') {
            alert('Add Date');
        }
        else {
            ordersRef.push({
                'name': this.state.name,
                supplier: this.state.supplier,
                'vat': this.state.vat,
                'discount': this.state.discount,
                'qty': this.state.qty,
                'price': this.state.price,
            });

            this.setState({
                name: 'New item',
                supplier: 'Donimet',
                vat: 23,
                discount: 0,
                qty: 1,
                price: 0.00,
            })
        }
    }


    render() {

        const { classes } = this.props;

        return (
            <div>
                <Paper className={this.props.classes.paper} elevation={0} variant="outlined" >



                    <TextField
                        id="discount-name"
                        label="Item Name"
                        value={this.state.name}
                        onChange={this.fieldUpdate('name')}
                        className={classes.textField}
                    />


                    <TextField
                        id="supplier"
                        select
                        label="Supplier"
                        className={classes.textField}
                        value={this.state.supplier}
                        onChange={this.fieldUpdate('supplier')}
                        SelectProps={{
                            native: true,
                            MenuProps: {
                                className: classes.menu,
                            },
                        }}
                        helperText=""

                    >
                        {suppliers.map(option => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </TextField>



                    <TextField
                        id="qty"
                        label="Qty"
                        value={this.state.qty}
                        onChange={this.fieldUpdate('qty')}
                        className={classes.textFieldShort}
                    />

                    <TextField
                        id="vat"
                        select
                        label="VAT rate"
                        className={classes.textFieldShort}
                        value={this.state.vat}
                        onChange={this.fieldUpdate('vat')}
                        SelectProps={{
                            native: true,
                            MenuProps: {
                                className: classes.menu,
                            },
                        }}
                        helperText=""

                    >
                        {vat.map(option => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </TextField>

                    <TextField
                        id="discount"
                        label="Discount"
                        value={this.state.discount}
                        onChange={this.fieldUpdate('discount')}
                        type="number"
                        className={classes.textFieldShort}
                        InputLabelProps={{
                            shrink: true,
                        }}

                    />
                    <TextField
                        id="price"
                        label="Price"
                        value={this.state.price}
                        type="number"
                        onChange={this.fieldUpdate('price')}
                        className={classes.textFieldShort}
                    />


                    <Button variant="outlined" color="primary" onClick={this.newCost}>Add New</Button>
                </Paper>

                <OrderDetailsTable orderId={this.props.orderId} sysUser={this.props.sysUser}></OrderDetailsTable>



            </div>

        );

    }
}

export default withStyles(styles)(OrderDetails);