import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    diag: {
        position: 'absolute',
  
        left: '0',
        top: '0',
        width: '100%',
        height: '100%',
        background: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%'><line x1='0' y1='0' x2='100%' y2='50%' stroke='rgb(237, 237, 237)' stroke-width='1' stroke-dasharray='5,5'  /><line x1='0' y1='100%' x2='100%' y2='50%' stroke='rgb(237, 237, 237)' stroke-width='1' stroke-dasharray='5,5'  /></svg>") no-repeat`,
    } 
  
})

class OpeningDirection extends Component{

    state= {
       
    }

    opSide = () => {
        if(this.props.side === "left"){

            // if(this.props.leaf === 'nonActive'){
            //     return {transform : 'rotate(1800deg)'};
            // }else{
            //     return {transform : 'rotate(0deg)'};
            // }
            return {transform : 'rotate(180deg)'};
        }else{
            return {transform : 'rotate(0deg)'};
            // if(this.props.leaf === 'nonActive'){
            //     return {transform : 'rotate(0deg)'};
            // }else{
            //     return {transform : 'rotate(180deg)'};
            // }
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.diag} style={this.opSide()}></div>
        )
    }
}

export default withStyles(styles)(OpeningDirection);