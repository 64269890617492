import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
//ironmongery
import PanicBar from '../../SteelDoor/Drawings/ironmongery/panicBar';
import Hinges from '../../SteelDoor/Drawings/ironmongery/hinges';
import AntiBurglaryBolts from '../../SteelDoor/Drawings/ironmongery/antiBurglaryBolts';
import Closer1 from '../../SteelDoor/Drawings/ironmongery/closer1';
import Closer2 from '../../SteelDoor/Drawings/ironmongery/closer2';
import Closer3 from '../../SteelDoor/Drawings/ironmongery/closer3';
import OpeningDirection from '../../SteelDoor/Drawings/ironmongery/openingDirection';
import ArmouredCable from '../../SteelDoor/Drawings/ironmongery/armouredCable';
import RoundVent from '../../SteelDoor/Drawings/ironmongery/roundVent';
import AirGrill from '../../SteelDoor/Drawings/ironmongery/airGrill';
import AirGrill2 from '../../SteelDoor/Drawings/ironmongery/airGrill2';
import AirVentGrill from '../../SteelDoor/Drawings/ironmongery/airVentGrill';
import Louver from '../../SteelDoor/Drawings/ironmongery/louver';
import PushPull from '../../SteelDoor/Drawings/ironmongery/pushPull';
import Kickplate3 from '../../SteelDoor/Drawings/ironmongery/kickplate3';
import BarsRC3 from '../../SteelDoor/Drawings/barsRC3';
import PushPull2 from '../../SteelDoor/Drawings/ironmongery/pushPull2';



const styles = theme => ({
    glass: {
        background: 'rgba(226,226,226,0.4)',
        background: '-moz-linear-gradient(-45deg, rgba(226,226,226,0.4) 0%, rgba(219,219,219,0.5) 40%, rgba(209,209,209,0.5) 46%, rgba(254,254,254,0.4) 100%)',
        background: '-webkit-gradient(left top, right bottom, color-stop(0%, rgba(226,226,226,0.4)), color-stop(40%, rgba(219,219,219,0.5)), color-stop(46%, rgba(209,209,209,0.5)), color-stop(100%, rgba(254,254,254,0.4)))',
        background: '-webkit-linear-gradient(-45deg, rgba(226,226,226,0.4) 0%, rgba(219,219,219,0.5) 40%, rgba(209,209,209,0.5) 46%, rgba(254,254,254,0.4) 100%)',
        background: '-o-linear-gradient(-45deg, rgba(226,226,226,0.4) 0%, rgba(219,219,219,0.5) 40%, rgba(209,209,209,0.5) 46%, rgba(254,254,254,0.4) 100%)',
        background: '-ms-linear-gradient(-45deg, rgba(226,226,226,0.4) 0%, rgba(219,219,219,0.5) 40%, rgba(209,209,209,0.5) 46%, rgba(254,254,254,0.4) 100%)',
        background: 'linear-gradient(135deg, rgba(226,226,226,0.4) 0%, rgba(219,219,219,0.5) 40%, rgba(209,209,209,0.5) 46%, rgba(254,254,254,0.4) 100%)',
        filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2e2e2', endColorstr='#fefefe', GradientType=1 )",
    },
    visionPanel: {
        marginLeft: `auto`,
        border: '1px dashed #bdbdbd',
        color: '#eee',

        marginRight: `auto`,
        '@media print': {
            border: 'none',
        },
    }

})

class NonActiveLeaf extends Component {

    state = {}

    singleDouble = () => {

        if (this.props.singleDouble === "Single") {
            return ((parseInt(this.props.mainLeaf) + this.props.clearOpeningWidth) / this.props.printRatio);
        } else {
            return ((parseInt(this.props.mainLeaf)) / this.props.printRatio);
        }

    }

    checkIfOhefItemExist = (item) => {

        let itemIndex = this.props.additional.findIndex(x => x.label.includes(item));

        if (itemIndex === -1) {
            return false;
        } else {
            return true;
        }
    }

    frame = () => {

        if (this.props.frameType === "type-2") {

            return ({
                height: `${((this.props.height) / this.props.printRatio) + (((this.props.clearOpeningHeight) / this.props.printRatio) * 0.3) - 2}px`,
                width: `${(((this.props.width - this.props.mainLeaf) + (this.props.clearOpeningHeight / 2)) / this.props.printRatio)}px`,
                border: '1px solid black',
                position: 'absolute',
                margin: '0 auto',
                [this.props.restSide]: `${(((this.props.clearOpeningWidth) / this.props.printRatio) * 0.35) - 1}px`,
                top: `${(((this.props.clearOpeningHeight) / this.props.printRatio) * 0.7) - 1}px`,
                background: 'none',
            })
        } else {
            return ({
                height: `${((this.props.height) / this.props.printRatio) + (((this.props.clearOpeningHeight) / this.props.printRatio) * 0.3)}px`,
                width: `${(((this.props.width - this.props.mainLeaf) + (this.props.clearOpeningHeight)) / this.props.printRatio)}px`,
                border: '1px solid black',
                position: 'absolute',
                margin: '0 auto',
                [this.props.restSide]: `${(((this.props.clearOpeningWidth / .65) / this.props.printRatio) * 0.5)}px`,
                top: `${(((this.props.clearOpeningHeight + 35) / this.props.printRatio))}px`,
                background: 'none',
            })
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <div id="secondLeaf" style={this.frame()}>
                <small style={{ position: 'absolute', bottom: '-20px', position: 'absolute', transform: 'translate(-50%)' }}> {this.props.width - this.props.mainLeaf}mm</small>
                {this.props.doorType === "Gate RC3" && <span>
                    {this.props.singleDouble === "Single" ? (
                        < BarsRC3 width={this.props.mainLeaf} printRatio={this.props.printRatio} height={this.props.height} singleDouble={this.props.singleDouble}></BarsRC3>
                    ) : (
                        < BarsRC3 width={Number(this.props.width) - Number(this.props.mainLeaf)} printRatio={this.props.printRatio} height={this.props.height} singleDouble={this.props.singleDouble}></BarsRC3>
                    )}
                </span>}
                <Hinges side={this.props.handleSide} printRatio={this.props.printRatio} fire={this.props.fire} grade={this.props.grade}></Hinges>
                <AntiBurglaryBolts side={this.props.handleSide} printRatio={this.props.printRatio}></AntiBurglaryBolts>
                <OpeningDirection side={this.props.handleSide} leaf={'nonActive'} printRatio={this.props.printRatio}></OpeningDirection>

                {this.props.additional.map((opt, index) => {


                    if (opt.label.includes('guide rail') || opt.label.includes('DC 500')) {

                        let side = this.props.handleSide === 'left' ? 'right' : 'left'
                        if (opt.leafChoice === false) {
                            return (<Closer1 key={index} handleSide={side} printRatio={this.props.printRatio} inside={opt.inside}></Closer1>)
                        }
                    }

                    if (opt.label.includes('with link arm')) {
                        let side = this.props.handleSide === 'left' ? 'right' : 'left'
                        if (opt.leafChoice === false) {
                            return (<Closer2 key={index} handleSide={side} printRatio={this.props.printRatio} inside={opt.inside}></Closer2>)
                        }
                    }

                    if (opt.label.includes('DC340 with integrated mechanical coordinator') || opt.label.includes('DC500 with integrated mechanical coordinator') ) {
                        return (<Closer3 key={index} handleSide={this.props.handleSide} leaf={'nonActive'} printRatio={this.props.printRatio} inside={opt.inside}></Closer3>)
                    }



                    if (opt.label.includes('Kick plate sqm')) {
                        if (opt.leafChoice === false) {
                            return (<Kickplate3 key={index} handleSide={this.props.handleSide} printRatio={this.props.printRatio} bottom={opt.bottom} sideDist={opt.sideDist} width={opt.width} height={opt.height} inside={opt.inside} swingDirection={this.props.swingDirection} bottom={opt.bottom}></Kickplate3>)
                        }
                    }
                    if (opt.label.includes('Push plate sqm')) {
                        if (opt.leafChoice === false) {
                            return (<Kickplate3 key={index} handleSide={this.props.handleSide} printRatio={this.props.printRatio} bottom={opt.bottom} sideDist={opt.sideDist} width={opt.width} height={opt.height} inside={opt.inside} swingDirection={this.props.swingDirection} bottom={opt.bottom}></Kickplate3>)
                        }
                    }

                    if (opt.label.includes('Armoured cable door')) {

                        if (this.checkIfOhefItemExist('strike')) {
                            return (<ArmouredCable key={index} side={this.props.handleSide} printRatio={this.props.printRatio} bottom={opt.bottom}></ArmouredCable>)
                        }
                    }

                    if (opt.label.includes('Push/Pull handle - inactive leaf')) {
                        return (<PushPull key={index} side={this.props.doorSide} printRatio={this.props.printRatio} sideDist={opt.sideDist} bottom={opt.bottom}></PushPull>)
                    }

                    if (opt.label.includes('Push/Pull handle type 2 - inactive leaf')) {
                        return (<PushPull2 key={index} side={this.props.doorSide} printRatio={this.props.printRatio} sideDist={opt.sideDist} bottom={opt.bottom}></PushPull2>)
                    }

                    if (opt.label.includes('Main Lock - Panic bar NEMEF on both leaves')) {
                        return (
                            <PanicBar key={index} handleSide={this.props.handleSide} printRatio={this.props.printRatio} bottom={opt.bottom}></PanicBar>
                        )
                    }

                })}


                <div className={classes.visionPanel} style={{
                    marginTop: `${this.props.visionPanelRestrictions.top / this.props.printRatio}px`,
                    marginBottom: ` ${this.props.visionPanelRestrictions.bottom / this.props.printRatio}px`,
                    // marginRight: ` auto`,

                    // marginLeft:`auto`, 
                    // border: '1px dashed #bdbdbd', 
                    // color: '#eee',
                    width: `${parseInt(this.props.maxVPWidth2) / this.props.printRatio}px`,
                    height: `${parseInt(this.props.maxVPHeight) / this.props.printRatio}px`,
                }}>
                    <div id="glassWrapper2" style={{ position: 'relative', height: '100%' }}>

                        {this.props.visionPanel === true && (this.props.secondLeaf - (this.props.visionPanelRestrictions.left + this.props.visionPanelRestrictions.right)) / this.props.printRatio > 0 &&
                            <span>
                                {this.props.visionPanels.length > 0 &&

                                    <span>
                                        {this.props.visionPanels.map(panel => {
                                            if (!panel.mainDoor) {
                                                return <div className={classes.glass} key={panel.key} style={{
                                                    width: `${panel.glassWidth / this.props.printRatio}px`,
                                                    height: `${panel.glassHeight / this.props.printRatio}px`,
                                                    border: '1px solid rgba(0,0,0,0.2)',
                                                    position: 'Absolute',
                                                    bottom: `${panel.bottom / this.props.printRatio}px`,
                                                    left: `${panel.left / this.props.printRatio}px`,
                                                }} >
                                                </div>
                                            }
                                        })}
                                    </span>
                                }
                            </span>
                        }
                        {this.props.ventilation === true && (this.props.secondLeaf - (this.props.visionPanelRestrictions.left + this.props.visionPanelRestrictions.right)) / this.props.printRatio > 0 &&
                            <span>
                                {this.props.ventilationList.length > 0 &&
                                    <span>
                                        {
                                            this.props.ventilationList.map(vent => {

                                                if (vent.label.includes('Round vent sleeve')) {
                                                    if (!vent.mainDoor) {
                                                        return (<RoundVent key={vent.key} left={vent.left} bottom={vent.bottom} printRatio={this.props.printRatio} handleVentPosition={this.props.handleVentPosition} id={vent.key} ></RoundVent>)
                                                    }
                                                }

                                                if (vent.label.includes('grill 400mm')) {
                                                    if (!vent.mainDoor) {
                                                        return (<AirGrill key={vent.key} left={vent.left} bottom={vent.bottom} printRatio={this.props.printRatio} handleVentPosition={this.props.handleVentPosition} id={vent.key}></AirGrill>)
                                                    }
                                                }

                                                if (vent.label.includes('grill 500mm')) {
                                                    if (!vent.mainDoor) {
                                                        return (<AirGrill2 key={vent.key} left={vent.left} bottom={vent.bottom} printRatio={this.props.printRatio} handleVentPosition={this.props.handleVentPosition} id={vent.key}></AirGrill2>)
                                                    }
                                                }

                                                if (vent.label.includes('Air vent grill')) {
                                                    if (!vent.mainDoor) {
                                                        return (<AirVentGrill key={vent.key} left={vent.left} height={vent.height} width={this.props.maxVPWidth2} bottom={vent.bottom} printRatio={this.props.printRatio} handleVentPosition={this.props.handleVentPosition} id={vent.key}></AirVentGrill>)
                                                    }
                                                }

                                                if (vent.label.includes('Louver vent')) {
                                                    if (!vent.mainDoor) {
                                                        return (<Louver key={vent.key} left={vent.left} height={vent.height} width={this.props.maxVPWidth} bottom={vent.bottom} printRatio={this.props.printRatio} handleVentPosition={this.props.handleVentPosition} id={vent.key}></Louver>)
                                                    }
                                                }

                                            })
                                        }
                                    </span>
                                }
                            </span>
                        }

                    </div>
                </div>

            </div>
        )
    }
}

export default withStyles(styles)(NonActiveLeaf);