import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import Frame2 from '../SteelDoor/Frame2';
import Frame1 from '../SteelDoor/Frame1';
import Grid from '@material-ui/core/Grid';
import Header from '../Quotes/QuoteHeader';

const styles = theme => ({

    quotePrint: {
        borderBottom: '1px solid #e5e5e5',
        margin: '0px',
        '@media print': {
            margin: '0cm',
            padding: '0cm',
            pageBreakInside: 'avoid',
            borderTop: '1px solid #e5e5e5'
        },
    },
    printRatio: {
        position: 'fixed',
        right: 0,
        top: '100px',
        width:  '32px',
        overflow: 'hidden',
        margin: '0px',
        '@media print': {
            display: 'none',
        }
    },
    preparePrint1:{
        textAlign: 'left',
        
        '@media print': {
            flexBasis: '30%'
        },
    },
    preparePrint:{
        borderLeft: '1px solid #eee',
        
        '@media print': {
            flexBasis: '70%'
        },
    }, 
   
    
    
    onlyPrint:{
        display: 'none',
        width:'100%',
        '@media print': {
            display: 'box'
        },
    }
});

class DoorSpec extends Component{

    componentDidMount(){}
    // Changed from PL to IE siede 
    changeSide = () =>{
        if(this.props.side === 'Left Hand'){
            return 'Right Hand';
        }else{
            return 'Left Hand'
        }
    }
    render(){
        const { classes } = this.props;

        return(
            <Grid container  spacing={0} style={{marginTop: '0px'} } className={classes.quotePrint}>
            <span className={classes.onlyPrint}>
                <Header  
                   
                    companyName={this.props.companyName}
                    contactName={this.props.contactName}
                    contactEmail={this.props.contactEmail}
                    phoneNumber={this.props.phoneNumber}
                    firstAddressLine={this.props.firstAddressLine }
                    secondAddressLine={this.props.secondAddressLine}
                    city={this.props.city}
                    county={this.props.county}
                    eircode={this.props.eircode}
                    country={this.props.country}
                    quoteDate={this.props.quoteDate}
                    quoteNo={this.props.quoteNo}
                    quoteID={this.props.quoteID}
                    refernceNumber={this.props.refernceNumber}
                ></Header>
            </span>
            <Grid item  md={3}  className={classes.preparePrint1}>
            <h4 >{this.props.doorName}</h4>
            <h4>{this.props.singleDouble} {this.props.singleDouble==="Single" ? <span>Door</span>: <span>Doors</span> } - {this.props.doorType}</h4>
            <p>

             
            {this.props.grade !== "" && <><strong>Grade: {this.props.grade} </strong> <br/></> }
            {this.props.fire === 'Fire' && 
                <>
                    <strong>Fire resistance:  </strong> 

                
                    {
                        ( this.props.fire === 'Fire' &&  this.props.glass === 'P6+Frame+EI60') && <span> EI60s</span> || 
                        ( this.props.fire === 'Fire' &&  this.props.glass === 'P5+Frame+EI60') && <span> EI60s</span> || 
                        ( this.props.fire === 'Fire' &&  this.props.glass === 'P6+Frame+EI30') && <span> EI30s</span> || 
                        ( this.props.fire === 'Fire' &&  this.props.glass === 'P5+Frame+EI30') && <span> EI30s</span> || 
                        
                      
                        this.props.fire === 'Fire' &&  <span> EI60s</span>
                    }
                <br/></> }
              
             
            <strong>Side:</strong> {this.props.side} - {this.props.swingDirection} <br/>
            <strong>Finishing (RAL)</strong> - Inside: {this.props.colorInside} - Outside: {this.props.colorOutside}
            </p>
            <strong>Dimensions: </strong>

            <p>
                <strong>Structural Opening:</strong> {parseInt(this.props.width) + parseInt(this.props.clearOpeningWidth) + 20}mm x {parseInt(this.props.height) + parseInt(this.props.clearOpeningHeight)+ parseInt(this.props.threshold) + Number(this.props.transomHeight) + 10}mm<br/>
                {this.props.transom && <><strong>Transom:</strong> { Number(this.props.transomHeight)}mm<br/> </>}
                {this.props.leftPanel && <><strong>Left Panel:</strong> { Number(this.props.leftPanelWidth)}mm x {parseInt(this.props.height) + parseInt(this.props.clearOpeningHeight)+ parseInt(this.props.threshold)}mm<br/> </>}
                {this.props.rightPanel && <><strong>Right Panel:</strong> { Number(this.props.rightPanelWidth)}mm x {parseInt(this.props.height) + parseInt(this.props.clearOpeningHeight)+ parseInt(this.props.threshold)}mm<br/> </>}
                {
                    this.props.frameType === 'type-2' ? 
                    <span><strong>Door Frame:</strong> {parseInt(this.props.width) + parseInt(this.props.clearOpeningWidth)}mm x {parseInt(this.props.height) + parseInt(this.props.clearOpeningHeight)+ parseInt(this.props.threshold) }mm<br/></span> 
                    :
                    <span><strong>Door Frame:</strong> {parseInt(this.props.width) + parseInt(this.props.clearOpeningWidth) +80 }mm x {parseInt(this.props.height) + parseInt(this.props.clearOpeningHeight)+ parseInt(this.props.threshold) + 40 }mm<br/></span>
                }
                
                <strong>Clear Opening:</strong> {this.props.width}mm x {this.props.height}mm <br/>
                <strong>Effective Opening:</strong>

                {this.props.width - this.props.effectiveOpening < this.props.width ? <span> {this.props.width - this.props.effectiveOpening}mm x {this.props.height}mm </span> :  <span> TBC</span>
                
            
            }

            </p>
            
            <strong>Ironmongery: </strong>
            <ul>
            { 

            
                
                 this.props.additional  !== [] &&
                    this.props.additional.map(option => (
                    <li key={option.key}>
                        {option.label} 
                        {(option.label.includes('AHW500') || option.label.includes('TL0403')) && ` - mounting height (${option.bottom}mm)`} 
                        { option.label === 'Main Lock - Panic bar Mottura Class.7 - Bar/Handle' && ` - Panic bar mounting height (${Number(option.bottom)}mm), handle mounting height (${Number(option.bottom) + 70}mm)`} 
                        { option.label.includes('Main Lock - Panic bar NEMEF') && ` - Panic bar mounting height (${Number(option.bottom)}mm)`}
                        {'inside' in option && <span>
                        
                        { this.props.swingDirection === "Inswing" ? (
                        
                            option.inside === true 
                                ? <span>(Mounted  Inside)</span> 
                                : <span> (Mounted  Outside)</span> 
                            ):(
                                option.inside === true 
                                ? <span>(Mounted  Outside)</span> 
                                : <span> (Mounted  Inside)</span>
                            )
                        }

                        </span>}
                  
                    </li>
                    ))


                }
                
            </ul>
        
            
            { this.props.visionPanel === true &&
                <span>
                    <strong>Vision Panel: </strong>
                    Type: {this.props.glass} <br/>
                    <p>
                        {/* {this.props.mirrorEffect === true && <span> - mirror effect <br/></span> } */}
                        {this.props.thermalIsolation === true && <span>- thermal isolation</span> }
                    </p>
               
               </span>

            }

            { this.props.ventilation === true &&
                <span>
                    <strong>Ventilation: </strong>
                    <p>
                        {this.props.ventilationList.map( vent => (
                            < span key={vent.key}> - {vent.label}
                            <br/></span>  
                        ))}
                    </p>
               </span>
            }

            {   this.props.extraOver !== undefined && <span>
                
                {this.props.extraOver.length > 0 &&
                <span>
                    <strong>Extra over</strong>
                    <ul>
                        {
                            this.props.extraOver.map( (item, index)  =>{
                                return <li key={index}>{item.label} </li>                                
                            } )
                        }
                    </ul>
                </span>
                }
                </span>
            }



            { (this.props.notes !== "<p><br></p>" && this.props.notes !== "" ) &&
                <span>
                    <strong>Notes: </strong>

                        <div dangerouslySetInnerHTML={{ __html: this.props.notes }}></div>
               </span>
            }

            </Grid>
            <Grid item  md={9} className={classes.preparePrint}  >

            { this.props.frameType === "type-1" &&
                <div>
                    {/* <small>For presentation purpose only</small> */}
                 
                    
                    {this.props.width * this.props.height > 0.8 &&
                    
                    <Frame1
                        waFrame={this.props.waFrame}
                        height={this.props.height}
                        width={this.props.width}
                        clearOpeningHeight={this.props.clearOpeningHeight}
                        clearOpeningWidth={this.props.clearOpeningWidth}
                        side={this.changeSide()}
                        singleDouble={this.props.singleDouble}
                        doubleDoorMainLeafwidth={this.props.doubleDoorMainLeafwidth}
                        threshold={this.props.threshold}
                        frameDepth={this.props.frameDepth}
                        handleWidth={this.props.handleWidth}
                        swingDirection={this.props.swingDirection}
                        printRatio={this.props.printRatio}
                        fire={this.props.fire}
                        grade={this.props.grade}
                        additional={this.props.additional}
                        visionPanel={this.props.visionPanel}
                        visionPanelRestrictions={this.props.visionPanelRestrictions}
                        visionPanels={this.props.visionPanels}
                        maxVPWidth2={this.props.maxVPWidth2}
                        maxVPWidth={this.props.maxVPWidth}
                        maxVPHeight={this.props.maxVPHeight}
                        ventilationList={this.props.ventilationList}
                        ventilation={this.props.ventilation}
                        frameType={this.props.frameType}
                        doorType={this.props.doorType}
                    ></Frame1>
                    }
                    
                    { parseInt(this.props.width-this.props.clearOpeningWidth-this.props.handleWidth) > 899 && 
                        <small>According to IWA recomendation minimum functional clear opening  width 900mm</small> 
                    }

                </div>
                }

        
        { this.props.frameType === "type-2" &&

            <div>
                
                {this.props.width * this.props.height > 0.8 &&
                <Frame2
           
                    height={this.props.height}
                    width={this.props.width}
                    clearOpeningHeight={this.props.clearOpeningHeight}
                    clearOpeningWidth={this.props.clearOpeningWidth}
                    side={this.changeSide()}
                    singleDouble={this.props.singleDouble}
                    doubleDoorMainLeafwidth={this.props.doubleDoorMainLeafwidth}
                    threshold={this.props.threshold}
                    frameDepth={this.props.frameDepth}
                    handleWidth={this.props.handleWidth}
                    swingDirection={this.props.swingDirection}
                    printRatio={this.props.printRatio}
                    fire={this.props.fire}
                    grade={this.props.grade}
                    additional={this.props.additional}
                    visionPanel={this.props.visionPanel}
                    visionPanelRestrictions={this.props.visionPanelRestrictions}
                    visionPanels={this.props.visionPanels}
                    maxVPWidth2={this.props.maxVPWidth2}
                    maxVPWidth={this.props.maxVPWidth}
                    maxVPHeight={this.props.maxVPHeight}
                    ventilationList={this.props.ventilationList}
                    ventilation={this.props.ventilation}
                    doorType={this.props.doorType}
                    frameType={this.props.frameType}
                    transom={this.props.transom}
                    transomType={this.props.transomType}
                    leftPanel={this.props.leftPanel}
                    rightPanel={this.props.rightPanel}
                    transomHeight={ this.props.transomHeight}
                    leftPanelWidth={this.props.leftPanelWidth}
                    rightPanelWidth={this.props.rightPanelWidth}
                   
                >
                </Frame2>
                }
               
                {/* { parseInt(this.props.width-this.props.clearOpeningWidth-this.props.handleWidth) > 899 && 
                    <small>According to IWA recomendation minimum functional clear opening  width 900mm</small> 
                } */}

            </div>
        }
            </Grid>
        </Grid>
        )
    }

}
export default withStyles(styles)(DoorSpec);