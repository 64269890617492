import React, { Component } from 'react';

class FlushPull extends Component{

    state= {}
    render() {

        return (
                <div style={{ height: `${120/this.props.printRatio}px`,  width: `${120/this.props.printRatio}px`, border: '1px solid #000', position: 'absolute',margin: '0 auto',[this.props.handleSide]: `${20/this.props.printRatio}px`,bottom: `${this.props.bottom/this.props.printRatio}px`,background: 'white',borderRadius: '50%', display: 'flex',  alignItems: 'center' }}>   
                    <div style={{ height: `${72/this.props.printRatio}px`,  width: `${54/this.props.printRatio}px`, border: '1px solid #000', justifyContent: 'center', borderBottomRightRadius: '80%' ,borderTopRightRadius:  '80%',     transform: 'translate(52%)'}}></div> 
                </div>
        )
    }
}

export default FlushPull;