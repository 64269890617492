import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FlipToFront from '@material-ui/icons/FlipToFront';


import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';


const styles = theme => ({
 
    printRatio:{
        margin: '10px',
        width: '80px',
        outline:0,
       
    },
    icon:{
        '@media print':{
            'display': 'none !important',
        }
    }
});



class PrintRatio extends Component{
    
    state = {
        open: false,  
        anchorEl: null,
    }

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
      };
    
    handleClose = () => {
        this.setState({ anchorEl: null , open: false,}); 
    };
    

    componentDidMount(){}

    render() {

        return (
            <div >
                 <Button 
                        className={this.props.classes.icon}
                        //variant="outlined" 
                        
                        //color="primary" 
                        aria-label="menu" 
                        aria-owns={this.state.anchorEl ? 'simple-menu' : undefined}
                        aria-haspopup="true"
                        onClick={this.handleClick}
                        style={{
                            position: 'absolute',
                            [this.props.vertical]: `${this.props.verticalOffset}px`,
                            [this.props.horizontal]: `${this.props.horizontalOffset}px`,

                        }}
                    ><FlipToFront/>
                    </Button>

                    <Menu
                        id="simple-menu"
                        anchorEl={this.state.anchorEl}
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.handleClose}
                        className={this.props.classes.menu} 
                        >

                    <TextField
                        id="printRatio"
                        label="Print Ratio"
                        type="number"
                        className={`${this.props.classes.textField} ${this.props.classes.printRatio}`}
                        value={this.props.printRatio}
                        onChange={this.props.handleChange('printRatio')}
                        margin="normal"
                        />
                       
                    </Menu>
            </div>
        );
        
    }
  }
export default withStyles(styles)(PrintRatio);
