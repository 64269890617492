import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Exit from '@material-ui/icons/ExitToApp';
import Person from '@material-ui/icons/Person';
import Home from '@material-ui/icons/Home';
import { userContext } from '../Context/userContext';

import Description from '@material-ui/icons/Description'

import logo from '../logo.svg';

import Ballot from '@material-ui/icons/Ballot';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import ReceiptIcon from '@material-ui/icons/Receipt';
import HowToRegSharpIcon from '@material-ui/icons/HowToRegSharp';

import HomeIcon from '@material-ui/icons/Home';
import history from '../history';




const styles = {
  root: {
    flexGrow: 1,
  },
  flex: {
    flex: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  list: {
    width: 280,
  },
  fullList: {
    width: 'auto',
  },
  appBar: {
    '@media print': {
      display: 'none',
    }
  },
  button: {
    color: '#ffffff',

  },
  logo: {
    position: 'absolute',
    padding: '5px 50px',
    top: '8px',
    width: '115px',
    transform: 'translate(-50%)',
  }
};


class ButtonAppBar extends Component {



  state = {
    right: false,
  };



  componentDidMount = () => {


  }

  componentDidUpdate = () => {
   // console.log(history)
  
  }


  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    });
  };


  render() {
    const { classes } = this.props;



    const sideList = (
      <div className={classes.list}>

        <userContext.Consumer>
          {(user) => (<div>

            <List component="nav">
              <ListItem >
                <ListItemIcon>
                  <Person />
                </ListItemIcon>
                <ListItemText primary={user.name} />
                <ListItemText primary={`Lv: ${user.access}`} />
              </ListItem>
            </List>

            <Divider />

            <List component="nav">
              <Link to="/home">
                <ListItem button>
                  <ListItemIcon>
                    <Home />
                  </ListItemIcon>
                  <ListItemText primary="Home" />
                </ListItem>
              </Link>

              <Divider />

              <Link to="/salespipeline" >
                <ListItem button>
                  <ListItemIcon>
                    <ContactMailIcon />
                  </ListItemIcon>
                  <ListItemText primary="Sales Pipeline" />
                </ListItem>
              </Link>


              <Link to="/orders">
                <ListItem button>
                  <ListItemIcon>
                    <LocalShippingIcon />
                  </ListItemIcon>
                  <ListItemText primary="Active Orders" />
                </ListItem>
              </Link>

              <Link to="/closed-orders">
                <ListItem button>
                  <ListItemIcon>
                    <Ballot />
                  </ListItemIcon>
                  <ListItemText primary="Closed Orders" />
                </ListItem>
              </Link>





              <Link to="/quotes">
                <ListItem button>
                  <ListItemIcon>
                    <ReceiptIcon />
                  </ListItemIcon>
                  <ListItemText primary="Quotes" />
                </ListItem>
              </Link>

              <Link to="/customers">
                <ListItem button>
                  <ListItemIcon>
                    <HowToRegSharpIcon />
                  </ListItemIcon>
                  <ListItemText primary="Customers" />
                </ListItem>
              </Link>

              <Link to="/steel-doors">
                <ListItem button>
                  <ListItemIcon>
                    <Description />
                  </ListItemIcon>
                  <ListItemText primary="Create Steel Doors Quote" />
                </ListItem>
              </Link>
              <Link to="/flexi-quote">
                <ListItem button>
                  <ListItemIcon>
                    <Description />
                  </ListItemIcon>
                  <ListItemText primary="Create Flexi Quote" />
                </ListItem>
              </Link>
              <Divider />

              <Link to="/lostdeals">
                <ListItem button>
                  <ListItemIcon>
                    <Description />
                  </ListItemIcon>
                  <ListItemText primary="Lost Deals" />
                </ListItem>
              </Link>
              <Link to="/wondeals">
                <ListItem button>
                  <ListItemIcon>
                    <Description />
                  </ListItemIcon>
                  <ListItemText primary="Won Deals" />
                </ListItem>
              </Link>

              <Divider />

              {/* ------------------------------
        Level 10 Rwquired 
        ------------------------------ */}








              <Divider />

              <Link to="/settings">
                <ListItem button>
                  <ListItemIcon>
                    <Description />
                  </ListItemIcon>
                  <ListItemText primary="Settings" />
                </ListItem>
              </Link>
              {user.access >= 10 &&

                <Link to="/users">
                  <ListItem button>
                    <ListItemIcon>
                      <Description />
                    </ListItemIcon>
                    <ListItemText primary="Users" />
                  </ListItem>
                </Link>
              }

              {user.access >= 10 &&

                <Link to="/file-types">
                  <ListItem button>
                    <ListItemIcon>
                      <Description />
                    </ListItemIcon>
                    <ListItemText primary="File Types" />
                  </ListItem>
                </Link>
              }
              <Divider />
              <ListItem >
                <ListItemText primary="Redundant" />
              </ListItem>

              <Divider />




            </List>
          </div>)}
        </userContext.Consumer>
      </div>
    );

    return (
      <div className={classes.root}>
        <AppBar position="static" className={this.props.classes.appBar}>
          <Toolbar>
            <IconButton className={classes.menuButton} color="inherit" aria-label="Menu" onClick={this.toggleDrawer('left', true)}>
              <MenuIcon />
            </IconButton>

            {window.location.pathname.split('/')[1] !== 'home' &&

              <Link to="/" style={{color: "white"}}>

                <IconButton className={classes.menuButton} color="inherit" aria-label="Menu">
                  <HomeIcon />
                </IconButton>

              </Link>
            }




            {window.location.pathname.split('/')[1] !== 'home' && <span onClick={() => history.goBack()} > | <span style={{ padding: "1em",  padding: "1.5em", cursor: "pointer" }}>Go back</span></span>}


            <Typography color="inherit" className={classes.flex}>
              <img className={classes.logo} src={logo} 
              alt="logo" />
            </Typography>

            {this.props.loggedIn &&
              <IconButton className={classes.button} aria-label="Email" onClick={this.props.handleLoginnameChange()}>
                <Exit />
              </IconButton>
            }


          </Toolbar>
        </AppBar>

        <Drawer open={this.state.left} onClose={this.toggleDrawer('left', false)}>
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer('left', false)}
            onKeyDown={this.toggleDrawer('left', false)}
          >
            {sideList}
          </div>
        </Drawer>


      </div>
    );
  }
}

ButtonAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ButtonAppBar);
