import {
    MeshStandardMaterial,
    Mesh,
    CylinderGeometry,
} from 'three';

const createHinges = (noHinges, color, side, doorWidth, doorHeight, profileDepth, leaf, metalnessVal, roughnessVal) => {



    //console.log(color, side, doorWidth, doorHeight ,profileDepth, leaf, metalnessVal, roughnessVal)

    const hingeGeometry = new CylinderGeometry(12, 12, 120, 8);
    const hingeMaterial = new MeshStandardMaterial({ color: color, metalness: 0.3, roughness: 0.3 })
    //const material = new MeshStandardMaterial({ color: hex , metalness: metalnessVal, roughness: roughnessVal})
    // const hinge = new Mesh(hingeGeometry, hingeMaterial);
    // const hinge2 = new Mesh(hingeGeometry, hingeMaterial);
    // const hinge3 = new Mesh(hingeGeometry, hingeMaterial);
    // const hinge4 = new Mesh(hingeGeometry, hingeMaterial);

    let hinges = {

    }

  
    for(let i = 1; i <= noHinges; i++){  hinges['hinge'+i]= new Mesh(hingeGeometry, hingeMaterial);}

 





    //---- X

    let adjust = doorWidth / 2

    if (leaf === "main") {

        if (side === "left") {
            for(let i = 1; i <= noHinges; i++){  hinges['hinge'+i].position.x = adjust; }
            // hinge.position.x = adjust
            // hinge2.position.x = adjust
            // hinge3.position.x = adjust
        } else {
            for(let i = 1; i <= noHinges; i++){  hinges['hinge'+i].position.x = -adjust; }
            // hinge.position.x = -adjust
            // hinge2.position.x = -adjust
            // hinge3.position.x = -adjust
        }
    } else {
        if (side === "left") {
            for(let i = 1; i <= noHinges; i++){  hinges['hinge'+i].position.x = -adjust; }
            // hinge.position.x = -adjust
            // hinge2.position.x = -adjust
            // hinge3.position.x = -adjust
        } else {
            for(let i = 1; i <= noHinges; i++){  hinges['hinge'+i].position.x = adjust; }
            // hinge.position.x = adjust
            // hinge2.position.x = adjust
            // hinge3.position.x = adjust
        }
    }


    //---- Y

    hinges.hinge1.position.y = doorHeight / 2 - 200
    hinges.hinge2.position.y = - doorHeight / 2 + 200

    if(noHinges === "4"){
        hinges.hinge3.position.y = doorHeight / 4 - 200
        hinges.hinge4.position.y = - doorHeight / 4 + 200
    }



    for(let i = 1; i <= noHinges; i++){  hinges['hinge'+i].position.z = profileDepth / 1.5; }



    return ( Object.values(hinges))



}
export default createHinges;

