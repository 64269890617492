import React, { Component } from 'react';

class Handle extends Component{

    state= {}
    angle = () => {

        if(this.props.side === 'left'){
            return(35);
        }else{
            return(-35);
        }
        
    }


    render() {
      
        
        return (
            <div>
                <div id="antiLigHang"style={{ height: `${65/this.props.printRatio}px`,  width: `${65/this.props.printRatio}px`, border: '1px solid black', position: 'absolute',margin: '0 auto', 'borderRadius': '50%',[this.props.side]: `${50/this.props.printRatio}px`,bottom: `${this.props.bottom/this.props.printRatio}px`,}}>
                    <div style={{height: `${20/this.props.printRatio}px`, width: `${115/this.props.printRatio}px`, border: '1px solid black', position: 'absolute', margin: '0 auto', 'borderRadius': '4px', [this.props.side] : `${20/this.props.printRatio}px`, bottom: '-3px', transform: `rotate(${this.angle()}deg)`}}></div>
                </div>
            </div>
       )
    }
}

export default Handle;