import React, { Component } from 'react';
import Cylinder from '../../../SteelDoor/Drawings/ironmongery/cylinder';

class Lock extends Component{

    state= {}

    render() {

        return (
            <div id="lock">
                <div style={{ height: '101%',  width: `${15/this.props.printRatio}px`, border: '1px solid #c5c3c3', position: 'absolute',margin: '0 auto',[this.props.handleSide]: `${65/this.props.printRatio}px`,bottom: `${-10/this.props.printRatio}px`,}}></div>

                <div style={{ height: `${275/this.props.printRatio}px`,  width: `${125/this.props.printRatio}px`, border: '1px solid #c5c3c3', position: 'absolute',margin: '0 auto',[this.props.handleSide]: `${20/this.props.printRatio}px`, bottom: `${this.props.bottom/this.props.printRatio}px`,backgroundColor:'white'}}>
                    <div style={{ height: `${15/this.props.printRatio}px`,  width: `${15/this.props.printRatio}px`, border: '1px solid #c5c3c3', position: 'absolute',margin: '0 auto',[this.props.handleSide]: `${-25/this.props.printRatio}px`,bottom: `${100/this.props.printRatio}px`,}}></div>
                    <div style={{ height: `${15/this.props.printRatio}px`,  width: `${15/this.props.printRatio}px`, border: '1px solid #c5c3c3', position: 'absolute',margin: '0 auto',[this.props.handleSide]: `${-25/this.props.printRatio}px`,bottom: `${150/this.props.printRatio}px`,}}></div>
                    <div style={{ height: `${15/this.props.printRatio}px`,  width: `${15/this.props.printRatio}px`, border: '1px solid #c5c3c3', position: 'absolute',margin: '0 auto',[this.props.handleSide]: `${-25/this.props.printRatio}px`,bottom: `${200/this.props.printRatio}px`,}}></div>
                    {
                        this.props.cylinder === true &&  < Cylinder printRatio={this.props.printRatio} ></Cylinder>
                    }
                    
                </div>
               
                { this.props.doorType === 'DC 3.1 ZK' &&
                    <div style={{height: `${350/this.props.printRatio}px`,  width: `${175/this.props.printRatio}px`, border: '1px solid #999', position: 'absolute',margin: '0 auto',[this.props.handleSide]: `${5/this.props.printRatio}px`,bottom:  `${this.props.bottom/this.props.printRatio}px`}}>
                        <div style={{width: '2px', height: '2px',backgroundColor: '#999', position: 'absolute', top: '2px', right: '2px'}}></div>
                        <div style={{width: '2px', height: '2px',backgroundColor: '#999', position: 'absolute', top:'50%', right: '2px'}}></div>
                        <div style={{width: '2px', height: '2px',backgroundColor: '#999', position: 'absolute', bottom:'2px', right: '2px'}}></div>
                        <div style={{width: '2px', height: '2px',backgroundColor: '#999', position: 'absolute', top:'2px', left: '2px'}}></div>
                        <div style={{width: '2px', height: '2px',backgroundColor: '#999', position: 'absolute', top:'50%', left: '2px'}}></div>
                        <div style={{width: '2px', height: '2px',backgroundColor: '#999', position: 'absolute', bottom:'2px', left: '2px'}}></div>
                    </div>
                }  
            </div>  
       )
    }
}

export default Lock;