import React, { Component } from 'react';
import { userContext } from '../../../Context/userContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import MenuItem from '@material-ui/core/MenuItem';


const styles = theme => ({
    panelDetails: {
        flexDirection: 'column'
    }

});



class ConfigDimensions extends Component {
    render() {
        const { classes } = this.props;
        return (<userContext.Consumer>
            {(user) => (user.access >= 1 ?
                <div style={{ flexDirection: 'column', display: 'flex' }}>
                    <h4>Configuration & Dimensions</h4>

                    <h5>Main Panel</h5>

                    {/*                
            <span style={{display:"flex"}}>
                {this.props.mainElement ? <span>Door</span> : <span>Panel</span>}
          
                <Switch
                    checked={this.props.mainElement}
                    onChange={this.props.handleChange('mainElement')}
                    name="doorType"
                    
                ></Switch> 
               
            </span> */}

                    <TextField
                        id="mainPanel"
                        select
                        label="Main Panel"
                        className={classes.textField}
                        value={this.props.mainElement}
                        onChange={this.props.handleChange('mainElement')}
                        SelectProps={{
                            native: true,
                            MenuProps: {
                                className: classes.menu,
                            },
                        }}
                        helperText=""
                        margin="normal"
                    >

                        <option key="hingedDoor" value="hingedDoor">Hinged Door</option>
                        <option key="pivotDoor" value="pivotDoor">Pivot Door</option>
                        <option key="slidingDoor" value="slidingDoor">Sliding Door</option>
                        <option key="panel" value="panel">Panel</option>
                    </TextField>
                    {/* ------------------------------------------------ Single / Double */}

                    {(this.props.mainElement === "hingedDoor" || this.props.mainElement === "slidingDoor") &&
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <TextField
                                id="single-double"
                                select
                                label="Single / Double"
                                style={{ flex: "2 1 50%" }}
                                value={this.props.singleDouble}
                                onChange={this.props.handleSingleDouble('singleDouble')}
                                SelectProps={{
                                    native: true,
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}
                                helperText=""
                                margin="normal"
                            >
                                <option key="Single" value="Single">Single</option>
                                <option key="Double" value="Double">Double</option>
                            </TextField>
                        </div>
                    }

                    { this.props.mainElement === "slidingDoor" &&

                        <MenuItem>
                            <Switch
                                checked={this.props.fullWidthMaskingBar}
                                onChange={this.props.handleChange('fullWidthMaskingBar')}
                                name="full width masking bar"

                            ></Switch>
                            Full width masking bar?
                            {this.props.fullWidthMaskingBar ? <> - Yes</> : <> - No</>}
                        </MenuItem>
                    }


                    {this.props.singleDouble === "Double" && < div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Button variant="outlined" onClick={this.props.handleDevideDoubleDoor("70")} >30%</Button>
                        <Button variant="outlined" onClick={this.props.handleDevideDoubleDoor("66")} >33%</Button>
                        <Button variant="outlined" onClick={this.props.handleDevideDoubleDoor("50")} >50%</Button>
                        <Button variant="outlined" onClick={this.props.handleDevideDoubleDoor("33")} >66%</Button>
                        <Button variant="outlined" onClick={this.props.handleDevideDoubleDoor("30")} >70%</Button>
                    </div>
                    }


                    <TextField
                        id="doorFrameWidth"
                        label='Frame Width'
                        type="number"
                        className="textField"
                        value={this.props.doorFrameWidth}
                        onChange={this.props.handleChange('doorFrameWidth')}
                        margin="normal"

                    />

                    <TextField
                        id="doorFrameHeight"
                        label=' Frame Heigh'
                        type="number"
                        className="textField"
                        value={this.props.doorFrameHeight}
                        onChange={this.props.handleChange('doorFrameHeight')}
                        margin="normal"

                    />


                    {this.props.singleDouble === "Double" &&

                        <TextField
                            id="doorWidth"
                            label='Main Door Width'
                            type="number"
                            className={classes.textField}
                            value={this.props.doorWidth}
                            onChange={this.props.handleChange('doorWidth')}
                            margin="normal"

                        />

                    }


                    {/* ---------------------------------------  Side */}

                    {(this.props.mainElement === "hingedDoor" || this.props.mainElement === "slidingDoor") &&

                        <TextField
                            id="Side"
                            select
                            label="Side"
                            className={classes.textField}
                            value={this.props.side}
                            onChange={this.props.handleChange('side')}
                            SelectProps={{
                                native: true,
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            helperText=""
                            margin="normal"
                        >
                            <option key="Left Hand" value="left">Left Hand</option>
                            <option key="Right Hand" value="right">Right Hand</option>
                        </TextField>
                    }

                    {/* ---------------------------------------  Swing */}

                    {/* {this.props.mainElement === "hingedDoor" && 

            <TextField
                id="SwingDirection"
                select
                label="Swing Direction"
                className={classes.textField}
                //value={this.state.vat}
                onChange={this.props.handleChange('swingDirection')}
                SelectProps={{
                    native: true,
                    MenuProps: {
                    className: classes.menu,
                    },
                }}
                helperText=""
                margin="normal"
                >
                    <option key="Inswing" value="Inswing">Inswing</option>
                    <option key="Outswing" value="Outswing">Outswing</option>
            </TextField>
        } */}

                    {/* Left Panel */}

                    <FormControlLabel
                        control={
                            <Checkbox
                                className={classes.checked}
                                checked={Boolean(this.props.leftPanel)}
                                onChange={this.props.handleChange('leftPanel')}
                            />
                        }
                        label={<h4>Left Panel</h4>}
                    />


                    {this.props.leftPanel &&

                        <TextField
                            id="lpWidth"
                            label='Left Panel Width'
                            type="text"
                            className={classes.textField}
                            value={this.props.lpWidth}
                            onChange={this.props.handleChange('lpWidth')}
                            margin="normal"

                        />
                    }


                    {/* Right Panel */}

                    <FormControlLabel
                        control={
                            <Checkbox
                                className={classes.checked}
                                checked={this.props.rightPanel}
                                onChange={this.props.handleChange('rightPanel')}

                            />
                        }
                        label={<h4>Right Panel</h4>}
                    />

                    {this.props.rightPanel &&

                        <TextField
                            id="rpWidth"
                            label='Right Panel Width'
                            type="text"
                            className={classes.textField}
                            value={this.props.rpWidth}
                            onChange={this.props.handleChange('rpWidth')}
                            margin="normal"

                        />
                    }


                    {/* Transom */}

                    <FormControlLabel
                        control={
                            <Checkbox
                                className={classes.checked}
                                checked={this.props.transom}
                                onChange={this.props.handleChange('transom')}

                            />
                        }
                        label={<h4>Transom</h4>}
                    />
                    {this.props.transom &&
                        <TextField
                            id="trHeight"
                            label='Transom Height'
                            type="number"
                            className={classes.textField}
                            value={this.props.transomHeight}
                            onChange={this.props.handleChange('transomHeight')}
                            margin="normal"

                        />
                    }

                </div> : <> {this.state.spinner ? <CircularProgress /> : <span></span>}  </>
            )}</userContext.Consumer>)
    }
}

export default withStyles(styles)(ConfigDimensions)