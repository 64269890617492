import React, { Component } from 'react';
import { Redirect } from 'react-router'
// import * as firebase from 'firebase';
import firebase from "firebase/compat/app";
import { withStyles } from '@material-ui/core/styles';
import {Input, Button} from '@material-ui/core/';
import Paper from '@material-ui/core/Paper';
import Customers from '../Search/Customers';
import CircularProgress from '@material-ui/core/CircularProgress';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';

const styles = theme => ({
    formControl: {
        margin: theme.spacing(3),
      },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    input: {
      margin: theme.spacing(3),
    
      width: 200,
    },
    root: {
        width: '100%',
        marginTop: theme.spacing(1) * 3,
        overflowX: 'auto',
      },
      table: {
        minWidth: 700,
      },
});

class AdvancedSearch extends Component{
    state = {
        quotes : [],
        searchItems: [],

        loading: false,
        getQuotes:false,

        getCustomers:true,

        whatYouArelookingFor:[
            {id: "contactName", checked : true, name:"Contact Name"},
            {id: "companyName", checked: false, name:"Company Name"},
            {id: "contactEmail", checked: true, name:"Email"},
            {id: "phoneNumber", checked: false, name:"Phone"},
            {id: "city", checked: false, name:"City"},
            {id: "county", checked: false, name:"County"},
            {id: "firstAddressLine:", checked: false, name:"First Address Line"},
            {id: "secondAddressLine", checked: false, name:"Second Address Line"},
        ]
    }
    filterList = name => event =>{
        let updatedList = this.state.quotes;

       

        updatedList = updatedList.reverse().filter(function(item){
            return item[name].toLowerCase().search(event.target.value.toLowerCase()) !== -1;
        });
        this.setState({searchItems: updatedList});
    }

    removeDuplicates = (item)=>event =>{
        let things = new Object();

        let arr = this.state.searchItems
        things.thing = arr

        things.thing = things.thing.filter((thing, index, self) =>
            index === self.findIndex((t) => (
                //  Example for future Bart of multiple records to compare -> t.contactEmail === thing.contactEmail && t.contactEmail === thing.contactEmail
                t.contactEmail === thing.contactEmail
            ))
        )

        this.setState({
            searchItems: things.thing
        })
    }

    getData = () =>{

        

        if(this.state.getQuotes){

        this.setState({ loading: true})

        const quotesRef = firebase.database().ref('Quotes');
        
        //load all orderes
        quotesRef.on('value',snap =>{
            
            let quotes = snap.val()

            //if there are orders in db 
            if(quotes !== null){
                //change json to array
                let allquotes = Object.entries(quotes).reverse();   
                
                let sorted = []
               
                for(const quote in allquotes ){

                    const newValues = {}
                    const selectedValues = this.state.whatYouArelookingFor

                    for(const selected in selectedValues){

                        if(selectedValues[selected].checked){
                            newValues[selectedValues[selected].id] = allquotes[quote][1][selectedValues[selected].id] 
                        }
                    }
                    sorted.push(newValues)
                }

      
                
                this.setState({
                    quotes: sorted,
                    loading: false
                },()=>{this.loadSearchItems()})
  
            }
        })

        }
    }

    handleChangeCheckbox = () =>event=> {
        this.setState({ ...this.state, [event.target.name]: event.target.checked });
    };

    handleChangewhatYouArelookingFor = (index) =>event=> {

        let whatYouArelookingFor  =  this.state.whatYouArelookingFor

        whatYouArelookingFor[index].checked = event.target.checked

        this.setState({ whatYouArelookingFor  });
    };


    loadSearchItems(){
        this.setState({searchItems: this.state.quotes})
    }

    componentDidMount(){}

    render() {

        const { classes, theme } = this.props;
        
        if(this.props.loggedIn === true  ){
            return (
                
                <div className={classes.root}>

                    <h2>Advanced Search</h2>

                    <FormControl component="fieldset" className={classes.formControl}>
                        <FormLabel component="legend">Where you want to search </FormLabel>
                        <FormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={this.state.getQuotes} onChange={this.handleChangeCheckbox()} name="getQuotes" />}
                            label="Quotes"
                        />
                    
                        </FormGroup>
                        <FormHelperText>----</FormHelperText>
                    </FormControl>

{/* /// ---------------------- What are you looking for            */}


                    <FormControl component="fieldset" className={classes.formControl}>
                        <FormLabel component="legend">What are you looking for  </FormLabel>
                        <FormGroup>

                            {this.state.whatYouArelookingFor.map( (item,index) =>(
                                <FormControlLabel key={index}
                                control={<Checkbox checked={item.checked} onChange={this.handleChangewhatYouArelookingFor(index)} name={item.id} />}
                                label={item.name}
                                />
                            )
                            )}
                        </FormGroup>
                        <FormHelperText>---</FormHelperText>
                    </FormControl>


                    { this.state.loading  ? <span style={{display : 'block'}}> Loading <CircularProgress color="primary" />  </span> : <span>
                        <button onClick={this.removeDuplicates()} >Remove duplicated Email Adresses</button>
                    <br/>   <br/>
                        <Button onClick={()=>{this.getData()}}>Get</Button>

                        <br/>   <br/>
                    
                     

                        <Paper>

                        {
                                this.state.whatYouArelookingFor.map( (itm,idx) => {

                                    if(itm.checked){
                                        return(
                                            <Input key={idx}
                                            type="text" 
                                            className={classes.input} 
                                            placeholder={itm.name} 
                                            onChange={this.filterList(itm.id)}>
                                        </Input>
                                        )
                                         
                                    }  
                                })
                            }


                        </Paper>

                
                        <Paper>
                            <Customers quotes={this.state.searchItems} whatYouArelookingFor={this.state.whatYouArelookingFor} ></Customers>
                        </Paper>

                    </span> }

                </div>

            );
        }else{
                return <Redirect to='/'/>
        }
    }
  }
;
export default withStyles(styles)(AdvancedSearch);