import createBox from "./createBox";
import { Group }  from 'three';
const uniqid = require('uniqid');

const rectangleFrame = ( Width, Height, profileWidth, profileDepth, color ,metalness, roughness, doorType, element) =>{


    const Frame = new Group()

  

    let Top = createBox(Width, profileWidth, profileDepth, color, uniqid(), metalness,roughness);
    let Bottom = createBox(Width, profileWidth, profileDepth, color, uniqid(), metalness,roughness);
    let Left = createBox(profileWidth, Height, profileDepth, color, uniqid(), metalness,roughness);
    let Right = createBox(profileWidth, Height, profileDepth, color, uniqid(), metalness,roughness);

    
    if (doorType === "slidingDoor"  ) {

 
        Bottom = createBox(Width, Number(profileWidth)*2, profileDepth, color, uniqid(), metalness,roughness);
        Left = createBox(profileWidth, Height -20, profileDepth, color, uniqid(), metalness,roughness);
        Right = createBox(profileWidth, Height  -20, profileDepth, color, uniqid(), metalness,roughness);
    }


    Top.position.y = (Height / 2) - (profileWidth/2);
    Bottom.position.y = (Height / -2) + (profileWidth/2);
    Left.position.x = (Width / -2 ) + (profileWidth / 2)
    Right.position.x = (Width / 2 ) - (profileWidth / 2)

    if (doorType === "slidingDoor" && element === "door") {
        Bottom.position.y = (Height / -2) + (profileWidth) + 10;
    }

    Frame.add(Top, Bottom, Left , Right )


    return(Frame)
}

export default rectangleFrame;