import React, { Component } from 'react';
import { Redirect } from 'react-router'
// import * as firebase from 'firebase';
import firebase from "firebase/compat/app";
import { withStyles } from '@material-ui/core/styles';
import {Paper, Container, Input} from '@material-ui/core';
import Deals from '../Leads/Deals';
import SearchIcon from '@material-ui/icons/Search';

const styles = theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
        margin: '1em',
        width: 200,
    },
    root: {
        width: '100%',
        marginTop: theme.spacing(1) * 3,
        overflowX: 'auto',
      },
      table: {
        minWidth: 700,
      },
});

class LostDeadls extends Component{
    state = {
        quotes : [],
        searchItems: [],
    }
    filterList = name => event =>{
        let updatedList = this.state.quotes;

        updatedList = updatedList.reverse().filter(function(item){
            if(item[1][name] != null){
                return item[1][name].toLowerCase().search(event.target.value.toLowerCase()) !== -1;
            }
        });
        this.setState({searchItems: updatedList});
    }

    loadSearchItems(){
        this.setState({searchItems: this.state.quotes})
    }

    componentDidMount(){

        const quotesRef = firebase.database().ref('DealsLost');
        
        //load all orderes
        quotesRef.on('value',snap =>{
            
            let quotes = snap.val()

            //if there are orders in db 
            if(quotes !== null){
                //change json to array
                let allquotes = Object.entries(quotes).reverse();   
                
                //console.log(allquotes);
                
                this.setState({
                    quotes: allquotes
                },()=>{this.loadSearchItems()})
  
            }
        })
    }


    render() {

        const { classes, theme } = this.props;
        
        if(this.props.loggedIn === true  ){
            return (
                
                <Container maxWidth="xl" className={classes.root}>

                    <h2>Lost Deadls</h2>


                    <SearchIcon style={{fontSize: '1rem'}} />
                        <Input 
                            type="text" 
                            className={classes.textField} 
                            placeholder="Customer Name" 
                            onChange={this.filterList('prospectName')}>
                        </Input>

                        <Input 
                            type="text" 
                            className={classes.textField} 
                            placeholder="Refference:" 
                            onChange={this.filterList('refference')}>
                        </Input>

                        <Input 
                            type="text" 
                            className={classes.textField} 
                            placeholder="Email" 
                            onChange={this.filterList('prospectEmail')}>
                        </Input>
                    <Paper style={{marginTop: "50px"}}>
                        <Deals quotes={this.state.searchItems} userEmail={this.props.userEmail} database={'DealsLost'} ></Deals>
                    </Paper>
                </Container>

            );
        }else{
                return <Redirect to='/'/>
        }
    }
  }
;
export default withStyles(styles)(LostDeadls);