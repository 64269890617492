import React, { Component } from 'react';
import { Redirect } from 'react-router'
// import * as firebase from 'firebase'


import firebase from "firebase/compat/app";
import { withStyles } from '@material-ui/core/styles';

import Orders from '../orders/Orders';
import AddOrder from '../orders/AddOrder';
import {Paper, Container} from '@material-ui/core';
import { userContext } from '../Context/userContext';

import { TotalValue, TotalIncome, TotalVat, TotalIncomeVat } from '../logic/GetData';
import CircularProgress from '@material-ui/core/CircularProgress';


// import Timeline from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css'
import moment from 'moment';

import Timeline, {
    TimelineHeaders,
    SidebarHeader,
    DateHeader,
    TimelineMarkers,
    CustomMarker,
    TodayMarker,
    CursorMarker
} from 'react-calendar-timeline'
import { ContactSupportOutlined, Height } from '@material-ui/icons';

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    paper: {
        overflowX: 'scroll',
        margin: '2%',
    },

});




class ActiveOrders extends Component {

    state = {

        activeOrders: [],
        timelineOrders: [],
        orderDetails: [],
        loading: true
    }

    GetCustomerName = (id = '', customers = []) => {

        let customer

        for (let i = 0; i < customers.length; i++) {

            if (id === customers[i][0]) {
                customer = customers[i][1].companyName
                break;
            }
        }



        return (customer);

    };

    componentDidMount() {




        // set refference to firebase 
        const ordersRef = firebase.database().ref('Orders');
        let orders
        let customers = {}

        // 1 get all customers as an array
        // 2 get all orders as an array
        // resolve 2 promises : https://stackoverflow.com/questions/57739177/firebase-realtime-database-wait-for-multiple-queries-to-finish
        // set them as state
        // 3 get customer nam by id in JSX from cutomers array
        const custRef = firebase.database().ref('Customers');
        //Dont know why this works if it will stop do what I have planned above 
        custRef.on('value', snap => {
            let cust = snap.val();
            if (cust !== null) {
                customers = Object.entries(cust)
            }
        })







        //load all orderes
        ordersRef.on('value', snap => {

            orders = snap.val()
            let timelineOrders = []
            let orderDetails = []
            let activeOrders = [];
            console.time();
            //if there are orders in db 
            if (orders !== null && customers !== {}) {


                //change json to array
                let allorders = Object.entries(orders).reverse();


                for (const [i, v] of allorders.entries()) {






                    if (v[1].OrderClosed === false) {




                        let projectDescryption = v[1].Reference

                        let color = '#316288';

                        let orderIncome = 0

                        if (v[1].Archive != null) {

                            let archive = Object.values(v[1].Archive)

                            let index = archive.findIndex(file => file.name === "Invoice")
                            let index2 = archive.findIndex(file => file.name === "Factory down payment")
                            if (index != -1) { v[1].invoiceUploaded = true }
                            if (index2 != -1) { v[1].factoryDownPaymentUploaded = true }

                        }



                        if (v[1].ManualTasks != null) {
                            let manualTasks = Object.values(v[1].ManualTasks)


                            let index = manualTasks.findIndex(task => task.name === "Down payment confirmation")
                            if (index != -1) {

                                if (manualTasks[index].value === true) {
                                    v[1].downpaymentConfirmation = true
                                }
                            }

                            let index2 = manualTasks.findIndex(task => task.name === "Factory down payment confirmation")



                            if (index2 != -1) {


                                if (manualTasks[index2].value === true) {


                                    v[1].factorydownpaymentConfirmation = true
                                }
                            }

                        }



                        v[1].outstanding = (TotalValue(v[0]) + TotalVat(v[0])) - (TotalIncome(v[0]) + TotalIncomeVat(v[0]))




                        v[1].CustomerName = this.GetCustomerName(v[1].Customer, customers)


                        let start = moment(v[1].DateApproved);
                        let end = moment(v[1].DateApproved).add(Number(v[1].LeadTime), 'weeks');



                        // Tasks

                        if (v[1].ManualTasks !== undefined) {

                            for (let i of Object.entries(v[1].ManualTasks)) {

                                if (i[1].name === 'Confirm collection date from manufacture') {
                                    orderDetails.push({
                                        id: v[0] + 1,
                                        group: v[0],
                                        start_time: moment(i[1].value),
                                        end_time: moment(i[1].value).add(1, 'day'),
                                        title: `Collection from manufacture  ${i[1].value}`,
                                        itemProps: {
                                            // these optional attributes are passed to the root <div /> of each item as <div {...itemProps} />
                                            'data-custom-attribute': 'Random content',
                                            'aria-hidden': true,
                                            onClick: () => { alert('You clicked double!') },
                                            className: 'weekend',
                                            style: {
                                                background: '#008000',
                                                borderColor: '#008000',
                                                color: 'black'
                                            }
                                        }
                                    })

                                }


                                if (i[1].name === 'Arrange Collection/Delivery date with customer') {
                                    orderDetails.push({
                                        id: v[0] + 2,
                                        group: v[0],
                                        start_time: moment(i[1].value),
                                        end_time: moment(i[1].value).add(1, 'day'),
                                        title: `Delivery  ${i[1].value}`,
                                        itemProps: {
                                            // these optional attributes are passed to the root <div /> of each item as <div {...itemProps} />
                                            'data-custom-attribute': 'Random content',
                                            'aria-hidden': true,
                                            onClick: () => { alert('You clicked double!') },
                                            className: 'weekend',
                                            style: {
                                                background: '#008000',
                                                borderColor: '#008000',
                                                color: 'black'
                                            }
                                        }
                                    })

                                }


                            }

                        }



                        const obligatoryItem = () => {

                            return (
                                orderDetails.push({
                                    id: v[0] + 3,
                                    group: v[0],
                                    start_time: start,
                                    end_time: moment(start).add(14, 'day'),
                                    // start_time: moment(i[1].value),
                                    // end_time: moment(i[1].value).add(1,'day'),
                                    title: `Drawings signed by customer required `,
                                    itemProps: {
                                        // these optional attributes are passed to the root <div /> of each item as <div {...itemProps} />
                                        'data-custom-attribute': 'Random content',
                                        'aria-hidden': true,
                                        onClick: () => { alert('You clicked double!') },
                                        className: 'weekend',
                                        style: {
                                            background: '#D80026',
                                            borderColor: '#D80026',
                                            color: 'white'
                                        }
                                    }
                                })

                            )

                        }





                        if (v[1].Archive != null) {

                            let archive = Object.values(v[1].Archive)

                            let index = archive.findIndex(file => file.name === "Invoice")
                            let index2 = archive.findIndex(file => file.name === "Factory down payment")
                            if (index != -1) { v[1].invoiceUploaded = true }
                            if (index2 != -1) { v[1].factoryDownPaymentUploaded = true }



                            let index3 = archive.findIndex(task => task.name === "Drawings signed by Customer")

                            if (index3 !== -1) {
                                projectDescryption = projectDescryption + " - Drawings signed by Customer ✓ "

                            } else {

                                obligatoryItem()

                            }



                            if (TotalIncome(v[0]) > 0) {


                                let procentagePaid = (TotalIncome(v[0]) / TotalValue(v[0])) * 100

                                projectDescryption = projectDescryption + " ---  paid: " + Math.trunc(procentagePaid) + "% "

                                if (
                                    index3 !== -1 &&
                                    Math.trunc(procentagePaid) > 0) {
                                    color = "#ff6600"
                                }

                                if (
                                    index3 !== -1 &&
                                    Math.trunc(procentagePaid) === 100) {
                                    color = "#93e12e"
                                }


                            }




                        } else {
                            obligatoryItem()
                        }

                        // Orders

                        timelineOrders.push({
                            id: v[0],
                            title: v[1].OrderNumber,
                            stackItems: true,
                            height: 100,

                        });

                        // Project timenline






                        orderDetails.push({
                            id: v[0],
                            group: v[0],
                            start_time: start,
                            end_time: end,
                            title: projectDescryption,

                            itemProps: {
                                'data-custom-attribute': 'Random content',
                                'aria-hidden': true,
                                onDoubleClick: () => { this.props.history.push(`/order/${v[0]}`) },
                                style: {
                                    background: color,
                                    // borderColor: 'white',
                                    minHeight: "35px",
                                    padding: "8px"
                                }
                            }
                        })



                        activeOrders.push(v)

                    }

                }

                this.setState({
                    activeOrders,
                    loading: false,
                    timelineOrders: timelineOrders,
                    orderDetails: orderDetails
                })

            } else {
                this.setState({
                    timelineOrders: [],
                    activeOrders,
                    orderDetails: [],

                })
            }
        })

    }

    render() {

        if (this.props.loggedIn === true) {
            return (
                <userContext.Consumer>
                    {(user) => (
                        user.access >= 5 ?

                            <div>

                                {this.state.loading ? <span style={{ display: 'block' }}> Loading <CircularProgress color="primary" />  </span> : <span>
                                    <Timeline

                                        groups={this.state.timelineOrders}
                                        items={this.state.orderDetails}
                                        defaultTimeStart={moment().add(-1, 'weeks')}
                                        defaultTimeEnd={moment().add(10, 'weeks')}
                                        style={{ background: '#fff' }}
                                    >
                                        <TimelineHeaders>
                                            <SidebarHeader  >
                                                {({ getRootProps }) => {
                                                    return <div {...getRootProps()} style={{ backgroundColor: '#D80026', color: 'white', width: '150px', paddingTop: '20px' }}>Order No.</div>
                                                }}
                                            </SidebarHeader>
                                            <DateHeader unit="primaryHeader" style={{ backgroundColor: '#D80026' }} />
                                            <DateHeader />
                                        </TimelineHeaders>
                                        <TimelineMarkers>
                                            <TodayMarker />
                                        </TimelineMarkers>

                                    </Timeline>

                                    <div style={{ display: 'flex', justifyContent: "center", gap: '10px' }}>
                                        <div style={{ display: 'flex', padding: "1em", gap: '10px', alignItems: "center" }}>
                                            <div style={{ backgroundColor: "#316288", width: "4em", height: "2em", border: "1px solid black" }}>  </div><div> - New projectd</div>
                                        </div>

                                        <div style={{ display: 'flex', padding: "1em", gap: '10px', alignItems: "center" }}>
                                            <div style={{ backgroundColor: "#D80026", width: "4em", height: "2em", border: "1px solid black" }}>  </div><div> - Drawings signed by customer required</div>
                                        </div>

                                        <div style={{ display: 'flex', padding: "1em", gap: '10px', alignItems: "center" }}>
                                            <div style={{ backgroundColor: "#ff6600", width: "4em", height: "2em", border: "1px solid black" }}>  </div><div> - Drawings signed & 1st invoice paid can be sent to production</div>
                                        </div>

                                        <div style={{ display: 'flex', padding: "1em", gap: '10px', alignItems: "center" }}>
                                            <div style={{ backgroundColor: "#93e12e", width: "4em", height: "2em", border: "1px solid black" }}>  </div><div> - All paid in full - doors can be installed</div>
                                        </div>

                                    </div>

                                    <h2>Active Orders</h2>
                                    <AddOrder></AddOrder>

                                    <Container maxWidth="xl">
                                        <Paper className={this.props.classes.paper} elevation={1}>
                                            <Orders orders={this.state.activeOrders}></Orders>
                                        </Paper>
                                    </Container>
                                </span>}


                            </div>
                            :
                            <div><h2>Access denied - Contact administrator</h2></div>
                    )}
                </userContext.Consumer>
            );
        } else {
            return <Redirect to='/' />
        }
    }
}
export default withStyles(styles)(ActiveOrders);
