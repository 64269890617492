import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';

import TableHead from '@material-ui/core/TableHead';

import DoorSpec from '../SteelDoor/DoorSpec';
import Header from '../Quotes/QuoteHeader';
import Footer from '../Quotes/QuoteFooter';

import { QuoteTotalValue } from '../logic/GetData'

const Terms = require('../Pages/Terms');

const styles = theme => ({
    sectionPrint: {
        margin: '0px',
        '@media print': {
            margin: '0cm',
            padding: '0cm',
            pageBreakInside: 'avoid',
            borderTop: '1px solid #e5e5e5'
        },
    },

    quotePrint: {

        margin: '0px',
        '@media print': {
            margin: '0cm',
            padding: '0.2cm',
        },
    },

    onlyDesktop: {
        display: 'block',
        width: '100%',
        '@media print': {
            display: 'none'
        },
    },

    onlyVisibleWhenPrint: {
        display: 'none',

        '@media print': {
            display: 'block'
        },
    }




});


//changed from PL to IE




class Quote extends Component {

    state = {
        discount: false,
        totalDiscount: 0,
        netValue: 0,
        totalVat: 0
    }


    // net value after discount 
    sumUp = () => {
        let total = 0;

        total += Number(this.props.qty) * Number(this.props.totalPrice);

        for (let door of this.props.allDoors) {

            let qty = Number(door.qty);

            let totPrice = door.totalPrice;

            total += qty * totPrice;

        }



        for (let item of this.props.additionalPriceItems) {
            // jesli jest znizka globalna to nie zlicza
            if (!item.globalDiscount) {
                let qty = Number(item.qty);
                let totPrice = item.price;
                total += qty * totPrice;
            }
        }



        // for(let door of this.props.allDoors){

        //     let qty = Number(door.qty);

        //     let totPrice =  door.totalPrice - (door.totalPrice *  (1 - (100 - door.discount)/100));  

        //     total += qty * totPrice ; 

        // }

        // for(let item of this.props.additionalPriceItems){
        //     // jesli jest znizka globalna to nie zlicza
        //     if(!item.globalDiscount){
        //         let qty = Number(item.qty);
        //         let totPrice =  item.price - (item.price *  (1 - (100 - item.discount)/100));
        //         total += qty * totPrice ; 
        //     } 
        // }

        // total += Number(this.props.qty) * (this.props.totalPrice-(this.props.totalPrice *  (1 - (100 - this.props.discount)/100)));  

        // if(this.props.additionalPriceItems.findIndex( x => Number(x.globalDiscount) == true) !== -1){

        //     for(let item of this.props.additionalPriceItems){
        //         if(item.globalDiscount){
        //             total = total - (total *  (1 - (100 - item.discount)/100));
        //         }
        //     }

        // }


        return total.toFixed(2);
    }



    totalVat = () => {

        let total = 0;

        if (this.props.additionalPriceItems.findIndex(x => x.globalDiscount === true) !== -1) {



            for (let additionalItem of this.props.additionalPriceItems) {
                if (additionalItem.globalDiscount) {




                    for (let door of this.props.allDoors) {
                        let qty = Number(door.qty);
                        let totPrice = door.totalPrice - (door.totalPrice * (1 - (100 - door.discount) / 100));
                        totPrice = totPrice - (totPrice * (1 - (100 - additionalItem.discount) / 100));
                        total += (qty * totPrice) * ('0.' + Number(door.vat));
                    }

                    for (let item of this.props.additionalPriceItems) {
                        if (!item.globalDiscount) {
                            let qty = Number(item.qty);
                            let totPrice = item.price - (item.price * (1 - (100 - item.discount) / 100));
                            totPrice = totPrice - (totPrice * (1 - (100 - additionalItem.discount) / 100));
                            total += (qty * totPrice) * ('0.' + Number(item.vat));
                        }
                    }

                    let befDisc = Number(this.props.qty) * (this.props.totalPrice - (this.props.totalPrice * (1 - (100 - this.props.discount) / 100)));

                    let befVat = befDisc - (befDisc * (1 - (100 - additionalItem.discount) / 100));

                    total += befVat * ('0.' + Number(this.props.vat));



                }
            }

        } else {

            for (let door of this.props.allDoors) {
                let qty = Number(door.qty);
                let totPrice = door.totalPrice - (door.totalPrice * (1 - (100 - door.discount) / 100));
                total += (qty * totPrice) * ('0.' + Number(door.vat));
            }

            for (let item of this.props.additionalPriceItems) {
                if (!item.globalDiscount) {
                    let qty = Number(item.qty);
                    let totPrice = item.price - (item.price * (1 - (100 - item.discount) / 100));
                    total += (qty * totPrice) * ('0.' + Number(item.vat));
                }
            }



            total += Number(this.props.qty) * (this.props.totalPrice - (this.props.totalPrice * (1 - (100 - this.props.discount) / 100))) * ('0.' + Number(this.props.vat));

        }
        return total.toFixed(2);

    }

    totalDiscount = () => {

        let total = 0;

        // ---------------------------- Old 

        // if(this.props.additionalPriceItems.findIndex( x => x.globalDiscount === true) !== -1)

        //     let sumOfAllDoors = this.sumUp()  

        //     for(let item of this.props.additionalPriceItems){
        //         if(item.globalDiscount){
        //             total = sumOfAllDoors *  (1 - (100 - item.discount)/100);
        //         }
        //     }

        // }else{

        //         for(let door of this.props.allDoors){
        //             let qty = Number(door.qty);
        //             let totPrice =  door.totalPrice *  (1 - (100 - door.discount)/100);
        //             total += qty * totPrice ; 
        //         }
        //         total += Number(this.props.qty) * (this.props.totalPrice *  (1 - (100 - this.props.discount)/100)); 


        //         //calculate discount for additional price items
        //         for(let item of this.props.additionalPriceItems){
        //             if(!item.globalDiscount){
        //                 let qty = Number(item.qty);
        //                 let totPrice =  item.price *  (1 - (100 - item.discount)/100);
        //                 total += qty * totPrice ; 

        //             }
        //         }

        // }

        // ------------------------------ New

        // if(this.props.additionalPriceItems.findIndex( x => x.globalDiscount === true) !== -1){


        //     let sumOfAllDoors = this.sumUp()

        //     for(let item of this.props.additionalPriceItems){
        //         if(item.globalDiscount){
        //             total = sumOfAllDoors *  (1 - (100 - item.discount)/100);
        //         }
        //     }

        // }else{


        for (let door of this.props.allDoors) {
            let qty = Number(door.qty);
            let totPrice = door.totalPrice * (1 - (100 - door.discount) / 100);
            total += qty * totPrice;
        }
        total += Number(this.props.qty) * (this.props.totalPrice * (1 - (100 - this.props.discount) / 100));

        //calculate discount for additional price items
        for (let item of this.props.additionalPriceItems) {

            if (!item.globalDiscount) {
                let qty = Number(item.qty);
                let totPrice = item.price * (1 - (100 - item.discount) / 100);
                total += qty * totPrice;

            }
        }

        // console.log("Dodatkowe: ", total)



        if (this.props.additionalPriceItems.findIndex(x => x.globalDiscount === true) !== -1) {


            let sumOfAllDoors = this.sumUp()

            console.log(sumOfAllDoors)
            console.log(total)


            for (let item of this.props.additionalPriceItems) {
                if (item.globalDiscount) {
                    total = total + ((sumOfAllDoors - total) * (1 - (100 - item.discount) / 100));
                }
            }

        }



        return total.toFixed(2);
    }

    componentDidMount() {
        let discount = false;
        if (this.props.allDoors.findIndex(x => Number(x.discount) !== 0) !== -1 || this.props.additionalPriceItems.findIndex(x => Number(x.discount) !== 0) !== -1 || Number(this.props.discount) !== 0) {
            discount = true
        } else {
            discount = false
        }


        let netValue = this.sumUp()

        let totalDiscount = this.totalDiscount()


        // Vat must be executed as last 
        let totalVat = this.totalVat()



        this.setState({
            totalDiscount,
            discount,
            netValue,
            totalVat
        })



    }
    componentDidUpdate(prevProps) {

        if (prevProps !== this.props) {


            let discount = false;
            let itemsDiscou


            if (this.props.allDoors.findIndex(x => Number(x.discount) !== 0) !== -1 || this.props.additionalPriceItems.findIndex(x => Number(x.discount) !== 0) !== -1 || Number(this.props.discount) !== 0) {
                discount = true
            } else {
                discount = false
            }

            if (this.props.allDoors.findIndex(x => Number(x.discount) !== 0) === -1) {

            }


            let totalDiscount = this.totalDiscount()
            let netValue = this.sumUp()
            // Vat must be executed as last 
            let totalVat = this.totalVat()

            this.setState({
                totalDiscount,
                discount,
                netValue,
                totalVat
            })
        }
    }



    // Changed from PL to IE siede 
    // remove once multi door implemeneted 
    changeSide = () => {
        if (this.props.side === 'Left Hand') {
            return 'Right Hand';
        } else {
            return 'Left Hand'
        }
    }

    // checkForDicount = (item )=>{


    //     return 
    // }

    render() {
        const { classes, ...other } = this.props;



        return (
            <div className={classes.quotePrint}>

                <span className={classes.onlyDesktop}>
                    <Header
                        companyName={this.props.companyName}
                        contactName={this.props.contactName}
                        contactEmail={this.props.contactEmail}
                        phoneNumber={this.props.phoneNumber}
                        firstAddressLine={this.props.firstAddressLine}
                        secondAddressLine={this.props.secondAddressLine}
                        city={this.props.city}
                        county={this.props.county}
                        eircode={this.props.eircode}
                        country={this.props.country}
                        quoteDate={this.props.quoteDate}
                        quoteNo={this.props.quoteNo}
                        quoteID={this.props.quoteID}
                        refernceNumber={this.props.refernceNumber}
                    ></Header>
                </span>


                {(window.location.pathname.split('/')[1] === 'steel-doors' || window.location.pathname.split('/')[1] === 'edit-quote') &&
                    <>
                        <DoorSpec {...other} ></DoorSpec>
                        <div className={classes.onlyVisibleWhenPrint}>
                            <Footer ></Footer>
                        </div>

                    </>


                }


                {this.props.allDoors.map((door, index) => {


                    return (
                        <>
                            <DoorSpec key={index}
                                {...door}
                                companyName={this.props.companyName}
                                contactName={this.props.contactName}
                                contactEmail={this.props.contactEmail}
                                phoneNumber={this.props.phoneNumber}
                                firstAddressLine={this.props.firstAddressLine}
                                secondAddressLine={this.props.secondAddressLine}
                                city={this.props.city}
                                county={this.props.county}
                                eircode={this.props.eircode}
                                country={this.props.country}
                                quoteDate={this.props.quoteDate}
                                quoteNo={this.props.quoteNo}
                                quoteID={this.props.quoteID}
                                refernceNumber={this.props.refernceNumber}

                            ></DoorSpec>
                            <Footer ></Footer>
                        </>

                    )
                })}
                {(window.location.pathname.split('/')[1] === 'quote' || window.location.pathname.split('/')[1] === 'order') &&
                    <>
                        <DoorSpec {...this.props} ></DoorSpec>
                        <div className={classes.onlyVisibleWhenPrint}>
                            <Footer ></Footer>
                        </div>

                    </>
                }

                <div className={classes.sectionPrint}>
                    <Header
                        companyName={this.props.companyName}
                        contactName={this.props.contactName}
                        contactEmail={this.props.contactEmail}
                        phoneNumber={this.props.phoneNumber}
                        firstAddressLine={this.props.firstAddressLine}
                        secondAddressLine={this.props.secondAddressLine}
                        city={this.props.city}
                        county={this.props.county}
                        eircode={this.props.eircode}
                        country={this.props.country}
                        quoteDate={this.props.quoteDate}
                        quoteNo={this.props.quoteNo}
                        quoteID={this.props.quoteID}
                        refernceNumber={this.props.refernceNumber}
                    ></Header>
                    <h3 className={classes.print} >Price Breakdown</h3>
                    <Table className={classes.summary}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Door</TableCell>
                                <TableCell align="right">Qty</TableCell>
                                <TableCell align="right">Net Unit Price</TableCell>
                                {this.state.discount && <TableCell align="right">Discount</TableCell>}
                                {this.state.discount && <TableCell align="right">Discount Unit Price</TableCell>}
                                <TableCell align="right">Total VAT</TableCell>
                                <TableCell align="right">Total</TableCell>


                            </TableRow>
                        </TableHead>
                        <TableBody  >



                            {this.props.allDoors.map((door, index) => {


                                return <TableRow key={index}>
                                    <TableCell >{door.doorName}</TableCell>
                                    <TableCell align="right"> {door.qty}</TableCell>
                                    <TableCell align="right">
                                        {new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format((door.totalPrice))}
                                    </TableCell >

                                    {this.state.discount &&
                                        <TableCell align="right">{door.discount}% - {new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format((door.totalPrice * (1 - (100 - door.discount) / 100)).toFixed(2))}</TableCell>}

                                    {this.state.discount &&
                                        <TableCell align="right">{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format((door.totalPrice - (door.totalPrice * (1 - (100 - door.discount) / 100))).toFixed(2))}</TableCell>
                                    }

                                    <TableCell align="right"> {door.vat === '23' ? ('23%') : door.vat === '21' ? ('21%') : (door.vat === '135') ? ('13.5%') : ("0%")}

                                        {
                                            Number(door.discount) !== 0
                                                ?
                                                <span> - {new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format((door.qty * (door.totalPrice - (door.totalPrice * (1 - (100 - door.discount) / 100))) * ('0.' + Number(door.vat))).toFixed(2))}</span>
                                                :
                                                <span> - {new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format((door.qty * door.totalPrice * ('0.' + Number(door.vat))).toFixed(2))} </span>
                                        }
                                    </TableCell>

                                    <TableCell align="right">
                                        {
                                            Number(door.discount) !== 0
                                                ?
                                                <span>
                                                    {new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format((door.qty * (door.totalPrice - (door.totalPrice * (1 - (100 - door.discount) / 100))) * ('1.' + Number(door.vat))).toFixed(2))}</span>
                                                :
                                                <span>{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format((door.qty * door.totalPrice * ('1.' + Number(door.vat))).toFixed(2))} </span>
                                        }
                                    </TableCell >



                                </TableRow>
                            })}

                            <TableRow key="main">
                                <TableCell >{this.props.doorName}</TableCell>
                                <TableCell align="right" > {this.props.qty}</TableCell>
                                <TableCell align="right"><span>{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format(Number(this.props.totalPrice).toFixed(2))} </span></TableCell >

                                {this.state.discount &&
                                    <TableCell align="right">{this.props.discount}% - {new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format((this.props.totalPrice - (this.props.totalPrice - (this.props.totalPrice * (1 - (100 - this.props.discount) / 100)))).toFixed(2))}</TableCell>
                                }
                                {this.state.discount &&
                                    <TableCell align="right"> {new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format((this.props.totalPrice - (this.props.totalPrice * (1 - (100 - this.props.discount) / 100))).toFixed(2))}</TableCell>
                                }


                                <TableCell align="right"> {this.props.vat === '23' ? ('23%') : this.props.vat === '21' ? ('21%') : (this.props.vat === '135') ? ('13.5%') : ("0%")}
                                    {
                                        Number(this.props.discount) !== 0
                                            ?
                                            <span> - {new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format((this.props.qty * (this.props.totalPrice - (this.props.totalPrice * (1 - (100 - this.props.discount) / 100))) * ('0.' + Number(this.props.vat))).toFixed(2))}</span>
                                            :
                                            <span> - {new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format((this.props.qty * this.props.totalPrice * ('0.' + Number(this.props.vat))).toFixed(2))} </span>
                                    }

                                </TableCell>

                                <TableCell align="right">
                                    {
                                        Number(this.props.discount) !== 0
                                            ?
                                            <span>{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format((this.props.qty * (this.props.totalPrice - (this.props.totalPrice * (1 - (100 - this.props.discount) / 100))) * ('1.' + Number(this.props.vat))).toFixed(2))}</span>
                                            :
                                            <span>{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format((this.props.qty * this.props.totalPrice * ('1.' + Number(this.props.vat))).toFixed(2))} </span>
                                    }
                                </TableCell >

                            </TableRow>

                            {this.props.additionalPriceItems.map((item, index) => {
                                return (

                                    !item.globalDiscount ?
                                        <TableRow key={index}>
                                            <TableCell style={{ maxWidth: "20vw" }} dangerouslySetInnerHTML={{ __html: item.text }} ></TableCell>
                                            <TableCell align="right" >{item.qty} </TableCell>
                                            <TableCell align="right" >{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format(item.price)} </TableCell>
                                            {this.state.discount && <TableCell align="right">{item.discount}% - {new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format((item.price - (item.price - (item.price * (1 - (100 - item.discount) / 100)))).toFixed(2))}</TableCell>}
                                            {this.state.discount && <TableCell align="right"> {new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format((item.price - (item.price * (1 - (100 - item.discount) / 100))).toFixed(2))}</TableCell>}
                                            <TableCell align="right"> {item.vat === '23' ? ('23%') : item.vat === '21' ? ('21%') : (item.vat === '135') ? ('13.5%') : ("0%")}
                                                {
                                                    Number(item.discount) !== 0
                                                        ?
                                                        <span> - {new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format((item.qty * (item.price - (item.price * (1 - (100 - item.discount) / 100))) * ('0.' + Number(item.vat))).toFixed(2))}</span>
                                                        :
                                                        <span> - {new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format((item.qty * item.price * ('0.' + Number(item.vat))).toFixed(2))} </span>
                                                }
                                            </TableCell>

                                            <TableCell align="right">
                                                {
                                                    Number(item.discount) !== 0
                                                        ?
                                                        <span>
                                                            {new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format((item.qty * (item.price - (item.price * (1 - (100 - item.discount) / 100))) * ('1.' + Number(item.vat))).toFixed(2))}</span>
                                                        :
                                                        <span>{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format((item.qty * item.price * ('1.' + Number(item.vat))).toFixed(2))} </span>
                                                }
                                            </TableCell>
                                        </TableRow>
                                        :
                                        <TableRow key={index}>
                                            <TableCell >Settlement discount: if ordered and paid 50% within {item.days} days </TableCell>
                                            <TableCell > </TableCell>
                                            <TableCell > </TableCell>
                                            {this.state.discount && <TableCell > </TableCell>}
                                            {this.state.discount && <TableCell > </TableCell>}

                                            <TableCell > </TableCell>
                                            <TableCell align="right" >{item.discount}% - {new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format(this.state.totalDiscount)}</TableCell>

                                        </TableRow>
                                )
                            })}

                            <TableRow key="spacer" style={{ padding: '4em 0 1.5em 2em' }}>
                                <TableCell ></TableCell>
                                <TableCell ></TableCell>
                                <TableCell ></TableCell>
                                {this.state.discount && <TableCell ></TableCell>}
                                {this.state.discount && <TableCell ></TableCell>}
                                <TableCell align="right" ></TableCell>
                                <TableCell align="right" style={{ padding: '4em 0 1.5em 2em' }} ><h3>Summary</h3></TableCell>
                            </TableRow>

                            <TableRow key="netsummary">
                                <TableCell ></TableCell>
                                <TableCell ></TableCell>
                                <TableCell ></TableCell>
                                {this.state.discount && <TableCell ></TableCell>}
                                {this.state.discount && <TableCell ></TableCell>}
                                <TableCell align="right" ><b>Net Amount:</b> </TableCell>
                                <TableCell align="right" >{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format(Number(this.state.netValue))}</TableCell>

                            </TableRow>

                            {this.state.discount &&
                                <TableRow key="discount">
                                    <TableCell ></TableCell>
                                    <TableCell ></TableCell>
                                    <TableCell ></TableCell>
                                    {this.state.discount && <TableCell ></TableCell>}
                                    {this.state.discount && <TableCell ></TableCell>}
                                    <TableCell align="right" ><b>Total Net Discount:</b> </TableCell>
                                    <TableCell align="right" >{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format(this.state.totalDiscount)}</TableCell>
                                </TableRow>
                            }
                            <TableRow key="vatTotal">
                                <TableCell ></TableCell>
                                <TableCell ></TableCell>
                                <TableCell ></TableCell>
                                {this.state.discount && <TableCell ></TableCell>}
                                {this.state.discount && <TableCell ></TableCell>}
                                <TableCell align="right"><b>VAT :</b></TableCell>

                                <TableCell align="right">{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format(this.state.totalVat)}</TableCell>
                            </TableRow>
                            <TableRow key="summary">
                                <TableCell ></TableCell>
                                <TableCell ></TableCell>
                                <TableCell ></TableCell>
                                {this.state.discount && <TableCell ></TableCell>}
                                {this.state.discount && <TableCell ></TableCell>}
                                <TableCell align="right"><b>Total :</b></TableCell>

                                <TableCell align="right">{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format(Number(this.state.totalVat) - Number(this.state.totalDiscount) + Number(this.state.netValue))}</TableCell>
                            </TableRow>

                        </TableBody>

                    </Table>
                    <h5 style={{ textAlign: 'left', marginTop: '20px' }}> NOTE: </h5>
                    <ol style={{ textAlign: 'left', fontSize: '11px' }}>
                        <li>The customer is obligated to check the quotation for compliance with their request.</li>
                        <li>The quotation is representative of the entire request, and is dependent on the volume of elements. The change of any factors (dimensions, colours, ironmongery, glass, quantity of order, etc.) requires a recalculation of the quotation.</li>
                        <li>The customer is obligated to confirm that the ordered product complies with the specifications and regulations required by them.</li>
                    </ol>
                </div>




                <div
                    className="terms"
                    dangerouslySetInnerHTML={{ __html: Terms }} >
                </div>
                <Footer></Footer>
            </div>
        )
    }

}
export default withStyles(styles)(Quote);