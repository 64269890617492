import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import EditManu from '../partials/EditMenu';

// import * as firebase from 'firebase'
import firebase from "firebase/compat/app";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {  suppliers } from '../logic/financialReports';


const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(2),
        overflowX: 'auto',
    },
    table: {
         minWidth: 700,
    },
    paper:{
        margin: '2%',
        overflowX: 'scroll'
    },
});

class OrderDetailsTable extends Component{


    state = {
        allItems: [],
        anchorEl: null,
    }
    componentDidMount(){
        // set refference to firebase 
        const ordersRef = firebase.database().ref(`Orders/${this.props.orderId}/AllItems`);
        
        //load all orderes
        ordersRef.on('value',snap =>{
            
            let costsFB = snap.val();

            if(costsFB !== null){
                let output = Object.entries(costsFB).map(([key, value]) => ({key,value}));

                this.setState({ 
                    allItems: output , 
                });
  
            }else{
                this.setState({
                    allItems: []
                })
            }
        })
 
    }
    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };
    
    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    render() {
        return (
            
            <Paper className={this.props.classes.paper} elevation={0} variant="outlined" >
            <Table className={this.props.classes.table}>
                <TableHead>
                <TableRow>
                    <TableCell> Name</TableCell>
                    <TableCell>Supplier</TableCell>
                    <TableCell>Qty</TableCell>
                    <TableCell>Discount(%)</TableCell>
                    <TableCell>Vat Rate</TableCell>
                    <TableCell>Value</TableCell>
                    <TableCell > Edit</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>

                {this.state.allItems.map((item)=>{ 
                        return (
                            <TableRow key={item.key}>

                            <TableCell>  <span dangerouslySetInnerHTML={{__html: item.value.name}} /></TableCell>
                            <TableCell>{item.value.supplier}</TableCell>
                            <TableCell>{item.value.qty}</TableCell>
                            <TableCell>{item.value.discount}</TableCell>
                            <TableCell>{item.value.vat}</TableCell>
                            <TableCell>{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format(item.value.price)}</TableCell>


                                <TableCell >

                                    {/* {this.props.sysUser.access > 2 && */}
                                    <EditManu 
                                        mainGroup="Orders"
                                        parentType='AllItems'
                                        orderId={this.props.orderId}
                                        recordKey={item.key}
                                        update={[

                                            { type:'text', id:'name', label: 'Name', value: item.value.name},
                                            { type:'text', id:'qty', label: 'Qty', value: item.value.qty},
                                            { type:'text', id:'discount', label: 'discount', value: item.value.discount},
                                            { type:'select', id:'supplier', label: 'Supplier', value: item.value.supplier, options: suppliers },
                                            { type:'select', id:'vat', label: 'VAT rate ', value: item.value.vat, options: [{ value: '23',label: '23%'},{ value: '21',label: '21%'},{value: '135',label: '13.5%'},{value: '0',label: '0%',}]},
                                            { type:'text', id:'price', label: 'price', value: item.value.price},
                                            
                                        ]}
                                    ></EditManu>
                                {/* } */}
                                </TableCell>
                            </TableRow>
                        );
                    })}

                
                </TableBody>
            </Table>
            </Paper>
        );
    }
    }
OrderDetailsTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OrderDetailsTable);