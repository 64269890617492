import React, { Component } from 'react';

import chain from '../../../SteelDoor/Drawings/ironmongery/chain.svg';



class PrisonChain extends Component{

    flip =() =>{
        if(this.props.side === 'right'){
            return('scaleX(-1)');
        }else{
            return('scaleX(1)');
        }
    }

    render() {
        
        return (
            <img id="chain"  src={chain} alt="chain"  style={{width: `${260/this.props.printRatio}px`,
            height: 'auto',
            transform: `${this.flip()}`,
            display: 'block',
            position: 'absolute',
            bottom: `${this.props.bottom/this.props.printRatio}px`,
            [this.props.side] : `${this.props.sideDist}px`,
        }}/> 
        )
    }
}

export default PrisonChain;