import React, { Component } from 'react';

class PrisonSlideLatch extends Component{

    state= {}

    flip =() =>{
        if(this.props.side === 'right'){
            return('scaleX(1)');
        }else{
            return('scaleX(-1)');
        }
    }

    color = () =>{
        if(this.props.inside){
            return('#000');
        }else{
            return('#c5c3c3');
        }
    }

    render() {
        
        return (     
                <div style={{
                        position: 'absolute', 
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        transform: `${this.flip()}`,
                        bottom: `${this.props.bottom/this.props.printRatio}px`, 
                        height: `${60/this.props.printRatio}px`, 
                        width: `${280/this.props.printRatio}px`,
                        [this.props.side]: `${-40/this.props.printRatio}px`, 
                    }}>
                        <div style={{ height: `${40/this.props.printRatio}px`, width: `${20/this.props.printRatio}px`, border: `1px solid ${this.color()}`, 'borderRadius': `${10/this.props.printRatio}px`}}></div>
                        <div style={{ height: `${60/this.props.printRatio}px`, width: `${10/this.props.printRatio}px`, border: `1px solid ${this.color()}`, 'borderRadius': `${10/this.props.printRatio}px`}}></div>
                            <div style={{
                                    border: `1px solid ${this.color()}`, 
                                    margin: '0% auto', 
                                    
                                    [this.props.side]: `${50/this.props.printRatio}px`,
                                    width: `${180/this.props.printRatio}px`,
                                    height: `${40/this.props.printRatio}px`,
                                }}>
                            </div>   
                        <div style={{ height: `${60/this.props.printRatio}px`, width: `${10/this.props.printRatio}px`, border: `1px solid ${this.color()}`, 'borderRadius': `${10/this.props.printRatio}px`}}></div>
                        <div style={{ height: `${60/this.props.printRatio}px`, width: `${10/this.props.printRatio}px`, border: `1px solid ${this.color()}`, 'borderRadius': `${10/this.props.printRatio}px`}}></div>
                        
                </div> 
                
           
       )
    }
}

export default PrisonSlideLatch;