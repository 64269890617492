import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({

    arrowLeft:{
        width: 0,
        height: 0, 
        borderTop: '3px solid transparent',
        borderBottom: '3px solid transparent',
        borderRight: '10px solid #d2d2d2',
        float: 'left',
        marginTop: '-1px', 
    },
    pagebreak: { pageBreakBefore: 'always' },
    arrowRight:{
        width: 0,
        height: 0, 
        borderTop: '3px solid transparent',
        borderBottom: '3px solid transparent',
        borderLeft: '10px solid #d2d2d2',
        float: 'right',
        marginTop: '-1px',
    } 
});



class TopMeasurement extends Component{

  

    width = () => {
      if(this.props.mtype === "frame" ){
        return `${(Number(this.props.width)+Number(this.props.clearOpeningWidth))/this.props.printRatio}px`;
      }
      if( this.props.mtype === "panel" ){
        return `${(Number(this.props.width))/this.props.printRatio}px`;
      }
      if(this.props.mtype === "wallOp"){
        return `${(Number(this.props.width))/this.props.printRatio}px`;
      }
      
    }

    descryption = () => {
      if(this.props.mtype === "frame" ){
        return <span>Frame - {Number(this.props.width)+  Number(this.props.clearOpeningWidth) }mm</span>;
      }

  

      if(  this.props.mtype === 'wallOp'  ){ 

        // console.log(this.props.descryption)

        if(this.props.transom === true){
        //   if(this.props.descryption <= 2710 ){
            return <span> Wall Opening - {Number(this.props.descryption)}mm</span>;
        //   }else{
        //     return <span style={{color: 'red'}}> TRANSOM TO WIDE !!!</span>;
        //   }    
        }else{
          return <span> Wall Opening - {Number(this.props.descryption)}mm</span>;
        } 
      }

      if( this.props.mtype === "panel" ){
        return <span>{Number(this.props.width)}mm</span>;
      }
    }

    height = () =>{


      if( this.props.mtype === "wallOp" ){
        return '60px';
      }else{
        return '40px';
      }

    }

    state= {}

    render() {
      const { classes } = this.props;
        return (
          

            <div 
            className='topMesurements'
            style={{
                    width: [this.width()],
                    height: [this.height()],
                    borderLeft: '1px solid #eee',
                    borderRight: '1px solid #eee',
                    // float: 'left',
                    display: 'block',
                    
                    
                   
                    }}>
                    <div style={{
                            height: `1px`, 
                            width: [this.width()], 
                            margin: '0 auto',
                            borderBottom: '1px solid #eee',
                            }}>
                            
                            <div className={classes.arrowLeft}></div>
                            <div className={classes.arrowRight}></div>
                    </div>
                    <small style={{margin: '-8px auto', display: 'table', backgroundColor: 'white'}}>{this.descryption()}</small>

            </div>
      
       )
    }
}

export default withStyles(styles)(TopMeasurement);