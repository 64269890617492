import React, { Component } from 'react';

class AntiBurglaryBolts extends Component{

    state= {}

    render() {
        
        return (
            <div>
                <div style={{ height: `${15/this.props.printRatio}px`, width: `${45/this.props.printRatio}px`, border: '1px solid #d2cdcd', position: 'absolute',margin: '0 auto',[this.props.side]: '-6px',top: '10%'}}></div>
                <div style={{ height: `${15/this.props.printRatio}px`, width: `${45/this.props.printRatio}px`, border: '1px solid #d2cdcd', position: 'absolute',margin: '0 auto',[this.props.side]: '-6px',bottom: '50%'}}></div>
                <div style={{ height: `${15/this.props.printRatio}px`, width: `${45/this.props.printRatio}px`, border: '1px solid #d2cdcd', position: 'absolute',margin: '0 auto',[this.props.side]: '-6px',bottom: '10%'}}></div>
            </div>  
       )
    }
}

export default AntiBurglaryBolts;