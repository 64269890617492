import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { userContext } from '../../../Context/userContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Slider from '@material-ui/core/Slider';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const styles = theme => ({
    textField: {
        width: '100%',
        margin: "16px 3px",
        background: "#fff"
    },

    paper: {
        padding: '1rem',
        border: '1px solid #efefef',
        margin: '0.5rem 0',
        display: 'flex',
        flexDirection: 'column'
    }


});


class ConfigAdjustments extends Component {
    render() {
        const { classes } = this.props;
        return (<userContext.Consumer>
            {(user) => (user.access >= 1 ?
                <div style={{ display: "flex", flexDirection: 'column' }}>

                    <h3>Adjustments</h3>
                    <Paper className={classes.paper}>

                        Sceen
                        <TextField
                            id="bgColor"
                            select
                            label="Background Color"
                            className={classes.textField}
                            value={this.props.backgroundColor}
                            onChange={this.props.handleChange('backgroundColor')}
                            SelectProps={{
                                native: true,
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            helperText=""
                            margin="normal"
                        >
                            <option key="1" value="#efefef">Grey</option>
                            <option key="2" value="#ffffff">White</option>
                        </TextField>

                        {user.access >= 10 &&

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        className={classes.checked}
                                        checked={this.props.floor}
                                        onChange={this.props.handleChange('floor')}
                                    />
                                }
                                label={<h4>Show floor</h4>}
                            />
                        }

                        {user.access >= 10 &&

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        className={classes.checked}
                                        checked={this.props.floor}
                                        onChange={this.props.handleChange('wall')}
                                    />
                                }
                                label={<h4>Show wall</h4>}
                            />
                        }

                        {/* <FormControlLabel
                            control={
                                <Checkbox
                                    className={classes.checked}
                                    checked={this.props.measurements}
                                    onChange={this.props.handleChange('measurements')}

                                />
                            }
                            label={<h4>Show floor</h4>}
                        /> */}

                    </Paper>

                    {user.access >= 10 &&

                        <Paper className={classes.paper}>

                            <h5>Materials</h5>

                            Roughness
                            < Slider
                                style={{ flexBasis: "100%", margin: '20px 0 0' }}
                                label="Strenght"
                                value={this.props.roughness}
                                aria-labelledby="discrete-slider"
                                valueLabelDisplay="auto"
                                step={0.05}
                                marks
                                min={0}
                                max={1}
                                onChange={this.props.handleSlider("roughness")}
                            />
                            Metalness
                            < Slider
                                style={{ flexBasis: "100%", margin: '20px 0 0' }}
                                label="Strenght"
                                value={this.props.metalness}
                                aria-labelledby="discrete-slider"
                                valueLabelDisplay="auto"
                                step={0.05}
                                marks
                                min={0}
                                max={1}
                                onChange={this.props.handleSlider("metalness")}
                            />
                        </Paper>

                    }

                    <Paper className={classes.paper}>
                        <h5>Lights</h5>
                        {this.props.lights.map((light, index) => {
                            return (
                                <div style={{ display: 'flex', flexWrap: "wrap", justifyContent: 'space-between' }} key={index}>
                                    <h5 style={{ flexBasis: "100%", textAlaign: "left" }} >Light {index + 1} - {light.type}</h5>
                                    <TextField
                                        label="X"
                                        onChange={this.props.handleLights('x', index)}
                                        value={light.x}
                                        style={{ flexbasis: "32%" }}
                                        type="number"
                                        InputProps={{ inputProps: { max: 1500, min: -1500 } }}
                                    ></TextField>
                                    <TextField
                                        label=" Y"
                                        onChange={this.props.handleLights('y', index)}
                                        value={light.y}
                                        style={{ flexbasis: "32%" }}
                                        type="number"
                                        InputProps={{ inputProps: { max: 1500, min: -1500 } }}
                                    ></TextField>
                                    <TextField
                                        style={{ flexbasis: "32%" }}
                                        label=" Z"
                                        onChange={this.props.handleLights('z', index)}
                                        value={light.z}
                                        // className={classes.textField}
                                        type="number"
                                        InputProps={{ inputProps: { max: 1500, min: -1500 } }}
                                    ></TextField>
                                    <Slider
                                        style={{ flexBasis: "100%", margin: '20px 0 0' }}
                                        label="Strenght"
                                        //defaultValue={light.strenght}
                                        value={light.strenght}
                                        aria-labelledby="discrete-slider"
                                        valueLabelDisplay="auto"
                                        step={0.1}
                                        marks
                                        min={0}
                                        max={1}
                                        onChange={this.props.handleLightsSlider(index)}
                                    />
                                    <hr style={{ flexBasis: "100%", margin: '10px 0' }} />
                                </div>
                            )
                        })}
                    </Paper>
                </div> : <> {this.state.spinner ? <CircularProgress /> : <span></span>}  </>
            )}</userContext.Consumer>)
    }
}

export default withStyles(styles)(ConfigAdjustments);