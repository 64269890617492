import React, { Component } from 'react';
class Kickplate3 extends Component{
    color = () =>{
        if(this.props.inside === true  && this.props.swingDirection === "Inswing"){
            return('#000');
        }else if(this.props.inside === false  && this.props.swingDirection === "Inswing"){
            return('#c5c3c3');
        }else if(this.props.inside === true  && this.props.swingDirection === "Outswing"){
            return('#000');
        }else if(this.props.inside === false  && this.props.swingDirection === "Outswing"){
            return('#c5c3c3');
        }
    }
    render() {
        return (
            <div id="pushplate">
                <div style={{ height: `${this.props.height/this.props.printRatio}px`,  width: `${this.props.width/this.props.printRatio}px`,boxSizing: 'border-box', border: `1px solid ${this.color()}`, position: 'absolute',margin: '0 auto',[this.props.handleSide]: `${this.props.sideDist/this.props.printRatio}px`,bottom: `${this.props.bottom/this.props.printRatio}px`}}>
                    <span style={{fontSize: '9px',  color: '#666', lineHeight: '1em'}}></span>
                </div>
            </div>  
        )
    }
}
export default Kickplate3;