import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

//-Door leafs
import SideMeasurement from './Drawings/Measurements/SideMeasurement';
import TopMeasurement from './Drawings/Measurements/TopMeasurement';
import ActiveLeaf from '../SteelDoor/Drawings/ActiveLeaf';
import NonActiveLeaf from '../SteelDoor/Drawings/NonActiveLeaf';

import Grid from '@material-ui/core/Grid';
import Threshold from '../SteelDoor/Drawings/threshold/threshold';
import Frame from '../SteelDoor/Drawings/frame/frame';

import SideAndSwing from '../SteelDoor/Drawings/sideAndSwing/sideAndSwing';
import Panel from '../SteelDoor/Drawings/panel';

const styles = theme => ({

    diag: {
        width: '100%',
        height: '100%',
        background: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%'><line x1='0' y1='0' x2='100%' y2='50%' stroke='#dedede' stroke-width='1' stroke-dasharray='5,5'  /><line x1='0' y1='100%' x2='100%' y2='50%' stroke='#dedede' stroke-width='1' stroke-dasharray='5,5'  /></svg>") no-repeat`
    }
});




class Frame2 extends Component {

    state = {
        mainLeaf: '',
        secondLeaf: '',
        handleSide: 'left',
        doorSide: 'left',
        restSide: 'right',
        boxHeight: '',
        rotate: 'rotate(0deg)',
        rotateText: 'rotate(0deg) translate(-50%)',
        topHandleSide: 'left',
        topDoorSide: 'left',
        topRestSide: 'right',
    }

    boxHeight = () => {

        if (this.props.singleDouble === "Double") {


            if (Number(this.state.mainLeaf) > (Number(this.props.width) - Number(this.state.mainLeaf))) {


                this.setState({
                    boxHeight: this.state.mainLeaf
                })
            } else {
                let height = Number(this.props.width) - Number(this.state.mainLeaf);
                this.setState({
                    boxHeight: height
                })
            }
        } else {
            this.setState({
                boxHeight: this.props.width
            })

        }
    }


    


    adjustment() {
        if (this.props.swingDirection === "Inswing") {
            if (this.props.singleDouble === "Double") {

                let secondDoorwidth = Number(this.props.width) - this.props.doubleDoorMainLeafwidth;

                if (this.props.side === "Right Hand") {
                    this.setState({
                        handleSide: 'left',
                        doorSide: 'right',
                        restSide: 'left',
                        mainLeaf: this.props.doubleDoorMainLeafwidth,
                        secondLeaf: secondDoorwidth,
                        rotate: 'rotate(0deg)',
                        rotateText: 'rotate(0deg) translate(-50%)',
                        topHandleSide: 'right',
                        topDoorSide: 'right',
                        topRestSide: 'left',
                    }, () => { this.boxHeight() })
                } else {
                    this.setState({
                        handleSide: 'right',
                        doorSide: 'left',
                        restSide: 'right',
                        mainLeaf: this.props.doubleDoorMainLeafwidth,
                        secondLeaf: secondDoorwidth,
                        rotate: 'rotate(0deg)',
                        rotateText: 'rotate(0deg) translate(-50%)',
                        topHandleSide: 'left',
                        topDoorSide: 'left',
                        topRestSide: 'right',
                    }, () => { this.boxHeight() })
                }
            } else {
                let mainDoorwidth = Number(this.props.width) - (this.props.clearOpeningWidth * 0.7);

                if (this.props.side === "Right Hand") {

                    this.setState({
                        handleSide: 'left',
                        doorSide: 'right',
                        mainLeaf: mainDoorwidth,
                        boxHeight: this.props.width,
                        rotate: 'rotate(0deg)',
                        rotateText: 'rotate(0deg) translate(-50%)',
                        topHandleSide: 'right',
                        topDoorSide: 'right',
                        topRestSide: 'left',
                    })
                } else {
                    this.setState({
                        handleSide: 'right',
                        doorSide: 'left',
                        mainLeaf: mainDoorwidth,
                        boxHeight: this.props.width,
                        rotate: 'rotate(0deg)',
                        rotateText: 'rotate(0deg) translate(-50%)',
                        topHandleSide: 'left',
                        topDoorSide: 'left',
                        topRestSide: 'right',
                    })
                }
            }
        } else {
            if (this.props.singleDouble === "Double") {
                let secondDoorwidth = Number(this.props.width) - this.props.doubleDoorMainLeafwidth;
                if (this.props.side === "Right Hand") {
                    this.setState({
                        handleSide: 'left',
                        doorSide: 'right',
                        restSide: 'left',
                        mainLeaf: this.props.doubleDoorMainLeafwidth,
                        secondLeaf: secondDoorwidth,
                        rotate: 'rotate(180deg)',
                        rotateText: `rotate(180deg) translate(50%)`,
                        topHandleSide: 'left',
                        topDoorSide: 'left',
                        topRestSide: 'right',

                    }, () => { this.boxHeight() })
                } else {
                    this.setState({
                        handleSide: 'right',
                        doorSide: 'left',
                        restSide: 'right',
                        mainLeaf: this.props.doubleDoorMainLeafwidth,
                        secondLeaf: secondDoorwidth,
                        rotate: 'rotate(180deg)',
                        rotateText: `rotate(180deg) translate(50%)`,
                        topHandleSide: 'right',
                        topDoorSide: 'right',
                        topRestSide: 'left',
                    }, () => { this.boxHeight() })
                }
            } else {
                let mainDoorwidth = Number(this.props.width) - (this.props.clearOpeningWidth * 0.7);

                if (this.props.side === "Right Hand") {

                    this.setState({
                        handleSide: 'left',
                        doorSide: 'right',
                        mainLeaf: mainDoorwidth,
                        boxHeight: this.props.width,
                        rotate: 'rotate(180deg)',
                        rotateText: `rotate(180deg) translate(50%)`,
                        topHandleSide: 'left',
                        topDoorSide: 'left',
                        topRestSide: 'right',
                    })
                } else {
                    this.setState({
                        handleSide: 'right',
                        doorSide: 'left',
                        mainLeaf: mainDoorwidth,
                        boxHeight: this.props.width,
                        rotate: 'rotate(180deg)',
                        rotateText: `rotate(180deg) translate(50%)`,
                        topHandleSide: 'right',
                        topDoorSide: 'right',
                        topRestSide: 'left',
                    })
                }
            }

        }
    }



    insideOutside(side) {
        if (side === true) {
            return ('#000');
        } else {
            return ('#eee');
        }
    }


    componentDidMount() {

        if (this.props.singleDouble === "Double") {

            this.setState({
                mainLeaf: this.props.doubleDoorMainLeafwidth
            })
        } else {
            this.setState({
                mainLeaf: this.props.width
            })
        }
        this.adjustment();


    }



    componentDidUpdate(prevProps, prevState) {
        if (prevProps !== this.props) {
            this.adjustment();
        }
    }


    totalWidth = (addTo = 0) => {
        return `${((Number(this.props.leftPanelWidth) + Number(this.props.rightPanelWidth) + Number(this.props.width) + Number(this.props.clearOpeningWidth)) / this.props.printRatio) + addTo}px`
    }

    transomWidth = () =>{

        return

    }


    render() {
        const { classes } = this.props;

        return (

            <div style={{ position: 'relative', width: '100%' }}>

                <Grid container spacing={0} >
                    <Grid item xs={9} style={{ position: 'relative' }} >
                        <SideAndSwing side={this.props.side} swingDirection={this.props.swingDirection} ></SideAndSwing>
                        <div style={{ width: [this.totalWidth(6)], margin: '20px auto 0', }}>


                            <TopMeasurement
                                clearOpeningWidth={this.props.clearOpeningWidth}
                                printRatio={this.props.printRatio}
                                width={this.totalWidth(6)}
                                transom={this.props.transom}
                                descryption={Number(this.props.leftPanelWidth) + Number(this.props.rightPanelWidth) + Number(this.props.width) + Number(this.props.clearOpeningWidth) + 20}
                                mtype="wallOp"
                            ></TopMeasurement>


                            <div style={{
                                margin: '-40px auto 0',
                                height: '40px',
                                display: 'flex',
                                justifyContent: 'center'
                            }}>



                                {this.props.leftPanel &&
                                    <TopMeasurement
                                        clearOpeningWidth={this.props.clearOpeningWidth}
                                        printRatio={this.props.printRatio}
                                        width={this.props.leftPanelWidth}
                                        mtype="panel"
                                    ></TopMeasurement>
                                }

                                <TopMeasurement
                                    clearOpeningWidth={this.props.clearOpeningWidth}
                                    printRatio={this.props.printRatio}
                                    width={this.props.width}
                                    mtype="frame"
                                ></TopMeasurement>

                                {this.props.rightPanel &&
                                    <TopMeasurement
                                        clearOpeningWidth={this.props.clearOpeningWidth}
                                        printRatio={this.props.printRatio}
                                        width={this.props.rightPanelWidth}
                                        mtype="panel"
                                    ></TopMeasurement>
                                }
                            </div>


                        </div>




                        <div className="wrapper"
                            style={{
                                margin: '0 auto',
                                position: 'relative',
                                background: 'white',
                                width: [this.totalWidth()],

                            }}>





                            <SideMeasurement
                                mtype="wallOp"
                                transom={this.props.transom}
                                height={this.props.height}
                                clearOpeningHeight={this.props.clearOpeningHeight}
                                transomHeight={this.props.transomHeight}
                                printRatio={this.props.printRatio}
                                threshold={this.props.threshold}
                            ></SideMeasurement>






                            {this.props.transom &&
                                <div it="transomWrapper" style={{ position: "relative", width: "100%" }}>

                                    <SideMeasurement
                                        mtype="transom"
                                        transomHeight={this.props.transomHeight}
                                        printRatio={this.props.printRatio}
                                        clearOpeningHeight={this.props.clearOpeningHeight}
                                    ></SideMeasurement>

                                    <div style={{ 

                                        left:  this.props.transomType === 'type-2' ? "0px" : `${this.props.leftPanelWidth / this.props.printRatio}px`,
                                  
                                        position: "relative", 
                                        width: this.props.transomType === 'type-2' ? this.totalWidth() : (Number(this.props.width) + Number(this.props.clearOpeningWidth)) / this.props.printRatio}}>
                                        <Panel
                                            width={this.props.transomType === 'type-2' ? `${this.totalWidth()}` : `${(Number(this.props.width) + Number(this.props.clearOpeningWidth)) / this.props.printRatio}px`}
                                            height={`${this.props.transomHeight / this.props.printRatio}px`}
                                            paddingHeight={`${((this.props.clearOpeningHeight) / 1.5) / this.props.printRatio}px`}
                                            paddingWidth={`${((this.props.clearOpeningWidth) / 3.1) / this.props.printRatio}px`}
                                            printRatio={this.props.printRatio}
                                            ventilationList={this.props.ventilationList}

                                            visionPanels={this.props.visionPanels}
                                            
                                            panelPlacement="tr"
                                        ></Panel>
                                    </div>



                                </div>
                            }

                            <div id='panelsAndDoorWrapper'
                                style={{
                                    position: 'relative',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    width: `${(Number(this.props.width) + Number(this.props.leftPanelWidth) + Number(this.props.rightPanelWidth) + Number(this.props.clearOpeningWidth)) / this.props.printRatio}px`,
                                    width: [this.totalWidth()],
                                    margin: '0px auto'
                                }}>
                                <SideMeasurement
                                    mtype="frame"
                                    height={this.props.height}
                                    clearOpeningHeight={this.props.clearOpeningHeight}
                                    transomHeight={this.props.transomHeight}
                                    printRatio={this.props.printRatio}
                                    threshold={this.props.threshold}
                                    transom={this.props.transom}
                                ></SideMeasurement>
                                {this.props.leftPanel &&
                                    <div style={{ top: this.props.transomType === 'type-2' ? "0px" : `-${Number(this.props.transomHeight) / this.props.printRatio}px`, position: "relative" }}>
                                        <Panel

                                            width={`${this.props.leftPanelWidth / this.props.printRatio}px`}
                                            height={this.props.transomType === 'type-2' ? `${(Number(this.props.height) + Number(this.props.clearOpeningHeight)) / this.props.printRatio + 2}px` : `${(Number(this.props.height) + Number(this.props.transomHeight) + Number(this.props.clearOpeningHeight)) / this.props.printRatio + 2}px`}
                                            paddingHeight={`${((this.props.clearOpeningHeight) / 1.55) / this.props.printRatio}px`}
                                            paddingWidth={`${((this.props.clearOpeningWidth) / 3.1) / this.props.printRatio}px`}
                                            printRatio={this.props.printRatio}
                                            ventilationList={this.props.ventilationList}
                                            visionPanels={this.props.visionPanels}
                                            panelPlacement="lp"

                                        ></Panel>
                                    </div>
                                }

                                <div id="doorAndFrame"
                                    style={{
                                        height: `${(Number(this.props.height) + Number(this.props.clearOpeningHeight)) / this.props.printRatio}px`,
                                        width: `${(Number(this.props.width) + Number(this.props.clearOpeningWidth)) / this.props.printRatio}px`,
                                        border: '0.001rem solid black',
                                        position: 'relative',
                                        margin: '0px auto 25px',
                                        zIndex: '0'
                                    }}>



                                    {this.props.additional.map((opt, index) => {
                                        return ((opt.label.includes('DC340 with integrated mechanical coordinator') || opt.label.includes('DC500 with integrated mechanical coordinator')) &&
                                            <div key={index} id="rkzrail" style={{ height: '2px', width: 'calc(100% - 55px)', border: `1px solid ${this.insideOutside(opt.inside)}`, position: 'absolute', margin: '0 auto', left: '27px', top: `${24 / this.props.printRatio}px` }}></div>
                                        )
                                    })}


                                    {/* ------------------------- Clear opening */}




                                    <div id="clearOpening" style={{
                                        height: `${((this.props.height) / this.props.printRatio) - 3}px`,
                                        width: `${(this.props.width) / this.props.printRatio}px`,
                                        border: '1px solid #dedcdc',
                                        position: 'absolute',
                                        margin: '0 auto',
                                        left: `${(this.props.clearOpeningWidth / 2) / this.props.printRatio}px`,
                                        top: `${((this.props.clearOpeningHeight) / this.props.printRatio) - 1}px`,
                                        background: 'none',
                                    }}>

                                    </div>



                                    {/* ------------------------- Single door  */}

                                    <ActiveLeaf
                                        additional={this.props.additional}
                                        height={this.props.height}
                                        width={this.props.width}
                                        printRatio={this.props.printRatio}
                                        clearOpeningHeight={this.props.clearOpeningHeight}
                                        clearOpeningWidth={this.props.clearOpeningWidth}
                                        mainLeaf={this.state.mainLeaf}
                                        doubleDoorMainLeafwidth={this.props.doubleDoorMainLeafwidth}
                                        doorSide={this.state.doorSide}
                                        handleSide={this.state.handleSide}
                                        singleDouble={this.props.singleDouble}
                                        visionPanel={this.props.visionPanel}
                                        visionPanelRestrictions={this.props.visionPanelRestrictions}
                                        visionPanels={this.props.visionPanels}
                                        maxVPWidth2={this.props.maxVPWidth2}
                                        maxVPWidth={this.props.maxVPWidth}
                                        maxVPHeight={this.props.maxVPHeight}
                                        fire={this.props.fire}
                                        grade={this.props.grade}
                                        ventilationList={this.props.ventilationList}
                                        ventilation={this.props.ventilation}
                                        doorType={this.props.doorType}
                                        frameType={this.props.frameType}
                                        swingDirection={this.props.swingDirection}
                                    ></ActiveLeaf>



                                    {/* -------------------------  Double door */}


                                    {this.props.singleDouble === "Double" &&

                                        <NonActiveLeaf
                                            additional={this.props.additional}
                                            height={this.props.height}
                                            width={this.props.width}
                                            printRatio={this.props.printRatio}
                                            clearOpeningHeight={this.props.clearOpeningHeight}
                                            clearOpeningWidth={this.props.clearOpeningWidth}
                                            mainLeaf={this.state.mainLeaf}
                                            restSide={this.state.restSide}
                                            doubleDoorMainLeafwidth={this.props.doubleDoorMainLeafwidth}
                                            doorSide={this.state.doorSide}
                                            handleSide={this.state.handleSide}
                                            singleDouble={this.props.singleDouble}
                                            secondLeaf={this.state.secondLeaf}
                                            fire={this.props.fire}
                                            grade={this.props.grade}
                                            visionPanel={this.props.visionPanel}
                                            visionPanelRestrictions={this.props.visionPanelRestrictions}
                                            visionPanels={this.props.visionPanels}
                                            maxVPWidth2={this.props.maxVPWidth2}
                                            maxVPWidth={this.props.maxVPWidth}
                                            maxVPHeight={this.props.maxVPHeight}
                                            ventilationList={this.props.ventilationList}
                                            ventilation={this.props.ventilation}
                                            frameType={this.props.frameType}
                                            swingDirection={this.props.swingDirection}
                                            doorType={this.props.doorType}
                                        ></NonActiveLeaf>

                                    }

                                    {/* ------------------------- Threshold */}
                                    <div
                                        id="treshold"
                                        style={{
                                            height: `${this.props.threshold / this.props.printRatio}px`,
                                            width: `${(this.props.width) / this.props.printRatio}px`,
                                            border: '1px solid #dedcdc',
                                            position: 'absolute',
                                            margin: '0 auto',
                                            left: `${(this.props.clearOpeningWidth / 2) / this.props.printRatio}px`,
                                            bottom: `2px`
                                        }}>
                                    </div>
                                </div>

                                {this.props.rightPanel &&
                                    <div style={{ top: this.props.transomType === 'type-2' ? "0px" : `-${Number(this.props.transomHeight) / this.props.printRatio}px`, position: "relative" }}>
                                        <Panel

                                            width={`${this.props.rightPanelWidth / this.props.printRatio}px`}
                                            height={this.props.transomType === 'type-2' ? `${(Number(this.props.height) + Number(this.props.clearOpeningHeight)) / this.props.printRatio + 2}px` : `${(Number(this.props.height) + Number(this.props.transomHeight) + Number(this.props.clearOpeningHeight)) / this.props.printRatio + 2}px`}
                                            paddingHeight={`${((this.props.clearOpeningHeight) / 1.55) / this.props.printRatio}px`}
                                            paddingWidth={`${((this.props.clearOpeningWidth) / 3.1) / this.props.printRatio}px`}
                                            printRatio={this.props.printRatio}
                                            ventilationList={this.props.ventilationList}
                                            visionPanels={this.props.visionPanels}
                                            panelPlacement="rp"
                                        ></Panel>
                                    </div>
                                }


                            </div>

                        </div>
                    </Grid>



                    <Grid item xs={3} style={{ borderLeft: '1px solid #eee' }}>

                        <Threshold
                            printRatio={this.props.printRatio}
                            height={this.props.height}
                            fire={this.props.fire}
                            threshold={this.props.threshold}
                            frameType={this.props.frameType}
                        ></Threshold>

                        <Frame
                            printRatio={this.props.printRatio}
                            frameType={this.props.frameType}
                        ></Frame>
                    </Grid>
                </Grid>


                {/* {Number(this.props.width-this.props.clearOpeningWidth-this.props.handleWidth) > 899 && 
                        <Avatar className={classes.avatar}>
                            <Accessible/>
                        </Avatar>
                    } */}



            </div>

        )
    }

}

Frame2.defaultProps = {
    leftPanelWidth: 0,
    rightPanelWidth: 0,
    rightPanel: false,
    leftPanel: false

}

export default withStyles(styles)(Frame2);