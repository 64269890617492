import React, { Component } from 'react';
import { userContext } from '../../../Context/userContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import MenuItem from '@material-ui/core/MenuItem';
import HeightIcon from '@material-ui/icons/Height';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
    textField: {
        width: '100%',
        margin: "16px 3px",
        background: "#fff"
    },
    textFields4: {
        flex: "2 1 20%",
        margin: "16px 3px",
    },
    paper: {
        padding: '1rem',
        border: '1px solid #efefef',
        margin: '0.5rem 0',
        display: 'flex',
        flexDirection: 'column'
    }


});



class ConfigIronmongery extends Component {
    render() {
        const { classes } = this.props;
        return (<userContext.Consumer>
            {(user) => (user.access >= 1 ?
                <div style={{ flexDirection: 'column', display: 'flex', textAlign: 'left' }}>

                    <TextField
                        id="mainDoorIronmongery"
                        select
                        label="Ironmongery type"
                        className={classes.textField}
                        value={this.props.mainDoorIronmongery.type}
                        onChange={this.props.handleChange('mainDoorIronmongery', 'type')}
                        SelectProps={{
                            native: true,
                            MenuProps: {
                                className: classes.menu,
                            },
                        }}
                        helperText=""
                        margin="normal"
                    >
                        <option key="none" value="none">None</option>
                        <option key="handle" value="handle">Handle</option>
                        <option key="typeA" value="typeA">Type A</option>
                        <option key="typeB" value="typeB">Type B</option>
                        <option key="typeC" value="typeC">Type C</option>
                        <option key="typeD" value="typeD">Type D - Sliding doors</option>
                        <option key="typeE" value="typeE">Type E - Sliding doors</option>
                      
                    </TextField>

                    {this.props.singleDouble === "Double" && this.props.mainDoorIronmongery.type !== "none" &&
                        <MenuItem>
                            <Switch
                                checked={this.props.mainDoorIronmongery.twoLeaves}
                                onChange={this.props.handleChange('mainDoorIronmongery', 'twoLeaves')}
                                name="3d"

                            ></Switch>
                            On both leaves?
                        </MenuItem>
                    }






                    { (this.props.mainDoorIronmongery.type === "handle" ||
                    this.props.mainDoorIronmongery.type === "typeE" ) &&

                        <Paper elevation={0} className={classes.paper}>

                            <h5 style={{ textAlign: "left" }}>Lock Case</h5>
                            <div style={{ display: "flex", flexWrap: "wrap" }}>

                                <TextField
                                    id="caseWidth"
                                    label='Width'
                                    type="number"
                                    className={classes.textFields4}
                                    value={this.props.lockCase.x}
                                    onChange={this.props.handleChange('lockCase', 'x')}
                                    margin="normal"
                                />

                                <TextField
                                    id="caseHeight"
                                    label='Height'
                                    type="number"
                                    className={classes.textFields4}
                                    value={this.props.lockCase.y}
                                    onChange={this.props.handleChange('lockCase', 'y')}
                                    margin="normal"
                                />

                                <TextField
                                    id="caseDepth"
                                    label='Depth'
                                    type="number"
                                    className={classes.textFields4}
                                    value={this.props.lockCase.z}
                                    onChange={this.props.handleChange('lockCase', 'z')}
                                    margin="normal"
                                />

                                < div style={{ display: "flex" }} >
                                    <TextField
                                        id="lockCaseLocation"
                                        label={
                                            <div className={classes.label} style={{ display: "flex" }}>
                                                <span>Location</span>
                                                <HeightIcon className="myIcon" fontSize="small" />
                                            </div>
                                        }
                                        type="number"
                                        className={classes.textField}
                                        value={this.props.lockCaseLocation}
                                        onChange={this.props.handleChange('lockCaseLocation')}
                                        margin="normal"
                                    />

                                    <TextField
                                        id="lockCaseLocationHorizontal"
                                        label={
                                            <div className={classes.label} style={{ display: "flex" }}>
                                                <span>Location</span>
                                                <SwapHorizIcon className="myIcon" fontSize="small" />
                                            </div>
                                        }
                                        type="number"
                                        className={classes.textField}
                                        value={this.props.lockCaseLocationHorizontal}
                                        onChange={this.props.handleChange('lockCaseLocationHorizontal')}
                                        margin="normal"
                                    />

                                </div>

                            </div>

                        </Paper>
                    }



                    <Paper elevation={0} className={classes.paper} style={{ display: "flex", flexWrap: "wrap" }}>
                        <h5 style={{ flex: '2 1 100%', textAlign: "left" }}>Handle</h5>

                        {(this.props.mainDoorIronmongery.type === "typeB" ||
                            this.props.mainDoorIronmongery.type === "typeA")
                            &&

                            <TextField
                                id="height"
                                label='height'
                                type="number"
                                className={classes.textFields4}
                                value={this.props.mainDoorIronmongery.height}
                                onChange={this.props.handleChange('mainDoorIronmongery', 'height')}
                                margin="normal"
                            />
                        }

                        {this.props.mainDoorIronmongery.type === "handle" &&
                            <div style={{ display: 'flex' }}>

                                <TextField
                                    id="grip"
                                    label='grip'
                                    type="number"
                                    className={classes.textFields4}
                                    value={this.props.mainDoorIronmongery.grip}
                                    onChange={this.props.handleChange('mainDoorIronmongery', 'grip')}
                                    margin="normal"
                                />

                                <TextField
                                    id="spindle"
                                    label='Spindle'
                                    type="number"
                                    className={classes.textFields4}
                                    value={this.props.mainDoorIronmongery.spindle}
                                    onChange={this.props.handleChange('mainDoorIronmongery', 'spindle')}
                                    margin="normal"
                                />

                                <TextField
                                    id="rosetDepth"
                                    label='Rosete Depth'
                                    type="number"
                                    className={classes.textFields4}
                                    value={this.props.mainDoorIronmongery.rosetDepth}
                                    onChange={this.props.handleChange('mainDoorIronmongery', 'rosetDepth')}
                                    margin="normal"
                                />

                            </div>
                        }

                        {(this.props.mainDoorIronmongery.type === "typeB" ||
                            this.props.mainDoorIronmongery.type === "typeA" ||
                            this.props.mainDoorIronmongery.type === "typeC" ||
                            this.props.mainDoorIronmongery.type === "typeD" ||
                            this.props.mainDoorIronmongery.type === "handle") &&


                            <TextField
                                id="handleLocation"
                                //label='Location'
                                type="number"
                                className={classes.textFields4}
                                value={this.props.mainDoorIronmongery.handleLocation}
                                onChange={this.props.handleChange('mainDoorIronmongery', 'handleLocation')}
                                margin="normal"
                                label={

                                    <div className={classes.label} style={{ display: "flex" }}>
                                        <span>Location</span>
                                        <HeightIcon className="myIcon" fontSize="small" />
                                    </div>

                                }
                            />


                        }

                        {(this.props.mainDoorIronmongery.type === "typeB" ||
                       
                            this.props.mainDoorIronmongery.type === "handle") &&

                            <TextField
                                id="handleHorizontalLocation"
                                //label='Location'
                                type="number"
                                className={classes.textFields4}
                                value={this.props.mainDoorIronmongery.handleLocationHorizontal}
                                onChange={this.props.handleChange('mainDoorIronmongery', 'handleLocationHorizontal')}
                                margin="normal"
                                label={

                                    <div className={classes.label} style={{ display: "flex" }}>
                                        <span>Location</span>
                                        <SwapHorizIcon className="myIcon" fontSize="small" />
                                    </div>

                                }
                            />
                        }

                        {( this.props.mainDoorIronmongery.type === "handle" ||
                            this.props.mainDoorIronmongery.type === "typeB" ) &&

                            <TextField
                                id="handleColor"
                                select
                                label="Color"
                                style={{ flexBasis: "100%" }}
                                value={this.props.mainDoorIronmongery.color}
                                onChange={this.props.handleChange('mainDoorIronmongery', 'color')}
                                SelectProps={{
                                    native: true,
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}
                                helperText=""
                                margin="normal"
                            >
                                <option key="1" value="sameAsFrame">Same As Frame</option>
                                <option key="2" value="#E1C16E">Gold</option>
                                <option key="3" value="#b5a642">Bras</option>
                                <option key="4" value="#B87333">Copper</option>
                                <option key="5" value="#777777">Steel</option>
                            </TextField>

                        }
                    </Paper>

                    <Paper elevation={0} className={classes.paper} >



                        <h4>Hinges</h4>

                        <TextField
                            id="hinges"
                            select
                            label="Hinges"
                            style={{ flex: "2 1 100%" }}
                            value={this.props.hinges}
                            onChange={this.props.handleChange('hinges')}
                            SelectProps={{
                                native: true,
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            helperText=""
                            margin="normal"
                        >
                            <option key="0" value="0">0</option>
                            <option key="2" value="2">2</option>
                            <option key="3" value="3">3</option>
                            <option key="4" value="4">4</option>
                        </TextField>
                    </Paper>





                </div> : <> {this.state.spinner ? <CircularProgress /> : <span></span>}  </>
            )}</userContext.Consumer>)
    }
}

export default withStyles(styles)(ConfigIronmongery)