import {
        BoxGeometry,
        MeshStandardMaterial,
        Mesh,
        }  from 'three';

const createBox = (w,h,z,hex,bfId=0, metalnessVal=0, roughnessVal=0) =>{

        // console.log(w,h,z,hex,bfId, metalnessVal, roughnessVal )

        const geometry = new BoxGeometry(w,h,z)
        const material = new MeshStandardMaterial({ color: hex , metalness: metalnessVal, roughness: roughnessVal, })

 

        const mesh = new Mesh(geometry, material)
        mesh.bfId = bfId  

        return(mesh)
}

export default createBox