import React, { Component } from 'react';

class Knob extends Component{

    state= {}

    render() {

        
        return (
            <div style={{height: `${80/this.props.printRatio}px`, width: `${80/this.props.printRatio}px`, border: '1px solid black', position: 'absolute', margin: '0 auto', 'borderRadius': '50%', [this.props.handleSide] : `${40/this.props.printRatio}px`,bottom: `${this.props.bottom/this.props.printRatio}px`,}}>
                <div style={{ height: `50%`,  width: `50%`, border: '1px solid black', margin: '50% auto', borderRadius: '50%',transform: 'translate(0,-50%)'}}></div>
            </div>  
       )
    }
}

export default Knob;