import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router';
import Composer from '../SteelFramed/composer'
import { userContext } from '../Context/userContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { getDatabase, ref, query, onValue, update, get, push, set } from "firebase/database";
import Paper from '@material-ui/core/Paper';
import PriceItems from '../SteelFramed/components/priceItems';

import Button from '@material-ui/core/Button';
import { getWeekYearWithOptions } from 'date-fns/fp';




const uniqid = require('uniqid');

const styles = theme => ({

    textField: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: 200,

        ['@media (max-width:600px)']: {
            display: 'inline-flex',
            width: '90%',
            margin: '10px 5%'
        }
    },

});



class SteelFramedAdmin extends Component {

    state = {

        groups: [{ name: "DoorType", items: [] }, { name: "Ironmongery", items: [] }, { name: "Finish", items: [] }, { name: "Glass", items: [] }],

        name: 'item',
        price: 0,
        fixedPrice: 0,
        assignTo: "Ironmongery",
        description: "description ...",
        Ironmongery: [],
        Colour: [],
        minArea: 0

    }

    componentDidMount() {

        const db = getDatabase();
        const recentPostsRef = query(ref(db, 'Crittall'));

        onValue(recentPostsRef, (snapshot) => {
            const data = snapshot.val();

            if (data !== null) {

                let crittal = Object.entries(data);
                let index;

                let groups = this.state.groups





                console.log(crittal, groups)

                groups.map(group => {


                    index = crittal.findIndex(section => section[0] === group.name);

                    console.log(index);

                    group.items = index !== -1 ? Object.entries(crittal[index][1]) : []


                })





                index = crittal.findIndex(section => section[0] === 'Ironmongery')






                let Ironmongery = index !== -1 ? Object.entries(crittal[index][1]) : []





                index = crittal.findIndex(section => section[0] === 'Colour')


                let Colour = index !== -1 ? Object.entries(crittal[index][1]) : []


                console.log(Ironmongery, Colour)

                this.setState({ groups })
            }
        });
    }

    handleUpdate = name => event => {
        this.setState({
            [name]: event.target.value
        })
    }

    addItem = () => {
        const db = getDatabase();
        const postListRef = ref(db, 'Crittall/' + this.state.assignTo);
        const newPostRef = push(postListRef);

        let toSave;

        if (this.state.assignTo === "DoorType") {
            toSave = {
                name: this.state.name,
                price: this.state.price,
                description: this.state.description,
                fixedPrice: this.state.fixedPrice,
                minArea: this.state.minArea
            }

        } else {

            toSave = {
                name: this.state.name,
                price: this.state.price,
                description: this.state.description
            }
        }

        console.log(toSave)




        set(newPostRef, toSave);

    }

    render() {
        const { classes } = this.props;


        if (this.props.loggedIn === true) {
            return (

                <userContext.Consumer>
                    {(user) => (user.access >= 1 ? <>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'baseline', margin: '2rem' }}>
                                <TextField
                                    id="group"
                                    select
                                    label="Asign to"
                                    style={{ width: '150px' }}
                                    className={classes.textField}
                                    value={this.state.assignTo}
                                    onChange={this.handleUpdate("assignTo")}
                                    SelectProps={{
                                        native: true,
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    helperText=""
                                    margin="normal"
                                >

                                    {this.state.groups.map(group => {
                                        return (
                                            <option key={group.name} value={group.name}>{group.name}</option>
                                        )
                                    })}

                                </TextField>


                                <TextField
                                    style={{ width: '200px' }}
                                    className={classes.textField}
                                    type="text"
                                    id="Name"
                                    name="name"
                                    label="Item Name"
                                    value={this.state.name}
                                    onChange={this.handleUpdate("name")}
                                />

                                <TextField
                                    style={{ width: '80px' }}
                                    className={classes.textField}
                                    type="number"
                                    id="price"
                                    name="prace"
                                    label="Price"
                                    value={this.state.price}
                                    onChange={this.handleUpdate("price")}
                                />

                                {this.state.assignTo === "DoorType" &&

                                    <TextField
                                        style={{ width: '80px' }}
                                        className={classes.textField}
                                        type="number"
                                        id="fixedPrice"
                                        name="fixedPrace"
                                        label="Fixed Price"
                                        value={this.state.fixedPrice}
                                        onChange={this.handleUpdate("fixedPrice")}
                                    />

                                }

                                {this.state.assignTo === "DoorType" &&

                                    <TextField
                                        style={{ width: '80px' }}
                                        className={classes.textField}
                                        type="number"
                                        id="minArea"
                                        name="minArea"
                                        label="Min Area"
                                        value={this.state.minArea}
                                        onChange={this.handleUpdate("minArea")}
                                    />

                                }

                                <TextField
                                    style={{ width: '320px' }}
                                    className={classes.textField}
                                    type="text"
                                    id="description"
                                    name="description"
                                    label="Description displayed on Quote"
                                    value={this.state.description}
                                    onChange={this.handleUpdate("description")}
                                />


                                <Button variant="outlined" color="primary" onClick={this.addItem} >Add new</Button>


                            </div>


                            {this.state.groups.map(group => {
                                return (
                                    <PriceItems priceItems={group.items} group={group.name} ></PriceItems>
                                )
                            })}






                        </div>
                    </> : <> {this.state.spinner ? <CircularProgress /> : <span>Contact administrator</span>}  </>
                    )}</userContext.Consumer>
            );
        } else {
            return <Redirect to='/' />
        }
    }
}

export default withStyles(styles)(SteelFramedAdmin);