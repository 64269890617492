import { Translate } from '@material-ui/icons';
import React, { Component } from 'react';

class LouverVent extends Component{
    state= {
        vetGrid:  []
    }
    componentDidUpdate(prevProps){

        if(prevProps !== this.props){

            let ventGrid = [];
            let nm =  (this.props.height/10)/this.props.printRatio;

            for(let i = 1 ; i < nm ; i++){
                ventGrid[i] = 1;
            }

            this.setState({
                vetGrid : ventGrid
            })
        }
    }
    render() {
        return (
            <div >
                <div style={{
                    height: `${this.props.height/this.props.printRatio}px`,  
                    width: `${this.props.width/this.props.printRatio}px`, 
                    border: '1px solid #000', position: 'absolute', bottom: `${this.props.bottom}px`,left: '50%', transform: 'translate(-50%)',padding: '4px', boxSizing: 'border-box'}}
                 >

                    <div style={{ border: '1px solid #000', width: '100%', height: '100%', boxSizing: 'border-box', overflow: 'hidden'}}>

                            { this.state.vetGrid.map((vent, index) =>
                                < div key={index} style={{height: `10px`,width: '100%', borderBottom: '1px solid #000', boxSizing: 'border-box'}} ></div>
                            )}    
                    </div>
                </div> 
            </div>  
       )
    }
}
export default LouverVent;