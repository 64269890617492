import React, { Component } from 'react';
import { Redirect } from 'react-router'
// import * as firebase from 'firebase'
import firebase from "firebase/compat/app";
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress'



import Orders from '../orders/Orders';
import AddOrder from '../orders/AddOrder';


import { Paper, Container, Input } from '@material-ui/core';
import { userContext } from '../Context/userContext';
import SearchIcon from '@material-ui/icons/Search';

import { GetCustomerNameForThisOrder, TotalValue, TotalIncome, TotalVat, TotalIncomeVat } from '../logic/GetData';


// import Timeline from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css'
import moment from 'moment';



const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    paper: {
        overflowX: 'scroll',
        margin: '2%',
    },

});




class ClosedOrders extends Component {

    state = {
        ordersClosed: [],
        activeOrders: [],
        timelineOrders: [],
        orderDetails: [],
        searchItems: [],
        spinner: true
    }


    filterList = name => event => {
        let updatedList = this.state.ordersClosed;

        updatedList = updatedList.reverse().filter(function (item) {
            if (item[1][name] != null) {
                return item[1][name].toLowerCase().search(event.target.value.toLowerCase()) !== -1;
            }
        });
        this.setState({ searchItems: updatedList });
    }


    loadSearchItems() {
        this.setState({ searchItems: this.state.ordersClosed })
    }


    componentDidMount() {
        // set refference to firebase 
        const ordersRef = firebase.database().ref('Orders');
        let orders

        // 1 get all customers as an array
        // 2 et all orders as an array
        // resolve 2 promises : https://stackoverflow.com/questions/57739177/firebase-realtime-database-wait-for-multiple-queries-to-finish
        // set them as state
        // 3 get customer nam by id in JSX from cutomers array
        const custRef = firebase.database().ref('Customers');
        //Dont know why this works if it will stop do what I have planned above 
        custRef.on('value', snap => { })



        //load all orderes
        ordersRef.on('value', snap => {

            orders = snap.val()

            let orderDetails = []
            let activeOrders = [];
            let ordersClosed = [];

            //if there are orders in db 
            if (orders !== null) {
                //change json to array
                let allorders = Object.entries(orders).reverse();

                for (const [i, v] of allorders.entries()) {

                    if (v[1].Archive != null) {

                        let archive = Object.values(v[1].Archive)
                        let index = archive.findIndex(file => file.name === "Invoice")
                        let index2 = archive.findIndex(file => file.name === "Factory down payment")
                        let index3 = archive.findIndex(file => file.name === "Final payment")

                        if (index != -1) { v[1].invoiceUploaded = true }
                        if (index2 != -1) { v[1].factoryDownPaymentUploaded = true }
                        if (index3 != -1) { v[1].FinalPayment = true }
                    }

                    if (v[1].ManualTasks != null) {
                        let manualTasks = Object.values(v[1].ManualTasks)

                        let index = manualTasks.findIndex(task => task.name === "Down payment confirmation")
                        if (index != -1) {

                            if (manualTasks[index].value === true) {
                                v[1].downpaymentConfirmation = true
                            }
                        }

                        let index2 = manualTasks.findIndex(task => task.name === "Factory down payment confirmation")

                        if (index2 != -1) {

                            if (manualTasks[index2].value === true) {

                                v[1].factorydownpaymentConfirmation = true
                            }
                        }

                    }

                    v[1].outstanding = (TotalValue(v[0]) + TotalVat(v[0])) - (TotalIncome(v[0]) + TotalIncomeVat(v[0]))

                    v[1].CustomerName = GetCustomerNameForThisOrder(v[1].Customer)


                    if (v[1].OrderClosed === false) {

                        activeOrders.push(v)

                    } else {
                        ordersClosed.push(v)
                    }
                }

                this.setState({
                    activeOrders,
                    ordersClosed: ordersClosed,
                    orderDetails: orderDetails,
                    spinner: false
                }, () => { this.loadSearchItems() })

            } else {

                this.setState({
                    activeOrders,
                    orderDetails: [],
                    ordersClosed,
                    spinner: false
                }, () => { this.loadSearchItems() })
            }
        })

    }

    render() {

        const { classes } = this.props;

        if (this.props.loggedIn === true) {
            return (
                <userContext.Consumer>
                    {(user) => (
                        user.access >= 5 ?

                            <Container maxWidth="xl">

                                <h2 style={{ padding: "20px" }}>Closed Orders</h2>

                                <div style={{ display: "flex" , gap: "2em", justifyContent: "center" }}>

                                    <SearchIcon style={{ fontSize: "1rem" }} />

                                    <Input
                                        type="text"
                                        className={classes.textField}
                                        placeholder="Order Number"
                                        onChange={this.filterList('OrderNumber')}>
                                    </Input>

                                    <Input
                                        type="text"
                                        className={classes.textField}
                                        placeholder="Reference"
                                        onChange={this.filterList('Reference')}>
                                    </Input>

                                </div>


                                <Paper className={this.props.classes.paper} elevation={1}>
                                    {this.state.spinner && <CircularProgress color="primary" />}
                                    <Orders orders={this.state.searchItems} status="closed"></Orders>
                                </Paper>

                            </Container>
                            :
                            <div><h2>Access denied - Contact administrator</h2></div>
                    )}
                </userContext.Consumer>
            );
        } else {
            return <Redirect to='/' />
        }
    }
}
export default withStyles(styles)(ClosedOrders);
