import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
//import Image from '@material-ui/core/Image';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import EditManu from '../partials/EditMenu';
// import * as firebase from 'firebase'
import firebase from "firebase/compat/app";
import {userContext} from '../Context/userContext';

const styles = theme => ({
    paper:{
        margin: '2%',
        overflowX: 'scroll'
    },
  table: {
    minWidth: 700,
  },
});

class LabourTable extends Component{

    state = {
        archive: [],
    }

    

    downloadFile = (path)=>()=>{

        // Create a reference to the file we want to download
        const storage = firebase.storage();

        storage.ref(path).getDownloadURL().then((url) => { window.open(url , '_blank') })
    }
    componentDidMount(){
        // set refference to firebase 
        const ordersRef = firebase.database().ref(`Orders/${this.props.orderId}/Archive`);
        
        //load all orderes
        ordersRef.on('value',snap =>{
            
            let costsFB = snap.val();

            if(costsFB !== null){
                let output = Object.entries(costsFB).map(([key, value]) => ({key,value}));
                this.setState({ 
                    archive: output , 
                });
  
            }else{
                this.setState({
                    archive: []
                })
            }
        })
    }

    render() {
        return (
            <userContext.Consumer>
                    {(user) => ( user.access >= 1 &&
            
            <Paper className={this.props.classes.paper} elevation={0} variant="outlined">
            <Table className={this.props.classes.table}>
                <TableHead>
                <TableRow>
                    <TableCell> Name</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>File</TableCell>
                    <TableCell>Uploaded by</TableCell>
             
                   
                    { user.access >= 10 && <TableCell>Edit</TableCell> }
                   
                    <TableCell padding="none"></TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {this.state.archive.map((file)=>{ 
                        return (
                            <TableRow key={file.key}>
                               
                                <TableCell >{file.value.name}</TableCell>
                                <TableCell>{file.value.date}</TableCell>
                                <TableCell>
                                {<span style={{cursor: 'pointer', color: '#D80026'}}  onClick={this.downloadFile(file.value.imagePath)}>{file.value.imagePath.split("/").pop()}</span>}
                                    
                                    
                                    {/* {<a href={()=> this.downloadFile(file.value.imagePath) }>download</a>} */}
                                    
                                {/* {file.value.imagePath && < a href={file.value.image} 
                            
                                 download={file.value.imagePath.split("/").pop()}
                            
                                >{file.value.imagePath.split("/").pop()}</a>} */}
                                
                                </TableCell>
                               
                                <TableCell>{file.value.uploadedBy}</TableCell>
                                {user.access >= 10 && 
                                    <TableCell padding="none">
                                        <EditManu 
                                            mainGroup="Orders"
                                            parentType='Archive'
                                            orderId={this.props.orderId}
                                            recordKey={file.key}
                                            update={[
                                                { type:'text', id:'name', label: 'Name', value: file.value.name},
                                         
                                            ]}
                                        ></EditManu>
                                    </TableCell>
                                }
                                
                            </TableRow>
                        );
                    })}
                
                </TableBody>
            </Table>
            </Paper>
            )}</userContext.Consumer>
        );
    }
    }
LabourTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LabourTable);