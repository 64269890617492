import React, { Component } from 'react';

class DropDownHatch extends Component{

    state= {}

    render() {
        
        return (
            <div>
                <div style={{height: `${300/this.props.printRatio}px`,  width: `${300/this.props.printRatio}px`, border: '1px solid #000', position: 'absolute',bottom: `${this.props.bottom/this.props.printRatio}px`,left: '50%', transform: 'translate(-50%)',padding: '4px'}}>
                    <div style={{ border: '1px solid #000', width: '100%', height: '100%', boxSizing: 'border-box'}}></div>
                        <div style={{ height: `${15/this.props.printRatio}px`,  width: `${80/this.props.printRatio}px`, border: '1px solid #000', position: 'absolute', margin: '0 auto', bottom: `${-30/this.props.printRatio}px`,backgroundColor: 'white', left:'20%'}}>
                            <div style={{ height: '100%',  width: '50%', borderRight: '1px solid #000'}}></div>
                        </div>
                        <div style={{ height: `${15/this.props.printRatio}px`,  width: `${80/this.props.printRatio}px`, border: '1px solid #000', position: 'absolute', margin: '0 auto', bottom: `${-30/this.props.printRatio}px`,backgroundColor: 'white', right:'20%'}}>
                            <div style={{ height: '100%',  width: '50%', borderRight: '1px solid #000'}}></div>
                        </div>
                        
                </div>  
            </div>  
       )
    }
}

export default DropDownHatch;