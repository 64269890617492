import React, { Component } from 'react';

class SecuritySeal extends Component{

    state= {}

    render() {

        return (   
            <div style={{ height: `${50/this.props.printRatio}px`,  width: `${50/this.props.printRatio}px`, border: '1px solid black', position: 'absolute',margin: '0 auto', 'borderRadius': '50%',[this.props.side]: `${50/this.props.printRatio}px`,bottom: `${this.props.bottom/this.props.printRatio}px`,}}>
                    <div style={{ height: `1px`,  width: `${120/this.props.printRatio}px`, borderBottom: '2px solid black',margin: '50% auto',transform: 'translate(0,-50%)', [this.props.side2]: `0px`, position: 'absolute'}}></div>
            </div>
       )
    }
}

export default SecuritySeal;


