import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

// import * as firebase from 'firebase'
import firebase from "firebase/compat/app";

import DeleteIcon from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';




class EditMenu extends Component {
  state = {
    anchorEl: null,
    openDelete: false,
    openEdit: false,
    update: this.props.update
  };

  openDialog = name => event => {
    this.setState({
      [name]: true
    })
  }

  deleteRecorConfirmed = () => {
    if (this.props.mainGroup === "FileTypes"
      || this.props.mainGroup === "Users"
      || this.props.mainGroup === "Customers"
      || this.props.mainGroup === "Costs") {
      const recordRef = firebase.database().ref(`${this.props.mainGroup}/${this.props.recordKey}`);
      recordRef.remove();
    }
    else if (this.props.mainGroup === "Crittall") {


      const recordRef = firebase.database().ref(`${this.props.mainGroup}/${this.props.parentType}/${this.props.orderId}`);
      recordRef.remove();
    }
    else {
      // console.log(this.props.mainGroup);
      // console.log(this.props.orderId);
      // console.log(this.props.parentType);
      // console.log(this.props.recordKey);

      const recordRef = firebase.database().ref(`${this.props.mainGroup}/${this.props.orderId}/${this.props.parentType}/${this.props.recordKey}`);

      // console.log(recordRef)

      recordRef.remove();
    }
  }
  saveRecord = () => {
    let recordRef;


    if (this.props.mainGroup === "FileTypes"
      || this.props.mainGroup === "Users"
      || this.props.mainGroup === "Costs"
    ) {

      recordRef = firebase.database().ref(`${this.props.mainGroup}/${this.props.recordKey}`);

    }
    else if (this.props.mainGroup === "Crittall") {


      recordRef = firebase.database().ref(`${this.props.mainGroup}/${this.props.parentType}/${this.props.orderId}`);
    }
    else {
      recordRef = firebase.database().ref(`${this.props.mainGroup}/${this.props.orderId}/${this.props.parentType}/${this.props.recordKey}`);
    }

    let update = {}



    this.state.update.forEach(element => {

      if (element.value === undefined) {

        if (element.type === "select") {
          update[element.id] = element.options[0].value
        }

      } else {
        update[element.id] = element.value
      }

    });

    recordRef.update(update);

    this.handleClose();

  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, openDelete: false, openEdit: false });
  };

  fieldUpdate = name => event => {

    console.log(event.target.value);

    let fl = el => {
      return el.id === name;
    }

    //Find index of object that change value
    let idref = this.state.update.findIndex(fl);

    // Make copy of update 
    let updaten = Object.assign([], this.state.update);


    updaten[idref].value = event.target.value;

    this.setState({
      update: updaten
    })
  }

  render() {


    return (
      <div>
        <IconButton
          aria-label="More"
          aria-owns={this.state.anchorEl ? 'long-menu' : null}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleClose}
          PaperProps={{
            style: {
              maxHeight: 80,
              width: 100,
            },
          }}
        >

          <IconButton aria-label="Delete" onClick={this.openDialog('openDelete')}>
            <DeleteIcon />
          </IconButton>
          <IconButton aria-label="Delete" onClick={this.openDialog('openEdit')}>
            <Edit />
          </IconButton>
        </Menu>





        <Dialog
          open={this.state.openDelete}
          onClose={this.handleClose}
          aria-describedby="alert-dialog-description"
        >

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you realy want to delete that item?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
            <Button onClick={this.deleteRecorConfirmed} color="primary" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>



        <Dialog
          open={this.state.openEdit}
          onClose={this.handleClose}
          aria-describedby="alert-dialog-description"
        >

          <DialogContent style={{
            background: "#e9e9e9",
            display: "flex",
            gap: '2em',
            flexWrap: 'wrap'
          }}>


            {/* {JSON.stringify(this.props.update)} */}

            {this.state.update.map((field) => {



              return (
                <span key={field.id}>
                  {field.type === 'select' ?

                    <TextField
                      id={field.id}
                      select
                      label={field.label}
                      // className={classes.textField}
                      value={field.value}
                      //defaultValue={field.value}
                      onChange={this.fieldUpdate(field.id, this.value)}

                      SelectProps={{
                        native: true,
                        MenuProps: {
                          //className: classes.menu,
                        },
                      }}
                      helperText=""

                    >
                      {field.options.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>

                    : field.type === 'date' ?
                      <TextField
                        id={field.id}
                        label={field.label}
                        type={field.type}
                        defaultValue={field.value}
                        onChange={this.fieldUpdate(field.id, this.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />

                      : <TextField
                        margin="dense"
                        id={field.id}
                        label={field.label}
                        type={field.type}
                        value={field.value}
                        onChange={this.fieldUpdate(field.id, this.value)}
                      />}

                </span>
              );
            })}


          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="primary" onClick={this.handleClose} >
              Close
            </Button>
            <Button variant="outlined" color="primary" onClick={this.saveRecord} autoFocus>
              Save
            </Button>
          </DialogActions>
        </Dialog>


      </div>
    );
  }
}

export default EditMenu;