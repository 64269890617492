import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import * as firebase from 'firebase';
import firebase from "firebase/compat/app";
import TextField from '@material-ui/core/TextField';


const styles = theme => ({
    inputField: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: 200,
  
      ['@media (max-width:600px)']: { 
          display: 'inline-flex',
          width: '90%',
          margin: '10px 5%'
        }
      },
 

});



class SelectCustomer extends Component{
    
    state = {
        customers: []
    }


    componentDidMount(){
        const ordersRef = firebase.database().ref('Customers');
       

        ordersRef.once('value').then((snap) =>{

            let result = snap.val();

            if(result !== null){

                let customers = [];

                for (var value of Object.entries(result)) {

                    customers.push({id: value[0], name: value[1].companyName})
                }

                this.setState({
                    customers
                })

            }

        })
    }

    render() {

        return (
            <Autocomplete
                id="customer"
                options={this.state.customers}
                getOptionLabel={(option) => option.name}
                className={this.props.classes.inputField}
                renderInput={(params) => <TextField {...params} label="Customer" variant="outlined" />}
                onChange={this.props.autocompleteUpdate}
                style={{ width: 250, display: 'inline-flex' }}
            
            />
        );
        
    }
  }
export default withStyles(styles)(SelectCustomer);
