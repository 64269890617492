import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router';
import { userContext } from '../Context/userContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import CalcStepper from '../partials/Stepper';
import Grid from '@material-ui/core/Grid';
import StepperNav from '../partials/StepperNav';
import Quote from '../SteelFramed/quote_new';
import SelectCustomer from '../Customers/selectCustomer';
import CustomerDetails from '../Customers/CustomerDetails';

import { optionReset } from '../SteelFramed/components/quoteComponents/optionsReset'

import { getDatabase, ref, query, onValue, update, get, push, set } from "firebase/database";



const uniqid = require('uniqid');

const styles = theme => ({

    paper: {
        padding: '2rem',
        margin: '2rem'

    },
    noPrint: {
        '@media print': {
            display: 'none'
        }
    }

});

class SteelFramedQuote extends Component {

    state = {
        steps: ['Customer Details', 'Review'],
        activeStep: 0,
        companyName: '',
        contactName: '',
        contactEmail: '',
        phoneNumber: '',
        firstAddressLine: '',
        secondAddressLine: '',
        city: '',
        county: '',
        country: '',
        refernceNumber: '',
        quoteDate: '',
        doors: [],
        prices: {},






        newImage: "",
        quoteId: '',

        redirect: false,
        itemToBeAdded: {},


        // availableIronmongery:[],
        // selectedForQuote: [],
        // availableGlass: [],
        // availableFinishes: [],
        // availableDoorTypes: [],

        additionalPriceItems: [{ key: "prelCost", text: "Preliminary costs", price: '0.00', vat: '23', qty: 1, discount: 0, subitems: [], globalDiscount: false, days: 15 }],

        quoteDate: moment().format('L')
    }


    componentDidMount() {

        if (this.props.match.params.quotetId === undefined) {
            this.addDoor()
        } else {
            console.log(this.props.match.params.quotetId)
        }

        let db = getDatabase();

        const recentPostsRef = query(ref(db, 'Crittall'));

        onValue(recentPostsRef, (snapshot) => {

            const data = snapshot.val();

            if (data !== null) {

                let prices = this.loadPrices(data)

                this.setState({
                    // selectedForQuote, availableIronmongery, availableGlass, availableFinishes, availableDoorTypes, templates
                    prices

                }
                    , this.loadDataIfThisIsQuoteEdit()

                )
            }
        });
    }

    handleItemChange = (values, index) => event => {

        let val

        if (event.target.checked) {
            val = event.target.checked
        } else {
            val = event.target.value
        }

        let doors = this.state.doors



        if (values[0] === "Ironmongery") {

            if (doors[index].Ironmongery === undefined) { doors[index].Ironmongery = [] }
            // checkif allready exist 

            let exist = doors[index].Ironmongery.findIndex(item => item === val)

            if (exist === -1) {

                doors[index].Ironmongery.push(val)

            } else {
                alert("This ironmongery item has allready been added")
            }

        } else if (values.length === 1) {

            doors[index][values[0]] = val

        } else if (values.length === 2) {

            doors[index][values[0]][values[1]] = val

        } else if (values.length === 3) {

            let findItem = doors[index][values[0]].findIndex(item => item.key === values[1])
            doors[index][values[0]][findItem][values[2]] = val

        }

        this.setState({ doors })
    }


    componentDidUpdate(prevProps, prevState) {


        // if (prevState.newImage !== this.state.newImage) {

        //     let selected = []
        //     let newImage = this.state.newImage

        //     let toBeRemoved = this.state.toBeRemoved 





        //     if (newImage !== "") {

        //         console.log(newImage, toBeRemoved)

        //         this.state.selectedForQuote.map(sel => {

        //             if(sel[0] !== toBeRemoved ){
        //                 selected.push(sel[0])
        //             }

        //         })




        //         selected.push(newImage)
        //         newImage =  ""




        //         console.log(selected)

        //         let db = getDatabase();
        //         const recentPostsRef = query(ref(db, 'Crittall'));

        //         onValue(recentPostsRef, (snapshot) => {

        //             const data = snapshot.val();

        //             if (data !== null) {

        //                 let doors = this.loadDoors(data, selected)


        //                 // console.log(doors)

        //                 this.setState({
        //                     // selectedForQuote, availableIronmongery, availableGlass, availableFinishes, availableDoorTypes, templates
        //                     ...doors, newImage
        //                 }, this.loadDataIfThisIsQuoteEdit()

        //                 )
        //             }
        //         });
        //     }
        // }
    }


    // handleUpdateDescryption = (group, index) => event => {

    //     let itemToBeAdded = this.state.itemToBeAdded

    //     itemToBeAdded = {
    //         group: group,
    //         doorIndex: index,
    //         value: event.target.value
    //     }

    //     this.setState({ itemToBeAdded })

    // }




    // addToDescription = () => event => {


    //     let itemToBeAdded = this.state.itemToBeAdded
    //     let selectedForQuote = this.state.selectedForQuote

    //     optionReset()

    //     if (selectedForQuote[itemToBeAdded.doorIndex][1][itemToBeAdded.group] === undefined) { selectedForQuote[itemToBeAdded.doorIndex][1][itemToBeAdded.group] = [] }

    //     selectedForQuote[itemToBeAdded.doorIndex][1][itemToBeAdded.group].push(itemToBeAdded.value)

    //     this.setState({
    //         selectedForQuote,
    //         itemToBeAdded: {}
    //     })

    // }




    /// New style quote creator (all above is old and need to be checked ad deleted if not needed)

    createOrderNumbeAndPushToSave = () => {

        const db = ref(getDatabase(), 'QuoteNo');

        // const dbRef = ref(db, 'QuoteNo' );


        let quoteNo;

        get(db).then((snapshot) => {

            if (snapshot.exists()) {

                quoteNo = Number(snapshot.val()) + 1;

                let updates = {}

                updates['QuoteNo'] = quoteNo;


                update(ref(getDatabase()), updates).then(() => {

                    quoteNo = this.props.userEmail.slice(0, 2) + '-' + moment().year() + '-' + quoteNo

                    this.setState({
                        quoteNo: quoteNo,
                        redirect: true
                    }, () => { this.handleSaveOrder() })
                }
                )
            } else {
                console.log("No data available");
            }
        }).catch((error) => {
            console.error(error);
        });
    }

    handleSaveOrder = () => {

        const db = getDatabase();
        const postListRef = ref(db, 'Quotes');
        const newPostRef = push(postListRef);

        console.log(this.state)
        // remove templates before from state before save

        // delete this.state.templates
        // console.log(this.state)


        set(newPostRef, {
            ...this.state,
            product: 'Steel Framed',
            // quoteNo: this.state.quoteNo,
            redirect: false
        });

        // this.handleNext();
    }

    handleRemoveDoorPriceItem = (doorIndex, itemIndex) => {

        let doors = this.state.doors
        doors[doorIndex].extraOver.splice(itemIndex, 1)

        this.setState({
            doors
        })

    }

    handleRemovePriceItem = id => event => {

        let remAdd = this.state.additionalPriceItems;
        let i = 0;

        remAdd.map(rm => {
            if (rm.key === id) {
                remAdd.splice(i, 1);
            }
            i++;
        })

        this.setState({
            additionalPriceItems: remAdd
        })
    }


    handleSlider = (values, index) => (event, newValue) => {

        let doors = this.state.doors

        if (values.length === 1) {
            doors[index][values[0]] = newValue
        } else if (values.length === 2) {
            doors[index][values[0]][values[1]] = newValue
        }

        this.setState({ doors })
    }

    handleglobalDiscount = (list, id) => event => {

        let additionalPriceItems = this.state[list];

        // console.log(additionalPriceItems)
        let index = additionalPriceItems.findIndex(x => x.key === id);
        let item = additionalPriceItems[index];

        // console.log(list, id);

        additionalPriceItems[index].globalDiscount = !additionalPriceItems[index].globalDiscount;

        this.setState({
            additionalPriceItems: additionalPriceItems
        })

    }

    handlePushItemDown = (list, id) => event => {

        let doors = this.state[list];
        let item = doors[id];

        doors.splice(id, 1);
        doors.splice(id + 1, 0, item);

        this.setState({
            [list]: doors
        })
    }

    handleDeleteItem = (list, id) => {

        let doors = this.state[list];
        doors.splice(id, 1);

        this.setState({
            [list]: doors
        })

    }

    handlePushItemUp = (list, id) => event => {

        console.log(list, id)

        let doors = this.state[list];

        let item = doors[id];

        doors.splice(id, 1);
        doors.splice(id - 1, 0, item);

        this.setState({
            [list]: doors
        })
    }

    loadPrices = (data) => {





        let availableIronmongery = []
        let availableGlass = []
        let availableFinishes = []
        let availableDoorTypes = []

        // console.log(data.Door_type)




        if (data.Ironmongery !== undefined) {
            availableIronmongery = Object.entries(data.Ironmongery);
        }

        if (data.Glass !== undefined) {
            availableGlass = Object.entries(data.Glass);
        }

        if (data.Finish !== undefined) {
            availableFinishes = Object.entries(data.Finish);
        }

        if (data.DoorType !== undefined) {
            availableDoorTypes = Object.entries(data.DoorType);
        }




        return ({ availableIronmongery, availableGlass, availableFinishes, availableDoorTypes })






        //// if sleceted for quote empty do what is above if not push new item



    }

    handleChange = (name) => event => {
        this.setState({
            [name]: event.target.value
        })
    }

    handelAdditionalPriceItem = (id, name, list) => event => {



        let li = this.state[list];
        let optionIndex = li.findIndex(x => x.key === id);
        li[optionIndex][name] = event.target.value;

        this.setState({
            [list]: li
        }
            // ,this.calculatePrice('handelAdditionalPriceItem')
        )
    }

    newDoor = () => {

        return {
            doorName: "D001",
            qty: '1',
            discount: '0',
            vat: '23',

            customDescription: "<p><strong>Note:</strong></p><p>Quotation for Supply, Delivery and Installation.</p><p>Removal of existing and off-site disposal by others - if necessary.</p><p>Make good door reveals by others - if necessary.</p><p>Painting and decorating by others - if necessary.</p>",
            smallDrawing: {
                wallFixing: "1",
                room1: 'Room 1',
                room2: 'Room 2'
            },


        }

    }

    addDoor = () => {

        let doors = this.state.doors
        let door = this.newDoor()
        doors.push(door)

        this.setState({ doors })
    }

    extraOver = (id) => {

        let doors = this.state.doors
        let uid = uniqid();
        if (doors[id].extraOver === undefined) { doors[id].extraOver = [] }

        doors[id].extraOver.push({ key: uid, text: "Installation", price: '0.00' })

        this.setState({
            doors: this.state.doors
        })

    }

    handelAddPriceItem = () => {

        let uid = uniqid();
        this.setState({
            additionalPriceItems: this.state.additionalPriceItems.concat({ key: uid, text: "Supply and Install", price: '0.00', vat: '23', qty: 1, discount: 0, subitems: [], globalDiscount: false, days: 15 })
        })

    }

    handleNext = () => {
        window.scrollTo(0, 0);
        const { activeStep } = this.state;
        this.setState({
            activeStep: activeStep + 1,
        });
    };

    handleBack = () => {
        window.scrollTo(0, 0);
        const { activeStep } = this.state;
        this.setState({
            activeStep: activeStep - 1,
        });
    };

    handleQuillText = (ob) => event => {

        let doors = this.state.doors

        doors[ob[1]][ob[0]] = event

        this.setState({ doors })
    }

    remFromDescription = (name, doorIndex) => {

        let doors = this.state.doors

        if (name[0] === "Ironmongery") {
            let index = doors[doorIndex][name[0]].findIndex(item => item === name[1])
            doors[doorIndex][name[0]].splice(index, 1)
        } else {
            delete doors[doorIndex][name[0]]
        }

        this.setState({
            doors
        })

    }

    createOrEditMainDrawing = (id, doorSetup) => {

        let doors = this.state.doors

        doors[id].mainDrawing = doorSetup

        this.setState({
            doors
        })

    }

    loadDataIfThisIsQuoteEdit = () => {

        let id = this.props.match.params.quotetId

        if (id !== "newQuote") {
            let db = getDatabase();

            const editedPost = query(ref(db, `Quotes/${this.props.match.params.quotetId}`));

            onValue(editedPost, (snapshot) => {
                const data = snapshot.val();

                if (data !== null) {



                    if (data.additionalPriceItems === undefined) { data.additionalPriceItems = [] }

                    // This removes prices of componnets carried with quote - Start

                    delete data.availableDoorTypes
                    delete data.availableFinishes
                    delete data.availableGlass
                    delete data.availableIronmongery

                    // This removes prices of componnets carried with quote - End

                    this.setState({
                        ...data
                    })
                }

            })
        }
    }

    autocompleteUpdate = (event, values) => {


        if (values != null) {

            const db = getDatabase();
            const starCountRef = ref(db, 'Customers/' + values.id);
            onValue(starCountRef, (snapshot) => {
                const data = snapshot.val();

                this.setState({
                    ...data,
                    Customer: values.id
                })

            });

        } else {
            this.setState({ name: '' })
        }
    }


    render() {
        const { classes } = this.props;
        if (this.state.redirect === true) {
            return <Redirect to={'/quotes'} />
        }

        return (<userContext.Consumer>
            {(user) => (user.access >= 5 ?
                <div style={{ flexDirection: 'column', display: 'flex', textAlign: 'left', alignItems: 'center', gap: '2em', overflow: 'hidden' }}>

                    <CalcStepper
                        //properties
                        activeStep={this.state.activeStep}
                        steps={this.state.steps}

                    > </CalcStepper>

                    <StepperNav
                        //actions
                        handleNext={this.handleNext}
                        handleBack={this.handleBack}
                        createOrderNumbeAndPushToSave={this.createOrderNumbeAndPushToSave}
                        //properties
                        activeStep={this.state.activeStep}
                        steps={this.state.steps}
                    ></StepperNav>

                    {this.state.activeStep === 0 &&
                        <Paper className={classes.paper} style={{ minWidth: '320px', width: '40vw', maxWidth: '800px' }} >
                            <SelectCustomer autocompleteUpdate={this.autocompleteUpdate}></SelectCustomer>


                            <CustomerDetails
                                companyName={this.state.companyName}
                                contactName={this.state.contactName}
                                contactEmail={this.state.contactEmail}
                                phoneNumber={this.state.phoneNumber}
                                firstAddressLine={this.state.firstAddressLine}
                                secondAddressLine={this.state.secondAddressLinesecondAddressLine}
                                city={this.state.city}
                                county={this.state.county}
                                country={this.state.country}
                                refernceNumber={this.state.refernceNumber}
                                quoteId={this.state.quoteID}
                                quoteDate={this.state.quoteDate}
                                //actions
                                handleChange={this.handleChange}
                            ></CustomerDetails>
                        </Paper>
                    }

                    {this.state.activeStep === 1 &&

                        <Quote
                            extraOver={this.extraOver}
                            handelAddPriceItem={this.handelAddPriceItem}
                            handleglobalDiscount={this.handleglobalDiscount}
                            handelAdditionalPriceItem={this.handelAdditionalPriceItem}
                            // handleChange={this.handleChange}
                            handleSlider={this.handleSlider}
                            handleQuillText={this.handleQuillText}
                            // handleUpdateDescryption={this.handleUpdateDescryption}
                            // addToDescription={this.addToDescription}
                            remFromDescription={this.remFromDescription}
                            handleItemChange={this.handleItemChange}
                            handleRemovePriceItem={this.handleRemovePriceItem}
                            handleRemoveDoorPriceItem={this.handleRemoveDoorPriceItem}
                            optionReset={this.optionReset}


                            // ????? - do I need that -- Start 
                            // editMainImage={this.editMainImage}
                            // ????? - do I need that -- End


                            {...this.state}
                            handlePushItemUp={this.handlePushItemUp}
                            handlePushItemDown={this.handlePushItemDown}
                            handleDeleteItem={this.handleDeleteItem}
                            addDoor={this.addDoor}
                            createOrEditMainDrawing={this.createOrEditMainDrawing}
                        ></Quote>
                    }

                </div> : <> {this.state.spinner ? <CircularProgress /> : <span></span>}  </>
            )
            }</userContext.Consumer>)
    }

}

export default withStyles(styles)(SteelFramedQuote);