import React, { Component } from 'react';

class Knob extends Component{

    state= {}

    rotate = () =>{
        if(this.props.side === "right"){
            return (0);
        }else{
            return (180);
        }
    } 

    render() {
        
        return (
            <div style={{height: `${460/this.props.printRatio}px`, width: `${120/this.props.printRatio}px`, border: '1px solid black', position: 'absolute', margin: '0 auto', 'borderRadius': '5px', [this.props.side] : `${this.props.sideDist/this.props.printRatio}px`, bottom: `${this.props.bottom/this.props.printRatio}px`,borderTopRightRadius: '0px', borderBottomRightRadius: '0px',borderRight: 'none',transform: `rotate(${this.rotate()}deg)`}}>
                  <div style={{height: `${400/this.props.printRatio}px`, width: `${95/this.props.printRatio}px`, border: '1px solid black', position: 'absolute','borderRadius': '3px',right:'-1px', top: `${25/this.props.printRatio}px`, borderRight : 'none', borderTopRightRadius: '0px', borderBottomRightRadius: '0px'}}></div>
                  <div style={{width: `${25/this.props.printRatio}px`, height: `${25/this.props.printRatio}px`, top: '-1px',right:`${-25/this.props.printRatio}px`, border: '1px solid black', borderLeft: 'none',position: 'absolute', borderBottomRightRadius: `${25/this.props.printRatio}px`,  borderTopRightRadius: `${25/this.props.printRatio}px`}}  ></div>
                  <div style={{width: `${25/this.props.printRatio}px`, height: `${25/this.props.printRatio}px`, bottom: '-1px',right:`${-25/this.props.printRatio}px`, border: '1px solid black',borderLeft: 'none', position: 'absolute', borderBottomRightRadius: `${25/this.props.printRatio}px`,  borderTopRightRadius: `${25/this.props.printRatio}px`}}  ></div>
            </div>  
       )
    }
}

export default Knob;