import React, { Component } from 'react';
import { Redirect } from 'react-router'
// import * as firebase from 'firebase';
import firebase from "firebase/compat/app";
import { withStyles } from '@material-ui/core/styles';
import SteelDoorsPaperwork from "../partials/SteelDoorsPaperwork"

const styles = theme => ({});

class Paperwork extends Component{
    
    state = {
        project : '',
        product:'',
    }

    componentDidMount(){

        // set refference to firebase 
        const quoteRef = firebase.database().ref(`Orders/${this.props.match.params.projectId}`);

        let order ='';

        quoteRef.on('value',snap =>{order = snap.val();})

        if(order !== ''){ this.setState({ ...order})}
    }
    
    render() {
       
        if(this.state.redirect === true){
            return <Redirect to='/'/>
        }

        const { classes, theme } = this.props;

     {console.log(this.state.product)}

        if(this.props.loggedIn === true){
          
            if(this.state.product === "Steel Door"){
                return (  <SteelDoorsPaperwork order={this.props.match.params.projectId}></SteelDoorsPaperwork>  );
            }else{
                return (  <p>Something wrong Contact Bart</p>  );
            }
            
        }else{
            return <Redirect to='/'/>
        }
    }
}
 
export default withStyles(styles)(Paperwork);