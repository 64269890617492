import React, { Component } from 'react';

class SpyHole extends Component{

    state= {}

    render() {
        return (
            <div>
             
                <div style={{ height: `6px`,  width: `6px`, border: '1px solid #c5c3c3', position: 'absolute', bottom: `${this.props.bottom/this.props.printRatio}px`,left: '50%', transform: 'translate(-50%)', borderRadius:'50%'}}>
                    <div style={{ height: '0px',  width: '200%', borderBottom: '1px solid #c5c3c3', position: 'absolute',top: '50%',transform: 'translate(-25%,-50%)'}}></div>
                    <div style={{ height: '200%',  width: '0%', borderLeft: '1px solid #c5c3c3', position: 'absolute',left: '50%',transform: 'translate(-50%, -25%)'}}></div>   
                </div>  
            </div>  
       )
    }
}

export default SpyHole;