import React, { Component } from 'react';
// import * as firebase from 'firebase';
import firebase from "firebase/compat/app";
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Input from '@material-ui/core/Input';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

const uniqid = require('uniqid');


const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: '90%',
        margin: '50px 5%',
      },
      textField: {
          marginLeft: theme.spacing(1),
          marginRight: theme.spacing(1),
          width: 200,
        },
      paper: {
          padding: '20px',
      },
      colorSwatch:{
          maxWidth: '17%',
          margin: '1%',
          border: '1px solid #efefef'
      },
      customFileUpload: {
            display: 'none'
            
        }
    
});


class steelDoorsPaperwork extends Component{
    
    state = {
        project : '',
        attachments: [],
        open: false, 
    }

    componentDidMount(){

        // set refference to firebase 
        const quoteRef = firebase.database().ref(`Orders/${this.props.order}`);

        let order ='';

        quoteRef.on('value',snap =>{ order = snap.val();})

        if(order !== ''){ this.setState({ ...order})}
    }

    confirm = (id ,file) => event =>{
        this.setState({
            open: true,
            toBeDeletedId: id,
            toBeDeletedFile: file
        })
    }

    handelAdditional = () =>{
        
        let uid = uniqid();

        this.setState({
            attachments: this.state.attachments.concat({key: uid, image: ''})
        })
    }

    updateDocs = () =>{
        const quoteRef = firebase.database().ref(`Orders/${this.props.order}`).update({attachments:this.state.attachments});
    }

    handleAdditionalImage = id  => event =>{
        
        
        //-------------------------------------------------------------------- create preview
       event.preventDefault();

       let uid = uniqid();

       //init reader
       let reader = new FileReader();
       let file = event.target.files[0];
       let fileName = `ArchiveSteelDoors/${this.props.order}/`+file.name 

       reader.onloadend = () => {

       let attachments = this.state.attachments;
       let optionIndex = attachments.findIndex(x => x.key === id);

       attachments[optionIndex].image = reader.result;
       attachments[optionIndex].imagePath = fileName ;

           this.setState({
            attachments: attachments,
           });
       }
       reader.readAsDataURL(file);


       //-------------------------------------------------------------------- upload image  to firebase
       let storageRef  = firebase.storage().ref(fileName)
       let uploadTask = storageRef.put(file)

       uploadTask.on('state_changed', function(snapshot){
           
           var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
           console.log('Upload is ' + progress + '% done');
           switch (snapshot.state) {
               case firebase.storage.TaskState.PAUSED: // or 'paused'
                   console.log('Upload is paused');
               break;
               case firebase.storage.TaskState.RUNNING: // or 'running'
                   console.log('Upload is running');
               break;
           }
           }, function(error) {
           // Handle unsuccessful uploads
           }, function() {
           // Handle successful uploads on complete
           this.updateDocs()
           }.bind(this));

   }

   handleRemoveAdditional = (id ,file)=> event =>{
        let remAdd = this.state.attachments;
        let i = 0;
        remAdd.map(rm=>{
            if(rm.key === id){
                remAdd.splice(i ,1);
            }
            i++;
        })
        

        // Create a reference to the file to delete
        let fileName = `ArchiveSteelDoors/${this.props.order}/`+file 

        var desertRef = firebase.storage().ref(fileName)

        // Delete the file
        desertRef.delete().then(function() {
        // File deleted successfully

        }).catch(function(error) {
        // Uh-oh, an error occurred!
        });

        this.setState({
            attachments :remAdd,
            open: false
        }, () =>{this.updateDocs()})
    }
    

    render() {
        const TDS = 'Technical Door Solutions Ltd';
        const { classes, theme } = this.props;
            return (
                <div className={classes.root}> 

                <Grid container justify="center" spacing={6}  className={classes.printMarginReset}>

                    <Grid item  lg={12}   md={12} className={classes.printMarginReset} >
          
                    <Grid container justify="center" spacing={6}  className={classes.printMarginReset}>

                        <Grid item  xs={12} className={classes.printMarginReset}>
                            Paperwork
                        </Grid>
                      
                  
                        <Grid item md={4} className={`${classes.printMarginReset} noPrint`}> 
                            <Paper className={`${classes.paper} noPrint`}  elevation={1}>
                                <h2>Attachnments</h2>
                                <Button variant="outlined" color="primary" aria-label="add" className={classes.btn} onClick={this.handelAdditional}>
                                    <AddIcon />
                                </Button>
                               
                                

                                {
                                    this.state.attachments.map(option => (
                                        <div key={option.key}>
                                        

                                                <label>
                                                    <Input 
                                                        type="file" 
                                                        className={classes.customFileUpload}
                                                        onChange={this.handleAdditionalImage(option.key).bind(this)}
                                                        />
                                                     {option.imagePath ? < a href={option.image} download={option.imagePath.split("/").pop()}>{option.imagePath.split("/").pop()}</a> : <span>Upload file</span>}
                                                </label>   

                                                <IconButton onClick={
                                                    this.confirm(option.key, option.imagePath && option.imagePath.split("/").pop())
                                                }>

                                            {/* <IconButton className={classes.delete} aria-label="Delete" onClick={this.handleRemoveAdditional(option.key, option.imagePath && option.imagePath.split("/").pop())}> */}
                                                <DeleteIcon />
                                            </IconButton>
                                            <hr/>
                                        </div>
                                    ))
                                } 
                            </Paper>
                        </Grid>
                     
                        
                        <Grid item md={8} className={classes.printMarginReset}> 
                            <Paper className={classes.paper}>
                                <ul>
                             { this.state.attachments.map(option => (
                                        <li key={option.key}>
                                      
                                          {option.imagePath && < a href={option.image} download={option.imagePath.split("/").pop()}>{option.imagePath.split("/").pop()}</a>}
                                            
                                                                           
                                        </li>
                                    ))
                                }
                                </ul>
                   
                            </Paper>
                        </Grid>
                          
                    </Grid>

                    </Grid>
                
                </Grid>


                  {/* Confirmation */}

                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    >
                    <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
                
                    <DialogActions>
                        <Button onClick={()=>this.setState({open:false})} variant="outlined" color="primary">
                            Cancel
                        </Button>
                        <Button variant="outlined" color="secondary" autoFocus onClick={this.handleRemoveAdditional(this.state.toBeDeletedId, this.state.toBeDeletedFile)}>
                            Delete   
                            <DeleteIcon className={this.props.classes.rightIcon} />
                        </Button>

                    </DialogActions>
                    </Dialog>


                </div>
            );
       
    }
}
 
export default withStyles(styles)(steelDoorsPaperwork);