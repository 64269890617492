import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router';
import moment from 'moment';
import MainComponent from '../Flexi/MainComponent';

const uniqid = require('uniqid');

const styles = theme => ({});

class FlexiQuote extends Component{
    
    state = {
        currencyRate: 1,
        saved: false,
        //stepper
        steps: ['Door Configuration','Customer Details','Summary'],
        activeStep: 0,
        skipped: new Set(),
        // Calculator

        additional:  [],
        priceItems: [{key: 'perlCost', text: 'Preliminary cost', price: '0.00',  vat: '23', qty: 0, discount: 0, subitems:[], thickLine : false}],
        totalPrice: 0,
        vat: "23",
        net: false,

        // Customer Details
        companyName: '',
        contactName: '',
        contactEmail: '',
        phoneNumber: '',
        firstAddressLine: '',
        secondAddressLine: '',
        city: '',
        county: '',
        country: 'Ireland',
        refernceNumber : '',
        quoteID: '',
        quoteDate: moment().format('L'),
        
    }

    componentDidMount(){} 

    render() {
        const { classes } = this.props;

        if(this.props.loggedIn === true ){
            return ( <MainComponent userEmail={this.props.userEmail} {...this.state} ></MainComponent>)
        }else{
            return <Redirect to='/'/>
        }
    }
}

export default withStyles(styles)(FlexiQuote);