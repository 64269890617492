import React, { Component } from 'react';
class Hatch extends Component{
    state= {}
    render() {
        return (
            <div>
                <div style={{height: `${300/this.props.printRatio}px`,  width: `${300/this.props.printRatio}px`, border: '1px solid #000', position: 'absolute', bottom: `${this.props.bottom/this.props.printRatio}px`,left: '50%', transform: 'translate(-50%)',padding: '4px'}}>
                    <div style={{ border: '1px solid #000', width: '100%', height: '100%', boxSizing: 'border-box'}}></div>
                    <div style={{ height: `${65/this.props.printRatio}px`,  width: `${15/this.props.printRatio}px`, border: '1px solid #000', position: 'absolute', margin: '0 auto', bottom: '20%',backgroundColor: 'white', left:'-5px'}}>
                        <div style={{ height: '50%',  width: '100%', borderBottom: '1px solid #000'}}></div>
                    </div>
                    <div style={{ height: `${65/this.props.printRatio}px`,  width: `${15/this.props.printRatio}px`, border: '1px solid #000', position: 'absolute', margin: '0 auto', top: '20%',backgroundColor: 'white', left:'-5px'}}>
                        <div style={{ height: '50%',  width: '100%', borderBottom: '1px solid #000'}}></div>
                    </div>
                    <div style={{width: '100%', height: `${50/this.props.printRatio}px`, bottom: `${-60/this.props.printRatio}px` , border: '1px solid #000', position: 'absolute', left: '-1px'}}></div>
                </div>  
            </div>  
       )
    }
}
export default Hatch;