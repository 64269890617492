import React, { Component } from 'react';

class Pushplate extends Component{
    render() {
        const { classes } = this.props;
        return (
            <div id="pushplate">
                <div style={{ height: `${300/this.props.printRatio}px`,  width: `${150/this.props.printRatio}px`, border: '1px solid #eee', position: 'absolute',margin: '0 auto',[this.props.handleSide]: `${this.props.sideDist/this.props.printRatio}px`,bottom: `${this.props.bottom/this.props.printRatio}px`}}>
                    <span style={{fontSize: '8px', color: '#eee', transform: 'rotateY(180deg)', display: 'block', margin: '10px 0 0 0'}}>PUSH</span>
                </div>
            </div>  
        )
    }
}

export default Pushplate;