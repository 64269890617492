import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Drawer, Button } from '@material-ui/core';
import Composer from '../../composer'

const styles = theme => ({});

class editDrawing extends Component {

    state = {
        panel: false
    }

    componentDidMount = () => {

        // this is to make possible to rewrite drawing in the next component insteasd of creating new one 


        // this.props.door[1].templateId =   this.props.door[0]



    }

    togglePanel = ()  => {
        this.setState({ panel: !this.state.panel })
    }

    render() {

        return (

            <>
                <Button onClick={()=>this.togglePanel()} variant='outlined'> Create/Edit Main Drawing </Button>

                <Drawer
                    variant="temporary"
                    anchor="bottom"
                    open={this.state.panel}
                    onClose={() => this.togglePanel()}
                >
           
                    <div style={{ height: '90vh', background: "#efefef", padding: "50px, 0", background: "#efefef", overflow: 'scroll' }}>
                        <Composer 
                             
                            
                            // editMainImage={this.props.editMainImage}

                            id={this.props.doorIndex} 
                            door={this.props.door}
                            doorIndex={this.props.doorIndex}
                            togglePanel={this.togglePanel}
                            createOrEditMainDrawing={this.props.createOrEditMainDrawing}
                        ></Composer>
                    </div>
                </Drawer>

            </>
        )

    }



}

export default withStyles(styles)(editDrawing)