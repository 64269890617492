import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';


import CPFrame from '../../../SteelDoor/Drawings/frame/CPFrame.svg';
import AFrame from '../../../SteelDoor/Drawings/frame/AFrame.svg';
import AFrame2 from '../../../SteelDoor/Drawings/frame/AFrame2.svg';



const styles = theme => ({
    bottom: {

    }
})

class Threshold extends Component {

    state = {}



    render() {

        const { classes } = this.props;

        return (
            <Grid container justifyContent="center" spacing={6}>

                <Grid item xs={12}>
                    <h6 style={{ marginBottom: '0px' }}>Frame</h6>
                    {this.props.frameType === 'type-2' ?

                        <img src={CPFrame} alt="bottomDrawing" className={classes.bottom} style={{ width: `${500 / this.props.printRatio}%`, height: 'auto' }} />

                        : <>
                            { this.props.waFrame === false ?
                                <img src={AFrame} alt="bottomDrawing" className={classes.bottom} style={{ width: `${500 / this.props.printRatio}%`, height: 'auto' }} />
                                :
                                <img src={AFrame2} alt="bottomDrawing" className={classes.bottom} style={{ width: `${500 / this.props.printRatio}%`, height: 'auto' }} />
                            }
                        </>
                    }


                </Grid>

            </Grid>
        )
    }
}

export default withStyles(styles)(Threshold);