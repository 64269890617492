import createBox from './createBox'


const createLockCase = (lc, lockCaseLocation, side ,doorWidth, color, id, door, profileWidth, metalness, roughness, lockCaseLocationHorizontal, doorHeight) =>{

    const lockCase = createBox(
        lc.x, 
        lc.y, 
        lc.z, 
        color,
        id,
        metalness,
        roughness
    )

    if(door === "main"){
        if(side === "left"){
            lockCase.position.x = -doorWidth/2 + lc.x/2 +  Number(profileWidth) - Number(lockCaseLocationHorizontal);
        }else{
            lockCase.position.x = doorWidth/2 - lc.x/2 -  Number(profileWidth) + Number(lockCaseLocationHorizontal);
        }
    }else{
        if(side === "left"){
            lockCase.position.x = doorWidth/2 - lc.x/2 -  Number(profileWidth) + Number(lockCaseLocationHorizontal);
        }else{
            lockCase.position.x = -doorWidth/2 + lc.x/2 +  Number(profileWidth) - Number(lockCaseLocationHorizontal);
        }

    }
   

    lockCase.position.y = (-doorHeight * 0.5) + Number(lockCaseLocation);

    return(lockCase)

}



export default createLockCase