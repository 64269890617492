import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'typeface-roboto';
import "@fontsource/comfortaa";
//import 'typeface-montesrrat';
import App from './App';
import { registerServiceWorker, unregister } from './registerServiceWorker';
// import * as firebase from 'firebase';


import firebase from "firebase/compat/app";

import "firebase/auth";
import 'firebase/compat/database';
import 'firebase/compat/storage';

require('typeface-montserrat');

// zmiana apki w cli

// firebase projects:list
// firebase use <project_id>

//Not safe change to external file before submit to github

var config = {
    apiKey: "AIzaSyD9YlUG1UDu9w36a_9a16HDhca1b2zAUVc",
    authDomain: "tds-ltd.firebaseapp.com",
    databaseURL: "https://tds-ltd.firebaseio.com",
    projectId: "tds-ltd",
    storageBucket: "tds-ltd.appspot.com",
    messagingSenderId: "738482366241",
    appId: "1:738482366241:web:6439b91e93491408"
};


// var config = {
//     apiKey: "AIzaSyBi16iJlNMXR4dfZJkJLqnzMJK_QQXdEz4",
//     authDomain: "tds-ltd-dev.firebaseapp.com",
//     databaseURL: "https://tds-ltd-dev.firebaseio.com",
//     projectId: "tds-ltd-dev",
//     storageBucket: "tds-ltd-dev.appspot.com",
//     messagingSenderId: "632280053861",
//     appId: "1:632280053861:web:dda47f4bea144d6e"
// };

firebase.initializeApp(config);


ReactDOM.render(<App />, document.getElementById('root'));
//registerServiceWorker();
unregister();


