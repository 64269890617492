import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
// import TableRow from '@material-ui/core/TableRow';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableFooter from '@material-ui/core/TableFooter';
import Grid from '@material-ui/core/Grid';
import Header from '../Quotes/QuoteHeader';
// import TableHead from '@material-ui/core/TableHead';
import Footer from '../Quotes/QuoteFooter';
import Paper from '@material-ui/core/Paper';

const Terms = require('../Pages/Terms');

const styles = theme => ({
    quotePrint: {
        '@media print': {
            margin: '0cm',
            padding: '0cm',
            width: '100%'
        },
    },
    itemWrap: {
        '@media print': {
            pageBreakInside: 'avoid'
        }
    },
    description: {
        textAlign: 'left',
        fontSize: '13px',
        fontFamily: 'Roboto',
        lineHeight: '1.2em',
    },

    img: {
        maxWidth: '100%',
        maxHeight: '450px',
        // '@media print':{
        //     pageBreakInside: 'avoid'
        // }
    },

    price: {
        fontSize: '16px',
        color: 'black',
        lineHeight: '4em',
    },

    aeraPrint30: {

        '@media print': {
            flexBasis: '33%',
            padding: '0px',
            margin: '0px'

        },
    },

    tableHead: {
        textAlign: 'left',
        padding: '10px 15px',
        borderBottom: '1px solid #CD2031',
        borderTop: '1px solid #eee',
        borderRight: '1px solid #eee',
        fontFamily: 'Montserrat'
    },

    tableCell: {
        textAlign: 'left',
        padding: '10px 15px',
        borderBottom: '1px solid #eee',
        borderRight: '1px solid #eee',
    },

    sumTableCell: {
        textAlign: 'left',
        padding: '10px 15px',
        borderTop: '1px solid #eee',
        borderRight: '1px solid #eee',

    },

    headerPrint: {
        margin: '0 0 10px 0',
    }




});

class Quote extends Component {

    sumUp = (items, vat, vatRate) => {

        let total = 0;

        items.map(item => {

            let val;

            if (item.discount === undefined) {
                item.discount = 0;
            } else if (item.price === undefined) {
                item.price = 0;
            } else if (item.qty === undefined) {
                item.qty = 1;
            } else if (item.vat === undefined) {
                if (this.props.vat !== undefined) {
                    item.vat = this.props.vat;
                } else {
                    item.vat = 0;
                }
            }

            if (item.discount !== 0) {
                val = item.qty * (item.price - (item.price * (1 - (100 - item.discount) / 100)))
            } else {
                val = item.price * item.qty;
            }

            if (vat === true) {
                if (item.vat === vatRate || vatRate === 'allItemsSumUp') {
                    total += val * ('1.' + Number(item.vat)) - val;
                } else if (item.vatRate === '0') {
                    total += 0
                }
            } else {
                if (item.vat === vatRate || vatRate === 'allItemsSumUp') {
                    total += val;
                }
            }
        })






        return total.toFixed(2);
    }

    componentDidMount() { }

    render() {
        const { classes } = this.props;
        return (

            <div className={classes.quotePrint}>
                {this.props.additional.length > 0 &&
                    this.props.additional.map((item, index) => (
                        <Paper key={index} className="page" style={{ pageBreakInside: 'avoid', flexDirection: "column" }}>
                            <div className={classes.headerPrint} >
                                <Header
                                    companyName={this.props.companyName}
                                    contactName={this.props.contactName}
                                    contactEmail={this.props.contactEmail}
                                    phoneNumber={this.props.phoneNumber}
                                    firstAddressLine={this.props.firstAddressLine}
                                    secondAddressLine={this.props.secondAddressLine}
                                    city={this.props.city}
                                    county={this.props.county}
                                    eircode={this.props.eircode}
                                    country={this.props.country}
                                    quoteDate={this.props.quoteDate}
                                    quoteNo={this.props.quoteNo}
                                    quoteID={this.props.quoteID}
                                    refernceNumber={this.props.refernceNumber}
                                ></Header>
                            </div>
                            <Grid container justify="center" className={classes.itemWrap} spacing={6} key={item.key}>
                                <Grid item sm={6}>
                                    <div className={classes.description} dangerouslySetInnerHTML={{ __html: item.text }} ></div>
                                </Grid>
                                <Grid item sm={6}>
                                    {item.image !== "" &&
                                        <img className={classes.img} src={item.image} alt="IMG" />
                                    }
                                </Grid>
                            </Grid>
                            <Footer></Footer>
                        </Paper>
                    ))


                }


                {this.props.priceItems.length > 0 &&
                    <Paper
                        //className={classes.itemWrap} 
                        className={`pagePriceing ${classes.itemWrap}`}  >
                        <div className={classes.headerPrint}>
                            <Header
                                companyName={this.props.companyName}
                                contactName={this.props.contactName}
                                contactEmail={this.props.contactEmail}
                                phoneNumber={this.props.phoneNumber}
                                firstAddressLine={this.props.firstAddressLine}
                                secondAddressLine={this.props.secondAddressLine}
                                city={this.props.city}
                                county={this.props.county}
                                eircode={this.props.eircode}
                                country={this.props.country}
                                quoteDate={this.props.quoteDate}
                                quoteNo={this.props.quoteNo}
                                quoteID={this.props.quoteID}
                                refernceNumber={this.props.refernceNumber}
                            ></Header>
                        </div>

                        <h4 style={{ marginTop: '20px' }}>Price Breakdown</h4>

                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '70%', borderLeft: '1px solid #eee' }} className={classes.tableHead}>Item</div>
                            <div style={{ width: '10%' }} className={classes.tableHead}>Qty</div>
                            <div style={{ width: '10%' }} className={classes.tableHead}>Unit Price</div>
                            {this.props.priceItems.findIndex(x => Number(x.discount) !== 0) !== -1 && <div style={{ width: '10%' }} className={classes.tableHead}>Discount</div>}
                            <div style={{ width: '10%' }} className={classes.tableHead}>Amount</div>
                        </div>

                        {this.props.priceItems.map(option => (

                            option.break === true ?

                                <div key={option.key} style={{ pageBreakBefore: 'always' }} >
                                    <div className={classes.headerPrint}>
                                        <Header
                                            companyName={this.props.companyName}
                                            contactName={this.props.contactName}
                                            contactEmail={this.props.contactEmail}
                                            phoneNumber={this.props.phoneNumber}
                                            firstAddressLine={this.props.firstAddressLine}
                                            secondAddressLine={this.props.secondAddressLine}
                                            city={this.props.city}
                                            county={this.props.county}
                                            eircode={this.props.eircode}
                                            country={this.props.country}
                                            quoteDate={this.props.quoteDate}
                                            quoteNo={this.props.quoteNo}
                                            quoteID={this.props.quoteID}
                                            refernceNumber={this.props.refernceNumber}
                                        ></Header>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '70%', borderLeft: '1px solid #eee' }} className={classes.tableHead}>Item</div>
                                        <div style={{ width: '10%' }} className={classes.tableHead}>Qty</div>
                                        <div style={{ width: '10%' }} className={classes.tableHead}>Unit Price</div>
                                        {this.props.priceItems.findIndex(x => Number(x.discount) !== 0) !== -1 && <div style={{ width: '10%' }} className={classes.tableHead}>Discount</div>}
                                        <div style={{ width: '10%' }} className={classes.tableHead}>Amount</div>
                                    </div>
                                </div>

                                :

                                <div style={{ display: 'flex', verticalAlign: 'bottom', borderBottom: option.thickLine && '1px solid #CD2031', marginBottom: option.thickLine ? '3px' : '0' }} key={option.key}  >
                                    <div style={{ width: '70%', borderLeft: '1px solid #eee', paddingTop: '0px', paddingBottom: '0px' }} className={`${classes.tableCell} wysiwyg`} dangerouslySetInnerHTML={{ __html: option.text }}></div>
                                    <div style={{ width: '10%' }} className={classes.tableCell}>{option.qty}</div>
                                    <div style={{ width: '10%' }} className={classes.tableCell}>{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format(option.price)}</div>
                                    {this.props.priceItems.findIndex(x => Number(x.discount) !== 0) !== -1 && <div style={{ width: '10%', verticalAlign: 'bottom' }} className={classes.tableCell}>{option.discount}%</div>}
                                    <div style={{ width: '10%' }} className={classes.tableCell}>
                                        {
                                            Number(option.discount) !== 0
                                                ?
                                                <span>{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format((option.qty * (option.price - (option.price * (1 - (100 - option.discount) / 100)))).toFixed(2))}</span>
                                                :
                                                <span>{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format((option.qty * option.price).toFixed(2))} </span>
                                        }
                                    </div>

                                </div>



                        ))

                        }

                        {
                            this.props.net === true &&

                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <div style={{ width: '10%', borderLeft: '1px solid #eee' }} className={classes.tableHead}>{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format(Number(this.sumUp(this.props.priceItems, false, 'allItemsSumUp')).toFixed(2))}</div>
                            </div>

                        }

                        {this.props.net === false &&
                            <Grid container justify="center" spacing={6} style={{ marginTop: '10px', pageBreakInside: 'avoid' }}>
                                <Grid item lg={4} md={4} className={classes.aeraPrint30}>

                                    <div style={{ borderBottom: '1px solid #CD2031' }} ><h4>Vat Analysis</h4></div>
                                    <div style={{ display: 'flex', }}>
                                        <div className={classes.sumTableCell} style={{ width: '30%', borderLeft: '1px solid #eee' }} >Rate</div>
                                        <div className={classes.sumTableCell} style={{ width: '30%' }} >Net Total</div>
                                        <div className={classes.sumTableCell} style={{ width: '30%' }} >VAT Total</div>
                                    </div>

                                    <div style={{ display: 'flex' }}>
                                        <div className={classes.sumTableCell} style={{ width: '30%', borderLeft: '1px solid #eee' }} >23%</div>
                                        <div className={classes.sumTableCell} style={{ width: '30%' }} >{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format(this.sumUp(this.props.priceItems, false, '23'))}</div>
                                        <div className={classes.sumTableCell} style={{ width: '30%' }} >{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format(this.sumUp(this.props.priceItems, true, '23'))}</div>
                                    </div>

                                    <div style={{ display: 'flex' }}>
                                        <div className={classes.sumTableCell} style={{ width: '30%', borderLeft: '1px solid #eee' }} >21%</div>
                                        <div className={classes.sumTableCell} style={{ width: '30%' }} >{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format(this.sumUp(this.props.priceItems, false, '21'))}</div>
                                        <div className={classes.sumTableCell} style={{ width: '30%' }} >{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format(this.sumUp(this.props.priceItems, true, '21'))}</div>
                                    </div>

                                    <div style={{ display: 'flex' }}>
                                        <div className={classes.sumTableCell} style={{ width: '30%', borderLeft: '1px solid #eee' }} >13.5%</div>
                                        <div className={classes.sumTableCell} style={{ width: '30%' }} >{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format(this.sumUp(this.props.priceItems, false, '135'))}</div>
                                        <div className={classes.sumTableCell} style={{ width: '30%' }} >{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format(this.sumUp(this.props.priceItems, true, '135'))}</div>

                                    </div>

                                    <div style={{ display: 'flex' }}>
                                        <div className={classes.sumTableCell} style={{ width: '30%', borderLeft: '1px solid #eee', borderBottom: '1px solid #eee' }} >0%</div>
                                        <div className={classes.sumTableCell} style={{ width: '30%', borderBottom: '1px solid #eee' }} >{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format(this.sumUp(this.props.priceItems, false, '0'))}</div>
                                        <div className={classes.sumTableCell} style={{ width: '30%', borderBottom: '1px solid #eee' }} >{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format(this.sumUp(this.props.priceItems, true, '0'))}</div>
                                    </div>

                                </Grid>
                                <Grid item lg={4} md={4} className={classes.aeraPrint30}>
                                </Grid>
                                <Grid item lg={4} md={4} className={classes.aeraPrint30}>

                                    <div style={{ borderBottom: '1px solid #CD2031' }} ><h4>Amount</h4></div>
                                    <div style={{ display: 'flex' }}>
                                        <div className={classes.sumTableCell} style={{ width: '50%', borderLeft: '1px solid #eee' }} >Subtotal</div>
                                        <div className={classes.sumTableCell} style={{ width: '50%' }} >{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format(Number(this.sumUp(this.props.priceItems, false, 'allItemsSumUp')).toFixed(2))}</div>
                                    </div>

                                    <div style={{ display: 'flex' }}>
                                        <div className={classes.sumTableCell} style={{ width: '50%', borderLeft: '1px solid #eee' }} >Vat Total</div>
                                        <div className={classes.sumTableCell} style={{ width: '50%' }} >{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format(Number(this.sumUp(this.props.priceItems, true, 'allItemsSumUp')).toFixed(2))}</div>
                                    </div>

                                    <div style={{ display: 'flex' }}>
                                        <div className={classes.sumTableCell} style={{ width: '50%', borderLeft: '1px solid #eee', borderBottom: '1px solid #eee' }} >Total EUR</div>
                                        <div className={classes.sumTableCell} style={{ width: '50%', borderBottom: '1px solid #eee' }} >{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format((Number(this.sumUp(this.props.priceItems, false, 'allItemsSumUp')) + Number(this.sumUp(this.props.priceItems, true, 'allItemsSumUp'))).toFixed(2))}</div>
                                    </div>

                                </Grid>

                            </Grid>
                        }


                        <Grid item lg={12} md={12} >
                            <h5 style={{ textAlign: 'left', marginTop: '20px' }}> NOTE: </h5>
                            <ol style={{ textAlign: 'left', fontSize: '11px' }}>
                                <li>The customer is obligated to check the quotation for compliance with their request.</li>
                                <li>The quotation is representative of the entire request, and is dependent on the volume of elements. The change of any factors (dimensions, colours, ironmongery, glass, quantity of order, etc.) requires a recalculation of the quotation.</li>
                                <li>The customer is obligated to confirm that the ordered product complies with the specifications and regulations required by them.</li>
                            </ol>
                        </Grid>



                        <Footer></Footer>
                    </Paper>
                }


                <div
                    className="terms"
                    dangerouslySetInnerHTML={{ __html: Terms }} >
                </div>
            </div>
        )
    }

}
export default withStyles(styles)(Quote);