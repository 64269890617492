import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';




const styles = theme => ({
  root: {
    width: '100%',
    '@media print':{
      display: 'none',
    }
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepper:{
    backgroundColor: '#F5F5F5'
  }
});

class ClassStepper extends React.Component {
  state = {
    
  };

  render() {
    const { classes } = this.props;
    const steps = this.props.steps;
    //const { activeStep } = this.state;

    return (
      <div className={classes.root}>
        <Stepper activeStep={this.props.activeStep} alternativeLabel className={classes.stepper} >
          {steps.map(label => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        
      </div>
    );
  }
}

ClassStepper.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(ClassStepper);