import {
    MeshStandardMaterial,
    Mesh,
    CylinderGeometry,
    Group 
    }  from 'three';

const createHandle = (ironmongery, maincColor , side, doorWidth, door, metalness, roughness, doorHeight) =>{
    
    let handleCol = ironmongery.color

    if(handleCol === "sameAsFrame"){
        handleCol = maincColor
    }

    const handleMaterial = new MeshStandardMaterial({color: handleCol,metalness: metalness+0.1, roughness: roughness+0.1})
    const gripMaterial = new MeshStandardMaterial({color: handleCol,metalness: 0.3, roughness: 0.3})

    const spindleGeometry = new CylinderGeometry(12, 12, ironmongery.spindle, 8);
    const gripGeometry =  new CylinderGeometry(12, 12, ironmongery.grip, 8);
    const rossetGeometry =  new CylinderGeometry(32, 32, ironmongery.rosetDepth, 20);

    const handle1 = new Mesh( spindleGeometry, handleMaterial );
    const handle2 = new Mesh( gripGeometry, gripMaterial );
    const handle3 = new Mesh( gripGeometry, handleMaterial );

    const rosset = new Mesh( rossetGeometry, handleMaterial );

    handle1.rotation.x =  Math.PI * 0.5;
    handle2.rotation.z =  Math.PI * 0.5;
    handle2.position.z = ironmongery.spindle/2 -12 ;
    handle3.rotation.z =  Math.PI * 0.5;
    handle3.position.z = -ironmongery.spindle/2 +12;

    rosset.rotation.x =  Math.PI * 0.5;

    const handle = new Group();

    handle.add(handle1, handle2, handle3, rosset)



    if(door === "main"){
        if(side === "left"){
            handle.position.x = -doorWidth/2 +45 -  Number(ironmongery.handleLocationHorizontal);

           
            handle2.position.x = ironmongery.grip/2 ;
            handle3.position.x = ironmongery.grip/2 ;
        }else{
            handle.position.x =  doorWidth/2 -45 +  Number(ironmongery.handleLocationHorizontal);
            handle2.position.x = -ironmongery.grip/2;
            handle3.position.x = -ironmongery.grip/2;
        }
    }
    else{

        if(side === "left"){
            handle.position.x = doorWidth/2 - 45 + Number(ironmongery.handleLocationHorizontal);
            handle2.position.x = -ironmongery.grip/2;
            handle3.position.x = -ironmongery.grip/2;
        }else{
            handle.position.x =  -doorWidth/2 + 45 -  Number(ironmongery.handleLocationHorizontal);
            handle2.position.x = ironmongery.grip/2;
            handle3.position.x = ironmongery.grip/2;
        }
    }

    // if(side === "left"){

    //     handle.position.x = -doorWidth/2 +60
    //     handle2.position.x = ironmongery.grip/2;
    //     handle3.position.x = ironmongery.grip/2;

    // }else{
    //     handle.position.x =  doorWidth/2 -60
    //     handle2.position.x = -ironmongery.grip/2;
    //     handle3.position.x = -ironmongery.grip/2;
    // }

    handle.position.y = (-doorHeight/2) + Number(ironmongery.handleLocation);

    return(handle)
}

export default createHandle; 