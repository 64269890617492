import React, { Component } from 'react';
// import * as firebase from 'firebase'
import firebase from "firebase/compat/app";
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment';
import TableHead from '@material-ui/core/TableHead';
import Badge from '@material-ui/core/Badge';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import DoneIcon from '@material-ui/icons/Done';
import WarningIcon from '@material-ui/icons/Warning';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import {userContext} from '../Context/userContext';

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';






  
  const styles = theme => ({
    root: {
    //   backgroundColor: theme.palette.background.paper,
      width: '100%',
      margin: '50 auto',
    },
    paper:{
        margin: '20px',
        overflowX: 'scroll',
    },
    
  
    
  });

class Tasks extends Component{
    state = {
        costName: '',
        vat: 23,
        cost: '',
        invoiceNo: '',
        date: '',
        totalCost: '',
        open: false,
//        totalLabour: '',
        Income: '',
    }
    confirmTask = (name, key )=> event => {

     

        const updateRef = firebase.database().ref(`Orders/${this.props.orderId}/ManualTasks/`);
        let result;
        let date;
    
        if(name === 'Confirm collection date from manufacture' || name  === 'Arrange Collection/Delivery date with customer' ){
             // get existing manual tasks   
            updateRef.once('value').then( (snap) =>{
                
                if(snap.val() != null){
                    let tasks = Object.entries(snap.val())

                    

                    let index = tasks.findIndex(x => x[1].name === name)

                    console.log(tasks, index)
                    

                    
                    if(index !== -1){
                        let taskToRem = tasks[index][0]
                        updateRef.child(taskToRem).remove();
                    }
                }

            }).then( ()=>{
                result =  moment(event).format('L');
                date =  date = moment().format('L')

                updateRef.push({
                    name :name,
                    value: result,
                    date: date,
                    confirmedBy: this.context.initials
                })
            })
        //all the rest of tasks 
        }else{
            result =  event.target.checked;
            date = moment().format('L');

            updateRef.push({
                name :name,
                value: result,
                date: date,
                confirmedBy: this.context.initials
            })
        }
    
      
    
    };



    checkTaks = (name, task ) =>{
        let index = -1;

        if(task === 'File Upload'){
            let archive  = Object.entries(this.props.Archive);
            index = archive.findIndex(x => x[1].name === name);
        }else{
            if(this.props.ManualTasks !== undefined){
                let manualTasks  = Object.entries(this.props.ManualTasks);
                index = manualTasks.findIndex(x => x[1].name === name);
            }
        }

        if(index !== -1){
            return(true)
        }else{
            return(false)
        }
    } 

    getTaskDate = name =>{

        let index ;
        let manualTasks  = Object.entries(this.props.ManualTasks)
        index = manualTasks.findIndex(x => x[1].name === name);

        if(index !== -1){
            return manualTasks[index][1].date;
        } 
        
    }
    getTaskValue = name =>{

        let index ;
        let manualTasks  = Object.entries(this.props.ManualTasks)
        index = manualTasks.findIndex(x => x[1].name === name);

        if(index !== -1){
            return manualTasks[index][1].value;
        } 
        
    }

    getOperator = (name,type) =>{

        let index ;

        if(type === 'upload'){
     
            let archive  = Object.entries(this.props.Archive);
            index = archive.findIndex(x => x[1].name === name);

            if(index !== -1){
                return archive[index][1].uploadedBy;
            } 

        }else{
            let manualTasks  = Object.entries(this.props.ManualTasks);
            index = manualTasks.findIndex(x => x[1].name === name);

            if(index !== -1){
                return manualTasks[index][1].confirmedBy;
            } 
        }
    }

    getOutstandingTasks = type =>{
       let outstanding = 0;
       //let required = 0;
       //let manualTasks  = Object.entries(this.props.ManualTasks);
       //let archive  = Object.entries(this.props.Archive);
       let fileTypes = this.props.fileTypes;

       fileTypes.map(task=>{
           if(task[1].Required === 'true' && (Number(task[1].Stage) <= Number(this.props.stage))){
                if(!this.checkTaks(task[1].Name, task[1].Confirmation )){
                   outstanding++
                }
           }
        })

       return outstanding;
    }  

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        this.setState({open:false});
    };

    componentDidMount(){

        this.setState({open:true})
    }

    render() {
        const { classes } = this.props;
    
        
            return (
                <userContext.Consumer>
                {(user) => ( user.access >= 1 &&
                
                <div >
                    <Paper className={classes.paper}>
                        
                                <h2>Order checklist:</h2>
                                <Table className={classes.table}>
                                 <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                           
                                               <Typography variant="overline"> Order Stage - {this.props.stage}</Typography> 
                                          
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                      
                                            <Typography variant="overline">Outstanding tasks -  {this.getOutstandingTasks()}</Typography> 
                                         
                                        </TableCell>
                                      
                                    </TableRow>
                                </TableBody>
                                </Table>
                          
                               
                                <Table className={classes.table}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Required: </TableCell>
                                                <TableCell ></TableCell>
                                                <TableCell >Date</TableCell>
                                                <TableCell style={{minWidth: "53px"}}>Done by </TableCell>
                                            </TableRow>
                                        </TableHead>
                                <TableBody>
                                {   this.props.fileTypes.sort((a,b) => (Number(a[1].Stage) > Number(b[1].Stage)) ? 1 : ((Number(b[1].Stage) > Number(a[1].Stage)) ? -1 : 0)).map( task =>{
                                       return( 
                                        <TableRow key={task[0]} style={{ border: (this.checkTaks(task[1].Name, task[1].Confirmation) !== true  && task[1].Name === "Drawings signed by Customer") ? "5px solid red" : "none" }} >


                                        {(task[1].Required === 'true' && (Number(task[1].Stage) <= Number(this.props.stage))) ? <TableCell >
                                        {this.checkTaks(task[1].Name, task[1].Confirmation) === true ?

                                        <span><DoneIcon  style={{color: 'green'}}/></span>
                                        :
                                        <span> <WarningIcon color="error"/></span>
                                        
                                    }
                                            
                                            </TableCell> : <TableCell ></TableCell>  }
                                            <TableCell component="th" scope="row">

                                            {   task[1].Confirmation === 'File Upload' ?
                                                    this.checkTaks(task[1].Name, task[1].Confirmation) === true ?
                                                    <span >{task[1].Name} - (Uploaded)</span> : 
                                                    <span>{task[1].Name}</span>
                                                :
                                                <span>
                                                    { ( 
                                                        task[1].Name  === 'Confirm collection date from manufacture' ||
                                                        task[1].Name  === 'Arrange Collection/Delivery date with customer' 
                                                       
                                                        )

                                                        ?
                                                        <span>
                                                            {task[1].Name} 
                                                            { this.getTaskValue(task[1].Name) !== undefined && <> <strong> <br/> Scheduled for {this.getTaskValue(task[1].Name)}</strong></>}
                                                            { 
                                                            //this.getTaskValue(task[1].Name) === undefined && 
                                                               
                                                                    <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                                                      <KeyboardDatePicker
                                                                       

                                                                        margin="normal"
                                                                        id="date-picker-dialog"
                                                                        label=""
                                                                    
                                                   
                                                                        onChange={this.confirmTask(task[1].Name ,task[0])}
                                                                        KeyboardButtonProps={{
                                                                          'aria-label': 'change date'
                                                                      
                                                                        }}
                                                                      />
                                                                    </MuiPickersUtilsProvider>
                                                        }

                                                     
                                                        </span>
                                                        :
                                                        <span>
                                                            {task[1].Name} 
                                                            <Checkbox
                                                                disabled={this.checkTaks(task[1].Name, task[1].Confirmation) }
                                                                checked={this.checkTaks(task[1].Name, task[1].Confirmation) }
                                                                onChange={this.confirmTask(task[1].Name ,task[0]) }
                                                            />
                                                            
                                                           
                                                        </span> 

                                                    }
                                                     
                                                  
                                                </span>
                                            }

                                         
                                            </TableCell>

                                            <TableCell>{ this.getTaskDate(task[1].Name) } </TableCell>
                                            <TableCell>

                                                {task[1].Confirmation === 'File Upload' ?
                                                    this.getOperator(task[1].Name, 'upload')
                                                :
                                                    this.getOperator(task[1].Name )
                                                } 
                            
                                            </TableCell>
                                            
                                       
                                        </TableRow>
                                       
                                        )
                                    })
                                }
                                </TableBody>
                            </Table>

                       
                            </Paper>

                             <Snackbar 
                             anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                              }}
                             open={this.state.open} 
                             autoHideDuration={6000} 
                             onClose={this.handleClose}>
                                <Alert onClose={this.handleClose} severity="error">
                                There is {this.getOutstandingTasks()} outstanding tasks.
                                </Alert>
                            </Snackbar>

                </div>
                
                )}</userContext.Consumer>

            );
        
    }
  }

export default withStyles(styles)(Tasks);
Tasks.contextType = userContext;