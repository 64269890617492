
import { Group }  from 'three';
import createBox from './createBox';
import SpriteText from 'three-spritetext';

const createTopMeasurement = (totalWidth, rightPanelIncluded, leftPanelIncluded, leftPanelWidth, rightPanelWidth, zoom) =>{
    const topMeasurements = new Group();

    const widthMeasurement = createBox(totalWidth +100, 2 ,2 , "#666666");
    const widthMeasurementLeft = createBox(2, 180, 2 , "#666666");
    const widthMeasurementRight = createBox(2, 180, 2 , "#666666");

 
    widthMeasurementLeft.position.y =  -40;
    widthMeasurementRight.position.y = -40;
    widthMeasurementLeft.position.x = totalWidth/2
    widthMeasurementRight.position.x = -totalWidth/2

    // Text WIDTH 

    const widthtText = new SpriteText(totalWidth);

   
    widthtText.textHeight =  Math.round(Number(zoom)/110);
    widthtText.color = "black";

    topMeasurements.add(widthMeasurement, widthMeasurementLeft, widthMeasurementRight ,widthtText)

    if(leftPanelIncluded && !rightPanelIncluded){
        topMeasurements.position.x = -leftPanelWidth/2
    }else if(!leftPanelIncluded && rightPanelIncluded){
        topMeasurements.position.x = rightPanelWidth/2
    }else if(leftPanelIncluded && rightPanelIncluded){
        topMeasurements.position.x = (rightPanelWidth - leftPanelWidth)/2
    }

    return(topMeasurements)
}

export  default createTopMeasurement;