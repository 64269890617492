import React, { Component, createContext } from 'react';
import { BrowserRouter, Route , Switch, Router } from 'react-router-dom';
import history from './history';
import './App.css';
import Login from './Pages/Login';
import Home from './Pages/Home';
import MonthlyReport from './Pages/monthlyReport';
import YearlyReport from './Pages/yearlyReport'
import Costs from './Pages/Costs';
import SteelDoors from './Pages/SteelDoors';
import FlexiQuote from './Pages/FlexiQuote';
import QuotesPage from './Pages/QuotesPage';
import OrdersPage from './Pages/OrdersPage';
import LostDeals from './Pages/LostDeals';
import WonDeals from './Pages/WonDeals';
import DealPage from './Pages/DealPage';
import QuotePage from './Pages/QuotePage';
import EditQuote from './Pages/EditQuote';
import OrderPage from './Pages/OrderPage';
import ClosedOrdersPage from './Pages/ClosedOrdersPage';
import Customers from './Pages/Customers';
import Customer from './Pages/Customer';
import Users from './Pages/Users';
import ButtonAppBar from './partials/NavBar';
import Paperwork from './Pages/Paperwork';
import Settings from './Pages/Settings';
import AdvancedSearch from './Pages/AdvancedSearch';
import SteelFramedTemplateBuilder from './Pages/SteelFramedTemplateBuilder';
import SteelFramedAdmin from './Pages/SteelFramedAdmin';
// import SteelFramedTemplates from './Pages/SteelFramedTemplates';
// import SteelFramedQuoteCreator from './Pages/SteelFramedQuoteCreator';
import SteelFramedPage from './Pages/SteelFramedPage';


import SteelFramedQuote from './Pages/SteelFramedQuote';




// import * as firebase from 'firebase';
//import firebase from "firebase/app";
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth"
import { getDatabase, ref, query , onValue} from "firebase/database";

import {userContext} from './Context/userContext';
import SalesPipeline from './Pages/SalesPipeline';
import FileTypes from './Pages/FileTypes';


import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';

const theme = createTheme({
  overrides: {
      MuiTableCell: {
          root: {  //This can be referred from Material UI API documentation. 
               lineHeight : '1.4',
          },
      },
  },
  palette: {
    primary: {
      //main: '#d60a2e',
      main:'#D80026',
    },
    secondary: {
      main: '#212121',
    }  
  },
});

class App extends Component {
  
  state={
    loggedIn : false ,
    sysUser: {access: 0}
  }

  handleLoginnameChange=() => event =>{

    const auth = getAuth();

    if(this.state.loggedIn === false){
      this.setState({loggedIn : true})
    }else{
      signOut(auth).then(() => {
        // Sign-out successful.
        this.setState({loggedIn : false})

      }).catch((error) => {
        alert("sign out error: " + error)
      });
      
    }
  }


  getUsers = () =>{
    // v9 code 

    const db = getDatabase();

    const userRef = query(ref(db , 'Users')) 

    onValue( userRef, (snap)=>{
        // console.log(snap.val())

        let sysUser = this.state.sysUser;
        let users = snap.val(); 
        if(users !== null){

          //change json to array
          let allusers = Object.entries(users);    
          
          allusers.map(us=>{
              if(us[1].email === this.state.userEmail){
                sysUser = us[1];
              }
          })
          this.setState({  sysUser: sysUser})
      }  
    })

  }
  componentDidMount(){

    let sysUser = this.state.sysUser ;

    const auth = getAuth();

    auth.onAuthStateChanged((user) => {
  
      if (user) {
          
      this.setState({ 
        userEmail : user.email,
        loggedIn : true 
      },()=>{this.getUsers()})

      }
    });
  
  }
  
  render() {
    return (

      <Router history={history}>
      <MuiThemeProvider theme={theme}>
      <userContext.Provider value={this.state.sysUser}>
  
      <div className="App">
        
          <ButtonAppBar loggedIn={this.state.loggedIn}  handleLoginnameChange={this.handleLoginnameChange} userEmail={this.state.userEmail} ></ButtonAppBar>
      
            <Switch>
              <Route exact path='/'
              //  component={Login}  
               render={(props) => <Login {...props} loggedIn={this.state.loggedIn } userEmail={this.state.userEmail}/>}/>
              <Route path='/home' render={(props) => <Home {...props} loggedIn={this.state.loggedIn } userEmail={this.state.userEmail}/>}/>
              <Route path='/Costs' render={(props) => <Costs {...props} loggedIn={this.state.loggedIn } userEmail={this.state.userEmail}/>}/>
              <Route path='/settings' render={(props) => <Settings {...props} loggedIn={this.state.loggedIn } userEmail={this.state.userEmail}/>}/>
              <Route path='/steel-doors' render={(props) => < SteelDoors {...props} loggedIn={this.state.loggedIn}  displayName={this.state.displayName} userEmail={this.state.userEmail}/>}/>
              <Route path='/flexi-quote' render={(props) => < FlexiQuote {...props} loggedIn={this.state.loggedIn}  displayName={this.state.displayName} userEmail={this.state.userEmail}/>}/>   
              <Route path='/quotes' render={(props) => < QuotesPage {...props} loggedIn={this.state.loggedIn}  displayName={this.state.displayName} userEmail={this.state.userEmail}/>}/>
              <Route path='/lostdeals' render={(props) => < LostDeals {...props} loggedIn={this.state.loggedIn}  displayName={this.state.displayName} userEmail={this.state.userEmail}/>}/>
              <Route path='/wondeals' render={(props) => < WonDeals {...props} loggedIn={this.state.loggedIn}  displayName={this.state.displayName} userEmail={this.state.userEmail}/>}/>
              <Route path='/quote/:projectId' render={(props) => < QuotePage {...props} loggedIn={this.state.loggedIn}  displayName={this.state.displayName} userEmail={this.state.userEmail}/>}/>
              <Route path='/deal/:projectId/:databaseCollection' render={(props) => < DealPage {...props} loggedIn={this.state.loggedIn}  displayName={this.state.displayName} userEmail={this.state.userEmail}/>}/>
              <Route path='/order/:orderId' render={(props) => < OrderPage {...props} loggedIn={this.state.loggedIn}  displayName={this.state.displayName} userEmail={this.state.userEmail}/>}/>
              <Route path='/edit-quote/:projectId' render={(props) => < EditQuote {...props} loggedIn={this.state.loggedIn}  displayName={this.state.displayName} userEmail={this.state.userEmail}/>}/>
              <Route path='/paperwork/:projectId' render={(props) => < Paperwork {...props} loggedIn={this.state.loggedIn}  displayName={this.state.displayName} userEmail={this.state.userEmail}/>}/>
              <Route path='/salespipeline' render={(props) => < SalesPipeline {...props} loggedIn={this.state.loggedIn}  displayName={this.state.displayName} userEmail={this.state.userEmail}/>}/>
              <Route path='/customers' render={(props) => < Customers {...props} loggedIn={this.state.loggedIn}  displayName={this.state.displayName} userEmail={this.state.userEmail}/>}/>
              <Route path='/customer/:customerId' render={(props) => < Customer {...props} loggedIn={this.state.loggedIn}  displayName={this.state.displayName} userEmail={this.state.userEmail}/>}/>
              <Route path='/orders' render={(props) => < OrdersPage {...props} loggedIn={this.state.loggedIn}  displayName={this.state.displayName} userEmail={this.state.userEmail}/>}/>
              <Route path='/closed-orders' render={(props) => < ClosedOrdersPage {...props} loggedIn={this.state.loggedIn}  displayName={this.state.displayName} userEmail={this.state.userEmail}/>}/>
              <Route path='/file-types' render={(props) => < FileTypes {...props} loggedIn={this.state.loggedIn}  displayName={this.state.displayName} userEmail={this.state.userEmail}/>}/>
              <Route path='/users' render={(props) => < Users {...props} userEmail={this.state.userEmail} loggedIn={this.state.loggedIn} />}/>
              <Route path='/advanced-search' render={(props) => < AdvancedSearch {...props} userEmail={this.state.userEmail} loggedIn={this.state.loggedIn} />}/>
              <Route path='/monthly-report' render={(props) => < MonthlyReport {...props} loggedIn={this.state.loggedIn}  displayName={this.state.displayName} userEmail={this.state.userEmail}/>}/>
              <Route path='/yearly-report' render={(props) => < YearlyReport {...props} loggedIn={this.state.loggedIn}  displayName={this.state.displayName} userEmail={this.state.userEmail}/>}/>


              
              <Route path='/steel-framed' render={(props) => < SteelFramedPage {...props} loggedIn={this.state.loggedIn}  displayName={this.state.displayName} userEmail={this.state.userEmail}/>}/>
              <Route path='/steel-framed-admin' render={(props) => < SteelFramedAdmin {...props} loggedIn={this.state.loggedIn}  displayName={this.state.displayName} userEmail={this.state.userEmail}/>}/>
              <Route path='/steel-framed-template-builder/:templateId?' render={(props) => < SteelFramedTemplateBuilder {...props} loggedIn={this.state.loggedIn}  displayName={this.state.displayName} userEmail={this.state.userEmail}/>}/>


              <Route path='/steel-framed-quote/:quotetId?' render={(props) => < SteelFramedQuote {...props} loggedIn={this.state.loggedIn}  displayName={this.state.displayName} userEmail={this.state.userEmail}/>}/>



              { /* ----- old   */ }
              {/* <Route path='/steel-framed-quote-creator/:doors/:quotetId' render={(props) => < SteelFramedQuoteCreator {...props} loggedIn={this.state.loggedIn}  displayName={this.state.displayName} userEmail={this.state.userEmail}/>}/> */}
              {/* <Route path='/steel-framed-templates' render={(props) => < SteelFramedTemplates {...props} loggedIn={this.state.loggedIn}  displayName={this.state.displayName} userEmail={this.state.userEmail}/>}/> */}
              
              {/* s    -f     -     */}


            </Switch>  
            <p className="noPrint"  style={{ position: 'fixed', right: '0', bottom: '0'}}>Version: Beta 0.0.9.3.1</p>
      </div>

      </userContext.Provider>
      </MuiThemeProvider>
      </Router>

      
    );
  }
}

export default App;
