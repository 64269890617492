import createBox from './createBox'

const createMuntinsPreset = (stateMuntins, childOf,color, metalness, roughness) =>{

    let muntins =[]
    stateMuntins.forEach(el => {

            if(el.childOf === childOf){
                const muntin = createBox(
                                        el.w, 
                                        el.h, 
                                        el.z, 
                                        color,
                                        el.bfId,
                                        metalness,
                                        roughness
                                    )

            if(Object.keys(el.position).length  !== 0){
                muntin.position.x = el.position.x;
                muntin.position.y = el.position.y;
                muntin.position.z = 0;
            }


            muntins.push(muntin)

        }
    });

    return(muntins)
}

export default createMuntinsPreset