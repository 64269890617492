import React, { Component, createContext } from 'react';
import { Redirect } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import Leads from '../Leads/Leads';



const styles = theme => ({
    root: {
      //flexGrow: 1,
      margin: '50px auto',
      width: '90%',
    },

   
});




class SalesPipeline extends Component{
    state = {}

    componentDidMount(){}
    
    render() {

        if(this.props.loggedIn === true){
            return (
                <div className={this.props.classes.root}>  
                    <Leads userEmail={this.props.userEmail}></Leads>
                </div>
            );
        }else{
                return <Redirect to='/'/>
        }
    }
  }
export default withStyles(styles)(SalesPipeline);