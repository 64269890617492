import React, { Component } from 'react';

class Hatch extends Component{
    state= {}
    render() {
        return (
            <div>
                <div style={{height: `${200/this.props.printRatio}px`,  width: `${500/this.props.printRatio}px`, border: '1px solid #000', position: 'absolute', bottom: `${this.props.bottom/this.props.printRatio}px`,left: '50%', transform: 'translate(-50%)',padding: '4px'}}
                >
                    <div style={{ border: '1px solid #000', width: '100%', height: '100%', boxSizing: 'border-box'}}>
                        < div style={{height: '20%',width: '100%', border: '1px solid #000', boxSizing: 'border-box'}} ></div>
                        < div style={{height: '20%',width: '100%', border: '1px solid #000', boxSizing: 'border-box'}} ></div>
                        < div style={{height: '20%',width: '100%', border: '1px solid #000', boxSizing: 'border-box'}} ></div>
                        < div style={{height: '20%',width: '100%', border: '1px solid #000', boxSizing: 'border-box'}} ></div>
                        < div style={{height: '20%',width: '100%', border: '1px solid #000', boxSizing: 'border-box'}} ></div>
                    </div>
                </div>  
            </div>  
        )
    }
}

export default Hatch;