import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router';
// import * as firebase from 'firebase';
import firebase from "firebase/compat/app";
import moment from 'moment';
import { Grid, Container, Paper } from '@material-ui/core';
import Quote from '../Flexi/Quote';
import CalcStepper from '../Flexi/Stepper';
import StepperNav from '../Flexi/StepperNav';
import Calculator from '../Flexi/Calculator';
import CustomerDetails from '../Flexi/CustomerDetails';


//const Prices = require('../ArchitecturalDoor/Prices');

const uniqid = require('uniqid');

const styles = theme => ({
    // root: {
    //     //   width: '96%',
    //     //   margin: '50px 2%',
    //     '@media print': {
    //         margin: '0',
    //         width: '100%',
    //     }
    // },

    paper: {
        padding: '20px',
        '@media print': {
            'box-shadow': 'none !important',
            'margin': "0cm",
            'padding': "0cm",
        }
    },

    checked: {
        color: '#d60a2e !important',
    },
    btn: {
        backgroundColor: '#d60a2e',
        color: '#ffffff',
    },

    delete: {
        position: 'absolute',
        top: '26px',
        right: '-34px',
    },
    additionalItem: {
        position: 'relative',
    },
    printMarginReset: {
        '@media print': {
            padding: '0cm !important',
            margin: '0cm !important',
            width: '100%'
        }

    }
});

class FlexiQuoteMainComponent extends Component {


    state = this.props;

    calculatePrice = () => {

        let total = 0;

        //Additional
        let priceItemsSum = 0;
        this.state.priceItems.map(add => {
            priceItemsSum += parseFloat(add.price, 10)
        })

        total += priceItemsSum;

        this.setState({
            totalPrice: total.toFixed(2),
        })
    }

    // Stepper navigation

    handleNext = () => {
        window.scrollTo(0, 0);
        const { activeStep } = this.state;
        this.setState({
            activeStep: activeStep + 1,
        });
    };

    handleBack = () => {
        window.scrollTo(0, 0);
        const { activeStep } = this.state;
        this.setState({
            activeStep: activeStep - 1,
        });
    };

    handleReset = () => {
        this.setState({
            activeStep: 0,
        })


    };

    handleNextForSameCompany = () => { };

    // Save Quote

    createOrderNumbeAndPushToSave = () => {

        const ID = firebase.database().ref().child('QuoteNo')
        let quoteNo;

        return ID.once('value').then((snap) => {

            quoteNo = snap.val() + 1;

            firebase.database().ref().update({ 'QuoteNo': quoteNo }).then((snap) => {

                quoteNo = this.props.userEmail.slice(0, 2) + '-' + moment().year() + '-' + quoteNo;
                this.setState({
                    quoteNo: quoteNo,
                    redirect: true
                }, () => { this.handleSaveOrder() })
            });
        })
    }

    handleSaveOrder = () => {
        //-------------------------------------------------------------------- remove preview image 
        this.state.additional.map(item => (
            item.image = ""
        ))

        const quoteRef = firebase.database().ref().child('Quotes');
        //-------------------------------------------------------------------- Save 
        quoteRef.push({

            //Door info

            'product': 'Flexi Quote',
            additional: this.state.additional,
            totalPrice: this.state.totalPrice,
            currencyRate: this.state.currencyRate,
            priceItems: this.state.priceItems,
            vat: this.state.vat,
            net: this.state.net,

            //Customer Data
            companyName: this.state.companyName,
            contactName: this.state.contactName,
            contactEmail: this.state.contactEmail,
            phoneNumber: this.state.phoneNumber,
            firstAddressLine: this.state.firstAddressLine,
            secondAddressLine: this.state.secondAddressLine,
            city: this.state.city,
            county: this.state.county,
            country: this.state.country,
            refernceNumber: this.state.refernceNumber,
            quoteDate: this.state.quoteDate,
            quoteNo: this.state.quoteNo

        }).then((snap) => {

            this.setState({
                quoteID: snap.key,

            })

        })
    }

    handleRemoveAdditional = id => event => {
        let remAdd = this.state.additional;
        let i = 0;
        remAdd.map(rm => {
            if (rm.key === id) {
                remAdd.splice(i, 1);
            }
            i++;
        })


        this.setState({
            additional: remAdd
        })
    }


    handleRemovePriceItem = id => event => {
        let remAdd = this.state.priceItems;
        let i = 0;
        remAdd.map(rm => {
            if (rm.key === id) {
                remAdd.splice(i, 1);
            }
            i++;
        })

        this.setState({
            priceItems: remAdd
        })
    }

    handleAdditionalImage = id => event => {


        //-------------------------------------------------------------------- create preview
        event.preventDefault();

        let uid = uniqid();

        //init reader
        let reader = new FileReader();
        let file = event.target.files[0];
        let fileName = 'flexi_Quote/' + uid + '_' + file.name

        reader.onloadend = () => {

            let additional = this.state.additional;
            let optionIndex = additional.findIndex(x => x.key === id);

            additional[optionIndex].image = reader.result;
            additional[optionIndex].imagePath = fileName;

            this.setState({
                additional: additional,
            });
        }
        reader.readAsDataURL(file);


        //-------------------------------------------------------------------- upload image  to firebase
        let storageRef = firebase.storage().ref(fileName)
        let uploadTask = storageRef.put(file)

        uploadTask.on('state_changed', function (snapshot) {

            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
            }
        }, function (error) {
            // Handle unsuccessful uploads
        }, function () {
            // Handle successful uploads on complete
        }.bind(this));
    }

    handleQuillText = (ob) => event => {

        let list = this.state[ob.list];
        let optionIndex = list.findIndex(x => x.key === ob.key);

        list[ob.index].text = event;

        this.setState({
            [list]: list
        })

    }

    handelItem = (id, name, list) => event => {

        let li = this.state[list];
        let optionIndex = li.findIndex(x => x.key === id);
        li[optionIndex][name] = event.target.value;



        this.setState({
            [list]: li
        }, this.calculatePrice())
    }


    handleChange = (name) => event => {

        this.setState({
            [name]: event.target.value
        }, () => this.calculatePrice())

    }
    handleNet = () => {
        let net = !this.state.net;
        this.setState({
            net: net
        })

    }

    handelAdditional = () => {

        let uid = uniqid();

        this.setState({
            additional: this.state.additional.concat({ key: uid, text: 'Text', image: '' })
        })
    }

    template = (ob) => event => {

        let list = this.state[ob.list];
        let optionIndex = list.findIndex(x => x.key === ob.key);

        list[ob.index].text = ` <div class="jss76"><h3>Internal Double Leaf Door</h3><h3>fabricated in "OC Slimline Prestige" Steel System</h3><p><br></p><p><strong>Dimensions:</strong>&nbsp;2100 x 2100 mm</p><p><strong>Glazing</strong>: 6 mm Toughen Glass (clear)</p><p><strong>Glazing gaskets / Weatherstrips</strong>: (black)</p><p><strong>Finishing (RAL)</strong>:&nbsp;RAL 9005 (fine structure)</p><p><strong>Ironmongery</strong></p><ul><li>Lock: Magnetic Catch</li><li>Handle: "Levem" handle 500mm (both leaves, both sides)</li><li>Hinges: Weld-on</li><li>Flush-bolts: Inactive Leaf (top, bottom)</li></ul><p><strong>Glazing Bar (muntin):</strong>&nbsp;Decorative/Structural 20 mm</p><p><br></p><p><br></p><p><strong>Note:</strong></p><p>Quotation for Supply Only. Delivery and Installation Charges not Included.</p></div>`;

        this.setState({
            [list]: list
        })

    }


    handelAddBreak = () => {
        let uid = uniqid();
        this.setState({
            priceItems: this.state.priceItems.concat({ key: uid, break: true, price: '0.00', discount: 0 })
        })
    }

    handleThickLIne = (list, id) => event => {

        let priceItems = this.state[list];
        let index = priceItems.findIndex(x => x.key === id);
        let item = priceItems[index];

        console.log(list, id);

        priceItems[index].thickLine = !priceItems[index].thickLine;

        this.setState({
            priceItems: priceItems
        })

    }


    handelAddItem = () => {
        let uid = uniqid();
        this.setState({
            priceItems: this.state.priceItems.concat({ key: uid, text: 'Item', price: '0.00', vat: '23', qty: 1, discount: 0, subitems: [], thickLine: false })
        })
    }

    handlePushItemDown = (list, id) => event => {

        let priceItems = this.state[list];
        let index = priceItems.findIndex(x => x.key === id);
        let item = priceItems[index];

        priceItems.splice(index, 1);
        priceItems.splice(index + 1, 0, item);

        this.setState({
            priceItems: priceItems
        })
    }

    componentDidMount() {
        this.timer = null;
    }

    render() {
        const { classes } = this.props;

        // if(this.state.quoteID !== "" ){
        //     return <Redirect to={'/quote/'+this.state.quoteID}/>
        // }

        // if(this.props.loggedIn === true){

        if (this.state.redirect === true) {
            return <Redirect to={'/quotes'} />
        }

        return (

            <Container maxWidth="xl" spacing={6} className={classes.printMarginReset}  style={{marginTop: "50px"}} >

           



                <Grid item xs={12} className={classes.printMarginReset}>

                    <CalcStepper
                        //properties
                        activeStep={this.state.activeStep}
                        steps={this.state.steps}

                    > </CalcStepper>

                </Grid>

        
                <Grid container spacing={6} className={classes.printMarginReset} style={{marginTop: "50px",justifyContent: "space-evenly"}}>

                    {this.state.activeStep === 0 &&
                        <Grid item md={4} className={`${classes.printMarginReset} noPrint`}>
                            <Paper className={`${classes.paper} noPrint`} elevation={1}>
                                <h2>Flexi Quote</h2>

                                <Calculator
                                    //handlers
                                    handlePushItemDown={this.handlePushItemDown} s
                                    handelAddSubItem={this.handelAddSubItem}
                                    handleChange={this.handleChange}
                                    handelAdditional={this.handelAdditional}
                                    handelAddBreak={this.handelAddBreak}
                                    handleRemoveAdditional={this.handleRemoveAdditional}
                                    handleRemovePriceItem={this.handleRemovePriceItem}
                                    handleAdditionalImage={this.handleAdditionalImage}
                                    handleQuillText={this.handleQuillText}
                                    handleThickLIne={this.handleThickLIne}
                                    handelAddItem={this.handelAddItem}
                                    handelItem={this.handelItem}
                                    handleNet={this.handleNet}
                                    template={this.template}

                                    //Properties
                                    additional={this.state.additional}
                                    priceItems={this.state.priceItems}
                                    net={this.state.net}
                                ></Calculator>
                            </Paper>
                        </Grid>
                    }
                    {this.state.activeStep === 1 &&
                        <Grid item md={4} className={`${classes.printMarginReset} noPrint `}>
                            <Paper className={classes.paper}>
                                <CustomerDetails


                                    companyName={this.state.companyName}
                                    contactName={this.state.contactName}
                                    contactEmail={this.state.contactEmail}
                                    phoneNumber={this.state.phoneNumber}
                                    firstAddressLine={this.state.firstAddressLine}
                                    secondAddressLine={this.state.secondAddressLinesecondAddressLine}
                                    city={this.state.city}
                                    county={this.state.county}
                                    country={this.state.country}
                                    refernceNumber={this.state.refernceNumber}
                                    quoteId={this.state.quoteID}
                                    quoteDate={this.state.quoteDate}



                                    //actions
                                    handleChange={this.handleChange}
                                ></CustomerDetails>
                            </Paper>
                        </Grid>
                    }

                    <Grid item md={8} className={classes.printMarginReset}>
                        {/* <Paper className={classes.paper}> */}
                        <Quote

                            //Product info
                            additional={this.state.additional}
                            totalPrice={this.state.totalPrice}
                            currencyRate={this.state.currencyRate}
                            priceItems={this.state.priceItems}
                            vat={this.state.vat}

                            //Customer Data
                            companyName={this.state.companyName}
                            contactName={this.state.contactName}
                            contactEmail={this.state.contactEmail}
                            phoneNumber={this.state.phoneNumber}
                            firstAddressLine={this.state.firstAddressLine}
                            secondAddressLine={this.state.secondAddressLine}
                            city={this.state.city}
                            county={this.state.county}
                            country={this.state.country}
                            refernceNumber={this.state.refernceNumber}
                            quoteId={this.state.quoteID}
                            quoteDate={this.state.quoteDate}
                            handlePrice={this.state.handlePrice}
                            handleOption={this.state.handleOption}
                            net={this.state.net}
                        ></Quote>
                        {/* </Paper> */}
                    </Grid>


                    <Grid item md={12} >

                        <StepperNav
                            //actions
                            handleNext={this.handleNext}
                            handleBack={this.handleBack}
                            saveRecord={this.saveRecord}
                            handleReset={this.handleReset}
                            createOrderNumbeAndPushToSave={this.createOrderNumbeAndPushToSave}
                            handleNextForSameCompany={this.handleNextForSameCompany}

                            //properties
                            activeStep={this.state.activeStep}
                            steps={this.state.steps}

                        ></StepperNav>
                    </Grid>

                </Grid>

    

            </Container>

        )
        // }else{
        //     return <Redirect to='/'/>
        // }
    }

}

export default withStyles(styles)(FlexiQuoteMainComponent);