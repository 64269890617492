import React, { Component } from 'react';
import { Redirect } from 'react-router';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import HowToRegSharpIcon from '@material-ui/icons/HowToRegSharp';
import ReceiptIcon from '@material-ui/icons/Receipt';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { userContext } from '../Context/userContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import SteelDoorIco from '../img/steelDoor.svg'
import FlexiQuoteIcon from '../img/flexiQuIco.svg';
import CritDoorIco from '../img/critDoorIco.svg';
import Container from '@material-ui/core/Container';



const styles = theme => ({
    root: {
        //flexGrow: 1,
        margin: '50px auto',
        width: '90%',
    },
    mainNav: {
        display: 'flex',
        // padding: '30px 0',

        flexWrap: 'wrap',

    },
    homeBtn: {
        flexBasis: '200px',
        flexGrow: '1',
        border: '1px solid #eee',
        margin: '1%',
        height: '100px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textTransform: 'uppercase',
        borderRadious: '5px',
        background: 'white',
        borderRadius: '5px',
        color: 'black !important',

        '&:hover':{
            backgroundColor: '#fcfcfc',
            boxShadow: "0px 2px 1px #c7c7c7",
            marginTop: "7px",
            marginBottom: "10px"
        } 
    },



    btnIcon: {
        fontSize: "48px",
        paddingRight: "20px",
        color: "#D80026"

    },

    btnDisabled: {
        color: '#eee !important',
        pointerEvents: "none",
        cursor: "default"
    },

    paper: {
        padding: '30px',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom: '50px'

    },
    title: {
        fontFamily: "Comfortaa",
        color: 'black',
        fontSize: '1.5em',
        textTransform: 'uppercase'

    }

});




class Home extends Component {
    state = {
        spinner: true
    }

    timer;
    componentDidMount() {
        this.timer = setTimeout(() => {
            this.setState({ spinner: false })
        }, 3000);
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    render() {

        if (this.props.loggedIn === true) {
            return (

                <userContext.Consumer>
                    {(user) => (user.access >= 1 ? <>

                        <div className={this.props.classes.root}>
                            <Container maxWidth="md">
                                <Grid container spacing={6}>
                                    <Grid item md={12} >
                                        <Paper className={this.props.classes.paper}>

                                            <h2 className={this.props.classes.title} >Sales & Logistics</h2>
                                            <div className={this.props.classes.mainNav}>

                                                <Link to="/salespipeline" className={`${this.props.classes.homeBtn}`} >
                                                    <ContactMailIcon className={this.props.classes.btnIcon} /> Sales Pipeline
                                                </Link>

                                                <Link to="/orders" className={`${this.props.classes.homeBtn}`} >
                                                    <LocalShippingIcon className={this.props.classes.btnIcon} />Active Orders
                                                </Link>



                                                <Link to="/customers" className={`${this.props.classes.homeBtn} `} >
                                                    <HowToRegSharpIcon className={this.props.classes.btnIcon} /> Customers
                                                </Link>


                                            </div>
                                        </Paper>

                                        <Paper className={this.props.classes.paper}>

                                            <h2 className={this.props.classes.title} >Quotes</h2>
                                            <div className={this.props.classes.mainNav}>


                                                <Link to="/quotes" className={`${this.props.classes.homeBtn}`} style={{flexBasis: "100%"}} >
                                                    < ReceiptIcon className={this.props.classes.btnIcon} /> Price Quotes
                                                </Link>

                                                <Link to="/flexi-quote" className={this.props.classes.homeBtn}>
                                                    <img src={FlexiQuoteIcon} alt="FlexiQuoteIcon" style={{ width: 'auto', height: '80px' }} /><span >Create Flexi Quote</span>
                                                </Link>

                                                <Link to="/steel-doors" className={`${this.props.classes.homeBtn} `} >
                                                    <img src={SteelDoorIco} alt="SteelDoorIco" style={{ width: 'auto', height: '80px' }} /> Create Steel Doors Quote
                                                </Link>
                                                {user.access >= 5 &&
                                                    <Link to="/steel-framed" className={`${this.props.classes.homeBtn} `} >
                                                        <img src={CritDoorIco} alt="CritDoorIco" style={{ width: 'auto', height: '80px' }} /> Create Crittal Doors Quote
                                                    </Link>
                                                }




                                            </div>
                                        </Paper>





                                        <Paper className={this.props.classes.paper}>


                                            <h2 className={this.props.classes.title}>Archive</h2>
                                            <div className={this.props.classes.mainNav}>

                                                <Link to="/closed-orders" className={`${this.props.classes.homeBtn}`} style={{flexBasis: "100%"}} >
                                                    Closed Orders
                                                </Link>

                                                <Link to="/advanced-search" className={`${this.props.classes.homeBtn}`} >
                                                    Advanced Search
                                                </Link>
                                                <Link to="/wondeals" className={this.props.classes.homeBtn}>
                                                    <span >Won Deals Archive</span>
                                                </Link>

                                                <Link to="/lostdeals" className={`${this.props.classes.homeBtn} `} >
                                                    Lost Deals Archive
                                                </Link>

                                            </div>
                                        </Paper>
                                        <Paper className={this.props.classes.paper}>

                                            <h2 className={this.props.classes.title}>Finance</h2>

                                            <div className={this.props.classes.mainNav}>

                                                <Link to="/costs" 
                                                className={user.access >= 6 ? `${this.props.classes.homeBtn}` : `${this.props.classes.homeBtn} ${this.props.classes.btnDisabled} `}
                                                style={{flexBasis: "47.7%", flexGrow:"0", }}
                                                 >
                                                    <EuroSymbolIcon className={this.props.classes.btnIcon} /> Operating Costs
                                                </Link>



                                                <Link to="/" 
                                                className={user.access >= 10 ? `${this.props.classes.homeBtn}` : `${this.props.classes.homeBtn} ${this.props.classes.btnDisabled} `} 
                                                style={{flexBasis: "47.7%", flexGrow:"0"}}
                                                >
                                                    <EuroSymbolIcon className={this.props.classes.btnIcon} /> Invoices
                                                </Link>




                                                <Link to="/monthly-report" className={user.access >= 6 ? `${this.props.classes.homeBtn}` : `${this.props.classes.homeBtn} ${this.props.classes.btnDisabled} `} >
                                                    <EuroSymbolIcon className={this.props.classes.btnIcon} /> Monthly reports
                                                </Link>



                                                <Link to="/yearly-report" className={user.access >= 6 ? `${this.props.classes.homeBtn}` : `${this.props.classes.homeBtn} ${this.props.classes.btnDisabled} `} >
                                                    <EuroSymbolIcon className={this.props.classes.btnIcon} /> Yearly reports
                                                </Link>



                                            </div>

                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Container>

                        </div>
                    </> : <> {this.state.spinner ? <CircularProgress /> : <span>Contact administrator</span>}  </>
                    )}</userContext.Consumer>
            );
        } else {
            return <Redirect to='/' />
        }
    }
}
export default withStyles(styles)(Home);
Home.contextType = userContext;