import React from 'react';
import Button from '@material-ui/core//Button';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import Print from '@material-ui/icons/Print';
import Right from '@material-ui/icons/ChevronRight';
import Left from '@material-ui/icons/ChevronLeft';
import Save from '@material-ui/icons/Save';


const styles = {
    stepperNav: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      

        '@media print':{
          display: 'none',
        }
    },
   
}

const StepperNav = props =>(   
     
    <div className={props.classes.stepperNav}>
          {props.activeStep === props.steps.length ? (
            <div>
              <Button
                    disabled={props.activeStep === 0}
                    onClick={props.handleBack}
                    color="primary"
                    variant="outlined"
                  >
                    <Left/>
              </Button>
              <Button onClick={props.createOrderNumbeAndPushToSave} variant="outlined">
                    <Save/>Save  
              </Button>
              
            </div>
          ) : (
            <div>
              {/* <Typography className={props.classes.instructions}>{props.steps}</Typography> */}
              <div>
                  <Button
                    disabled={props.activeStep === 0}
                    onClick={props.handleBack}
                    color="primary"
                    variant="outlined"
                  >
                    <Left/>
                  </Button>
                  {props.activeStep !== props.steps.length &&
                    <Button  
                      color="primary" onClick={props.handleNext} variant="outlined">
                      <Right/>
                    </Button>
                  }
                {props.activeStep === props.steps.length - 1 && 
              

                  <Button 
                    variant="outlined" 
                    color="secondary" 
                    aria-label="edit" 
                    className={props.classes.button}
                    onClick={window.print}
                    >
                    <Icon>
                      <Print/>
                    </Icon>
                  </Button>
                }

              </div>
            </div>
          )}
        </div>
      
)
export default withStyles (styles)(StepperNav);