import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

//-Door leafs

import ActiveLeaf from '../SteelDoor/Drawings/ActiveLeaf';
import NonActiveLeaf from '../SteelDoor/Drawings/NonActiveLeaf';

import Grid from '@material-ui/core/Grid';
import Threshold from '../SteelDoor/Drawings/threshold/threshold';
import Frame from '../SteelDoor/Drawings/frame/frame';

import SideAndSwing from '../SteelDoor/Drawings/sideAndSwing/sideAndSwing';


const styles = theme => ({
    arrowLeft:{
        width: 0,
        height: 0, 
        borderTop: '3px solid transparent',
        borderBottom: '3px solid transparent',
        borderRight: '10px solid #d2d2d2',
        float: 'left',
        marginTop: '-1px', 
    },
    pagebreak: { pageBreakBefore: 'always' },
    arrowRight:{
        width: 0,
        height: 0, 
        borderTop: '3px solid transparent',
        borderBottom: '3px solid transparent',
        borderLeft: '10px solid #d2d2d2',
        float: 'right',
        marginTop: '-1px',
    } ,
    arrowTop:{
        width: 0,
        height: 0, 
        position: 'absolute',
        borderRight: '3px solid transparent',
        borderLeft: '3px solid transparent',
        borderBottom: '10px solid #d2d2d2',
        top: '0px',
        left: '-3px',
    }, 
    arrowBottom:{
        width: 0,
        height: 0, 
        position: 'absolute',
        borderLeft: '3px solid transparent',
        borderRight: '3px solid transparent',
        borderTop: '10px solid #d2d2d2',
        bottom: '0px',
        left: '-3px',
    },
    diag: {
        width: '100%',
        height: '100%',
        background: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%'><line x1='0' y1='0' x2='100%' y2='50%' stroke='#dedede' stroke-width='1' stroke-dasharray='5,5'  /><line x1='0' y1='100%' x2='100%' y2='50%' stroke='#dedede' stroke-width='1' stroke-dasharray='5,5'  /></svg>") no-repeat`
    } ,
   

    
   
});

class Frame1 extends Component{

    state = {
        mainLeaf : '', 
        secondLeaf: '',
        handleSide: 'left',
        doorSide: 'left',
        restSide: 'right',
        boxHeight: '',
        rotate: 'rotate(0deg)',
        rotateText: 'rotate(0deg) translate(-50%)',
        topHandleSide: 'left',
        topDoorSide: 'left',
        topRestSide: 'right',
    }

    boxHeight = () =>{

        if(this.props.singleDouble === "Double"){
         
           
            if(Number(this.state.mainLeaf) > (Number(this.props.width)-Number(this.state.mainLeaf))){

                
                this.setState({
                    boxHeight: this.state.mainLeaf
                })
            }else{
                let height = Number(this.props.width)-Number(this.state.mainLeaf);
                this.setState({
                    boxHeight: height
                })
            }
        }else{
            this.setState({
                boxHeight: this.props.width
            })

        }
    }


    adjustment(){
        if(this.props.swingDirection ==="Inswing"){
            if(this.props.singleDouble === "Double"){

                let secondDoorwidth = Number(this.props.width) - this.props.doubleDoorMainLeafwidth;

                if(this.props.side === "Right Hand"){
                    this.setState({
                        handleSide: 'left',
                        doorSide: 'right',
                        restSide: 'left',
                        mainLeaf: this.props.doubleDoorMainLeafwidth,
                        secondLeaf: secondDoorwidth,
                        rotate: 'rotate(0deg)',
                        rotateText: 'rotate(0deg) translate(-50%)',
                        topHandleSide: 'right',
                        topDoorSide: 'right',
                        topRestSide: 'left',
                    },()=>{this.boxHeight()})
                }else{
                    this.setState({
                        handleSide: 'right',
                        doorSide: 'left',
                        restSide: 'right',
                        mainLeaf: this.props.doubleDoorMainLeafwidth,
                        secondLeaf: secondDoorwidth,
                        rotate: 'rotate(0deg)',
                        rotateText: 'rotate(0deg) translate(-50%)',
                        topHandleSide: 'left',
                        topDoorSide: 'left',
                        topRestSide: 'right',
                    },()=>{this.boxHeight()})
                }
            }else{
                let mainDoorwidth = Number(this.props.width) - (this.props.clearOpeningWidth*0.7);

                if(this.props.side === "Right Hand"){
                   
                    this.setState({
                        handleSide: 'left',
                        doorSide: 'right',
                        mainLeaf: mainDoorwidth,
                        boxHeight: this.props.width,
                        rotate: 'rotate(0deg)',
                        rotateText: 'rotate(0deg) translate(-50%)',
                        topHandleSide: 'right',
                        topDoorSide: 'right',
                        topRestSide: 'left',
                    })
                }else{
                    this.setState({
                        handleSide: 'right',
                        doorSide: 'left',
                        mainLeaf: mainDoorwidth,
                        boxHeight : this.props.width,
                        rotate: 'rotate(0deg)',
                        rotateText: 'rotate(0deg) translate(-50%)',
                        topHandleSide: 'left',
                        topDoorSide: 'left',
                        topRestSide: 'right',
                    })
                }
            }
        }else{
            if(this.props.singleDouble === "Double"){
                let secondDoorwidth = Number(this.props.width) - this.props.doubleDoorMainLeafwidth;
                if(this.props.side === "Right Hand"){
                    this.setState({
                        handleSide: 'left',
                        doorSide: 'right',
                        restSide: 'left',
                        mainLeaf: this.props.doubleDoorMainLeafwidth,
                        secondLeaf: secondDoorwidth,
                        rotate: 'rotate(180deg)',
                        rotateText: `rotate(180deg) translate(50%)`,
                        topHandleSide: 'left',
                        topDoorSide: 'left',
                        topRestSide: 'right',
                    
                    },()=>{this.boxHeight()})
                }else{
                    this.setState({
                        handleSide: 'right',
                        doorSide: 'left',
                        restSide: 'right',
                        mainLeaf: this.props.doubleDoorMainLeafwidth,
                        secondLeaf: secondDoorwidth,
                        rotate: 'rotate(180deg)',
                        rotateText: `rotate(180deg) translate(50%)`,
                        topHandleSide: 'right',
                        topDoorSide: 'right',
                        topRestSide: 'left',
                    },()=>{this.boxHeight()})
                }
            }else{
                let mainDoorwidth = Number(this.props.width) - (this.props.clearOpeningWidth*0.7);

                if(this.props.side === "Right Hand"){
            
                    this.setState({
                        handleSide: 'left',
                        doorSide: 'right',
                        mainLeaf: mainDoorwidth,
                        boxHeight: this.props.width,
                        rotate: 'rotate(180deg)',
                        rotateText: `rotate(180deg) translate(50%)`,
                        topHandleSide: 'left',
                        topDoorSide: 'left',
                        topRestSide: 'right',
                    })
                }else{
                    this.setState({
                        handleSide: 'right',
                        doorSide: 'left',
                        mainLeaf: mainDoorwidth,
                        boxHeight : this.props.width,
                        rotate: 'rotate(180deg)',
                        rotateText: `rotate(180deg) translate(50%)`,
                        topHandleSide: 'right',
                        topDoorSide: 'right',
                        topRestSide: 'left',
                    })
                }
            }

        }
    }

    insideOutside (side){
        if(side===true){
            return('#000');
        }else{
            return('#eee');
        }
    }


    componentDidMount(){
        
        if(this.props.singleDouble === "Double"){

            this.setState({
                mainLeaf: this.props.doubleDoorMainLeafwidth
            })
        }else{
            this.setState({
                mainLeaf: this.props.width
            })
        }
        this.adjustment();
 
    }

    componentDidUpdate( prevProps, prevState) {
        if(prevProps !== this.props){  
            this.adjustment();
        } 
    }
    render(){
        const { classes } = this.props;
        return(

             <div style={{position: 'relative', width: '100%'}}>

                    <Grid container  spacing={0} >
                        <Grid item  xs={9} style={{position: 'relative'}}>

                            <SideAndSwing side={this.props.side} swingDirection={this.props.swingDirection} ></SideAndSwing>
                            <div 
                            id='topMesurements1'
                            style={{
                                    width: `${(Number(this.props.width)+Number(this.props.clearOpeningWidth+70))/this.props.printRatio + 6}px`,
                                    height: '60px',
                                    borderLeft: '1px solid #eee',
                                    borderRight: '1px solid #eee',
                                    
                                    margin: '20px auto 0',
                                    }}>
                                    <div style={{
                                            height: `1px`, 
                                            width: `${(Number(this.props.width)+Number(this.props.clearOpeningWidth +70))/this.props.printRatio +6}px`, 
                                            margin: '0px auto',
                                            borderBottom: '1px solid #eee',
                                            }}> 
                                            <div className={classes.arrowLeft}></div>
                                            <div className={classes.arrowRight}></div>
                                    </div>
                                    <small style={{margin: '-8px auto 0', display: 'table' , backgroundColor:'white' }}>Frame - {Number(this.props.width) + this.props.clearOpeningWidth + 80}mm</small>                                           
                            </div>

                            <div 
                            id='topMesurements2'
                            style={{
                                    width: `${(Number(this.props.width)+Number(this.props.clearOpeningWidth))/this.props.printRatio}px`,
                                    height: '40px',
                                    borderLeft: '1px solid #eee',
                                    borderRight: '1px solid #eee',
                                    
                                    margin: '-40px auto 0',
                                    }}>
                                    <div style={{
                                            height: `1px`, 
                                            width: `${(Number(this.props.width)+Number(this.props.clearOpeningWidth))/this.props.printRatio}px`, 
                                            margin: '0 auto',
                                            borderBottom: '1px solid #eee',
                                            }}>
                                            
                                            <div className={classes.arrowLeft}></div>
                                            <div className={classes.arrowRight}></div>
                                    </div>
                                    <small style={{margin: '-8px auto', display: 'table', backgroundColor: 'white'}}>Structural<br/> opening - {Number(this.props.width)+this.props.clearOpeningWidth + 20}mm</small>

                            </div>

                            <div id="doorAndFrame" style={{height: `${(Number(this.props.height)+Number(this.props.clearOpeningHeight)+60)/this.props.printRatio}px`, width: `${(Number(this.props.width)+Number(this.props.clearOpeningWidth)+120)/this.props.printRatio}px`, border: '0.001rem solid black', position: 'relative',margin: '0px auto 25px', zIndex: '0'}}>

                            <div 
                                id='sideMesurements1'
                                style={{
                                    position: 'relative',
                                    height: `${(Number(this.props.height)+Number(this.props.clearOpeningHeight))/this.props.printRatio }px`, 
                                    width: '30px', 
                                    borderTop: '1px solid #eee',
                                    borderBottom: '1px solid #eee',
                                    left: '-30px',
                                    top: `${(this.props.clearOpeningHeight+30)/this.props.printRatio}px`
                                    }}>

                                    <div style={{
                                        borderLeft: '1px solid #eee',
                                        height: '100%',
                                        widyh: '1px',
                                        position: 'relative',
                                        left: 0,
                                    }}>
                                        <div className={classes.arrowTop}></div>
                                        <div className={classes.arrowBottom}></div>
                                        <small style={{'left':'-85px',background: 'white', width: '170px',top: '50%', transform: 'rotate(270deg)',   position: 'absolute' }}>Structural opening - {Number(this.props.height) + Number(this.props.clearOpeningHeight) + Number(this.props.threshold) + 10}mm</small>
                                    </div>
                            </div>

                            <div 
                                id='sideMesurements2'
                                style={{
                                    height: `${(Number(this.props.height)+Number(this.props.clearOpeningHeight)+30)/this.props.printRatio +6}px`, 
                                    width: '60px', 
                                    borderTop: '1px solid #eee',
                                    borderBottom: '1px solid #eee',
                                    position: 'absolute',
                                    left: '-60px',
                                    top: '-1px'
                                    }}>

                                    <div style={{
                                        borderLeft: '1px solid #eee',
                                        height: '100%',
                                        widyh: '1px',
                                        position: 'relative',
                                        left: 0,
                                    }}>
                                        <div className={classes.arrowTop}></div>
                                        <div className={classes.arrowBottom}></div>
                                        <small style={{ background: 'white', width: '110px',left: '-55px', top: '50%', transform: 'rotate(270deg)',   position: 'absolute' }}>Frame - {Number(this.props.height)+ Number(this.props.clearOpeningHeight) + Number(this.props.threshold)+40}mm</small>

                                    </div>
                            </div>
       
            

                            { this.props.additional.map((opt,index)=>{
                                return ( (opt.label.includes('DC340 with integrated mechanical coordinator') || opt.label.includes('DC500 with integrated mechanical coordinator'))&&
                                    <div key={index} id="rkzrail" style={{ height: '2px',  width: 'calc(100% - 55px)', border: `1px solid ${this.insideOutside(opt.inside)}` , position: 'absolute', margin: '0 auto',left: '27px', top: `${24/this.props.printRatio}px`}}></div> 
                                )
                            })}
                       
                    
                           {/* ------------------------- Clear opening */}

                                <div id="clearOpening" style={{
                                    height: `${((this.props.height )/this.props.printRatio)-3}px`, 
                                    width: `${(this.props.width )/this.props.printRatio}px`, 
                                    border: '1px solid #dedcdc', 
                                    position: 'absolute',
                                    margin: '0 auto',
                                    left: `${((this.props.clearOpeningWidth/2)+60)/this.props.printRatio}px`,
                                    top: `${((this.props.clearOpeningHeight+60)/this.props.printRatio)-1}px`,
                                    background: 'none',
                                    }}>
                                    
                                </div>

                                

                             {/* ------------------------- Single door  */}

                            <ActiveLeaf 
                                additional={this.props.additional}
                                height={this.props.height }
                                width={this.props.width}
                                printRatio={this.props.printRatio}
                                clearOpeningHeight={this.props.clearOpeningHeight}
                                clearOpeningWidth ={this.props.clearOpeningWidth}
                                mainLeaf={this.state.mainLeaf}
                                doubleDoorMainLeafwidth={this.props.doubleDoorMainLeafwidth}
                                doorSide={this.state.doorSide}
                                handleSide={this.state.handleSide}
                                singleDouble={this.props.singleDouble}
                                visionPanel={this.props.visionPanel}
                                visionPanelRestrictions={this.props.visionPanelRestrictions}
                                visionPanels={this.props.visionPanels}
                                maxVPWidth2={this.props.maxVPWidth2}
                                maxVPWidth={this.props.maxVPWidth}
                                maxVPHeight={this.props.maxVPHeight}
                                fire={this.props.fire}
                                grade={this.props.grade}
                                ventilationList={this.props.ventilationList}
                                // handlePanelPosition={this.props.handlePanelPosition}
                                ventilation={this.props.ventilation}
                                // handleVentPosition={this.props.handleVentPosition}
                                doorType={this.props.doorType}
                                frameType={this.props.frameType}
                                swingDirection={this.props.swingDirection}
                            ></ActiveLeaf>

                           
 
                           {/* -------------------------  Double door */}


                            { this.props.singleDouble === "Double" &&

                            <NonActiveLeaf 
                                frameType={this.props.frameType}
                                additional={this.props.additional}
                                height={this.props.height }
                                width={this.props.width}
                                printRatio={this.props.printRatio}
                                clearOpeningHeight={this.props.clearOpeningHeight}
                                clearOpeningWidth ={this.props.clearOpeningWidth}
                                mainLeaf={this.state.mainLeaf}
                                restSide={this.state.restSide}
                                doubleDoorMainLeafwidth={this.props.doubleDoorMainLeafwidth}
                                doorSide={this.state.doorSide}
                                handleSide={this.state.handleSide}
                                singleDouble={this.props.singleDouble}
                                secondLeaf={this.state.secondLeaf}
                                fire={this.props.fire}
                                grade={this.props.grade}
                                visionPanel={this.props.visionPanel}
                                visionPanelRestrictions={this.props.visionPanelRestrictions}
                                visionPanels={this.props.visionPanels}
                                maxVPWidth2={this.props.maxVPWidth2}
                                maxVPWidth={this.props.maxVPWidth}
                                maxVPHeight={this.props.maxVPHeight}
                                ventilationList={this.props.ventilationList}
                                // handlePanelPosition={this.props.handlePanelPosition}
                                ventilation={this.props.ventilation}
                                // handleVentPosition={this.props.handleVentPosition}
                                swingDirection={this.props.swingDirection}
                                doorType={this.props.doorType}
                                

                            ></NonActiveLeaf>
                                    
                            }
                            


                            {/* ------------------------- Threshold */}


                                <div 
                                id="treshold"
                                style={{
                                    height: `${this.props.threshold/this.props.printRatio}px`, 
                                    width: `${(this.props.width )/this.props.printRatio}px`, 
                                    border: '1px solid #dedcdc', 
                                    position: 'absolute',
                                    margin: '0 auto',
                                    left: `${((this.props.clearOpeningWidth/2)+60)/this.props.printRatio}px`,
                                    bottom: `2px`
                                    }}>
                                </div>


                                
                            </div>
                        </Grid>



                        <Grid item  xs={3} style={{borderLeft: '1px solid #eee'}}>

                                <Threshold 
                                    printRatio={this.props.printRatio}
                                    height={this.props.height}
                                    fire={this.props.fire}
                                    threshold={this.props.threshold}
                                    frameType={this.props.frameType}
                                    waFrame={this.props.waFrame}
                                ></Threshold>

                                <Frame 
                                    waFrame={this.props.waFrame}
                                    printRatio={this.props.printRatio} 
                                    frameType={this.props.frameType
                                }></Frame>
                        </Grid>
                    </Grid>


                    {/* {Number(this.props.width-this.props.clearOpeningWidth-this.props.handleWidth) > 899 && 
                        <Avatar className={classes.avatar}>
                            <Accessible/>
                        </Avatar>
                    } */}
        </div>

        )
    }

}
export default withStyles(styles)(Frame1);