import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Paper, Container, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { userContext } from '../Context/userContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import CritDoorIco from '../img/critDoorIco.svg'



const styles = theme => ({
    root: {
        //flexGrow: 1,
        margin: '50px auto',
        width: '90%',
    },
    mainNav: {
        display: 'flex',
        // padding: '30px 0',

        flexWrap: 'wrap',

    },

    homeBtn: {
        flexBasis: '200px',
        flexGrow: '1',
        border: '1px solid #eee',
        margin: '1%',
        height: '100px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textTransform: 'uppercase',
        borderRadious: '5px',
        background: 'white',
        borderRadius: '5px',
        color: 'black !important',

        '&:hover': {
            backgroundColor: '#fcfcfc',
            boxShadow: "0px 2px 1px #c7c7c7",
            marginTop: "7px",
            marginBottom: "10px"
        }
    },



    btnIcon: {
        fontSize: "48px",
        paddingRight: "20px",
        color: "#D80026"

    },

    btnDisabled: {
        color: '#eee !important'
    },

    paper: {
        padding: '30px',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom: '50px'

    },
    title: {
        fontFamily: "Comfortaa",
        color: 'black',
        fontSize: '20px',

    }

});




class SteelFramedPage extends Component {
    state = {
        spinner: true
    }

    timer;

    componentDidMount() {

        this.timer = setTimeout(() => {
            this.setState({ spinner: false })
        }, 3000);
    }




    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    render() {

        if (this.props.loggedIn === true) {
            return (

                <userContext.Consumer>
                    {(user) => (user.access >= 1 ? <>

                        <Container maxWidth="md" className={this.props.classes.root}>

                            <Grid container spacing={6}>
                                <Grid item md={12} >
                                    <Paper className={this.props.classes.paper}>

                                        <h2 className={this.props.classes.title} >Crittal Doors</h2>
                                        <div className={this.props.classes.mainNav}>


                                            <Link to="/steel-framed-quote" className={`${this.props.classes.homeBtn} `} >
                                                <img src={CritDoorIco} alt="CritDoorIco" style={{ width: 'auto', height: '80px' }} /> Create quote
                                            </Link>



                                            <Link to="/steel-framed-admin" className={`${this.props.classes.homeBtn} `} >
                                                Admin
                                            </Link>

                                        </div>

                                    </Paper>

                                    {/* {
                                        user.access >= 10 &&

                                        <Paper className={this.props.classes.paper}>
                                            <div className={this.props.classes.mainNav}>

                                                <Link to="/steel-framed-quote" className={`${this.props.classes.homeBtn} `} >
                                                    New builder
                                                </Link>


                                                {user.access >= 11 &&
                                                    <Link to="/steel-framed-quote-creator" className={`${this.props.classes.homeBtn} `} >
                                                        <img src={CritDoorIco} alt="CritDoorIco" style={{ width: 'auto', height: '80px' }} /> Create Crittal Doors Quote
                                                    </Link>


                                                }

                                                <Link to="steel-framed-template-builder" className={this.props.classes.homeBtn}>
                                                    <img src={CritDoorIco} alt="CritDoorIco" style={{ width: 'auto', height: '80px' }} /> Crittal Doors Builder
                                                </Link>



                                                {user.access >= 6 &&
                                                    <Link to="steel-framed-templates" className={this.props.classes.homeBtn}>
                                                        <span >Create Quote</span>
                                                    </Link>
                                                }

                                            </div>
                                        </Paper>
                                    } */}


                                </Grid>
                            </Grid>

                        </Container>
                    </> : <> {this.state.spinner ? <CircularProgress /> : <span>Contact administrator</span>}  </>
                    )}</userContext.Consumer>
            );
        } else {
            return <Redirect to='/' />
        }
    }
}
export default withStyles(styles)(SteelFramedPage);
SteelFramedPage.contextType = userContext;