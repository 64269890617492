import {Group}  from 'three';
import createBox from './createBox';
const uniqid = require('uniqid');


const createPushPullHandle = (color, adjustment , door, profileWidth, profileDepth, side,  metalness, roughness, ironmongery) =>{

    if(ironmongery.color === "sameAsFrame"){
        color = color
    }else{
        color = ironmongery.color
    }

    const handleFront = new Group();
    const handleBack = new Group();
    const set = new Group();
    
    const handle1 = createBox(10,ironmongery.height,10,color, uniqid(), metalness+0.1,roughness+0.)
    const handle2 = createBox(10,30,10,color, uniqid(), metalness,roughness)
    const handle3 = createBox(10,30,10,color, uniqid(), metalness,roughness)
    const handle4 = createBox(10,ironmongery.height,10,color, uniqid(), metalness,roughness)
    const handle5 = createBox(10,30,10,color, uniqid(), metalness,roughness)
    const handle6 = createBox(10,30,10,color, uniqid(), metalness,roughness)

    handle2.rotation.x = -Math.PI * 0.5;
    handle3.rotation.x = -Math.PI * 0.5;
    handle2.position.y = ironmongery.height/2 -5
    handle3.position.y = -ironmongery.height/2 +5
    handle2.position.z = -20
    handle3.position.z = -20

    handle5.rotation.x = -Math.PI * 0.5;
    handle6.rotation.x = -Math.PI * 0.5;
    handle5.position.y = ironmongery.height/2 -5;
    handle6.position.y = -ironmongery.height/2 +5;
    handle5.position.z = 20;
    handle6.position.z = 20;

    handleFront.add( handle1, handle2, handle3)
    handleBack.add(handle4, handle5, handle6)

    handleBack.position.z =  - Number(profileDepth)-10
    handleFront.position.z =  Number(profileDepth)+10

    // handleBack.position.x = - Number(adjustment)  + profileWidth + 160
    // handleFront.position.x = - Number(adjustment)  + profileWidth + 160
    

    if(door === "mainDoor" ){
     

        if(side == "left" ){
            handleBack.position.x = - Number(adjustment)  +  profileWidth +60
            handleFront.position.x = - Number(adjustment)  + profileWidth +60
        }else{
            handleBack.position.x = Number(adjustment)  -  profileWidth - 60
            handleBack.rotation.z = Math.PI 
            handleFront.position.x = Number(adjustment)  -  profileWidth - 60
            handleFront.rotation.z = Math.PI 
        }
    }else{
       
        if(side == "left" ){
            handleBack.position.x =  Number(adjustment)  -  profileWidth - 60
            handleFront.position.x =  Number(adjustment)  - profileWidth - 60
        }else{
            handleBack.position.x = -Number(adjustment)  + profileWidth + 60
            handleBack.rotation.z = Math.PI 
            handleFront.position.x = -Number(adjustment)  +  profileWidth + 60
            handleFront.rotation.z = Math.PI 
        }
    }
    set.add(handleFront , handleBack)

    return( set)

}

export default createPushPullHandle

