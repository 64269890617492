import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import tresh20 from '../../../SteelDoor/Drawings/threshold/tresh_20.svg';
import tresh20Fire from '../../../SteelDoor/Drawings/threshold/tresh_20_fire.svg';
import treshDrop from '../../../SteelDoor/Drawings/threshold/tresh_drop.svg';
import treshDropFire from '../../../SteelDoor/Drawings/threshold/tresh_drop_fire.svg';

import Atresh20 from '../../../SteelDoor/Drawings/threshold/A_tresh_20.svg';
import Atresh20Fire from '../../../SteelDoor/Drawings/threshold/A_tresh_20_fire.svg';
import AtreshDrop from '../../../SteelDoor/Drawings/threshold/A_tresh_drop.svg';
import AtreshDropFire from '../../../SteelDoor/Drawings/threshold/A_tresh_drop_fire.svg';

import Atresh20_2 from '../../../SteelDoor/Drawings/threshold/A_tresh_20_2.svg';
import Atresh20Fire_2 from '../../../SteelDoor/Drawings/threshold/A_tresh_20_fire_2.svg';
import AtreshDrop_2 from '../../../SteelDoor/Drawings/threshold/A_tresh_drop_2.svg';
import AtreshDropFire_2 from '../../../SteelDoor/Drawings/threshold/A_tresh_drop_fire_2.svg';

const styles = theme => ({
    bottom: {

    }
})

class Threshold extends Component {

    state = {}


    render() {
        const { classes } = this.props;

        return (
            <Grid container justifyContent="center" spacing={0} style={{ borderBottom: '1px solid #eee', }}>

                <Grid item xs={12}>

            

                    <h6 style={{ marginBottom: '0px' }}>Top</h6>
                    {this.props.frameType === "type-2" ?
                        (this.props.threshold === "20" && this.props.fire === "Standard") ? (
                            <img src={tresh20} alt="bottomDrawing" className={classes.bottom} style={{ width: `${500 / this.props.printRatio}%`, height: 'auto' }} />
                        ) : (this.props.threshold === "0" && this.props.fire === "Standard") ? (
                            <img src={treshDrop} alt="bottomDrawing" className={classes.bottom} style={{ width: `${500 / this.props.printRatio}%`, height: 'auto' }} />
                        ) : (this.props.threshold === "20" && this.props.fire === "Fire") ? (
                            <img src={tresh20Fire} alt="bottomDrawing" className={classes.bottom} style={{ width: `${500 / this.props.printRatio}%`, height: 'auto' }} />
                        ) : (this.props.threshold === "0" && this.props.fire === "Fire") ? (
                            <img src={treshDropFire} alt="bottomDrawing" className={classes.bottom} style={{ width: `${500 / this.props.printRatio}%`, height: 'auto' }} />
                        ) : (<div></div>)

                        :

                        (this.props.threshold === "20" && this.props.fire === "Standard") ? (
                            this.props.waFrame ?
                                <img src={Atresh20_2} alt="bottomDrawing" className={classes.bottom} style={{ width: `${500 / this.props.printRatio}%`, height: 'auto' }} />
                                :
                                <img src={Atresh20} alt="bottomDrawing" className={classes.bottom} style={{ width: `${500 / this.props.printRatio}%`, height: 'auto' }} />

                        ) : (this.props.threshold === "0" && this.props.fire === "Standard") ? (
                            this.props.waFrame ?
                                <img src={AtreshDrop_2} alt="bottomDrawing" className={classes.bottom} style={{ width: `${500 / this.props.printRatio}%`, height: 'auto' }} />
                                :
                                <img src={AtreshDrop} alt="bottomDrawing" className={classes.bottom} style={{ width: `${500 / this.props.printRatio}%`, height: 'auto' }} />
                        ) : (this.props.threshold === "20" && this.props.fire === "Fire") ? (
                            this.props.waFrame ?
                                <img src={Atresh20Fire_2} alt="bottomDrawing" className={classes.bottom} style={{ width: `${500 / this.props.printRatio}%`, height: 'auto' }} />
                                :
                                <img src={Atresh20Fire} alt="bottomDrawing" className={classes.bottom} style={{ width: `${500 / this.props.printRatio}%`, height: 'auto' }} />
                        ) : (this.props.threshold === "0" && this.props.fire === "Fire") ? (
                            this.props.waFrame ?
                            <img src={AtreshDropFire_2} alt="bottomDrawing" className={classes.bottom} style={{ width: `${500 / this.props.printRatio}%`, height: 'auto' }} />
                            :
                            <img src={AtreshDropFire} alt="bottomDrawing" className={classes.bottom} style={{ width: `${500 / this.props.printRatio}%`, height: 'auto' }} />
                        ) : (<div></div>)

                    }
                    <h6 style={{ marginTop: '0px' }}>Bottom</h6>

                </Grid>

            </Grid>
        )
    }
}

export default withStyles(styles)(Threshold);