import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
// import * as firebase from 'firebase';
import firebase from "firebase/compat/app";
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/paper';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import { Redirect } from 'react-router';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { GetCustomerNameForThisOrder, TotalValue } from '../logic/GetData';
import CustomerDetails from '../Customers/CustomerDetails' 

const styles = theme => ({
    textField: {
        width: '100%',
    },
    root: {
        width: '96%',
        margin: '50px 2%',
        '@media print': {
            margin: '0',
            width: '100%',
        }
    },
    paper: {
        padding: '20px',
        '@media print': {
            'box-shadow': 'none !important',
            'margin': "0px",
            'padding': "0px",
        }
    },
})

class Customer extends Component {

    state = {
        companyName: '',
        contactName: '',
        contactEmail: '',
        phoneNumber: '',
        firstAddressLine: '',
        secondAddressLine: '',
        city: '',
        county: '',
        country: '',
        eircode: '',
        vat: '',
        edit: false,
        orders: [],

    }

    componentDidMount() {
        // set refference to firebase 

        const customerRef = firebase.database().ref(`Customers/${this.props.match.params.customerId}`);

        customerRef.on('value', snap => {

            let customer = snap.val();

            this.setState({
                ...customer,
            })
        })

        const ordersRef = firebase.database().ref('Orders');

        ordersRef.on('value', snap => {
            let orders = snap.val();
            let custOrders = [];
            if (orders !== null) {

                let allorders = Object.entries(orders).reverse();
                for (const [i, v] of allorders.entries()) {
                    if (v[1].Customer === this.props.match.params.customerId) {
                        custOrders.push(v)
                    }
                }

                this.setState({
                    orders: custOrders
                })

            }

        })


    }

    handleChange = (name) => event => {
        this.setState({
            [name]: event.target.value
        })
    }

    save = () => {
        const customerRef = firebase.database().ref(`Customers/${this.props.match.params.customerId}`);

        customerRef.update({
            companyName: this.state.companyName,
            contactName: this.state.contactName,
            contactSurname: this.state.contactSurname,
            contactEmail: this.state.contactEmail,
            phoneNumber: this.state.phoneNumber,
            firstAddressLine: this.state.firstAddressLine,
            secondAddressLine: this.state.secondAddressLine,
            city: this.state.city,
            county: this.state.county,
            country: this.state.country,
            eircode: this.state.eircode,
            vat: this.state.vat,
        })
        this.setState({ edit: false })
    }

    render() {

        if (this.props.loggedIn === true) {

            return (
                <div className={this.props.classes.root}>
                    <Grid container justify="center" spacing={6}>
                        <Grid item spacing={6} xs={12} >
                            <Paper className={this.props.classes.paper}>
                                <h2>Company: {this.state.companyName}</h2>
                            </Paper>
                        </Grid>
                        <Grid item spacing={6} sm={4} >
                            <Paper className={this.props.classes.paper}>
                                <TextField
                                    disabled={!this.state.edit}
                                    id="companyName"
                                    label="Company Name"
                                    type="text"
                                    className={this.props.classes.textField}
                                    value={this.state.companyName}
                                    onChange={this.handleChange('companyName')}
                                    margin="normal"
                                />
                                {/* ----------------------------------------------   Contact Name */}

                                <TextField
                                    disabled={!this.state.edit}
                                    id="contactName"
                                    label="Name"
                                    type="text"
                                    className={this.props.classes.textField}
                                    value={this.state.contactName}
                                    onChange={this.handleChange('contactName')}
                                    margin="normal"
                                />

                                {/* ----------------------------------------------   Contact Surname */}

                                <TextField
                                    disabled={!this.state.edit}
                                    id="contactSurname"
                                    label="Surname"
                                    type="text"
                                    className={this.props.classes.textField}
                                    value={this.state.contactSurname}
                                    onChange={this.handleChange('contactSurname')}
                                    margin="normal"


                                />

                                {/* ----------------------------------------------   Contact Email */}

                                <TextField
                                    disabled={!this.state.edit}
                                    id="contactEmail"
                                    label="Contact Email"
                                    type="email"
                                    className={this.props.classes.textField}
                                    value={this.state.contactEmail}
                                    onChange={this.handleChange('contactEmail')}
                                    margin="normal"
                                />

                                {/* ----------------------------------------------   Phone Number */}

                                <TextField
                                    disabled={!this.state.edit}
                                    id="phoneNumber"
                                    label="Phone Number"
                                    type="text"
                                    className={this.props.classes.textField}
                                    value={this.state.phoneNumber}
                                    onChange={this.handleChange('phoneNumber')}
                                    margin="normal"
                                />
                                {/* ----------------------------------------------   First Address Line */}

                                <TextField
                                    disabled={!this.state.edit}
                                    id="firstAddressLine"
                                    label="First Address Line"
                                    type="text"
                                    className={this.props.classes.textField}
                                    value={this.state.firstAddressLine}
                                    onChange={this.handleChange('firstAddressLine')}
                                    margin="normal"
                                />
                                {/* ----------------------------------------------   Second Address Line */}

                                <TextField
                                    disabled={!this.state.edit}
                                    id="secondAddressLine"
                                    label="Second Address Line"
                                    type="text"
                                    className={this.props.classes.textField}
                                    value={this.state.secondAddressLine}
                                    onChange={this.handleChange('secondAddressLine')}
                                    margin="normal"
                                />

                                {/* ----------------------------------------------   Second Address Line */}

                                <TextField
                                    disabled={!this.state.edit}
                                    id="city"
                                    label="City"
                                    type="text"
                                    className={this.props.classes.textField}
                                    value={this.state.city}
                                    onChange={this.handleChange('city')}
                                    margin="normal"
                                />

                                {/* ----------------------------------------------   County */}

                                <TextField
                                    disabled={!this.state.edit}
                                    id="county"
                                    label="county"
                                    type="text"
                                    className={this.props.classes.textField}
                                    value={this.state.county}
                                    onChange={this.handleChange('county')}
                                    margin="normal"
                                />

                                {/* ----------------------------------------------   County */}

                                <TextField
                                    disabled={!this.state.edit}
                                    id="country"
                                    label="country"
                                    type="text"
                                    className={this.props.classes.textField}
                                    value={this.state.country}
                                    onChange={this.handleChange('country')}
                                    margin="normal"
                                />
                                {/* ----------------------------------------------   Eircode */}

                                <TextField
                                    disabled={!this.state.edit}
                                    id="eircode"
                                    label="eircode"
                                    type="text"
                                    className={this.props.classes.textField}
                                    value={this.state.eircode}
                                    onChange={this.handleChange('eircode')}
                                    margin="normal"
                                />
                                {/* ----------------------------------------------   VAT */}

                                <TextField
                                    disabled={!this.state.edit}
                                    id="VAT"
                                    label="VAT"
                                    type="text"
                                    className={this.props.classes.textField}
                                    value={this.state.vat}
                                    onChange={this.handleChange('vat')}
                                    margin="normal"
                                />
                                {!this.state.edit && <EditIcon onClick={() => { this.setState({ edit: !this.state.edit }) }} />}
                                {this.state.edit && <Button variant="outlined" color="primary" onClick={this.save}>Save</Button>}
                            </Paper>
                        </Grid>
                        <Grid item spacing={6} sm={8} >
                            <Paper className={this.props.classes.paper}>
                                <h3>Orders</h3>
                            </Paper>
                            <Table className={this.props.classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell >Order no</TableCell>
                                        <TableCell >Order Reference</TableCell>
                                        <TableCell >Customer</TableCell>
                                        <TableCell >Order No</TableCell>
                                        <TableCell >Based on Quote no</TableCell>
                                        <TableCell >Deadline</TableCell>
                                        <TableCell >Order Value</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.orders.map((order) => {
                                        return (
                                            <TableRow key={order[0]}>
                                                <TableCell ><Link to={`/order/${order[0]}`}>{order[1].OrderNumber} </Link></TableCell>
                                                <TableCell >{order[1].Reference}</TableCell>
                                                <TableCell >{order[1].Customer}</TableCell>
                                                <TableCell >{order[1].OrderNumber}</TableCell>
                                                <TableCell >{order[1].Quote}</TableCell>
                                                <TableCell >{moment(order[1].DateApproved).add(order[1].LeadTime, 'week').format('L')}</TableCell>
                                                <TableCell >{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format(TotalValue(order[0]))}{ }</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>


                        </Grid>

                    </Grid>
                </div>
            )
        } else {
            return <Redirect to='/' />
        }
    }

};

export default withStyles(styles)(Customer);