import React, { Component } from 'react';

class Hinges extends Component{
    state= {}

    fourthHinge = ()=>{
        if(this.props.grade === "RC4" && this.props.fire === "Fire"){
            return( true )
        }else if( this.props.grade === "RC5"){
            return( true )
        }else if( this.props.grade === "Class ||"){
            return( true )
        }else{
            return( false )
        }
    }

    thirdHinge = () =>{
        if(this.props.grade === "RC4"){
            return( true )
        }else if( this.props.grade === "RC3"){
            return( true )
        }else if( this.props.grade === "RC5"){
            return( true )
        }else if( this.props.grade === "Class ||"){
            return( true )
        }else if( this.props.grade === "RC2"){
            return( false )
        }else{
            return( false )
        }
    }

    render() { 
        return (
            <div>
                <div style={{ height: `${100/this.props.printRatio}px`,  width: `${25/this.props.printRatio}px`, border: '1px solid #000', position: 'absolute', margin: '0 auto',[this.props.side]: `${-20/this.props.printRatio}px`, bottom: '12%',backgroundColor: 'white'}}>
                    <div style={{ height: '50%',  width: '100%', borderBottom: '1px solid #000'}}></div>
                </div>
                {this.fourthHinge() &&
                    <div style={{ height: `${100/this.props.printRatio}px`,  width: `${25/this.props.printRatio}px`, border: '1px solid #000', position: 'absolute', margin: '0 auto',[this.props.side]: `${-20/this.props.printRatio}px`, bottom: '30%',backgroundColor: 'white'}}>
                        <div style={{ height: '50%',  width: '100%', borderBottom: '1px solid #000'}}></div>
                    </div>
                }
                
                <div style={{ height: `${100/this.props.printRatio}px`,  width: `${25/this.props.printRatio}px`, border: '1px solid #000', position: 'absolute', margin: '0 auto',[this.props.side]: `${-20/this.props.printRatio}px`, top: '12%',backgroundColor: 'white'}}>
                    <div style={{ height: '50%',  width: '100%', borderBottom: '1px solid #000'}}></div>
                </div>
                {this.thirdHinge() &&
                <div style={{ height: `${100/this.props.printRatio}px`,  width: `${25/this.props.printRatio}px`, border: '1px solid #000', position: 'absolute', margin: '0 auto',[this.props.side]: `${-20/this.props.printRatio}px`, top: '30%',backgroundColor: 'white'}}>
                    <div style={{ height: '50%',  width: '100%', borderBottom: '1px solid #000'}}></div>
                </div>
                }
            </div>  
        )
    }
}
export default Hinges;