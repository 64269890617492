import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import EditManu from '../partials/EditMenu';
// import * as firebase from 'firebase';
import firebase from "firebase/compat/app";
import moment from 'moment';

const styles = theme => ({
    paper:{
        margin: '2%',
        overflowX: 'scroll'
    },
  table: {
    minWidth: 700,
  },
});

class IncomeTable extends Component{

    state = {
        incomes: [],
    }
    componentDidMount(){
        // set refference to firebase 
        const ordersRef = firebase.database().ref(`Orders/${this.props.orderId}/Income`);
        
        //load all orderes
        ordersRef.on('value',snap =>{
            
            let incomeFB = snap.val();

            if(incomeFB !== null){
                let output = Object.entries(incomeFB).map(([key, value]) => ({key,value}));

                this.setState({ 
                    incomes: output , 
                });
  
            }else{
                this.setState({
                    incomes: []
                })
            }
        })

    }
    handleChange = (name, key )=> event => {
        // this.setState({ [name]: event.target.checked });
        // console.log(key);

        

        const updateRef = firebase.database().ref(`Orders/${this.props.orderId}/Income/${key}`);
        
        updateRef.update({
            [name]: event.target.checked,
            registered: moment().format('L')
        })

    };

    render() {
        return (
            
            <Paper className={this.props.classes.paper}  elevation={0} variant="outlined">
            <Table className={this.props.classes.table} >
                <TableHead>
                <TableRow>
                    <TableCell>No</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Vat</TableCell>
                    <TableCell>CN No</TableCell>
                    <TableCell>CN Amount</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Invoice Paid</TableCell>
                    <TableCell></TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                
                {this.state.incomes.map((income)=>{ 


                    //CN added in dec 2018 - check if values exist
                    if(!income.value.cnValue){
                        income.value.cnValue = 0;
                    }

                    if(!income.value.cN){
                        income.value.cN = "";
                    }

                        return (
                            <TableRow key={income.key}>
                                <TableCell>{income.value.invoiceNo}</TableCell> 
                                <TableCell>{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format(income.value.Amount)}</TableCell>
                                <TableCell>{income.value.vat}</TableCell> 
                                <TableCell>{income.value.cN}</TableCell>
                                <TableCell>{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format(income.value.cnValue)}</TableCell>

                                
                              
                                <TableCell>{income.value.date}</TableCell>
                                <TableCell>
                                {income.value.registered}
                                    <Checkbox
                                        label={income.value.registered}
                                        disabled={income.value.paid}
                                        checked={income.value.paid}
                                        onChange={this.handleChange('paid', income.key)}
                                        value="paid"
                                        />
                                </TableCell>
                                <TableCell>
                                    <EditManu 
                                            mainGroup="Orders"
                                            parentType='Income'
                                            orderId={this.props.orderId}
                                            recordKey={income.key}
                                            update={[
                                                { type:'text', id:'invoiceNo', label: 'Invoice No', value: income.value.invoiceNo},
                                                { type:'text', id:'Amount', label: 'Amount ', value: income.value.Amount},
                                                { type:'date', id:'date', label: 'Date ', value: income.value.date},
                                                { type: 'text', id:'cN', label: 'Credit Note No', value: income.value.cN},
                                                { type: 'text', id:'cnValue', label: 'Credit Note Value', value: income.value.cnValue},
                                                { type:'select', id:'vat', label: 'VAT rate ', value: income.value.vat, options: [{ value: '23',label: '23%'},{ value: '21',label: '21%'},{value: '135',label: '13.5%'},{value: '0',label: '0%',}]},
                                            ]}
                                    ></EditManu>
                                </TableCell>
                                
                            </TableRow>
                        );
                    })}
                
                </TableBody>
            </Table>
            </Paper>
        );
    }
    }
IncomeTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(IncomeTable);