import React, { Component } from 'react';

class PrisonSlideLatch extends Component{

    state= {}
    render() {
        
        return (     
            <div style={{ height: `100%`,  width: `100%`, position: 'absolute', margin: '0 auto',[this.props.handleSide]: `${50/this.props.printRatio}px`, top: '0%',}}>

                <div style={{ 
                    height: `${40/this.props.printRatio}px`, 
                    width: `${80/this.props.printRatio}px`, 
                    border: '1px solid black', 
                    margin: '0% auto', 
                    top: '20%', 
                    'borderRadius': `${10/this.props.printRatio}px`, 
                    [this.props.side]: `${50/this.props.printRatio}px`,
                    position: 'absolute',
                }}>
                    <div style={{ height: `${40/this.props.printRatio}px`, width: `${10/this.props.printRatio}px`, border: '1px solid black', margin: '-1px auto 0', 'borderRadius': `${10/this.props.printRatio}px`}}></div>
                </div>


                <div style={{ 
                    height: `${40/this.props.printRatio}px`, 
                    width: `${80/this.props.printRatio}px`, 
                    border: '1px solid black', 
                    margin: '0% auto', 
                    bottom: '20%', 
                    'borderRadius': `${10/this.props.printRatio}px`, 
                    [this.props.side]: `${50/this.props.printRatio}px`,
                    position: 'absolute',
                }}>
                    <div style={{ height: `${40/this.props.printRatio}px`, width: `${10/this.props.printRatio}px`, border: '1px solid black', margin: '-1px auto 0', 'borderRadius': `${10/this.props.printRatio}px`}}></div>
                </div>

                

            </div>
       )
    }
}

export default PrisonSlideLatch;