import React, { Component } from 'react';

class Handle extends Component{

    state= {}

    color = () =>{
        if(typeof(this.props.inside) !== "undefined"){
            if(this.props.inside === true){
                return('#000');
            }else{
                return('#c5c3c3');
            }
        }else{
            return('#000');
        }       
    }

    render() {
      
        
        return (
            <div>
                <div style={{ height: `${65/this.props.printRatio}px`,  width: `${65/this.props.printRatio}px`, border: `1px solid ${this.color()}`, position: 'absolute',margin: '0 auto', 'borderRadius': '50%',[this.props.handleSide]: `${50/this.props.printRatio}px`,bottom: `${this.props.bottom/this.props.printRatio}px`,}}>
                    <div style={{height: `${20/this.props.printRatio}px`, width: `${175/this.props.printRatio}px`, border: `1px solid ${this.color()}`, position: 'absolute', margin: '0 auto', 'borderRadius': '4px', [this.props.handleSide] : `${20/this.props.printRatio}px`, bottom: '31%',}}></div>
                </div>
            </div>
       )
    }
}

export default Handle;