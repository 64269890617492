import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
// import * as firebase from 'firebase'
import firebase from "firebase/compat/app";

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Edit from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {userContext} from '../Context/userContext';

const styles = theme => ({
    root: {

      width: '50',
      margin: '50',
      display: 'inline',
    },
    dateremainder: {
      color: 'red'
    },
    textField: {
      padding: "20px"
    }
});


class EditForm extends Component {
  state = {
    open: false,
    reference: '',
    leadTime: '',
    closed: '',
    dateremainder: false,
    orderNumber:''

  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  componentDidUpdate(prevProps, prevState) {

    if(this.state.closed !== prevState.closed && prevState.closed === false){
      this.setState({dateremainder: true})
    }
  }

  handleUpdate = () => {

    const updateRef = firebase.database().ref(`Orders/${this.props.orderID}`);

      if(this.state.reference === ''){
          alert('Set Order Reference');
      }else if(this.state.leadTime === ''){
          alert('Set Order Lead Time');
      }else{

      updateRef.update({
          'Reference': this.state.reference,
          'LeadTime': this.state.leadTime,
          'OrderClosed': this.state.closed,
          'OrderNumber' : this.state.orderNumber
      });

      this.setState({ open: false });
    }

    
    
  };
fieldUpdate = name => event =>{

  if(name === 'closed'){
    this.setState({
      [name]: event.target.checked
    })
  }else{
    this.setState({
      [name]: event.target.value
    })
  }   
}


fieldUpdateDate = name => event =>{
   // let dateN = moment(event.target.value).format('dd/mm/yyyy');
    this.setState({
        [name]: event.target.value
  })
}

componentDidMount(){
    // set refference to firebase 
    const ordersRef = firebase.database().ref(`Orders/${this.props.orderID}`);
    
    ordersRef.on('value',snap =>{
        
        let orderBF = snap.val();

        this.setState({ 
            reference: orderBF.Reference , 
            leadTime: orderBF.LeadTime,
            closed: orderBF.OrderClosed,
            orderNumber: orderBF.OrderNumber
        });
    })
    
}

  render() {
    return (
      <userContext.Consumer>
         {(user) => ( user.access >= 1 &&
      <span className={this.props.classes.root}>


        <IconButton  aria-label="Delete" onClick={this.handleClickOpen} >
                <Edit />
        </IconButton>
        <Dialog

          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-reference"
        >
  
          <DialogContent>
            
                <TextField
                    id="reference"
                    label= "Order Reference"
                    type="text"
                    value={this.state.reference}
                    onChange={this.fieldUpdate('reference')} 
                    className={this.props.classes.textField}
                    
                />
      
                
                <TextField 
                    id="leadtime"
                    label= "Lead Time"
                    type="number"
                    value={this.state.leadTime}
                    onChange={this.fieldUpdate('leadTime')}
                    className={this.props.classes.textField}
                    
                />
                { user.access === 10 &&
                  <TextField 
                    id="orderNumber"
                    label= "Order Number"
                    type="text"
                    value={this.state.orderNumber}
                    onChange={this.fieldUpdate('orderNumber')}
                    className={this.props.classes.textField}
                    
                />
                }
    



             
                
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.closed}
                        onChange={this.fieldUpdate('closed')}
                        value="closed"
                        color="primary"
                      />
                    }
                    label="This order is closed"
                  />
                  


                    {this.state.dateremainder && <div className={this.props.classes.dateremainder}>Remeber to change date</div>}
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="primary" onClick={this.handleClose} >
              Cancel
            </Button>
            <Button variant="outlined" color="primary" onClick={this.handleUpdate} >
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </span>
      )}
      </userContext.Consumer>
    );
  }
}




export default withStyles(styles)(EditForm);
EditForm.contextType = userContext;