import React, { Component } from 'react';
import { Redirect } from 'react-router'
// import * as firebase from 'firebase';
import firebase from "firebase/compat/app";
import { withStyles } from '@material-ui/core/styles';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import Paper from '@material-ui/core/Paper';
import Costs from '../orders/Costs';
import Archive from '../orders/Archive';
import Income from '../orders/Income';
import OrderDetails from '../orders/OrderDetails'
import EditForm from '../orders/EditForm';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import AddComment from '../partials/AddComment'
import TimelineComp from '../partials/Timeline';



import moment from 'moment';

// import { HorizontalBar } from 'react-chartjs-2';

import Tasks from '../orders/Tasks';



//Logic
import {GetCustomerNameForThisOrder, TotalValue, TotalCost, TotalIncome, TotalVat, TotalIncomeVat, TotalCostVat, GetFileTypes} from '../logic/GetData';
import {
    OrderStage, 
    TimelineData
} from '../logic/orderStage'
  
const styles = theme => ({
    root: { 
        width: '100%',
        margin: '50 auto',
    },
    top:{
        width: '96%',
        margin: '0 2%'
    },
    btn:{
        backgroundColor: '#168BCF',
        color: '#ffffff',
    },
    paper:{
        margin: '20px',
        overflowX: 'scroll',
    },
    tabs:{
        backgroundColor:'white',
    },
    appBar:{
        margin: '20px',
        width: '96%'
    },
    red: {
        color: 'red'
    }
});

class Order extends Component{

    state = {
        order : {Reference:'...', LeadTime: '...'},
        value: 0,
        costNamet: '',
        vat: 23,
        Reference: '',
        LeadTime: 0,
        invoiceNo: '',
        fileTypes: [],
        stage: 1,
        Archive: [],
        labels: ['Week0'],
        timelineWeek: ['1'],
        ManualTasks: [],
        comment: ''
       
        
    }
    

    fieldUpdate = name => event =>{
        this.setState({
            [name]: event.target.value
        })
    }

    handleChange = (event, value) => {
        this.setState({ value });
    };

    
    
    handleChangeIndex = index => {
        this.setState({ value: index });
    };

  




    componentDidMount(){
        // set refference to firebase 

        const ordersRef = firebase.database().ref(`Orders/${this.props.match.params.orderId}`);
        
        //load  order
        ordersRef.on('value',snap =>{
            
            let order = snap.val();

            this.setState({ 
                ...order , 
            },()=>{
                let start = this.state.DateApproved;
                let end = moment(this.state.DateApproved).add(this.state.LeadTime, 'week').format('L');

                if( this.state.ManualTasks  !== undefined){

                    let tasks = Object.entries(this.state.ManualTasks);


                    let index = tasks.findIndex(x => x[1].name === 'Collection/Delivery date');

                    if(index !== -1 ){
                        end = moment(tasks[index][1].date)
                    }   
                }

                let OrderStageDetails = OrderStage(start, end);  
                this.setState({ ...OrderStageDetails }

                ,()=>{
                    let timelineData = TimelineData(this.state.daysInOrder/7, this.state.daysPassed);
                    this.setState({...timelineData})
                }) 
            })
        })

        

        const fileTypeRef = firebase.database().ref('FileTypes');

        fileTypeRef.once('value').then((snapshot)=> {

         
            let allfileTypes = []

            if(snapshot.val() != null){

                 allfileTypes = Object.entries(snapshot.val())
            }

            this.setState({
                fileTypes: allfileTypes,
            })
        })
  
    }

    render() {

        const { classes } = this.props;
        const Order_ID = this.props.match.params.orderId;

        if(this.props.loggedIn === true){
            return (
                <div className={classes.root}>
                    <br/>
                        <Grid container  className={this.props.classes.top}>
                       
                            <Grid item md={4} > 

                            {/* TASKS */}
                   


              

                            <Tasks 

                       
                                orderId={this.props.match.params.orderId}
                                stage={this.state.stage}
                                Archive={this.state.Archive}
                                ManualTasks={this.state.ManualTasks}
                                sysUser={this.props.sysUser}
                                fileTypes={this.state.fileTypes}
                            />
                         

                          

                            </Grid>
                            <Grid item sm={8}  >

                            <Grid container >

                             
                                <Grid item md={6} > 
                                
                            
                                <Paper className={classes.paper}>
                                    <h2>Financial details</h2>
                                        <Table className={classes.table}>
                                

                                            <TableBody>
                                                <TableRow key='grossValue'>
                                                    <TableCell component="th" scope="row">Order gross value:</TableCell>
                                                    <TableCell >{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format( TotalValue(Order_ID) + TotalVat(Order_ID))}</TableCell>
                                                </TableRow>
                                                <TableRow key='netValue'>
                                                    <TableCell component="th" scope="row">Order net value:</TableCell>
                                                    <TableCell >{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format( TotalValue(Order_ID))}</TableCell>
                                                </TableRow>
                                                <TableRow key='totIncome'>
                                                    <TableCell component="th" scope="row">Paid:</TableCell>
                                                    <TableCell >{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' })
                                                        .format( 
                                                            TotalIncome(Order_ID) 
                                                            //+ TotalIncomeVat(Order_ID)
                                                            )}</TableCell>
                                                </TableRow>
                                                <TableRow key='outstanding'>
                                                    <TableCell component="th" scope="row"><strong>Outstanding:</strong></TableCell>
                                                    <TableCell style={{color: (TotalValue(Order_ID).toFixed(2) - TotalIncome(Order_ID).toFixed(2) ) != 0 ? "red" : "green"}} >{}<strong>{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' })
                                                    .format( ( 
                                                        TotalValue(Order_ID).toFixed(2)
                                                        //+ TotalVat(Order_ID)
                                                        ) - (
                                                        TotalIncome(Order_ID).toFixed(2)
                                                        //+ TotalIncomeVat(Order_ID)
                                                        ))}</strong></TableCell>
                                                </TableRow>

                                               
                                            
                                            </TableBody>
                                        </Table>
                                        <h2>Profit Loss</h2>
                                        <Table className={classes.table}>
                                            <TableBody> 
                                                <TableRow key='profit'>
                                                    <TableCell component="th" scope="row">Profit netto:</TableCell>
                                                    <TableCell >{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format( TotalIncome(Order_ID) - TotalCost(Order_ID))}</TableCell>
                                                   
                                                </TableRow>
                                               
                                            </TableBody>
                                        </Table>

                                        <h2>VAT</h2>
                                        <Table className={classes.table}>
                                            <TableBody> 
                                                <TableRow key='Vattitles'>
                                                    <TableCell component="th" scope="row">Vat - Income:</TableCell>
                                                    <TableCell component="th" scope="row">Vat - Cost:</TableCell>
                                                    <TableCell component="th" scope="row">Vat Due:</TableCell>
                                                </TableRow>
                                                
                                                <TableRow key='Vatcalculations'>
                                                    <TableCell component="th" scope="row">{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format(TotalIncomeVat(Order_ID))}</TableCell>
                                                    <TableCell component="th" scope="row">{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format(TotalCostVat(Order_ID))}</TableCell>
                                                    <TableCell component="th" scope="row">{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format(TotalIncomeVat(Order_ID) - TotalCostVat(Order_ID))}</TableCell>
                                                    
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </Paper>


                                </Grid>

                                <Grid item md={6} > 
                                    <Paper className={classes.paper}>
                                    <h2>Project Details      <EditForm orderID={Order_ID}></EditForm>   </h2>
                                    <Table className={classes.table}>
                                        <TableBody>
                                            <TableRow key='main1'>
                                                <TableCell component="th" scope="row"> Order Reference:</TableCell>
                                                <TableCell > {this.state.Reference}</TableCell>
                                            </TableRow>
                                            <TableRow key='main2'>
                                                <TableCell component="th" scope="row">Order No:</TableCell>
                                                <TableCell >{this.state.OrderNumber}</TableCell>
                                            </TableRow>
                                            <TableRow key='main31'>
                                                <TableCell component="th" scope="row">Order Placed:</TableCell>
                                                <TableCell >{moment(this.state.DateApproved).format('L')}</TableCell>
                                            </TableRow>
                                            <TableRow key='main3'>
                                                <TableCell component="th" scope="row">Order Deadline:</TableCell>
                                                <TableCell >{moment(this.state.DateApproved).add(this.state.LeadTime, 'week').format('L')}</TableCell>
                                            </TableRow>
                                            <TableRow key='main4'>
                                                <TableCell component="th" scope="row">Based on Quote:</TableCell>
                                                <TableCell >{this.state.Quote}</TableCell>
                                            </TableRow>
                                            <TableRow key='main5'>
                                                <TableCell component="th" scope="row">Customer:</TableCell>
                                                <TableCell ><Link to={`/customer/${this.state.Customer}`}>{GetCustomerNameForThisOrder(this.state.Customer)}</Link></TableCell>
                                            </TableRow>
                                    
                                        
                                        </TableBody>
                                    </Table>
                                    </Paper>
                    
                                </Grid>
                            </Grid>

                            <Paper className={classes.paper}>
                                <h2>Finance & Archive</h2>

                             
                                    <Tabs
                                        className={classes.tabs}
                                        value={this.state.value}
                                        onChange={this.handleChange}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        
                                        centered
                                    >
                                        <Tab label="Order Details" />
                                        <Tab label="Income" />
                                        <Tab label="Cost" />
                                        <Tab label="Archive" />
                                        <Tab label="Comments" />
                                    </Tabs>
                                   
                             

                                {/* Order details --- Tab */}

                                {this.state.value === 0 && <div>
                                    <OrderDetails orderId={Order_ID} sysUser={this.props.sysUser} ></OrderDetails>
                                </div>}

                                {/* Income --- Tab */}

                                {this.state.value === 1 && <div>
                                    <Income orderId={Order_ID} OrderNumber={this.state.OrderNumber} sysUser={this.props.sysUser}></Income>
                                </div>}
                                

                                {/* Variable Cost --- Tab */}
                                {this.state.value === 2 && <div> 
                                    <Costs orderId={Order_ID} sysUser={this.props.sysUser}></Costs>
                                </div>}

                                {/* Archive --- Tab */}

                                {this.state.value === 3 && <div>
                                    <Archive orderId={Order_ID} sysUser={this.props.sysUser}></Archive>
                                </div>}

                                {this.state.value === 4 && <div>

                                    <Paper>
                                        <AddComment
                                            userEmail ={this.props.userEmail}
                                            pushPath = {`Orders/${this.props.match.params.orderId}/comments`}
                                        
                                        ></AddComment>

                                        <TimelineComp 
                                            timeline={this.state.comments} 
                                        >
                                        </TimelineComp> 
                                    </Paper>

                                </div>}


                            </Paper>
                            

                            </Grid>
                        </Grid>
                  

                     
                </div>

            );
        }else{
                return <Redirect to='/'/>
        }
    }
  }
  Order.propTypes = {
    classes: PropTypes.object.isRequired,
  };
export default withStyles(styles)(Order);