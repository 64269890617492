import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';

import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Switch from '@material-ui/core/Switch';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';


//import PropTypes from 'prop-types'




const styles = theme => ({
    root: {
      backgroundColor: theme.palette.background.paper,
      width: '90%',
      margin: '50px 5%',
    },
    textField: {
        margin: theme.spacing(1),
        width: 60,
      },
    paper: {
        padding: '20px',
    },
    colorSwatch:{
        maxWidth: '17%',
        margin: '1%',
        border: '1px solid #efefef'
    }
});


class Calculator extends Component{

    componentDidMount(){} 

    render() {
        const { classes } = this.props;
        
        return (

            <div >  
                {this.props.net === true  ? <h5> Net value </h5> : <h5> Gross value </h5> }
                
                <Switch 
                    color="primary" 
                    onChange={this.props.handleNet}   
                />

                <hr/>
                <br/>

                <div>
                    <h3>Specification </h3>
                    {/* <Button variant="outlined" color="primary" aria-label="add" className={classes.btn} onClick={this.props.handelAdditional}>
                        <AddIcon />
                    </Button> */}

                    <List component="nav" aria-label="main mailbox folders">
                        <ListItem button onClick={this.props.handelAdditional}>
                        <ListItemAvatar>
                            <Avatar>
                                <AddIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Add Item Specification" />
                        </ListItem>
                     </List>
                </div>

                
                {
                    this.props.additional.map((option,index) => (
                        <div key={index}>

                            <span style={{display: 'flex', padding: '10px'}}>
                                <Button variant="outlined" color="primary" aria-label="add" style={{marginRight: '10px'}}className={classes.btn} onClick={this.props.template({key:option.key, list:'additional', index})}>
                                    Template
                                </Button>

                                <Input type="file"
                                    id="avatar" 
                                    name="avatar"
                                    accept="image/x-png,image/gif,image/jpeg"
                                    //fullwidth
                                    onChange={this.props.handleAdditionalImage(option.key).bind(this)}
                                    />
                                <IconButton className={classes.delete} aria-label="Delete" onClick={this.props.handleRemoveAdditional(option.key)}>
                                    <DeleteIcon />
                                </IconButton>
                            </span>
                            <ReactQuill 
                                value={option.text}
                                onChange={this.props.handleQuillText({key:option.key, list:'additional', index})} 
                            />
                            
                            <hr/>
                        </div>
                    ))

                }

                <div>

                    <hr style={{margin: '50px 0 '}} ></hr>
                    <h3 style={{}}>Price Breakdown</h3>
      
                    <List component="nav" aria-label="main mailbox folders">
                        <ListItem button onClick={this.props.handelAddItem}>
                        <ListItemAvatar>
                            <Avatar>
                                <AddIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Add Price Item" />
                        </ListItem>
                        <ListItem button onClick={this.props.handelAddBreak}>
                     
                        <ListItemAvatar>
                            <Avatar>
                                <AddIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Add Page Break" />
                        </ListItem>
                    </List>
                    {/* <Button variant="outlined" color="primary" aria-label="add" className={classes.btn} onClick={this.props.handelAddItem}>
                        <AddIcon />
                    </Button>
                    <Button variant="outlined" color="primary" aria-label="add" className={classes.btn} onClick={this.props.handelAddBreak}>
                        BREAK <AddIcon />
                    </Button> */}
                </div>
                

                {
                    this.props.priceItems.map((option,index) => (
                        option.break === true ?

                        <div key={index} style={{color: '#cd2031',  margin: '50px 0'}} >
                           {/* Page Break < hr style={{ }}  />  */}

                           <p className="subtitle fancy"><span>Page Break</span></p>
                            

                            <IconButton  aria-label="delete" size="small" className={classes.listBtn} onClick={this.props.handlePushItemDown('priceItems' , option.key ) }>
                                <ArrowDownwardIcon />
                            </IconButton>
                            <IconButton className={classes.delete} aria-label="Delete" onClick={this.props.handleRemovePriceItem(option.key)}>
                                <DeleteIcon />
                            </IconButton>

                           
                        </div>

                        :

                        <div key={index}>
                            <h3>Item {index+1}</h3>

                            <ReactQuill 
                                value={option.text}
                                onChange ={this.props.handleQuillText({key:option.key, list:'priceItems', index})} 
                            />
                          

                            <TextField
                                
                                className={classes.textField}
                                type="number"
                                id="price" 
                                name="prace"
                                label="Price"
                                value={option.price}
                                onChange={this.props.handelItem(option.key, 'price','priceItems')}
                            />

                            <TextField
                                
                                className={classes.textField}
                                type="number"
                                id="qty" 
                                name="Qty"
                                label="Qty"
                                value={option.qty}
                                onChange={this.props.handelItem(option.key, 'qty','priceItems')}
                            />

                            <TextField
                                
                                id="vat"
                                select
                                label="VAT"
                                className={classes.textField}
                                value={option.vat}
                                onChange={this.props.handelItem(option.key,'vat','priceItems')}
                                SelectProps={{
                                    native: true,
                                    MenuProps: {
                                    className: classes.menu,
                                    },
                                }}
                                helperText=""
                                margin="normal"
                                >
                                <option key='21' value="21">21%</option>
                                <option key='23' value="23">23%</option>
                                <option key='13' value="135">13.5%</option>
                                <option key='0' value="0">0%</option>
                            </TextField>

                            <TextField
                                type="number"
                                id="discount" 
                                name="discount"
                                label="Discount (%)"
                                style={{width:'80px'}}
                                className={classes.textField}
                                value={option.discount}
                                onChange={this.props.handelItem(option.key,'discount', 'priceItems')}
                            />
                            <IconButton  aria-label="delete" size="small"   className={classes.listBtn} onClick={this.props.handlePushItemDown('priceItems' , option.key ) }>
                                <ArrowDownwardIcon />
                            </IconButton>

                            

                            <IconButton className={classes.delete} aria-label="Delete"  onClick={this.props.handleRemovePriceItem(option.key)}>
                                <DeleteIcon />
                            </IconButton>

                            <Switch 
                                color="primary" 
                                onChange={this.props.handleThickLIne('priceItems' , option.key)}   
                            />
                            <hr/>
                        </div>
                    ))
                }
            </div>
            )

    }

}

export default withStyles(styles)(Calculator);