import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
        
})

class Closer2 extends Component{

    state= {}

    closerSide = () => {

        if(this.props.handleSide === "left"){
            if(this.props.leaf === 'nonActive'){
                return 'left';
            }else{
                return 'right';
            }
        }else{
            if(this.props.leaf === 'nonActive'){
                return 'right';
            }else{
                return 'left';
            }
        }

    }

    color = () =>{
        if(this.props.inside){
            return('#000');
        }else{
            return('#c5c3c3');
        }
    }

    zindex = () =>{


        if(this.props.inside === false){
            return('-1000');
        }else{
            return('1000');
        }

    }

    render() {
        const { classes } = this.props;
        
        return (
            <div>
                <div style={{ height: '1px',  width: '5px', border: `1px solid ${this.color()}`, position: 'absolute', margin: '0 auto',[this.closerSide()]: '24px', top: '-1px', zIndex: `${this.zindex()}`}}></div>
                <div style={{ height: '15px',  width: '60px', border: `1px solid ${this.color()}`, position: 'absolute', margin: '0 auto',[this.closerSide()]: '15px', top: '1px',zIndex: `${this.zindex()}`}}></div>
            </div>  
       )
    }
}

export default withStyles(styles)(Closer2);