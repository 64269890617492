import React, { Component } from 'react';
import {userContext} from '../../../Context/userContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({

});




class ConfigGeneralSettings extends Component{
    render() {
        const { classes } = this.props;
        return ( <userContext.Consumer>
            {(user) => ( user.access >= 1 ? 
            <div style={{ display: 'flex',flexDirection: 'column', textAlign: "left"}}>


                <h4>General settings</h4>

                {/* <TextField
                    id="templateName"
                    label='Template Name'
                    type="text"
                    className="textField"
                    value={this.props.templateName}
                    onChange={this.props.handleChange('templateName')}
                    margin="normal"
                /> */}

            <TextField
                id="profileWidth"
                select
                label="Profile Width"
                className="textField"
             
                onChange={this.props.handleChange('profileWidth')}
                SelectProps={{
                    native: true,
                    MenuProps: {
                    //className: classes.menu,
                    },
                }}
                helperText=""
                margin="normal"
                >
                    <option key="20" value="20">20</option>
                    <option key="40" value="40">40</option>
            </TextField>

            <TextField
                id="profileDepth"
                select
                label="Profile Depth"
                className="textField"
                value={this.props.profileDepth}
                onChange={this.props.handleChange('profileDepth')}
                SelectProps={{
                    native: true,
                    MenuProps: {
                    className: classes.menu,
                    },
                }}
                helperText=""
                margin="normal"
                >
                    <option key="1" value="1">1</option>
                    <option key="20" value="20">20</option>
                    <option key="30" value="30">30</option>
                    <option key="40" value="40">40</option>
            </TextField>

            <TextField
                id="color"
                select
                label="Color"
                className="textField"
                value={this.props.color}
                onChange={this.props.handleChange('color')}
                SelectProps={{
                    native: true,
                    MenuProps: {
                    className: classes.menu,
                    },
                }}
                helperText=""
                margin="normal"
                >
                    <option key="1" value="#383e42">Anthracite</option>
                    <option key="3" value="#333333">Black </option>
                    <option key="2" value="#FCFBF4">White</option>
                    
                    <option key="4" value="#E1C16E">Gold</option>
                    <option key="5" value="#b5a642">Bras</option>
                    <option key="6" value="#B87333">Copper</option>
            </TextField>


            </div> : <> { this.state.spinner ? <CircularProgress/> : <span></span> }  </>
        )}</userContext.Consumer> )   
    }
}



export default withStyles(styles)(ConfigGeneralSettings);