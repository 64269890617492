import moment from 'moment';

export const date = (date = '') => {

    if(date[4] === "-"){
        date = date.split("-") 
        date = date[2] + "-" + date[1] + "-" + date[0]
    }else if(date[2] === "/"){
        date = date.split("/") 
        date = date[1] + "-" + date[0] + "-" + date[2]
    }

    return(date)
}