import React, { Component } from 'react';

class Closer2 extends Component{

    state= {}

    closerSide = () => {

        if(this.props.handleSide === "left"){
            return 'right';
        }else{
            return 'left';
        }

    }
    color = () =>{
        if(this.props.inside){
            return('#000');
        }else{
            return('#c5c3c3');
        }
    }

    zindex = () =>{


        if(this.props.inside === false){
            return('-1000');
        }else{
            return('1000');
        }

    }
    
    render() {
        
        return (
            <div>
                <div style={{ height: '2px',  width: '11px', border: `1px solid ${this.color()}`, position: 'absolute', margin: '0 auto',[this.closerSide()]: '35px', top: '-6px',zIndex: `${this.zindex()}`}}></div>
                <div style={{ height: '1px',  width: '5px', border: `1px solid ${this.color()}`, position: 'absolute', margin: '0 auto',[this.closerSide()]: '35px', top: '-3px',zIndex: `${this.zindex()}`}}></div>
                <div style={{ height: '2px',  width: '13px', border: `1px solid ${this.color()}`, position: 'absolute', margin: '0 auto',[this.closerSide()]: '27px', top: '-1px',zIndex: `${this.zindex()}`}}></div>
                <div style={{ height: '15px',  width: '60px', border: `1px solid ${this.color()}`, position: 'absolute', margin: '0 auto',[this.closerSide()]: '15px', top: '2px',zIndex: `${this.zindex()}`}}></div>
            </div>  
       )
    }
}

export default Closer2;