
import { Group }  from 'three';
import createBox from './createBox';
import SpriteText from 'three-spritetext';

const createSideMeasurements = ( totalHeight,zoom ) =>{

    const leftSideMeasurements = new Group();
    const heightMeasurement = createBox(2, totalHeight +100,2 , "#666666");
    const heightMeasurementTop = createBox(180, 2 ,2 , "#666666");
    const heightMeasurementBottom = createBox(180, 2 ,2 , "#666666");
  
    heightMeasurementTop.position.x =  40;
    heightMeasurementTop.position.y = totalHeight/2
    heightMeasurementBottom.position.x =  40;
    heightMeasurementBottom.position.y = - totalHeight/2

    const heightText = new SpriteText(totalHeight);
    
    heightText.textHeight = Math.round(Number(zoom)/110)
    heightText.color = "black";

    leftSideMeasurements.add(heightMeasurement, heightMeasurementTop, heightMeasurementBottom, heightText)

    return(leftSideMeasurements)
}

export  default createSideMeasurements;