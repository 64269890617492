import React, { Component } from 'react';

import CalcStepper from '../partials/Stepper';
//import CustomerDetails from '../Customers/CustomerDetails';
import StepperNav from '../partials/StepperNav';


import { withStyles } from '@material-ui/core/styles';
import { Button, Container, Menu, MenuItem, Paper, Grid, Switch, IconButton, Snackbar } from '@material-ui/core';

import Settings from '@material-ui/icons/Settings';
import ThreeDRotationIcon from '@material-ui/icons/ThreeDRotation';
import CloseIcon from '@material-ui/icons/Close';

import ConfigGeneralSettings from './components/composerComponents/configGeneralSettings';
import ConfigDimensions from './components/composerComponents/configDimensions';
import ConfigIronmongery from './components/composerComponents/configIronmongery';
import ConfigGlass from './components/composerComponents/configGlass';
import ConfigMuntins from './components/composerComponents/configMuntins';
import ConfigAdjustments from './components/composerComponents/configAdjustments';
import ConfigZoom from './components/composerComponents/configZoom';

import Canvas from './components/canvas/renderCanvas';
import { getWeekYearWithOptions } from 'date-fns/fp';

import { getDatabase, ref, push, set } from "firebase/database";


const uniqid = require('uniqid');

const glassTypes = [
    {
        name: "No glass",
        color: "#ffffff",
        roughness: 0,
        metalness: 0,
        reflectivity: 0,
        clearcoat: 0,
        opacity: 0,
        transmission: 0,
    },
    {
        name: "Clear",
        color: "#ffffff",
        roughness: .3,
        metalness: 0.05,
        reflectivity: 0.05,
        clearcoat: .7,
        opacity: 1,
        transmission: .9,
    }, {
        name: "Frosted",
        color: "#ffffff",
        roughness: .3,
        metalness: 0.1,
        reflectivity: .45,
        clearcoat: 0,
        opacity: 1,
        transmission: .55,
    }, {
        name: "Light blue test",
        color: "#438ABF",
        roughness: .8,
        metalness: 0,
        reflectivity: .6,
        clearcoat: 0,
        opacity: .15,
        transmission: .65,
    }
]

const styles = theme => ({
    root: {

        marginTop: '50px',
        '@media print': {
            margin: '0',
            width: '100%',
        }
    },
    paper: {
        padding: '20px',
        '@media print': {
            'box-shadow': 'none !important',
            'margin': "0px",
            'padding': "0px",
        }
    },
    checked: {
        color: '#d60a2e !important',
    },

    printFullWidth: {
        '@media print': {
            flexBasis: '100%',
            margin: '0px',
            padding: '4px 16px !important',
        }
    }

});


class Composer extends Component {

    state = {
        templateId: "",
        steps: ['General settings', 'Configuration & Dimensions', 'Ironmongery', 'Muntins Design', "Glaass", 'Adjustments', 'Save'],
        zoom: 5600,
        activeStep: 0,
        positionX: 0,
        positionY: 0,
        muntins: [],
        doorFrameWidth: 900,
        doorFrameHeight: 2010,
        leftPanel: false,
        rightPanel: false,
        transom: false,
        lpWidth: 0,
        rpWidth: 0,
        transomHeight: 0,
        profileWidth: "20",
        profileDepth: "40",
        color: "#383e42",
        orbitCam: false,
        draggable: true,
        anchorEl: null,
        muntinsDesigne: {
            mainDoor: 0,
            secondLeaf: 0,
            transom: 0,
            leftPanel: 0,
            rightPanel: 0
        },
        animation: true,
        side: "left",
        lockCase: { x: 65, y: 180, z: 40 },
        lockCaseLocation: 860,
        lockCaseLocationHorizontal: 0,
        singleDouble: 'Single',
        doorWidth: 0,
        mainElement: "hingedDoor",
        // metalness: 0, 
        // roughness: 0,
        metalness: 0.5,
        roughness: 0.55,
        backgroundColor: "#ffffff",
        templateName: "",
        hinges: '3',
        floor: false,
        wall: false,
        fullWidthMaskingBar: false,
        mainDoorIronmongery: {
            type: "handle",
            color: 'sameAsFrame',
            handleLocation: 900,
            handleLocationHorizontal: 0,
            twoLeaves: true,
            spindle: 170,
            rosetDepth: 70,
            grip: 130,
            height: 300,

        },
        glass: {
            name: "No glass",
            color: "#ffffff",
            roughness: 0,
            metalness: 0,
            reflectivity: 0,
            clearcoat: 0,
            opacity: 0,
            transmission: 0,
        },





        glassThickness: 8,

        lights: [


            {
                type: 'PointLight',
                color: '#ffffff',
                strenght: 0.2,
                x: -500,
                y: 0,
                z: 1000
            }, {
                type: 'PointLight',
                color: '#ffffff',
                strenght: 0.2,
                x: 0,
                y: -1200,
                z: 500
            },
        ]

    }

    componentDidMount() {


        let lights = this.state.lights

        // console.log(this.props)

        lights.unshift({
            type: 'PointLight',
            color: '#ffffff',
            strenght: 0.8,
            x: this.state.doorFrameWidth / 2,
            y: this.state.doorFrameHeight * 0.5,
            z: this.state.doorFrameHeight * 1
        })


        // console.log("component did mount",this.props)

        // const light1 = new PointLight('#ffffff', 0.7)
        // light1.position.x = totalWidth / 2
        // light1.position.y = doorHeight * 0.5
        // light1.position.z = doorHeight * 1

        // const light2 = new PointLight('#ffffff', 0.2)
        // light2.position.x = -totalWidth
        // light2.position.y = -doorHeight * 0.5
        // light2.position.z = doorHeight * 1

        this.setState({

            lights,
            //  ...this.props,
            ...this.props.door,
        })



    }

    componentDidUpdate(prevProps, prevState) {


        if (prevProps !== this.props) {

            let templateId = this.props.id

            //console.log(templateId)

            this.setState({
                ...this.props.door,
                templateId
            })

        }


        if (prevState !== this.state) {

            let lights = this.state.lights

            // lights[0] = {
            //     type: 'PointLight',
            //     color: '#ffffff',
            //     strenght: lights[0].strenght,
            //     x: this.state.doorFrameWidth / 2,
            //     y: this.state.doorFrameHeight * 0.5,
            //     z: this.state.doorFrameHeight * 1
            // }
        }
    }



    // Profile template 

    // ----------------------------
    //  RENDER
    // ----------------------------



    ///// --------- React




    handleNext = () => {
        window.scrollTo(0, 0);
        const { activeStep } = this.state;
        this.setState({
            activeStep: activeStep + 1,
        });
    };

    handleBack = () => {
        window.scrollTo(0, 0);
        const { activeStep } = this.state;
        this.setState({
            activeStep: activeStep - 1,
        });
    };


    handleChangeSize = (name, id) => event => {

        const muntins = this.state.muntins;
        const index = muntins.findIndex((x => x.bfId === id))




        if (name === "width") {
            muntins[index].w = Math.round(Number(event.target.value))
        } else if (name === "height") {
            muntins[index].h = Math.round(Number(event.target.value))
        } else if (name === "positionX") {
            muntins[index].position.x = Math.round(Number(event.target.value))
        } else if (name === "positionY") {
            muntins[index].position.y = Math.round(Number(event.target.value))
        }

        this.setState({
            muntins
        })

    }


    addMuntin = () => {
        const muntins = this.state.muntins;



        // const mesh =  createBox(400,20,40, '#ffffff')

        const mesh = { w: 400, h: this.state.profileWidth, z: this.state.profileDepth, color: this.state.color, bfId: uniqid(), position: { x: 0, y: -this.state.doorFrameHeight / 2 - 150, z: 0 }, childOf: "all" }

        muntins.unshift(mesh)

        this.setState({
            muntins
        })
    }

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null, open: false, });
    };





    setMuntinDesign = (name) => event => {

        let value = '0'

        let muntinsDes = this.state.muntinsDesigne[name]



        if (event !== undefined) {
            value = event.target.value;
        } else {
            value = muntinsDes
        }


        const doorFrameHeight = Number(this.state.doorFrameHeight);
        const doorFrameWidth = Number(this.state.doorFrameWidth);
        const profileWidth = Number(this.state.profileWidth);
        const profileDepth = Number(this.state.profileDepth);
        let doorWidth = Number(this.state.doorWidth);
        const leftPanelWidth = Number(this.state.lpWidth)
        const rightPanelWidth = Number(this.state.rpWidth)

        //const doorHeight = doorFrameHeight - (profileWidth * 2 ) - 16;
        const color = this.state.color;

        let width, sLdoorWidth, height, sLdoorHeight;

        if (this.state.singleDouble === "Single") {
            doorWidth = doorFrameWidth - (profileWidth * 2) - 8;
        } else {
            doorWidth = doorWidth;
            sLdoorWidth = doorFrameWidth - doorWidth - (profileWidth * 2) - 10;
            sLdoorHeight = doorFrameHeight - (profileWidth * 2) - 16
        }




        if (name === "mainDoor") {
            width = doorWidth
            height = doorFrameHeight - (profileWidth * 2) - 16;
        } else if (name === "secondLeaf") {
            width = sLdoorWidth
            height = doorFrameHeight - (profileWidth * 2) - 16;
        } else if (name === "leftPanel") {
            width = leftPanelWidth
            height = doorFrameHeight
        } else if (name === "rightPanel") {
            width = rightPanelWidth
            height = doorFrameHeight
        } else if (name === "transom") {
            width = 300
        }


        let muntinsDesigne = this.state.muntinsDesigne;
        muntinsDesigne[name] = value

        let muntins = this.state.muntins;


        const changeMuntins = (name, value, width, height) => {


            muntins = muntins.filter(muntin => muntin.childOf !== name)


            if (value === "1") {

                const muntin1 = { w: width, h: profileWidth, z: profileDepth, color, bfId: uniqid(), position: { x: 0, y: 0, z: 0 }, childOf: name }
                const muntin2 = { w: width, h: profileWidth, z: profileDepth, color, bfId: uniqid(), position: { x: 0, y: 0, z: 0 }, childOf: name }

                muntin1.position.y = doorFrameHeight / 6;
                muntin2.position.y = -doorFrameHeight / 6;
                muntins.push(muntin1, muntin2)

            } else if (value === "2") {

                const muntin1 = { w: width, h: profileWidth, z: profileDepth, color, bfId: uniqid(), position: { x: 0, y: 0, z: 0 }, childOf: name }
                const muntin2 = { w: width, h: profileWidth, z: profileDepth, color, bfId: uniqid(), position: { x: 0, y: 0, z: 0 }, childOf: name }
                const muntin3 = { w: profileWidth, h: height, z: profileDepth, color, bfId: uniqid(), position: { x: 0, y: 0, z: 0 }, childOf: name }

                muntin1.position.y = doorFrameHeight / 6;
                muntin2.position.y = -doorFrameHeight / 6;
                muntins.push(muntin1, muntin2, muntin3)

            } else if (value === "3") {

                const muntin1 = { w: width, h: profileWidth, z: profileDepth, color, bfId: uniqid(), position: { x: 0, y: 0, z: 0 }, childOf: name }
                const muntin2 = { w: width, h: profileWidth, z: profileDepth, color, bfId: uniqid(), position: { x: 0, y: 0, z: 0 }, childOf: name }
                const muntin3 = { w: width, h: profileWidth, z: profileDepth, color, bfId: uniqid(), position: { x: 0, y: 0, z: 0 }, childOf: name }

                muntin1.position.y = doorFrameHeight / 4;
                muntin3.position.y = -doorFrameHeight / 4;
                muntins.push(muntin1, muntin2, muntin3)

            } else if (value === "4") {

                const muntin1 = { w: width, h: profileWidth, z: profileDepth, color, bfId: uniqid(), position: { x: 0, y: 0, z: 0 }, childOf: name }
                const muntin2 = { w: width, h: profileWidth, z: profileDepth, color, bfId: uniqid(), position: { x: 0, y: 0, z: 0 }, childOf: name }
                const muntin3 = { w: width, h: profileWidth, z: profileDepth, color, bfId: uniqid(), position: { x: 0, y: 0, z: 0 }, childOf: name }
                const muntin4 = { w: profileWidth, h: height, z: profileDepth, color, bfId: uniqid(), position: { x: 0, y: 0, z: 0 }, childOf: name }

                muntin1.position.y = doorFrameHeight / 4;
                muntin3.position.y = -doorFrameHeight / 4;
                muntins.push(muntin1, muntin2, muntin3, muntin4)
            }

            return (muntins)

        }

        muntins = changeMuntins(name, value, width, height)

        if (this.state.singleDouble === "Double") {

            let secondLeaf = muntins.findIndex(muntin => muntin.childOf !== "secondLeaf")

            if (secondLeaf !== -1) {
                muntins = changeMuntins("secondLeaf", muntinsDesigne.secondLeaf, sLdoorWidth, sLdoorHeight)
            }
        }

        this.setState({
            muntins,
            muntinsDesigne
        })
    }


    updateMuntinLocation = (object) => {


        this.setState({
            muntins: object
        })

    }


    handleChange = (name, objVal) => event => {

        if (name === 'transom') {

            let th = (this.state[name]) ? 0 : 400

            this.setState({
                [name]: !this.state[name],
                transomHeight: th
            })
        } else if (name === "doorFrameWidth" ||
            name === "doorFrameHeight" ||
            name === "lpWidth" ||
            name === "rpWidth" ||
            name === "doorWidth"
        ) {

            let element

            if (name === "doorFrameWidth" || name === "doorFrameHeight" || name === "doorWidth") {
                element = "mainDoor"
            }
            if (name === "lpWidth") { element = "leftPanel" }
            if (name === "rpWidth") { element = "rightPanel" }



            this.setState({
                [name]: event.target.value
            }, () => { this.setMuntinDesign(element)() })

        } else if (name === 'leftPanel' || name === 'rightPanel') {

            let side = (name === 'leftPanel') ? 'lpWidth' : 'rpWidth'
            let pw = (this.state[name]) ? 0 : 400

            this.setState({
                [name]: !this.state[name],
                [side]: pw
            })

        } else if (objVal === "twoLeaves") {


            let ironmongery = this.state[name]
            ironmongery[objVal] = !ironmongery[objVal]

            this.setState({
                ironmongery
            })
        } else if (name === "mainElement") {

            let mainDoorIronmongery = this.state.mainDoorIronmongery

            if (event.target.value === "panel") {
                mainDoorIronmongery.type = "none"
            }
            this.setState({
                [name]: event.target.value,
                mainDoorIronmongery
            })


        } else if (name === "glass") {




            let index = glassTypes.findIndex(setup => setup.name === event.target.value)

            let glass = glassTypes[index]

            this.setState({ glass })





        } else if (name == "orbitCam" || name == "floor" || name == "wall" || name === "fullWidthMaskingBar") {
            this.setState({
                [name]: !this.state[name],

            })
        } else if (objVal !== undefined) {

            let to = this.state[name];

            to[objVal] = event.target.value

            this.setState({
                to
            })
        } else {

            this.setState({
                [name]: event.target.value
            })
        }

    }

    handleDevideDoubleDoor = (name) => event => {

        let doorWidth = this.state.doorWidth

        let devider = (100 - name) / 100

        console.log(devider)

        doorWidth = (this.state.doorFrameWidth * devider) - 4 - (this.state.profileWidth)

        this.setState({
            doorWidth
        })

    }





    handleLights = (name, index) => event => {

        // console.log(name,index ,event.target.value)

        let lights = this.state.lights;

        lights[index][name] = Number(event.target.value)



        this.setState({
            lights
        })

    }

    handleSlider = (name) => (event, newValue) => {
        this.setState({
            [name]: newValue
        })
    }

    handleGlasslSlider = (name) => (event, newValue) => {


        console.log(name, 'event: ' + event, newValue)

        let glass = this.state.glass

        if (name === 'color') {
            glass[name] = event
        } else {
            glass[name] = newValue
        }



        this.setState({
            glass
        })
    }



    handleLightsSlider = (index) => (event, newValue) => {

        let lights = this.state.lights;

        lights[index].strenght = newValue

        this.setState({
            lights
        },
        )
    }


    handleSingleDouble = name => event => {

        let singleDouble = this.state.singleDouble
        let doorWidth, doorFrameWidth

        if (singleDouble === "Single") {
            doorWidth = 600
            doorFrameWidth = 1248
        } else {
            doorWidth = 0
            doorFrameWidth = 900
        }

        this.setState({
            [name]: event.target.value,
            doorFrameWidth,
            doorWidth
        })
    }


    // This Must be removed once New creator is Finished --- START
    createOrderNumbeAndPushToSave = () => {


        // alert("works")


        // console.log(...Object.entries(this.state))


        // const ID = firebase.database().ref().child('QuoteNo')
        // let quoteNo ;

        // return ID.once('value').then((snap) =>{

        //     quoteNo  = snap.val()+1;

        //     firebase.database().ref().update({'QuoteNo':quoteNo}).then((snap) => {

        //         quoteNo = this.props.userEmail.slice(0,2)+'-'+ moment().year() +'-'+ quoteNo;


        this.setState({
            // quoteNo: quoteNo,
            // redirect: true
        }, () => { this.handleSaveOrder() })
        //     });
        // })
    }
    handleSaveOrder = () => {

        console.log(this.props.id)


        if (this.state.templateId === "") {


            // set new template using new firebase v9


            let saveItems = this.state
            saveItems.activeStep = 0

            const db = getDatabase();
            const postListRef = ref(db, 'Crittall/Templates');
            const newPostRef = push(postListRef);
            set(newPostRef, { ...saveItems });



            this.setState({
                activeStep: 0,
                templateId: 0
            });

        } else {

            // Update existing template , this is possible as quote creator passes templateId, option not available in templet builder or duplicate 



            ///  update existing template firebase v9

            // clear template id before save


            // let updatedDoor = this.state

            // updatedDoor.templateId = ""

            // console.log(updatedDoor)



            // Create new template and push and pop items in quote creator


            let saveItems = this.state
            saveItems.activeStep = 0

            const db = getDatabase();
            const postListRef = ref(db, 'Crittall/Templates');

            // const newTemplateId = push(postListRef).key;
            const newPostRef = push(postListRef);

            let newTemplateId = newPostRef.key;


            set(newPostRef, { ...saveItems });

            this.props.editMainImage(this.state.templateId, newTemplateId)

        }

    }

    // This Must be removed once New creator is Finished --- START

    render() {

        const { anchorEl } = this.state;
        return (

            <Container maxWidth="xl" className={this.props.classes.root} >
                <Grid container spacing={6} style={{ justifyContent: 'center' }} >
                    <Grid item xs={12} className="noPrint" >



                        {/* ----------------------- NAVIGATION */}
                        <Button
                            style={{ float: 'right' }}
                            //variant="outlined" 

                            //color="primary" 
                            aria-label="menu"
                            className="noPrint"
                            //className={this.props.classes.outlined} 
                            aria-owns={anchorEl ? 'simple-menu' : undefined}
                            aria-haspopup="true"
                            onClick={this.handleClick}
                        ><Settings /></Button>

                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={this.handleClose}
                        >

                            <MenuItem>
                                <Switch
                                    checked={this.state.orbitCam}
                                    onChange={this.handleChange('orbitCam')}
                                    name="3d"
                                ></Switch>
                                <ThreeDRotationIcon />
                            </MenuItem>
                        </Menu>

                        <CalcStepper
                            //properties
                            activeStep={this.state.activeStep}
                            steps={this.state.steps}

                        > </CalcStepper>
                    </Grid >
                    <Grid item sm={12}  >
                        <StepperNav
                            handleNext={this.handleNext}
                            handleBack={this.handleBack}
                            activeStep={this.state.activeStep}
                            steps={this.state.steps}
                        ></StepperNav>
                        <Button color="primary" 
                            onClick={ ()=> {
                                this.props.createOrEditMainDrawing( this.props.doorIndex, {...this.state}) 

                                this.props.togglePanel()
                                }} 
                                variant="outlined" 
                                style={{margin: "1em auto", display: "block"}} >
                            Save Drawing
                        </Button>
                    </Grid>




                    {this.state.activeStep === 0 &&
                        <Grid item md={3} className="noPrint">
                            <div >
                                <Paper className="paper">
                                    <ConfigGeneralSettings
                                        handleChange={this.handleChange}
                                        templateName={this.state.templateName}
                                        profileWidth={this.state.profileWidth}
                                        profileDepth={this.state.profileDepth}
                                        color={this.state.color}
                                    ></ConfigGeneralSettings>
                                </Paper>

                                {/* <ConfigPanel
                                    handleChange={this.handleChange}
                                    handleSingleDouble={this.handleSingleDouble}
                                    handleChangeSize={this.handleChangeSize}
                                    addMuntin={this.addMuntin}
                                    setMuntinDesign={this.setMuntinDesign}
                                    handleLights={this.handleLights}
                                    handleLightsSlider={this.handleLightsSlider}
                                    handleMaterialSlider={this.handleMaterialSlider}
                                    templateName={this.state.templateName}
                                    profileWidth={this.state.profileWidth} 
                                    profileDepth={this.state.profileDepth}
                                    muntins={this.state.muntins}
                                    doorFrameWidth={this.state.doorFrameWidth}
                                    doorFrameHeight={this.state.doorFrameHeight}
                                    color={this.state.color}
                                    leftPanel={this.state.leftPanel}
                                    lpWidth={this.state.lpWidth}
                                    rightPanel={this.state.rightPanel}
                                    rpWidth={this.state.rpWidth}
                                    muntinsDesigne={this.state.muntinsDesigne}
                                    side={this.state.side}
                                    lockCase={this.state.lockCase}
                                    lockCaseLocation={this.state.lockCaseLocation}
                                    metalness={this.state.metalness}
                                    roughness={this.state.roughness}
                                    backgroundColor={this.state.backgroundColor}
                                    singleDouble={this.state.singleDouble}
                                    doorWidth={this.state.doorWidth}
                                    transomHeight={this.state.transomHeight}
                                    transom={this.state.transom}
                                    mainDoorIronmongery={this.state.mainDoorIronmongery}
                                    mainElement={this.state.mainElement}
                                    lockCaseLocationHorizontal={this.state.lockCaseLocationHorizontal}
                                    //admin
                                    lights={this.state.lights}
                                ></ConfigPanel> */}
                            </div>
                        </Grid>
                    }

                    {this.state.activeStep === 1 &&
                        <Grid item md={3} className="noPrint" >
                            <Paper className="paper">
                                <ConfigDimensions
                                    handleChange={this.handleChange}
                                    handleSingleDouble={this.handleSingleDouble}
                                    handleDevideDoubleDoor={this.handleDevideDoubleDoor}
                                    mainElement={this.state.mainElement}
                                    doorFrameWidth={this.state.doorFrameWidth}
                                    doorFrameHeight={this.state.doorFrameHeight}
                                    singleDouble={this.state.singleDouble}
                                    doorWidth={this.state.doorWidth}
                                    side={this.state.side}
                                    leftPanel={this.state.leftPanel}
                                    lpWidth={this.state.lpWidth}
                                    rightPanel={this.state.rightPanel}
                                    rpWidth={this.state.rpWidth}
                                    transomHeight={this.state.transomHeight}
                                    transom={this.state.transom}
                                    fullWidthMaskingBar={this.state.fullWidthMaskingBar}
                                ></ConfigDimensions>
                            </Paper>
                        </Grid>
                    }

                    {this.state.activeStep === 2 &&
                        <Grid item md={3} className="noPrint" >
                            <Paper className="paper">
                                <ConfigIronmongery
                                    handleChange={this.handleChange}
                                    mainDoorIronmongery={this.state.mainDoorIronmongery}
                                    singleDouble={this.state.singleDouble}
                                    lockCase={this.state.lockCase}
                                    lockCaseLocation={this.state.lockCaseLocation}
                                    lockCaseLocationHorizontal={this.state.lockCaseLocationHorizontal}
                                    hinges={this.state.hinges}
                                ></ConfigIronmongery>
                            </Paper>
                        </Grid>
                    }

                    {this.state.activeStep === 3 &&
                        <Grid item md={3} className="noPrint" >
                            <Paper className="paper">
                                <ConfigMuntins
                                    addMuntin={this.addMuntin}
                                    setMuntinDesign={this.setMuntinDesign}
                                    handleChangeSize={this.handleChangeSize}
                                    muntinsDesigne={this.state.muntinsDesigne}
                                    muntins={this.state.muntins}
                                    transom={this.state.transom}
                                    rightPanel={this.state.rightPanel}
                                    leftPanel={this.state.leftPanel}
                                    singleDouble={this.state.singleDouble}
                                ></ConfigMuntins>
                            </Paper>
                        </Grid>
                    }

                    {this.state.activeStep === 4 &&
                        <Grid item md={3} className="noPrint" >
                            <Paper className="paper">
                                <ConfigGlass
                                    handleChange={this.handleChange}
                                    handleGlasslSlider={this.handleGlasslSlider}

                                    glassThickness={this.state.glassThickness}
                                    glassTypes={glassTypes}
                                    glass={this.state.glass}

                                ></ConfigGlass>
                            </Paper>
                        </Grid>
                    }

                    {this.state.activeStep === 5 &&
                        <Grid item md={3} className="noPrint" >
                            <Paper className="paper">
                                <ConfigAdjustments
                                    handleChange={this.handleChange}
                                    handleSlider={this.handleSlider}
                                    handleLightsSlider={this.handleLightsSlider}
                                    handleLights={this.handleLights}

                                    lights={this.state.lights}
                                    roughness={this.state.roughness}
                                    metalness={this.state.metalness}
                                    backgroundColor={this.state.backgroundColor}
                                ></ConfigAdjustments>
                            </Paper>
                        </Grid>
                    }


                    <Grid item md={9} className={this.props.classes.printFullWidth}  >
                        <Paper className={this.props.classes.paper} id="scena">

                            <ConfigZoom zoom={this.state.zoom} editWhat="zoom" handleSlider={this.handleSlider}></ConfigZoom>
                            <Canvas
                                updateMuntinLocation={this.updateMuntinLocation}
                                doorSetup={this.state}
                                id={"config"}
                                height="700px"
                            ></Canvas>
                        </Paper>
                    </Grid>

                </Grid>

                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.state.snackBar}
                    //autoHideDuration={5000}
                    onClose={this.handleCloseSnackBar}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{this.state.snackBarText}</span>}
                    action={[
                        ,
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            //className={classes.close}
                            onClick={this.handleCloseSnackBar}
                        >
                            <CloseIcon />
                        </IconButton>,
                    ]}
                />
            </Container>







        )

    }





}

export default withStyles(styles)(Composer);
