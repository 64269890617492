import React, { Component } from 'react';
import { Redirect } from 'react-router';
import moment from 'moment';
import MainComponent from '../SteelDoor/MainComponent';
import Prices from '../SteelDoor/Prices';




class SteelDoors extends Component{
   


    state = {
        redirect: false,
        //Pop Up
        snackBar: false,
        snackBarText: '',
        snackBarHorizontal: 'left',
        //stepper
        steps: ['Door Configuration','Customer Details','Summary'],
        activeStep: 0,
        skipped: new Set(),
        // Calculator
        vat: '23',
        fire: 'Standard',
        productLine: 'RC4',
        grade: 'RC4',
        doorType: Prices.RC4[0].label,
        sqmPrice: Prices.RC4[0].Standard.value,
        frame: Prices.RC4[0].Standard.frame,
        description: Prices.RC4[0].description,
        visionPanelRestrictions: Prices.RC4[0].Standard.visionPanelRestrictions,
        quoteID : '',
        //width: Prices.RC4[0].Standard.maxSizes.Single.width,
        width: 800,
        //height: Prices.RC4[0].Standard.maxSizes.Single.height,
        height: 2000,
        qty: 1,
        maxWidth: Prices.RC4[0].Standard.maxSizes.Single.width,
        maxHeight:  Prices.RC4[0].Standard.maxSizes.Single.height,
        additional:  [],
        clearOpeningWidth: '',
        clearOpeningHeight: '',
        singleDouble :'Single',
        frameType: 'type-2',
        side: 'Left Hand',
        finishingPrice: 0,
        discount: 0,
        
        totalPrice: '0',
        galvanizing: '',
        handle: '',
        handleWidth: 70,
        closer: '',
        colorOutside: '',
        colorInside: '',
       
        maxVPWidth: '',
        maxVPWidth2: '',
        maxVPHeight: '',
        threshold: '20',
        doubleDoorMainLeafwidth: 0,
        swingDirection: 'Outswing',
        printRatio: 5,
        notes: '',
        allDoors: [],
        doorName:'D001',
        additionalPriceItems: [{key: "prelCost", text: "Preliminary costs", price: '0.00',  vat: '23', qty: 1, discount: 0, subitems:[], globalDiscount : false, days:15}],
        effectiveOpening: 0,

        //Vision Panels
        waFrame: false,
        visionPanel: false,
        transom:false,
        transomType:'type-2',
        leftPanel:false,
        rightPanel:false,
        transomHeight: 0, 
        leftPanelWidth: 0,
        rightPanelWidth: 0,
        visionPanels: [],
        availableGlass: [],
        thermalIsolation: false,
        // mirrorEffect: false,
        thermalIsolationValue: 0,
        // mirrorEffectValue:  0,
        
        glassHeight: '',
        glassWidth: '',
        glass: Prices.glass[0].label,
        glassValue: Prices.glass[0].value,
        visionPanelPrice: 0,
        frameValue: 0,
    

        //Ventilation

        ventilation: false,
        ventilationList: [],
        availableVentilation: [],
       

        extraOver: [],
        editPage: false,
        
        // Customer Details
        companyName: '',
        contactName: '',
        contactEmail: '',
        phoneNumber: '',
        firstAddressLine: '',
        secondAddressLine: '',
        city: '',
        county: '',
        country: 'Ireland',
        refernceNumber : '',
        //quoteId: '',
        quoteDate: moment().format('L'),
        eircode: ''
    }
    componentDidMount(){} 
  

    render() {
      

        if(this.props.loggedIn === true ){
        return (

            <MainComponent userEmail={this.props.userEmail} {...this.state} ></MainComponent>

            )
        }else{
            return <Redirect to='/'/>
        }
    }
}
export default SteelDoors;