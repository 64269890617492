import React, { Component } from 'react';
import { Redirect } from 'react-router'
// import * as firebase from 'firebase';
import firebase from "firebase/compat/app";
import { withStyles } from '@material-ui/core/styles';
import moment, { relativeTimeRounding } from 'moment';
import {Drawer, Container, Grid, Paper, TextField, Stepper, 
    Step, StepLabel, Select , MenuItem, InputLabel, FormControl,Checkbox,FormControlLabel,
    Accordion, AccordionSummary, AccordionDetails, Typography, Button, Avatar,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@material-ui/core';

import TimelineComp from '../partials/Timeline';

import EventNoteIcon from '@material-ui/icons/EventNote';
import ErrorIcon from '@material-ui/icons/Error';
// import PrintRatio from'../partials/PrintRatio';



const styles = theme => ({
    root: {
        textAlign: 'left',
      width: '100%',
      margin: '50px auto',
      '@media print':{
        margin: '0px',
        }
    },
    paper: {
        padding: '1em',
        margin: '1em',
        '@media print':{
            'box-shadow': 'none !important',
        }
    },
    outlined: {
        position: 'absolute',
        top: theme.spacing(1) * 10,
        right: theme.spacing(1) * 2,
        '@media print':{
            'display': 'none !important',
        }
    },
   
});


class DealPage extends Component{
    
    state = {
     
       
    }

    componentDidMount(){

        this.reloadData()


    }

    reloadData = () =>{

        // set refference to firebase 
        const quoteRef = firebase.database().ref(`${this.props.match.params.databaseCollection}/${this.props.match.params.projectId}`);
        
        let deal ;

        quoteRef.on('value',snap =>{
            deal = snap.val();
        })

        if(deal !== ''){

            this.setState({ 
                ...deal,
            });

        }
    }

  

    handleChange = name => event =>{ 

        this.setState({
            [name]: event.target.value
        })
        
    }

    render() {

        if(this.state.redirect === true){
            return <Redirect to='/'/>
        }

        const { classes, theme } = this.props;
       
        
        if(this.props.loggedIn === true){
            return (
                
                <div className={this.props.classes.root}>
                    <Container maxWidth="lg">
                        <Grid container  spacing={6}>
                                <Grid item md={4}>
                                    <Paper className={classes.paper} >
                                   
                                        <h2>Contact Details</h2>
                                        <hr/>
                                        <p>Prospect Name: {this.state.prospectName}</p>
                                        <p>Company: {this.state.company}</p>
                                        <p>Email: {this.state.prospectEmail}</p>
                                        <p>Phone: {this.state.prospectPhone}</p>
                               
                                    </Paper>
                                </Grid>
                                <Grid item md={8}>
                                    <Paper className={classes.paper} >
                                        <h3>Refference: {this.state.refference}</h3>
                                        <h3>Value: {this.state.value}</h3>
                                        <h2>Original Request</h2>
                                        {this.state.quote && this.state.quote.replace('Action required\r\n\t\t\t\t\t\tThere is a new request for a free quote\r\n\t\t\t\t\t\t','').split("\n").map((i,key) => {
                                            return <p style={{display: 'block'}} key={key}>{i}</p>;
                                        })}
                                     
                                    </Paper >
                                { this.state.timeline &&  
                                <TimelineComp 
                                    timeline={this.state.timeline} 
                                    date={this.state.date}
                                    projectID={this.props.match.params.projectId}
                                    databaseCollection={this.props.match.params.databaseCollection}
                                    reloadData = {this.reloadData}
                                ></TimelineComp>
                        }
                            </Grid>
                        </Grid>
                    </Container>
                </div>

            );
        }else{
                return <Redirect to='/'/>
        }
    }
  }
 
export default withStyles(styles)(DealPage);