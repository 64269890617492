import React, { Component } from 'react';
import { userContext } from '../../../Context/userContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import MenuItem from '@material-ui/core/MenuItem';
import HeightIcon from '@material-ui/icons/Height';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import Paper from '@material-ui/core/Paper';
import Slider from '@material-ui/core/Slider';
import ColorPicker from 'material-ui-color-picker'

const styles = theme => ({
    paper: {
        padding: '1rem',
        border: '1px solid #efefef',
        margin: '0.5rem 0',
        display: 'flex',
        flexDirection: 'column'
    }


});



class ConfigGlass extends Component {
    render() {
        const { classes } = this.props;
        return (<userContext.Consumer>
            {(user) => (user.access >= 1 ?
                <div style={{ flexDirection: 'column', display: 'flex', textAlign: 'left' }}>

                    <TextField
                        id="glass"
                        select
                        label="Glass type"
                        className={classes.textField}
                        value={this.props.glasse}
                        onChange={this.props.handleChange('glass')}
                        SelectProps={{
                            native: true,
                            MenuProps: {
                                className: classes.menu,
                            },
                        }}
                        helperText=""
                        margin="normal"
                    >

                        {this.props.glassTypes.map((glassType, index) => {
                            return (
                                <option key={index} value={glassType.name}>{glassType.name}</option>
                            )


                        })

                        }


                    </TextField>

                    {user.access >= 10 &&

                        <Paper className="paper" >

                            {/* {JSON.stringify(this.props.glass, null, "---")} */}




                            <h4>Glass setup - {this.props.glass.name}</h4>
                            <hr/>

                            <h4>Color</h4>

                            <ColorPicker
                                name='color'
                                //defaultValue={this.props.glass.color}
                                // value={this.props.glass.color}
                                 value={this.props.glass.color} 
                                onChange={this.props.handleGlasslSlider('color')}
                            />





                            <h4>Roughness</h4>
                            < Slider
                                value={this.props.glass.roughness}
                                aria-labelledby="discrete-slider"
                                valueLabelDisplay="auto"
                                step={0.05}
                                marks
                                min={0}
                                max={1}
                                onChange={this.props.handleGlasslSlider("roughness")}
                            />


                            <h4>Metalness</h4>
                            < Slider
                                value={this.props.glass.metalness}
                                aria-labelledby="discrete-slider"
                                valueLabelDisplay="auto"
                                step={0.05}
                                marks
                                min={0}
                                max={1}
                                onChange={this.props.handleGlasslSlider("metalness")}
                            />

                            <h4>reflectivity</h4>
                            < Slider
                                value={this.props.glass.reflectivity}
                                aria-labelledby="discrete-slider"
                                valueLabelDisplay="auto"
                                step={0.05}
                                marks
                                min={0}
                                max={1}
                                onChange={this.props.handleGlasslSlider("reflectivity")}
                            />

                            <h4>clearcoat</h4>
                            < Slider
                                value={this.props.glass.clearcoat}
                                aria-labelledby="discrete-slider"
                                valueLabelDisplay="auto"
                                step={0.05}
                                marks
                                min={0}
                                max={1}
                                onChange={this.props.handleGlasslSlider("clearcoat")}
                            />

                            <h4>opacity</h4>
                            < Slider
                                value={this.props.glass.opacity}
                                aria-labelledby="discrete-slider"
                                valueLabelDisplay="auto"
                                step={0.05}
                                marks
                                min={0}
                                max={1}
                                onChange={this.props.handleGlasslSlider("opacity")}
                            />

                            <h4>transmission</h4>
                            < Slider
                                value={this.props.glass.transmission}
                                aria-labelledby="discrete-slider"
                                valueLabelDisplay="auto"
                                step={0.05}
                                marks
                                min={0}
                                max={1}
                                onChange={this.props.handleGlasslSlider("transmission")}
                            />





                            {/* 
                            {Object.keys(this.props.glass).map((property, index) => {

                                return (


                                    <div
                                        key={index}
                                        style={{ flexBasis: "100%", margin: '20px 0 0' }}
                                    >

                                        <h4>{property}</h4>

                                        < Slider
                                            value={this.props.glassSetup[property]}
                                            aria-labelledby="discrete-slider"
                                            valueLabelDisplay="auto"
                                            step={0.05}
                                            marks
                                            min={0}
                                            max={1}
                                            onChange={this.props.handleGlasslSlider(property)}
                                        />
                                    </div>
                                )
                            })} */}


                            {/* <TextField
                                id="glassThickness"
                                number
                                label="Glass Thickness"
                                className={classes.textField}
                                value={this.props.glassThickness}
                                onChange={this.props.handleChange('glassThickness')}
                    
                                helperText=""
                                margin="normal"
                            >
                   

                            </TextField> */}



                            <TextField
                                label="Glass Thickness"
                                onChange={this.props.handleChange('glassThickness')}
                                value={this.props.glassThickness}
                                style={{ flexBasis: "100%", margin: '20px 0 0' }}
                                type="number"
                                InputProps={{ inputProps: { max: 1500, min: -1500 } }}
                            ></TextField>


                      








                        </Paper>



                    }


                </div> : <> {this.state.spinner ? <CircularProgress /> : <span></span>}  </>
            )}</userContext.Consumer>)
    }
}

export default withStyles(styles)(ConfigGlass)