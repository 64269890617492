import React, { Component } from 'react';

class SideAndSwing extends Component{

    state= {}

    side = () => {
        if(this.props.side === 'Right Hand'){
            return 'right';
        }else if(this.props.side === 'Left Hand' ){
            return 'left';
        } 
    }

    side = () => {
        if(this.props.side === 'Right Hand'){
            return {height: '45px', width: '2px',  border: '1px solid black', right: '3px',position: 'absolute'};
        }else if(this.props.side === 'Left Hand' ){
            return {height: '45px', width: '2px',  border: '1px solid black', left: '5px',position: 'absolute'};
        } 
    }

    curve = () => {
        if(this.props.side === 'Left Hand'){
            return 'borderBottomRightRadius';
        }else if(this.props.side === 'Right Hand' ){
            return 'borderBottomLeftRadius';
        } 
    }

    symbol = () =>{
        // Changed from PL to IE siede 
        if(this.props.side === 'Left Hand'){
            return 'RH';
            // Changed from PL to IE siede 
        }else if(this.props.side === 'Right Hand' ){
            return 'LH';
        } 
    }

    render() {
        
        return (
            <div id="sideAndSwing" style={{width: '60px', position: 'absolute', right:'10px', top: '10px'}}> 
            {this.props.swingDirection === "Inswing" ? <h6 style={{margin:'0'}} >Outside</h6> : <h6 style={{margin:'0'}} >Inside</h6> }
                <div style={{position: 'relative', padding: '5px', width: '100%', boxSizing: 'border-box', height: '65px'}}>

                <h5 style={{position: 'absolute', top:  '50%', left: '50%', transform: 'translate(-50% ,-50%)', margin: 0}}>{this.symbol()}</h5>

                    <div style={{width: '100%', border: '1px solid black', height: '5px'}} ></div>
                    <div style={this.side()}></div>
                    <div style={{ 
                        border: '1px solid black',
                        width: 'calc(100% - 10px)',
                        position: 'absolute',
                        height: '47px',
                        [this.curve()]: '100%',
                        top: '11px'
                        }}></div>
                </div>
                {this.props.swingDirection === "Inswing" ? <h6 style={{margin:'0'}} >Inside</h6> : <h6 style={{margin:'0'}} >Outside</h6> }
            </div>
       )
    }
}

export default SideAndSwing;