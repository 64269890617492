import React, { Component } from 'react';

class Closer1 extends Component{

    state= {}

    closerSide = () => {

        if(this.props.handleSide === "left"){
            return 'right';
        }else{
            return 'left';
        }

    }

    color = () =>{
        if(this.props.inside){
            return('#000');
        }else{
            return('#c5c3c3');
        }
    }

    zindex = () =>{

        if(this.props.inside === false){
            return('-1000');
        }else{
            return('1000');
        }

    }
    
    render() {
        return (
            <div>
                <div style={{ height: `${10/this.props.printRatio}px`,  width: `${550/this.props.printRatio}px`, border: `1px solid ${this.color()}`, position: 'absolute', margin: '0 auto',[this.closerSide()]: `${75/this.props.printRatio}px`, top: `${-20/this.props.printRatio}px`,}}></div>
                <div style={{ height: '1px',  width: `${20/this.props.printRatio}px`, border: `1px solid ${this.color()}`, position: 'absolute', margin: '0 auto',[this.closerSide()]: `${120/this.props.printRatio}px`, top: '-1px',}}></div>
                <div style={{ height: `${75/this.props.printRatio}px`,  width: `${300/this.props.printRatio}px`, border: `1px solid ${this.color()}`, position: 'absolute', margin: '0 auto',[this.closerSide()]: `${75/this.props.printRatio}px`, top: '1px',}}></div>
            </div>  
       )
    }
}

export default Closer1;