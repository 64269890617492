import { TotalValue, TotalVat, TotalCost, roundToTwo } from '../logic/GetData';

export const suppliers = [
    {
        value: 'Donimet',
        label: 'Donimet',
    },
    {
        value: 'OC Slim',
        label: 'OC Slim',
    },
    {
        value: 'Interproduct',
        label: 'Interproduct',
    },
    {
        value: 'Pagen',
        label: 'Pagen',
    },
    {
        value: 'Entra',
        label: 'Entra',
    },
    {
        value: 'Anthony',
        label: 'Anthony',
    },
    {
        value: 'Other',
        label: 'Other',
    },


]

export const costCategories = [
    "Training and education",
    "Advertising and marketing",
    "Website and software expenses",
    "Office supplies",
    "Furniture, equipment, and machinery",
    "Utilities",
    "Rent or mortgage payments",
    "Entertainment",
    "Business meals and travel expenses",
    "Vehicle expenses",
    "Payroll",
    "Employee benefits",
    "Taxes",
    "Business insurance",
    "Business licenses and permits",
    "Interest payments and bank fees",
    "Professional fees and business services",
]
// source : https://www.brex.com/blog/business-expense-categories/



export const FinReport = (orders = [], generalCosts = [], year, month) => {

    let costs = []
    let incomes = []
    let totalCosts = 0
    let totalIncome = 0
  


    // Jakim hujem te ordery sie nadpisuja !!!!!!!!!!!

    // let index = orders.findIndex(ord => ord[0] === "-N912rFxdl02UzWm6BV5")
    // console.log(index , orders[index][1].Income)

  


    const checkDate = (date) => {
        if (month === undefined) {
            return (date.substring(0, 4) === `${year}`)
        } else {
            return (date.substring(0, 7) === `${year}-${month}`)
        }
    }
    // -------- -------- 
    // -------- Orders
    // -------- -------- 

    orders.forEach(order => {

        let orderValue = TotalValue(order[0])

        // Cost

        if (order[1].Costs !== undefined) {

            for (const cost in order[1].Costs) {

                let el = order[1].Costs[cost]

                if (checkDate(el.date)) {

                    let cst = el
                    cst.relatedTo = order[1].OrderNumber
                    cst.relatedToLink = order[0]

                    totalCosts += Number(cst.cost)
                    costs.push(cst)
                }
            }

        }


        // Income

     



        if (order[1].Income !== undefined) {

            // if(order[0] === "-N912rFxdl02UzWm6BV5"){
            //     console.log(order[1])
            // }



            let suppliers = []

            // Suppliers
            for (const item in order[1].AllItems) {

                let el = order[1].AllItems[item]

                if (el.supplier !== undefined) {
                    let proc = 0

                    if (orderValue !== 0) {

                        proc = (Number(((el.price - (el.price * (1 - (100 - el.discount) / 100))) * el.qty)) / Number(orderValue))
                    }

                    suppliers.push({ supplier: el.supplier, procentage: proc })
                }
            }







            /// Test start

            // let check = 0

            // suppliers.map(sup =>{
            //     check +=  sup.procentage
            // })

            // if (check !== 1 && check !== 0){
            //     console.log(order[0], check)
            // }


            // Test end









            // All incomes in order

            for (const id in order[1].Income) {

                let income = order[1].Income[id]

                if (checkDate(income.date)) {

                    let inc = {}

                    inc.invoiceNo = income.invoiceNo
                    inc.date = income.date
                    inc.suppliers = suppliers
                    inc.orderValue = roundToTwo(order[1].orderValue)
                    inc.relatedToOrder = order[0]
                    inc.orderNumber = order[1].OrderNumber

                    if (income.cnValue !== undefined) {
                        inc.Amount = Number(income.Amount) - Number(income.cnValue)
                    }else{
                        inc.Amount = Number(income.Amount)
                    }
                     
                    totalIncome += Number(inc.Amount )

                    incomes.push(inc)

                }

            }
  
        }
    });

    // -------- -------- -------- 
    // -------- General costs
    // -------- -------- -------- 

  

    //Create gategories
    let agregatedCostByCategory = []
    costCategories.map(category =>{
        agregatedCostByCategory.push({name: category, value : 0})
    })



    for (const cost in generalCosts) {

       

        let el = generalCosts[cost]

        if (checkDate(el[1].date)) {


            

            let cst = el[1]
            cst.relatedTo = el[1].costCategory

            


            // Add cost to category 
            let index = agregatedCostByCategory.findIndex(cost=> cost.name === el[1].costCategory )

            console.log(index)

            agregatedCostByCategory[index].value +=  Number(cst.cost)

            

            totalCosts += Number(cst.cost)

            costs.push(cst)
        }
    }

   



    // Fonction return 

    return ({
        costs,
        incomes,
        totalCosts,
        totalIncome,
        agregatedCostByCategory
    })
}