import React, { Component } from 'react';
import Cylinder from '../../../SteelDoor/Drawings/ironmongery/cylinder';

class ROMlock extends Component{

    state= {}
    render() {

        return (
            <div style={{ height: `${250/this.props.printRatio}px`,  width: `${125/this.props.printRatio}px`, border: '1px solid #c5c3c3', position: 'absolute',margin: '0 auto',[this.props.handleSide]: `${20/this.props.printRatio}px`,bottom: `${this.props.bottom/this.props.printRatio}px`,background: 'white'}}>   
                <div style={{ height: `${30/this.props.printRatio}px`,  width: `${15/this.props.printRatio}px`, border: '1px solid #c5c3c3', position: 'absolute',margin: '0 auto',[this.props.handleSide]: `${-25/this.props.printRatio}px`,bottom: `50%`,}}></div>
                { this.props.cylinder === true &&  < Cylinder printRatio={this.props.printRatio} ></Cylinder> }
               
            </div>
        )
    }
}

export default ROMlock;