import React, { Component } from 'react';

class ROMlock extends Component{

    state= {}
    render() {

        return (
            <div style={{ height: `${150/this.props.printRatio}px`,  width: `${125/this.props.printRatio}px`, border: '1px solid #c5c3c3', position: 'absolute',margin: '0 auto',[this.props.handleSide]: `${20/this.props.printRatio}px`,bottom: `${this.props.bottom/this.props.printRatio}px`,background: 'white'}}>   
                <div style={{ height: `${30/this.props.printRatio}px`,  width: `${15/this.props.printRatio}px`, border: '1px solid #c5c3c3', position: 'absolute',margin: '0 auto',[this.props.handleSide]: `${-25/this.props.printRatio}px`,bottom: `50%`,}}></div>
                <div style={{ height: `1px`,  width: `${30/this.props.printRatio}px`, borderBottom: '2px solid #000', position: 'absolute',margin: '0 auto',left: '50%',bottom: `50%`, transform: 'translate(-50%)'}}></div>
            </div>
        )
    }
}

export default ROMlock;