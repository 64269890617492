import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
//ironmongery
import Handle from '../../SteelDoor/Drawings/ironmongery/handle';
import PanicBar from '../../SteelDoor/Drawings/ironmongery/panicBar';
import Lock from '../../SteelDoor/Drawings/ironmongery/lock';
import Lock2 from '../../SteelDoor/Drawings/ironmongery/lock2';
import Hinges from '../../SteelDoor/Drawings/ironmongery/hinges';
import AntiBurglaryBolts from '../../SteelDoor/Drawings/ironmongery/antiBurglaryBolts';
import Closer1 from '../../SteelDoor/Drawings/ironmongery/closer1';
import Closer2 from '../../SteelDoor/Drawings/ironmongery/closer2';
import Closer3 from '../../SteelDoor/Drawings/ironmongery/closer3';
import OpeningDirection from '../../SteelDoor/Drawings/ironmongery/openingDirection';
import Knob from '../../SteelDoor/Drawings/ironmongery/knob';

import SecuritySeal from '../../SteelDoor/Drawings/ironmongery/securitySeal';
import PushPull from '../../SteelDoor/Drawings/ironmongery/pushPull';
import PushPull2 from '../../SteelDoor/Drawings/ironmongery/pushPull2';
import ROMlock from '../../SteelDoor/Drawings/ironmongery/romlock';
import ROMlock2 from '../../SteelDoor/Drawings/ironmongery/romlock2';
import SlidingBoltLock from '../../SteelDoor/Drawings/ironmongery/slidingBoltLock';
import SpyHole from '../../SteelDoor/Drawings/ironmongery/spyHole';
import DropDownHatch from '../../SteelDoor/Drawings/ironmongery/dropDownHatch';
import Hatch from '../../SteelDoor/Drawings/ironmongery/Hatch';
import DigitalLock from '../../SteelDoor/Drawings/ironmongery/digitalLock';
import ArmouredCable from '../../SteelDoor/Drawings/ironmongery/armouredCable';
import ElectricStrike from '../../SteelDoor/Drawings/ironmongery/electricStrike';
import AirGrill from '../../SteelDoor/Drawings/ironmongery/airGrill';
import AirGrill2 from '../../SteelDoor/Drawings/ironmongery/airGrill2';
import RoundVent from '../../SteelDoor/Drawings/ironmongery/roundVent';
import AirVentGrill from '../../SteelDoor/Drawings/ironmongery/airVentGrill';
import Louver from '../../SteelDoor/Drawings/ironmongery/louver';
import PrisonSlideLatch from '../../SteelDoor/Drawings/ironmongery/prisonSlideLatch';
import AntiLigatureHandle from '../../SteelDoor/Drawings/ironmongery/antiLigatureHandle';
import CombinationLock from '../../SteelDoor/Drawings/ironmongery/combinationLock';
import Pushplate from '../../SteelDoor/Drawings/ironmongery/pushplate';
import Kickplate3 from '../../SteelDoor/Drawings/ironmongery/kickplate3';
import PrisonChain from '../../SteelDoor/Drawings/ironmongery/prisonChain';
import BarsRC3 from '../../SteelDoor/Drawings/barsRC3';
import SlideBarLatch from '../../SteelDoor/Drawings/ironmongery/slideBarLatch';
import FlushPull from '../../SteelDoor/Drawings/ironmongery/flushPull';

const styles = theme => ({
    glass: {
        background: 'rgba(226,226,226,0.4)',
        background: '-moz-linear-gradient(-45deg, rgba(226,226,226,0.4) 0%, rgba(219,219,219,0.5) 40%, rgba(209,209,209,0.5) 46%, rgba(254,254,254,0.4) 100%)',
        background: '-webkit-gradient(left top, right bottom, color-stop(0%, rgba(226,226,226,0.4)), color-stop(40%, rgba(219,219,219,0.5)), color-stop(46%, rgba(209,209,209,0.5)), color-stop(100%, rgba(254,254,254,0.4)))',
        background: '-webkit-linear-gradient(-45deg, rgba(226,226,226,0.4) 0%, rgba(219,219,219,0.5) 40%, rgba(209,209,209,0.5) 46%, rgba(254,254,254,0.4) 100%)',
        background: '-o-linear-gradient(-45deg, rgba(226,226,226,0.4) 0%, rgba(219,219,219,0.5) 40%, rgba(209,209,209,0.5) 46%, rgba(254,254,254,0.4) 100%)',
        background: '-ms-linear-gradient(-45deg, rgba(226,226,226,0.4) 0%, rgba(219,219,219,0.5) 40%, rgba(209,209,209,0.5) 46%, rgba(254,254,254,0.4) 100%)',
        background: 'linear-gradient(135deg, rgba(226,226,226,0.4) 0%, rgba(219,219,219,0.5) 40%, rgba(209,209,209,0.5) 46%, rgba(254,254,254,0.4) 100%)',
        filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2e2e2', endColorstr='#fefefe', GradientType=1 )",
    },
    visionPanel: {
        marginLeft: `auto`,
        border: '1px dashed #bdbdbd',
        color: '#eee',
        marginRight: `auto`,
        '@media print': {
            border: 'none',
        },
    }
})

class ActiveLeaf extends Component {

    state = {}

    singleDouble = () => {
        if (this.props.singleDouble === "Single") {
            return (Number(this.props.mainLeaf) + this.props.clearOpeningWidth);
        } else {
            return (Number(this.props.mainLeaf) + (this.props.clearOpeningHeight / 2));
        }
    }

    checkIfOhefItemExist = (item) => {

        let itemIndex = this.props.additional.findIndex(x => x.label.includes(item));

        if (itemIndex === -1) {
            return false;
        } else {
            return true;
        }
    }

    frame = () => {
        if (this.props.frameType === "type-2") {
            return ({
                height: `${((this.props.height) / this.props.printRatio) + (((this.props.clearOpeningHeight) / this.props.printRatio) * 0.3) - 2}px`,
                width: `${this.singleDouble() / this.props.printRatio}px`,
                border: '1px solid black',
                position: 'absolute',
                margin: '0 auto',
                [this.props.doorSide]: `${(((this.props.clearOpeningWidth / 1.4) / this.props.printRatio) * 0.5) - 1}px`,
                top: `${(((this.props.clearOpeningHeight) / this.props.printRatio) * 0.7) - 1}px`,
                background: 'none',
            })
        } else {
            return ({
                height: `${((this.props.height) / this.props.printRatio) + (((this.props.clearOpeningHeight) / this.props.printRatio) * 0.3)}px`,
                width: `${(this.singleDouble() + 30) / this.props.printRatio}px`,
                border: '1px solid black',
                position: 'absolute',
                margin: '0 auto',
                [this.props.doorSide]: `${(((this.props.clearOpeningWidth + 60) / this.props.printRatio) * 0.5)}px`,
                top: `${(((this.props.clearOpeningHeight + 60) / this.props.printRatio) * 0.7)}px`,
                background: 'none',
            })
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div id="MainLeaf"
                style={this.frame()}>
                {this.props.singleDouble === 'Single' &&
                    <small style={{ bottom: '-20px', position: 'absolute', transform: 'translate(-50%)' }}>{this.props.width}mm</small>
                }
                {this.props.singleDouble === 'Double' &&
                    <small style={{ bottom: '-20px', position: 'absolute', transform: 'translate(-50%)' }}> {this.props.doubleDoorMainLeafwidth}mm</small>
                }
                {this.props.doorType === "Gate RC3" &&

                    < BarsRC3 width={Number(this.props.mainLeaf)} printRatio={this.props.printRatio} height={this.props.height} singleDouble={this.props.singleDouble}></BarsRC3>
                }
                <OpeningDirection side={this.props.doorSide} printRatio={this.props.printRatio}></OpeningDirection>

                <Hinges side={this.props.doorSide} printRatio={this.props.printRatio} fire={this.props.fire} grade={this.props.grade}></Hinges>
                <AntiBurglaryBolts side={this.props.doorSide} printRatio={this.props.printRatio}></AntiBurglaryBolts>


                {this.props.doorType !== "Gate RC3" &&
                    <div className={classes.visionPanel} style={{
                        marginTop: `${this.props.visionPanelRestrictions.top / this.props.printRatio}px`,
                        marginBottom: ` ${this.props.visionPanelRestrictions.bottom / this.props.printRatio}px`,
                        width: `${Number(this.props.maxVPWidth) / this.props.printRatio}px`,
                        height: `${Number(this.props.maxVPHeight) / this.props.printRatio}px`,
                    }}>

                        <div id="glassWrapper" style={{ position: 'relative', height: '100%' }}>

                            {this.props.visionPanel === true &&

                                this.props.visionPanels.filter(panel => panel.mainDoor).map(panel => {



                                    return <div
                                        className={classes.glass}
                                        key={panel.key}
                                        style={{
                                            width: `${panel.glassWidth / this.props.printRatio}px`,
                                            height: `${panel.glassHeight / this.props.printRatio}px`,
                                            border: '1px solid rgba(0,0,0,0.2)',
                                            position: 'Absolute',
                                            bottom: `${panel.bottom / this.props.printRatio}px`,
                                            left: `${panel.left / this.props.printRatio}px`,
                                        }}>
                                        {/* <div style={{color: 'red'}}>{panel.key}</div> */}
                                    </div>
                                })
                            }

                            {this.props.ventilation === true &&
                                <span>
                                    {
                                        this.props.ventilationList.map(vent => {

                                            if (vent.fixing === "door") {

                                                if (vent.label.includes('Round vent sleeve')) {
                                                    if (vent.mainDoor) {
                                                        return (<RoundVent key={vent.key} left={vent.left} bottom={vent.bottom} printRatio={this.props.printRatio} id={vent.key} ></RoundVent>)
                                                    }
                                                }

                                                if (vent.label.includes('grill 400mm')) {
                                                    if (vent.mainDoor) {
                                                        return (<AirGrill key={vent.key} left={vent.left} bottom={vent.bottom} printRatio={this.props.printRatio} id={vent.key}></AirGrill>)
                                                    }
                                                }

                                                if (vent.label.includes('grill 500mm')) {
                                                    if (vent.mainDoor) {
                                                        return (<AirGrill2 key={vent.key} left={vent.left} bottom={vent.bottom} printRatio={this.props.printRatio} id={vent.key}></AirGrill2>)
                                                    }
                                                }

                                                if (vent.label.includes('Air vent grill')) {
                                                    if (vent.mainDoor) {
                                                        return (<AirVentGrill key={vent.key} left={vent.left} height={vent.height} width={this.props.maxVPWidth} bottom={vent.bottom} printRatio={this.props.printRatio} id={vent.key}></AirVentGrill>)
                                                    }
                                                }

                                                if (vent.label.includes('Louver vent')) {
                                                    if (vent.mainDoor) {
                                                        return (<Louver key={vent.key} left={vent.left} height={vent.height} width={this.props.maxVPWidth} bottom={vent.bottom} printRatio={this.props.printRatio} id={vent.key}></Louver>)
                                                    }
                                                }
                                            }
                                        })
                                    }
                                </span>
                            }

                        </div>

                    </div>
                }


                {this.props.additional.map((opt, index) => {

                    if (

                        opt.label === 'Main Lock - ISEO Multiblindo eMotion'
                        || opt.label === 'Main Lock - ABLOY EL566/567'
                        || opt.label === 'Main Lock - ABLOY EL166'
                        || opt.label === 'Main Lock - ABLOY EL520 MP'

                    ) {

                        if (this.props.additional.some(ditem => ditem.label.includes('Cylinder'))) {
                            return (<Lock2 key={index} handleSide={this.props.handleSide} printRatio={this.props.printRatio} doorType={this.props.doorType} bottom={opt.bottom} cylinder={true}>1</Lock2>)
                        } else {
                            return (<Lock2 key={index} handleSide={this.props.handleSide} printRatio={this.props.printRatio} doorType={this.props.doorType} bottom={opt.bottom}></Lock2>)
                        }


                    }

                    if (


                        opt.label === 'Main Lock - ISEO Multiblindo eMotion EXIT'

                    ) {



                        if (this.props.additional.some(ditem => ditem.label.includes('Cylinder'))) {
                            return (


                                <div>
                                    <Lock2 key={index} handleSide={this.props.handleSide} printRatio={this.props.printRatio} doorType={this.props.doorType} bottom={opt.bottom} cylinder={true}>1</Lock2>

                                    <PanicBar handleSide={this.props.handleSide} printRatio={this.props.printRatio} bottom={opt.bottom}></PanicBar>
                                    <Handle key={index} handleSide={this.props.handleSide} printRatio={this.props.printRatio} inside={opt.inside} bottom={Number(opt.bottom) + 170}></Handle>
                                </div>


                            )
                        } else {
                            return (<Lock2 key={index} handleSide={this.props.handleSide} printRatio={this.props.printRatio} doorType={this.props.doorType} bottom={opt.bottom}></Lock2>)
                        }

                    }

                    if (
                        opt.label.includes('Main Lock - Class.7 + Defender')
                        || opt.label.includes('Main Lock - Classroom Lock')

                        || opt.label === 'Main Lock - KALE 352R Class.6 + Defender'

                    ) {

                        if (this.props.additional.some(ditem => ditem.label.includes('Cylinder'))) {
                            return (<Lock key={index} handleSide={this.props.handleSide} printRatio={this.props.printRatio} doorType={this.props.doorType} bottom={opt.bottom} cylinder={true}>1</Lock>)
                        } else {
                            return (<Lock key={index} handleSide={this.props.handleSide} printRatio={this.props.printRatio} doorType={this.props.doorType} bottom={opt.bottom}></Lock>)
                        }
                    }



                    if (opt.label === 'Main Lock - ABLOY EL560/561') {
                        return (<div>

                            <ROMlock2 key={index} handleSide={this.props.handleSide} printRatio={this.props.printRatio} bottom={opt.bottom}></ROMlock2>


                        </div>)
                    }

                    if (opt.label.includes('Combination lock')) {
                        return (<CombinationLock key={index} handleSide={this.props.handleSide} printRatio={this.props.printRatio} bottom={opt.bottom} sideDist={opt.sideDist}></CombinationLock>)
                    }

                    if (opt.label.includes('Pushplate 305x76')) {
                        return (<Pushplate key={index} handleSide={this.props.handleSide} printRatio={this.props.printRatio} bottom={opt.bottom} sideDist={opt.sideDist} ></Pushplate>)
                    }

                    if (opt.label.includes('Kick plate sqm')) {
                        if (opt.leafChoice) {
                            return (<Kickplate3 key={index} handleSide={this.props.handleSide} printRatio={this.props.printRatio} bottom={opt.bottom} sideDist={opt.sideDist} width={opt.width} height={opt.height} inside={opt.inside} swingDirection={this.props.swingDirection}></Kickplate3>)
                        }
                    }


                    if (opt.label.includes('Push plate sqm')) {
                        if (opt.leafChoice) {
                            return (<Kickplate3 key={index} handleSide={this.props.handleSide} printRatio={this.props.printRatio} bottom={opt.bottom} sideDist={opt.sideDist} width={opt.width} height={opt.height} inside={opt.inside} swingDirection={this.props.swingDirection}></Kickplate3>)
                        }
                    }

                    if (opt.label.includes('Prison chain')) {
                        return (<PrisonChain key={index} side={this.props.handleSide} printRatio={this.props.printRatio} bottom={opt.bottom} sideDist={opt.sideDist} ></PrisonChain>)
                    }

                    if (opt.label.includes('guide rail') || opt.label.includes('DC 500')) {
                        if (opt.leafChoice) {
                            return (<Closer1 key={index} handleSide={this.props.handleSide} printRatio={this.props.printRatio} inside={opt.inside}></Closer1>)
                        }
                    }

                    if (opt.label.includes('with link arm')) {
                        if (opt.leafChoice) {
                            return (<Closer2 key={index} handleSide={this.props.handleSide} printRatio={this.props.printRatio} inside={opt.inside}></Closer2>)
                        }
                    }

                    if (opt.label.includes('DC340 with integrated mechanical coordinator') || opt.label.includes('DC500 with integrated mechanical coordinator')) {
                        return (<Closer3 key={index} handleSide={this.props.handleSide} printRatio={this.props.printRatio} inside={opt.inside}></Closer3>)
                    }

                    if (opt.label.includes('Handle/Handle AH') || opt.label.includes('Handle/Handle AH')) {
                        return (<Handle key={index} handleSide={this.props.handleSide} printRatio={this.props.printRatio} inside={opt.inside} bottom={opt.bottom}></Handle>)
                    }


                    if (opt.label === 'Assa Abloy Fixed Knob/Panic Bar PBE011') {
                        return (<div>
                            <PanicBar handleSide={this.props.handleSide} printRatio={this.props.printRatio} bottom={opt.bottom} ></PanicBar>
                            <Knob key={index} handleSide={this.props.handleSide} printRatio={this.props.printRatio} bottom={Number(opt.bottom) + 140}></Knob>
                        </div>)
                    }

                    if (opt.label === 'Assa Abloy Handle AHW500/Panic Bar PBE011') {
                        return (<div>
                            <PanicBar handleSide={this.props.handleSide} printRatio={this.props.printRatio} bottom={opt.bottom} ></PanicBar>
                            <Handle key={index} handleSide={this.props.handleSide} printRatio={this.props.printRatio} inside={opt.inside} bottom={Number(opt.bottom) + 140}></Handle>
                        </div>)
                    }


                    if (opt.label.includes('Panic bar NEMEF')) {
                        return (<div>

                            <ROMlock2 key={index} handleSide={this.props.handleSide} printRatio={this.props.printRatio} bottom={opt.bottom}></ROMlock2>
                            <PanicBar handleSide={this.props.handleSide} printRatio={this.props.printRatio} bottom={opt.bottom} ></PanicBar>

                        </div>)
                    }

                    if (opt.label.includes('Handle/Handle NEMEF')) {
                        return (<div>

                            <Lock handleSide={this.props.handleSide} printRatio={this.props.printRatio} doorType={this.props.doorType} cylinder={true} bottom={opt.bottom - 150}></Lock>
                            <Handle key={index} handleSide={this.props.handleSide} printRatio={this.props.printRatio} inside={opt.inside} bottom={opt.bottom}></Handle>
                        </div>
                        )
                    }

                    if (opt.label.includes('Handle AH') || opt.label.includes('Handle split')) {
                        return (<Handle key={index} handleSide={this.props.handleSide} printRatio={this.props.printRatio} inside={opt.inside} bottom={opt.bottom}></Handle>)
                    }

                    if (opt.label.includes('Knob/Knob') || opt.label.includes('Knob/Handle')) {
                        return (<Knob key={index} handleSide={this.props.handleSide} printRatio={this.props.printRatio} bottom={opt.bottom}></Knob>)
                    }

                    if (opt.label.includes('Push/Pull handle - active leaf')) {
                        return (<PushPull key={index} side={this.props.handleSide} printRatio={this.props.printRatio} sideDist={opt.sideDist} bottom={opt.bottom}></PushPull>)
                    }




                    if (opt.label.includes('Push/Pull handle type 2 - active leaf')) {
                        return (<PushPull2 key={index} side={this.props.handleSide} printRatio={this.props.printRatio} sideDist={opt.sideDist} bottom={opt.bottom}></PushPull2>)
                    }


                    if (opt.label.includes('Security seal')) {
                        return (<SecuritySeal key={index} side={this.props.handleSide} side2={this.props.doorSide} printRatio={this.props.printRatio} bottom={opt.bottom}></SecuritySeal>)
                    }

                    if (opt.label.includes('Main Lock - Panic bar Mottura Class.7 - Bar/Handle')
                        || opt.label.includes('Main Lock - ISEO Multiblindo eMotion EXIT')
                    ) {

                        return (
                            <span key={index}>
                                <Lock handleSide={this.props.handleSide} printRatio={this.props.printRatio} doorType={this.props.doorType} bottom={opt.bottom}></Lock>
                                <PanicBar handleSide={this.props.handleSide} printRatio={this.props.printRatio} bottom={opt.bottom}></PanicBar>
                                <Handle key={index} handleSide={this.props.handleSide} printRatio={this.props.printRatio} inside={opt.inside} bottom={Number(opt.bottom) + 170}></Handle>
                            </span>
                        )
                    }

                    if (opt.label.includes('Main Lock - Panic bar Mottura Class.7 - Bar/(no handle outside')) {

                        return (
                            <span key={index}>
                                <Lock handleSide={this.props.handleSide} printRatio={this.props.printRatio} doorType={this.props.doorType} bottom={opt.bottom}></Lock>
                                <PanicBar handleSide={this.props.handleSide} printRatio={this.props.printRatio} bottom={opt.bottom}></PanicBar>

                            </span>
                        )
                    }



                    if (opt.label.includes('Lock ROM 1') || opt.label.includes('Lock KALE 257')) {
                        return (<ROMlock key={index} handleSide={this.props.handleSide} printRatio={this.props.printRatio} bottom={opt.bottom}></ROMlock>)
                    }

                    if (opt.label.includes('ROM 2')) {

                        if (this.props.additional.some(ditem => ditem.label.includes('Cylinder'))) {
                            return (<ROMlock2 key={index} handleSide={this.props.handleSide} printRatio={this.props.printRatio} bottom={opt.bottom} cylinder={true}></ROMlock2>)
                        } else {
                            return (<ROMlock2 key={index} handleSide={this.props.handleSide} printRatio={this.props.printRatio} bottom={opt.bottom}></ROMlock2>)
                        }

                    }

                    if (opt.label.includes('Flush pull (Donimet)')) {
                        return (<FlushPull key={index} handleSide={this.props.handleSide} printRatio={this.props.printRatio} bottom={opt.bottom}></FlushPull>)
                    }

                    if (opt.label.includes('Sliding bolt')) {
                        return (<SlidingBoltLock key={index} handleSide={this.props.handleSide} printRatio={this.props.printRatio}></SlidingBoltLock>)
                    }

                    if (opt.label.includes('Spyhole')) {
                        return (<SpyHole key={index} handleSide={this.props.handleSide} printRatio={this.props.printRatio} bottom={opt.bottom}></SpyHole>)
                    }

                    if (opt.label.includes('Prison spyhole BR2"S"')) {
                        return (<SpyHole key={index} handleSide={this.props.handleSide} printRatio={this.props.printRatio} bottom={opt.bottom}></SpyHole>)
                    }

                    if (opt.label.includes('Prison drop down hatch')) {
                        return (<DropDownHatch key={index} handleSide={this.props.handleSide} printRatio={this.props.printRatio} bottom={opt.bottom}></DropDownHatch>)
                    }

                    if (opt.label.includes('Side swing hatch')) {
                        return (<Hatch key={index} handleSide={this.props.handleSide} printRatio={this.props.printRatio} bottom={opt.bottom}></Hatch>)
                    }

                    if (opt.label.includes('Digital lock FIAM X1R')) {
                        return (<DigitalLock key={index} handleSide={this.props.handleSide} printRatio={this.props.printRatio} bottom={opt.bottom}></DigitalLock>)
                    }

                    if (opt.label.includes('Main Lock - FIAM X1R kl.7')) {
                        if (this.props.additional.some(ditem => ditem.label.includes('Cylinder'))) {
                            return (<Lock key={index} handleSide={this.props.handleSide} printRatio={this.props.printRatio} doorType={this.props.doorType} bottom={opt.bottom} cylinder={true}></Lock>)
                        } else {
                            return (<Lock key={index} handleSide={this.props.handleSide} printRatio={this.props.printRatio} doorType={this.props.doorType} bottom={opt.bottom}></Lock>)
                        }
                    }

                    if (opt.label.includes('Armoured cable door')) {

                        if (this.checkIfOhefItemExist('Digital lock FIAM X1R')) {
                            return (<ArmouredCable key={index} side={this.props.doorSide} printRatio={this.props.printRatio}></ArmouredCable>)
                        }
                    }

                    if (opt.label.includes('Electric strike')) {
                        return (<ElectricStrike key={index} side={this.props.handleSide} bottom={opt.bottom} printRatio={this.props.printRatio}></ElectricStrike>)
                    }

                    if (opt.label.includes('2 Flushed slide bar latches')) {
                        return (<PrisonSlideLatch key={index} side={this.props.handleSide} printRatio={this.props.printRatio}></PrisonSlideLatch>)
                    }

                    if (opt.label.includes('Anti ligature handle')) {
                        return (<AntiLigatureHandle key={index} side={this.props.handleSide} printRatio={this.props.printRatio} bottom={opt.bottom}></AntiLigatureHandle>)
                    }

                    if (opt.label.includes('Slide bar latch')) {
                        return (<SlideBarLatch key={index} side={this.props.handleSide} printRatio={this.props.printRatio} bottom={opt.bottom} inside={opt.inside}></SlideBarLatch>)
                    }

                })}

            </div>
        )
    }
}

export default withStyles(styles)(ActiveLeaf);