import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import EditManu from '../partials/EditMenu';

// import * as firebase from 'firebase'
import firebase from "firebase/compat/app";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';


const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(2),
        overflowX: 'auto',
    },
    table: {
         minWidth: 700,
    },
    paper:{
        margin: '2%',
        overflowX: 'scroll'
    },
});

class CostsTable extends Component{


    state = {
        costs: [],
        anchorEl: null,
    }
    componentDidMount(){
        // set refference to firebase 
        const ordersRef = firebase.database().ref(`Orders/${this.props.orderId}/Costs`);
        
        //load all orderes
        ordersRef.on('value',snap =>{
            
            let costsFB = snap.val();

            if(costsFB !== null){
                let output = Object.entries(costsFB).map(([key, value]) => ({key,value}));

                this.setState({ 
                    costs: output , 
                });
  
            }else{
                this.setState({
                    costs: []
                })
            }
        })

       
        
    }
    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
      };
    
    handleClose = () => {
        this.setState({ anchorEl: null });
    };




    render() {
        return (
            
            <Paper className={this.props.classes.paper} elevation={0} variant="outlined">
            <Table className={this.props.classes.table}>
                <TableHead>
                <TableRow>
                    <TableCell>Cost Name</TableCell>
                    <TableCell>Invoice No</TableCell>
                    <TableCell>Net Cost</TableCell>
                    <TableCell>Vat Rate</TableCell>
                    
                    <TableCell>VAT</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell></TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
              
                {this.state.costs.map((cost)=>{ 
                        return (
                            <TableRow key={cost.key}>

                                <TableCell >{cost.value.costName}</TableCell>
                                <TableCell>{cost.value.invoiceNo}</TableCell>
                                <TableCell>{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format(cost.value.cost)}</TableCell>
                                <TableCell>{cost.value.vatRate}%</TableCell>
              
                                <TableCell>{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format((cost.value.cost*("1."+cost.value.vatRate))-cost.value.cost)}</TableCell>
                                <TableCell>{cost.value.date}</TableCell>
                                <TableCell>
                                    <EditManu 
                                        mainGroup="Orders"
                                        parentType='Costs'
                                        orderId={this.props.orderId}
                                        recordKey={cost.key}
                                        update={[
                                            { type:'text', id:'costName', label: 'Cost Name', value: cost.value.costName},
                                            { type:'text', id:'cost', label: 'Cost ', value: cost.value.cost},
                                            { type:'text', id:'invoiceNo', label: 'Invoice No ', value: cost.value.invoiceNo},
                                            { type:'select', id:'vatRate', label: 'VAT rate ', value: cost.value.vatRate, options: [{ value: '23',label: '23%'}, { value: '21',label: '21%'},{value: '135',label: '13.5%'},{value: '0',label: '0%',}]},
                                            { type:'date', id:'date', label: 'Date ', value: cost.value.date},
                                        
                                        ]}
                                    ></EditManu>
                                </TableCell>
                            </TableRow>
                        );
                    })}

                
                </TableBody>
            </Table>
            </Paper>
        );
    }
    }
CostsTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CostsTable);