import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router';
import Composer from '../SteelFramed/composer'
import { userContext } from '../Context/userContext';
import CircularProgress from '@material-ui/core/CircularProgress';

import { getDatabase, ref, query, set, limitToLast, onValue } from "firebase/database";

const uniqid = require('uniqid');

const styles = theme => ({});

class SteelFramedTemplateBuilder extends Component {

    state = {
        door: {},
        id: ""
    }

    componentDidMount() {

        let id = this.props.match.params.templateId

        if (id !== undefined) {

            const db = getDatabase();

            const doorRef = query(ref(db, `Crittall/Templates/${id}`));

            onValue(doorRef, (snapshot) => {
                const data = snapshot.val();

                if (data !== null) {

                    let door = data;

                    this.setState({
                        door
                    })
                }
            });

        }

    }

    render() {

        if (this.props.loggedIn === true) {
            return (

                <userContext.Consumer>
                    {(user) => (user.access >= 1 ? <>

                        <Composer
                            door={this.state.door}
                            id={this.state.id}
                        > </Composer>

                    </> : <> {this.state.spinner ? <CircularProgress /> : <span>Contact administrator</span>}  </>
                    )}</userContext.Consumer>
            );
        } else {
            return <Redirect to='/' />
        }
    }
}

export default withStyles(styles)(SteelFramedTemplateBuilder);