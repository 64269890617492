import React, { Component } from 'react';
import { Redirect } from 'react-router';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { userContext } from '../Context/userContext';
import { Table, Container, TableBody, TableCell, TableHead, TableRow, TextField, Button, Input } from '@material-ui/core';
import EditManu from '../partials/EditMenu';
import firebase from "firebase/compat/app";
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';



import { costCategories } from '../logic/financialReports';


let uniqid = require('uniqid');

const vat = [
    {
        value: '23',
        label: '23%',
    }, {
        value: '21',
        label: '21%',
    }, {
        value: '135',
        label: '13.5%',
    }, {
        value: '0',
        label: '0%',
    }
];

const styles = theme => ({
    root: {
        //flexGrow: 1,
        margin: '50px auto',

    },


    btnIcon: {
        fontSize: "48px",
        paddingRight: "20px"
    },

    btnDisabled: {
        color: '#eee !important'
    },
    textField: {
        textAlign: 'left',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: 200,
        ['@media (max-width:600px)']: {
            display: 'inline-flex',
            width: '90%',
            margin: '10px 5%'
        }
    },

    container: {

    },

    paper: {

        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
});




// source : https://www.brex.com/blog/business-expense-categories/


class Costs extends Component {
    state = {
        costs: [],
        costFile: {},
        costCategories: [],
        costName: '',
        vat: 23,
        cost: '',
        invoiceNo: '',
        date: '',
        costCategory: '',
    }

    fieldUpdate = name => event => {
        this.setState({
            [name]: event.target.value
        })
    }

    handleUploadFile = id => event => {

        //-------------------------------------------------------------------- create preview
        event.preventDefault();

        //init reader
        let reader = new FileReader();
        let file = event.target.files[0];
        let year = new Date().getFullYear();
        let fileName = `Costs/${year}/${this.context.initials}_${uniqid()}_${file.name}`
        let costFile = this.state.costFile;

        reader.onloadend = () => {

            costFile.imagePath = fileName;
            //costFile.image = reader.result;

            this.setState({
                costFile: costFile,
            });


        }
        reader.readAsDataURL(file);


        //-------------------------------------------------------------------- upload image  to firebase
        let storageRef = firebase.storage().ref(fileName)
        let uploadTask = storageRef.put(file)

        uploadTask.on('state_changed', function (snapshot) {

            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
            }
        }, function (error) {
            // Handle unsuccessful uploads
        }, function () {
            // Handle successful uploads on complete
        }.bind(this));
    }

    newCost = () => {
        const ordersRef = firebase.database().ref().child(`Costs/`);
        if (this.state.costName === '') {
            alert('Add Cost Name');
        } else if (this.state.invoiceNo === '') {
            alert('Add Invoice Number');
        } else if (this.state.cost === '') {
            alert('Add Cost');
        } else if (this.state.date === '') {
            alert('Add Date');
        } else if(this.state.costFile.imagePath === undefined){
            alert('Add File');
        }
        else {
            ordersRef.push({
                'costName': this.state.costName,
                'vatRate': this.state.vat,
                'cost': this.state.cost,
                'invoiceNo': this.state.invoiceNo,
                'date': this.state.date,
                'costCategory': this.state.costCategory,
                'addedBy': this.state.userInitials,
                'costFile': this.state.costFile
            });
        }
    }

 


    downloadFile = (path) => () => {

        // Create a reference to the file we want to download
        const storage = firebase.storage();

        storage.ref(path).getDownloadURL().then((url) => { window.open(url, '_blank') })
    }

    componentDidMount() {
        const ordersRef = firebase.database().ref(`Costs`);

        //load all orderes

        console.log(this.context.initials)
        ordersRef.on('value', snap => {

            let costs = snap.val();
            let userInitials = this.context.initials

            if (costs !== null) {
                let output = Object.entries(costs).map(([key, value]) => ({ key, value }));
                this.setState({
                    costs: output,
                    userInitials
                });

            } else {
                this.setState({
                    costs: [],
                    userInitials
                })
            }
        })
    }

    render() {
        const { classes } = this.props;

        if (this.props.loggedIn === true) {
            return (


                <userContext.Consumer>
                    {(user) => (user.access >= 5 &&
                        <Container maxWidth="xl">
                            <Paper className={`${classes.paper} ${classes.root}`} elevation={0} variant="outlined" style={{ padding: '4em' }}>


                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-around',
                                    alignItems: 'end',
                                    flexWrap: 'wrap',
                                    gap: '30px'
                                }}>
       
                                    <div style={{ width: '150px' }}>
                                        <TextField
                                            id="costCategory"
                                            select
                                            label="Cost Category"

                                            value={this.state.costCategory}
                                            onChange={this.fieldUpdate('costCategory')}
                                            SelectProps={{
                                                native: true,
                                                MenuProps: {
                                                    className: classes.menu,
                                                },
                                            }}
                                            helperText=""

                                        >
                                            {costCategories.map(option => (
                                                <option key={option} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </TextField>

                                    </div>

                                    <div style={{ width: '150px', flexGrow: 4 }}>
                                        <Input type="file"
                                            id="file"
                                            name="file"
                                            accept="image/x-png,image/gif,image/jpeg"
                                            onChange={this.handleUploadFile().bind(this)}
                                        />
                                    </div>


                                    <div style={{ width: '120px', flexGrow: 1 }}>
                                        <TextField
                                            id="cost-name"
                                            label="Cost Name"
                                            value={this.state.costName}
                                            onChange={this.fieldUpdate('costName')}

                                        />
                                    </div>

                                    <div style={{ width: '120px', flexGrow: 1 }}>
                                        <TextField
                                            id="invoiceNo"
                                            label="Invoice No"
                                            value={this.state.invoiceNo}
                                            onChange={this.fieldUpdate('invoiceNo')}

                                        />
                                    </div>


                                    <div style={{ flexGrow: 1 }}>
                                        <TextField
                                            id="vat"
                                            select
                                            label="VAT rate"

                                            value={this.state.vat}
                                            onChange={this.fieldUpdate('vat')}
                                            SelectProps={{
                                                native: true,
                                                MenuProps: {
                                                    className: classes.menu,
                                                },
                                            }}
                                            helperText=""

                                        >
                                            {vat.map(option => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </TextField>

                                    </div>


                                    <div style={{ width: '100px', flexGrow: 1 }}>
                                        <TextField
                                            id="cost"
                                            label="Net Cost"
                                            value={this.state.cost}
                                            onChange={this.fieldUpdate('cost')}
                                            type="number"
                                            className={classes.textFieldShort}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />

                                    </div>

                                    <div style={{ flexGrow: 2 }}>
                                        <TextField
                                            id="date"
                                            label="Date"
                                            type="date"

                                            defaultValue={moment().format('L')}
                                            onChange={this.fieldUpdate('date', this.value)}
                                            // className={this.props.classes.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />

                                    </div>

                                    <div style={{ flexGrow: 5 }}>
                                        <Button style={{ width: '100%' }} variant="outlined" color="primary" onClick={this.newCost}>Add Cost</Button>
                                    </div>

                                </div>


                            </Paper>

                            <Paper className={`${classes.paper} ${classes.root}`} elevation={0} variant="outlined">




                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell> Cost</TableCell>
                                            <TableCell>Net Amount</TableCell>
                                            <TableCell>Vat Rate</TableCell>
                                            <TableCell>Date</TableCell>
                                            <TableCell>Category</TableCell>
                                            <TableCell>Invoice No</TableCell>
                                            <TableCell>File</TableCell>
                                            <TableCell>Added by</TableCell>


                                            {user.access >= 10 && <TableCell>Edit</TableCell>}

                                            <TableCell padding="none"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.costs.map((cost) => {
                                            return (
                                                <TableRow key={cost.key}>

                                                    <TableCell >{cost.value.costName}</TableCell>
                                                    <TableCell>{cost.value.cost}</TableCell>
                                                    <TableCell>{cost.value.vatRate}</TableCell>
                                                    <TableCell>{cost.value.date}</TableCell>
                                                    <TableCell>{cost.value.costCategory}</TableCell>
                                                    <TableCell>{cost.value.invoiceNo}</TableCell>

                                                    <TableCell>{<span style={{ cursor: 'pointer', color: '#D80026' }} onClick={this.downloadFile(cost.value.costFile.imagePath)}>{cost.value.costFile.imagePath.split("/").pop()}</span>}</TableCell>


                                                    <TableCell>{cost.value.addedBy}</TableCell>



                                                    {user.access >= 10 &&
                                                        <TableCell padding="none">
                                                            <EditManu
                                                                mainGroup="Costs"
                                                                //parentType='Archive'
                                                                //orderId={this.props.orderId}
                                                                recordKey={cost.key}


                                                                update={[


                                                                    { type: 'text', id: 'costName', label: 'Cost Name', value: cost.value.costName },
                                                                    { type: 'text', id: 'cost', label: 'Net Value', value: cost.value.cost },
                                                                    { type: 'text', id: 'invoiceNo', label: 'Invoice No', value: cost.value.invoiceNo },
                                                                    { type: 'date', id: 'date', label: 'Date ', value: cost.value.date },
                                                                    { type: 'select', id: 'vatRate', label: 'VAT rate ', value: cost.value.vatRate, options: [{ value: '23', label: '23%' }, { value: '21', label: '21%' }, { value: '135', label: '13.5%' }, { value: '0', label: '0%', }] },
                                                                    //{ type:'select', id:'vat', label: 'VAT rate ', value: item.value.vat, options: [{ value: '23',label: '23%'},{ value: '21',label: '21%'},{value: '135',label: '13.5%'},{value: '0',label: '0%',}]},

                                                                ]}
                                                            ></EditManu>
                                                        </TableCell>
                                                    }

                                                </TableRow>
                                            );
                                        })}

                                    </TableBody>
                                </Table>
                            </Paper>
                        </Container>
                    )}</userContext.Consumer>
            );
        } else {
            return <Redirect to='/' />
        }
    }
}
export default withStyles(styles)(Costs);
Costs.contextType = userContext;