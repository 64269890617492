import React, { Component } from 'react';
class Lock extends Component{
    state= {}
    render() {
        return (
            <div>
                <div style={{ height: `${200/this.props.printRatio}px`,  width: `${40/this.props.printRatio}px`, border: '1px solid #c5c3c3', position: 'absolute',margin: '0 auto',[this.props.side]: `${-40/this.props.printRatio}px`,bottom:  `${this.props.bottom/this.props.printRatio}px`,}}>
                    <div style={{ height: `${100/this.props.printRatio}px`,  width: `${20/this.props.printRatio}px`, border: '1px solid #c5c3c3', position: 'absolute',margin: '0 auto',[this.props.handleSide]: `${-25/this.props.printRatio}px`,bottom: `50%`,transform: 'translate(0,50%)'}}></div>  
                </div>
            </div>  
       )
    }
}
export default Lock;