import moment from 'moment';

export const OrderStage = (start,end) => {

    //  console.log(start, end)

    start = moment(start);
    end = moment(end);

    // console.log(start, end)

   
    let today = moment().format('L');
    let daysInOrder = Math.abs(start.diff(end,'days'));
    let daysPassed = Math.abs(start.diff(today,'days'));
    let daysLeft = daysInOrder - daysPassed;


    let stageLenght = daysInOrder/10;
    let stage =  daysPassed/stageLenght


    //console.log(start, end)

    if(stage > 10){
        stage = 10;
    }
    if(stage === 0){
        stage = 1;
    }

    if(daysLeft < 0){
        daysLeft = 0
    }

    if(daysPassed > daysInOrder ){
        daysPassed = daysInOrder
    }



    return ({daysInOrder: daysInOrder , daysPassed:daysPassed, stageLenght:stageLenght ,daysLeft: daysLeft, stage:  Math.ceil(stage)})

};

export const TimelineData = (LeadTime, daysPassed) => {

   
 
    let labels = ['Week',1];
    for(let i = 1; i < Number(LeadTime); i++){labels.push(i+1) }

    let week =  Math.ceil(daysPassed/7);
    if(week === 0){week = 1}
    let timelineWeek =  ['1']

    for(let i = 1; i <= Number(week); i++){timelineWeek.push(1) }

    

 

    return({labels: labels, week : week , timelineWeek:timelineWeek})
};




