import React, { Component } from 'react';
import { Redirect } from 'react-router'
// import * as firebase from 'firebase';
import firebase from "firebase/compat/app";
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Link } from 'react-router-dom';
import SteelDoor from '../SteelDoor/Quote';
import Flexi from '../Flexi/Quote';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Print from '@material-ui/icons/Print';
import Delete from '@material-ui/icons/Delete';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import Edit from '@material-ui/icons/Edit';
import Settings from '@material-ui/icons/Settings';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import SteelFramed from '../SteelFramed/quote_new';
import { getDatabase, ref, update, onValue, get, push, set } from "firebase/database";




const styles = theme => ({
    // '@media only screen and (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: )' : { },
    root: {
        width: '100%',
        margin: '50px auto',
        display: 'flex',
        justifyContent: 'center',
        '@media print': {
            margin: '0px',
        }
    },
    paper: {
        padding: '20px',
        '@media print': {
            'box-shadow': 'none !important',
        }
    },
    outlined: {
        position: 'absolute',
        top: theme.spacing(1) * 10,
        right: theme.spacing(1) * 2,
        '@media print': {
            'display': 'none !important',
        }
    },

});


class QuotePage extends Component {

    state = {
        project: '',
        additional: [],
        visionPanelRestrictions: {},
        quoteId: this.props.match.params.projectId,
        printRatio: '',
        anchorEl: null,
        open: false,
        allDoors: [],
        redirect: false,
        additionalPriceItems: [],
        //templatesRef: []

    }

    componentDidMount() {

        // console.log(this.props.match.params.projectId)

        // console.log(this.state.quoteId)



        // set refference to firebase 
        const quoteRef = firebase.database().ref(`Quotes/${this.props.match.params.projectId}`);


        // let additional, priceItems, width, ventilationList, extraOver, visionPanels, discount;

        let project = '';

        quoteRef.on('value', snap => {


            project = snap.val();

            if (project !== null) {

                if (project !== '' && project.product !== 'Steel Framed') {


                    console.log(project)



                    let additional = project.additional;
                    let priceItems = project.priceItems;
                    let width = project.width;
                    let ventilationList = project.ventilationList;
                    let extraOver = project.extraOver;
                    let visionPanels = project.visionPanels;
                    let discount = project.discount;
                    let additionalPriceItems = project.additionalPriceItems
                    let transomHeight = project.transomHeight
                    let leftPanelWidth = project.leftPanelWidth
                    let rightPanelWidth = project.rightPanelWidth

                    if (!leftPanelWidth) { leftPanelWidth = 0; }
                    if (!rightPanelWidth) { rightPanelWidth = 0; }
                    if (!transomHeight) { transomHeight = 0; }
                    if (!additional) { additional = []; }
                    if (!ventilationList) { ventilationList = []; }
                    if (!priceItems) { priceItems = []; }
                    if (!extraOver) { extraOver = []; }
                    if (!visionPanels) { visionPanels = []; }
                    if (!discount) { discount = 0; }
                    if (!additionalPriceItems) { additionalPriceItems = []; }

                    this.setState({
                        transomHeight: transomHeight,
                        extraOver: extraOver,
                        visionPanels: visionPanels,
                        project: project,
                        ventilationList: ventilationList,
                        additional: additional,
                        priceItems: priceItems,
                        visionPanelRestrictions: project.visionPanelRestrictions,
                        quoteID: this.props.match.params.projectId,
                        // printRatio: project.printRatio,
                        discount: discount,
                        ...project,

                    }, this.loadImage());

                } else {


                    //let templatesRef = []

                    // project.selectedForQuote.forEach(element => {
                    //     templatesRef.push(element[0])
                    // });

                    this.setState({

                        //templatesRef,
                        ...project,

                    })

                }
            }
        })


    }




    loadImage = () => () => {

        if (this.state.project.product === "Flexi Quote") {

            let additional = this.state.additional;

            const storage = firebase.storage().ref();

            for (let rys of additional) {


                if (typeof rys.imagePath === "undefined") {
                    rys.image = '';

                    this.setState({
                        additional: additional
                    })

                } else {

                    storage.child(rys.imagePath).getDownloadURL().then((url) => {

                        rys.image = url;

                        this.setState({
                            additional: additional
                        })

                    }).catch((error) => { })
                }
            }
        }
    }

    handleRemoveQuote = () => event => {

        const quotesRef = firebase.database().ref('Quotes');
        const deletedOrdersRef = firebase.database().ref('DeletedOrders');

        let quote = {};

        quotesRef.on('value', snap => {
            let quotes = snap.val();
            quote = quotes[this.state.quoteId];
        })

        // console.log(this.props)

        console.log('Useremail: ' + this.props.userEmail, 'quote Id: ' + this.props.match.params.projectId);
        quote.deletedBy = this.props.userEmail;

        // Save quote in deleted 
        deletedOrdersRef.push(quote);

        //Delete
        quotesRef.child(this.props.match.params.projectId).remove();

        this.setState({
            redirect: true
        })

    }

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null, open: false, });
    };

    confirm = () => event => {
        this.setState({
            open: true,
        })
    }


    //these are empty functions to trick quote page 
    // they do nothing 
    handlePanelPosition = id => event => { }
    handleVentPosition = id => event => { }
    handleChange = name => event => {

        this.setState({
            [name]: event.target.value
        })

    }

    pushToTemplates = () => {

        this.state.selectedForQuote.map(door => {

            const db = getDatabase();
            const postListRef = ref(db, 'Crittall/Templates');
            const newPostRef = push(postListRef);

            set(newPostRef, door[1]);
        }

        )

    }


    render() {

        if (this.state.redirect === true) {
            return <Redirect to='/' />
        }

        const { classes, theme } = this.props;
        const { anchorEl } = this.state;

        if (this.props.loggedIn === true) {
            return (

                <div className={this.props.classes.root} >

                    {/* Menu */}

                    <Button
                        //variant="outlined" 

                        //color="primary" 
                        aria-label="menu"
                        className={classes.outlined}
                        aria-owns={anchorEl ? 'simple-menu' : undefined}
                        aria-haspopup="true"
                        onClick={this.handleClick}
                    ><Settings /></Button>

                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={this.handleClose}
                    >
                        <MenuItem onClick={window.print}><Print /></MenuItem>
                        <MenuItem onClick={this.confirm()}><Delete /></MenuItem>
                        {/* {this.state.product === 'Steel Framed' && <MenuItem> <Link onClick={() => { this.pushToTemplates() }} to={"/steel-framed-templates/"}> <PlaylistAddIcon /> </Link></MenuItem>} */}

                        {this.state.product === 'Steel Framed'
                            ?
                            <MenuItem> <Link to={`/steel-framed-quote/${this.props.match.params.projectId}`}><Edit /></Link></MenuItem>
                            :
                            <MenuItem> <Link to={"/edit-quote/" + this.props.match.params.projectId}><Edit /></Link></MenuItem>
                        }

                    </Menu>





                    <Dialog
                        open={this.state.open}
                        onClose={this.handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>



                        <DialogActions>
                            <Button onClick={this.handleClose} variant="outlined" color="primary">
                                Cancel
                            </Button>
                            <Button onClick={this.handleRemoveQuote()} variant="outlined" color="secondary" autoFocus>
                                Delete Quote  <Delete className={this.props.classes.rightIcon} />
                            </Button>
                        </DialogActions>
                    </Dialog>



                    {/* Delete confirmation - End */}







                    {this.state.project.product === 'Steel Door' &&
                        <Paper className={this.props.classes.paper}  >
                            <SteelDoor
                                handlePanelPosition={this.handlePanelPosition}
                                handleVentPosition={this.handleVentPosition}
                                handleChange={this.handleChange}

                                //properties
                                {...this.state}
                            >
                            </SteelDoor>
                        </Paper>
                    }



                    {this.state.project.product === 'Flexi Quote' &&
                        <Paper className={this.props.classes.paper} >
                            <Flexi {...this.state}  ></Flexi>
                        </Paper>
                    }




                    {this.state.product === 'Steel Framed' &&


                        <div>
                            <SteelFramed {...this.state}  ></SteelFramed>

                          
                        </div>



                        // <div style={{ width: '1050px' }}>
                        //     <SteelFramed {...this.state}  ></SteelFramed>
                        // </div>
                    }







                </div>

            );
        } else {
            return <Redirect to='/' />
        }
    }
}

export default withStyles(styles)(QuotePage);