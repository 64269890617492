import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({});

class OpeningSideAndDirection extends Component {
    state = {}

    componentDidMount = () => { }

    openingDirection = () => {

        if (this.props.door.mainDrawing.side === "left") {
            return ("rotateY(180deg)")
        } else {
            return ("rotateY(0deg)")
        }
    }

    wallFixing = () =>{

        if(this.props.door.smallDrawing.wallFixing === "1"){
            return("13px")
        }else if(this.props.door.smallDrawing.wallFixing === "2"){
            return("6px")
        }else{
            return("0px")
        }
        

    }



    render() {
        const { classes, door } = this.props;

        return (<>

            {/* CALY RYSUNEK Z OPISAMI */}
            <div style={{
                marginTop: "auto",
                borderTop: "1px solid #efefef",
                padding: "10px",
                right: "0px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
            }}>
                <small style={{ padding: "0 0 15px" }}>{this.props.door.smallDrawing.room1}</small>

                <div style={{
                    display: "flex"
                }}>
                    {/* <small style={{ marginRight: "10px" }} > {door.lhrh === "lh" ? <small>LH</small> : <small>RH</small>}</small> */}
                    {/* ŚCIANA */}
                    <div style={{
                        border: "1px solid",
                        width: "20px",
                        height: "15px"
                    }}></div>
                    {/* WRAPPER DRZWI I PANELI*/}
                    <div style={{ display: "flex", marginTop: this.wallFixing()}}>



                        {door.mainDrawing.leftPanel &&
                            <div style={{
                                display: "flex",
                                justifyContent: "space-around",
                                border: "1px solid",
                                width: "50px",
                                height: "2px",
                            }}><small style={{ padding: "5px" }}>Fix</small></div>
                        }


                        { door.mainDrawing.mainElement === "panel" && 
                            <div style={{
                                display: "flex",
                                justifyContent: "space-around",
                                border: "1px solid",
                                width: "50px",
                                height: "2px",
                            }}><small style={{ padding: "5px" }}>Fix</small></div>
                        }

                        {( door.mainDrawing.mainElement === "slidingDoor"  && door.mainDrawing.singleDouble === "Single" ) && 
                            <div style={{
                                display: "flex",
                                justifyContent: "space-around",
                                border: "1px solid",
                                width: "50px",
                                height: "2px",
                                margin: "4px 0 0 0"
                            }}><small style={{ padding: "5px" }}>{door.mainDrawing.side === "left" ?<>&#8592;</> : <>&#8594;</> }</small></div>
                        }

                        {( door.mainDrawing.mainElement === "slidingDoor"  && door.mainDrawing.singleDouble === "Double" ) && <>
                            <div style={{
                                display: "flex",
                                justifyContent: "space-around",
                                border: "1px solid",
                                width: "50px",
                                height: "2px",
                                margin: "4px 0 0 0"
                            }}><small style={{ padding: "5px" }}>&#8592;</small></div>

                            <div style={{
                                display: "flex",
                                justifyContent: "space-around",
                                border: "1px solid",
                                width: "50px",
                                height: "2px",
                                margin: "4px 0 0 0"
                            }}><small style={{ padding: "5px" }}>&#8594;</small></div>

                            </>


                        }



                        {door.mainDrawing.mainElement === "hingedDoor" && <>
                            {/* RAMA */}
                            <div style={{ width: "4px", height: "4px", border: "1px solid black", boxSizing: "border-box" }}></div>


                            {/* Drzwi 1 */}
                            {door.mainDrawing.singleDouble === "Single" &&
                                <div style={{
                                    width: "50px",
                                    height: "50px",
                                    boxShadow: "0 0 0 0px black inset, 1px 1px 0px 0px black",
                                    borderRadius: "0 0 110% 0",
                                    borderColor: "black",
                                    borderStyle: "solid",
                                    borderWidth: "0 0 0 0",
                                    transform: this.openingDirection()
                                }}>
                                    <div style={{
                                        boxSizing: "border-box",
                                        border: "1px solid",
                                        width: "4px",
                                        height: "50px",
                                    }}></div>

                                </div>
                            }

                            {/* Drzwi 2 */}
                            {door.mainDrawing.singleDouble === "Double" && <>



                                {door.mainDrawing.side === "left" &&
                                    <div style={{
                                        border: "1px solid",
                                        width: "50px",
                                        height: "2px",
                                    }}></div>
                                }


                                <div style={{
                                    width: "50px",
                                    height: "50px",
                                    boxShadow: "0 0 0 0px black inset, 1px 1px 0px 0px black",
                                    borderRadius: "0 0 110% 0",
                                    borderColor: "black",
                                    borderStyle: "solid",
                                    borderWidth: "0 0 0 0",
                                    transform: this.openingDirection()
                                }}>
                                    <div style={{
                                        border: "1px solid",
                                        width: "2px",
                                        height: "50px",
                                    }}></div>
                                </div>

                                {door.mainDrawing.side === "right" &&
                                    <div style={{
                                        border: "1px solid",
                                        width: "50px",
                                        height: "2px",
                                    }}></div>
                                }


                            </>}

                            {/* RAMA */}
                            <div style={{ width: "4px", height: "4px", border: "1px solid black", boxSizing: "border-box" }}></div>

                        </>}
                        {   door.mainDrawing.rightPanel &&
                            <div style={{
                                display: "flex",
                                justifyContent: "space-around",
                                border: "1px solid",
                                width: "50px",
                                height: "2px",
                            }}><small style={{ padding: "5px" }}>Fix</small></div>
                        }

                    </div>

                    {/* ŚCIANA */}
                    <div style={{
                        border: "1px solid",
                        width: "20px",
                        height: "15px"
                    }}></div>

                </div>

                <small style={{ padding: "20px 0 0" }}>{this.props.door.smallDrawing.room2}</small>

                {/* <p>Single/Double:  {JSON.stringify(door.singleDouble)} </p>
                <p>LeftPanel:  {JSON.stringify(door.leftPanel)}</p>
                <p>RightPanel:  {JSON.stringify(door.rightPanel)}</p>
                <p>mainElement: {JSON.stringify(door.mainElement)}</p>
                <p>LH/RH: {JSON.stringify(door.lhrh)}</p> */}

            </div>
        </>)

    }
}

export default withStyles(styles)(OpeningSideAndDirection)