import React, { Component } from 'react';
import { Redirect } from 'react-router'
// import * as firebase from 'firebase';
import firebase from "firebase/compat/app";
import { withStyles } from '@material-ui/core/styles';
import SteelDoor from '../SteelDoor/MainComponent';
import Flexi from '../Flexi/MainComponent';
import moment from 'moment';
import Prices from '../SteelDoor/Prices';
//const uniqid = require('uniqid');

const styles = theme => ({
    root: {
      width: '100%',
      margin: '50px auto',
      '@media print':{
        margin: '0px',
        }
    },
  
    outlined: {
        position: 'absolute',
        top: theme.spacing(1) * 10,
        right: theme.spacing(1) * 2,
        '@media print':{
            'display': 'none !important',
        }
    },
   
});


class QuotePage extends Component{
    
    state = {
        project : '',
        additional: [],
        visionPanelRestrictions : {},
        quoteId: this.props.match.params.projectId,
        printRatio: '',
        anchorEl: null,
        open: false, 
        allDoors: [],
        ///---
        redirect: false,
        //Pop Up
        snackBar: false,
        snackBarText: '',
        snackBarHorizontal: 'left',
        //stepper
        steps: ['Door Configuration','Customer Details','Summary'],
        activeStep: 0,
        skipped: new Set(),
        // Calculator
        vat: '0',
        fire: 'Standard',
        productLine: 'RC4',
        grade: 'RC4',
        doorType: Prices.RC4[0].label,
        sqmPrice: Prices.RC4[0].Standard.value,
        frame: Prices.RC4[0].Standard.frame,
        description: Prices.RC4[0].description,
        visionPanelRestrictions: Prices.RC4[0].Standard.visionPanelRestrictions,
        quoteID : '',
        //width: Prices.RC4[0].Standard.maxSizes.Single.width,
        width: 800,
        //height: Prices.RC4[0].Standard.maxSizes.Single.height,
        height: 2000,
        qty: 1,
        maxWidth: Prices.RC4[0].Standard.maxSizes.Single.width,
        maxHeight:  Prices.RC4[0].Standard.maxSizes.Single.height,
        additional:  [],
        clearOpeningWidth: '',
        clearOpeningHeight: '',
        singleDouble :'Single',
        frameType: 'type-2',
        side: 'Left Hand',
        finishingPrice: 0,
        discount: 0,
        
        totalPrice: '0',
        galvanizing: '',
        handle: '',
        handleWidth: 70,
        closer: '',
        colorOutside: '',
        colorInside: '',
       
        maxVPWidth: '',
        maxVPWidth2: '',
        maxVPHeight: '',
        threshold: '20',
        doubleDoorMainLeafwidth: 0,
        swingDirection: 'Inswing',
        printRatio: 6,
        notes: '',
        allDoors: [],
        doorName:'D001',

        //Vision Panels

        visionPanel: false,
        transom: false,
        transomType:'type-2',
        leftPanel: false,
        rightPanel: false,
        transomHeight: 0,
        leftPanelWidth: 0,
        rightPanelWidth: 0,
        visionPanels: [],
        availableGlass: [],
        thermalIsolation: false,
        // mirrorEffect: false,
        thermalIsolationValue: 0,
        // mirrorEffectValue:  0,
        
        glassHeight: '',
        glassWidth: '',
        glass: Prices.glass[0].label,
        glassValue: Prices.glass[0].value,
        visionPanelPrice: 0,

        //Ventilation

        ventilation: false,
        ventilationList: [],
        availableVentilation: [],
        additionalPriceItems: [],
       

        extraOver: [],
        
        // Customer Details
        companyName: '',
        contactName: '',
        contactEmail: '',
        phoneNumber: '',
        firstAddressLine: '',
        secondAddressLine: '',
        city: '',
        county: '',
        country: 'Ireland',
        refernceNumber : '',
        //quoteId: '',
        quoteDate: moment().format('L'),
        eircode: ''
    }

    componentDidMount(){

        // set refference to firebase 
        const quoteRef = firebase.database().ref(`Quotes/${this.props.match.params.projectId}`);
        let additional ,priceItems , width, ventilationList, extraOver, visionPanels, discount;

        let project ='';

        quoteRef.on('value',snap =>{
            project = snap.val();
        })

        if(project !== ''){

      

            let additional = project.additional;
            let priceItems = project.priceItems;
            let width = project.width;
            let ventilationList = project.ventilationList;
            let extraOver = project.extraOver;
            let visionPanels = project.visionPanels;
            let discount = project.discount;
            let additionalPriceItems = project.additionalPriceItems;

            let effectiveOpening = project.effectiveOpening;

            let waFrame = project.waFrame


          
            if(!waFrame){ waFrame = false;}
            if(!additional){ additional = [];}
            if(!ventilationList){ ventilationList = [];}
            if(!priceItems){priceItems = [];}
            if(!extraOver){ extraOver = []; }
            if(!visionPanels){ visionPanels = []; }
            if(!discount){ discount = 0; }
            if(!additionalPriceItems){ additionalPriceItems = []; }

            if(!effectiveOpening) {effectiveOpening = 0}

            project.quoteDate =  moment().format('L')

            this.setState({ 
                effectiveOpening: effectiveOpening,
                editPage:true,
                extraOver: extraOver,
                visionPanels:visionPanels,
                project: project , 
                ventilationList: ventilationList,
                additional: additional,
                priceItems: priceItems,
                visionPanelRestrictions: project.visionPanelRestrictions,
                quoteID:  this.props.match.params.projectId,
                printRatio: project.printRatio,
                discount: discount,
                ...project,
                
            }, this.loadImage());

        }


    }
    loadImage = ()=>() =>{
         
        if(this.state.project.product === "Flexi Quote"){

            let additional = this.state.additional;

            const storage = firebase.storage().ref();

            for(let rys of additional){


                if(typeof rys.imagePath === "undefined" ){
                    rys.image = '';

                    this.setState({
                        additional:additional
                    })

                }else{
    
                    storage.child(rys.imagePath).getDownloadURL().then((url) => {
                        
                        rys.image = url;

                    this.setState({
                        additional:additional
                    })

                    }).catch((error) => {})
                } 
            }
        }
    }
    handleRemoveQuote = () => event  =>{

        

        const quotesRef = firebase.database().ref('Quotes');
        const deletedOrdersRef = firebase.database().ref('DeletedOrders');

        let quote = {};

        quotesRef.on('value',snap =>{
                let quotes = snap.val();
                quote = quotes[this.state.quoteId];
        })

       // console.log(this.props.userEmail)
        quote.deletedBy = this.props.userEmail;
        
        // Save quote in deleted 
        deletedOrdersRef.push(quote);

        //Delete
        quotesRef.child(this.state.quoteId).remove();

        this.setState({
            redirect:true
        })

      

    }

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
      };
    
    handleClose = () => {
        this.setState({ anchorEl: null , open: false,}); 
    };

    confirm = () => event =>{
            this.setState({
                open: true,
            })
    }
    
  
    //these are empty functions to trick quote page 
    // they do nothing 
    handlePanelPosition = id => event =>{ }
    handleVentPosition = id => event =>{ }
    handleChange = name => event =>{ 

        this.setState({
            [name]: event.target.value
        })
        
    }

    render() {

        if(this.state.redirect === true){
            return <Redirect to='/'/>
        }

        const { classes, theme } = this.props;
        const { anchorEl } = this.state;
        
        if(this.props.loggedIn === true){
            return (
                
                <div className={this.props.classes.root}>

                
                
             

                {/* Delete confirmation - End */}



                          

                                { this.state.project.product === 'Steel Door' &&

                                
                                    <SteelDoor
                                        userEmail={this.props.userEmail}
                                        
                                        //properties
                                        {...this.state}
                                    ></SteelDoor>
                                }

                           

                                {  this.state.project.product === 'Flexi Quote' && 
                                
                                    <Flexi 
                                        userEmail={this.props.userEmail}
                                        {...this.state}  
                                    ></Flexi>
                                }

                  
                </div>

            );
        }else{
                return <Redirect to='/'/>
        }
    }
  }
 
export default withStyles(styles)(QuotePage);

