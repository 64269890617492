import React, { Component } from 'react';

class Lock extends Component{

    state= {}

    render() {

        return (
            <div>
                <div style={{ height: `${15/this.props.printRatio}px`,  width: `${125/this.props.printRatio}px`, border: '1px solid black', position: 'absolute',margin: '0 auto',[this.props.handleSide]: `${-45/this.props.printRatio}px`,top: '30%'}}></div>
                <div style={{ height: `${150/this.props.printRatio}px`,  width: `${50/this.props.printRatio}px`, border : '1px solid black', position: 'absolute',margin: '0 auto',[this.props.handleSide]: `${85/this.props.printRatio}px`,top: '30%', borderBottomLeftRadius:'3px'}}></div>
            </div>
        )
    }
}

export default Lock;