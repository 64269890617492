import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {Drawer, Container, Grid, Paper, TextField, Stepper, 
        Step, StepLabel, Select , MenuItem, InputLabel, FormControl,Checkbox,FormControlLabel,
        Accordion, AccordionSummary, AccordionDetails, Typography, Button, Avatar,
        Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider
    } from '@material-ui/core';
import {Alert, AlertTitle, Autocomplete }  from '@material-ui/lab';

// import * as firebase from 'firebase';
import firebase from "firebase/compat/app";
import moment, { relativeTimeRounding } from 'moment';
import {userContext} from '../Context/userContext';
import {usersContext} from '../Context/usersContext';

import TimelineComp from '../partials/Timeline';
import Filter1Icon from '@material-ui/icons/Filter1';
import Filter2Icon from '@material-ui/icons/Filter2';
import Filter3Icon from '@material-ui/icons/Filter3';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EventNoteIcon from '@material-ui/icons/EventNote';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import AddComment from "../partials/AddComment"


    const styles = theme =>  ({
        prospectBox:{
            margin: '.5em 1em',
            padding: '1em'
        },
        paper:{
            padding: '1em',
            margin: '1em',

        },
        textField:{
            margin: '1em',
        },
        textFieldFullWidth:{
            width: '90%'
        },
        button:{
            margin: '0.2em .5em',
        },
        stepper: {
            clear: 'both',
        },
        alert: {
            margin: '1em',
        },
        avatarSmall: {
            width: theme.spacing(2),
            height: theme.spacing(2),
            fontSize: '0.8em',
            background: '#ffffff',
            float: 'right',
            margin: '0.1em',
            color: 'black'
          },
  
    });

    
    
    const popularTasks = [
        { title: 'Follow up with customer'},
        { title: 'Follow up with manufacturer'},
        { title: 'Create TDS quotation'}
      ];
    
      const reasons = [
        { title: 'Outside customers budget'},
        { title: `Customer does not respond`},
        { title: `Customer declined quotation`}, 
        { title: 'Manufacturer unable to fabricate'},
        { title: 'We do not offer that kind of product'},
        { title: `Customer doesn't need product anymore`},
        { title: `Installation imposible`},
        { title: `Customer did not supply required information`},
        { title: `Customer didn't win tender`},
        { title: `Customer has chosen another supplier`}
      ];

class Lead extends Component{

   
    
    state = {
        panel: false,
        comment: '',
        task: '',
        taskAsignedTo: '',
        taskDate: Date(),
        customerIsWaiting: false,
        outstandingTask: false,
        dealWonConfirmation: false,
        dealDeleteConfirmation:false,
        dealLostConfirmation: false,
        idle: false,
        lostReason: '',
        users: [],
        avatars: []
    }


    autocompleteUpdate = (name)=>( event, values) =>{

        this.setState({
            [name]: values
        })
    }


    handleStatechange = (name) => event =>{
       
        this.setState({
            [name]: event.target.value
        })
    }

    dealWon = () => event =>{
        let leadRef = firebase.database().ref(`Leads`);
        let dealWonRef = firebase.database().ref(`DealsWon`);

        this.props.lead[1].dateClosed = Date();
        this.props.lead[1].status = 'closed';

 
        dealWonRef.push(this.props.lead[1]).then( ()=>{   
            leadRef.child(this.props.lead[0]).remove().then(()=>{this.reloadIfNoDealsLeft()});}
        )
    }

    dealLost = () => event =>{

        let leadRef = firebase.database().ref(`Leads`);
        let dealWonRef = firebase.database().ref(`DealsLost`);
        let deal = this.props.lead[1];

        deal.dateClosed = Date();
        deal.status = 'closed';
        deal.lostReason = this.state.lostReason;
   

        dealWonRef.push(deal).then( ()=>{
            leadRef.child(this.props.lead[0]).remove().then(()=>{this.reloadIfNoDealsLeft()});
            }
        )
    }

    dealDelete =() =>event =>{

        let leadRef = firebase.database().ref(`Leads`);

        leadRef.child(this.props.lead[0]).remove().then(()=>{this.reloadIfNoDealsLeft()});
    

    }

    reloadIfNoDealsLeft = () =>{

        let leadRef = firebase.database().ref(`Leads`);

        leadRef.on('value',snap =>{
            let leads = snap.val()
            if(leads === null){ 
                window.location.reload(false);
            }
        })

    }






    addTask = () => event =>{

     
        firebase.database().ref(`Leads/${this.props.lead[0]}/timeline`).push({
                date: Date(),
                note: this.state.task,
                user: this.props.userEmail,
                taskDate: this.state.taskDate, 
                type: 'task',
                completed : false,
                taskAsignedTo: this.state.taskAsignedTo
            })
       

            this.setState({ task: '', taskAsignedTo:'', taskDate: Date()});

    }


    changeStage = () => event =>{
      
        let stg = "Lead In";
        if(event.target.value === 2){
            stg = "Contact Made";
        }else if(event.target.value === 3){
            stg = "Needs Defined";
        }else if(event.target.value === 4){
            stg = "Costs Estimated";
        }else if(event.target.value === 5){
            stg = "Proposal Made";
        }else if(event.target.value === 6){
            stg = "Site survey";
        }

        firebase.database().ref(`Leads/${this.props.lead[0]}/timeline`).push({
            date: Date(),
            note: `Deal stage changed to ${stg} ` ,
            user: this.props.userEmail,
            type: 'stageChange',
            stage: event.target.value
        })

        firebase.database().ref(`Leads/${this.props.lead[0]}`).update({
            stage: event.target.value
        })
    }



    handlechange = (name) => event =>{

        let value = event.target.value;

        if(name === 'priority' && value > 3){ return; }
        firebase.database().ref(`Leads/${this.props.lead[0]}`).update({
            [name]: value
        })
     }

    togglePanel = ()=>() =>{
        this.setState({panel: !this.state.panel})
    }

    asignedTo =() =>{
        if(this.props.lead[1].timeline){

            let arr = Object.entries(this.props.lead[1].timeline).reverse();
            let avatars = []
            for (let el of arr) {
            
                if (el[1].type === 'task') {

                    if(!el[1].completed && typeof el[1].taskAsignedTo != 'undefined' ){

                        avatars.indexOf(el[1].taskAsignedTo) === -1 && avatars.push(el[1].taskAsignedTo) 

                    }
                }
            }
            this.setState({
                avatars:avatars
            })
        }

    }
    outstandingTaskCheck = () =>{

        if(this.props.lead[1].timeline){

            let arr = Object.entries(this.props.lead[1].timeline).reverse();
            for (let el of arr) {
            
                if (el[1].type === 'task') {

                    if(!moment(el[1].taskDate).isAfter(Date()) && !el[1].completed){

                        this.setState({
                            outstandingTask:true
                        })
                        break;

                    }
                }
            }
        }

    }
    checkIfDealIsOk = () =>{
        let currDate = moment();
        let dateToTest = moment(this.props.lead[1].date);
        let result = currDate.diff(dateToTest, 'days');

         if((result > 7 )&& (this.props.lead[1].stage < 5) ){
            this.setState({
                customerIsWaiting:true
            })
        }  
    }

    isItIdle = () =>{

        if(this.props.lead[1].timeline){

            let arr = Object.entries(this.props.lead[1].timeline).reverse();

            let idle = true
            for (let el of arr) {
            
                if (el[1].type === 'task') {

 

                    if(!el[1].completed){

                      idle = false

                    }
                }

                this.setState({
                    idle :idle
                })
             

                
            }
        }

    }
    setDefoultTaskUser = () =>{
        this.setState({taskAsignedTo:  this.context.initials})
    }



    // static contextType = MainContext;
    componentDidMount(){
        this.checkIfDealIsOk()
        this.outstandingTaskCheck()
        this.asignedTo()
        this.isItIdle()
        this.setDefoultTaskUser()
        
        // if(this.props.lead[0] === '-MI55dOHhKyl72E5iF6R'){
        //     alert('-MI55dOHhKyl72E5iF6R')
        // }
    }


    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.checkIfDealIsOk()
            this.outstandingTaskCheck()
            this.asignedTo()
            this.isItIdle()
            this.setDefoultTaskUser()
        }
    }

    render() {
        const { classes } = this.props;
        const stages = ['Lead In','Contact Made','Needs Defined','Costs Estimated','Proposal Made','Site survey'];
        return (
            <userContext.Consumer>
            {(user) => ( user.access >= 1 &&
            <div>
  
            <Paper onClick={this.togglePanel()} className={classes.prospectBox} style={{border: this.state.outstandingTask ? '1px solid #D80026' : 'none'}}> 
                {Number(this.props.lead[1].priority) === 1 ? <Filter1Icon color='secondary' style={{float: 'left'}} /> : Number(this.props.lead[1].priority) === 2 ? <Filter2Icon color='secondary' style={{float: 'left'}}/> : Number(this.props.lead[1].priority) === 3 && <Filter3Icon  style={{float: 'left', color: '#969696'}}/> } 
                
       

            { this.state.customerIsWaiting  && <HourglassEmptyIcon color='primary' style={{float: 'right'}}></HourglassEmptyIcon>}

            { this.state.outstandingTask && <EventNoteIcon color='primary' style={{float: 'right'}}></EventNoteIcon>}

            { this.state.idle  && <ErrorIcon style={{float: 'right' , color: '#ffcc00'}}></ErrorIcon>}

            {this.state.avatars.map((avatar,key)=><Avatar key={key} className={classes.avatarSmall}>{avatar}</Avatar>)}
           
            

        {this.props.lead[1].dealValue ? <span style={{fontWeight: '400', float: 'left', marginLeft: '.6em' }}> {new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format(this.props.lead[1].dealValue)}<br/></span> : <span style={{fontWeight: '400', float: 'left', marginLeft: '.6em' }} >€0</span> }
            <div style={{clear: 'both', borderBottom : '1px solid #efefef', height: '5px'}}></div>
                
        < span style={{fontWeight: 500, fontSize: '1.1em'}}>{this.props.lead[1].refference ? this.props.lead[1].refference : this.props.lead[1].prospectName} </span><br/>
                    
                    {/* Priority: {this.props.lead[1].priority}<br/> */}
                    
                  
                    
               
                
                <Typography color="textSecondary"  variant="caption" >Deal started: {moment(this.props.lead[1].date).fromNow()}</Typography>
                
              
            </Paper>

            <Drawer
              variant="temporary"
              anchor="bottom"
              open={this.state.panel}
              onClose={this.togglePanel()}
            >
            
                <div  style={{minHeight: '50vh',maxHeight:'75vh', background:"#efefef", padding:"50px, 0", background:"#efefef", overflow: 'scroll'}}>

                    <span onClick={this.togglePanel()} style={{color: '#D80026',position: 'absolute', right: '0', margin: '1em'}}><CloseIcon></CloseIcon></span>
                    <Container maxWidth="lg">
                  
                       
                        <Paper className={classes.paper} >
                            <h2 style={{fontWeight: '600', fontSize: '1.7em', float: 'left'}}>{this.props.lead[1].refference}</h2>
                            { user.access >= 5 && <Button variant="outlined"  color="primary" style={{float: 'right'}} className={classes.button} onClick={()=>(this.setState({dealWonConfirmation: true}))}>Won</Button>}
                            { user.access >= 6 && <Button variant="outlined"  color="primary" style={{float: 'right'}} className={classes.button} onClick={()=>(this.setState({dealLostConfirmation: true}))}>Lost</Button>}
                            { user.access >= 7 && <Button variant="outlined"  color="secondary" style={{float: 'right'}} className={classes.button} onClick={()=>(this.setState({dealDeleteConfirmation: true}))}>Delete</Button> }
                            
                            <Stepper activeStep={Number(this.props.lead[1].stage)} alternativeLabel className={classes.stepper} >
                            {stages.map(label => {
                                return (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                                );
                            })}
                            </Stepper>
                        </Paper>
                        {this.state.customerIsWaiting && 
                            <Alert severity="error" className={classes.alert}>
                                <AlertTitle>Customer awaiting </AlertTitle>
                                Customer requested a quote {moment(this.props.lead[1].date).fromNow()} and still hasn't got it.
                            </Alert>
                        }

                        {this.props.lead[1].timeline && Object.entries(this.props.lead[1].timeline).reverse().map(event=>{
                            return <span  key={event[0]} >{
                                event[1].type === 'task' && <span>{(!moment(event[1].taskDate).isAfter(Date()) && !event[1].completed ) && 
                                        <Alert severity="error" className={classes.alert} >
                                            <AlertTitle>Outstanding Task - expired {moment(event[1].taskDate).fromNow()}</AlertTitle>
                                                {event[1].note}
                                        </Alert>
                                    }</span>
                                }</span>    
                        })}

                        {this.state.idle && 
                            <Alert severity="warning" className={classes.alert}>
                                <AlertTitle> This Deal is Idle </AlertTitle>
                              Set a task for yourself or other member of the team, alternativly close the deal.
                            </Alert>
                        }       

                    

                        {/* { Object.entries(this.props.lead[1].timeline).reverse().map(event=>{

            
                            
                       

                        })} */}
                        
                        <Grid container  spacing={6}>
                            <Grid item md={4}>
                                <Paper className={classes.paper} >
                                    
                                    <h2>Contact Details</h2>
                                    <hr/>
                            
                               
                                    <TextField
                                        id="prospectName"
                                        label="Prospect Name"
                                        type="text"
                                        className={`${this.props.classes.textField} ${this.props.classes.textFieldFullWidth }`}
                                        value={this.props.lead[1].prospectName}
                                        onChange={this.handlechange('prospectName' )}
                                        margin="normal"
                                    />
                                 
                                    <TextField
                                        id="company"
                                        label="Company"
                                        type="text"
                                        className={`${this.props.classes.textField} ${this.props.classes.textFieldFullWidth }`}
                                        value={this.props.lead[1].company}
                                        onChange={this.handlechange('company' )}
                                        margin="normal"
                                    />

                                    <TextField
                                        id="prospectEmail"
                                        label="Email"
                                        type="text"
                                        className={`${this.props.classes.textField} ${this.props.classes.textFieldFullWidth }`}
                                        value={this.props.lead[1].prospectEmail}
                                        onChange={this.handlechange('prospectEmail' )}
                                        margin="normal"
                                    />
                                    <TextField
                                        id="prospectPhone"
                                        label="Phone"
                                        type="tel"
                                        className={`${this.props.classes.textField} ${this.props.classes.textFieldFullWidth }`}
                                        value={this.props.lead[1].prospectPhone}
                                        onChange={this.handlechange('prospectPhone' )}
                                        margin="normal"
                                    />
                                </Paper>


                                <Paper className={classes.paper} >
                                            <h2>Create a Task</h2>
                                            <hr/>
                             
                                        <Autocomplete
                                                freeSolo
                                                id="tasks"
                                                disableClearable
                                                onChange={this.autocompleteUpdate('task')}
                                                options={popularTasks.map((option) => option.title)}
                                               
                                                renderInput={(params) => (
                                                    
                                                <TextField
                                                    {...params}
                                                    onChange={this.handleStatechange('task')}
                                                    label="Set Task"
                                                    margin="normal"
                                                    variant="outlined"
                                                    InputProps={{ ...params.InputProps, type: 'search' }}
                                                />
                                            )}
                                        />
                                        <usersContext.Consumer>
                                        
                                        {(users) => ( users != null &&

                                        

                                        <TextField
                                            id="user"
                                            select
                                            label="User"
                                            className={classes.textFieldFullWidth}
                                            displayEmpty
                                            value={this.state.taskAsignedTo}
                                            onChange={this.handleStatechange('taskAsignedTo')}
                                            SelectProps={{
                                                native: true,
                                                MenuProps: {
                                                className: classes.menu,
                                                },
                                            }}
                                            helperText=""
                                            margin="normal"
                                            >
                                            {
                                                users.map(option => (
                                                    <option key={option[1].name} value={option[1].initials}>
                                                        {option[1].name}
                                                    </option>
                                                ))
                                            }
                                        </TextField>
                                       

                                                )}
                                        </usersContext.Consumer>
                                                            
                                        <TextField
                                            id="datetime-local"
                                            label="Set remainder date and time"
                                            type="datetime-local"
                                            defaultValue={`${Date()}`}
                                            className={classes.textFieldFullWidth}
                                            style={{ display: 'inline-block' }}
                                            InputLabelProps={{
                                            shrink: true,

                                            }}
                                            onChange={this.handleStatechange('taskDate')}
                                        />

                                        <Button  variant="outlined"  onClick={this.addTask()} > Add task </Button>

                                </Paper>

                                <Paper className={classes.paper} >

                                <AddComment
                                    userEmail ={this.props.userEmail}
                                    pushPath = {`Leads/${this.props.lead[0]}/timeline`}
                                
                                ></AddComment>

                      
                                </Paper>

                            </Grid>


                            <Grid item md={8}>
                                <Paper className={classes.paper} >
                                    <h2>Deal info</h2>
                                  
                                    <hr/>


                               
                                    <TextField
                                        id="priority"
                                        label="Priority"
                                        type="number"
                                        className={this.props.classes.textField}
                                        value={this.props.lead[1].priority}
                                        onChange={this.handlechange('priority' )}
                                        margin="normal"
                                        InputProps={{ inputProps: { min:  1 ,max:  3 , step: 1 } }}
                                    />
                                    <TextField
                                        id="refference"
                                        label="Deal Refference"
                                        type="text"
                                        className={this.props.classes.textField}
                                        value={this.props.lead[1].refference}
                                        onChange={this.handlechange('refference' )}
                                        margin="normal"
                                        
                                    />

                                    <TextField
                                        id="dealValue"
                                        label="Deal Value"
                                        type="number"
                                        className={this.props.classes.textField}
                                        value={this.props.lead[1].dealValue}
                                        onChange={this.handlechange('dealValue' )}
                                        margin="normal"
                                      
                                    />



                                    <FormControl className={this.props.classes.textField}> 
                                        <InputLabel shrink id="stage">Stage</InputLabel>
                                        <Select
                                            value={this.props.lead[1].stage}
                                            onChange={this.changeStage()}
                                        >
                                            <MenuItem value={1}>Lead In</MenuItem>
                                            <MenuItem value={2}>Contact Made</MenuItem>
                                            <MenuItem value={3}>Needs Defined</MenuItem>
                                            <MenuItem value={4}>Costs Estimated</MenuItem>
                                            <MenuItem value={5}>Proposal Made</MenuItem>
                                            <MenuItem value={6}>Site survey</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {  
                                        
                                        <Accordion  >
                                            <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            >
                                            <Typography className={classes.heading}>Original Request</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{flexWrap: 'wrap'}}>
                                            <Typography>
                                            {this.props.lead[1].quote && String(this.props.lead[1].quote).replace('Action required\r\n\t\t\t\t\t\tThere is a new request for a free quote\r\n\t\t\t\t\t\t','').split("\n").map((i,key) => {
                                                return <span style={{display: 'block'}} key={key}>{i}</span>;
                                            })}
                                            </Typography>
                                            { this.props.lead[1].manualEntry && 

                                               
                                              <TextField
                                                  className={`${classes.textField} ${classes.textFieldFullWidth }`}
                                                  style={{marginTop: "50px"}}
                                                  id="outlined-multiline-flexible"
                                                  label="Customer request"
                                                  multiline
                                                  rows={6}
                                                  value={this.props.lead[1].quote}
                                                  onChange={this.handlechange('quote' )}
                                                  variant="outlined"
                                              />
                                             

                                            }
                                            </AccordionDetails>
                                        </Accordion>

                                    }
                                </Paper>

                                {(this.props.lead[1].timeline || this.props.lead[1].manualEntry)&&  
                                    <TimelineComp 
                                        timeline={this.props.lead[1].timeline} 
                                        date={this.props.lead[1].date}
                                        projectID={this.props.lead[0]}
                                        databaseCollection='Leads'
                                        manualEntry={this.props.lead[1].manualEntry}
                                        addedBy={this.props.lead[1].addedBy}
                                    >
                                    </TimelineComp> 
                                }

                            </Grid>
                        </Grid>
                    </Container>
                    ID:{this.props.lead[0]}  
                </div>
          
            </Drawer>



            {/* // Deal Won */}

            <Dialog
                open={this.state.dealWonConfirmation}
                onClose={this.handleClose}
                aria-describedby="alert-dialog-description"
                >
                
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    This deal will be removed from Sales Pipeline.
                    Are you sure ?

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>(this.setState({dealWonConfirmation:false}))} variant="outlined"  color="secondary">
                    Cancel
                    </Button>
                    <Button onClick={this.dealWon()} variant="outlined"  color="primary" autoFocus>
                    Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            {/* //  Deal Lost */}

            <Dialog
                open={this.state.dealLostConfirmation}
                onClose={this.handleClose}
                aria-describedby="alert-dialog-description"
                >
                
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    This deal will be removed from Sales Pipeline.
                    Are you sure ?

                    <Autocomplete
                            freeSolo
                            id="tasks"
                            disableClearable
                            onChange={this.autocompleteUpdate('lostReason')}
                            options={reasons.map((option) => option.title)}
                            
                            renderInput={(params) => (
                                
                            <TextField
                                {...params}
                                onChange={this.handleStatechange('lostReason')}
                                label="Why this deal is lost?"
                                margin="normal"
                                variant="outlined"
                                InputProps={{ ...params.InputProps, type: 'search' }}
                            />
                        )}
                    />

                    </DialogContentText>
                </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>(this.setState({dealLostConfirmation:false}))} variant="outlined"  color="secondary">
                        Cancel
                        </Button>
                        {this.state.lostReason !== '' &&
                            <Button onClick={this.dealLost()} variant="outlined"  color="primary" autoFocus>
                            Close Deal
                            </Button>
                         }
                    </DialogActions>
            </Dialog>

            {/* // Delete Deal */}

            <Dialog
                open={this.state.dealDeleteConfirmation}
                onClose={this.handleClose}
                aria-describedby="alert-dialog-description"
                >
                
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    This deal will be removed from Sales Pipeline.
                    Are you sure ?

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>(this.setState({dealDeleteConfirmation:false}))} variant="outlined"  color="secondary">
                    Cancel
                    </Button>
                    <Button onClick={this.dealDelete()} variant="outlined"  color="primary" autoFocus>
                    Delete
                    </Button>
                </DialogActions>
            </Dialog>



            
            </div>


                )}
            </userContext.Consumer>
            );
        
    }
  }
export default withStyles(styles)(Lead);
Lead.contextType = userContext;