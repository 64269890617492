
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import logo from '../logo.jpg';
//import formatDate from "../logic/formatDate"


import {date} from '../logic/date';


const styles = theme => ({
    logo:{
        width: '75px',
        margin: '-3px 0 0 0px',
        '@media print':{
            display: 'block',
            width: '75px',
          }
    },
    table:{
        borderTop: '2px solid #d60a2e',
        '@media print':{
            'margin': "0px",
        }
    },
    tableFont:{
        fontSize: '12px',
        '@media print':{
            fontSize: '10px',
           
          }

    }

});


class Header extends Component{

    componentDidMount(){}

    render(){
        const { classes } = this.props;
        return(

            <Table className={classes.table}>                         
                <TableBody>
                    <TableRow key='Quote'>
                        <TableCell width="24%" scope="row">
                            <img className={classes.logo} src={logo} alt="logo" style={{float: 'left'}}/>
                        </TableCell>

                        <TableCell  className={classes.tableFont}>
                            Customer: {this.props.companyName}<br/>
                            Contact Name: {this.props.contactName}<br/>
                            Email: {this.props.contactEmail}  Phone: {this.props.phoneNumber}
                        </TableCell>

                        <TableCell   scope="row" className={classes.tableFont} >
                            {this.props.firstAddressLine !== "" &&  this.props.firstAddressLine }{this.props.secondAddressLine !== "" ? ", ": <br/>}
                            {this.props.secondAddressLine !== "" && this.props.secondAddressLine}{this.props.secondAddressLine !== "" && <br/>}
                            {this.props.city !== "" && this.props.city }{this.props.county !== "" ? ", ": <br/>}
                            {this.props.county !== "" && this.props.county}{this.props.county !== "" && <br/>}
                            {this.props.eircode !== "" && this.props.eircode}{this.props.county !== "" ? ", ": <br/>}
                            {this.props.country !== "" && this.props.country }
                        </TableCell>

                        <TableCell  className={classes.tableFont}  >Date: {date(this.props.quoteDate)}<br/>{this.props.quoteNo !== undefined ? ` Quote No: ${this.props.quoteNo}`: ( this.props.quoteID !== undefined ) ?`Quote ID: ${this.props.quoteID}` : <div></div>} <br/>Reference Number: {this.props.refernceNumber}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            )
        }
    }
    export default withStyles(styles)(Header);