import React, { Component } from 'react';
// import * as firebase from 'firebase'
import firebase from "firebase/compat/app";
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import ArchiveTable from '../orders/ArchiveTable';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {userContext} from '../Context/userContext';

let uniqid = require('uniqid');

  const styles = theme => ({
    root: {
    //   backgroundColor: theme.palette.background.paper,
      width: '100%',
      margin: '50 auto',
    },
    btn:{
        backgroundColor: '#d60a2e',
        color: '#ffffff',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    textField: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: 200,
        ['@media (max-width:600px)']: { 
            display: 'inline-flex',
            width: '90%',
            margin: '10px 5%',
        }
    },
    paper:{
        margin: '20px',
        overflowX: 'scroll',
        padding: '20px'
    },
  });

class Archive extends Component{
    state = {
        archive: [],
        filesTypes:[],
        date: '',
        name: '',
        archiveFile: {},
        duplicate: false,

    }

    customeAutocomplete = ()=> event =>{

        let fileName = ''; 
        let duplicate = false;


        for(let i = 0; i < this.state.filesTypes.length ; i++){

            if(this.state.filesTypes[i].Name === event.target.value ){
           

                duplicate= true
                fileName = '';
                break;
            }
                fileName = event.target.value;
        }

        this.setState({
            name: fileName,
            duplicate: duplicate
            
        })
     

    }

    autocompleteUpdate = (event, values) =>{

        console.log(event, values)


        if(values != null){

                this.setState({
                    name: values.Name
                })

        }else{
            this.setState({
                name: ''
            })

        }

    }

    fieldUpdate = name => event =>{

        this.setState({
            [name]: event.target.value
        })
    }

    addToArchive = ()=>{


        if(this.context.initials === undefined){
            alert('Ask administrator to add initials to your account')
        }else{
            const ordersRef = firebase.database().ref().child(`Orders/${this.props.orderId}/Archive`);
            let archive  = this.state.archiveFile;
            archive.name = this.state.name;
            archive.date = moment().format('L');

            archive.uploadedBy = this.context.initials;

            

            console.log(this.state.archiveFile.imagePath)

            if(this.state.name === ''){
                alert('Select file type or type different name');
            }else if( this.state.archiveFile.imagePath === undefined ){
                alert('Upload file');
            }
            else{
                ordersRef.push(archive);
                
                this.setState({
                    archiveFile: {},
                    name: ''
                })
            }
        }
    
    }

    handleAdditionalImage = id  => event =>{

        //-------------------------------------------------------------------- create preview
       event.preventDefault();

       //init reader
       let reader = new FileReader();
       let file = event.target.files[0];
       let fileName = `Archive/${this.props.orderId}/${this.context.initials}_${uniqid()}_${file.name}`
       let archiveFile = this.state.archiveFile;

       reader.onloadend = () => {

        archiveFile.imagePath = fileName;
        //archiveFile.image = reader.result;

        this.setState({
            archiveFile: archiveFile,
        });

        
       }
       reader.readAsDataURL(file);


       //-------------------------------------------------------------------- upload image  to firebase
       let storageRef  = firebase.storage().ref(fileName)
       let uploadTask = storageRef.put(file)

       uploadTask.on('state_changed', function(snapshot){
           
           var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
           console.log('Upload is ' + progress + '% done');
           switch (snapshot.state) {
               case firebase.storage.TaskState.PAUSED: // or 'paused'
                   console.log('Upload is paused');
               break;
               case firebase.storage.TaskState.RUNNING: // or 'running'
                   console.log('Upload is running');
               break;
           }
           }, function(error) {
           // Handle unsuccessful uploads
           }, function() {
           // Handle successful uploads on complete
           }.bind(this));
   }



    componentDidMount(){
        // ---------------------------------------
        // Get all emploees for the dropdown field
        // ---------------------------------------

        // set refference to firebase 
        const filesRef = firebase.database().ref(`/FileTypes`);
        
        //load all orderes
        filesRef.on('value',snap =>{
            
            let filesTypes = snap.val();
            let output = Object.entries(filesTypes).map(([key, value]) => (value));

     

            if(filesTypes !== null){
               // let output = Object.entries(filesTypes).map(([key, value]) => ({key,value}));


                this.setState({ 
                    filesTypes: output , 
                });
            }else{
                this.setState({
                    filesTypes: [],

                    name: 'text'
                })
            }
        })

        const ordersRef = firebase.database().ref(`Orders/${this.props.orderId}/Archive`);
        
        //load all orderes
        ordersRef.on('value',snap =>{
            
            let costsFB = snap.val();

            if(costsFB !== null){
                let output = Object.entries(costsFB).map(([key, value]) => ({key,value}));
                this.setState({ 
                    archive: output , 
                    specialNames : ['Purchase Order']
                });
  
            }else{
                this.setState({
                    archive: []
                })
            }
        })

    }

    render() {

        const { classes } = this.props;
        
            return (
                <userContext.Consumer>
                    {(user) => ( user.access >= 1 &&
                
                <div className={classes.root}>
                <Paper className={this.props.classes.paper} elevation={0} variant="outlined" >

                        <Autocomplete
                            id="disabled-options-demo"
                            freeSolo
                            disableClearable
                            options={this.state.filesTypes}
                            getOptionDisabled={option =>{
                                for(let i = 0 ;i < this.state.archive.length; i++){
                                    if( this.state.archive[i].value.name === option.Name){
                                        return true
                                    }
                                }
                            }}
                            
                            getOptionLabel={option => option.Name}
                            onChange={this.autocompleteUpdate}
                            style={{ width: 300, display: 'inline-flex' }}
                            renderInput={params => 
                            <TextField {...params} 
                       
                            onChange={this.customeAutocomplete()}
                            label={this.state.duplicate === true ? <span>This file name is reserved try something else</span> : <span>"Upload file"</span>}
                            variant="outlined"
                                InputProps={{ ...params.InputProps, type: 'search' }} 
                             />}
                        />

                     <Input type="file"
                     className={classes.textField}
                        id="avatar" 
                        name="avatar"
                        accept="image/x-png,image/gif,image/jpeg"
                       
                        //fullwidth
                        onChange={this.handleAdditionalImage().bind(this)}
                        />

                <Button variant="outlined" color="primary" onClick={this.addToArchive}>Add New</Button> 

                </Paper>


                <ArchiveTable orderId={this.props.orderId}></ArchiveTable>    
            </div>
        )}</userContext.Consumer>
        );  
    }
}

export default withStyles(styles)(Archive);
Archive.contextType = userContext;