import React, { Component } from 'react';
// import * as firebase from 'firebase';
import firebase from "firebase/compat/app";
import { withStyles } from '@material-ui/core/styles';
import {Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot, TimelineOppositeContent }  from '@material-ui/lab';
import moment, { relativeTimeRounding } from 'moment';
import { Paper, Checkbox,FormControlLabel, Typography, Avatar} from '@material-ui/core';

import EventNoteIcon from '@material-ui/icons/EventNote';
import ErrorIcon from '@material-ui/icons/Error';




const styles = theme => ({
    root: {
      width: '100%',
      margin: '50px auto',
      '@media print':{
        margin: '0px',
        }
    },
    paper: {
        padding: '20px',
        '@media print':{
            'box-shadow': 'none !important',
        }
    },


    avatarSmall: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        fontSize: '0.8em',
        background: '#ffcc00',
        float: 'right',
        margin: '0.1em'
      },
   
});


class TimelineComp extends Component{
    
    state = {
   
    }

    completeTheTask = (id , changeTo) => event =>{

        firebase.database().ref(`${this.props.databaseCollection}/${this.props.projectID}/timeline/${id}`).update({
            completed : changeTo
        }).then(
                ()=>{
                    // Leads component will reload automatilcly tdatabase automatically no need to triger that function
                    if(this.props.databaseCollection != 'Leads'){
                        this.props.reloadData()
                    }
                }
            )
    }

   

    componentDidMount(){ }


    render() {


        const { classes, theme } = this.props;
       
        
   
            return (
                
                <span className={this.props.classes.root}>

                { this.props.timeline && Object.entries(this.props.timeline).reverse().map(event=>{
                        return <TimelineItem key={event[0]}>
                            <TimelineOppositeContent>
                            <Typography color="textPrimary">
                                {moment(event[1].date).format('MMMM Do YYYY, h:mm:ss a')}
                            </Typography>
                            <Typography color="textSecondary" variant="caption" >
                                {moment(event[1].date).fromNow()} by  {event[1].user}
                            </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot />
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <span>
                                    <Paper className={classes.paper} >
                                        <Typography>
                                    {event[1].type === 'comment' ? <span>{event[1].note}</span> :
                                    
                                    (event[1].type === 'task' ? <span>
                                        <EventNoteIcon></EventNoteIcon>
                                        <>{ 
                                    
                                                moment(event[1].taskDate).fromNow()
                                        }
                                        </>
                                        <>{(!moment(event[1].taskDate).isAfter(Date()) && !event[1].completed ) && <ErrorIcon style={{float: 'right'}} color='primary'></ErrorIcon>}</>
                                        <Avatar className={classes.avatarSmall}>{event[1].taskAsignedTo}</Avatar>
                                        <span style={{width: '100%', height :"1px" , backgroundColor: '#bdbdbd', display: 'block', margin: '1em 0'}}></span>
                                        {event[1].note}<br/>
                                        <FormControlLabel
                                            control={<Checkbox checked={event[1].completed}
                                            onChange={this.completeTheTask(event[0], !event[1].completed)} 
                                            name="completed" />}
                                            label
                                            ="Completed"
                                        />
                
                                        </span> :

                                    (event[1].type === 'stageChange' ? <span>{event[1].note}</span> :<span></span> ) 
                                        )}  

                                        </Typography>
                                    </Paper>
                                    
                                </span>

                            </TimelineContent>
                        </TimelineItem>
                     }) }

                    { this.props.date != null &&
                        <TimelineItem>
                        <TimelineOppositeContent>
                            <Typography color="textPrimary">
                                {moment(this.props.date).format('MMMM Do YYYY, h:mm:ss a')}
                            </Typography>
                            <Typography color="textSecondary">
                                {moment(this.props.date).fromNow()}
                            </Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot />
                        
                        </TimelineSeparator>
                        <TimelineContent>
                            <Paper className={classes.paper} >

                                {
                                this.props.manualEntry 
                                ?
                                <Typography>
                                    Deal started by {this.props.addedBy}
                                </Typography>
                                :
                                <Typography>
                                    Deal started by website enquiry
                                </Typography>
                                }
                            </Paper>
                            
                        </TimelineContent>
                    </TimelineItem>
                    }
                </span>

            );
       
    }
  }
 
export default withStyles(styles)(TimelineComp);