import React, { Component } from 'react';
import { Redirect } from 'react-router'
// import * as firebase from 'firebase'
import firebase from "firebase/compat/app";
import { withStyles } from '@material-ui/core/styles';
import {TableRow, Container, TableHead, TableCell, TableBody, Table, Paper} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { OrdersCount } from '../logic/GetData';
import { userContext } from '../Context/userContext';

import CustomerDetails from '../Customers/createCustomerDetails';

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    // root: {
    //     width: '100%',
    //     overflowX: 'auto',
    // },
    table: {
        minWidth: 700,
    },
    paper: {
        overflowX: 'scroll',
        margin: '2% 0 ',
    },
});

class Customers extends Component {

    state = {
        customers: []
    }

    componentDidMount() {
        const custRef = firebase.database().ref('Customers');

        //load all customers
        custRef.on('value', snap => {
            let result = snap.val()
            let filtered = []
            if (result !== null) {
                //change json to array
                let allCust = Object.entries(result);
                for (let cust of allCust) { filtered.push(cust) }
                this.setState({
                    customers: filtered
                })
            } else {
                this.setState({
                    customers: []
                })
            }
        })

        const ordersRef = firebase.database().ref('Orders');

        ordersRef.on('value', snap => {
            let orders = snap.val();
            if (orders !== null) {
                this.setState({ orders })
            }
        })



    }

    render() {

        if (this.props.loggedIn === true) {
            return (

                <userContext.Consumer>
                    {(user) => (user.access >= 1 &&

                        <Container maxWidth="xl">

                            <h2>Customers</h2>
                            <CustomerDetails ></CustomerDetails>
                            <Paper className={this.props.classes.paper} elevation={1}>

                                <Table className={this.props.classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell >No</TableCell>
                                            <TableCell >Company</TableCell>
                                            <TableCell >Name</TableCell>
                                            <TableCell >Surname</TableCell>
                                            <TableCell >Contact Number</TableCell>
                                            <TableCell >Email</TableCell>
                                            <TableCell >Orders Placed</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.customers.map((customer, index) => {
                                            return (
                                                <TableRow key={customer[0]}>
                                                    <TableCell ><Link to={`/customer/${customer[0]}`}> {index + 1} </Link></TableCell>
                                                    <TableCell ><Link to={`/customer/${customer[0]}`}>{customer[1].companyName} </Link></TableCell>
                                                    <TableCell >{customer[1].contactName} </TableCell>
                                                    <TableCell >{customer[1].contactSurname} </TableCell>
                                                    <TableCell >{customer[1].phoneNumber} </TableCell>
                                                    <TableCell >{customer[1].contactEmail} </TableCell>
                                                    <TableCell >{OrdersCount(customer[0], this.state.orders)} </TableCell>

                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Container>
                    )}
                </userContext.Consumer>
            );
        } else {
            return <Redirect to='/' />
        }
    }
}
export default withStyles(styles)(Customers);
Customers.contextType = userContext;