// import * as firebase from 'firebase';
import firebase from "firebase/compat/app";

import { sumUpAllNetValue } from "../SteelFramed/logic"

export const TotalValue = (orderId = '') => {

    let orderValue = 0;

    const orderRef = firebase.database().ref(`Orders/${orderId}`);

    orderRef.on('value', snap => {

        let order = snap.val();

        if (typeof order.AllItems === 'object') {

            let allItems = Object.entries(order.AllItems);

            for (let item of allItems) {

                let price = item[1].price - (item[1].price * (1 - (100 - item[1].discount) / 100))
                price = price.toFixed(2)
                orderValue += parseFloat(price * item[1].qty);

            }

        }
    })


    return (orderValue);
};

export const TotalCost = (orderId = '') => {

    let totalCost = 0;

    const orderRef = firebase.database().ref(`Orders/${orderId}`);

    orderRef.on('value', snap => {

        let order = snap.val();

        if (typeof order.Costs === 'object') {

            let allCosts = Object.entries(order.Costs);

            for (let cost of allCosts) {

                totalCost += parseFloat(cost[1].cost);

            }
        }
    })

    return (totalCost);
};

export const TotalCostVat = (orderId = '') => {

    let totalCostVat = 0;

    const orderRef = firebase.database().ref(`Orders/${orderId}`);

    orderRef.on('value', snap => {

        let order = snap.val();

        if (typeof order.Costs === 'object') {

            let allCosts = Object.entries(order.Costs);

            for (let cost of allCosts) {

                totalCostVat += parseFloat(cost[1].cost * ("0." + cost[1].vatRate));

            }

        }
    })

    return (totalCostVat);
};

export const TotalIncome = (orderId = '') => {

    let totalIncome = 0;

    const orderRef = firebase.database().ref(`Orders/${orderId}`);

    orderRef.on('value', snap => {

        let order = snap.val();

        if (typeof order.Income === 'object') {

            let allIncomes = Object.entries(order.Income);

            for (let inc of allIncomes) {

                if (inc[1].paid) {
                    if (!inc[1].cnValue) {
                        inc[1].cnValue = 0;
                    }

                    totalIncome += parseFloat(inc[1].Amount - inc[1].cnValue);
                }
            }
        }
    })

    return (totalIncome);
};



export const TotalIncomeVat = (orderId = '') => {


    let totalIncomeVat = 0;

    const orderRef = firebase.database().ref(`Orders/${orderId}`);

    orderRef.on('value', snap => {

        let order = snap.val();

        if (typeof order.Income === 'object') {

            let allIncomes = Object.entries(order.Income);

            for (let inc of allIncomes) {

                if (inc[1].paid) {
                    if (!inc[1].cnValue) {
                        inc[1].cnValue = 0;
                    }


                    totalIncomeVat += parseFloat((inc[1].Amount * ("0." + inc[1].vat))
                        - (inc[1].cnValue * ("0." + inc[1].vat)));
                }
            }
        }
    })

    return (totalIncomeVat);
};



export const TotalVat = (orderId = '') => {

    let orderVat = 0;

    const orderRef = firebase.database().ref(`Orders/${orderId}`);

    orderRef.on('value', snap => {

        let order = snap.val();

        if (typeof order.AllItems === 'object') {

            let allItems = Object.entries(order.AllItems);

            for (let item of allItems) {

                let vat = (item[1].price - (item[1].price * (1 - (100 - item[1].discount) / 100))) * ("0." + item[1].vat)

                orderVat += parseFloat(vat * item[1].qty);
            }
        }
    })

    return (orderVat);
};


export const QuoteTotalValue = (id = '', quotes) => {

    let qt = quotes.filter(quote => quote[0] === id)
    qt = qt[0][1]

    let total = 0;



    if (qt.product === 'Steel Door') {

        // if(qt.allDoors != undefined){
        //     for(let door of qt.allDoors){
        //         let qty = Number(door.qty);
        //         let totPrice =  door.totalPrice-(door.totalPrice *  (1 - (100 - door.discount)/100));
        //         total += (qty * totPrice) *  ('1.'+ Number(door.vat)); 
        //     }
        // }
        // total += (Number(qt.qty) * (qt.totalPrice-(qt.totalPrice *  (1 - (100 - qt.discount)/100)))) *  ('1.'+ Number(qt.vat)); 






        if (qt.allDoors) {
            for (let door of qt.allDoors) {

                let qty = Number(door.qty);

                let totPrice = door.totalPrice - (door.totalPrice * (1 - (100 - door.discount) / 100));

                total += qty * totPrice;


            }
        }
        if (qt.additionalPriceItems) {
            for (let item of qt.additionalPriceItems) {
                if (!item.globalDiscount) {
                    let qty = Number(item.qty);
                    let totPrice = item.price - (item.price * (1 - (100 - item.discount) / 100));
                    total += qty * totPrice;
                }
            }
        }




        total += Number(qt.qty) * (qt.totalPrice - (qt.totalPrice * (1 - (100 - qt.discount) / 100)));

        if (qt.additionalPriceItems) {

            if (qt.additionalPriceItems.findIndex(x => Number(x.globalDiscount) === true) !== -1) {

                for (let item of qt.additionalPriceItems) {
                    if (item.globalDiscount) {
                        total = total - (total * (1 - (100 - item.discount) / 100));
                    }
                }

            }
        }
    }

    if (qt.product === 'Flexi Quote') {


        if (qt.priceItems != undefined) {
            for (let item of qt.priceItems) {
                let qty = Number(item.qty);

                let price = item.price - (item.price * (1 - (100 - item.discount) / 100));

                total += qty * price;


            }
        }

    }
    // stare ceny z bazy danych zapisane razem z quote , ulegna zmianie przy edycji



    if (qt.product === 'Steel Framed') {



        if (qt.doors !== undefined) {
            total += Number(sumUpAllNetValue(qt))
        }


    }
    return (total.toFixed(2))


}



export const sqmArea = (door) => {
    return (((Number(door.mainDrawing.doorFrameWidth) + Number(door.mainDrawing.lpWidth) + Number(door.mainDrawing.rpWidth)) * (Number(door.mainDrawing.doorFrameHeight) + Number(door.mainDrawing.transomHeight))) / 1000000)
}


export const totalPricePerDoor = (door, prices) => {

    if (prices === undefined) { alert("Prices are missing") }

    let totalPrice = 0


    if (door.Ironmongery !== undefined) {
        door.Ironmongery.forEach(id => {

            let index = prices.availableIronmongery.findIndex(irm => irm[0] === id)

            totalPrice += Number(prices.availableIronmongery[index][1].price)
        })
    }

    if (door.Glass !== undefined) {

        let sqm = sqmArea(door)

        let index = prices.availableGlass.findIndex(glassType => glassType[0] === door.Glass)

        totalPrice += roundToTwo(Number(prices.availableGlass[index][1].price) * sqm)

    }

    if (door.Finish !== undefined) {


        let index = prices.availableFinishes.findIndex(glassType => glassType[0] === door.Finish)

        totalPrice += roundToTwo(Number(prices.availableFinishes[index][1].price))
    }

    if (door.DoorType !== undefined) {
        let index = prices.availableDoorTypes.findIndex(doorType => doorType[0] === door.DoorType)
        if (index !== -1) {
            let item = prices.availableDoorTypes[index][1]
            let sqm = sqmArea(door)
            if (item.fixedPrice !== undefined) {
                totalPrice += roundToTwo((Number(item.price) * sqm) + Number(item.fixedPrice))
            } else {
                totalPrice += roundToTwo(Number(item.price) * sqm)
            }
        }

    }

    return (totalPrice)

}

export const roundToTwo = (num) => {
    return +(Math.round(num + "e+2") + "e-2");
}






// To usi byc przepisane na mniejsz ailosc polaczen z baza, wszyscy Custommers powinni być pobrani tylko raz

export const GetCustomerNameForThisOrder = (id = '') => {
    let customer = '';
    const custRef = firebase.database().ref(`Customers/${id}`);
    custRef.on('value', snap => {
        let cust = snap.val();
        if (cust !== null) { customer = cust.companyName }
    })
    return (customer);

};

export const OrdersCount = (id = '', orders = {}) => {

    let orderCount = 0;

    let allorders = Object.entries(orders);
    for (const [i, v] of allorders.entries()) {
        if (v[1].Customer === id) {
            orderCount++
        }
    }


    return (orderCount);

};
