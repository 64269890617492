import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
//import {TotalValue, TotalVat} from '../logic/GetData';
import DoneIcon from '@material-ui/icons/Done';
import WarningIcon from '@material-ui/icons/Warning';
import EditMenu from '../../partials/EditMenu';

import { Paper } from '@material-ui/core';

const styles = theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      width: 200,
    },

    table: {
        minWidth: 250,
    },
  });


class PriceItems extends Component{
    
    state = {}
  
    componentDidMount(){}
    render() {
            return ( 
                <Paper style={{width: '1040px', margin: "1rem 0" }}>

                    <h4>{this.props.group}</h4>
                    <Table className={this.props.classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell >Order no</TableCell>
                                <TableCell >Item</TableCell>
                                <TableCell >Description</TableCell>
                                <TableCell >Price</TableCell>
                                {this.props.group === "DoorType" &&  <TableCell >Fixed Price</TableCell>}
                                {this.props.group === "DoorType" &&  <TableCell >Min Area(m<sup>2</sup>)</TableCell>}
                                <TableCell >Edit</TableCell>   
                            </TableRow>
                        </TableHead>
                        
                        <TableBody>
                        {this.props.priceItems.map((item,index)=>{ 
                            return (
                                    <TableRow key={item[0] }>
                                        {/* {JSON.stringify(item)} */}
                                        <TableCell>{index+1}</TableCell>
                                        <TableCell>{item[1].name}</TableCell>
                                        <TableCell>{item[1].description}</TableCell>
                                        <TableCell>{item[1].price}</TableCell>
                                        {this.props.group === "DoorType"  && <TableCell>{item[1].fixedPrice}</TableCell>}
                                        {this.props.group === "DoorType"  && <TableCell>{item[1].minArea}</TableCell>}
                                        <TableCell>

                                       <EditMenu 
                                        mainGroup="Crittall"
                                        parentType={this.props.group}
                                        orderId={item[0]}
                                        // recordKey={item[0]}
                                        update={[

                                            { type:'text', id:'name', label: 'Name', value: item[1].name},
                                            { type:'number', id:'price', label: 'Price', value:item[1].price},
                                            { type:'number', id:'fixedPrice', label: 'Fixed Price', value:item[1].fixedPrice},
                                            { type:'number', id:'minArea', label: 'Min Area', value:item[1].minArea},
                                            ... this.props.group === "DoorType" ? [{ type:'text', id:'description', label: 'Description', value:item[1].description}] :[],
                                            // { type:'text', id:'discount', label: 'discount', value: item.value.discount},
                                            // { type:'select', id:'vat', label: 'VAT rate ', value: item.value.vat, options: [{ value: '23',label: '23%'},{ value: '21',label: '21%'},{value: '135',label: '13.5%'},{value: '0',label: '0%',}]},
                                            // { type:'text', id:'price', label: 'price', value: item.value.price},
                                            
                                        ]}
                                    ></EditMenu>
                                        </TableCell>

                                 
                                    </TableRow>   
                                    );
                                })}
                        </TableBody>
                    </Table>
                </Paper>
            );
        
    }
  }
export default withStyles(styles)(PriceItems);
