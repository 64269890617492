import {Group}  from 'three';
import createBox from './createBox';
const uniqid = require('uniqid');


const createFlatHandle = (color, adjustment , door, profileWidth, profileDepth, side,  metalness, roughness, height) =>{

   

    const handleFront = new Group();
    const handleBack = new Group();
    const set = new Group();
    

    const handle1 = createBox(20,height,5,color, uniqid(), metalness,roughness)
    const handle2 = createBox(30,height,5,color, uniqid(), metalness,roughness)
    const handle3 = createBox(20,height,5,color, uniqid(), metalness,roughness)
    const handle4 = createBox(30,height,5,color, uniqid(), metalness,roughness)

    handle1.rotation.y = -Math.PI * 0.25;
    handle1.position.x = - 20
    handle1.position.z = - 5

    handle3.rotation.y = Math.PI * 0.25;
    handle3.position.x = - 20
    handle3.position.z =  7

    handleFront.add(handle1, handle2)
    handleBack.add(handle3, handle4)

    handleBack.position.z =  - Number(profileDepth)+5
    handleFront.position.z =  Number(profileDepth)-5

    handleBack.position.x = - Number(adjustment)  + (2 * profileWidth)
    
    handleFront.position.x = - Number(adjustment)  + (2 * profileWidth)
    

    if(door === "mainDoor" ){
     

        if(side == "left" ){
            handleBack.position.x = - Number(adjustment)  + (2 * profileWidth)
            handleFront.position.x = - Number(adjustment)  + (2 * profileWidth)
        }else{
            handleBack.position.x = Number(adjustment)  - (2 * profileWidth)
            handleBack.rotation.z = Math.PI 
            handleFront.position.x = Number(adjustment)  - (2 * profileWidth)
            handleFront.rotation.z = Math.PI 
        }
    }else{

        
       
        if(side == "left" ){
            handleBack.position.x =  Number(adjustment)  - (2 * profileWidth)
            handleFront.position.x =  Number(adjustment)  - (2 * profileWidth)
            handleBack.rotation.z = Math.PI 
            handleFront.rotation.z = Math.PI 
          
        }else{
            handleBack.position.x =  -Number(adjustment)  + (2 * profileWidth)
            handleFront.position.x =  -Number(adjustment)  + (2 * profileWidth)
            
        }
    }
    set.add(handleFront , handleBack)

    return( set)

}

export default createFlatHandle

