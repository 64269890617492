import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import createHinges from './createHinges';
import createBox from './createBox';
import createLockCase from './createLockCase';
import rectangleFrame from './rectangleFrame';
import createHandle from './createHandle';
import createTopMeasurement from './createTopMeasurements'
import createBottomMeasurements from './createBottomMeasurements'
import createSideMeasurements from './createSideMeasurements'
import createFlatHandle from './createFlatHandle'
import createMuntinsPreset from './createMuntinsPreset';
import createPushPullHandle from './createPushPullHandle'
import createHandleTypeC from './createHandleTypeC';
import createHandleTypeD from './createHandleTypeD';
import createHandleTypeE from './createHandleTypeE';
import { DragControls } from 'three/examples/jsm/controls/DragControls.js'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { CSG } from 'three-csg-ts';

import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";

import hdrFile from "./textures/test.hdr"

import {
    Scene,
    Raycaster,
    Vector2,
    PerspectiveCamera,
    WebGLRenderer,
    AmbientLight,
    DirectionalLight,
    Color,
    Group,
    PointLight,
    CircleGeometry,
    PointLightHelper,
    DirectionalLightHelper,
    BoxGeometry,
    MeshStandardMaterial,
    Mesh,
    MeshPhysicalMaterial,
    ACESFilmicToneMapping,
    EquirectangularReflectionMapping,
    sRGBEncoding

} from 'three';


import { ContactSupportOutlined, FlashOnTwoTone } from '@material-ui/icons';
import { is } from 'date-fns/locale';
import { isFirstDayOfMonth } from 'date-fns/fp';
import { Vector3 } from 'three';
import { PMREMGenerator } from 'three';


const uniqid = require('uniqid');

//const hdrFile = new URL("./textures/brown_photostudio_02_4k.hdr", import.meta.url )


const styles = theme => ({

});



class Customers extends Component {



    state = {

        // ...this.props.doorSetup, 

        reqAnim: {},


    }

    componentDidMount() {



        this.renderCanvas()

    }
    componentDidUpdate() {


        // cancelAllAnimationFrames
        var id = window.requestAnimationFrame(function () { });
        while (id--) {
            window.cancelAnimationFrame(id);
        }


        this.renderCanvas()
    }



    renderCanvas = (action) => {

        let sceenHolder = document.getElementById("scena" + this.props.id);

        //let sceenHolder = document.querySelector(".scena");



        let sizes = {
            width: Number(sceenHolder.offsetWidth),
            height: Number(sceenHolder.offsetHeight),



        }

        // window.addEventListener('resize', ()=>{
        //     addjustScene()
        // });

        const addjustScene = () => {
            sizes = {
                width: Number(sceenHolder.offsetWidth),
                height: Number(sceenHolder.offsetHeight),

            }

            camera.aspect = sizes.width / sizes.height
            camera.updateProjectionMatrix()


            renderer.setSize(sizes.width, sizes.height)

        };

        // ---- Base Values
        const profileWidth = Number(this.props.doorSetup.profileWidth);
        const profileDepth = Number(this.props.doorSetup.profileDepth);
        const transomHeight = Number(this.props.doorSetup.transomHeight)
        const transomeIncluded = this.props.doorSetup.transom;
        const color = this.props.doorSetup.color;
        const canvas = document.querySelector(`canvas.${this.props.id}`);
        const floor = this.props.doorSetup.floor !== undefined ? this.props.doorSetup.floor : false
        let animation = this.props.doorSetup.animation !== undefined ? this.props.doorSetup.animation : false
        const floorsize = this.props.doorSetup.doorFrameWidth * 5;
        let floorColor = "#ffffff";
        let side = this.props.doorSetup.side;
        let singleDouble = this.props.doorSetup.singleDouble;
        const fullWidthMaskingBar = this.props.doorSetup.fullWidthMaskingBar;

        const doorFrameHeight = Number(this.props.doorSetup.doorFrameHeight);
        const doorFrameWidth = Number(this.props.doorSetup.doorFrameWidth);
        const leftPanelIncluded = this.props.doorSetup.leftPanel;
        const rightPanelIncluded = this.props.doorSetup.rightPanel;
        let leftPanelWidth = Number(this.props.doorSetup.lpWidth);
        let rightPanelWidth = Number(this.props.doorSetup.rpWidth);
        const doorHeight = doorFrameHeight - (profileWidth * 2);
        const totalHeight = doorFrameHeight + transomHeight;
        let totalWidth = doorFrameWidth + leftPanelWidth + rightPanelWidth;
        let doorWidth = 0;
        let sLdoorWidth = 0;
        const metalness = this.props.doorSetup.metalness;
        const roughness = this.props.doorSetup.roughness;
        const backgroundColor = this.props.doorSetup.backgroundColor;
        const noHinges = this.props.doorSetup.hinges !== undefined ? this.props.doorSetup.hinges : 3;

        //let lockCase = this.state.lockCase


        const threeJsMuntins = []
        const muntins = this.props.doorSetup.muntins !== undefined ? this.props.doorSetup.muntins : []
        const lights = this.props.doorSetup.lights !== undefined ? this.props.doorSetup.lights : []
        const lockCaseDimensions = this.props.doorSetup.lockCase !== undefined ? this.props.doorSetup.lockCase : {}
        const lockCaseLocation = this.props.doorSetup.lockCaseLocation !== undefined ? this.props.doorSetup.lockCaseLocation : "";
        const lockCaseLocationHorizontal = this.props.doorSetup.lockCaseLocationHorizontal !== undefined ? this.props.doorSetup.lockCaseLocationHorizontal : "";
        const orbitCam = this.props.doorSetup.orbitCam !== undefined ? this.props.doorSetup.orbitCam : false;
        const draggable = this.props.doorSetup.draggable !== undefined ? this.props.doorSetup.draggable : false;
        const mainElement = this.props.doorSetup.mainElement !== undefined ? this.props.doorSetup.mainElement : "panel";
        const glass = this.props.doorSetup.glass !== undefined ? this.props.doorSetup.glass : { name: "No glass" };
        const glassThickness = this.props.doorSetup.glassThickness !== undefined ? this.props.doorSetup.glassThickness : 8;

        const zoom = this.props.doorSetup.zoom !== undefined ? this.props.doorSetup.zoom : 5600;






        const ironmongery = this.props.doorSetup.mainDoorIronmongery !== undefined ? this.props.doorSetup.mainDoorIronmongery : {
            type: "handle",
            color: 'sameAsFrame',
            handleLocation: 900,
            handleLocationHorizontal: 0,
            twoLeaves: true,
            spindle: 170,
            rosetDepth: 70,
            grip: 130,
            height: 300,

        };


        if (singleDouble === "Single") {
            doorWidth = doorFrameWidth - (profileWidth * 2) - 8;
        } else {
            doorWidth = Number(this.props.doorSetup.doorWidth);
            sLdoorWidth = Number(doorFrameWidth - doorWidth) - (profileWidth * 2) - 10;
        }

        // ---- floor color
        if (color === "#efefef") {
            floorColor = "#979797"
        }


        // Scene
        const scene = new Scene()
        scene.background = new Color(backgroundColor);








        // - all components


        // ----------------------------
        //  Transom
        // ----------------------------


        if (transomeIncluded) {

            //const transomWidth = doorFrameWidth;

            let addon = 0

            if (singleDouble === "Double" && leftPanelIncluded && rightPanelIncluded) { addon = 6 }
            else if (singleDouble === "Single" && leftPanelIncluded && rightPanelIncluded) { addon = 6 }
            // else if(singleDouble === "Single" && leftPanelIncluded ){addon = 3 }
            // else if(singleDouble === "Single" && rightPanelIncluded){addon = 3 }
            // else if(singleDouble === "Double" && leftPanelIncluded ){addon = 3 }
            // else if(singleDouble === "Double" && rightPanelIncluded){addon = 3 }



            const transomWidth = Number(totalWidth) + addon;



            const transomFrame = rectangleFrame(transomWidth, transomHeight, profileWidth, profileDepth, color, metalness, roughness, mainElement, "fix");

            let haveMuntins = muntins.findIndex(x => x.childOf === "transom");

            if (haveMuntins !== -1) {

                let muntinsdesign = createMuntinsPreset(muntins, "transom", color, metalness, roughness)

                transomFrame.add(...muntinsdesign)
                threeJsMuntins.push(...muntinsdesign)
            }



            // -- adjustments 
            transomFrame.position.y = (doorFrameHeight / 2) + (transomHeight / 2) + 3

            if (leftPanelIncluded && !rightPanelIncluded) {
                transomFrame.position.x = -leftPanelWidth / 2
            } else if (!leftPanelIncluded && rightPanelIncluded) {
                transomFrame.position.x = rightPanelWidth / 2
            } else if (leftPanelIncluded && rightPanelIncluded) {
                transomFrame.position.x = (rightPanelWidth - leftPanelWidth) / 2
            }

            scene.add(transomFrame)
        }

        // ----------------------------
        //  Create left panel
        // ----------------------------


        if (leftPanelIncluded) {

            // if (mainElement === "slidingDoor") {
            //     leftPanelWidth += 20
            // }

            const leftPanel = rectangleFrame(leftPanelWidth, doorFrameHeight, profileWidth, profileDepth, color, metalness, roughness, mainElement, "fix");

            let haveMuntins = muntins.findIndex(x => x.childOf === "leftPanel");

            if (haveMuntins !== -1) {

                let muntinsdesign = createMuntinsPreset(muntins, "leftPanel", color, metalness, roughness)

                leftPanel.add(...muntinsdesign)
                threeJsMuntins.push(...muntinsdesign)
            }


            // -- adjustments 

            leftPanel.position.x = -(doorFrameWidth / 2) - (leftPanelWidth / 2) - 3

            if (mainElement === "slidingDoor") {

                leftPanel.position.x += (profileWidth + 3)
                leftPanel.position.z = - (profileDepth + 5)

            }

            scene.add(leftPanel)
        }

        // ----------------------------
        //  Create Right panel
        // ----------------------------


        if (rightPanelIncluded) {
            // if (mainElement === "slidingDoor") {
            //     rightPanelWidth += 20
            // }

            const rightPanel = rectangleFrame(rightPanelWidth, doorFrameHeight, profileWidth, profileDepth, color, metalness, roughness, mainElement, "fix");

            // -- Muntins

            let haveMuntins = muntins.findIndex(x => x.childOf === "rightPanel");

            if (haveMuntins !== -1) {
                let muntinsdesign = createMuntinsPreset(muntins, "rightPanel", color, metalness, roughness)

                rightPanel.add(...muntinsdesign)
                threeJsMuntins.push(...muntinsdesign)

            }


            //--- adjustments
            rightPanel.position.x = (doorFrameWidth / 2) + (rightPanelWidth / 2) + 3

            if (mainElement === "slidingDoor") {

                rightPanel.position.x -= (profileWidth + 3)
                rightPanel.position.z = - (profileDepth + 5)
            }


            scene.add(rightPanel)
        }

        //- maskownica dla suwanych 

        if (mainElement === "slidingDoor") {

            // Width 
            let maskingBarWidth





            if (fullWidthMaskingBar) {

                maskingBarWidth = doorFrameWidth + leftPanelWidth + rightPanelWidth

                console.log(maskingBarWidth)

            } else {

                if (singleDouble === "Single") {

                    if (side === "left") {

                        if (Number(doorFrameWidth) < Number(leftPanelWidth)) {

                            maskingBarWidth = doorFrameWidth + leftPanelWidth

                        } else {
                            maskingBarWidth = doorFrameWidth * 2 + 10
                        }
                    }

                    if (side === "right") {

                        if (Number(doorFrameWidth) < Number(rightPanelWidth)) {

                            maskingBarWidth = doorFrameWidth + rightPanelWidth
                        } else {
                            maskingBarWidth = doorFrameWidth * 2 + 10
                        }
                    }


                } else {

                    maskingBarWidth = doorFrameWidth * 2 + 10

                }
            }



            // Masking bar creation



            const maskingBar = createBox(maskingBarWidth, 60, profileDepth, color, uniqid(), metalness, roughness);

            // Position

            let xAdjustment = 0

            if (fullWidthMaskingBar) {

                if (leftPanelWidth < rightPanelWidth) {
                    xAdjustment = (rightPanelWidth - leftPanelWidth) / 2
                } else {
                    xAdjustment = - (leftPanelWidth - rightPanelWidth) / 2
                }

            } else {

                if (singleDouble === "Single") {

                    if (side === "left") {

                        if (Number(doorFrameWidth) < Number(leftPanelWidth)) {
                            xAdjustment = (-doorFrameWidth / 2) - ((leftPanelWidth - doorFrameWidth) / 2)
                        } else {
                            xAdjustment = -doorFrameWidth / 2
                        }

                    } else {

                        if (Number(doorFrameWidth) < Number(rightPanelWidth)) {
                            xAdjustment = (doorFrameWidth / 2) + ((rightPanelWidth - doorFrameWidth) / 2)
                        } else {
                            xAdjustment = doorFrameWidth / 2
                        }

                    }

                } else {
                    xAdjustment = 0
                }

            }





            maskingBar.position.x = xAdjustment
            maskingBar.position.y = doorFrameHeight / 2 + profileWidth * 2


            scene.add(maskingBar)

        }

        // -----------------------------------------------------------
        //  Second Leaf ---- Start
        // -----------------------------------------------------------

        if (singleDouble === "Double") {
            if (mainElement === "hingedDoor") {

                const secondLeaf = new Group()

                const sLFrame = rectangleFrame(sLdoorWidth, doorHeight, profileWidth, profileDepth, color, metalness, roughness, mainElement, "door");

                // --------  Hinges
                const hinges = createHinges(noHinges, color, side, sLdoorWidth, doorHeight, profileDepth, "sL", metalness, roughness);

                sLFrame.add(...hinges)

                if (ironmongery.twoLeaves) {

                    if (ironmongery.type === "handle") {

                        //  Lock case

                        const lockCase = createLockCase(lockCaseDimensions, lockCaseLocation, side, sLdoorWidth, color, uniqid(), "sL", profileWidth, metalness, roughness, lockCaseLocationHorizontal, doorHeight);

                        sLFrame.add(lockCase)

                        //  Handle

                        const handle = createHandle(ironmongery, color, side, sLdoorWidth, "sL", metalness, roughness, doorHeight);


                        sLFrame.add(handle)

                    } else if (ironmongery.type === "typeA") {

                        const handle = createFlatHandle(color, sLdoorWidth / 2, "sL", profileWidth, profileDepth, side, metalness, roughness, ironmongery.height)

                        handle.position.y = -doorFrameHeight / 2 + Number(ironmongery.handleLocation)

                        sLFrame.add(handle)


                    } else if (ironmongery.type === "typeB") {

                        const handle = createPushPullHandle(color, sLdoorWidth / 2, "sL", profileWidth, profileDepth, side, metalness, roughness, ironmongery)

                        handle.position.y = -doorFrameHeight / 2 + Number(ironmongery.handleLocation)
                        handle.position.x = -ironmongery.handleLocationHorizontal

                        sLFrame.add(handle)

                    }
                    else if (ironmongery.type === "typeC") {

                        const handle = createHandleTypeC(color, sLdoorWidth / 2, "sL", profileWidth, profileDepth, side, metalness, roughness, ironmongery)

                        handle.position.y = -doorFrameHeight / 2 + Number(ironmongery.handleLocation)
                        handle.position.x = ironmongery.handleLocationHorizontal

                        sLFrame.add(handle)

                    }
                }

                //-- Muntins

                let haveMuntins = muntins.findIndex(x => x.childOf === "secondLeaf");

                if (haveMuntins !== -1) {

                    let muntinsdesign = createMuntinsPreset(muntins, "secondLeaf", color, metalness, roughness)


                    sLFrame.add(...muntinsdesign)
                    threeJsMuntins.push(...muntinsdesign)

                }

                // --------  Adjustments

                sLFrame.position.y -= 4;

                if (side === "left") {
                    sLFrame.position.x = -doorFrameWidth / 2 + sLdoorWidth / 2 + profileWidth + 5
                } else {
                    sLFrame.position.x = doorFrameWidth / 2 - sLdoorWidth / 2 - profileWidth - 5
                }

                secondLeaf.add(sLFrame)

                scene.add(secondLeaf)
            }
        }


        if (mainElement === "slidingDoor" && singleDouble === 'Double') {

            const sLFrame = rectangleFrame((sLdoorWidth + profileWidth / 2), doorFrameHeight, profileWidth, profileDepth, color, metalness, roughness, mainElement, "door");



            //-- Muntins


            let haveMuntins = muntins.findIndex(x => x.childOf === "secondLeaf");

            if (haveMuntins !== -1) {

                let muntinsdesign = createMuntinsPreset(muntins, "secondLeaf", color, metalness, roughness)

                sLFrame.add(...muntinsdesign)
                threeJsMuntins.push(...muntinsdesign)
            }

            // -- Muntins

            haveMuntins = muntins.findIndex(x => x.childOf === "all");

            if (haveMuntins !== -1) {
                let muntinsdesign = createMuntinsPreset(muntins, "all", color, metalness, roughness)

                scene.add(...muntinsdesign)
                threeJsMuntins.push(...muntinsdesign)
            }

            // -- Ironmongery 

            if (ironmongery.type === "typeD") {

                const handle = createHandleTypeD(color, sLdoorWidth / 2, "sL", profileWidth, profileDepth, side, metalness, roughness, ironmongery, singleDouble)

                handle.position.y = -doorFrameHeight / 2 + Number(ironmongery.handleLocation)
                handle.position.x = ironmongery.handleLocationHorizontal

                sLFrame.add(handle)
            }

            if (ironmongery.type === "typeE") {

                const handle = createHandleTypeE(lockCaseDimensions, lockCaseLocation, side, doorWidth, color, uniqid(), "sL", profileWidth, metalness, roughness, lockCaseLocationHorizontal, doorHeight, singleDouble);

                sLFrame.add(handle)

            }

            // --------  Adjustments

            if (side === "left") {
                sLFrame.position.x = -doorFrameWidth / 2 + sLdoorWidth / 2 + 3
            } else {
                sLFrame.position.x = doorFrameWidth / 2 - sLdoorWidth / 2 - 3
            }

            scene.add(sLFrame)

        }


        // -----------------------------------------------------------
        //  Main door ---- Start
        // -----------------------------------------------------------

        if (mainElement === "panel") {

            const mainPanel = rectangleFrame(doorFrameWidth, doorFrameHeight, profileWidth, profileDepth, color, metalness, roughness, mainElement, "door");



            //-- Muntins


            let haveMuntins = muntins.findIndex(x => x.childOf === "mainDoor");

            if (haveMuntins !== -1) {

                let muntinsdesign = createMuntinsPreset(muntins, "mainDoor", color, metalness, roughness)

                mainPanel.add(...muntinsdesign)
                threeJsMuntins.push(...muntinsdesign)
            }

            // -- Muntins

            haveMuntins = muntins.findIndex(x => x.childOf === "all");

            if (haveMuntins !== -1) {
                let muntinsdesign = createMuntinsPreset(muntins, "all", color, metalness, roughness)

                scene.add(...muntinsdesign)
                threeJsMuntins.push(...muntinsdesign)

            }

            scene.add(mainPanel)

        } else if (mainElement === "slidingDoor") {

            const mainPanel = rectangleFrame((doorWidth + profileWidth * 2), doorFrameHeight, profileWidth, profileDepth, color, metalness, roughness, mainElement, "door");



            //-- Muntins


            let haveMuntins = muntins.findIndex(x => x.childOf === "mainDoor");

            if (haveMuntins !== -1) {

                let muntinsdesign = createMuntinsPreset(muntins, "mainDoor", color, metalness, roughness)

                mainPanel.add(...muntinsdesign)
                threeJsMuntins.push(...muntinsdesign)
            }

            // -- Muntins

            haveMuntins = muntins.findIndex(x => x.childOf === "all");

            if (haveMuntins !== -1) {
                let muntinsdesign = createMuntinsPreset(muntins, "all", color, metalness, roughness)

                scene.add(...muntinsdesign)
                threeJsMuntins.push(...muntinsdesign)

            }

            // ----- Ironmongery 

            if (ironmongery.type === "typeD") {

                const handle = createHandleTypeD(color, doorWidth / 2, "mainDoor", profileWidth, profileDepth, side, metalness, roughness, ironmongery, singleDouble)

                handle.position.y = -doorFrameHeight / 2 + Number(ironmongery.handleLocation)
                handle.position.x = ironmongery.handleLocationHorizontal

                mainPanel.add(handle)
            }

            if (ironmongery.type === "typeE") {


                const handle = createHandleTypeE(lockCaseDimensions, lockCaseLocation, side, doorWidth, color, uniqid(), "mainDoor", profileWidth, metalness, roughness, lockCaseLocationHorizontal, doorHeight, singleDouble);

                mainPanel.add(handle)


            }



            // ----  adjustments 

            if (singleDouble === "Double") {

                if (side === "left") {
                    mainPanel.position.x = doorFrameWidth / 2 - doorWidth / 2 - profileWidth
                } else {
                    mainPanel.position.x = -doorFrameWidth / 2 + doorWidth / 2 + profileWidth
                }
            }


            scene.add(mainPanel)




        }


        if (mainElement === "hingedDoor") {


            // ----------------------------
            //  Create Door Frame
            // ----------------------------

            const doorFrame = new Group()


            const frameTop = createBox(doorFrameWidth, profileWidth, profileDepth, color, uniqid(), metalness, roughness);
            const frameLeft = createBox(profileWidth, doorFrameHeight, profileDepth, color, uniqid(), metalness, roughness);
            const frameRight = createBox(profileWidth, doorFrameHeight, profileDepth, color, uniqid(), metalness, roughness);

            frameTop.position.y = (doorFrameHeight / 2) - (profileWidth / 2);
            frameLeft.position.x = (doorFrameWidth / -2) + (profileWidth / 2);
            frameRight.position.x = (doorFrameWidth / 2) - (profileWidth / 2);


            doorFrame.add(frameTop, frameLeft, frameRight)

            scene.add(doorFrame)


            //------- Frame

            const mainDoor = rectangleFrame(doorWidth, doorHeight, profileWidth, profileDepth, color, metalness, roughness, mainElement, "door");

            mainDoor.position.y -= 4;


            // --------  Hinges
            const hinges = createHinges(noHinges, color, side, doorWidth, doorHeight, profileDepth, "main", metalness, roughness);

            mainDoor.add(...hinges)


            //-- Muntins


            let haveMuntins = muntins.findIndex(x => x.childOf === "mainDoor");

            if (haveMuntins !== -1) {

                let muntinsdesign = createMuntinsPreset(muntins, "mainDoor", color, metalness, roughness)

                mainDoor.add(...muntinsdesign)
                threeJsMuntins.push(...muntinsdesign)
            }

            // -- Muntins

            haveMuntins = muntins.findIndex(x => x.childOf === "all");

            if (haveMuntins !== -1) {
                let muntinsdesign = createMuntinsPreset(muntins, "all", color, metalness, roughness)

                scene.add(...muntinsdesign)
                threeJsMuntins.push(...muntinsdesign)

            }

            // ----- Handles


            if (ironmongery.type === "handle") {

                //  Lock case

                const lockCase = createLockCase(lockCaseDimensions, lockCaseLocation, side, doorWidth, color, uniqid(), "main", profileWidth, metalness, roughness, lockCaseLocationHorizontal, doorHeight);

                mainDoor.add(lockCase)

                //  Handle

                const handle = createHandle(ironmongery, color, side, doorWidth, "main", metalness, roughness, doorHeight);


                mainDoor.add(handle)

            } else if (ironmongery.type === "typeA") {

                const handle = createFlatHandle(color, doorWidth / 2, "mainDoor", profileWidth, profileDepth, side, metalness, roughness, ironmongery.height)

                handle.position.y = -doorFrameHeight / 2 + Number(ironmongery.handleLocation)


                mainDoor.add(handle)

            } else if (ironmongery.type === "typeB") {

                const handle = createPushPullHandle(color, doorWidth / 2, "mainDoor", profileWidth, profileDepth, side, metalness, roughness, ironmongery)

                handle.position.y = -doorFrameHeight / 2 + Number(ironmongery.handleLocation)
                handle.position.x = ironmongery.handleLocationHorizontal

                mainDoor.add(handle)

            } else if (ironmongery.type === "typeC") {

                const handle = createHandleTypeC(color, doorWidth / 2, "mainDoor", profileWidth, profileDepth, side, metalness, roughness, ironmongery)

                handle.position.y = -doorFrameHeight / 2 + Number(ironmongery.handleLocation)
                handle.position.x = ironmongery.handleLocationHorizontal

                mainDoor.add(handle)

            }

            // ----  adjustments 

            if (singleDouble === "Double") {

                if (side === "left") {
                    mainDoor.position.x = doorFrameWidth / 2 - doorWidth / 2 - profileWidth - 4
                } else {
                    mainDoor.position.x = -doorFrameWidth / 2 + doorWidth / 2 + profileWidth + 4
                }
            }

            scene.add(mainDoor)

        }



        // -----------------------------------------------------------
        //  Main door ---- End
        // -----------------------------------------------------------




        // ----------------------------
        //  Measurements
        // ----------------------------

        let shiftToSide;
        let allMeasurements = [];

        if (transomeIncluded) {
            shiftToSide = -((doorFrameWidth / 2) + leftPanelWidth + 280);
        } else {
            shiftToSide = -((doorFrameWidth / 2) + leftPanelWidth + 140);
        }

        const shiftToTop = ((doorFrameHeight / 2) + transomHeight + profileWidth + 120);

        /// --------- Left Side Measurements

        const leftSideMeasurements = createSideMeasurements(totalHeight, zoom)



        leftSideMeasurements.position.x = shiftToSide
        leftSideMeasurements.position.y = transomHeight / 2

        if (mainElement === "slidingDoor") {

            leftSideMeasurements.position.z -= profileDepth
            leftSideMeasurements.position.x += profileWidth
        }

        allMeasurements.push(leftSideMeasurements)

        /// ------- Top Measutements

        if (mainElement === "slidingDoor") { 

            if(leftPanelIncluded && rightPanelIncluded ){

                totalWidth -= profileWidth * 2

            }else if(leftPanelIncluded || rightPanelIncluded){
                totalWidth -= profileWidth
            }
            
        }


        const topMeasurements = createTopMeasurement(totalWidth, rightPanelIncluded, leftPanelIncluded, leftPanelWidth, rightPanelWidth, zoom)


        topMeasurements.position.y = shiftToTop


        if (mainElement === "slidingDoor") {

            topMeasurements.position.z -= profileDepth  

        }

        allMeasurements.push(topMeasurements)


        // ---- Transom measurements

        if (transomeIncluded) {
            const transomMeasurements = createSideMeasurements(transomHeight)
            transomMeasurements.position.y = ((doorFrameHeight / 2) + transomHeight / 2)
            transomMeasurements.position.x = -((doorFrameWidth / 2) + leftPanelWidth + 140)

            allMeasurements.push(transomMeasurements)
        }

        // Door Height measurement

        if (transomeIncluded) {
            const doorHeightMeasurements = createSideMeasurements(doorFrameHeight)
            doorHeightMeasurements.position.x = -((doorFrameWidth / 2) + leftPanelWidth + 140)


            allMeasurements.push(doorHeightMeasurements)
        }

        // Door Width measurement

        if (leftPanelIncluded || rightPanelIncluded || mainElement === "slidingDoor") {
            const doorWidthtMeasurements = createBottomMeasurements(doorFrameWidth, zoom)

            doorWidthtMeasurements.position.y = -doorFrameHeight / 2 - 140

            if (mainElement === "slidingDoor") {

                doorWidthtMeasurements.position.z -= profileDepth
 
            }

            allMeasurements.push(doorWidthtMeasurements)

        }

        if (leftPanelIncluded) {
            const lpWidthtMeasurements = createBottomMeasurements(leftPanelWidth, zoom)

            lpWidthtMeasurements.position.y = -doorFrameHeight / 2 - 140
            lpWidthtMeasurements.position.x = -doorFrameWidth / 2 - leftPanelWidth / 2

            if (mainElement === "slidingDoor") {

                lpWidthtMeasurements.position.z -= profileDepth
                lpWidthtMeasurements.position.x += profileWidth
               
            }

            

            allMeasurements.push(lpWidthtMeasurements)

        }

        if (rightPanelIncluded) {
            const rpWidthtMeasurements = createBottomMeasurements(rightPanelWidth, zoom)

            rpWidthtMeasurements.position.y = -doorFrameHeight / 2 - 140
            rpWidthtMeasurements.position.x = doorFrameWidth / 2 + rightPanelWidth / 2

            if (mainElement === "slidingDoor") {

                rpWidthtMeasurements.position.z -= profileDepth
                rpWidthtMeasurements.position.x -= profileWidth
 
            }

            allMeasurements.push(rpWidthtMeasurements)

        }


        // add to scene
        scene.add(...allMeasurements)


        // ----------------------------
        //  Create floor
        // ----------------------------
        if (floor) {
            const floorGeometry = new CircleGeometry(floorsize, 40);
            const floorMaterial = new MeshStandardMaterial({
                // roughness: 0.9,
                color: floorColor,
                //color: "#ff6600" ,
                // // clearcoat: .5,
                //transpopacity: 0.3
                // metalness: .9


            });

            const floor = new Mesh(floorGeometry, floorMaterial);
            floor.receiveShadow = true;
            floor.position.y = -((doorFrameHeight / 2));
            floor.rotation.x = - Math.PI * 0.5

            scene.add(floor);

        }



        // ----------------------------
        //  Glass - experiment
        // ----------------------------









        const loader = new RGBELoader();

        let glassMaterial;

        // let reflection = loader.load(hdrFile, function(texture){



        //     //scene.background = texture;
        //     //scene.environment = texture;

        //   return (texture.mapping = EquirectangularReflectionMapping)

        // })

        // console.log(glass)


        glassMaterial = {
            //color: 0xcccccc,
            color: glass.color,
            roughness: Number(glass.roughness),
            metalness: Number(glass.metalness),
            transmission: Number(glass.transmission),
            thickness: Number(glassThickness),
            reflectivity: Number(glass.reflectivity),
            clearcoat: Number(glass.clearcoat),

            transparent: true,
            opacity: Number(glass.opacity),
            // envMapIntensity: 0.9,
            ///envMap : reflection
        }





        const geometry = new BoxGeometry((doorWidth - (profileWidth)), (doorHeight - profileWidth), glassThickness)
        const material = new MeshPhysicalMaterial(glassMaterial)

        // Number(this.props.doorSetup.glassSetup.roughness)

        const selectedGlass = new Mesh(geometry, material)

        scene.add(selectedGlass)



        //Glass - experiment end




        // ----------------------------
        //  Lights
        // ----------------------------




        const ambientLight = new AmbientLight(0xefefef, .3)
        scene.add(ambientLight)




        lights.map(light => {

            if (light.type === "PointLight") {
                const light1 = new PointLight(light.color, light.strenght)
                light1.position.x = light.x
                light1.position.y = light.y
                light1.position.z = light.z
                //const light1Helper = new PointLightHelper(light1, 100)
                scene.add(
                    light1,
                    // light1Helper
                )
            }

            if (light.type === "DirectionalLight") {

                const light1 = new DirectionalLight('#ffffff', 0.5)
                light1.position.x = light.x
                light1.position.y = light.y
                light1.position.z = light.z

                // light1.lookAt(hinge)


                // const light1Helper = new DirectionalLightHelper(light1, 100)

                scene.add(
                    light1,
                    //light1Helper
                )
            }

        })


        // const light1 = new PointLight('#ffffff', 0.7)
        // light1.position.x = totalWidth / 2
        // light1.position.y = doorHeight * 0.5
        // light1.position.z = doorHeight * 1

        // const light2 = new PointLight('#ffffff', 0.2)
        // light2.position.x = -totalWidth
        // light2.position.y = -doorHeight * 0.5
        // light2.position.z = doorHeight * 1

        // //const light1Helper = new DirectionalLightHelper(light1, 100)

        // scene.add(
        //     // light1,
        //     // light2
        //     // light1Helper
        // )

        // light1.castShadow = true;
        // light1.shadow.radius = 1.64

        /// ---------------------
        // Camera
        // ---------------------


        const camera = new PerspectiveCamera(25, sizes.width / sizes.height, 1000, doorFrameHeight * 10)

        const xCenter = topMeasurements.position.x
        const yCenter = leftSideMeasurements.position.y

        //let cameraZoom = totalHeight * 2.8

        let cameraZoom = Number(zoom) 






        camera.position.set(xCenter, yCenter, cameraZoom)
        // camera.position.z = totalHeight * 1.7

        // camera.position.x = -totalWidth + doorFrameWidth 
        camera.lookAt(xCenter, yCenter, cameraZoom);
        //camera.position.y = totalHeight
        //camera.lookAt(mainDoorFrame.children[0].position)







        const controlls = new OrbitControls(camera, canvas);

        controlls.minDistance = 1000;
        controlls.maxDistance = doorFrameHeight * 8;
        controlls.enableDamping = true;

        controlls.target.x = xCenter
        controlls.target.y = yCenter

        controlls.enabled = orbitCam;

        scene.add(camera)






        /// ---------------------  ---------------------
        /// --------------------- Wall
        /// ---------------------  ---------------------

        if (this.props.doorSetup.wall) {

            const wall = createBox(
                totalWidth + 2000,
                totalHeight + 300,
                100,
                "#efefef",
                uniqid(),
                0.1,
                .9
            )

            const wallOpening = createBox(
                totalWidth,
                totalHeight,
                100,
                color,
                uniqid(),
                metalness,
                roughness
            )
            wallOpening.position.y = -150


            wall.updateMatrix();
            wallOpening.updateMatrix();

            const doorWall = CSG.subtract(wall, wallOpening);
            doorWall.position.y = 150

            if (mainElement === "slidingDoor") {
                doorWall.position.z = -75
            }



            scene.add(doorWall)
        }



        // Make objects draggable 

        /// -----------------------------------------------------------------------------------------------------

        if (draggable) {



            let check = 1

            const dConttols = new DragControls(threeJsMuntins, camera, canvas)

            dConttols.addEventListener('drag', (event) => {
                event.object.position.z = 0;
                event.object.material.color.set(0xff6600)

                // console.log( event.object)

                //console.log(muntins)


            })





            dConttols.addEventListener('dragend', (event) => {

                console.log("chech: " + check)



                const index = muntins.findIndex(x => x.bfId === event.object.bfId)

                console.log(index)

                if (index !== -1) {
                    muntins[index].position.x = Math.round(Number(event.object.position.x))
                    muntins[index].position.y = Math.round(Number(event.object.position.y))
                    muntins[index].position.z = 0
                }

                this.props.updateMuntinLocation(muntins)

                dConttols.dispose()

                check++

            })


            if (orbitCam === true) {
                dConttols.enabled = false
            }

        }


        //// -------


        /// -----------------------------------------------------------------------------------------------------

        // CHange with of the canvas to fill the sceen



        camera.aspect = sizes.width / sizes.height
        camera.updateProjectionMatrix()

        // Renderer
        const renderer = new WebGLRenderer({
            canvas: canvas,
            antialias: true
        })

        //renderer.outputEncoding = sRGBEncoding;
        //renderer.toneMapping = ACESFilmicToneMapping;
        renderer.toneMappingExposure = .7

        renderer.setSize(sizes.width, sizes.height)

        renderer.render(scene, camera)



        //Animacja sceny - wazne 

        function animate() {
            controlls.update();

            renderer.render(scene, camera);
            requestAnimationFrame(animate);
        }


        if (animation) { animate(); }

        //czyszczenie pamięci

        // renderer.dispose()
        // renderer.forceContextLoss()



    }


    render() {
        return (
            <div id={"scena" + this.props.id} style={{ height: this.props.height, maxWidth: `${Number(this.props.height.slice(0, -2)) * 2.5}px`, minWidth: `${this.props.minWidth}px` }}>
                <canvas className={this.props.id} ></canvas>
            </div>

        )

    }

}
export default withStyles(styles)(Customers);
