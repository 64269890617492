import React, { Component } from 'react';
import {userContext} from '../../../Context/userContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    textField:{
        width: '100%',
        margin: "16px 3px",
        background: "#fff"
    },
    


});



class ConfigMuntins extends Component{
    render() {
        const { classes } = this.props;
        return ( <userContext.Consumer>
            {(user) => ( user.access >= 1 ? 
            
            <div style={{display: "flex", flexDirection: 'column'}}>

                < TextField
                      
                    id="muntinsDesigne"
                    select
                    label="Door muntins Designe"
                    className={classes.textField}
                    value={this.props.muntinsDesigne.mainDoor}
                    onChange={this.props.setMuntinDesign("mainDoor")}
                    SelectProps={{
                        native: true,
                        MenuProps: {
                        className: classes.menu,
                        },
                    }}
                    helperText=""
                    margin="normal"
                      >
                        <option key="0" value="0">0</option>
                        <option key="1" value="1">1</option>
                        <option key="2" value="2">2</option>
                        <option key="3" value="3">3</option>
                        <option key="4" value="4">4</option>
                     
                       
                  </TextField>
                  { this.props.singleDouble === "Double" &&
                  < TextField
                      id="sLmuntinsDesigne"
                      select
                      label="Second Leaf muntins Designe"
                      className={classes.textField}
                      value={this.props.muntinsDesigne.secondLeaf}
                      onChange={this.props.setMuntinDesign("secondLeaf")}
                      SelectProps={{
                          native: true,
                          MenuProps: {
                          className: classes.menu,
                          },
                      }}
                      helperText=""
                      margin="normal"
                      >
                        <option key="0" value="0">0</option>
                        <option key="1" value="1">1</option>
                        <option key="2" value="2">2</option>
                        <option key="3" value="3">3</option>
                        <option key="4" value="4">4</option>
                  </TextField>
                  }

                  { this.props.leftPanel &&
                      < TextField
                          id="lPmuntinsDesigne"
                          select
                          label="Left panel muntins designe"
                          className={classes.textField}
                          value={this.props.muntinsDesigne.leftPanel}
                          onChange={this.props.setMuntinDesign("leftPanel")}
                          SelectProps={{
                              native: true,
                              MenuProps: {
                              className: classes.menu,
                              },
                          }}
                          helperText=""
                          margin="normal"
                          >
                            <option key="0" value="0">0</option>
                            <option key="1" value="1">1</option>
                            <option key="2" value="2">2</option>
                            <option key="3" value="3">3</option>
                            <option key="4" value="4">4</option>
                      </TextField>
                  }

                  { this.props.rightPanel &&
                      < TextField
                          id="rPmuntinsDesigne"
                          select
                          label="Right panel muntins designe"
                          className={classes.textField}
                          value={this.props.muntinsDesigne.rightPanel}
                          onChange={this.props.setMuntinDesign("rightPanel")}
                          SelectProps={{
                              native: true,
                              MenuProps: {
                              className: classes.menu,
                              },
                          }}
                          helperText=""
                          margin="normal"
                          >
                            <option key="0" value="0">0</option>
                            <option key="1" value="1">1</option>
                            <option key="2" value="2">2</option>
                            <option key="3" value="3">3</option>
                            <option key="4" value="4">4</option>
                      </TextField>
                  }

                  {/* { this.props.transom &&
                      < TextField
                          id="trmuntinsDesigne"
                          select
                          label="Transom muntins designe"
                          className={classes.textField}
                          value={this.props.muntinsDesigne.transom}
                          onChange={this.props.setMuntinDesign("transom")}
                          SelectProps={{
                              native: true,
                              MenuProps: {
                              className: classes.menu,
                              },
                          }}
                          helperText=""
                          margin="normal"
                          >
                              <option key="0" value="0">0</option>
                              <option key="4" value="4">4</option>
                              <option key="8" value="8">8</option>
                      </TextField>
                  } */}
      
      

          

          <Button  onClick={()=>{this.props.addMuntin()}} type="outlined">Add Element</Button>

              { this.props.muntins.map( (obj,key)=>{ 
                  return(
                      < div key={key} style={{display: 'flex'}}>
                          {/* {JSON.stringify(obj)} */}
                          <TextField 
                              style={{}}
                              label="Width"
                              onChange={this.props.handleChangeSize('width', obj.bfId )}
                              className={classes.textField}
                              value={obj.w}
                              type="number"
                          ></TextField>
                          <TextField 
                              label="Height"
                              onChange={this.props.handleChangeSize('height', obj.bfId)}
                              value={obj.h}
                              className={classes.textField}
                              type="number"
                          ></TextField>
                          <TextField 
                              label="Position x"
                              onChange={this.props.handleChangeSize('positionX', obj.bfId)}
                              value={obj.position.x}
                              className={classes.textField}
                              type="number"
                              InputProps={{ inputProps: { max: 1500 , min: -1500} }}
                          ></TextField>
                          <TextField 
                              label="Position Y"
                              onChange={this.props.handleChangeSize('positionY', obj.bfId)}
                              value={obj.position.y}
                              className={classes.textField}
                              type="number"
                              InputProps={{ inputProps: { max: 1500 , min: -1500} }}
                          ></TextField>
                      </div>
                  )
              })}


            </div> : <> { this.state.spinner ? <CircularProgress/> : <span></span> }  </>
        )}</userContext.Consumer> )   
    }
}

export default withStyles(styles)(ConfigMuntins)