import React, { Component } from 'react';

class Louver extends Component{

    state= {

        vetGrid:  []
    }

    componentDidUpdate(prevProps){

        if(prevProps !== this.props){

            let ventGrid = [];
            let nm =  (this.props.height/8)/this.props.printRatio;


            for(let i = 1 ; i < nm ; i++){
                ventGrid[i] = 1 ;
            }

            this.setState({
                vetGrid : ventGrid
            })
        }
    }

    render() {
        
        return (
            <div>
                <div style={{height: `${this.props.height/this.props.printRatio}px`,  width: `${this.props.width/this.props.printRatio}px`,  position: 'absolute', bottom: `${this.props.bottom/this.props.printRatio}px`,left: '50%', transform: 'translate(-50%)', 'boxSizing': 'border-box'}}
                 >
                    { this.state.vetGrid.map((vent, index) =>
                        <span key={index}>
                        { Number(this.props.width) < 300 ? (
                            < div key={index} style={{width: '100%', boxSizing: 'border-box'}} >
                                <div style={{borderBottom: '1px solid black', width: '40%', float: 'left', height: `8px`, borderRadius: '5px', margin: '0px 5%', boxSizing: 'border-box'}}></div>
                                <div style={{borderBottom: '1px solid black', width: '40%', float: 'left', height: `8px`, borderRadius: '5px', margin: '0px 5%', boxSizing: 'border-box'}}></div>
                            </div>
                        ) : ( Number(this.props.width) >= 300  && Number(this.props.width) < 400)? (
                            < div key={index} style={{width: '100%', boxSizing: 'border-box'}} >
                                <div style={{borderBottom: '1px solid black', width: '28%', float: 'left', height: `8px`, borderRadius: '5px', margin: '0px', boxSizing: 'border-box'}}></div>
                                <div style={{borderBottom: '1px solid black', width: '28%', float: 'left', height: `8px`, borderRadius: '5px', margin: '0px 8%', boxSizing: 'border-box'}}></div>
                                <div style={{borderBottom: '1px solid black', width: '28%', float: 'left', height: `8px`, borderRadius: '5px', margin: '0px', boxSizing: 'border-box'}}></div>
                            </div>
                        ) :  (Number(this.props.width) >= 400 && Number(this.props.width) <600 ) ?(
                            < div key={index} style={{width: '100%', boxSizing: 'border-box'}} >
                                <div style={{borderBottom: '1px solid black', width: '20%', float: 'left', height: `8px`, borderRadius: '5px', margin: '0px 2.5%', boxSizing: 'border-box'}}></div>
                                <div style={{borderBottom: '1px solid black', width: '20%', float: 'left', height: `8px`, borderRadius: '5px', margin: '0px 2.5%', boxSizing: 'border-box'}}></div>
                                <div style={{borderBottom: '1px solid black', width: '20%', float: 'left', height: `8px`, borderRadius: '5px', margin: '0px 2.5%', boxSizing: 'border-box'}}></div>
                                <div style={{borderBottom: '1px solid black', width: '20%', float: 'left', height: `8px`, borderRadius: '5px', margin: '0px 2.5%', boxSizing: 'border-box'}}></div>   
                            </div>
                        ) :  (Number(this.props.width) >= 600 && Number(this.props.width) < 800 ) ?(
                            < div key={index} style={{width: '100%', boxSizing: 'border-box'}} >
                                <div style={{borderBottom: '1px solid black', width: '16%', float: 'left', height: `8px`, borderRadius: '5px', margin: '0px 2%', boxSizing: 'border-box'}}></div>
                                <div style={{borderBottom: '1px solid black', width: '16%', float: 'left', height: `8px`, borderRadius: '5px', margin: '0px 2%', boxSizing: 'border-box'}}></div>
                                <div style={{borderBottom: '1px solid black', width: '16%', float: 'left', height: `8px`, borderRadius: '5px', margin: '0px 2%', boxSizing: 'border-box'}}></div>
                                <div style={{borderBottom: '1px solid black', width: '16%', float: 'left', height: `8px`, borderRadius: '5px', margin: '0px 2%', boxSizing: 'border-box'}}></div>
                                <div style={{borderBottom: '1px solid black', width: '16%', float: 'left', height: `8px`, borderRadius: '5px', margin: '0px 2%', boxSizing: 'border-box'}}></div>
                            </div>
                        )  :  (Number(this.props.width) >= 800 && Number(this.props.width) < 1000 ) ?(
                            < div key={index} style={{width: '100%', boxSizing: 'border-box'}} >
                                <div style={{borderBottom: '1px solid black', width: '12.5%', float: 'left', height: `8px`, borderRadius: '5px', margin: '0px 2%', boxSizing: 'border-box'}}></div>
                                <div style={{borderBottom: '1px solid black', width: '12.5%', float: 'left', height: `8px`, borderRadius: '5px', margin: '0px 2%', boxSizing: 'border-box'}}></div>
                                <div style={{borderBottom: '1px solid black', width: '12.5%', float: 'left', height: `8px`, borderRadius: '5px', margin: '0px 2%', boxSizing: 'border-box'}}></div>
                                <div style={{borderBottom: '1px solid black', width: '12.5%', float: 'left', height: `8px`, borderRadius: '5px', margin: '0px 2%', boxSizing: 'border-box'}}></div>
                                <div style={{borderBottom: '1px solid black', width: '12.5%', float: 'left', height: `8px`, borderRadius: '5px', margin: '0px 2%', boxSizing: 'border-box'}}></div>
                                <div style={{borderBottom: '1px solid black', width: '12.5%', float: 'left', height: `8px`, borderRadius: '5px', margin: '0px 2%', boxSizing: 'border-box'}}></div>
                            </div>
                        ):( <div></div>)}
                        </span>
                    )} 
                </div>  
            </div>  
       )
    }
}

export default Louver;