import React, { Component } from 'react';
import Lead from './Lead';
import { withStyles } from '@material-ui/core/styles';
import {Input, Button, Checkbox, FormControlLabel, Container} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
// import * as firebase from 'firebase';
import firebase from "firebase/compat/app";
import {usersContext} from '../Context/usersContext';
import {userContext} from '../Context/userContext';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
    stageColumn:{
        width: '16%',
        display: "flex",
        flexDirection: "column"
    },
    stageHeader:{
        borderBottom: "1px solid #000",
        borderTop: "1px solid #000",
        marginBottom: "2em",
        marginTop: "2em",
        height: '50px',
        background: 'white'
    },
    arrow:{
        width:"1%", height:"50px",
        borderBottom: '1px solid #000',
        borderTop: '1px solid #000',
        marginBottom: "2em",
        marginTop: "2em",
    },
    accoredeon:{
        boxShadow: 'none',
        borderTopColor: 'rgba(0, 0, 0, 0);'
    },
    textField:{
        margin: '1em',
    },
  

  });


class Leads extends Component{
    
    state = {
        leads: [],
        users: [],
        searchItems: [],
        activeDeals : 0,
        dealsValue: 0,
        users : [{name: 'test'}],
        loading: true,
        myTasks: false
    }

    handelAddDeal = () => () =>{


      
        firebase.database().ref(`Leads/`).push({
            date: Date(),
            priority: 1,
            prospectEmail: '',
            prospectName: '',
            stage: 1,
            status: 'open',
            refference: 'New Deal',
            manualEntry: true,
            addedBy: this.context.initials
            
        })
    }

    getMyTasks = (user) => event =>{

       

        let list;

        if(this.state.searchItems.length < 1){
            list = this.state.leads
        }else{
            list = this.state.searchItems
        }

        //console.log(list)

        let prefiltered= [] ;
        
        
        list.forEach(function(item){
            if(item[1].timeline != null){


                Object.entries(item[1].timeline).forEach(function(event){

                    if(event[1].type === 'task' ){

                        if(event[1].taskAsignedTo != null){
                            
                            if(event[1].taskAsignedTo === user && event[1].completed === false){ 

                                prefiltered.push(item) 
                            }
                        } 
                    }  
                })
            }
        })




        this.setState({
            myTasks: !this.state.myTasks,
        },()=>{this.filterByPerson(prefiltered)})
    }

    filterByPerson = (list) =>{


        if(this.state.myTasks){
            this.setState({
                searchItems: list
            })
        }else{
            this.setState({
                searchItems: this.state.leads
            })
        }

      


    }


    filterList = (name ) => event =>{

        if(event.target.value === ''){
            this.loadSearchItems()
        }else{
            let updatedList = this.state.leads;

            updatedList = updatedList.filter(function(item){
                if(item[1][name]){
            return item[1][name].toLowerCase().search(event.target.value.toLowerCase()) !== -1;
                }
            });
            this.setState({searchItems: updatedList,  myTasks: false });
        }
    }


    loadSearchItems(){
        this.setState({searchItems: this.state.leads})
    }


    componentDidMount(){

        const leadsRef = firebase.database().ref('Leads');

        leadsRef.on('value',snap =>{
            let leads = snap.val()
            if(leads !== null){
                let allleads = Object.entries(leads).reverse();      
                this.setState({
                    leads: allleads,
                    loading: false
                },()=>{this.loadSearchItems()  ; this.sumUp()})
            }
        })

        const usersRef = firebase.database().ref('Users');

        usersRef.on('value',snap =>{
            let users = snap.val()
            if(users !== null){
                let allusers = Object.entries(users);      
                this.setState({
                    users: allusers
                })
            }
        })
      

    }

    sumUp =()=>{


        let activeDeals = 0;
        let dealsValue = 0;

            this.state.leads.forEach(lead => {

                if(lead[1].status === 'open'){
                    activeDeals ++;
                    lead[1].dealValue ? 
                    dealsValue += Number(lead[1].dealValue) :
                    dealsValue += 0;
                }
            });

        this.setState({
            activeDeals: activeDeals,
            dealsValue: dealsValue,
        })

    }



    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {

            //this.sumUp()
      
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <usersContext.Provider value={this.state.users}>
                <userContext.Consumer>
                    { (user) => ( user.access >= 1 &&
            <Container maxWidth="xl">
                
                <Button variant="outlined" color="primary" aria-label="add" style={{float: 'right', margin: '1rem'}} onClick={this.handelAddDeal()}>
                    <AddIcon />
                </Button>
                    <h2>Sales Pipeline</h2>
                
                                <span>Open Deals: <strong>{this.state.activeDeals}</strong></span>
                                <span style={{marginLeft: '2em'}}>Total Deals Value: <strong>{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format(this.state.dealsValue)}</strong></span><br/>

                                   
                                    <SearchIcon style={{fontSize: '1rem'}} />
                                        <Input 
                                            type="text" 
                                            className={classes.textField} 
                                            placeholder="Company" 
                                            onChange={this.filterList('company')}>
                                        </Input>

                                        <Input 
                                            type="text" 
                                            className={classes.textField} 
                                            placeholder="Customer Name" 
                                            onChange={this.filterList('prospectName')}>
                                        </Input>

                                        <Input 
                                            type="text" 
                                            className={classes.textField} 
                                            placeholder="Refference:" 
                                            onChange={this.filterList('refference')}>
                                        </Input>

                                        <Input 
                                            type="text" 
                                            className={classes.textField} 
                                            placeholder="Email" 
                                            onChange={this.filterList('prospectEmail')}>
                                        </Input>

                                        <FormControlLabel
                                            value="start"
                                            control={
                                                    <userContext.Consumer>
                                                    {(user) => ( user.access >= 1 &&
                                                    <Checkbox
                                                        checked={this.state.myTasks}
                                                        onChange={this.getMyTasks(user.initials)}
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                    )}
                                                    </userContext.Consumer>
                                            }
                                            label="My Tasks"
                                            labelPlacement="start"
                                        />


                                        { this.state.loading  ? <span style={{display : 'block'}}> Loading <CircularProgress color="primary" />  </span> :
                                        
                                    
                                            <div style={{display: "flex"}}>
                                                <div className={classes.stageColumn}>
                                                    <div className={classes.stageHeader}>
                                                        <h2>Lead In</h2>  
                                        
                                                    </div>
                                                    
                                                    { this.state.searchItems.map(lead=>(
                                                        Number(lead[1].stage) === 1 &&   
                                                        <div key={lead[0]} style={{ order: lead[1].priority}}>
                                                            <Lead 
                                                                lead={lead} 
                                                                
                                                                userEmail={this.props.userEmail}>
                                                            </Lead>
                                                            
                                                        </div>
                                                        
                                                    ))}
                                                </div>
                                                <svg  className={classes.arrow} ><g fill="none" fillRule="evenodd"><path  fill="#ffffff" d="M0 0h16v56H0z"></path><path  fill="#D80026" d="M1 0l8 28-8 28H0V0z"></path><path  fill="#ffffff" d="M0 1l8 27-8 27z"></path></g></svg>
                                                <div className={classes.stageColumn}>
                                                    <div className={classes.stageHeader}><h2>Contact Made</h2></div>
                                                    { this.state.searchItems.map(lead=>(
                                                        Number(lead[1].stage) === 2 &&   
                                                        <div key={lead[0]} style={{ order: lead[1].priority}}>
                                                            <Lead 
                                                                lead={lead} 
                                                                
                                                                userEmail={this.props.userEmail}>
                                                            </Lead>    
                                                        </div>
                                                    ))}
                                                </div>
                                                <svg  className={classes.arrow} ><g fill="none" fillRule="evenodd"><path  fill="#ffffff" d="M0 0h16v56H0z"></path><path  fill="#D80026" d="M1 0l8 28-8 28H0V0z"></path><path  fill="#ffffff" d="M0 1l8 27-8 27z"></path></g></svg>
                                                <div className={classes.stageColumn}>
                                                    <div className={classes.stageHeader}><h2>Needs Defined</h2></div>
                                                    { this.state.searchItems.map(lead=>(
                                                        Number(lead[1].stage) === 3 &&   
                                                        <div key={lead[0]}  style={{ order: lead[1].priority}}>
                                                            <Lead 
                                                                lead={lead} 
                                                                
                                                                userEmail={this.props.userEmail}>
                                                            </Lead>
                                                        </div>
                                                    ))}
                                                </div>
                                                <svg  className={classes.arrow} ><g fill="none" fillRule="evenodd"><path  fill="#ffffff" d="M0 0h16v56H0z"></path><path  fill="#D80026" d="M1 0l8 28-8 28H0V0z"></path><path  fill="#ffffff" d="M0 1l8 27-8 27z"></path></g></svg>
                                                <div className={classes.stageColumn}>
                                                    <div className={classes.stageHeader}><h2>Costs Estimated</h2></div>
                                                    { this.state.searchItems.map(lead=>(
                                                        Number(lead[1].stage) === 4 &&   
                                                        <div key={lead[0]}  style={{ order: lead[1].priority}}>
                                                            <Lead 
                                                                lead={lead} 
                                                                
                                                                userEmail={this.props.userEmail}>
                                                            </Lead>
                                                        </div>
                                                    ))}
                                                </div>
                                                <svg  className={classes.arrow} ><g fill="none" fillRule="evenodd"><path  fill="#ffffff" d="M0 0h16v56H0z"></path><path  fill="#D80026" d="M1 0l8 28-8 28H0V0z"></path><path  fill="#ffffff" d="M0 1l8 27-8 27z"></path></g></svg>
                                                <div className={classes.stageColumn}>
                                                    <div className={classes.stageHeader}><h2>Proposal Made</h2></div>
                                                    { this.state.searchItems.map(lead=>(
                                                        Number(lead[1].stage) === 5 &&   
                                                        <div key={lead[0]}  style={{ order: lead[1].priority}}>
                                                            <Lead 
                                                                lead={lead} 
                                                                
                                                                userEmail={this.props.userEmail}>
                                                            </Lead>
                                                        </div>
                                                    ))}
                                                </div>
                                                <svg  className={classes.arrow} ><g fill="none" fillRule="evenodd"><path  fill="#ffffff" d="M0 0h16v56H0z"></path><path  fill="#D80026" d="M1 0l8 28-8 28H0V0z"></path><path  fill="#ffffff" d="M0 1l8 27-8 27z"></path></g></svg>
                                                <div className={classes.stageColumn}>
                                                    <div className={classes.stageHeader}><h2>Site survey</h2></div>
                                                    { this.state.searchItems.map(lead=>(
                                                        Number(lead[1].stage) === 6 &&   
                                                        <div key={lead[0]}  style={{ order: lead[1].priority}}>
                                                            <Lead 
                                                                lead={lead} 
                                                                
                                                                userEmail={this.props.userEmail}>
                                                            </Lead>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>  
                                            
                                        
                                        
                                        }
  
            </Container>
                    )}
                        </userContext.Consumer>

            </usersContext.Provider>
        );
        
    }
  }
export default withStyles(styles)(Leads);
Leads.contextType = userContext;