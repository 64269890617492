import React, { Component } from 'react';
// import * as firebase from 'firebase'
import firebase from "firebase/compat/app";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import SelectCustomer from '../Customers/selectCustomer'
import moment from 'moment';

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    inputField: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: 200,

        ['@media (max-width:600px)']: {
            display: 'inline-flex',
            width: '90%',
            margin: '10px 5%'
        }
    },
    root: {
        width: '100%',
        marginTop: theme.spacing(2),
        overflowX: 'auto',
    },
    table: {
        minWidth: 250,
    },
});

class AddOrder extends Component {

    state = {
        Customer: '',
        DateApproved: '',
        Reference: 'New Order',
        LeadTime: 8,
        OrderClosed: false,
        OrderNumber: '',
        Quote: '',
        Date: '',
    }
    handleChange = name => event => {
        this.setState({
            [name]: event.target.value
        })
    }

    autocompleteUpdate = (event, values) => {
        if (values != null) {
            this.setState({ Customer: values.id })
        } else {
            this.setState({ name: '' })
        }
    }

    componentDidMount() {

    }

    newOrder = () => {

        if (this.state.Customer === '') {
            alert('Select customer')
        } else if (this.state.LeadTime === '') {
            alert('Eneter Lead Time')
        } else {

            const orderNumber = firebase.database().ref().child('OrderNumber')
            let orderNo = '';

            return orderNumber.once('value').then((snap) => {

                orderNo = snap.val() + 1;

                firebase.database().ref().update({ 'OrderNumber': orderNo }).then((snap) => { });
            }).then(() => {
                const ordersRef = firebase.database().ref().child('Orders');

                let OrderNo = orderNo + '-' + moment().year();

                ordersRef.push({
                    'Reference': this.state.Reference,
                    'DateApproved': moment().format('L'),
                    'Customer': this.state.Customer,
                    'OrderNumber': OrderNo,
                    'OrderClosed': false,
                    'Quote': 'Manual entry',
                    'LeadTime': this.state.LeadTime
                });
            }

            )
        }

    }

    render() {

        return (

            <div>

                <SelectCustomer autocompleteUpdate={this.autocompleteUpdate}></SelectCustomer>

                <TextField
                    id="reference"
                    label="Order Reference"
                    value={this.state.Reference}
                    onChange={this.handleChange('Reference')}
                    className={this.props.classes.inputField}
                />

                <TextField
                    id="leadTime"
                    label="Lead Time (weeks)"
                    value={this.state.LeadTime}
                    onChange={this.handleChange('LeadTime')}
                    className={this.props.classes.inputField}
                />

                <Button variant="outlined" color="primary" onClick={this.newOrder}>Add New</Button>

            </div>
        );

    }
}
export default withStyles(styles)(AddOrder);