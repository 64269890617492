import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';


const styles = theme => ({
    textField:{
        width: '100%',
    },
})
class CustomerDetails extends Component {
    render(){
        //const { classes } = this.props;
        return(
            <div>
                

                {/* ----------------------------------------------   Door Width */}

                    <TextField
                        id="companyName"
                        label="Company Name"
                        type="text"
                        className={this.props.classes.textField}
                        value={this.props.companyName}
                        onChange={this.props.handleChange('companyName')}
                        margin="normal"
                    />
                {/* ----------------------------------------------   Contact Name */}

                    <TextField
                        id="contactName"
                        label="Contact Name"
                        type="text"
                        className={this.props.classes.textField}
                        value={this.props.contactName}
                        onChange={this.props.handleChange('contactName')}
                        margin="normal"
                    />
                {/* ----------------------------------------------   Contact Email */}

                    <TextField
                        id="contactEmail"
                        label="Contact Email"
                        type="email"
                        className={this.props.classes.textField}
                        value={this.props.contactEmail}
                        onChange={this.props.handleChange('contactEmail')}
                        margin="normal"
                    />

                {/* ----------------------------------------------   Phone Number */}

                    <TextField
                        id="phoneNumber"
                        label="Phone Number"
                        type="text"
                        className={this.props.classes.textField}
                        value={this.props.phoneNumber}
                        onChange={this.props.handleChange('phoneNumber')}
                        margin="normal"
                    />
                {/* ----------------------------------------------   First Address Line */}

                    <TextField
                        id="firstAddressLine"
                        label="First Address Line"
                        type="text"
                        className={this.props.classes.textField}
                        value={this.props.firstAddressLine}
                        onChange={this.props.handleChange('firstAddressLine')}
                        margin="normal"
                    />
                {/* ----------------------------------------------   Second Address Line */}

                    <TextField
                        id="secondAddressLine"
                        label="Second Address Line"
                        type="text"
                        className={this.props.classes.textField}
                        value={this.props.secondAddressLine}
                        onChange={this.props.handleChange('secondAddressLine')}
                        margin="normal"
                    />

                {/* ----------------------------------------------   Second Address Line */}

                    <TextField
                        id="city"
                        label="City"
                        type="text"
                        className={this.props.classes.textField}
                        value={this.props.city}
                        onChange={this.props.handleChange('city')}
                        margin="normal"
                    />
                
                {/* ----------------------------------------------   County */}

                    <TextField
                        id="county"
                        label="County"
                        type="text"
                        className={this.props.classes.textField}
                        value={this.props.county}
                        onChange={this.props.handleChange('county')}
                        margin="normal"
                    />
                
                {/* ----------------------------------------------   County */}

                    <TextField
                        id="country"
                        label="Country"
                        type="text"
                        className={this.props.classes.textField}
                        value={this.props.country}
                        onChange={this.props.handleChange('country')}
                        margin="normal"
                    />
                {/* ----------------------------------------------   refernceNumber */}
                
                    <TextField
                            id="refernceNumber"
                            label="refernceNumber"
                            type="text"
                            className={this.props.classes.textField}
                            value={this.props.refernceNumber}
                            onChange={this.props.handleChange('refernceNumber')}
                            margin="normal"
                        />

                    {/* {this.props.refernceNumber}
                    {this.props.quoteId}
                    {this.props.quoteDate} */}


                    
            </div>
        )
    }

};

export default withStyles(styles)(CustomerDetails);