import React, { Component } from 'react';
import { Redirect } from 'react-router';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { userContext } from '../Context/userContext';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';

// import * as firebase from 'firebase'
import firebase from "firebase/compat/app";

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { cs } from 'date-fns/locale';

import { FinReport, suppliers, costCategories } from '../logic/financialReports';

import { Bar, Line } from 'react-chartjs-2';
import { roundToTwo } from "../logic/GetData"

import { date } from '../logic/date';







let uniqid = require('uniqid');



const styles = theme => ({
    root: {
        //flexGrow: 1,
        margin: '50px auto',
        width: '90%',
    },


    textField: {
        textAlign: 'left',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: 200,
        ['@media (max-width:600px)']: {
            display: 'inline-flex',
            width: '90%',
            margin: '10px 5%'
        }
    },

    paper: {
        padding: '1em',
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
});


const years = [
    {
        value: 2017, label: '2017',
    },
    {
        value: 2018, label: '2018',
    },
    {
        value: 2019, label: '2019',
    },
    {
        value: 2020, label: '2020',
    },
    {
        value: 2021, label: '2021',
    },
    {
        value: 2022, label: '2022',
    },
    {
        value: 2023, label: '2023',
    }
]



// source : https://www.brex.com/blog/business-expense-categories/


class YearlyReports extends Component {
    state = {
        year: 2023,
        orders: [],
        incomes: [],
        costs: [],
        loading: true,
        generalCosts: [],
        totalCosts: 0,
        totalIncome: 0,
        incomeBySupplier: [],
        chartData: {},
        agregatedCostByCategory: []

    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    // getReport = (orders, generalCosts , year, month)=>() =>{

    //     let yearlyReportsRes = FinReport(orders, generalCosts , year, month)
    //     this.setState({ ...yearlyReportsRes})
    // }



    getReport = (orders, generalCosts, year, month) => () => {






        let monthReportsRes = FinReport(orders, generalCosts, year, month)





        monthReportsRes.incomeBySupplier = Array(suppliers.length).fill(0)





        monthReportsRes.incomes.forEach(inc => {



            // console.log(inc)

            if (inc.suppliers !== undefined && inc.suppliers !== []) {



                inc.suppliers.forEach(sup => {

                    // search through suppliers (const imported from financial reports) and compare with supplier asigned to income
                    let index = suppliers.findIndex(supplier => supplier.label === sup.supplier)

                    if (inc.cnValue !== undefined) {

                        // console.log(inc.Amount ,inc.cnValue , sup.procentage)
                        monthReportsRes.incomeBySupplier[index] += roundToTwo((Number(inc.Amount) - Number(inc.cnValue)) * Number(sup.procentage))

                    } else {

                        monthReportsRes.incomeBySupplier[index] += roundToTwo(Number(inc.Amount) * Number(sup.procentage))
                    }


                });
            }

        });




        const chartData = {
            labels: suppliers.map(obj => obj.value),

            datasets: [{
                label: 'Income by supplier - (if order has multipple suppliers data will be inaccurate as income is multiplied )',
                data: monthReportsRes.incomeBySupplier,

                backgroundColor: ['rgba(198,242,242, 0.4)', 'rgba(198,242,242, 0.4)', 'rgba(198,242,242, 0.4)', 'rgba(198,242,242, 0.4)', 'rgba(198,242,242, 0.4)', 'rgba(198,242,242, 0.4)', 'rgba(198,242,242, 0.4)', 'rgba(198,242,242, 0.4)', 'rgba(198,242,242, 0.4)', 'rgba(198,242,242, 0.4)', 'rgba(198,242,242, 0.4)', 'rgba(198,242,242, 0.4)', 'rgba(198,242,242, 0.4)'],
                borderWidth: 0
            }

            ]
        }

        this.setState({
            ...monthReportsRes,
            chartData

        })
    }

    componentDidMount() {
        const ordersRef = firebase.database().ref(`Orders`);
        const costsRef = firebase.database().ref(`Costs`);





        ordersRef.on('value', snap => {
            let ord = snap.val();
            if (ord !== null) {
                let orders = Object.entries(ord)

                this.setState({
                    orders,
                    loading: false
                });
            }
        })

        costsRef.on('value', snap => {
            let genCosts = snap.val();
            if (genCosts !== null) {
                let generalCosts = Object.entries(genCosts)

                this.setState({
                    generalCosts,
                    loading: false
                });
            }
        })
    }

    // componentDidUpdate (prevState ){


    //     if(prevState.orders !== this.state.orders){
    //         console.log(this.state.orders)
    //     }

    // }

    render() {
        const { classes } = this.props;

        if (this.props.loggedIn === true) {
            return (


                // <userContext.Consumer>
                //     {(user) => ( user.access >= 10 && 



                <>

                    {this.state.loading ? <span>Loading Data</span> :

                        <>
                            <Grid container spacing={3} className={classes.root}>
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>

                                        <TextField
                                            id="year"
                                            select
                                            label="Year"
                                            className={this.props.classes.textField}
                                            value={this.state.year}
                                            onChange={this.handleChange('year')}

                                            helperText=" Select Month"
                                            margin="normal"
                                        >
                                            {years.map(option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>

                                        <Button variant="outlined" color="primary" onClick={this.getReport(this.state.orders, this.state.generalCosts, this.state.year)}>Get report</Button>
                                    </Paper>
                                </Grid>

                                <Grid container item xs={12} spacing={6} style={{ background: "white", margin: '1em' }}>

                                    <Grid item xs={12} md={6}>

                                        <h3>P&L</h3>
                                        <Table className={classes.table} aria-label="profit loss table" style={{ margin: '0 auto' }}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Total Income</TableCell>
                                                    <TableCell>Total cost</TableCell>
                                                    <TableCell align="right">Profit/Loss</TableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format(this.state.totalIncome)}</TableCell>
                                                    <TableCell>{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format(this.state.totalCosts)}</TableCell>
                                                    <TableCell align="right">{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format(Number(this.state.totalIncome) - Number(this.state.totalCosts))}</TableCell>
                                                </TableRow>
                                            </TableBody>

                                        </Table>


                                        <h3 style={{ marginTop: "50px" }}> Operating Costs</h3>
                                        <Table className={classes.table} aria-label="profit loss table" style={{ margin: '0 auto' }}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Cost Category</TableCell>

                                                    <TableCell align="right">Total</TableCell>
                                                </TableRow>
                                            </TableHead>

                                            < TableBody>
                                                {this.state.agregatedCostByCategory.map((cost, index) => {
                                                    return (
                                                        <TableRow key={index} >
                                                            <TableCell>{cost.name}</TableCell>
                                                            <TableCell align="right" >{cost.value}</TableCell>

                                                        </TableRow>
                                                    )
                                                })}
                                            </TableBody>

                                        </Table>


                                    </Grid>


                                    <Grid item xs={12} md={6}>

                                        <Bar
                                            data={this.state.chartData}
                                            width={600}
                                            height={200}
                                            options={{
                                                maintainAspectRatio: true
                                            }}
                                        />
                                        <Table className={classes.table} aria-label="profit loss table" style={{ marginLeft: '8%', width: '92%', tableLayout: 'fixed' }}>
                                            {/* <TableHead>
                                                <TableRow>
                                                    {suppliers.map((sup, index) => {
                                                        return (
                                                            <TableCell key={index}>{sup.label}</TableCell>
                                                        )
                                                    })}
                                                </TableRow>
                                            </TableHead> */}

                                            <TableBody>
                                                <TableRow>
                                                    {this.state.incomeBySupplier.map((inc, index) => {
                                                        return (
                                                            <TableCell key={index}>{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format(roundToTwo(inc))}</TableCell>
                                                        )
                                                    })}
                                                </TableRow>
                                            </TableBody>

                                        </Table>

                                    </Grid>


                                </Grid>

                                <Grid item xs={6}>
                                    <Paper className={classes.paper}>


                                        <span>Income</span>

                                        <TableContainer >
                                            <Table className={classes.table} aria-label="cost table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Invoice No</TableCell>
                                                        <TableCell>Order</TableCell>
                                                        <TableCell>Income</TableCell>
                                                        <TableCell>Supplier</TableCell>
                                                        <TableCell align="right" style={{ minWidth: "100px" }}>Date</TableCell>

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.state.incomes.map((income, index) => {
                                                        return (

                                                            <TableRow key={index}>
                                                                <TableCell >{income.invoiceNo}</TableCell>
                                                                <TableCell style={{ minWidth: "100px" }}><Link to={`order/${income.relatedToOrder}`}>{income.orderNumber}</Link></TableCell>
                                                                <TableCell >{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format(income.Amount)}</TableCell>
                                                                <TableCell >{income.suppliers.map((sup, index) => { return (<span key={index}>{sup.supplier} </span>) })}</TableCell>
                                                                <TableCell align="right">{date(income.date)} </TableCell>
                                                            </TableRow>

                                                        )
                                                    })
                                                    }

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Paper>
                                </Grid>
                                <Grid item xs={6}>
                                    <Paper className={classes.paper}>
                                        <span>Cost</span>
                                        <TableContainer >
                                            <Table className={classes.table} aria-label="cost table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Name</TableCell>
                                                        <TableCell>Cost</TableCell>
                                                        <TableCell>Related to</TableCell>

                                                        <TableCell align="right" style={{ minWidth: "100px" }}>Date</TableCell>

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.state.costs.map((cost, index) => {
                                                        return (

                                                            <TableRow key={index}>
                                                                <TableCell >{cost.costName}</TableCell>
                                                                <TableCell >{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format(cost.cost)}</TableCell>
                                                                <TableCell >{cost.relatedToLink !== undefined ? <Link to={`/order/${cost.relatedToLink}`}>{cost.relatedTo}</Link> : <> {cost.relatedTo}</>}</TableCell>
                                                                
                                                                <TableCell align="right">{date(cost.date)} </TableCell>
                                                            </TableRow>

                                                        )
                                                    })
                                                    }

                                                </TableBody>
                                            </Table>
                                        </TableContainer>



                                    </Paper>
                                </Grid>


                            </Grid>


                        </>}

                </>
                // )}</userContext.Consumer>
            );
        } else {
            return <Redirect to='/' />
        }
    }
}
export default withStyles(styles)(YearlyReports);
YearlyReports.contextType = userContext;