import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import Prices from '../SteelDoor/Prices';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import AddIcon from '@material-ui/icons/Add';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { roundToTwo } from '../logic/GetData';


const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: '90%',
        margin: '50px 5%',
    },
    paper: {
        padding: '20px',
    },
    checked: {
        color: '#d60a2e !important',
    },
    btn: {
        backgroundColor: '#d60a2e',
        color: '#ffffff',
    },
    textField: {
        width: '100%',
        margin: "16px 3px",
        background: "#fff"
    },
    delete: {
        position: 'absolute',
        top: '26px',
        right: '-34px',
    },
    additionalItem: {
        position: 'relative',
    },
    squ: {
        width: '100px',
        height: '100px',
        background: 'red',
    },
    panelDetails: {
        flexDirection: 'column'
    },
    half: {
        width: '44%',
        float: 'left',
        margin: '3%'
    },
    listBtn: {
        float: 'right',
        width: '30px',
        height: '30px',
    }
});


class Calculator extends Component {

    state = {
        isVisible: 'Visible',
    }

    handleTabs = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    };

    render() {
        const { classes } = this.props;
        return (
            <div >

                <Accordion expanded={this.state.expanded === 'general'} onChange={this.handleTabs('general')}>

                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <h4>General settings</h4>
                    </AccordionSummary>
                    <AccordionDetails className={classes.panelDetails}>

                        <TextField
                            id="doorName"
                            label='doorName'
                            type="text"
                            className={classes.textField}
                            value={this.props.doorName}
                            onChange={this.props.handleChange('doorName')}
                            margin="normal"

                        />


                        {/*----------------------------------------------------   Product type */}

                        <TextField
                            id="productLine"
                            select
                            label="Product Line"
                            className={classes.textField}
                            value={this.props.productLine}
                            onChange={this.props.categoryUpdate('productLine')}
                            SelectProps={{
                                native: true,
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            helperText=""
                            margin="normal"
                        >
                            {
                                Prices.productLine.map(option => (
                                    <option key={option.label} value={option.value}>
                                        {option.label}
                                    </option>
                                ))
                            }
                        </TextField>


                        {/*----------------------------------------------------   Door Type */}

                        <TextField
                            id="door"
                            select
                            label="Door Type"
                            className={classes.textField}

                            onChange={this.props.handleDorrType('doorType', 'sqmPrice', this.props.productLine)}
                            SelectProps={{
                                native: true,
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            helperText=""
                            margin="normal"
                        >
                            {
                                Prices[this.props.productLine].map(option => (

                                    <option key={option.label} value={option[this.props.fire].value}>
                                        {option.label}
                                    </option>
                                ))}
                        </TextField>

                      

                        {/* ----------------------------------------------------  Fire */}
                        {this.props.doorType !== 'DL 1.1' &&
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        className={classes.checked}
                                        checked={this.props.fire === 'Fire' ? true : false}
                                        onChange={this.props.handleFire()}
                                        value={this.props.a}
                                    />
                                }
                                label={<h4>Fire resistance</h4>}

                            />
                        }

                        {/* ----------------------------------------------------  Transom */}
                        <FormControlLabel
                            control={
                                <Checkbox
                                    className={classes.checked}
                                    checked={this.props.transom}
                                    onChange={this.props.handleChange('transom')}

                                />
                            }
                            label={<h4>Transom</h4>}
                        />

                        {/* ----------------------------------------------------  Transom Type */}

                        {this.props.transom &&
                            <TextField
                                id="transomType"
                                select
                                label="transomType"
                                className={classes.textField}
                                //value={this.state.vat}
                                onChange={this.props.handleFrameType('transomType')}
                                SelectProps={{
                                    native: true,
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}
                                helperText=""
                                margin="normal"
                            >
                                <option key="type-2" value="type-2">Over Door & Panels</option>
                                <option key="type-1" value="type-1">Over Door</option>



                            </TextField>
                        }


                        {/* ----------------------------------------------------  Left panel */}

                        {/* {this.props.fire === "Standard" && */}
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        className={classes.checked}
                                        checked={this.props.leftPanel}
                                        onChange={this.props.handleChange('leftPanel')}

                                    />
                                }
                                label={<h4>Left Panel</h4>}
                            />
                        {/* } */}
                        {/* ----------------------------------------------------  Right Panel */}
                        {/* {this.props.fire === "Standard" && */}
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        className={classes.checked}
                                        checked={this.props.rightPanel}
                                        onChange={this.props.handleChange('rightPanel')}

                                    />
                                }
                                label={<h4>Right Panel</h4>}
                            />
                        {/* } */}
                         
                         {/* ----------------------------------------------------  waFrame 
                          */}
                         {this.props.frameType === "type-1" &&

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        className={classes.checked}
                                        checked={this.props.waFrame}
                                        onChange={this.props.handleChange('waFrame')}

                                    />
                                }
                                label={<h4>Wrap around Frame</h4>}
                            />
                        }



                        {/* ----------------------------------------------------  Frame */}


                        <TextField
                            id="frame"
                            select
                            label="Frame Type"
                            className={classes.textField}
                            //value={this.state.vat}
                            onChange={this.props.handleFrameType('frameType')}
                            SelectProps={{
                                native: true,
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            helperText=""
                            margin="normal"
                        >
                            <option key="type-2" value="type-2">Type 2</option>
                            <option key="type-1" value="type-1">Type 1</option>



                        </TextField>


                        {/* ------------------------------------------------ Single / Double */}


                        <TextField
                            id="single-double"
                            select
                            label="Single / Double"
                            className={classes.textField}
                            //value={this.state.vat}
                            onChange={this.props.handleSingleDouble('singleDouble')}
                            SelectProps={{
                                native: true,
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            helperText=""
                            margin="normal"
                        >
                            <option key="Single" value="Single">Single</option>
                            <option key="Double" value="Double">Double</option>
                        </TextField>


                        {/* ---------------------------------------  Side */}

                        <TextField
                            id="Side"
                            select
                            label="Side"
                            className={classes.textField}
                            //value={this.state.vat}
                            onChange={this.props.handleChange('side')}
                            SelectProps={{
                                native: true,
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            helperText=""
                            margin="normal"
                        >
                            <option key="Left Hand" value="Left Hand">Left Hand</option>
                            <option key="Right Hand" value="Right Hand">Right Hand</option>
                        </TextField>

                        {/* ---------------------------------------  Swing */}

                        <TextField
                            id="SwingDirection"
                            select
                            label="Swing Direction"
                            className={classes.textField}
                            value={this.props.swingDirection}
                            onChange={this.props.handleChange('swingDirection')}
                            SelectProps={{
                                native: true,
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            helperText=""
                            margin="normal"
                        >
                            <option key="Inswing" value="Inswing">Inswing</option>
                            <option key="Outswing" value="Outswing">Outswing</option>
                        </TextField>



                        {/* ---------------------------------------  Treshold */}



                        <TextField
                            id="threshold"
                            select
                            label="threshold"
                            className={classes.textField}
                            //value={this.state.vat}
                            onChange={this.props.handleChange('threshold')}
                            SelectProps={{
                                native: true,
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            helperText=""
                            margin="normal"
                        >
                            <option key="20" value="20">With threshold</option>
                            <option key="0" value="0">Without threshold</option>

                        </TextField>

                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={this.state.expanded === 'dimensions'} onChange={this.handleTabs('dimensions')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <h4>Dimensions</h4>
                    </AccordionSummary>
                    <AccordionDetails className={classes.panelDetails}>


                        <div>Clear opening</div>
                        {/* ----------------------------------------------   Overall Width */}

                        <TextField
                            id="width"
                            label={`Width (mm) -  max ${this.props.maxWidth}(mm)`}
                            type="number"
                            className={classes.textField}
                            value={this.props.width}
                            onChange={this.props.handleDoorSize('width')}
                            margin="normal"
                            InputProps={{ inputProps: { max: String(this.props.maxWidth), step: "1" } }}
                        />


                        {/* ----------------------------------------------   Overall Height */}


                        <TextField
                            id="height"
                            label={`Height(mm) - max ${this.props.maxHeight}(mm)`}
                            type="number"
                            className={classes.textField}
                            value={this.props.height}
                            onChange={this.props.handleDoorSize('height')}
                            margin="normal"
                            InputProps={{ inputProps: { max: this.props.maxHeight } }}
                        />

                        {/* ---------------------------------------------- (if double door)  Door width */}

                        {this.props.singleDouble === "Double" &&
                            <span>
                                <TextField
                                    id="doubleDoorMainLeafwidth"
                                    label="Main Leaf Width (mm)"
                                    type="number"
                                    className={classes.textField}
                                    value={this.props.doubleDoorMainLeafwidth}
                                    onChange={this.props.handleDoorSize('doubleDoorMainLeafwidth')}
                                    margin="normal"
                                />
                            </span>
                        }

                        {/* ---------------------------------------------- (if double door)  Door width */}


                        <span>
                            <h4>Effective opening</h4>

                            <TextField
                                id="effectiveOpening"
                                label="Entered value will be subtracted from clear opening (mm)"
                                type="number"
                                className={classes.textField}
                                value={this.props.effectiveOpening}
                                onChange={this.props.handleDoorSize('effectiveOpening')}
                                margin="normal"
                            />
                        </span>


                        {/* ---------------------------------------------- Transom */}

                        {this.props.transom &&
                            <>
                                <h4>Transom</h4>
                                <TextField
                                    id="transomHeight"
                                    label="Transom Height"
                                    type="number"
                                    className={classes.textField}
                                    value={this.props.transomHeight}
                                    onChange={this.props.handlePanelSize('transomHeight')}
                                    margin="normal"
                                />
                            </>
                        }
                        {/* ---------------------------------------------- LeftPanelWidth */}

                        {this.props.leftPanel &&
                            <>
                                <h4>Left Panel Width</h4>
                                <TextField
                                    id="leftPanelWidth"
                                    label="Left Panel Width"
                                    type="number"
                                    className={classes.textField}
                                    value={this.props.leftPanelWidth}
                                    onChange={this.props.handlePanelSize('leftPanelWidth')}
                                    margin="normal"
                                />
                            </>
                        }

                        {/* ---------------------------------------------- RightPanelWidth */}

                        {this.props.rightPanel &&
                            <>
                                <h4>Right Panel Width</h4>
                                <TextField
                                    id="rightPanelWidth"
                                    label="Right Panel Width"
                                    type="number"
                                    className={classes.textField}
                                    value={this.props.rightPanelWidth}
                                    onChange={this.props.handlePanelSize('rightPanelWidth')}
                                    margin="normal"
                                />
                            </>
                        }
                    </AccordionDetails>
                </Accordion>





                <Accordion expanded={this.state.expanded === 'ironmongery'} onChange={this.handleTabs('ironmongery')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <h4>Ironmongery</h4>
                    </AccordionSummary>
                    <AccordionDetails className={classes.panelDetails}>

                        {/* -----------------------------------------------   Ironmongery */}

                        {/* <p>Ironmongery</p> */}

                        {this.props.height !== '' &&
                            <Button variant="outlined" size="small" color="primary" className={classes.margin} onClick={this.props.handleAdditional}>Add New</Button>
                        }


                        {this.props.additional.map((option, key) => {

                            return (


                                <div key={key} className={classes.additionalItem}>


                                    <TextField
                                        id="additional"
                                        select
                                        label="Option"
                                        className={classes.textField}
                                        value={option.label}
                                        onChange={this.props.handleAdditionalValue(option.key)}
                                        // defaultValue={option.label}
                                        SelectProps={{
                                            native: true,
                                            MenuProps: {
                                                className: classes.menu,
                                            },
                                        }}
                                        helperText=""
                                        margin="normal"
                                    >

                                        {
                                            Prices.additional.filter(opt => (opt.allowed.indexOf(this.props.singleDouble) !== -1 && opt.allowed.indexOf(this.props.doorType) !== -1 && opt.allowed.indexOf(this.props.fire) !== -1))
                                                .map(opt => {
                                                    // if(option.label === opt.label){
                                                    //     return <option key={opt.key+opt.label} value={opt.value} selected>{opt.label} </option>
                                                    // }else{
                                                    return <option key={opt.label} value={opt.label} >{opt.label} </option>
                                                    //}
                                                })
                                        }

                                    </TextField>

                                    <IconButton className={classes.delete} aria-label="Delete" onClick={this.props.handleRemoveItemFromList('additional', option.key)}>
                                        <DeleteIcon />
                                    </IconButton>


                                    {typeof (option.leafChoice) !== 'undefined' &&
                                        <div>
                                            {option.leafChoice === true && <span>Active</span>}
                                            {option.leafChoice === false && <span>Inactive</span>}
                                            <Switch color="primary" onChange={this.props.handleActiveInactive(option.key,)} />
                                        </div>
                                    }
                                    {typeof option.inside !== 'undefined' &&

                                        <div>
                                            {option.inside === true && <span>Inside</span>}
                                            {option.inside === false && <span>Outside</span>}
                                            <Switch color="primary" onChange={this.props.handleInsideOutside(option.key,)} />
                                        </div>
                                    }

                                    {option.position === true &&


                                        <div>
                                            <TextField

                                                label={'bottom'}
                                                type="number"
                                                className={classes.half}
                                                value={option.bottom}
                                                onChange={this.props.handleAdditionalPosition(option.key, 'bottom')}
                                                margin="normal"
                                                InputProps={{ inputProps: { min: 1, max: this.props.height } }}
                                            />




                                            {typeof option.sideDist !== 'undefined' &&
                                                <TextField

                                                    label={'side'}
                                                    type="number"
                                                    className={classes.half}
                                                    value={option.sideDist}
                                                    onChange={this.props.handleAdditionalPosition(option.key, 'sideDist')}
                                                    margin="normal"
                                                    InputProps={{ inputProps: { min: 1, max: this.props.height } }}
                                                />
                                            }
                                        </div>


                                    }

                                    {'per' in option &&
                                        <div>
                                            <TextField
                                                label="Width (mm)"
                                                type="number"
                                                className={classes.textField}
                                                value={option.width}
                                                onChange={this.props.handleAdditionalSqmSize(option.key, 'width')}
                                                margin="normal"
                                            />

                                            <TextField
                                                label="Height (mm)"
                                                type="number"
                                                className={classes.textField}
                                                value={option.height}
                                                onChange={this.props.handleAdditionalSqmSize(option.key, 'height')}
                                                margin="normal"
                                            />
                                        </div>
                                    }

                                </div>
                            )
                        })
                        }
                    </AccordionDetails>
                </Accordion>

                {
                    /*
                     ----------------------------------------------------------------------------------
                    ------------------------------------------------------------------   Vision Panel 
                     ----------------------------------------------------------------------------------
                    */
                }

                <Accordion expanded={this.state.expanded === 'visionpanel'} onChange={this.handleTabs('visionpanel')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <h4>Vision Panel</h4>
                    </AccordionSummary>
                    <AccordionDetails className={classes.panelDetails}>


                        {/* ------  Checkbox */}


                        {this.props.availableGlass.length > 0 &&

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        className={classes.checked}
                                        checked={this.props.visionPanel}
                                        onChange={this.props.handleChange('visionPanel')}
                                    //value={this.props.visionPanel}
                                    />
                                }
                                label={<h3>Vision Panel</h3>}
                            />
                        }


                        {/* ------  Glass type */}

                        {this.props.visionPanel === true &&
                            <span>
                                <TextField
                                    id="glass"
                                    select
                                    label="Glass"
                                    className={classes.textField}
                                    //value={this.state.vat}
                                    onChange={this.props.handleGlassType('glass')}
                                    SelectProps={{
                                        native: true,
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    helperText=""
                                    margin="normal"
                                >
                                    {this.props.availableGlass.map(option => {
                                        return <option key={option.label} value={option.value}>{option.label}</option>
                                    })}
                                </TextField>


                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            className={classes.checked}
                                            checked={this.props.thermalIsolation}
                                            onChange={this.props.handleGlassType('thermalIsolation')}
                                        //value={this.props.thermalIsolation}
                                        />
                                    }
                                    label={<h3>Thermal isolation</h3>}
                                />



                                <p> Add chosen vision panel to</p>


                                <ButtonGroup color="primary" aria-label="outlined primary button group">
                                    <Button variant="outlined" size="small" color="primary" className={classes.margin} onClick={this.props.addVisonPanelToPanelOrDoor("door")}>
                                        Door
                                    </Button>


                                    <Button variant="outlined" size="small" color="primary" className={classes.margin} onClick={this.props.addVisonPanelToPanelOrDoor("tr")}>
                                        Transom
                                    </Button>

                                    <Button variant="outlined" size="small" color="primary" className={classes.margin} onClick={this.props.addVisonPanelToPanelOrDoor("lp")}>
                                        LP
                                    </Button>
                                    <Button variant="outlined" size="small" color="primary" className={classes.margin} onClick={this.props.addVisonPanelToPanelOrDoor("rp")}>
                                        RP
                                    </Button>

                                </ButtonGroup>

                            </span>
                        }

                        {this.props.availableGlass.length > 0 &&

                            <span style={{ marginTop: '20px' }}>


                                {this.props.visionPanels.map(panel => {
                                    return <div key={panel.key}>


                                        {panel.fixing === 'door' || panel.fixing === undefined ?
                                            <h4>Door</h4> :
                                            panel.fixing === 'tr' ?
                                                <h4>Transom</h4> :
                                                panel.fixing === 'lp' ?
                                                    <h4> Left panel</h4> :
                                                    panel.fixing === 'rp' ?
                                                        <h4>Right Panel</h4> :
                                                        <></>
                                        }


                                        {panel.fixing === 'door' && <>

                                            {
                                                (panel.mainDoor) ?
                                                    <TextField
                                                        id="width"
                                                        label={'Width (mm) - max: ' + this.props.maxVPWidth + 'mm'}
                                                        type="number"
                                                        className={classes.half}
                                                        value={panel.glassWidth}
                                                        onChange={this.props.visionPanelsLocationAndSize('glassWidth', panel.key, 'visionPanels')}
                                                        margin="normal"
                                                        InputProps={{ inputProps: { min: 1, max: this.props.maxVPWidth } }}
                                                    />

                                                    :
                                                    <TextField
                                                        id="width"
                                                        label={'Width (mm) - max: ' + this.props.maxVPWidth2 + 'mm'}
                                                        type="number"
                                                        className={classes.half}
                                                        value={panel.glassWidth}
                                                        onChange={this.props.visionPanelsLocationAndSize('glassWidth', panel.key, 'visionPanels')}
                                                        margin="normal"
                                                        InputProps={{ inputProps: { min: 1, max: this.props.maxVPWidth2 } }}
                                                    />


                                            }

                                            <TextField
                                                id="height"
                                                label={'Height (mm) - max: ' + this.props.maxVPHeight + 'mm'}
                                                type="number"
                                                className={classes.half}
                                                value={panel.glassHeight}
                                                onChange={this.props.visionPanelsLocationAndSize('glassHeight', panel.key, 'visionPanels')}
                                                margin="normal"
                                                InputProps={{ inputProps: { min: 1, max: this.props.maxVPHeight } }}
                                            />

                                            <TextField

                                                label={'Left'}
                                                type="number"
                                                className={classes.half}
                                                value={panel.left}
                                                onChange={this.props.visionPanelsLocationAndSize('left', panel.key, 'visionPanels')}
                                                margin="normal"
                                                InputProps={{ inputProps: { min: 1, max: this.props.maxVPHeight } }}
                                            />
                                            <TextField

                                                label={'bottom'}
                                                type="number"
                                                className={classes.half}
                                                value={panel.bottom}
                                                onChange={this.props.visionPanelsLocationAndSize('bottom', panel.key, 'visionPanels')}
                                                margin="normal"
                                                InputProps={{ inputProps: { min: 1, max: this.props.maxVPHeight } }}
                                            />


                                            <Switch
                                                color="primary"
                                                onChange={this.props.visionPanelsLocationAndSize('mainDoor', panel.key, 'visionPanels')} />





                                        </>}

                                        <IconButton aria-label="Delete" onClick={this.props.handleRemoveItemFromList('visionPanels', panel.key)}>
                                            <DeleteIcon />
                                        </IconButton>
                                        <hr style={{ borderTop: "1px solid #efefef" }} />
                                    </div>
                                })

                                }
                            </span>

                        }

                    </AccordionDetails>
                </Accordion>

                {/*                        
                    ------------------------------------------
                    ------ Ventilation   Checkbox ------------
                    ------------------------------------------
*/}


                <Accordion expanded={this.state.expanded === 'ventilation'} onChange={this.handleTabs('ventilation')}>

                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <h4>Ventilation</h4>
                    </AccordionSummary>
                    <AccordionDetails className={classes.panelDetails}>

                        {/* ------  Checkbox ------ */}


                        {this.props.availableVentilation.length > 0 &&

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        className={classes.checked}
                                        checked={this.props.ventilation}
                                        onChange={this.props.handleChange('ventilation')}
                                    />
                                }
                                label={<h5>Ventilation</h5>}
                            />

                        }
                        {/* ------  Ventilation type */}

                        {this.props.ventilation === true &&
                            <span>


                                <TextField
                                    id="availableVentilation"
                                    select
                                    label="Available Ventilation"
                                    className={classes.textField}
                                    //value={this.state.vat}
                                    onChange={this.props.handleVentilationType()}
                                    SelectProps={{
                                        native: true,
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    helperText=""
                                    margin="normal"

                                >
                                    {this.props.availableVentilation.map(option => {
                                        return <option key={option.label} value={option.value} >{option.label}</option>
                                    })}
                                </TextField>



                                <ButtonGroup color="primary" aria-label="outlined primary button group">
                                    <Button variant="outlined" size="small" color="primary" className={classes.margin} onClick={this.props.handleAddVentilation("door")}>
                                        Door
                                    </Button>



                                    {this.props.ventilationType === "Air vent grill" && <Button variant="outlined" size="small" color="primary" className={classes.margin} onClick={this.props.handleAddVentilation('tr')}>
                                        Transom
                                    </Button>}
                                    {this.props.ventilationType === "Air vent grill" && <Button variant="outlined" size="small" color="primary" className={classes.margin} onClick={this.props.handleAddVentilation('lp')}>
                                        LP
                                    </Button>}
                                    {this.props.ventilationType === "Air vent grill" && <Button variant="outlined" size="small" color="primary" className={classes.margin} onClick={this.props.handleAddVentilation('rp')}>
                                        RP
                                    </Button>}

                                </ButtonGroup>


                            </span>
                        }

                        {this.props.ventilationList.length > 0 &&
                            <span>

                                {/* Door ventilation --------------  */}

                                {this.props.ventilationList.map(panel => {
                                    return <div key={panel.key}>


                                        {panel.fixing === 'door' || panel.fixing === undefined ?
                                            <h4>Door</h4> :
                                            panel.fixing === 'tr' ?
                                                <h4>Transom</h4> :
                                                panel.fixing === 'lp' ?
                                                    <h4> Left panel</h4> :
                                                    panel.fixing === 'rp' ?
                                                        <h4>Right Panel</h4> :
                                                        <></>
                                        }

                                        <small>{panel.label}</small><br />

                                        {(panel.fixing === "door") &&
                                            <TextField
                                                id="height"
                                                label={'Height (mm) - max: ' + this.props.maxVPHeight + 'mm'}
                                                type="number"
                                                className={classes.textField}
                                                value={panel.height}
                                                onChange={this.props.handleVents('ventHeight', panel.key)}
                                                margin="normal"
                                                InputProps={{ inputProps: { min: 1, max: this.props.maxVPHeight } }}
                                            />
                                        }

                                        {(panel.label === "Round vent sleeve") &&

                                            <TextField
                                                label={'Left'}
                                                type="number"
                                                className={classes.textField}
                                                value={panel.left}
                                                onChange={this.props.handleVents('left', panel.key, 'visionPanels')}
                                                margin="normal"
                                                InputProps={{ inputProps: { min: 1, max: this.props.maxVPHeight } }}
                                            />
                                        }
                                        {(panel.fixing === "door") &&
                                            <TextField
                                                label={'bottom'}
                                                type="number"
                                                className={classes.textField}
                                                value={panel.bottom}
                                                onChange={this.props.handleVents('bottom', panel.key, 'visionPanels')}
                                                margin="normal"
                                                InputProps={{ inputProps: { min: 1, max: this.props.maxVPHeight } }}
                                            />
                                        }
                                        <br />

                                        <IconButton aria-label="Delete" onClick={this.props.handleRemoveItemFromList('ventilationList', panel.key)}>
                                            <DeleteIcon />
                                        </IconButton>
                                        {(panel.fixing === "door") &&
                                            <Switch color="primary" onChange={this.props.handleVents('mainDoor', panel.key)} />
                                        }
                                        <hr />
                                    </div>
                                })
                                }
                            </span>

                        }

                    </AccordionDetails>
                </Accordion>


                <Accordion expanded={this.state.expanded === 'finishing'} onChange={this.handleTabs('finishing')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <h4>Door finishing</h4>
                    </AccordionSummary>
                    <AccordionDetails className={classes.panelDetails}>

                        {/* ----------------------------------------------   Color Inside */}


                        <TextField
                            id="colorInside"
                            label="Color Inside "
                            type="text"
                            className={classes.textField}
                            value={this.props.colorInside}
                            onChange={this.props.handleChange('colorInside')}
                            margin="normal"
                        />

                        {/* ----------------------------------------------   Color Outside */}

                        <TextField
                            id="colorOutside"
                            label="Color Outside "
                            type="text"
                            className={classes.textField}
                            value={this.props.colorOutside}
                            onChange={this.props.handleChange('colorOutside')}
                            margin="normal"
                        />
                    </AccordionDetails>
                </Accordion>

                {/*
                                    ------------------------------------------------------------------ 
                                    ----------------------------------------------   Finance ---
                                    ------------------------------------------------------------------
                                */}

                <Accordion expanded={this.state.expanded === 'finance'} onChange={this.handleTabs('finance')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <h4>Finance</h4>
                    </AccordionSummary>
                    <AccordionDetails className={classes.panelDetails}>



                        <Button variant="outlined" size="small" color="primary" className={classes.margin} onClick={this.props.handleAddExtraOver}>
                            Add Extra Over
                        </Button>


                        {this.props.extraOver.length > 0 &&

                            <div style={{ clear: 'both', borderBottom: '1px solid #e5e5e5' }}>

                                {this.props.extraOver.map(item => {
                                    return <div key={item.key}  >
                                        <div style={{ width: '70%', float: 'left' }}>
                                            <TextField
                                                label='name'
                                                type="text"
                                                className={classes.textField}
                                                value={item.label}
                                                onChange={this.props.handleExtraOver(item.key, 'label')}
                                                margin="normal"
                                            />
                                        </div>
                                        <div style={{ width: '20%', float: 'left' }}>
                                            <TextField
                                                label='Value'
                                                type="number"
                                                className={classes.textField}
                                                value={item.value}
                                                onChange={this.props.handleExtraOver(item.key, 'value')}
                                                margin="normal"
                                            />
                                        </div>
                                        <div style={{ width: '10%', float: 'left' }}>

                                            <IconButton aria-label="Delete" onClick={this.props.handleRemoveItemFromList('extraOver', item.key)}>

                                                <DeleteIcon />
                                            </IconButton>
                                        </div>

                                    </div>
                                })
                                }
                            </div>
                        }
                        <TextField
                            id="qty"
                            label='Quantity'
                            type="number"
                            className={classes.textField}
                            value={this.props.qty}
                            onChange={this.props.handleChange('qty')}
                            margin="normal"
                            InputProps={{ inputProps: { max: "1000", step: "1" } }}

                        />

                        <TextField
                            id="vat"
                            select
                            label="Vat"
                            className={classes.textField}
                            value={this.props.vat}
                            onChange={this.props.handleChange('vat')}
                            SelectProps={{
                                native: true,
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            helperText=""
                            margin="normal"
                        >
                            <option key="23" value="23">23%</option>
                            <option key="21" value="21">21%</option>
                            <option key="13" value="135">13.5%</option>
                            <option key="0" value="0">0%</option>

                        </TextField>

                        <TextField
                            id="discount"
                            label="Discount (%)"
                            type="number"
                            className={classes.textField}
                            value={this.props.discount}
                            onChange={this.props.handleChange('discount')}
                            margin="normal"
                            helperText="Discount"
                            variant="filled"
                        />

                    </AccordionDetails>
                </Accordion>




                {/* ----------------------------------------------   Color Outside */}

                <Accordion expanded={this.state.expanded === 'AdditionalNotes'} onChange={this.handleTabs('AdditionalNotes')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <h4>Additional notes</h4>
                    </AccordionSummary>
                    <AccordionDetails className={classes.panelDetails}>




                        <ReactQuill
                            value={this.props.notes}
                            onChange={this.props.handleQuillTextNotes('notes') }
                        />
                    </AccordionDetails>
                </Accordion>

                <Button onClick={this.props.addMoreDoors} style={{ marginTop: "60px", marginBottom: "60px" }} variant="outlined" color="primary">Add another door</Button>



                <Accordion expanded={this.state.expanded === 'allDoors'} onChange={this.handleTabs('allDoors')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <h4>Manage Doors</h4>
                    </AccordionSummary>
                    <AccordionDetails className={classes.panelDetails}>

                        <div style={{ 'position': 'relative' }}>

                            {this.props.allDoors.map((door, index) => {
                                return (
                                    <div key={index} className={classes.additionalItem} style={{ borderBottom: '1px solid gray', position: 'relative', padding: '20px 0', textAlign: 'left' }}>

                                        <span >{door.doorName}</span>

                                        <IconButton edge="end" aria-label="Delete" size="small" onClick={this.props.handleRemoveItemFromList('allDoors', door.key)} className={classes.listBtn} >
                                            <DeleteIcon />
                                        </IconButton>

                                        <IconButton aria-label="delete" size="small" className={classes.listBtn} onClick={this.props.handlePushItemDown('allDoors', door.key)}>
                                            <ArrowDownwardIcon />
                                        </IconButton>

                                        <IconButton className={classes.listBtn} size="small" aria-label="Duplicate" onClick={this.props.editDoor(door.key)}>
                                            <AddIcon />
                                        </IconButton>

                                        <TextField style={{ marginTop: '-11px', padding: "0px" }}
                                            id="notes"
                                            label="Ratio"
                                            type="number"
                                            className={classes.listBtn}
                                            value={door.printRatio}
                                            onChange={this.props.changeDoorRatio('allDoors', door.key)}
                                        />
                                    </div>
                                )
                            })
                            }
                            <div className={classes.additionalItem} style={{ borderBottom: '1px solid gray', position: 'relative', padding: '20px 0', textAlign: 'left' }}>

                                <span >{this.props.doorName} - you are editinng this door at the moment</span>
                            </div>

                        </div>
                    </AccordionDetails>
                </Accordion>



                {/*
                        ------------------------------------------------------------------ 
                        ----------------------------------------------   Additional Price Items ---
                        ------------------------------------------------------------------
                    */}

                <Accordion expanded={this.state.expanded === 'additionalPriceItems'} onChange={this.handleTabs('additionalPriceItems')}>

                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <h4>Additional Price Items</h4>
                    </AccordionSummary>

                    <AccordionDetails className={classes.panelDetails}>
                        <Button variant="outlined" color="primary" aria-label="add" className={classes.btn} onClick={this.props.handelAddPriceItem}>
                            <AddIcon /> Add Price Item
                        </Button>

                        {
                            this.props.additionalPriceItems.map((option, index) => (
                                option.break === true ?

                                    <div key={index} style={{ color: '#cd2031', margin: '50px 0' }} >


                                        <p className="subtitle fancy"><span>Page Break</span></p>


                                        <IconButton aria-label="delete" size="small" className={classes.listBtn} onClick={this.props.handlePushItemDown('additionalPriceItems', option.key)}>
                                            <ArrowDownwardIcon />
                                        </IconButton>
                                        <IconButton className={classes.delete} aria-label="Delete" onClick={this.props.handleRemovePriceItem(option.key)}>
                                            <DeleteIcon />
                                        </IconButton>


                                    </div>

                                    :

                                    <div key={index}>
                                        <h3>Extra Price Item {index + 1}</h3>
                                        Is this a global discount ?

                                        {option.globalDiscount ? <span> Yes </span> : <span> No </span>}
                                        <Switch
                                            color="primary"
                                            onChange={this.props.handleglobalDiscount('additionalPriceItems', option.key)}
                                        />

                                        {!option.globalDiscount ? <span>
                                            <ReactQuill
                                                value={option.text}
                                                onChange={this.props.handleQuillText({ key: option.key, list: 'additionalPriceItems', index })}
                                            />

                                            <TextField
                                                style={{ width: '80px' }}
                                                className={classes.textField}
                                                type="number"
                                                id="price"
                                                name="prace"
                                                label="Price"
                                                value={option.price}
                                                onChange={this.props.handelAdditionalPriceItem(option.key, 'price', 'additionalPriceItems')}
                                            />


                                            <TextField
                                                style={{ width: '50px' }}
                                                className={classes.textField}
                                                type="number"
                                                id="qty"
                                                name="Qty"
                                                label="Qty"
                                                value={option.qty}
                                                onChange={this.props.handelAdditionalPriceItem(option.key, 'qty', 'additionalPriceItems')}
                                            />

                                            <TextField
                                                style={{ width: '50px' }}
                                                type="number"
                                                id="discount2"
                                                name="discount"
                                                label="Discount (%)"
                                                className={classes.textField}
                                                value={option.discount}
                                                onChange={this.props.handelAdditionalPriceItem(option.key, 'discount', 'additionalPriceItems')}
                                            />



                                            <TextField
                                                style={{ width: '50px' }}
                                                id="vat"
                                                select
                                                label="VAT"
                                                className={classes.textField}
                                                value={option.vat}
                                                onChange={this.props.handelAdditionalPriceItem(option.key, 'vat', 'additionalPriceItems')}
                                                SelectProps={{
                                                    native: true,
                                                    MenuProps: {
                                                        className: classes.menu,
                                                    },
                                                }}
                                                helperText=""
                                                margin="normal"
                                            >
                                                <option key='21' value="21">21%</option>
                                                <option key='23' value="23">23%</option>
                                                <option key='13' value="135">13.5%</option>
                                                <option key='0' value="0">0%</option>
                                            </TextField>
                                        </span>
                                            :
                                            <span style={{ clear: 'both', display: 'box' }}>


                                                <TextField
                                                    style={{ width: '46%' }}
                                                    type="number"
                                                    id="discount"
                                                    name="discount"
                                                    label="Discount (%)"
                                                    className={classes.textField}
                                                    value={option.discount}
                                                    onChange={this.props.handelAdditionalPriceItem(option.key, 'discount', 'additionalPriceItems')}
                                                />

                                                <TextField
                                                    style={{ width: '46%' }}
                                                    type="number"
                                                    id="days"
                                                    name="days"
                                                    label="Days to order and pay"
                                                    className={classes.textField}
                                                    value={option.days}
                                                    onChange={this.props.handelAdditionalPriceItem(option.key, 'days', 'additionalPriceItems')}
                                                />

                                            </span>
                                        }




                                        <IconButton aria-label="arrow" size="small" className={classes.listBtn} onClick={this.props.handlePushItemDown('additionalPriceItems', option.key)}>
                                            <ArrowDownwardIcon />
                                        </IconButton>



                                        <IconButton className={classes.listBtn} size="small" aria-label="Delete" onClick={this.props.handleRemovePriceItem(option.key)}>
                                            <DeleteIcon />
                                        </IconButton>






                                        <hr />
                                    </div>
                            ))
                        }
                    </AccordionDetails>
                </Accordion>







                {(this.props.userEmail !== "ro@tdsltd.ie") &&


                    <Accordion expanded={this.state.expanded === 'estimatedCost'} onChange={this.handleTabs('estimatedCost')}>

                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <h4>Estimated Cost</h4>
                        </AccordionSummary>

                        <AccordionDetails className={classes.panelDetails}>



                            <div className={classes.additionalItem} style={{ borderBottom: '1px solid gray', position: 'relative', padding: '20px 0', textAlign: 'left' }}>
                                <span >{this.props.doorName} - </span>
                                <span >

                                    {"Door: " + roundToTwo(Number(this.props.totalCost) - Number(this.props.leftPanelCost) - Number(this.props.rightPanelCost) - Number(this.props.transomCost))}
                                    {this.props.leftPanel && <> + LP: {this.props.leftPanelCost}</>}
                                    {this.props.rightPanel && <> + RP: {this.props.rightPanelCost}</>}
                                    {this.props.transom && <> + TR: {this.props.transomCost}</>}

                                </span>
                            </div>

                            {this.props.allDoors.map((door, index) => {
                                return (
                                    <div key={index} className={classes.additionalItem} style={{ borderBottom: '1px solid gray', position: 'relative', padding: '20px 0', textAlign: 'left' }}>

                                        <span >{door.doorName} - </span>
                                        <span >

                                            {"Door: " + roundToTwo(Number(door.totalCost) - Number(door.leftPanelCost) - Number(door.rightPanelCost) - Number(door.transomCost))}
                                            {door.leftPanel && <> + LP: {door.leftPanelCost}</>}
                                            {door.rightPanel && <> + RP: {door.rightPanelCost}</>}
                                            {door.transom && <> + TR: {door.transomCost}</>}

                                        </span>


                                    </div>
                                )
                            })
                            }

                        </AccordionDetails>
                    </Accordion>
                }
            </div>





        )
    }
}

export default withStyles(styles)(Calculator);