import React, { Component } from 'react';

class Cylinder extends Component{

    state= {}

    render() {
        
        return (     
            <div style={{ height: `${60/this.props.printRatio}px`,  width: `${60/this.props.printRatio}px`, border: '1px solid black', position: 'absolute',margin: '0 auto', 'borderRadius': '50%',left: '50%',bottom: '18%', transform: 'translate(-50%)'}}>
                <div style={{ height: `${35/this.props.printRatio}px`,  width: `${10/this.props.printRatio}px`, border: '1px solid black',margin: '50% auto', 'borderRadius': `${10/this.props.printRatio}px`,transform: 'translate(0,-50%)'}}></div>
            </div>
       )
    }
}

export default Cylinder;