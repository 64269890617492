import React, { Component } from 'react';
// import * as firebase from 'firebase';
import firebase from "firebase/app";

import { getAuth, onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth"

import { Redirect } from 'react-router'

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';

const styles = {
    button: {
        color: 'white',
        backgroundColor: '#d60a2e',
        margin: '10px 5px',
        '&:hover': {
            backgroundColor: '#d60a2e',
        }, 
    },
    paper1:{
        padding: '20px',
    },
    loginPage: {
    flex: '1',
    width: '360px',
    margin: '50px auto',
    },
    loginform: {
        marginTop: 30,
        display: 'flex',
    }
}

class Login extends Component{
    state={
        redirect: false,
        email: '',
        password: ''
    }

    handleChange = name => event =>{
        this.setState({
            [name]: event.target.value
        })
    }

    login2=()=> event=>{if(event.key === 'Enter'){this.signIn()}}

    signIn = () =>{

        //-------------------------------->
        // v8
        //-------------------------------->
        // firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password).catch(function(error) {

        //     var errorCode = error.code;
        //     var errorMessage = error.message;

        //     if (errorCode === 'auth/wrong-password') {
        //         alert('Wrong password.');
        //     } else {
        //         alert(errorMessage);
        //     }
        //     console.log(error);
            
        //     });


        // firebase.auth().onAuthStateChanged((user) => {

        //     console.log('logedin');
        //     if (user) {this.setState({ redirect: true })}
        // });


        //-------------------------------->
        // v9
        //-------------------------------->

        const auth = getAuth();

        if(this.state.email === ""){
            alert('Enter Email')
            return
         }
        if(this.state.password === ""){
            alert('Enter Password')
            return
        }

        

        if(this.state.email !== "" && this.state.password !== ""){

            signInWithEmailAndPassword(auth, this.state.email, this.state.password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;

                if (errorCode === 'auth/wrong-password') {
                    alert('Wrong password.');
                } else {
                    alert(errorMessage);
                }
            });

            onAuthStateChanged(auth, user => { if (user) {this.setState({ redirect: true })} });
        }

    }

    componentDidMount(){
        //-------------------------------->
        // v9
        //-------------------------------->
        const auth = getAuth();
        onAuthStateChanged(auth, user => { if (user) {this.setState({ redirect: true })} });

        //-------------------------------->
        // v8
        //-------------------------------->
 
        // firebase.auth().onAuthStateChanged((user) => {
        //   if (user) {
        //   this.setState({ redirect: true });
        //   }
        // });
    }

    render() {

        if (this.state.redirect) {
            return <Redirect to='/home'/>;
        }else{
            return (
                <div className={this.props.classes.loginPage}>
                    <Paper elevation={2} className={this.props.classes.paper1}> 
                        <FormControl fullWidth >
                            <InputLabel htmlFor="email">Login</InputLabel>
                            <Input
                                id="email"
                                value={this.state.email}
                                onChange={this.handleChange('email')}
                            />
                        </FormControl>
                        <FormControl fullWidth >
                            <InputLabel htmlFor="password">Password</InputLabel>
                            <Input
                                id="password"
                                type="password"
                                value={this.state.password}
                                onChange={this.handleChange('password')} 
                                onKeyPress={ this.login2()} 
                            />
                        </FormControl>
                        <Button  className={this.props.classes.button} onClick={this.signIn}>Sign In</Button> 
                    </Paper>
                </div>
            );
        }
    }
  }
export default withStyles(styles)(Login);