import React, { Component } from 'react';

class Knob extends Component{

    state= {}

    rotate = () =>{
        if(this.props.side === "right"){
            return (0);
        }else{
            return (180);
        }
    } 

    render() {
        
        return (
            <div style={{height: `${460/this.props.printRatio}px`, width: `${30/this.props.printRatio}px`, border: '1px solid black', position: 'absolute', margin: '0 auto', [this.props.side] : `${this.props.sideDist/this.props.printRatio}px`, bottom: `${this.props.bottom/this.props.printRatio}px`}}>
                 
                  <div style={{width: `${20/this.props.printRatio}px`, height: `${20/this.props.printRatio}px`, top: `${75/this.props.printRatio}px`, border: '1px solid #d2d2d2',position: 'absolute', borderRadius: `${25/this.props.printRatio}px`}}  ></div>
                  <div style={{width: `${20/this.props.printRatio}px`, height: `${20/this.props.printRatio}px`, bottom: `${75/this.props.printRatio}px`, border: '1px solid #d2d2d2', position: 'absolute', borderRadius: `${25/this.props.printRatio}px`}}  ></div>
            </div>  
       )
    }
}

export default Knob;