import React, { Component } from 'react';
// import * as firebase from 'firebase'
import firebase from "firebase/compat/app";
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    root: {
        width: '100%',
        marginTop: theme.spacing(1) * 3,
        overflowX: 'auto',
      },
      table: {
        minWidth: 250,
    },
    highlight:{
        '&:hover':{
            background: '#f5f5f5'
        }
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
      },
  });


class Deals extends Component{
    
    state = {
        open: false,  
        reason: ''
    }

    confirm = id => event =>{

        this.setState({
            open: true,
            toBeRessurected: id,
           
        })
    }

    handlechange = (name) => event =>{

        this.setState({
            [name]: event.target.value
        })

    }

    ressurect = () => event  =>{

        const lostDealsRef = firebase.database().ref('DealsLost');
        const leadsRef = firebase.database().ref('Leads');

        let deal = {};

        lostDealsRef.on('value',snap =>{
                let deals = snap.val();
                deal = deals[this.state.toBeRessurected];
        })

        deal.stage = 1;
        deal.status = 'open';
        
        // Save quote in deleted 
        leadsRef.push(deal);

        //Delete
        lostDealsRef.child(this.state.toBeRessurected).remove();

        this.setState({
            open:false,
            toBeRessurected: ''
        })
    }


    handleClose = () => {
        this.setState({ open: false });
    };


    componentDidMount(){}

    render() {
        return (
            <div>
                <Table className={this.props.classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell >Quote NO</TableCell>
                      
                            
                            <TableCell >Customer Name</TableCell>
                            <TableCell >Value</TableCell>
                            {this.props.database === "DealsLost" &&  <TableCell style={{maxWidth: "25vw"}}>Deal Loss Reasons</TableCell>}
                            
                            <TableCell >Prospect email</TableCell>
                            <TableCell >Deal started</TableCell>
                            <TableCell >Closed</TableCell>
                            {this.props.database === "DealsLost" &&  <TableCell >Ressurect</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { this.props.quotes.map(quote=>(

                            <TableRow key={quote[0]} className={this.props.classes.highlight}>
                                <TableCell >
                                    <Link to={`/deal/${ quote[0] }/${ this.props.database }`}>
                                        {'quoteNo' in quote[1]  ? quote[1].quoteNo : quote[0]}
                                    </Link>
                                </TableCell> 
                                
                                <TableCell >{quote[1].prospectName}</TableCell>
                                <TableCell >{quote[1].dealValue}</TableCell> 
                                {this.props.database === "DealsLost" && <TableCell style={{maxWidth: "25vw"}}>{quote[1].lostReason}</TableCell> }
                                <TableCell >{quote[1].prospectEmail}</TableCell>
                                <TableCell >{moment(quote[1].date).format('DD-MM-YYYY')}</TableCell>
                                <TableCell >{moment(quote[1].dateClosed).format('DD-MM-YYYY')}</TableCell>
                               
                                { this.props.database === "DealsLost" &&  <TableCell >
                                    <IconButton  aria-label="Delete" 
                                        onClick={this.confirm(quote[0])}
                                        >
                                        <RestoreFromTrashIcon />
                                    </IconButton>
                                    </TableCell> 
                                }
                            </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>




                {/* Confirmation */}

                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    >
                    <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
                
                    <DialogActions>
                        <Button onClick={this.handleClose} variant="outlined" color="primary">
                        Cancel
                        </Button>
                        <Button onClick={this.ressurect()} variant="outlined" color="secondary" autoFocus>
                        Restore Deal <RestoreFromTrashIcon className={this.props.classes.rightIcon} />
                        </Button>
                    </DialogActions>
                    </Dialog>

                  

                    
            </div>
        );
        
    }
  }
export default withStyles(styles)(Deals);
