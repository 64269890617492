import React, { Component } from 'react';

// import * as firebase from 'firebase'
// import firebase from "firebase/compat/app";
// import { Link } from 'react-router-dom';
// import Button from '@material-ui/core/Button';
// import Input from '@material-ui/core/Input';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import moment from 'moment';
// import DeleteIcon from '@material-ui/icons/Delete';
// import IconButton from '@material-ui/core/IconButton';
// import SendToProduction from '@material-ui/icons/SaveAlt';
// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import {userContext} from '../Context/userContext';

// import SelectCustomer from '../Customers/selectCustomer'

const styles = theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    root: {
        width: '100%',
        marginTop: theme.spacing(1) * 3,
        overflowX: 'auto',
      },
      table: {
        minWidth: 250,
    },
    highlight:{
        '&:hover':{
            background: '#f5f5f5'
        }
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
      },
  });


class Customers extends Component{
    
    state = {
        open: false,  
        Customer: ''
    }

    componentDidMount(){}

    render() {
        return (

            <Table className={this.props.classes.table}>
                <TableHead>
                    <TableRow> 
                        <TableCell >No</TableCell>
                        {
                            this.props.whatYouArelookingFor.map( (itm,index) => {
                                if(itm.checked){
                                    return <TableCell key={index} >{itm.name} </TableCell>
                                }  
                            })
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    { this.props.quotes.map((item,index)=>(
                        <TableRow key={index} className={this.props.classes.highlight}>
                            <TableCell >{index+1}</TableCell>
                            {
                                this.props.whatYouArelookingFor.map( (itm,idx) => {

                                    if(itm.checked){
                                        return <TableCell key={idx} >{item[itm.id]} </TableCell>
                                    }  
                                })
                            }
                        </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
 
        );
        
    }
  }
export default withStyles(styles)(Customers);
