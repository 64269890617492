import React, { Component } from 'react';

class PanicBar extends Component{

    state= {}
    mountSide = () => {
        if(this.props.handleSide === "left"){
            return 'right';
        }else{
            return 'left';
        }
    }

    render() {
        return (
            <div>
                <div style={{ height: '44px',  width: '11px', border: '1px solid #dedcdc', position: 'absolute',margin: '0 auto',[this.props.handleSide]: '11px', bottom: `${this.props.bottom/this.props.printRatio}px`,}}></div>
                <div style={{ height: '44px',  width: '11px', border: '1px solid #dedcdc', position: 'absolute',margin: '0 auto',[this.mountSide()]: '11px',bottom: `${this.props.bottom/this.props.printRatio}px`,}}></div>
                <div style={{height: '8px', width: 'calc(100% - 48px)', border: '1px solid #dedcdc', position: 'absolute', margin: '0 auto', [this.props.handleSide] : '23px', bottom: `${(Number(this.props.bottom)+30)/this.props.printRatio}px`,}}></div>
            </div>
       )
    }
}

export default PanicBar;