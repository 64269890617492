import React, { Component } from 'react';
import { Redirect } from 'react-router';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';


// Check this out later --- 
//import posed from "react-pose";

const styles = theme => ({
    root: {
      //flexGrow: 1,
      margin: '50px auto',
      width: '90%',
    },




    paper: {
      padding: '1em',
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },

   
});




class Settings extends Component{
    state = {
    
    }


    componentDidMount(){}

    resetCahce = () => event  =>{


        
        // var req = indexedDB.deleteDatabase('firebaseLocalStorage');

        // req.onsuccess = function () {
        //     console.log("Deleted database successfully");
        // };
        // req.onerror = function () {
        //     console.log("Couldn't delete database");
        // };
        // req.onblocked = function () {
        //     console.log("Couldn't delete database due to the operation being blocked");
        // };

        // localStorage.clear();
        // window.location.reload(true) 
    }
    
    render() {

        if(this.props.loggedIn === true){
            return (
                
                <div className={this.props.classes.root}>  
           


                    <Grid container  spacing={6}>
                        <Grid item md={12} > 
                            <Paper>
                                <div style={{padding: '50px'}}>

                                    {this.props.userEmail}

                                    <Button onClick={this.resetCahce()} > For Administrators only </Button>
                    
                                </div>

                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            );
        }else{
                return <Redirect to='/'/>
        }
    }
  }
export default withStyles(styles)(Settings);