import React, { Component } from 'react';
import { Redirect } from 'react-router'
import { getDatabase, ref, query, limitToLast , onValue} from "firebase/database";
import { withStyles } from '@material-ui/core/styles';
import {Input, Container, Paper }from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Quotes from '../Quotes/Quotes';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

const styles = theme => ({


    header:{
        display: "flex",
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '1.5rem 2rem',

    }

});

class QuotesPage extends Component{
    state = {
        quotes : [],
        searchItems: [],
        loading: true
    }


    filterList = name => event =>{
        let updatedList = this.state.quotes;

        updatedList = updatedList.reverse().filter(function(item){
          return item[1][name].toLowerCase().search(event.target.value.toLowerCase()) !== -1;
        });
        this.setState({searchItems: updatedList});
    }

    loadSearchItems(){
        this.setState({searchItems: this.state.quotes})
    }

    componentDidMount(){

        const db = getDatabase();

        const recentPostsRef = query(ref(db, 'Quotes'), limitToLast(500));

        onValue(recentPostsRef, (snapshot) => {
            const data = snapshot.val();

            if(data !== null){

                let allquotes = Object.entries(data).reverse();   

                this.setState({
                    quotes: allquotes,
                    loading: false,
                    fullList: false
                },()=>{this.loadSearchItems()})
            }
        });
    }

    
    loadAllQuotes =() =>{

        this.setState({
            loading: true
        }, ()=>(this.loadAll()))
    }


    loadAll = () =>{
        const db = getDatabase();
        const recentPostsRef = query(ref(db, 'Quotes'));

        onValue(recentPostsRef, (snapshot) => {
            const data = snapshot.val();
                if(data !== null){
                    let allquotes = Object.entries(data).reverse();   
                    this.setState({
                        quotes: allquotes,
                        loading: false,
                        fullList: true
                    },()=>{this.loadSearchItems()})
                }
        });
    }

    render() {

       // const { classes, theme } = this.props;
        
        if(this.props.loggedIn === true  ){
            return (
                <Container maxWidth="xl" >
                    { this.state.loading  ? <span style={{display : 'block'}}> Loading <CircularProgress color="primary" />  </span> : <span>
                        <div className={this.props.classes.header}>  
                            <div style={{flexBasis: '195px'}}></div>
                            { !this.state.fullList &&  <div style={{flexBasis: ""}}><h2>500 Latest Quotes loaded</h2></div> }
                            { this.state.fullList &&  <><div style={{flexBasis: ""}}><h2>All Quotes</h2> </div> <div style={{flexBasis: '195px'}}></div></>}
                            { !this.state.fullList && 
                                <div style={{alignSelf: "flex-end"}}>
                                <Button 
                                    variant="contained" 
                                    endIcon={<CloudDownloadIcon/>} 
                                    onClick={this.loadAllQuotes }
                                    color="primary" 
                                    aria-label="load" 
                                    >
                                    Load All Quotes
                                </Button>
                                </div>
                            }
                         </div>
                            {/* {   this.state.fullList &&  */}
                                <Paper style={{ 
                                    display: 'flex', 
                                    justifyContent: 'center', 
                                    gap: '1rem', 
                                    padding : '1.5rem 2.5rem' ,
                                  
                                    margin: '0rem auto 1.5rem'
                                }}>
                                    <Input 
                                        type="text" 
                                        placeholder="Refference" 
                                        onChange={this.filterList('refernceNumber')}>
                                    </Input>
                                    <Input 
                                        type="text" 
                                        placeholder="Company Name" 
                                        onChange={this.filterList('companyName')}>
                                    </Input>  
                                </Paper>
                            {/* }   */}
                            <Paper >
                                <Quotes quotes={this.state.searchItems} userEmail={this.props.userEmail} sendToProduction={this.sendToProduction} ></Quotes>
                            </Paper>
                    </span> }

                </Container>

            );
        }else{
                return <Redirect to='/'/>
        }
    }
  }
;
export default withStyles(styles)(QuotesPage);