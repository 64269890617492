import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import {TotalValue, TotalVat, TotalCost} from '../logic/GetData';
import DoneIcon from '@material-ui/icons/Done';
import WarningIcon from '@material-ui/icons/Warning';

const styles = theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      width: 200,
    },

    table: {
        minWidth: 250,
    },
  });


class Orders extends Component{
    
    state = {}
  
    componentDidMount(){}
    render() {
            return ( 
                <div>
                    <Table className={this.props.classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell >Order no</TableCell>
                                <TableCell >Customer</TableCell>
                                <TableCell >Order Reference</TableCell>
                                <TableCell >order Placed</TableCell>
                                <TableCell >Deadline</TableCell>
                                { this.props.status === "closed" ? <TableCell >Invoice Uploaded ? </TableCell> : <TableCell >Customer DP</TableCell> }
                                { this.props.status === "closed" ? <TableCell >Final Payment Uplases ?</TableCell> : <TableCell >Factory DP</TableCell> }
                                <TableCell >Outstanding</TableCell>
                                <TableCell >VAT</TableCell> 
                                <TableCell >Order Net Cost</TableCell>   
                                <TableCell >Order Net Value</TableCell>   
                                <TableCell >Net P/L</TableCell>   
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {this.props.orders.map((order)=>{ 
                            return (
                                    <TableRow key={order[0]}>
                                        <TableCell ><Link to={`/order/${ order[0] }`}>{order[1].OrderNumber} </Link></TableCell>
                                        <TableCell ><Link to={`/customer/${order[1].Customer}`}>{order[1].CustomerName}</Link></TableCell> 
                                        <TableCell >{order[1].Reference}</TableCell>
                                        <TableCell >{order[1].DateApproved}</TableCell>
                                        
                                        <TableCell >{moment(order[1].DateApproved).add(order[1].LeadTime, 'week').format('L')}</TableCell>
                                        { order[1].OrderClosed === false ? 
                                            <TableCell >{order[1].downpaymentConfirmation === true ? <DoneIcon  style={{color: 'green'}}/> : <WarningIcon color="error"/>} </TableCell>
                                            :
                                            <TableCell >{order[1].invoiceUploaded === true ? <DoneIcon  style={{color: 'green'}}/> : <WarningIcon color="error"/>}</TableCell>
                                        }
                                        { order[1].OrderClosed === false ? 
                                            <TableCell >{order[1].factorydownpaymentConfirmation === true ? <DoneIcon  style={{color: 'green'}}/> : <WarningIcon color="error"/>}</TableCell>
                                            :
                                            <TableCell >{order[1].FinalPayment === true ? <DoneIcon  style={{color: 'green'}}/> : <WarningIcon color="error"/>}</TableCell>
                                        }
                                        <TableCell style={{color: order[1].outstanding !== 0 ? "red" : "green"}} ><strong>{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format( order[1].outstanding)}</strong></TableCell>    
                                        <TableCell style={{color: TotalValue(order[0]) === 0 ? "red" : "green"}} >{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format( TotalVat(order[0]))}{}</TableCell> 
                                        <TableCell style={{color: TotalValue(order[0]) === 0 ? "red" : "green"}} >{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format( TotalCost(order[0]))}{}</TableCell> 

                                        <TableCell style={{color: TotalValue(order[0]) === 0 ? "red" : "green"}} >{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format( TotalValue(order[0]))}{}</TableCell> 
                                        <TableCell style={{color: ( TotalValue(order[0]) -  TotalCost(order[0])) < 0 ? "red" : "green"}} >{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format( TotalValue(order[0]) -  TotalCost(order[0]))}{}</TableCell> 
                                    </TableRow>   
                                    );
                                })}
                        </TableBody>
                    </Table>
                </div>
            );
        
    }
  }
export default withStyles(styles)(Orders);
