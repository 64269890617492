import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { userContext } from '../../../Context/userContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import Slider from '@material-ui/core/Slider';


const styles = theme => ({

    zoomSlider: {
        '@media print' : {
            display : 'none' 
        }
    }

});


class ConfigZoom extends Component {
    render() {
        const { classes } = this.props;
        return (<userContext.Consumer>
            {(user) => (user.access >= 1 ?
                <div style={{float: 'right', width: '200px', marginTop: '-50px' }}>

          {this.props.zoom}
                    <Slider
                        style={{ flexBasis: "100%", margin: '20px 0 0' }}
                        label="Strenght"
                        className={this.props.classes.zoomSlider}
                        //defaultValue={light.strenght}
                        value={this.props.zoom}
                        aria-labelledby="discrete-slider"
                        valueLabelDisplay="auto"
                        step={300}
                        marks
                        min={5000}
                        max={10000}
                        onChange={this.props.handleSlider(this.props.editWhat, this.props.index)}
                    />

      
                </div> : <> {this.state.spinner ? <CircularProgress /> : <span></span>}  </>
            )}</userContext.Consumer>)
    }
}

export default withStyles(styles)(ConfigZoom);