import React, { Component } from 'react';
// import * as firebase from 'firebase'
import firebase from "firebase/compat/app";
import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import moment from 'moment';

import CostsTable from '../orders/CostsTable';

import Paper from '@material-ui/core/Paper';

  
  const styles = theme => ({
    root: {
    //   backgroundColor: theme.palette.background.paper,
      width: '100%',
      margin: '50 auto',
    },
    btn:{
        backgroundColor: '#d60a2e',
        color: '#ffffff',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
   
    textField: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: 200,
         ['@media (max-width:600px)']: { 
            display: 'inline-flex',
            width: '90%',
            margin: '10px 5%'
          }
    },
    textFieldShort: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: 70,
         ['@media (max-width:600px)']: { 
            display: 'inline-flex',
            width: '90%',
            margin: '10px 5%'
          }
    },paper:{
        margin: '20px',
        overflowX: 'scroll',
        padding: '20px'
    },
    
  });

const vat = [
    {
      value: '23',
      label: '23%',
    },{
      value: '21',
      label: '21%',
    },{
        value: '135',
        label: '13.5%',
    },{
        value: '0',
        label: '0%',
    }
];

class Costs extends Component{
    state = {
        costName: '',
        vat: 23,
        cost: '',
        invoiceNo: '',
        date: '',
        totalCost: '',
//        totalLabour: '',
        Income: '',
    }

    fieldUpdate = name => event =>{
        this.setState({
            [name]: event.target.value
        })
    }

    newCost = ()=>{
        const ordersRef = firebase.database().ref().child(`Orders/${this.props.orderId}/Costs`);
        if(this.state.costName === ''){
            alert('Add Cost Name');
        }else if(this.state.invoiceNo === ''){
            alert('Add Invoice Number');
        }else if(this.state.cost === ''){
            alert('Add Cost');
        }else if(this.state.date === ''){
            alert('Add Date');
        }
        else{
            ordersRef.push({
                'costName':this.state.costName,
                'vatRate': this.state.vat,
                'cost' : this.state.cost,
                'invoiceNo' : this.state.invoiceNo,
                'date' : this.state.date,
            });
        }
    }


    render() {

        const { classes } = this.props;
        
            return (
                
                <div className={classes.root}>
                 <Paper className={this.props.classes.paper} elevation={0} variant="outlined" >

                    <TextField
                        id="cost-name"
                        label="Cost Name"
                        value={this.state.costName}
                        onChange={this.fieldUpdate('costName')}
                        className={classes.textField} 
                    />

                    <TextField
                        id="invoiceNo"
                        label="Invoice No"
                        value={this.state.invoiceNo}
                        onChange={this.fieldUpdate('invoiceNo')}
                        className={classes.textField}
                    />
                  
                    <TextField
                        id="vat"
                        select
                        label="VAT rate"
                        className={classes.textFieldShort}
                        value={this.state.vat}
                        onChange={this.fieldUpdate('vat')}
                        SelectProps={{
                            native: true,
                            MenuProps: {
                            className: classes.menu,
                            },
                        }}
                        helperText=""
                        
                        >
                        {vat.map(option => (
                            <option key={option.value} value={option.value}>
                            {option.label}
                            </option>
                        ))}
                        </TextField>

                        <TextField
                            id="cost"
                            label="Cost"
                            value={this.state.cost}
                            onChange={this.fieldUpdate('cost')}
                            type="number"
                            className={classes.textFieldShort}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            
                        />
                        <TextField
                            id="date"
                            label="Date"
                            type="date"
                            className={classes.textField}
                            defaultValue={ moment().format('L')}
                            onChange={this.fieldUpdate('date',this.value)}
                            // className={this.props.classes.textField}
                            InputLabelProps={{
                            shrink: true,
                            }}
                        />

                    <Button  variant="outlined" color="primary" onClick={this.newCost}>Add New</Button> 

                    </Paper>

                    <CostsTable orderId={this.props.orderId}></CostsTable>    
                </div>

            );
        
    }
  }

export default withStyles(styles)(Costs);