import React, { Component } from 'react';
import { Redirect } from 'react-router'
// import * as firebase from 'firebase'
import firebase from "firebase/compat/app";

import EditManu from '../partials/EditMenu';
import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';






const styles = theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(2),
      marginRight:  theme.spacing(2),
      width: 200,
      ['@media (max-width:600px)']: { 
        display: 'inline-flex',
        width: '90%',
        margin: '10px 5%'
      }
    },
    paper:{
        overflowX: 'scroll',
        margin: '2%',
    },
    table: {
        minWidth: 340,
    },
  });


class FileTypes extends Component{
    
    state = {
        name: '',
        required: false,
        stage: 1,
        taskUpload: 'Upload',
        confirmation: 'File Upload',
        active: true,
        fileTypes : [],
    }
    handleChange = name => event =>{

        console.log(name)

        var active = this.state.active

        var value;


        value = event.target.value

        if(name === "active"){
            value = !active
        }else{
            value = event.target.value
        }
        this.setState({
            [name]: value
        })
    }

    componentDidMount(){
        // set refference to firebase 
        const ordersRef = firebase.database().ref('FileTypes');
        let allfileTypes = [];
        
        //load all orderes
        ordersRef.on('value',snap =>{
            let fileTypes = snap.val()
            //if there are orders in db 
            if(fileTypes !== null){
                //change json to array
                allfileTypes = Object.entries(fileTypes);       
            }
            this.setState({
                fileTypes: allfileTypes,
            })
        })   
    }

    newFileType = ()=>{

            const ordersRef = firebase.database().ref().child('FileTypes');

            

            ordersRef.push({
                'Name': this.state.name,
                'Required': this.state.required,
                'Stage': this.state.stage,
                'TaskUpload': this.state.taskUpload,
                'Confirmation': this.state.confirmation,
                'active': this.state.active,
            });
            this.setState({ 
                name: '' , 
                required: false,
                stage: 1,
                taskUpload: 'Upload',
                confirmation: 'File Upload',
                active: true
            });
        
    }

    render() {
        
        if(this.props.loggedIn === true  
            // && this.props.sysUser.access === 10 
            ){
            return (
                
                <div >
                    <h2>File Types</h2>
                        <TextField
                            id="name"
                            label="Name"
                            value={this.state.name}
                            onChange={this.handleChange('name')}
                            className={this.props.classes.textField}
                        />

                        <TextField
                            id="required"
                            select
                            label={this.state.required}
                            className={this.props.classes.textField}
                            value={this.state.required}
                            onChange={this.handleChange('required')}
                            SelectProps={{
                                native: true,
                                displayEmpty:true,
                                MenuProps: {
                                },
                            }}
                            helperText="Is this file/task required"
                            >
                                <option key='true' value='true'>Yes</option>
                                <option key='false' value='false'>No</option>
                        </TextField>
                        {this.state.required && 

                        <TextField
                            id="required"
                            select
                            label={this.state.stage}
                            className={this.props.classes.textField}
                            value={this.state.stage}
                            onChange={this.handleChange('stage')}
                            SelectProps={{
                                native: true,
                                displayEmpty:true,
                                MenuProps: {
                                },
                            }}
                            helperText="Sets stage of the project"
                            >   
                                <option key='1' value='1'>1</option>
                                <option key='2' value='2'>2</option>
                                <option key='3' value='3'>3</option>
                                <option key='4' value='4'>4</option>
                                <option key='5' value='5'>5</option>
                                <option key='6' value='6'>6</option>
                                <option key='7' value='7'>7</option>
                                <option key='8' value='8'>8</option>
                                <option key='9' value='9'>9</option>
                                <option key='10' value='10'>10</option>
                        </TextField>
                        }

                         <TextField
                            id="taskUpload"
                            select
                            label={this.state.taskUpload}
                            className={this.props.classes.textField}
                            value={this.state.taskUpload}
                            onChange={this.handleChange('taskUpload')}
                            SelectProps={{
                                native: true,
                                displayEmpty:true,
                                MenuProps: {
                                },
                            }}
                            helperText="Is this file/task required"
                            >
                                <option key='Task' value='Task'>Task</option>
                                <option key='Upload' value='Upload'>Upload</option>
                                <option key='Task & Upload' value='Task & Upload'>Task & Upload</option>
                        </TextField>



                        <TextField
                            id="confirmation"
                            select
                            label={this.state.confirmation}
                            className={this.props.classes.textField}
                            value={this.state.confirmation}
                            onChange={this.handleChange('confirmation')}
                            SelectProps={{
                                native: true,
                                displayEmpty:true,
                                MenuProps: {
                                },
                            }}
                            helperText="Is this acomplished by file upload or data entry">
                                <option key='Data Entry' value='Data Entry'>Data entry</option>
                                <option key='File Upload' value='File Upload'>File Upload</option>  
                        </TextField>

                        {/* <span>
                         is active ?
                            <Checkbox
                                // disabled={this.checkTaks(task[1].Name, task[1].Confirmation) }
                                checked={this.state.active}
                                onChange={this.handleChange("active") }
                            />
                            
                            
                        </span>  */}


                    

                    <Button  variant="outlined" color="primary" onClick={this.newFileType}>Add File Type</Button>
                    <Paper className={this.props.classes.paper}>

                    <ul>
                        <li>1. Start of the order.(Quote approved, downpayment paid, order sent to production )</li>
                        <li>5. Half of the lead time (Confiram collection date from factory)</li>
                        <li>6. Prepare second invoice, all papers </li>
                        <li>9. Product delivered to custommer</li>
                        <li>10.Post sell actions like callout service ect.</li>
                    </ul>
                        <Table className={this.props.classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell> Name</TableCell>
                                    <TableCell> Required</TableCell>
                                    <TableCell> Stage</TableCell>
                                    <TableCell> Task / Upload</TableCell>
                                    <TableCell> Data Input</TableCell>
                                    <TableCell align="right"  > Edit</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {this.state.fileTypes.map((fileType)=>{ 
                                return (
                                        <TableRow key={fileType[0]}>
                                                <TableCell >{fileType[1].Name}</TableCell>
                                                <TableCell >{fileType[1].Required === 'false' ? <span>No</span> : <span>Yes</span>}</TableCell>
                                                <TableCell >{fileType[1].Stage}</TableCell>
                                                <TableCell >{fileType[1].TaskUpload}</TableCell>
                                                <TableCell >{fileType[1].Confirmation}</TableCell>
                                                <TableCell align="right">
                                                    <EditManu 
                                                        mainGroup="FileTypes"
                                                        parentType=''
                                                        projectId=''
                                                        recordKey={fileType[0]}
                                                        update={[
                                                            { type:'text', id:'Name', label: 'Name', value: fileType[1].Name},
                                                            { type:'select', id:'Required', label: 'Required', value: fileType[1].Required, options: [{ value: 'true',label: 'Yes'},{ value: 'false',label: 'No'}] },
                                                            { type:'select', id:'Stage', label: 'Stage', value: fileType[1].Stage, options: [{ value: 1,label: '1'},{ value: 2,label: '2'},{ value: 3,label: '3'},{ value: 4,label: '4'},{ value: 5,label: '5'},{ value: 6,label: '6'},{ value: 7,label: '7'},{ value: 8,label: '8'},{ value: 9,label: '9'},{ value: 10,label: '10'}] },  
                                                            { type:'select', id:'TaskUpload', label: 'Task/Upload', value: fileType[1].TaskUpload, options: [{ value: 'Task',label: 'Task'},{ value: 'Upload',label: 'Upload'},{ value: 'Task & Upload',label: 'Task & Upload'}] },
                                                            { type:'select', id:'Confirmation', label: 'Confirmation', value: fileType[1].Confirmation, options: [{ value: 'Data Entry',label: 'Data Entry'},{ value: 'File Upload',label: 'File Upload'}] },
                                                        ]}
                                                    ></EditManu>
                                                </TableCell> 
                                        </TableRow> 
                                );
                            })}
                            </TableBody>
                        </Table>
                        </Paper>

                </div>
            );
        }else{
                return <Redirect to='/'/>
        }
    }
  }
export default withStyles(styles)(FileTypes);
