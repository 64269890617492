import {Group}  from 'three';
import createBox from './createBox';
const uniqid = require('uniqid');


const createHandleTypeC = (color, adjustment , door, profileWidth, profileDepth, side,  metalness, roughness, ironmongery) =>{

    if(ironmongery.color === "sameAsFrame"){
        color = color
    }else{
        color = ironmongery.color
    }

    const handleFront = new Group();
    const handleBack = new Group();
    const set = new Group();
    
    const handle1 = createBox(10,ironmongery.height,10,color, uniqid(), metalness+0.1,roughness+0.)
    const handle2 = createBox(10,30,10,color, uniqid(), metalness,roughness)
    const handle3 = createBox(10,30,10,color, uniqid(), metalness,roughness)
    const handle4 = createBox(50,10,10,color, uniqid(), metalness,roughness)
    const handle5 = createBox(50,10,10,color, uniqid(), metalness,roughness)

    
    const handle6= createBox(10,ironmongery.height,10,color, uniqid(), metalness,roughness)
    const handle7 = createBox(10,30,10,color, uniqid(), metalness,roughness)
    const handle8 = createBox(10,30,10,color, uniqid(), metalness,roughness)
    const handle9 = createBox(50,10,10,color, uniqid(), metalness,roughness)
    const handle10 = createBox(50,10,10,color, uniqid(), metalness,roughness)

    handle2.rotation.x = -Math.PI * 0.5;
    handle3.rotation.x = -Math.PI * 0.5;
    handle2.position.x =  -50
    handle3.position.x = -50
    handle2.position.y = ironmongery.height/2 -5
    handle3.position.y = -ironmongery.height/2 +5
    handle2.position.z = -20
    handle3.position.z = -20
    handle4.position.y = ironmongery.height/2 -5
    handle4.position.x = - 30
    handle5.position.y = -ironmongery.height/2 +5
    handle5.position.x = - 30

    handle7.rotation.x = -Math.PI * 0.5;
    handle8.rotation.x = -Math.PI * 0.5;
    handle7.position.x =  -50
    handle8.position.x = -50
    handle7.position.y = ironmongery.height/2 -5;
    handle8.position.y = -ironmongery.height/2 +5;
    handle7.position.z = 20;
    handle8.position.z = 20;
    handle9.position.y = ironmongery.height/2 -5
    handle9.position.x = - 30
    handle10.position.y = -ironmongery.height/2 +5
    handle10.position.x = - 30

    handleFront.add( handle1, handle2, handle3, handle4, handle5)
    handleBack.add(handle6, handle7, handle8, handle9, handle10)

    handleBack.position.z =  - Number(profileDepth)-10
    handleFront.position.z =  Number(profileDepth)+10

    // handleBack.position.x = - Number(adjustment)  + profileWidth + 160
    // handleFront.position.x = - Number(adjustment)  + profileWidth + 160
    

    if(door === "mainDoor" ){
     

        if(side == "left" ){
            handleBack.position.x = - Number(adjustment)  +  profileWidth +40
            handleFront.position.x = - Number(adjustment)  + profileWidth +40
        }else{
            handleBack.position.x = Number(adjustment)  -  profileWidth - 40
            handleBack.rotation.z = Math.PI 
            handleFront.position.x = Number(adjustment)  -  profileWidth - 40
            handleFront.rotation.z = Math.PI 
        }
    }else{
       
        if(side == "left" ){
            handleBack.position.x =  Number(adjustment)  -  profileWidth - 40
            handleFront.position.x =  Number(adjustment)  - profileWidth - 40
              handleBack.rotation.z = Math.PI 
              handleFront.rotation.z = Math.PI 
        }else{
            handleBack.position.x = -Number(adjustment)  + profileWidth + 40
          
            handleFront.position.x = -Number(adjustment)  +  profileWidth + 40
            
        }
    }
    set.add(handleFront , handleBack)

    return( set)

}

export default createHandleTypeC

