module.exports = `
<h3 >Terms & Conditions of Quotation and Sale</h3>

<ol>
    <li>

        <h4 >Definitions</h4>

        <ol>
            <li>“Seller” Technical Door Solution - Unit 251,Blanchardstown Corporate Park 2, Ballycoolin, Dublin 15, Co. Dublin D15 W62P, Ireland.</li>
            <li>“Purchaser” means the person, firm or company who purchases the Goods from the Seller.</li>
            <li>"Contract” means the contract between the Seller and the Purchaser for the sale and purchase of the Goods in accordance with these Terms and Conditions.</li>
            <li>“Goods” means doors, windows, fixtures, parts, products, items, assemblies and any item or items described in any quotation and/or acceptance of any order or any part thereof.</li>
            <li>“Terms and Conditions” means the terms and conditions set out in this document.</li>

            <li>“Statutory Interest” means statutory interest as defined in the Late Payment of Commercial Debts (Interest) Act.</li>
        </ol>
    </li>

    <li>
            <h4 >Terms & Conditions</h4>

            <ol>
                <li>These Terms and Conditions apply to the Contract to the exclusion of any other terms that the Purchaser seeks to impose or incorporate, or which are implied by trade, custom, practice or course of dealing.</li>
            </ol>
    </li>

    <li>

            <h4 >Acceptance</h4>

            <ol>
                <li>All quotations are subject to confirmation by the Seller on receipt of any order and the Seller reserves the right to refuse any order.</li>

                <li> Quotations provided by the Seller will only remain valid for the period of time as indicated in the quotation after which the Seller reserves the rights to re-tender if requested by the Purchaser to proceed further.</li>

            </ol>
    </li>
    <li>

        <h4 >Title</h4>

            <ol>
                <li>
                Title to the Goods shall remain vested in the Seller and shall not pass to the Purchaser until all sums due to the Seller under the Contract have been paid in full.
                </li>
            </ol>

    </li>
    <li>
            <h4 >Drawings & Specifications</h4>

            <ol>
                <li>
                    All specifications, drawings, descriptions and catalogues issued by or on behalf of the Seller are intended as a guideline of the goods that the
                          Company can supply / manufacture and the actual goods may differ from same
                </li>
                <li>
                    The Purchaser shall be responsible for providing all of the specifications required in order to manufacture the Goods. The Seller shall not be charged for the provision of said specifications.
                </li>
                <li>
                    The Purchaser shall be responsible for providing all of the information required for the manufacture of the Goods in a complete and accurate state.
                </li>
                <li>
                    The Seller maintains the right to cease all progress and manufacturing of the Goods without liability should information provided by the Purchaser be conflicting, in error, incomplete or inadequate in any way to produce the Goods.
                </li>
              
            </ol>
    </li>
    <li>

            <h4 >Quotation</h4>

            <ol>
                <li>
                    All quotations, indication of costs and financial commitments given or made by the Seller are based on the assumption of the validity of the information provided being fully accurate and correct in all circumstances. The Seller maintains the right at any stage to re-negotiate any contract , cost agreement or any other relevant commitment should any information provided by the Purchaser fail to be fully valid accurate and correct.
                </li>
                <li>
                    Quotes are valid for 14 days and are subject to the availability of Equipment at the time of order.
                </li>
                <li>
                    Any revised Quote with the same Product or for the same Project supersedes and nullifies any prior quote.
                </li>
                <li>
                    Design or Specification changes may affect pricing.
                </li>
                
            </ol>
    </li>
   
    <li>
            <h4 >Terms of Payment</h4>
            <ol>
                <li>
                    Payment will be paid in two installments. 
                    
                    First payment 50% of the order value paid upfront and outsdanding 50% before delivery.
                    
                </li>
                <li>
                    All payments to the Seller shall be made in EURO
                </li>
                <li>
                    The Seller may, at any time, without limiting any other rights and remedies it may have, set off any amount owing to it by the Purchaser against any amount payable by the Seller to the Purchaser.
                </li>
                <li>
                    If payment is not made in accordance with clause 7.1, the seller reserves the right to hold the goods untill order is paid in full.

                    In addition, each dunning letter will attract an administration fee of €50 which will be paid by the Purchaser to the Seller.
                </li>
                <li>
                    Failure to pay by the due date shall entitle the Seller to suspend delivery of all current and future orders
                </li>
                <li>
                    Where the Seller has good reason to believe the Purchaser may default on payment, the Seller reserves the right at any time and in its absolute discretion to demand immediate payment of any account and to take legal action to recover the debt and costs.
                </li>

                <li>
                    Where the order is cancelled the Purchaser is obligated to pay for goods in full. 
                
                <ol>
                <li> Where the order is cancelled while in production, the Purchaser is obligated to pay for goods in full.</li>
                <li> Where the order is cancelled before production, the site survey cost is non-refundable (site survey cost priced individually - min. €450 + VAT) and will be retained from any refund due.</li>
                </ol>
                </li>
                
            </ol>

    </li>
    <li>
        <h4 >Modifications</h4>

        <ol>
            <li>
                The Seller will not agree to incorporate or implement any modification on the basis of a “Change Proposal” provided by the Purchaser or any party acting on behalf of the Purchaser after first installment was paid.
            </li>
           
        </ol>
    </li>
    <li>

        <h4 >Cancellations & Returns</h4>

        <ol>
            <li>
                Where the order is cancelled while in production, the Purchaser is obligated to pay for goods in full. 
            </li>

            <li>
                Where the order is cancelled before production, the site survey cost is non-refundable (site survey cost priced individually - min. €450 + VAT) and will be retained from any refund due.
            </li>
            <li>
            As all Goods are custom made, returns are not accepted.
            </li>
        </ol>

    </li>
    <li>
        <h4 >Delivery & Schedules</h4>

        <ol>
            <li>
                If due to unforeseen circumstances the Purchaser requires the delivery time or times set out in the Contract to be altered, the Purchaser must provide notice in writing not less than four weeks prior to the said delivery time or times. The Seller will use its reasonable endeavours to meet the new delivery time or times requested by the Purchaser but reserves the right to charge the Purchaser for any increase in cost to the Seller incurred in meeting the new delivery time or times.
            </li>
        
            <li>
                If fransport is arranged by the Seller, the risk in the Goods shall pass to the Purchaser at the time of the delivery at the said delivery point.
            </li>
        
            <li>
                If transport is arranged by the Purchaser, risk in the Goods shall pass to the Purchaser immediately after the Goods pass out of the control of the Seller, or its authorised agents.
            </li>

            <li>
                The Seller does not accept any financial penalties for late deliveries.
            </li>
        </ol>

    </li>

    <li>
        <h4 >Warranties</h4>
        <ol>
            <li>
                The Seller warrants that the Goods shall conform to the specifications or descriptions furnished by the Purchaser and accepted by the Seller, will be of satisfactory quality and free from material defects in material and workmanship.
            </li>
            <li>
                All Goods sold by the Seller must be installed by approved Installer. The Seller is not responsible for personal or property damages or Goods malfunction due to improper installations. Warranty does not cover any damages caused by unprofessional installation, improper use or improper installation. 
            </li>
            <li>
            The manufacturer grants a warranty for proper operation of the doors and locks (this does not apply to the customer’s own locks) for a period of 24 months for doors and 12 months for hardware from the date of: a) installation by an authorised installer, or b) purchase subject to correct installation.
        </li>
        <li>
            The warranty can be extended by a service carried out by our authorised installer 12 months after installation and every 12 months thereafter. This service only applies to doors installed in ROI. Other areas are subject to individual written agreement.
        </li>
        <li>
            An incorrectly filled out Warranty Card is invalid. In particular, the production number must match the number on the ID plate of the door. The plate is located halfway up the door on the hinged side, or accordingly on the door frame.
        </li>
        <li>
            The client acknowledges and agrees that in the event of any damage to the door(s) attributable to the client's negligence or mishandling prior to the agreed-upon handover, TDS Ltd reserves the right to charge for a call-out service or necessary repairs incurred to rectify the damage.
        </li>
        </ol>
    </li>
</ol>
`
