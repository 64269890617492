import React, { Component } from 'react';
import { userContext } from '../Context/userContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Header from '../Quotes/QuoteHeader';
import Footer from '../Quotes/QuoteFooter';
import Canvas from './components/canvas/renderCanvas';
import { Redirect } from 'react-router';
import ReactQuill from 'react-quill';
import ConfigZoom from './components/composerComponents/configZoom';
import { Button } from '@material-ui/core';
// import { optionReset } from './components/quoteComponents/optionsReset'
import OpeningSideAndDirection from "./components/quoteComponents/openingSideAndDirection"
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TableHead, TableCell, TableBody, Table, TableRow, Accordion, AccordionDetails, Container, Grid } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DeleteIcon from '@material-ui/icons/Delete';
import { totalPricePerDoor, returnTotalVat, sqmArea, returnTotalDiscount, sumUpAllNetValue, showDiscount } from "./logic";

import UploadFileIcon from '@mui/icons-material/UploadFile';

import EditDrawing from "./components/quoteComponents/editDrawing";

const Terms = require('../Pages/Terms');

const styles = theme => ({
    header: {
        height: '85px'
    },
    noPrint: {
        '@media print': {
            display: 'none'
        }
    },
    panelDetails: {
        display: 'flex',
        flexDirection: 'column',
        gap: "1em"
    },
    btn: {
        backgroundColor: '#d60a2e',
        color: '#ffffff',
    },

    listBtn: {
        float: "right",
        margin: "10px",
        width: "10px"
    },
    dangHtml: {

        '& p': {
            margin: "0px"
        }
    },
    paper: {
        padding: "2em"
    },


    dashedLineScreenOnly: {
        marginBottom: "2em",
        borderBottom: "1px dashed #666",
        paddingBottom: "2em",

        '@media print': {
            marginBottom: "0em",
            borderBottom: "0px ",
            paddingBottom: "0em",

        }
    }
});



class Quote extends Component {

    state = {
        discount: false,
        // isVisible: 'Visible',
        totalDiscount: 0,
        netValue: 0,
        totalVat: 0,
        // panel: false
    }


    componentDidMount = () => {

        // optionReset()

        let discount = showDiscount(this.props)
        let netValue = sumUpAllNetValue(this.props)
        let totalDiscount = returnTotalDiscount(this.props)
        let totalVat = returnTotalVat(this.props)

        this.setState({
            discount,
            netValue,
            totalDiscount,
            totalVat
        })
    }

    componentDidUpdate(prevProps) {

        if (prevProps !== this.props) {

            let discount = showDiscount(this.props)
            let netValue = sumUpAllNetValue(this.props)
            let totalDiscount = returnTotalDiscount(this.props)
            let totalVat = returnTotalVat(this.props)

            this.setState({
                netValue,
                discount,
                totalDiscount,
                totalVat
            })

        }
    }

    handleTabs = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    };

    priceItem = (key, id, doorIndex, pricelist) => {

        let itemIndex = this.props.prices[pricelist].findIndex(priceItem => priceItem[0] === id)


        if (itemIndex !== -1) {
            let item = this.props.prices[pricelist][itemIndex][1]
            return (
                <small style={{ display: "flex", alignItems: "center" }}>
                    <div> {item.name}: €{item.price} {item.fixedPrice !== undefined && <> + fixedP: €{item.fixedPrice} </>}</div>
                    <span onClick={() => {

                        this.props.remFromDescription([key, id], doorIndex)
                    }}>       <DeleteIcon style={{ fontSize: "13px", cursor: "pointer", border: "1px solid black", marginLeft: "10px", padding: "1px" }}></DeleteIcon></span>
                </small>
            )
        } else {
            return (<small > This item has been deleted from database, check admin panel</small>)
        }

    }

    returnPriceItemValue = (pricelist, id, name) => {
        let itemIndex = this.props.prices[pricelist].findIndex(priceItem => priceItem[0] === id)

        if (itemIndex !== -1) {
            let itemValue = this.props.prices[pricelist][itemIndex][1][name]

            return itemValue

        } else {
            return "Item not found"
        }

    }

    priceItemDescription = (id, list) => {

        let index = this.props.prices[list].findIndex(itm => itm[0] === id)

        return (this.props.prices[list][index][1].description)

    }


    render() {
        const { classes } = this.props;
        if (this.state.redirect === true) {
            return <Redirect to={'/quotes'} />
        }

        return (<userContext.Consumer>
            {(user) => (user.access >= 5 ?
                <Container
                    maxWidth="xl"
                    style={{ flexDirection: 'column', display: 'flex', textAlign: 'left' }}
                >

                    {
                        this.props.doors.map((door, doorIndex) => {

                            return (

                                <Grid container key={doorIndex} spacing={6} className={classes.dashedLineScreenOnly} >






                                    {window.location.pathname.split('/')[1] === 'steel-framed-quote' &&


                                        <Grid item xl={5} className={classes.noPrint} >
                                            <Paper className={`${classes.paper} ${classes.noPrint}ś`} style={{ display: "flex", flexDirection: "column", gap: "2em" }}>

                                                {/* ------- Main Drawing --------- */}

                                                <EditDrawing

                                                    door={door.mainDrawing}
                                                    doorIndex={doorIndex}
                                                    createOrEditMainDrawing={this.props.createOrEditMainDrawing}
                                                ></EditDrawing>

                                                {door.mainDrawing !== undefined && <>

                                                    <div>
                                                        < TextField
                                                            id="doorRef"
                                                            text
                                                            label="Door ref"
                                                            style={{ width: '100%' }}
                                                            value={door.doorName}
                                                            onChange={this.props.handleItemChange(["doorName"], doorIndex)}
                                                            margin="normal"
                                                        > </TextField>

                                                        < TextField
                                                            id="subHeading"
                                                            text
                                                            label="Subheading"
                                                            style={{ width: '100%' }}
                                                            value={door.subHeading}
                                                            onChange={this.props.handleItemChange(["subHeading"], doorIndex)}
                                                            margin="normal"
                                                        > </TextField>
                                                    </div>


                                                    {/* ------- Build Price and description --------- */}


                                                    <Accordion expanded={this.state.expanded === 'pricingAndDescription'} onChange={this.handleTabs('pricingAndDescription')}>
                                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                            <h4> Pricing and description</h4>
                                                        </AccordionSummary>


                                                        <AccordionDetails className={classes.panelDetails}>


                                                            <div style={{ display: "flex", justifyContent: "space-between" }}>

                                                                < TextField
                                                                    id="qty"
                                                                    type="number"
                                                                    label="Qty"
                                                                    style={{}}
                                                                    value={door.qty}
                                                                    onChange={this.props.handleItemChange(["qty"], doorIndex)}
                                                                    margin="normal"
                                                                > </TextField>

                                                                < TextField
                                                                    id="discount"
                                                                    type="number"
                                                                    label="Discount"
                                                                    style={{}}
                                                                    value={door.discount}
                                                                    onChange={this.props.handleItemChange(["discount"], doorIndex)}
                                                                    margin="normal"
                                                                > </TextField>

                                                                <TextField
                                                                    id="vat"
                                                                    select
                                                                    label="VAT"
                                                                    value={door.vat}
                                                                    onChange={this.props.handleItemChange(["vat"], doorIndex)}
                                                                    SelectProps={{
                                                                        native: true,
                                                                        MenuProps: {
                                                                            className: classes.menu,
                                                                        },
                                                                    }}
                                                                    helperText=""
                                                                    margin="normal"
                                                                >
                                                                    <option key='21' value="21">21%</option>
                                                                    <option key='23' value="23">23%</option>
                                                                    <option key='13' value="135">13.5%</option>
                                                                    <option key='0' value="0">0%</option>
                                                                </TextField>

                                                            </div>






                                                            {/* ---------------------------------------------   DoorType */}





                                                            {(this.props.prices.availableDoorTypes !== undefined) &&

                                                                <TextField
                                                                    id="doorType"
                                                                    select
                                                                    label="Door Type"
                                                                    style={{ width: '100%' }}
                                                                    className="Select Item"
                                                                    value={door.DoorType === undefined ? "Select Item" : door.DoorType}
                                                                    onChange={this.props.handleItemChange(["DoorType"], doorIndex)}
                                                                    SelectProps={{
                                                                        native: true,
                                                                        MenuProps: {
                                                                            className: classes.menu,
                                                                        },
                                                                    }}
                                                                    helperText=""
                                                                    margin="normal"
                                                                >


                                                                    <option key="1" disabled="disabled" value="Select Item">Select item</option>
                                                                    {this.props.prices.availableDoorTypes.map(irnm => {
                                                                        return (
                                                                            <option key={irnm[0]} value={irnm[0]}>{irnm[1].name}</option>
                                                                        )
                                                                    })}
                                                                </TextField>
                                                            }



                                                            {/* ---------------------------------------------   Glass */}



                                                            {(this.props.prices.availableGlass !== undefined) &&

                                                                <TextField
                                                                    id="glass"
                                                                    select
                                                                    label="Glass"
                                                                    style={{ width: '100%' }}
                                                                    className="glass"
                                                                    value={door.Glass === undefined ? "Select Item" : door.Glass}
                                                                    onChange={this.props.handleItemChange(["Glass"], doorIndex)}
                                                                    SelectProps={{
                                                                        native: true,
                                                                        MenuProps: {
                                                                            className: classes.menu,
                                                                        },
                                                                    }}
                                                                    helperText=""
                                                                    margin="normal"
                                                                >
                                                                    <option key="1" disabled="disabled" value="Select Item">Select item</option>
                                                                    {this.props.prices.availableGlass.map(irnm => {
                                                                        return (
                                                                            <option key={irnm[0]} value={irnm[0]}>{irnm[1].name}</option>
                                                                        )
                                                                    })}
                                                                </TextField>

                                                            }


                                                            {/* ---------------------------------------------   Finish */}

                                                            {(this.props.prices.availableFinishes !== undefined) &&


                                                                <TextField
                                                                    id="finish"
                                                                    select
                                                                    label="Finish"
                                                                    style={{ width: '100%' }}
                                                                    className="finish"
                                                                    value={door.Finish === undefined ? "Select Item" : door.Finish}
                                                                    onChange={this.props.handleItemChange(["Finish"], doorIndex)}
                                                                    SelectProps={{
                                                                        native: true,
                                                                        MenuProps: {
                                                                            className: classes.menu,
                                                                        },
                                                                    }}
                                                                    helperText=""
                                                                    margin="normal"
                                                                >
                                                                    <option key="1" disabled="disabled" value="Select Item">Select item</option>
                                                                    {this.props.prices.availableFinishes.map(irnm => {
                                                                        return (
                                                                            <option key={irnm[0]} value={irnm[0]}>{irnm[1].name}</option>
                                                                        )
                                                                    })}
                                                                </TextField>
                                                            }

                                                            {/* ---------------------------------------------   Ironmongery */}

                                                            <TextField
                                                                id="ironmongery"
                                                                select
                                                                label="Ironmongery"
                                                                style={{ width: '100%' }}
                                                                className="ironmongery"
                                                                value={(door.availableIronmongery === undefined) ? "Select Item" : door.availableIronmongery[0]}
                                                                onChange={this.props.handleItemChange(["Ironmongery"], doorIndex)}
                                                                SelectProps={{
                                                                    native: true,
                                                                    MenuProps: {
                                                                        className: classes.menu,
                                                                    },
                                                                }}
                                                                helperText=""
                                                                margin="normal"
                                                            >

                                                                <option key="1" disabled="disabled" value="Select Item" >Select item</option>
                                                                {this.props.prices.availableIronmongery.map(irnm => {
                                                                    return (
                                                                        <option key={irnm[0]} value={irnm[0]}>{irnm[1].name}</option>
                                                                    )
                                                                })}

                                                            </TextField>

                                                            <h4>Additional Price Items</h4>
                                                            <Button variant="outlined" color="primary" aria-label="add" className={classes.btn} onClick={() => this.props.extraOver(doorIndex)}>
                                                                <AddIcon /> Add Price Item
                                                            </Button>

                                                            {door.extraOver &&
                                                                door.extraOver.map((option, index) => {

                                                                    return (
                                                                        <div key={index} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>

                                                                            <TextField
                                                                                style={{}}
                                                                                className={classes.textField}
                                                                                type="text"
                                                                                id="text"
                                                                                name="text"
                                                                                label="text"
                                                                                value={option.text}
                                                                                onChange={this.props.handleItemChange(["extraOver", option.key, "text"], doorIndex)}
                                                                            />

                                                                            <TextField
                                                                                style={{}}
                                                                                className={classes.textField}
                                                                                type="number"
                                                                                id="price"
                                                                                name="prace"
                                                                                label="Price"
                                                                                value={option.price}
                                                                                onChange={this.props.handleItemChange(["extraOver", option.key, "price"], doorIndex)}
                                                                            />
                                                                            <span>
                                                                                <small>Visible on quote</small>
                                                                                <Switch
                                                                                    color="primary"
                                                                                    onChange={this.props.handleItemChange(["extraOver", option.key, "visible"], doorIndex)}
                                                                                />
                                                                            </span>

                                                                            <IconButton className={classes.listBtn} size="small" aria-label="Delete" onClick={() => this.props.handleRemoveDoorPriceItem(doorIndex, index)}>
                                                                                <DeleteIcon />
                                                                            </IconButton>

                                                                        </div>
                                                                    )
                                                                })
                                                            }





                                                            <h5>Note:</h5>
                                                            <ReactQuill
                                                                value={door.customDescription}
                                                                onChange={this.props.handleQuillText(["customDescription", doorIndex])}
                                                            />


                                                        </AccordionDetails>
                                                    </Accordion>



                                                    {/* ------- Small Drawing --------- */}


                                                    <Accordion expanded={this.state.expanded === 'general'} onChange={this.handleTabs('general')} style={{ marginTop: "2em" }}>

                                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                            <h4>Small Drawing</h4>
                                                        </AccordionSummary>

                                                        <AccordionDetails className={classes.panelDetails}>
                                                            {/* ---------------------------------------------   Zoom */}

                                                            {/* <div style={{ display: "flex", flexDirection: "column" }}> */}

                                                            {door.mainDrawing !== undefined &&
                                                                <TextField
                                                                    id="side"
                                                                    select
                                                                    label="LH/RH"
                                                                    style={{ width: '100%' }}
                                                                    className="side"
                                                                    value={door.mainDrawing.side}
                                                                    onChange={this.props.handleItemChange(["mainDrawing", "side"], doorIndex)}
                                                                    SelectProps={{
                                                                        native: true,
                                                                        MenuProps: {
                                                                            className: classes.menu,
                                                                        },
                                                                    }}
                                                                    helperText=""
                                                                    margin="normal"

                                                                >
                                                                    <option key="1" value="left">LH</option>
                                                                    <option key="2" value="right">RH</option>
                                                                </TextField>

                                                            }

                                                            {/* </div> */}

                                                            < TextField
                                                                id="room1"
                                                                text
                                                                label="room1"
                                                                style={{ width: '100%' }}
                                                                value={door.smallDrawing.room1}
                                                                onChange={this.props.handleItemChange(["smallDrawing", "room1"], doorIndex)}
                                                                margin="normal"
                                                            > </TextField>

                                                            < TextField
                                                                id="room2"
                                                                text
                                                                label="room2"
                                                                value={door.smallDrawing.room2}
                                                                style={{ width: '100%' }}
                                                                onChange={this.props.handleItemChange(["smallDrawing", "room2"], doorIndex)}
                                                                margin="normal"
                                                            > </TextField>

                                                            < TextField
                                                                id="wallFixing"
                                                                select
                                                                label="Wall Fixing"
                                                                style={{ width: '100%' }}
                                                                className="wallFixing"
                                                                value={door.smallDrawing.wallFixing}
                                                                onChange={this.props.handleItemChange(["smallDrawing", "wallFixing"], doorIndex)}
                                                                SelectProps={{
                                                                    native: true,
                                                                    MenuProps: {
                                                                        className: classes.menu,
                                                                    },
                                                                }}
                                                                helperText=""
                                                                margin="normal"
                                                            >
                                                                <option key="1" value="1">1</option>
                                                                <option key="2" value="2">2</option>
                                                                <option key="3" value="3">3</option>
                                                            </TextField>

                                                        </AccordionDetails>
                                                    </Accordion>

                                                    <div>
                                                        <h4>Price Breakdown:</h4>

                                                        <small>




                                                            {sqmArea(door) < this.returnPriceItemValue("availableDoorTypes", door.DoorType, "minArea") ? <span>Sqm - {sqmArea(door)} m<sup>2</sup> &#8594; {this.returnPriceItemValue("availableDoorTypes", door.DoorType, "minArea")}m<sup>2</sup></span> : <> Sqm = {sqmArea(door)}m<sup>2</sup></>}
                                                            <br />
                                                            {door.DoorType !== undefined && this.priceItem("DoorType", door.DoorType, doorIndex, "availableDoorTypes")}
                                                            {door.Glass !== undefined && this.priceItem("Glass", door.Glass, doorIndex, "availableGlass")}
                                                            {door.Finish !== undefined && this.priceItem("Finish", door.Finish, doorIndex, "availableFinishes")}
                                                            {door.Ironmongery !== undefined && door.Ironmongery.map(item => {
                                                                return (<span key={item}>{this.priceItem("Ironmongery", item, doorIndex, "availableIronmongery")}</span>)
                                                            })}

                                                            <h5><strong>Total: €{totalPricePerDoor(door, this.props.prices)}</strong></h5>
                                                        </small>
                                                    </div>

                                                </>

                                                }
                                            </Paper>
                                        </Grid>
                                    }

                                    <Grid item xl={7}>

                                        <Paper className="pagePriceing" style={{ minHeight: "730px", maxHeight: "730px", display: "flex", flexDirection: "column" }}>

                                            <Header className={this.props.classes.header}
                                                companyName={this.props.companyName}
                                                contactName={this.props.contactName}
                                                contactEmail={this.props.contactEmail}
                                                phoneNumber={this.props.phoneNumber}
                                                firstAddressLine={this.props.firstAddressLine}
                                                secondAddressLine={this.props.secondAddressLine}
                                                city={this.props.city}
                                                county={this.props.county}
                                                eircode={this.props.eircode}
                                                country={this.props.country}
                                                quoteDate={this.props.quoteDate}
                                                quoteNo={this.props.quoteNo}
                                                quoteID={this.props.quoteID}
                                                refernceNumber={this.props.refernceNumber}
                                            ></Header>
                                            {door.mainDrawing !== undefined &&
                                                <Grid container  >

                                                    <Grid item md={4} style={{ display: "flex", flexDirection: "column", borderRight: "1px solid #efefef", minHeight: "620px" }}>

                                                        <div className="quoteDescription">
                                                            {door.doorName !== "" && <h4>{door.doorName} </h4>}
                                                            {door.subHeading && <h6>{door.subHeading}</h6>}

                                                            {door.DoorType !== undefined && <small><strong>Door type:</strong> {this.priceItemDescription(door.DoorType, "availableDoorTypes")} <br /></small>}
                                                            {door.Glass !== undefined && <small><strong>Glazing:</strong> {this.priceItemDescription(door.Glass, "availableGlass")} <br /></small>}
                                                            {door.Finish !== undefined && <small><strong>Finish:</strong> {this.priceItemDescription(door.Finish, "availableFinishes")} <br /></small>}

                                                            <br /><strong>Ironmongery</strong>

                                                            {door.Ironmongery !== undefined &&
                                                                <ul>
                                                                    {door.Ironmongery.map((ironM, index) => {
                                                                        return (<li key={index}>{this.priceItemDescription(ironM, "availableIronmongery")}</li>)
                                                                    })}
                                                                </ul>
                                                            }

                                                            {door.extraOver !== undefined &&
                                                                <>
                                                                    {door.extraOver.map((item) => {
                                                                        return (item.visible && <p key={item.key}>{item.text}</p>)
                                                                    })}
                                                                </>
                                                            }

                                                            <div style={{ maxWidth: "96%" }} dangerouslySetInnerHTML={{ __html: door.customDescription }} ></div>
                                                        </div>

                                                        <OpeningSideAndDirection door={door}></OpeningSideAndDirection>




                                                    </Grid>

                                                    <Grid item md={8} >

                                                        {// restrict rotation and zoom
                                                            door.mainDrawing.orbitCam = false}

                                                        {// restrict rotation and zoom
                                                            door.mainDrawing.draggable = false}

                                                        <Canvas
                                                            doorSetup={door.mainDrawing}
                                                            id={"door" + doorIndex}
                                                            height="570px"
                                                        ></Canvas>



                                                    </Grid>

                                                </Grid>

                                            }
                                            <Footer></Footer>

                                        </Paper>

                                    </Grid>

                                    {/* Dokończ nawigację */}
                                    {window.location.pathname.split('/')[1] === 'steel-framed-quote' &&
                                        <div className={classes.noPrint} style={{ marginRight: "-100px", float: "right", display: 'flex', flexDirection: "column", gap: '1em' }}>
                                            {doorIndex !== 0 &&
                                                <UploadFileIcon
                                                    onClick={this.props.handlePushItemUp("doors", doorIndex)}
                                                    style={{ cursor: "pointer" }}>
                                                </UploadFileIcon>
                                            }
                                            <UploadFileIcon
                                                onClick={this.props.handlePushItemDown("doors", doorIndex)}
                                                style={{ rotate: "180deg", cursor: "pointer" }}></UploadFileIcon>
                                            <DeleteIcon
                                                onClick={() => this.props.handleDeleteItem("doors", doorIndex)}
                                            ></DeleteIcon>
                                        </div>
                                    }

                                </Grid>

                            )
                        })
                    }

                    {window.location.pathname.split('/')[1] === 'steel-framed-quote' &&
                        <Button onClick={this.props.addDoor} className={classes.noPrint} variant="outlined" style={{ width: "200px", margin: "1em auto" }}>Add new door</Button>
                    }
                    <h2 className={classes.noPrint}> Price Breakdown</h2>

                    <Grid container spacing={6}>

                        {window.location.pathname.split('/')[1] === 'steel-framed-quote' &&

                            <Grid item md={5} className={classes.noPrint}>
                                <Paper
                                    className={this.props.classes.noPrint}
                                    style={{ padding: '1rem', flexGrow: "1", display: "flex", flexDirection: "column", gap: "2em", justifyContent: "space-evenly" }}
                                >

                                    <h2>Additional Price Items</h2>
                                    <Button variant="outlined" color="primary" aria-label="add" className={classes.btn} onClick={this.props.handelAddPriceItem}>
                                        <AddIcon /> Add Price Item
                                    </Button>

                                    {
                                        this.props.additionalPriceItems.map((option, index) => (


                                            <div key={index} className={classes.dashedLineScreenOnly}>
                                                {/* <h3>Extra Price Item {index + 1}</h3> */}
                                                Is this a global discount ?

                                                {option.globalDiscount ? <span> Yes </span> : <span> No </span>}
                                                <Switch
                                                    color="primary"
                                                    onChange={this.props.handleglobalDiscount('additionalPriceItems', option.key)}
                                                />

                                                {!option.globalDiscount ?
                                                    <span>


                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>

                                                            <TextField
                                                                style={{}}
                                                                className={classes.textField}
                                                                type="text"
                                                                id="itemText"
                                                                name="text"
                                                                label="text"
                                                                value={option.text}
                                                                onChange={this.props.handelAdditionalPriceItem(option.key, 'text', 'additionalPriceItems')}
                                                            />

                                                            <TextField
                                                                style={{ width: '98px' }}
                                                                className={classes.textField}
                                                                type="number"
                                                                id="price"
                                                                name="prace"
                                                                label="Price"
                                                                value={option.price}
                                                                onChange={this.props.handelAdditionalPriceItem(option.key, 'price', 'additionalPriceItems')}
                                                            />

                                                            <TextField
                                                                style={{ width: '50px' }}
                                                                className={classes.textField}
                                                                type="number"
                                                                id="itemQty"
                                                                name="Qty"
                                                                label="Qty"
                                                                value={option.qty}
                                                                onChange={this.props.handelAdditionalPriceItem(option.key, 'qty', 'additionalPriceItems')}
                                                            />

                                                            <TextField
                                                                style={{ width: '90px' }}
                                                                type="number"
                                                                id="discount2"
                                                                name="discount"
                                                                label="Discount (%)"
                                                                className={classes.textField}
                                                                value={option.discount}
                                                                onChange={this.props.handelAdditionalPriceItem(option.key, 'discount', 'additionalPriceItems')}
                                                            />

                                                            <TextField
                                                                style={{ width: '80px' }}
                                                                id="vat"
                                                                select
                                                                label="VAT"
                                                                className={classes.textField}
                                                                value={option.vat}
                                                                onChange={this.props.handelAdditionalPriceItem(option.key, 'vat', 'additionalPriceItems')}
                                                                SelectProps={{
                                                                    native: true,
                                                                    MenuProps: {
                                                                        className: classes.menu,
                                                                    },
                                                                }}
                                                                helperText=""
                                                                margin="normal"
                                                            >
                                                                <option key='21' value="21">21%</option>
                                                                <option key='23' value="23">23%</option>
                                                                <option key='13' value="135">13.5%</option>
                                                                <option key='0' value="0">0%</option>
                                                            </TextField>

                                                            <IconButton aria-label="arrow" size="small" className={classes.listBtn} onClick={this.props.handlePushItemDown('additionalPriceItems', option.key)}>
                                                                <ArrowDownwardIcon />
                                                            </IconButton>


                                                            <IconButton className={classes.listBtn} size="small" aria-label="Delete" onClick={this.props.handleRemovePriceItem(option.key)}>
                                                                <DeleteIcon />
                                                            </IconButton>

                                                        </div>
                                                    </span>
                                                    :
                                                    <span style={{ clear: 'both', display: 'flex' }}>


                                                        <TextField
                                                            style={{}}
                                                            type="number"
                                                            id="discount"
                                                            name="discount"
                                                            label="Discount (%)"

                                                            value={option.discount}
                                                            onChange={this.props.handelAdditionalPriceItem(option.key, 'discount', 'additionalPriceItems')}
                                                        />

                                                        <TextField
                                                            style={{}}
                                                            type="number"
                                                            id="days"
                                                            name="days"
                                                            label="Days to order and pay"

                                                            value={option.days}
                                                            onChange={this.props.handelAdditionalPriceItem(option.key, 'days', 'additionalPriceItems')}
                                                        />

                                                    </span>
                                                }

                                            </div>
                                        ))
                                    }

                                </Paper>
                            </Grid>

                        }


                        <Grid item md={7}>


                            <Paper className="pagePriceing" >
                                <Header className={this.props.classes.header}
                                    companyName={this.props.companyName}
                                    contactName={this.props.contactName}
                                    contactEmail={this.props.contactEmail}
                                    phoneNumber={this.props.phoneNumber}
                                    firstAddressLine={this.props.firstAddressLine}
                                    secondAddressLine={this.props.secondAddressLine}
                                    city={this.props.city}
                                    county={this.props.county}
                                    eircode={this.props.eircode}
                                    country={this.props.country}
                                    quoteDate={this.props.quoteDate}
                                    quoteNo={this.props.quoteNo}
                                    quoteID={this.props.quoteID}
                                    refernceNumber={this.props.refernceNumber}
                                ></Header>
                                <div style={{ display: "flex", flexDirection: "column", minHeight: "600px" }}>


                                    <Table >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Door</TableCell>
                                                <TableCell align="right">Qty</TableCell>
                                                <TableCell align="right">Net Unit Price</TableCell>
                                                {this.state.discount && <TableCell align="right">Discount</TableCell>}
                                                {this.state.discount && <TableCell align="right">Discount Unit Price</TableCell>}
                                                <TableCell align="right">Total VAT</TableCell>
                                                <TableCell align="right">Total</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody  >
                                            {this.props.doors.map((door, index) => {
                                                return <TableRow key={index}>
                                                    <TableCell >{door.doorName}</TableCell>
                                                    <TableCell align="right"> {door.qty}</TableCell>
                                                    <TableCell align="right">{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format(totalPricePerDoor(door, this.props.prices).toFixed(2))}</TableCell>
                                                    {this.state.discount &&
                                                        <TableCell align="right">{door.discount}% - {new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format((totalPricePerDoor(door, this.props.prices).toFixed(2) * (1 - (100 - door.discount) / 100)).toFixed(2))}</TableCell>}

                                                    {this.state.discount &&
                                                        <TableCell align="right">{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format((totalPricePerDoor(door, this.props.prices).toFixed(2) - (totalPricePerDoor(door, this.props.prices).toFixed(2) * (1 - (100 - door.discount) / 100))).toFixed(2))}</TableCell>
                                                    }

                                                    <TableCell align="right"> {door.vat === '23' ? ('23%') : door.vat === '21' ? ('21%') : (door.vat === '135') ? ('13.5%') : ("0%")}

                                                        {
                                                            Number(door.discount) !== 0
                                                                ?
                                                                <span> - {new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format((door.qty * (totalPricePerDoor(door, this.props.prices).toFixed(2) - (totalPricePerDoor(door, this.props.prices).toFixed(2) * (1 - (100 - door.discount) / 100))) * ('0.' + Number(door.vat))).toFixed(2))} </span>
                                                                :
                                                                <span> - {new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format((door.qty * totalPricePerDoor(door, this.props.prices).toFixed(2) * ('0.' + Number(door.vat))).toFixed(2))} </span>
                                                        }
                                                    </TableCell>

                                                    <TableCell align="right">
                                                        {
                                                            Number(door.discount) !== 0
                                                                ?
                                                                <span>
                                                                    {new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format((door.qty * (totalPricePerDoor(door, this.props.prices).toFixed(2) - (totalPricePerDoor(door, this.props.prices).toFixed(2) * (1 - (100 - door.discount) / 100))) * ('1.' + Number(door.vat))).toFixed(2))}</span>
                                                                :
                                                                <span>{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format((door.qty * totalPricePerDoor(door, this.props.prices).toFixed(2) * ('1.' + Number(door.vat))).toFixed(2))} </span>
                                                        }
                                                    </TableCell >
                                                </TableRow>
                                            })}

                                            {this.props.additionalPriceItems.map((item, index) => {
                                                return (

                                                    !item.globalDiscount ?
                                                        <TableRow key={index}>
                                                            <TableCell style={{ maxWidth: "20vw" }} ><span className={classes.dangHtml} dangerouslySetInnerHTML={{ __html: item.text }}></span></TableCell>
                                                            <TableCell align="right" >{item.qty} </TableCell>
                                                            <TableCell align="right" >{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format(item.price)} </TableCell>
                                                            {this.state.discount && <TableCell align="right">{item.discount}% - {new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format((item.price - (item.price - (item.price * (1 - (100 - item.discount) / 100)))).toFixed(2))}</TableCell>}
                                                            {this.state.discount && <TableCell align="right"> {new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format((item.price - (item.price * (1 - (100 - item.discount) / 100))).toFixed(2))}</TableCell>}
                                                            <TableCell align="right"> {item.vat === '23' ? ('23%') : item.vat === '21' ? ('21%') : (item.vat === '135') ? ('13.5%') : ("0%")}
                                                                {
                                                                    Number(item.discount) !== 0
                                                                        ?
                                                                        <span> - {new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format((item.qty * (item.price - (item.price * (1 - (100 - item.discount) / 100))) * ('0.' + Number(item.vat))).toFixed(2))}</span>
                                                                        :
                                                                        <span> - {new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format((item.qty * item.price * ('0.' + Number(item.vat))).toFixed(2))} </span>
                                                                }
                                                            </TableCell>

                                                            <TableCell align="right">
                                                                {
                                                                    Number(item.discount) !== 0
                                                                        ?
                                                                        <span>
                                                                            {new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format((item.qty * (item.price - (item.price * (1 - (100 - item.discount) / 100))) * ('1.' + Number(item.vat))).toFixed(2))}</span>
                                                                        :
                                                                        <span>{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format((item.qty * item.price * ('1.' + Number(item.vat))).toFixed(2))} </span>
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                        :
                                                        <TableRow key={index}>
                                                            <TableCell >Settlement discount: if ordered and paid 50% within {item.days} days </TableCell>
                                                            <TableCell > </TableCell>
                                                            <TableCell > </TableCell>
                                                            {this.state.discount && <TableCell > </TableCell>}
                                                            {this.state.discount && <TableCell > </TableCell>}

                                                            <TableCell > </TableCell>
                                                            <TableCell align="right" >{item.discount}% - {new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format(this.state.totalDiscount)}</TableCell>

                                                        </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                    <div className='summary' style={{ display: "flex", flexDirection: "column", minHeight: "300px" }}>
                                        <Table >
                                            < TableBody >
                                                <TableRow key="spacer" style={{ padding: '4em 0 1.5em 2em' }}>
                                                    <TableCell ></TableCell>
                                                    <TableCell ></TableCell>
                                                    <TableCell ></TableCell>
                                                    {this.state.discount && <TableCell ></TableCell>}
                                                    {this.state.discount && <TableCell ></TableCell>}
                                                    <TableCell align="right" ></TableCell>
                                                    <TableCell align="right" style={{ padding: '4em 0 1.5em 2em' }} ><h3>Summary</h3></TableCell>
                                                </TableRow>

                                                <TableRow key="netsummary">
                                                    <TableCell ></TableCell>
                                                    <TableCell ></TableCell>
                                                    <TableCell ></TableCell>
                                                    {this.state.discount && <TableCell ></TableCell>}
                                                    {this.state.discount && <TableCell ></TableCell>}
                                                    <TableCell align="right" ><b>Net Amount:</b> </TableCell>
                                                    <TableCell align="right" >{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format(Number(this.state.netValue))}</TableCell>

                                                </TableRow>

                                                {this.state.discount &&
                                                    <TableRow key="discount">
                                                        <TableCell ></TableCell>
                                                        <TableCell ></TableCell>
                                                        <TableCell ></TableCell>
                                                        {this.state.discount && <TableCell ></TableCell>}
                                                        {this.state.discount && <TableCell ></TableCell>}
                                                        <TableCell align="right" ><b>Total Net Discount:</b> </TableCell>
                                                        <TableCell align="right" >{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format(this.state.totalDiscount)}</TableCell>
                                                    </TableRow>
                                                }
                                                <TableRow key="vatTotal">
                                                    <TableCell ></TableCell>
                                                    <TableCell ></TableCell>
                                                    <TableCell ></TableCell>
                                                    {this.state.discount && <TableCell ></TableCell>}
                                                    {this.state.discount && <TableCell ></TableCell>}
                                                    <TableCell align="right"><b>VAT :</b></TableCell>

                                                    <TableCell align="right">{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format(this.state.totalVat)}</TableCell>
                                                </TableRow>
                                                <TableRow key="summary">
                                                    <TableCell ></TableCell>
                                                    <TableCell ></TableCell>
                                                    <TableCell ></TableCell>
                                                    {this.state.discount && <TableCell ></TableCell>}
                                                    {this.state.discount && <TableCell ></TableCell>}
                                                    <TableCell align="right"><b>Total :</b></TableCell>

                                                    <TableCell align="right">{new Intl.NumberFormat('gb-BG', { style: 'currency', currency: 'EUR' }).format(Number(this.state.totalVat) - Number(this.state.totalDiscount) + Number(this.state.netValue))}</TableCell>
                                                </TableRow>

                                            </TableBody  >
                                        </Table>
                                    </div>
                                    <ol style={{ textAlign: 'left', fontSize: '11px', marginTop: "auto" }}>
                                        <li>The customer is obligated to check the quotation for compliance with their request.</li>
                                        <li>The quotation is representative of the entire request, and is dependent on the volume of elements. The change of any factors (dimensions, colours, ironmongery, glass, quantity of order, etc.) requires a recalculation of the quotation.</li>
                                        <li>The customer is obligated to confirm that the ordered product complies with the specifications and regulations required by them.</li>
                                    </ol>
                                    <Footer></Footer>
                                </div>
                                <div
                                    className="terms"
                                    dangerouslySetInnerHTML={{ __html: Terms }} >
                                </div>


                            </Paper>

                        </Grid>
                    </Grid>

                </Container> : <> {this.state.spinner ? <CircularProgress /> : <span></span>}  </>
            )
            }</userContext.Consumer>)
    }
}

export default withStyles(styles)(Quote)