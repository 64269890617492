import React, { Component } from 'react';
import { Redirect } from 'react-router';
// import * as firebase from 'firebase';
import firebase from "firebase/compat/app";

import EditManu from '../partials/EditMenu';
import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import {userContext} from '../Context/userContext';

const styles = theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      width: 200,
      ['@media (max-width:600px)']: { 
        display: 'inline-flex',
        width: '90%',
        margin: '10px 5%'
      }
    },
    paper:{
        overflowX: 'scroll',
        margin: '2%',
    },
    table: {
        minWidth: 340,
    },
  });


class Users extends Component{
    
    state = {
        name: '',
        email: '',
        access: '',
        initials: '',
        users : [],
    }
    handleChange = name => event =>{
        this.setState({
            [name]: event.target.value
        })
    }

    componentDidMount(){
        // set refference to firebase 
        const ordersRef = firebase.database().ref('Users');

        //load all orderes
        ordersRef.on('value',snap =>{
            
            let users = snap.val();


            

            //if there are orders in db 
            if(users !== null){

                //change json to array
                let allusers = Object.entries(users);    
                

                this.setState({
                    users: allusers,
                })
  
            }else{
                this.setState({
                    users: []
                })
            }
        })
        
    }
    newUser = ()=>{

            const ordersRef = firebase.database().ref().child('Users');

            if(this.state.name === ''){
                alert('Set Name');
            }else{

            ordersRef.push({
                'name': this.state.name,
                'email': this.state.email,
                'access': this.state.access,
                'initials': this.state.initials,
            });
            this.setState({ 
                name: '' , 
            });
        }
    }

    render() {
        
 
            return (

                <userContext.Consumer>
                    {(user) => (

                user.access >= 0 ?
                
                <div>

                    <h2>File Types</h2>
                        <TextField
                            id="name"
                            label="Name"
                            value={this.state.name}
                            onChange={this.handleChange('name')}
                            className={this.props.classes.textField}
                        />

                        <TextField
                            id="name"
                            label="Email"
                            value={this.state.email}
                            onChange={this.handleChange('email')}
                            className={this.props.classes.textField}
                        />

                        <TextField
                            id="name"
                            label="Access"
                            value={this.state.access}
                            onChange={this.handleChange('access')}
                            className={this.props.classes.textField}
                        />

                        <TextField
                            id="name"
                            label="Initials"
                            value={this.state.initials}
                            onChange={this.handleChange('initials')}
                            className={this.props.classes.textField}
                        />

                    <Button  variant="outlined" color="primary" onClick={this.newUser}>Add User</Button>
                    <Paper className={this.props.classes.paper}>
                        <Table className={this.props.classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Access</TableCell>
                                    <TableCell>Initials</TableCell>
                                    
                                    <TableCell align="right"  > Edit</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {this.state.users.map((user)=>{ 
                                return (
                                 
                                    <TableRow key={user[0]}>
                                        <TableCell >{user[1].name}</TableCell>
                                        <TableCell >{user[1].email}</TableCell>
                                        <TableCell >{user[1].access}</TableCell>
                                        <TableCell >{user[1].initials}</TableCell>
                                        <TableCell align="right" >
                                            <EditManu 
                                                mainGroup="Users"
                                                parentType=''
                                                projectId=''
                                                recordKey={user[0]}
                                                update={[
                                                    { type:'text', id:'name', label: 'Name', value: user[1].name},
                                                    { type:'text', id:'email', label: 'Email', value: user[1].email},
                                                    { type:'text', id:'access', label: 'Access', value: user[1].access},
                                                    { type:'text', id:'initials', label: 'Initials', value: user[1].initials},
                                                ]}
                                            ></EditManu> 
                                        </TableCell>
                                    </TableRow>
                                    
                                );
                            })}
                            </TableBody>
                        </Table>
                        </Paper>

                        <Paper className={this.props.classes.paper}>

                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Stage</TableCell>
                                    <TableCell>1</TableCell>
                                    <TableCell>2</TableCell>
                                    <TableCell>3</TableCell>
                                    <TableCell>4</TableCell>
                                    <TableCell>5</TableCell>
                                    <TableCell>6</TableCell>
                                    <TableCell>7</TableCell>
                                    <TableCell>8</TableCell>
                                    <TableCell>9</TableCell>
                                    <TableCell>10</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Read Quotes</TableCell>
                                    <TableCell>x</TableCell>
                                    <TableCell>x</TableCell>
                                    <TableCell>x</TableCell>
                                    <TableCell>x</TableCell>
                                    <TableCell>x</TableCell>
                                    <TableCell>x</TableCell>
                                    <TableCell>x</TableCell>
                                    <TableCell>x</TableCell>
                                    <TableCell>x</TableCell>
                                    <TableCell>x</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>View & Edit deals</TableCell>
                                    <TableCell>x</TableCell>
                                    <TableCell>x</TableCell>
                                    <TableCell>x</TableCell>
                                    <TableCell>x</TableCell>
                                    <TableCell>x</TableCell>
                                    <TableCell>x</TableCell>
                                    <TableCell>x</TableCell>
                                    <TableCell>x</TableCell>
                                    <TableCell>x</TableCell>
                                    <TableCell>x</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Close Deals</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>x</TableCell>
                                    <TableCell>x</TableCell>
                                    <TableCell>x</TableCell>
                                    <TableCell>x</TableCell>
                                    <TableCell>x</TableCell>
                                </TableRow>
                                  <TableRow>
                                    <TableCell>Delete from pipeline</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>x</TableCell>
                                    <TableCell>x</TableCell>
                                    <TableCell>x</TableCell>
                                    <TableCell>x</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Edit Users</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>x</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Change oreder numbers</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>x</TableCell>
                                </TableRow>
                        
                            </TableBody>
                        </Table>
                        </Paper>

                        </div>
                        :
                        <Redirect to='/'/>
                        
                        )}
        </userContext.Consumer>
                
                

        
            );
     
    }
  }
export default withStyles(styles)(Users);
