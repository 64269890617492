
import React, { Component } from 'react';




class Header extends Component{

    render(){

        return(<div className="footer" style={{width: '100%' , marginTop: "auto"}} >
            <hr style={{height: '1px',  color: '#D82328',  backgroundColor: '#D82328',  border: 'none', margin: "0 0 10px" }}/>
            <p style={{fontSize: "smaller", margin: "0px", textAlign: "left"}}>Unit 251, Blanchardstown Corporate Park 2, Ballycoolin, Dublin 15, Co. Dublin D15 W62P | Phone: +353 (01) 866 5831 | Email: info@tdsltd.ie</p>
            </div>)
        }
    }
export default Header;